import React, { useEffect } from 'react';
import { useLocation, useNavigationType } from 'react-router-dom';
import { usePrevious } from 'hooks';

export const ScrollToTop: React.FC = () => {
	const location = useLocation();
	const prevLocation = usePrevious(location);
	const type = useNavigationType();

	useEffect(() => {
		if (location.key !== prevLocation?.key && type !== 'POP') {
			window.scrollTo(0, 0);
		}
	}, [location]);

	return null;
};
