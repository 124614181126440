import { SetupMutationFn } from 'core/configure/types';
import { BaseHttpError } from 'core/services/api/errors';
import { AlbumPhoto } from 'core/types';
import { albumPhotoKeys, communityKeys } from 'core/utils/query-key-factory';
import { isCancelledError, Mutation, useMutation } from 'react-query';
import {
	patchAlbumPhotoMutationKey,
	postAlbumPhotoMutationKey,
} from 'core/utils/mutation-key-factory';
import {
	onPatchMutateOptimisticInfinityQueryCache,
	cancelPreviousMutation,
	onSuccessOptimisticInInfinityQueryCache,
	findRecordFromInfinityQueryCache,
	PaginatedRecordsSnapshot,
} from 'core/utils/optimistic-utils';

export interface PatchPhotoMutationInput {
	communityId: string;
	albumId: string;
	photo: AlbumPhoto;
	name: string;
}

export const usePatchPhoto = () => {
	const mutation = useMutation<
		AlbumPhoto,
		BaseHttpError,
		PatchPhotoMutationInput
	>(patchAlbumPhotoMutationKey);

	return mutation;
};

type MutationContext = {
	communityId: string;
	photoId: string;
	updatedPhoto: AlbumPhoto;
	currentPhotoInfo: {
		listSnapshot: PaginatedRecordsSnapshot<AlbumPhoto>;
	} | null;
};

export const setupPatchPhoto: SetupMutationFn = (
	services,
	createTrackedParallelMutation,
	mutationTracker
) => {
	const { queryClient, api } = services;

	const mutation = createTrackedParallelMutation<
		AlbumPhoto,
		BaseHttpError,
		PatchPhotoMutationInput,
		MutationContext
	>({
		mutationFn: async ({ photo, albumId, name }) => {
			return api
				.patchPhoto(albumId, photo.uuid, {
					name,
					sortOrder: photo.sort_order,
				})
				.then(response => response.data);
		},
		onMutate: async input => {
			const { photo, communityId, name } = input;

			const currentPhotoInfo =
				findRecordFromInfinityQueryCache<AlbumPhoto>(
					queryClient,
					albumPhotoKeys.list(communityId),
					photo.uuid
				);

			const updatedPhoto: AlbumPhoto = {
				...photo,
				image: {
					...photo.image,
					name,
				},
			};

			// Cancel the previous mutation if it exists
			await cancelPreviousMutation<MutationContext>(
				queryClient,
				mutation => {
					return (
						mutation.state.context?.photoId === photo.uuid &&
						isPostPatchAlbumPhotoMutation(mutation)
					);
				}
			);

			await onPatchMutateOptimisticInfinityQueryCache<AlbumPhoto>(
				queryClient,
				albumPhotoKeys.list(communityId),
				albumPhotoKeys.detail(communityId, photo.uuid),
				updatedPhoto
			);

			return {
				communityId,
				photoId: photo.uuid,
				updatedPhoto,
				currentPhotoInfo,
			};
		},
		onSuccess: (result, input, { communityId, photoId }) => {
			onSuccessOptimisticInInfinityQueryCache<AlbumPhoto>(
				queryClient,
				albumPhotoKeys.list(communityId),
				albumPhotoKeys.detail(communityId, photoId),
				photoId,
				result
			);
		},
		onError: (error, _input, context) => {
			if (context?.currentPhotoInfo && !isCancelledError(error)) {
				queryClient.setQueryData(
					albumPhotoKeys.list(context.communityId),
					context?.currentPhotoInfo.listSnapshot
				);
			}
		},
		onSettled: (_data, error, _variables, context) => {
			if (context && !isCancelledError(error)) {
				mutationTracker.queueInvalidations(
					albumPhotoKeys.list(context.communityId),
					albumPhotoKeys.detail(context.communityId, context.photoId),
					communityKeys.feed(context.communityId)
				);
			}
		},
		retry: 1,
	});

	queryClient.setMutationDefaults(patchAlbumPhotoMutationKey, mutation);
};

export const isPostPatchAlbumPhotoMutation = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	mutation: Mutation<any, any, any, any>
) =>
	[patchAlbumPhotoMutationKey, postAlbumPhotoMutationKey].includes(
		String(mutation?.options?.mutationKey)
	);
