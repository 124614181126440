import { Nullable, Identity, Community } from 'core/types';
import { useTranslation } from 'hooks';
import { useCallback, useRef } from 'react';
import { getBaseUrl } from 'utils/url';
import { sleep } from 'utils/delay';
import {
	Stack,
	Box,
	TextField,
	LoadingButton,
	Form,
	Field,
	Typography,
	PickerFile,
	Heading,
	ImagePickerField,
	Link,
	FieldForSubscription,
	FormOnSubmitReturn,
	FormStack,
} from '../../../common';

export type SetupCommunityProfileSubmittedValues = {
	community: {
		slug: string;
		image: Nullable<PickerFile>;
	};
	identity: {
		image: Nullable<PickerFile>;
	};
};

type SetupCommunityProfileFormProps = {
	identity: Identity;
	community: Community;
	initialValues?: Partial<
		Omit<SetupCommunityProfileSubmittedValues, 'community.image'>
	>;
	onSubmit?: (
		values: SetupCommunityProfileSubmittedValues
	) => FormOnSubmitReturn;
};

export const SetupCommunityProfileForm = (
	props: SetupCommunityProfileFormProps
) => {
	const {
		onSubmit,
		initialValues,
		community: currentCommunity,
		identity: currentIdentity,
	} = props;

	const { t } = useTranslation();

	const cachedInitialValues = useRef({
		...initialValues,
		identity: {
			...initialValues?.identity,
			image: initialValues?.identity?.image
				? [initialValues?.identity?.image]
				: undefined,
		},
	}).current;

	const handleOnSubmit = useCallback(
		async values => {
			const { community, identity } = values;

			const communitySlug = community?.slug
				? community.slug.trim()
				: currentCommunity.slug;

			await sleep(1);

			const result = onSubmit?.({
				identity: {
					image: identity.image?.[0] ? identity.image[0] : undefined,
				},
				community: {
					slug: communitySlug,
					image: community?.image?.[0]
						? community?.image[0]
						: undefined,
				},
			});

			return Promise.resolve(result).catch(error => error);
		},
		[onSubmit, currentCommunity, currentIdentity]
	);

	return (
		<Form
			onSubmit={handleOnSubmit}
			initialValues={cachedInitialValues}
			subscription={{ submitting: true }}
			keepDirtyOnReinitialize
		>
			{({ handleSubmit, submitting }) => (
				<form onSubmit={handleSubmit} noValidate>
					<FormStack>
						<Box component='section'>
							<Heading variant='h5'>
								{t('community-form.community-details')}
							</Heading>
							<Stack spacing={6}>
								<Box component='dl'>
									<Typography component='dt'>
										{t('community-form.community-name')}:
									</Typography>
									<Typography component='dd'>
										{currentCommunity.name}
									</Typography>
								</Box>
								<Box component='dl'>
									<Typography component='dt'>
										{t('community-form.community-type')}:
									</Typography>
									<Typography component='dd'>
										{currentCommunity.purpose.name}
									</Typography>
								</Box>
								<Box component='dl'>
									<Typography component='dt'>
										{t('community-form.community-location')}
										:
									</Typography>
									<Typography component='dd'>
										{currentCommunity.city}
										{currentCommunity.state
											? `, ${currentCommunity.state}`
											: ''}
									</Typography>
								</Box>
							</Stack>
						</Box>
						<Box component='section'>
							<Heading variant='h5'>
								{t('community-form.add-a-community-photo')}
							</Heading>
							<Typography mb={1}>
								{t('community-form.upload-a-photo-that')}.{' '}
								{t(
									'community-form.you-can-change-this-at-any-time'
								)}
								.
							</Typography>
							<Box maxWidth={300}>
								<Field
									name='community.image'
									component={ImagePickerField}
								/>
							</Box>
						</Box>
						<Box component='section'>
							<Heading variant='h5'>
								{t('community-form.owner-details')}
							</Heading>
							<Stack spacing={6}>
								<Box component='dl'>
									<Typography component='dt'>
										{t('community-form.name')}:
									</Typography>
									<Typography component='dd'>
										{currentIdentity.first_name}{' '}
										{currentIdentity.last_name}
									</Typography>
								</Box>
								<Box component='dl'>
									<Typography component='dt'>
										{t('community-form.email')}:
									</Typography>
									<Typography component='dd'>
										<Link
											href={`mailto:${currentIdentity.email}`}
										>
											{currentIdentity.email}
										</Link>
									</Typography>
								</Box>
							</Stack>
						</Box>
						<Box component='section'>
							<Heading variant='h5' mb={1}>
								{t('community-form.profile-photo')}
							</Heading>
							<Box maxWidth={300}>
								<Field
									name='identity.image'
									component={ImagePickerField}
								/>
							</Box>
						</Box>
						<Box component='section'>
							<Heading variant='h5'>
								{t('community-form.set-your-community-url')}
							</Heading>
							<Typography mb={1}>
								{t('community-form.share-the-url')}.
							</Typography>
							<Field
								name='community.slug'
								component={TextField}
								placeholder={currentCommunity.slug}
							/>
							<FieldForSubscription
								name='community.slug'
								subscription={{ value: true }}
							>
								{({ input }) => (
									<>
										<Typography
											mb={1}
											mt={2}
											color='primary'
										>
											{`${getBaseUrl()}/community/${
												input.value ||
												currentCommunity.slug
											}`}
										</Typography>
									</>
								)}
							</FieldForSubscription>
						</Box>
						<Box>
							<LoadingButton
								loading={submitting}
								loadingIndicator={t(
									'community-form.going-to-step-2'
								)}
								size='small'
								type='submit'
							>
								{t('community-form.step-2')}:{' '}
								{t('community-form.add-members')}
							</LoadingButton>
						</Box>
					</FormStack>
				</form>
			)}
		</Form>
	);
};
