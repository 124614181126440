import { useServices } from 'core/services';
import { BaseHttpError } from 'core/services/api/errors';
import { AnnouncementsResponse } from 'core/services/api/response-types';
import { Announcement } from 'core/types';
import { getPageParams } from 'core/utils/pagination';
import { announcementKeys } from 'core/utils/query-key-factory';
import { useInfiniteQuery } from 'react-query';

export const useGetAnnouncements = (
	communityId: string,
	type: Parameters<(typeof announcementKeys)['list']>[1],
	options: {
		enable?: boolean;
		loadAllPages?: boolean;
	} = {
		enable: true,
		loadAllPages: false,
	}
) => {
	const { api, authentication } = useServices();

	const isLoggedIn = authentication.isAuthenticated();

	const announcements = useInfiniteQuery<
		AnnouncementsResponse,
		BaseHttpError,
		Announcement[]
	>(
		announcementKeys.list(communityId, type),
		({ pageParam = null, signal }) => {
			return api.getAnnouncements(communityId, [type], {
				pagination: {
					...pageParam,
					limit: options.loadAllPages ? -1 : undefined,
				},
				signal,
			});
		},
		{
			enabled: !!communityId && isLoggedIn && options.enable,
			select: response => {
				return {
					pages: response.pages.map(page => page.data),
					pageParams: response.pageParams,
				};
			},
			getPreviousPageParam: firstPage =>
				getPageParams(firstPage.pagination).previousParam,
			getNextPageParam: lastPage =>
				getPageParams(lastPage.pagination).nextParam,
			notifyOnChangeProps: 'tracked',
			refetchOnMount: true,
		}
	);

	return announcements;
};

export const useGetPublishedAnnouncements = (
	communityId: string,
	options: { enable: boolean } = { enable: true }
) => {
	const published = useGetAnnouncements(communityId, 'published', {
		enable: options.enable,
		loadAllPages: false,
	});

	return published;
};
