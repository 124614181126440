import { SetupMutationFn } from 'core/configure/types';
import { BaseHttpError, Services } from 'core/services';
import { postPasswordRequestMutationKey } from 'core/utils/mutation-key-factory';
import { cancelPreviousMutation } from 'core/utils/optimistic-utils';
import { useMutation } from 'react-query';

interface PostResetPasswordMutationInput {
	email: string;
}

export const usePostResetPassword = () => {
	const mutation = useMutation<
		string,
		BaseHttpError,
		PostResetPasswordMutationInput
	>(postPasswordRequestMutationKey);

	return mutation;
};

type MutationContext = {
	email: string;
};

export const setupPostResetPassword: SetupMutationFn = (
	services: Services,
	createTrackedParallelMutation
) => {
	const { queryClient, api } = services;

	const mutation = createTrackedParallelMutation<
		string,
		BaseHttpError,
		PostResetPasswordMutationInput,
		MutationContext
	>({
		mutationFn: ({ email }) =>
			api
				.postResetPasswordRequest(email)
				.then(response => response.data.message),
		onMutate: async input => {
			const { email } = input;

			await cancelPreviousMutation<MutationContext>(
				queryClient,
				mutation => {
					return (
						mutation.state.context?.email === email &&
						mutation?.options?.mutationKey ===
							postPasswordRequestMutationKey
					);
				}
			);

			return input;
		},
		retry: 1,
	});

	queryClient.setMutationDefaults(postPasswordRequestMutationKey, mutation);
};
