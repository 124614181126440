import config from 'config';
import { StrictNullable, Location } from 'core/types';
import { useControlledValue } from 'hooks';
import { useState, useEffect, FocusEvent } from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';
import { TextField, TextFieldProps } from '../text-field';

export type LocationValue = StrictNullable<Location>;

type LocationFieldProps = Omit<
	TextFieldProps,
	| 'value'
	| 'onChange'
	| 'multiline'
	| 'type'
	| 'rows'
	| 'maxRows'
	| 'minRows'
	| 'select'
	| 'SelectProps'
	| 'type'
	| 'defaultValue'
> & {
	value?: LocationValue;
	onChange?: (value: LocationValue) => void;
};

export const LocationField = (props: LocationFieldProps) => {
	const { value: incomingValue = null, onChange, ...rest } = props;

	const [value, setValue] = useControlledValue<LocationValue>(
		incomingValue,
		onChange
	);

	const [pickedLocation, setPickedLocation] = useState<LocationValue>(null);

	useEffect(() => {
		if (pickedLocation) {
			setValue(pickedLocation);
		}
	}, [pickedLocation]);

	const onBlurHandler = (event: FocusEvent) => {
		(event.target as HTMLInputElement).value = '';
	};

	const { ref } = usePlacesWidget({
		inputAutocompleteValue: value?.address,
		apiKey: config.google.placesApiKey,
		onPlaceSelected: place => {
			if (!place.geometry) return;

			if (!place.address_components) return;

			const location = {
				name: place.name || '',
				address: place.formatted_address || '',
				latitude: place.geometry.location.lat() || '',
				longitude: place.geometry.location.lng() || '',
				zip: '',
				state: '',
				city: '',
				country: '',
			};

			for (let i = 0; i < place.address_components.length; i++) {
				const isType = (type: string) =>
					place.address_components[i].types.includes(type);

				if (isType('postal_code')) {
					location.zip = place.address_components[i].short_name;
				} else if (isType('administrative_area_level_1')) {
					location.state = place.address_components[i].short_name;
				} else if (isType('locality')) {
					location.city = place.address_components[i].short_name;
				} else if (isType('country')) {
					location.country = place.address_components[i].short_name;
				}
			}

			setPickedLocation(location);
		},
		language: 'en',
		options: {
			types: ['geocode'],
			fields: [
				'address_component',
				'geometry.location',
				'formatted_address',
				'name',
			],
		},
	});

	return <TextField {...rest} inputRef={ref} onBlur={onBlurHandler} />;
};
