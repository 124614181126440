import { useMsal } from '@azure/msal-react';
import { Button } from 'components';
import { useImportMicrosoftEmails } from 'components/community/hooks';
import { useServices, useTranslation } from 'hooks';
import { SxProps } from '@mui/system';
import config from 'config';
import { BrowserAuthError } from '@azure/msal-browser';

export const ImportMicrosoftEmailsButton = ({
	onSubmit,
	sx,
}: {
	onSubmit: (contacts: string[]) => void;
	sx?: SxProps;
}) => {
	const { instance, inProgress } = useMsal();
	const importEmails = useImportMicrosoftEmails(onSubmit);
	const { t } = useTranslation();
	const { notification } = useServices();

	const showEmails = async () => {
		try {
			const accessTokenResponse = await instance.loginPopup({
				scopes: ['Contacts.Read'],
			});

			importEmails(accessTokenResponse.accessToken);
		} catch (error) {
			if (
				error instanceof BrowserAuthError &&
				error.errorCode === 'user_cancelled'
			) {
				return;
			}

			notification.show('error', t('community-form.could-not-import'));
		}
	};

	if (!config.microsoft.auth.clientId) {
		return null;
	}

	return (
		<Button
			variant='outlined'
			onClick={showEmails}
			disabled={inProgress !== 'none'}
			sx={sx}
		>
			{t('community-form.outlook')}
		</Button>
	);
};
