import { AxiosRequestConfig } from 'axios';
import { AuthenticationInterface } from 'core/services/types';

export class AuthMiddleware {
	authentication: AuthenticationInterface;

	constructor(authentication: AuthenticationInterface) {
		this.authentication = authentication;
	}

	async onRequest(config: AxiosRequestConfig): Promise<AxiosRequestConfig> {
		const headerObjects = { ...config.headers };

		const token = this.authentication.getAccessToken();

		if (token) {
			headerObjects['X-ACCESS-TOKEN'] = `${token}`;
		}

		return {
			...config,
			headers: headerObjects,
		};
	}
}
