import { Box, Paper, CircularProgress, PageHeading } from 'components/common';
import {
	useErrorHandler,
	useParams,
	useRouter,
	useServices,
	useSubmitHandlerForPausedMutation,
	useTranslation,
} from 'hooks';
import { useGetNote, usePatchNote } from 'core/uses-cases/note';
import {
	NoteForm,
	NoteFormSubmittedValues,
	useDeleteNoteHandler,
	useNoteFormData,
} from 'components/notes';

export const EditNote = () => {
	const { t } = useTranslation();

	const { communityId, noteId } = useParams() as {
		communityId: string;
		noteId: string;
	};

	const router = useRouter();

	const { notification } = useServices();

	const noteQuery = useGetNote(communityId, noteId);

	const { mutateAsync, isPaused } = usePatchNote();

	const handleOnSubmit =
		useSubmitHandlerForPausedMutation<NoteFormSubmittedValues>(
			isPaused,
			values =>
				mutateAsync({
					...values,
					communityId,
					noteId,
					createdDt: noteQuery.data?.created_dt,
				}),
			{
				onSuccess: ({ values }) => {
					notification.show(
						'success',
						t('notes.note-was-successfully-updated', {
							title: values.title,
						})
					);

					router.navigate('community.notes', {
						communityId,
					});
				},
			},
			[communityId, noteId, noteQuery.data]
		);

	const { initialValues, groups } = useNoteFormData(noteQuery?.data);

	const deleteNoteHandler = useDeleteNoteHandler({
		onConfirm: () =>
			router.navigate('community.notes', {
				communityId,
			}),
	});

	const handleOnDelete = () => {
		if (!noteQuery.data) return;

		deleteNoteHandler({
			noteId: noteQuery.data.uuid,
			communityId,
		});
	};

	useErrorHandler(noteQuery.error, {
		redirectTo: router.getPathFor('community.notes', {
			communityId,
		}),
	});

	if (noteQuery.isLoading) {
		return (
			<Box display='flex' justifyContent='center' mt={4}>
				<CircularProgress message={t('common.loading')} />
			</Box>
		);
	}

	return (
		<Box>
			<PageHeading title={t('notes.edit-note')} />
			<Box component={Paper} elevation={0} mt={6} px={5} py={5}>
				{noteQuery.isSuccess && noteQuery.data && (
					<>
						<NoteForm
							onSubmit={handleOnSubmit}
							submitText={t('note-form.update-note')}
							submittingText={t('note-form.updating-note')}
							initialValues={initialValues}
							groups={groups}
							onDelete={handleOnDelete}
							deleteText={t('note-form.delete-note')}
						/>
					</>
				)}
			</Box>
		</Box>
	);
};
