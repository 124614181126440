import { BaseHttpError } from 'core/services/api/errors';
import { AlbumPhoto } from 'core/types';
import { PaginatedRecords } from 'core/utils/optimistic-utils';
import { albumPhotoKeys } from 'core/utils/query-key-factory';
import { useServices } from 'hooks';
import { useCallback } from 'react';
import { onlineManager, useQuery, useQueryClient } from 'react-query';

export const useGetPhoto = (
	photoId: string,
	communityId: string,
	albumId: string,
	isDeleting: boolean
) => {
	const { api, authentication } = useServices();
	const isLoggedIn = authentication.isAuthenticated();
	const queryClient = useQueryClient();
	const getInitialData = useCallback(() => {
		let photo;

		if (onlineManager.isOnline()) {
			return photo;
		}

		const result = queryClient.getQueryData<PaginatedRecords<AlbumPhoto>>(
			albumPhotoKeys.list(communityId)
		);

		for (const page of result?.pages ?? []) {
			photo = page.data.find(record => record.uuid === photoId);

			if (photo) {
				break;
			}
		}

		return photo;
	}, [photoId]);

	const photoQuery = useQuery<AlbumPhoto, BaseHttpError>(
		albumPhotoKeys.detail(communityId, photoId),
		() => api.getPhoto(albumId, photoId).then(response => response.data),
		{
			enabled: !!photoId && isLoggedIn && !isDeleting,
			initialData: getInitialData,
		}
	);

	return photoQuery;
};
