import { Event } from 'core/types';
import { updateRecordCacheForOfflineMode } from 'core/utils/optimistic-utils';
import { calendarKeys } from 'core/utils/query-key-factory';
import produce from 'immer';
import { QueryClient } from 'react-query';
import { EventsCache } from './use-get-events';

export const removeEventsFromCache = (
	queryClient: QueryClient,
	calendarId: string,
	eventIds: string[]
) => {
	queryClient.setQueryData<EventsCache>(
		calendarKeys.all(calendarId),
		currentCache => {
			const cache = currentCache
				? currentCache
				: { byId: {}, allIds: [] };

			return produce(cache, draft => {
				eventIds.forEach(eventId => {
					delete draft.byId[eventId];
				});

				draft.allIds = draft.allIds.filter(
					id => !eventIds.includes(id)
				);
			});
		}
	);
};

export const saveEventsToCache = async (
	queryClient: QueryClient,
	calendarId: string,
	events: Event[]
) => {
	queryClient.setQueryData<EventsCache>(
		calendarKeys.all(calendarId),
		currentCache => {
			const cache = currentCache
				? currentCache
				: { byId: {}, allIds: [] };

			return produce(cache, draft => {
				events.forEach(event => {
					draft.byId[event.uuid] = event;
				});
				draft.allIds.push(...events.map(event => event.uuid));
			});
		}
	);

	for (const event of events) {
		await updateRecordCacheForOfflineMode(
			queryClient,
			calendarKeys.detail(calendarId, event.uuid),
			event
		);
	}
};

export const updateSingleEventInCache = (
	queryClient: QueryClient,
	calendarId: string,
	event: Event
) => {
	queryClient.setQueryData<EventsCache>(
		calendarKeys.all(calendarId),
		currentCache => {
			const cache = currentCache
				? currentCache
				: { byId: {}, allIds: [] };

			return produce(cache, draft => {
				draft.byId[event.uuid] = event;
			});
		}
	);
};
