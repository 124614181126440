import React from 'react';
import {
	Field as FinalFormField,
	FieldMetaState,
	useField,
} from 'react-final-form';

export interface FieldProps
	extends React.ComponentProps<typeof FinalFormField> {
	label?: string;
	validateImmediately?: boolean;
}

const composeEventFns =
	(...fns: any[]) =>
		(e: any) =>
			fns.forEach(fn => fn(e)); // prettier-ignore

const defaultOnFocus = () => undefined;
const defaultOnBlur = () => undefined;

export const Field = React.forwardRef(function Field<T>(
	props: FieldProps,
	ref: React.ForwardedRef<T>
) {
	const {
		name,
		afterSubmit,
		allowNull,
		beforeSubmit,
		data,
		defaultValue,
		format,
		formatOnBlur,
		initialValue,
		isEqual,
		multiple,
		parse,
		subscription,
		type,
		validate,
		validateFields,
		component,
		disabled,
		onFocus: customOnFocus = defaultOnFocus,
		onBlur: customOnBlur = defaultOnBlur,
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		render,
		children,
		helperText,
		validateImmediately = false,
		hideErrorMessage = false,
		...rest
	} = props;

	const { input, meta } = useField(name, {
		afterSubmit,
		allowNull,
		beforeSubmit,
		data,
		defaultValue,
		format,
		formatOnBlur,
		initialValue,
		isEqual,
		multiple,
		parse,
		subscription,
		type,
		validate,
		validateFields,
	});

	if (!component) return null;

	const Component = component as any;

	const { touched, error, submitError } = meta;

	const showError = !disabled && !!(validateImmediately || touched);

	return (
		<Component
			data-test-id={`field-${name.replaceAll('.', '_')}`}
			{...input}
			{...rest}
			disabled={disabled}
			ref={ref}
			onBlur={composeEventFns(input.onBlur, customOnBlur)}
			onFocus={composeEventFns(input.onFocus, customOnFocus)}
			error={!!(showError && (error || submitError))}
			helperText={renderHelperText(
				{ touched, error: showError && error, submitError },
				helperText,
				validateImmediately,
				hideErrorMessage
			)}
			meta={meta}
		>
			{children}
		</Component>
	);
});

export const FieldForSubscription = FinalFormField;

Field.displayName = 'Field';

export function renderHelperText(
	meta: FieldMetaState<unknown>,
	helperText?: string | null | React.ReactNode,
	validateImmediately?: boolean,
	hideErrorMessage?: boolean
) {
	const { touched, error, submitError } = meta;

	if (hideErrorMessage) {
		return null;
	}

	let text = null;

	if (
		(submitError || (error && typeof error === 'string')) &&
		(touched || validateImmediately)
	) {
		text = (
			<>
				{error || submitError} {helperText ? <br /> : null}
			</>
		);
	}

	if (helperText) {
		if (text) {
			text = (
				<>
					{text} {helperText}
				</>
			);
		} else {
			text = helperText;
		}
	}

	return text;
}
