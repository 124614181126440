import { useServices } from 'hooks';
import { useMutation } from 'react-query';

type RequestDeletionMutationInput = {
	email: string;
};

export const useRequestDeletion = () => {
	const { api } = useServices();

	const { mutateAsync: doRequestDeletionAsync } = useMutation(
		({ email }: RequestDeletionMutationInput) => {
			return api.requestDeletion(email);
		}
	);

	return { doRequestDeletionAsync };
};
