import { useStore } from 'store';
import { CommunityDropDownMenu } from 'components/layout/community/header';
import { Box } from '../../../common';
import { AccountMenu } from './account-menu';
import { Header, HeaderLogo } from '../../shared';
import { PublicMenu } from './public-menu';

export const AppHeader: React.FC = () => {
	const { auth } = useStore();

	return auth.isLoggedIn ? (
		<Header
			headerLeft={
				<Box display='flex' alignItems='center'>
					<HeaderLogo />
					<CommunityDropDownMenu />
				</Box>
			}
			headerRight={<AccountMenu />}
		/>
	) : (
		<Header headerRight={<PublicMenu />} />
	);
};
