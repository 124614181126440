import {
	Box,
	Typography,
	Link,
	Icon,
	Button,
	useCardMenu,
} from 'components/common';
import { Event, Identity } from 'core/types';
import {
	useImageServer,
	useTranslation,
	useVerifyActionsWithLabels,
} from 'hooks';
import { SxProps } from '@mui/system';
import { containerStyle, itemsStyle } from './styles';
import { BasicEventInfo } from './basic-event-info';

export type EventDetailCardProps = {
	identity: Identity;
	event: Event;
	isIdentityVolunteer: (identity: Identity) => boolean;
	onDelete?: (event: Event) => void;
	onEdit?: (event: Event) => void;
	onDuplicate?: (event: Event) => void;
	onVolunteer?: (identity: Identity) => void;
	onUnVolunteer?: (identity: Identity) => void;
	disableActions?: boolean;
	canVolunteer?: boolean;
};

export const EventDetailCard = (props: EventDetailCardProps) => {
	const {
		identity,
		event,
		onDelete,
		onEdit,
		onDuplicate,
		isIdentityVolunteer,
		onVolunteer,
		onUnVolunteer,
		disableActions,
		canVolunteer,
	} = props;

	const { t } = useTranslation();

	const eventImage = useImageServer(event.image, {
		w: 300,
		h: 225,
		fit: 'max',
	});

	const actions = useVerifyActionsWithLabels(
		{
			type: 'task',
			identity_permissions: event.identity_permissions,
		},
		[
			{ action: 'update', handler: () => onEdit?.(event), key: 'onEdit' },
			{
				action: 'create',
				handler: () => onDuplicate?.(event),
				key: 'onDuplicate',
			},
			{
				action: 'delete',
				handler: () => onDelete?.(event),
				key: 'onDelete',
			},
		],
		{
			onEdit: {
				label: t('event.edit'),
				icon: 'edit',
			},
			onDuplicate: {
				label: t('event.duplicate'),
				icon: 'content_copy',
			},
			onDelete: {
				label: t('event.delete'),
				icon: 'delete',
			},
		}
	);

	const menuAction = useCardMenu(actions);

	const locations = event?.locations ?? [];
	const assets = event?.asset_collection?.assets ?? [];

	return (
		<Box sx={[containerStyle, itemsStyle] as SxProps}>
			{/* Event Basic Info Sections */}
			<Box display='flex' alignItems='flex-start'>
				<Box flex={1} alignItems='center'>
					<BasicEventInfo event={event} identity={identity} />
				</Box>
				<Box>{menuAction}</Box>
			</Box>
			{/* Event Volunteers Sections */}
			{event.volunteers_needed > 0 && (
				<>
					<Box>
						<Typography variant='h3'>
							{t('event.volunteer-needed', {
								count: event.volunteers_needed,
							})}
						</Typography>
						<Typography variant='h5'>
							{t('event.volunteer-spots-left', {
								count: event.volunteers_available,
							})}
						</Typography>
					</Box>
					{/* Volunteer Actions */}
					<Box>
						{
							canVolunteer &&
							event.volunteers_available > 0 &&
							!isIdentityVolunteer(identity) && (
								<Button
									size='small'
									startIcon={<Icon name='add_circle' />}
									onClick={() => onVolunteer?.(identity)}
									disabled={disableActions}
								>
									{t('event.volunteer')}
								</Button>
							) // prettier-ignore
						}
						{
							isIdentityVolunteer(identity) && (
								<Button
									size='small'
									color='error'
									startIcon={<Icon name='cancel' />}
									onClick={() => onUnVolunteer?.(identity)}
									disabled={disableActions}
								>
									{t('event.remove-my-commitment')}
								</Button>
							) // prettier-ignore
						}
					</Box>
				</>
			)}
			{/* Event Description */}
			<Box>
				<Typography variant='h3'>{t('event.description')}</Typography>
				<Typography
					variant='h5'
					dangerouslySetInnerHTML={{ __html: event.description }}
					className='description'
				/>
			</Box>
			{/* Location */}
			<Box>
				<Typography variant='h3'>
					{t('event.location', { count: locations.length })}
				</Typography>
				{locations.map(location => (
					<Box key={location.uuid}>
						<Typography variant='h5' className='location-item'>
							<Link
								href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
									location.address
								)}`}
								target='_blank'
								rel='noopener noreferrer'
							>
								{location.address}
							</Link>
						</Typography>
					</Box>
				))}
				{locations.length === 0 && (
					<Typography variant='h5'>
						{t('event.no-location-available')}
					</Typography>
				)}
			</Box>
			{/* Event Image and Assets */}
			{(eventImage || assets.length > 0) && (
				<Box>
					<Box
						display='flex'
						flexDirection={{ xs: 'column', md: 'row' }}
					>
						{eventImage && (
							<Box sx={{ flex: 1 / 2 }}>
								<Typography variant='h3'>
									{t('event.images')}
								</Typography>
								<Box className='image-container'>
									<img src={eventImage.url} />
								</Box>
							</Box>
						)}
						{assets.length > 0 && (
							<Box sx={{ flex: 1 / 2 }}>
								<Typography variant='h3'>
									{t('event.attachments')}
								</Typography>
								<Box>
									{assets.map(asset => (
										<Box key={asset.uuid}>
											<Typography variant='h6'>
												<Link
													href={asset.url}
													target='_blank'
													rel='noopener noreferrer'
												>
													{asset.name}
												</Link>
											</Typography>
										</Box>
									))}
								</Box>
							</Box>
						)}
					</Box>
				</Box>
			)}
		</Box>
	);
};
