import { Nullable, Image } from 'core/types';

export const selectImage = (
	image: Nullable<Image>,
	defaultUrl?: string
): Nullable<Image> => {
	if (image || (!image && !defaultUrl)) return image;

	return {
		uuid: '',
		name: '',
		width: 0,
		height: 0,
		size: 0,
		created_dt: '',
		url: defaultUrl || '',
		file: null,
	};
};
