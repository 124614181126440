import { selectMyCommunities } from 'core/selectors';
import { useServices } from 'core/services';
import { BaseHttpError } from 'core/services/api/errors';
import { CommunitiesResponse } from 'core/services/api/response-types';
import { Community } from 'core/types';
import { getPageParams } from 'core/utils/pagination';
import { communityKeys } from 'core/utils/query-key-factory';
import { useInfiniteQuery, useQuery } from 'react-query';
import { useCurrentIdentity } from 'core/uses-cases/authentication';

type Options = { enabled: boolean };
export const useGetMyCommunities = (options: Options = { enabled: true }) => {
	const { api, authentication } = useServices();
	const identity = useCurrentIdentity();

	const isLoggedIn = authentication.isAuthenticated();

	const isEnabled = !!identity && isLoggedIn && options.enabled;

	return useInfiniteQuery<CommunitiesResponse, BaseHttpError, Community[]>(
		communityKeys.list('my_communities'),
		({ pageParam = null, signal }) => {
			return api.getMyCommunities(identity?.uuid ?? '', undefined, {
				pagination: pageParam,
				signal,
			});
		},
		{
			enabled: isEnabled,
			staleTime: 1000 * 60 * 5, // 5 minutes,
			select: response => {
				return {
					pages: response.pages.map(page =>
						selectMyCommunities(page.data)
					),
					pageParams: response.pageParams,
				};
			},
			getPreviousPageParam: firstPage =>
				getPageParams(firstPage.pagination).previousParam,
			getNextPageParam: lastPage =>
				getPageParams(lastPage.pagination).nextParam,
			notifyOnChangeProps: 'tracked',
			refetchOnMount: true,
		}
	);
};

export const useGetInvitedCommunities = (
	options: Options = { enabled: true }
) => {
	const { api, authentication } = useServices();
	const identity = useCurrentIdentity();
	const isLoggedIn = authentication.isAuthenticated();

	const isEnabled = !!identity && isLoggedIn && options.enabled;

	return useQuery<CommunitiesResponse, BaseHttpError, Community[]>(
		communityKeys.list('invited'),
		({ signal }) => {
			return api.getMyCommunities(identity?.uuid ?? '', 'invited', {
				signal,
			});
		},
		{
			enabled: isEnabled,
			staleTime: 1000 * 60 * 5, // 5 minutes,
			select: response => selectMyCommunities(response.data),
			notifyOnChangeProps: 'tracked',
			refetchOnMount: true,
		}
	);
};

export const useGetPendingCommunities = (
	options: Options = { enabled: true }
) => {
	const { api, authentication } = useServices();
	const identity = useCurrentIdentity();
	const isLoggedIn = authentication.isAuthenticated();

	const isEnabled = !!identity && isLoggedIn && options.enabled;

	return useQuery<CommunitiesResponse, BaseHttpError, Community[]>(
		communityKeys.list('pending'),
		({ signal }) => {
			return api.getMyCommunities(identity?.uuid ?? '', 'pending', {
				signal,
			});
		},
		{
			enabled: isEnabled,
			staleTime: 1000 * 60 * 5, // 5 minutes,
			select: response => selectMyCommunities(response.data),
			notifyOnChangeProps: 'tracked',
			refetchOnMount: true,
		}
	);
};
