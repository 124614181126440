import { Services } from 'core/services/types';
import {
	setupPostAnnouncement,
	setupDeleteAnnouncement,
	setupPatchAnnouncement,
} from 'core/uses-cases/announcement';
import {
	setupDeleteNote,
	setupPatchNote,
	setupPostNote,
} from 'core/uses-cases/note';
import {
	setupPostAlbumPhoto,
	setupPostCommunityPhoto,
	setupDeleteAlbumPhoto,
} from 'core/uses-cases/photo';
import {
	setupDeleteWellWish,
	setupPatchWellWish,
	setupPostWellWish,
} from 'core/uses-cases/well-wish';
import {
	createMutationTracker,
	createTrackedParallelMutationCreator,
} from 'core/utils/mutation-tracker';
import { setupPatchIdentity } from 'core/uses-cases/identity';
import {
	setupDeleteEvent,
	setupPostVolunteer,
	setupRemoveVolunteer,
	setupDuplicateEvent,
	setupPathEvent,
	setupPostEvents,
} from 'core/uses-cases/calendar';
import { setupPatchPhoto } from 'core/uses-cases/photo/use-patch-photo';
import {
	setupPatchAlbum,
	setupPostAlbum,
	setupDeleteAlbum,
} from 'core/uses-cases/albums';
import { setupPatchAlbumOrder } from 'core/uses-cases/albums/use-patch-album-order';
import { setupPostResetPassword } from 'core/uses-cases/password/use-post-reset-password';
import {
	setupDeleteCommunityMember,
	setupDeleteFailedInvites,
	setupPatchDeclinedInvites,
	setupPatchPendingInvites,
	setupPatchPendingRequests,
	setupPutGlobalPermissions,
	setupUpdateCommunityImage,
} from 'core/uses-cases/community';
import {
	setupPostComment,
	setupDeleteComment,
	setupPatchComment,
} from 'core/uses-cases/discussion';
import {
	setupDeleteCommunityGroup,
	setupPostCommunityGroup,
	setupPutCommunityGroup,
	setupPatchCommunityGroup,
	setupManageCommunityGroup,
} from 'core/uses-cases/group';
import {
	setupDeleteCommunityTransfer,
	setupInitiateTransferCommunity,
} from 'core/uses-cases/transfer-request';
import { setupPatchCommunityMember } from 'core/uses-cases/member/use-patch-community-member';

export const prepareMutations = (services: Services) => {
	const mutationTracker = createMutationTracker(services.queryClient);

	const createTrackedParallelMutation =
		createTrackedParallelMutationCreator(mutationTracker);

	[
		setupPostAnnouncement,
		setupDeleteAnnouncement,
		setupPatchAnnouncement,
		setupDeleteNote,
		setupPatchNote,
		setupPostNote,
		setupDeleteWellWish,
		setupPatchWellWish,
		setupPostWellWish,
		setupPostEvents,
		setupDeleteEvent,
		setupPathEvent,
		setupPostAlbumPhoto,
		setupPatchPhoto,
		setupPostAlbum,
		setupPatchAlbum,
		setupPatchAlbumOrder,
		setupPostCommunityPhoto,
		setupDeleteAlbumPhoto,
		setupPatchIdentity,
		setupPostVolunteer,
		setupRemoveVolunteer,
		setupDuplicateEvent,
		setupPostResetPassword,
		setupInitiateTransferCommunity,
		setupDeleteCommunityTransfer,
		setupPostComment,
		setupDeleteComment,
		setupPatchComment,
		setupPatchPendingRequests,
		setupPatchPendingInvites,
		setupPatchDeclinedInvites,
		setupDeleteFailedInvites,
		setupDeleteCommunityGroup,
		setupPostCommunityGroup,
		setupPutCommunityGroup,
		setupPatchCommunityGroup,
		setupManageCommunityGroup,
		setupPutGlobalPermissions,
		setupDeleteAlbum,
		setupPatchCommunityMember,
		setupDeleteCommunityMember,
		setupUpdateCommunityImage,
	].forEach(mutation => {
		mutation.call(
			mutation,
			services,
			createTrackedParallelMutation,
			mutationTracker
		);
	});
};
