import Box from '@mui/material/Box';
import { Grid } from 'components';
import { useCalendar } from 'components/calendar/context';
import { calendarUtils, getRangeOfDates } from 'components/calendar/utils';
import { Fragment, useEffect, useMemo } from 'react';
import { usePrevious } from 'hooks';
import { LinearProgress } from '@mui/material';
import { Day } from './day';
import { spinnerStyle } from './styles';

export const Widget = () => {
	const { displayed, loadEvents, loadEventsStatus } = useCalendar();

	const daysRange = useMemo(() => {
		const startDate = calendarUtils.getFirstDayOfWeek(displayed);
		const endDate = calendarUtils.getLastDayOfWeek(displayed);
		const daysRange = getRangeOfDates(startDate, endDate);
		return daysRange;
	}, [displayed]);

	const prevDisplayed = usePrevious(displayed);

	useEffect(() => {
		// Load +/- 1 week of the current week in the first render.
		const startDate = calendarUtils.getFirstDayOfWeek(
			displayed.minus({ weeks: 1 })
		);
		const endDate = calendarUtils.getLastDayOfWeek(
			displayed.plus({ weeks: 1 })
		);

		loadEvents({ startDate, endDate });
	}, []);

	useEffect(() => {
		if (!prevDisplayed) return;

		if (displayed > prevDisplayed) {
			// Preload next week and current
			const startDate = calendarUtils.getFirstDayOfWeek(displayed);
			const endDate = calendarUtils.getLastDayOfWeek(
				displayed.plus({ weeks: 1 })
			);
			loadEvents({ startDate, endDate });
		} else if (displayed < prevDisplayed) {
			// preload prev week and current
			const startDate = calendarUtils.getFirstDayOfWeek(
				displayed.minus({ weeks: 1 })
			);
			const endDate = calendarUtils.getLastDayOfWeek(displayed);
			loadEvents({ startDate, endDate });
		}
	}, [displayed, prevDisplayed]);

	return (
		<Box mt={1.5} position='relative'>
			<Grid container spacing={0}>
				{daysRange.map(day => (
					<Fragment key={`week-header-${day.toISO()}`}>
						<Grid item xs={12 / 7}>
							<Day day={day} />
						</Grid>
					</Fragment>
				))}
			</Grid>
			{loadEventsStatus === 'loading' && (
				<Box sx={spinnerStyle}>
					<LinearProgress />
				</Box>
			)}
		</Box>
	);
};
