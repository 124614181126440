import { RefObject, RefCallback } from 'react';

type ReactRef<T> = RefCallback<T> | RefObject<T> | null;

export function useMergeRefs<T = any>(
	refs: ReactRef<T>[]
): React.MutableRefObject<T | null> {
	let current: T | null = null;

	return {
		get current() {
			return current as T;
		},
		set current(value: T) {
			const last = current;

			if (last !== value) {
				current = value;

				refs.forEach(ref => {
					if (typeof ref === 'function') {
						ref(value);
					} else if (ref != null) {
						(ref as React.MutableRefObject<T | null>).current =
							value;
					}
				});
			}
		},
	};
}
