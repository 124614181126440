import { SxProps } from '@mui/system';
import { COLORS } from 'components/calendar/constants';
import { Theme } from 'theme';

export const dotIndicatorStyle: SxProps<Theme> = {
	borderRadius: '100%',

	'&.needs-met': {
		backgroundColor: COLORS.needsMet,
	},
	'&.help-needed': {
		backgroundColor: COLORS.helpNeeded,
	},

	'&.occasions': {
		backgroundColor: COLORS.occasions,
	},

	'&.small': {
		mx: 0.2,
		width: 8,
		height: 8,
	},

	'&.large': {
		mx: 0.2,
		width: 16,
		height: 16,
	},
};

export const dayStyle: SxProps<Theme> = theme => ({
	paddingTop: 3,
	flexDirection: 'column',
	display: 'flex',
	alignItems: 'center',
	borderRadius: 2,
	cursor: 'pointer',
	'&:hover': {
		backgroundColor: theme.secondaryColors.secondary10,
	},

	'& > .indicators': {
		mt: 2,
		minHeight: 8,
		height: 24,
		whiteSpace: 'nowrap',
		display: 'flex',
	},
});

export const eventListItemStyle: SxProps<Theme> = {
	marginTop: 3,
	display: 'flex',
	alignItems: 'flex-start',

	'& .event-info': {
		'&:hover > *': {
			cursor: 'pointer',
			textDecoration: 'underline',
		},

		'& > p:first-of-type': {
			fontWeight: '800',
			lineHeight: '16px',
		},
	},
};

export const infoContainerStyle: SxProps<Theme> = theme => ({
	padding: theme.spacing(1, 3, 1, 0),
	marginTop: 1,
	borderRadius: 2,
	backgroundColor: '#3E2B26',
});

export const spinnerStyle: SxProps<Theme> = {
	position: 'absolute',
	bottom: 0,
	width: '100%',
};
