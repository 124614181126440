export const extractAfterParamFromUrl = (url: string) => {
	const decodedUrl = decodeURIComponent(url);

	const urlParts = decodedUrl.split('?');
	const queryString = urlParts[1] ?? '';
	const match = /after(\[uuid\])?=(?<id>.+?(?=$|&|\?))/gm.exec(queryString);
	return match?.groups?.id ?? undefined;
};

export const extractBeforeParamFromUrl = (url: string) => {
	const decodedUrl = decodeURIComponent(url);

	const urlParts = decodedUrl.split('?');
	const queryString = urlParts[1] ?? '';
	const match = /before(\[uuid\])?=(?<id>.+?(?=$|&|\?))/gm.exec(queryString);
	return match?.groups?.id ?? undefined;
};

export const getPageParams = (pagination: {
	next: string;
	previous: string;
}) => {
	const afterUuid = extractAfterParamFromUrl(pagination.next);
	const beforeUuid = extractBeforeParamFromUrl(pagination.previous);

	return {
		nextParam: afterUuid ? { after: afterUuid } : undefined,
		previousParam: beforeUuid ? { before: beforeUuid } : undefined,
	};
};
