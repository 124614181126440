import { useGetTimezones } from 'core/uses-cases/timezone';
import { useMemo, useContext } from 'react';
import { TimezonesContext } from './context';

export const TimezonesProvider: React.FC = ({ children }) => {
	const { data = [] } = useGetTimezones();

	const renderedContent = useMemo(() => children, []);

	return (
		<TimezonesContext.Provider value={data}>
			{renderedContent}
		</TimezonesContext.Provider>
	);
};

export const useTimezones = () => {
	const timezones = useContext(TimezonesContext);

	if (timezones === undefined) {
		throw new Error('useTimezones must be used within TimezonesProvider');
	}

	return timezones;
};
