import { ApiConfig } from 'core/services/types';
import { BaseHttpClient } from '../base-http-client';
import { createAuthApi } from './auth-api';
import { createIdentityApi } from './identity-api';
import { createCommunityApi } from './community-api';
import { createAlbumApi } from './album-api';
import { createIdentityCommunityApi } from './identity-community';
import { createAssetsApi } from './assets-api';
import { createCommonApi } from './common';
import { createCalendarApi } from './calendar-api';
import { createInvitationApi } from './invitation-api';
import { createRequestToJoinApi } from './request-api';
import { createDiscussionApi } from './discussion-api';
import { createSubscriptionApi } from './subscription-api';
import { createMemberApi } from './member-api';

/**
 * Manages all requests to the API.
 */
export const createClient = (config: ApiConfig) => {
	const http = new BaseHttpClient(config);

	return {
		...http,
		...createAuthApi(http),
		...createIdentityApi(http),
		...createIdentityCommunityApi(http),
		...createCommunityApi(http),
		...createAssetsApi(http),
		...createCommonApi(http),
		...createAlbumApi(http),
		...createCalendarApi(http),
		...createInvitationApi(http),
		...createRequestToJoinApi(http),
		...createDiscussionApi(http),
		...createSubscriptionApi(http),
		...createMemberApi(http),
	};
};

export type ApiClient = ReturnType<typeof createClient>;
