import { useDialog, DialogHookOptions } from 'components/common';
import {
	DeleteAlbumMutationInput,
	useDeleteAlbum,
} from 'core/uses-cases/albums';
import { useServices, useTranslation } from 'hooks';

export const useDeleteAlbumHandler = (
	options?: DialogHookOptions<DeleteAlbumMutationInput>
) => {
	const { t } = useTranslation();
	const { notification } = useServices();
	const { mutate, isLoading } = useDeleteAlbum();

	const { open } = useDialog<DeleteAlbumMutationInput>({
		title: t('albums.delete-confirm-title'),
		content: t('albums.delete-confirm-content'),
		confirmText: t('common.delete'),
		onConfirm: input => {
			if (!input) return;

			mutate(input, {
				onSuccess: () => {
					options?.onConfirm?.(input);

					notification.show(
						'success',
						t('albums.album-was-successfully-deleted')
					);
				},
			});
		},
		onCancel: options?.onCancel,
	});

	return { open, isDeletingPhoto: isLoading };
};
