import { Box, PageHeading, CircularProgress, Paper } from 'components/common';
import {
	useParams,
	useRouter,
	useServices,
	useSubmitHandlerForPausedMutation,
	useTranslation,
	useVerifyAccess,
} from 'hooks';
import { useGetCommunityMember } from 'core/uses-cases/member';
import { useGetMemberSubscriptionPreferences } from 'core/uses-cases/subscription/use-get-member-subscription-preferences';
import { useMemberFormData } from 'components/members/hooks/use-member-form-data';
import { CommunityMember, Preferences } from 'core/types';
import {
	EditMemberForm,
	EditMemberFormSubmittedValues,
} from 'components/members/edit-member-form';
import { usePatchCommunityMember } from 'core/uses-cases/member/use-patch-community-member';
import { useGetCurrentIdentity } from 'core/uses-cases/authentication';
import { Navigate } from 'navigation';
import { PREFERENCES } from 'components/email-preferences/constants';

export const EditMemberProfile = () => {
	const { t } = useTranslation();
	const verify = useVerifyAccess();
	const router = useRouter();
	const canEditGroups = verify('update', 'group');
	const canUpdateMembers = verify('update', 'member');
	const { communityId, identityId } = useParams() as {
		communityId: string;
		identityId: string;
	};
	const { data: identity } = useGetCurrentIdentity();
	const isSameMember = identityId === identity?.uuid;
	const memberQuery = useGetCommunityMember(communityId, identityId);
	const preferencesQuery = useGetMemberSubscriptionPreferences(
		communityId,
		identityId,
		isSameMember
	);
	const { notification } = useServices();
	const { mutateAsync, isPaused } = usePatchCommunityMember();
	const { initialValues, groupsOptions, reminderOptions } = useMemberFormData(
		memberQuery.data as CommunityMember,
		preferencesQuery.data as Preferences
	);

	const handleOnSubmit =
		useSubmitHandlerForPausedMutation<EditMemberFormSubmittedValues>(
			isPaused,
			values => {
				let payload = null;

				if (isSameMember && canUpdateMembers) {
					payload = {
						type: 'sameAndAdmin' as const,
						first_name: values.firstName ?? '',
						last_name: values.lastName ?? '',
						phone: `+${values.phone?.dialCode}${values.phone?.number}`,
						email: values.email ?? '',
						help_provided: values.helpWith,
						event_reminder_time: values.eventReminderTime ?? '',
						announcement_subscription_status:
							values.emailPreferences?.includes(
								PREFERENCES.ANNOUNCEMENT_SUBSCRIPTION
							) ?? false,
						email_digest_subscription_status:
							values.emailPreferences?.includes(
								PREFERENCES.DIGEST_SUBSCRIPTION
							) ?? false,
						digest_frequency: values.digestFrequency ?? 'Daily',
						delivery_time: values.deliveryTime?.hour ?? 19,
						custom_day: values.deliveryDay ?? '',
						custom_days: values.deliveryDays ?? [],
						groups: values.groups ?? [],
						administrative_notes: values.administrativeNotes ?? '',
					};
				} else if (isSameMember) {
					payload = {
						type: 'same' as const,
						first_name: values.firstName ?? '',
						last_name: values.lastName ?? '',
						phone: `+${values.phone?.dialCode}${values.phone?.number}`,
						email: values.email ?? '',
						help_provided: values.helpWith,
						event_reminder_time: values.eventReminderTime ?? '',
						announcement_subscription_status:
							values.emailPreferences?.includes(
								PREFERENCES.ANNOUNCEMENT_SUBSCRIPTION
							) ?? false,
						email_digest_subscription_status:
							values.emailPreferences?.includes(
								PREFERENCES.DIGEST_SUBSCRIPTION
							) ?? false,
						digest_frequency: values.digestFrequency ?? 'Daily',
						delivery_time: values.deliveryTime?.hour ?? 19,
						custom_day: values.deliveryDay ?? '',
						custom_days: values.deliveryDays ?? [],
					};
				} else {
					payload = {
						type: 'admin' as const,
						help_provided: values.helpWith,
						groups: values.groups ?? [],
						administrative_notes: values.administrativeNotes ?? '',
					};
				}

				return mutateAsync({
					memberId: identityId,
					communityId,
					payload,
				});
			},
			{
				onSuccess: () => {
					notification.show('success', t('account.profile-updated'));

					router.navigate('community.team', {
						communityId,
					});
				},
			},
			[identityId]
		);

	const showForm =
		memberQuery.isSuccess && (preferencesQuery.isSuccess || !isSameMember);

	if (!isSameMember && !canUpdateMembers) {
		return (
			<Navigate
				to={router.getPathFor('community.home', {
					communityId,
				})}
				replace
			/>
		);
	}

	if (memberQuery.isLoading || preferencesQuery.isLoading) {
		return (
			<Box display='flex' justifyContent='center' mt={4}>
				<CircularProgress message={t('common.loading')} />
			</Box>
		);
	}

	return (
		<Box>
			<PageHeading title={t('edit-member-profile.header')} />
			{showForm && (
				<Box
					component={Paper}
					px={4.5}
					py={3}
					elevation={0}
					display='flex'
					flexDirection='column'
				>
					<EditMemberForm
						initialValues={initialValues}
						isSameMember={isSameMember}
						canEditGroups={canEditGroups}
						canSeeAdminInfo={canUpdateMembers}
						groupsOptions={groupsOptions}
						reminderOptions={reminderOptions}
						submitText={t('account.update-profile')}
						submittingText={t('account.updating')}
						onSubmit={handleOnSubmit}
					/>
				</Box>
			)}
		</Box>
	);
};
