export const PREFERENCES = {
	ANNOUNCEMENT_SUBSCRIPTION: 'announcement_subscription' as const,
	DIGEST_SUBSCRIPTION: 'digest_subscription' as const,
};

export const DIGEST_FREQUENCY = {
	DAILY: 'Daily' as const,
	WEEKLY: 'Weekly' as const,
	CUSTOM: 'Custom' as const,
};

export const DEFAULT_DELIVERY_HOUR = 19;
