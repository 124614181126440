import { AxiosError } from 'axios';
import { ErrorReportInterface } from 'core/services/types';
import { BaseHttpError } from '../errors';

const silencedEndpointsRegex = [
	/^\/v[a-zA-Z0-9.-_]+\/community\/[a-z0-9-]+\/token\/[a-zA-Z0-9-_]+$/, // Get community token endpoint
];

export class ErrorHandlerMiddleware {
	ignoredTypes = ['canceled', 'too-many-requests'];
	errorHandler?: ErrorReportInterface;

	constructor(errorHandler?: ErrorReportInterface) {
		this.errorHandler = errorHandler;
	}

	onResponseError(error: AxiosError): never {
		let requestUrl = {
			pathname: '',
		};

		try {
			requestUrl = new URL((error.request as XMLHttpRequest).responseURL);
		} catch (e) {
			// Do nothing
		}

		const displayError = !silencedEndpointsRegex.some(
			silencedEndpointRegex =>
				silencedEndpointRegex.test(requestUrl.pathname)
		);

		const httpError = BaseHttpError.fromError(error, displayError);

		if (!this.ignoredTypes.includes(httpError.type ?? '')) {
			this.errorHandler?.recordError(httpError);
		}

		throw httpError;
	}
}
