import config from 'config';
import React, { Suspense } from 'react';
import { suspend } from 'suspend-react';
import { configureAppServices } from 'core/configure';
import { ServicesProvider, ErrorBoundary } from 'core/services';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'components/common/devtools';
import { TimezonesProvider } from 'components/timezones';
import { SplashBase } from 'components';
import { createErrorFallback } from 'components/layout/error/500';

const DefaultFallback = <SplashBase />;

export const Bootstrap: React.FC<{ fallback?: JSX.Element }> = ({
	fallback = DefaultFallback,
	children,
}) => {
	if (
		process.env.NODE_ENV === 'development' &&
		config.debug.whyDidYouRender
	) {
		suspend(
			() =>
				import('@welldone-software/why-did-you-render').then(module => {
					console.log('[Bootstrap]', 'WhyDidYouRender enabled');
					module.default(React, {});
				}),
			['bootstrap']
		);
	}

	const services = suspend(() => configureAppServices(), ['setup-services']);

	return (
		<Suspense fallback={fallback}>
			<QueryClientProvider client={services.queryClient}>
				<ServicesProvider services={services}>
					<ErrorBoundary fallback={createErrorFallback}>
						<TimezonesProvider>{children}</TimezonesProvider>
					</ErrorBoundary>
				</ServicesProvider>
				<ReactQueryDevtools />
			</QueryClientProvider>
		</Suspense>
	);
};
