import { BaseHttpError, useServices } from 'core/services';
import { calendarKeys } from 'core/utils/query-key-factory';
import { useQuery } from 'react-query';

type ExportCalendarSelectData = {
	token: string;
	downloadUrl: string;
};

export const useGetExportCalendarToken = (calendarId: string) => {
	const { api } = useServices();

	return useQuery<string, BaseHttpError, ExportCalendarSelectData>(
		calendarKeys.exportToken(calendarId),
		() =>
			api
				.getExportToken(calendarId)
				.then(response => response.data.token),
		{
			enabled: !!calendarId,
			select: token => {
				return {
					token,
					downloadUrl: `${api.client.defaults.baseURL}calendar/${calendarId}/export/${token}`,
				};
			},
		}
	);
};
