import { Community, Identity } from 'core/types';
import { v4 as uuid } from 'uuid';
import config from 'config';
import { DateTime } from 'luxon';
import defaultImage from 'assets/images/community_profile.svg?url';
import { getCurrentTimeZone } from './dates';
import { getBaseUrl } from './url';

export const getCommunityUrl = (community: Community) => {
	return `${getBaseUrl()}/community/${community.slug}`;
};

export const getCommunityIdFromLocation = () => {
	const { id = '' } =
		window.location.pathname.match(/\/community\/(?<id>[^/]+)/)?.groups ??
		{};

	return id;
};

export const getLotsaAdministrationAccount = (): Identity => {
	return {
		first_name: 'Lotsa',
		last_name: 'Administration',
		uuid: uuid(),
		email: config.supportEmail,
		phone: '1234455566',
		address: null,
		location: null,
		autodetect_timezone: true,
		timezone: getCurrentTimeZone(),
		language: null,
		created_dt: DateTime.now().toISO(),
		image: {
			uuid: null,
			name: '',
			url: defaultImage,
			width: 0,
			height: 0,
			size: 0,
			created_dt: '',
			file: null,
		},
		my_communities: [],
	};
};
