import { AppLayout } from 'components/layout';
import { ComponentType, Fragment, ReactNode } from 'react';
import { useLocation, Outlet, Navigate } from 'react-router-dom';

type GuardProps = {
	useDefaultLayout?: boolean;
	element?: ReactNode;
	layout?: ComponentType;
};

export const PublicGuard = (props: GuardProps) => {
	const {
		useDefaultLayout = true,
		layout: Layout = useDefaultLayout ? AppLayout : Fragment,
		element,
	} = props;

	const location = useLocation() as {
		state?: { from?: { pathname: string } };
	};

	const from = location.state?.from?.pathname || null;

	if (from) return <Navigate to={from} replace />;

	return <Layout>{element ? element : <Outlet />}</Layout>;
};
