import { DateTime } from 'luxon';
import { Fragment } from 'react';
import { Typography, Grid } from 'components/common';
import { EventsBox, useEventsForDateTime } from 'components/calendar/shared';
import { useCalendar } from 'components/calendar/context';
import { timeRowStyle } from '../day/styles';

type TimeRowProps = {
	label: string;
	days: DateTime[];
	time?: DateTime;
};

type TimeDaySlotProps = {
	date: DateTime;
	time?: DateTime;
};

const TimeDaySlot = ({ time, date }: TimeDaySlotProps) => {
	const { onEventClick, setSelected } = useCalendar();

	let dateTime = date;

	if (time) {
		dateTime = date.set({
			hour: time.hour,
			minute: time.minute,
			second: time.second,
		});
	}

	const events = useEventsForDateTime(dateTime, !time);

	return (
		<Grid item xs={11 / 7}>
			<EventsBox
				onClick={() => setSelected(date)}
				events={events}
				onEventClick={onEventClick}
				maxRenderCount={3}
			/>
		</Grid>
	);
};

export const TimeRow = ({ label, days, ...rest }: TimeRowProps) => {
	return (
		<Grid container item spacing={0} sx={timeRowStyle}>
			<Grid item xs={1}>
				<Typography variant='caption'>{label}</Typography>
			</Grid>
			{days.map(day => (
				<Fragment key={`week-header-${day.toISO()}`}>
					<TimeDaySlot {...rest} date={day} />
				</Fragment>
			))}
		</Grid>
	);
};
