import {
	Form,
	Grid,
	LoadingButton,
	Field,
	CheckboxField,
	PasswordField,
	PasswordRulesChecker,
} from 'components';
import { useTranslation } from 'hooks';
import { TFunction } from 'i18next';
import { useCallback, useMemo } from 'react';
import * as Yup from 'yup';
import { sleep } from 'utils/delay';

export type UpdatePasswordFormSubmittedValues = {
	password: string;
	logout: boolean;
};

type UpdatePasswordFormProps = {
	email: string;
	onSubmit?: (values: UpdatePasswordFormSubmittedValues) => Promise<void>;
	submitText?: string;
	submittingText?: string;
};

export const UpdatePasswordForm = (props: UpdatePasswordFormProps) => {
	const { email, onSubmit, submitText, submittingText } = props;

	const { t } = useTranslation();

	const handleOnSubmit = useCallback(
		async values => {
			await sleep(1);

			const result = onSubmit?.(values);

			return Promise.resolve(result).catch(error => error);
		},
		[onSubmit]
	);

	const validationSchema = useMemo(() => createValidationSchema(t), []);

	return (
		<Form
			onSubmit={handleOnSubmit}
			validationSchema={validationSchema}
			subscription={{ submitting: true }}
			initialValues={{ email }}
		>
			{({ handleSubmit, submitting }) => (
				<form onSubmit={handleSubmit} noValidate>
					<Field
						name='email'
						component={(fieldProps: {
							name: string;
							value: string;
						}) => {
							const { name, value } = fieldProps;

							return (
								<input
									type='hidden'
									name={name}
									value={value}
								/>
							);
						}}
					/>
					<Grid container justifyContent='center'>
						<Grid item>
							<Field
								placeholder={t('update-password.new-password')}
								name='password'
								type='password'
								component={PasswordField}
								fullWidth
								required
							/>
						</Grid>
						<Grid item>
							<PasswordRulesChecker
								forField='password'
								usernameField='email'
								initialEmail={email}
								required={false}
							/>
						</Grid>
						<Grid item>
							<Field
								label={t('update-password.logout')}
								name='logout'
								component={CheckboxField}
								fullWidth
								required
							/>
						</Grid>
						<Grid item xs={12} mt={2}>
							<LoadingButton
								loading={submitting}
								loadingIndicator={
									submittingText || t('form.submitting')
								}
								size='small'
								type='submit'
							>
								{submitText || t('form.submit')}
							</LoadingButton>
						</Grid>
					</Grid>
				</form>
			)}
		</Form>
	);
};

function createValidationSchema(t: TFunction) {
	return Yup.object().shape({
		password: Yup.string().required(t('form.generic-required')),
	});
}
