import React, { useMemo } from 'react';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Divider from '@mui/material/Divider';
import Fade from '@mui/material/Fade';
import {
	useGetInvitedCommunities,
	useGetMyCommunities,
	useGetPendingCommunities,
} from 'core/uses-cases/identity';
import joinCommunityIcon from 'assets/images/join-community-icon.svg?url';
import createCommunityIcon from 'assets/images/create-community-icon.svg?url';
import { useRouter, useTranslation } from 'hooks';
import {
	CommunityMenuLink,
	CommunityMenuItem,
	PendingCommunityMenuItem,
} from 'components/community';
import classNames from 'classnames';
import { HeaderLogo } from 'components';
import { getCurrentLanguage } from 'utils/lang';
import {
	Box,
	RouterLink,
	Typography,
	Menu,
	MenuItem,
	ButtonBase,
	MenuItemProps,
	useMediaQuery,
} from '../../../common';
import {
	itemStyle,
	menuPaperStyle,
	dropDownTitleStyle,
	brandTitleStyle,
	arrowMenuIconStyle,
	footerLinkStyle,
	pendingItemStyle,
	currentCommunityContainer,
} from './style';
import {
	useCurrentCommunity,
	useIsLoadingCurrentCommunity,
} from '../community-context';

const ButtonLink = (props: MenuItemProps<typeof RouterLink>) => (
	<MenuItem
		{...props}
		variant='button'
		disableRipple
		sx={footerLinkStyle}
		component={RouterLink}
	/>
);

export const CommunityDropDownMenu = ({ fixed }: { fixed?: boolean }) => {
	const router = useRouter();

	const community = useCurrentCommunity();
	const { t } = useTranslation();
	const isLoadingCommunity = useIsLoadingCurrentCommunity();

	const [anchorElNav, setAnchorElNav] = React.useState<Element>();

	const open = Boolean(anchorElNav);

	const handleOpenNavMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorElNav(event.currentTarget);
	};
	const handleCloseNavMenu = () => {
		setAnchorElNav(undefined);
	};

	const { data: currentCommunities } = useGetMyCommunities();
	const { data: invitedCommunities } = useGetInvitedCommunities();
	const { data: pendingCommunities } = useGetPendingCommunities();

	const lastInvitedCommunities = useMemo(
		() => invitedCommunities?.slice(0, 1) ?? [],
		[invitedCommunities]
	);

	const lastPendingCommunities = useMemo(
		() => pendingCommunities?.slice(0, 1) ?? [],
		[pendingCommunities]
	);

	const currentCommunityCount =
		5 - lastInvitedCommunities.length - lastPendingCommunities.length;

	const lastActiveCommunities = useMemo(
		() =>
			fixed
				? currentCommunities?.pages?.[0]?.slice(
					1,
					currentCommunityCount + 1
				) ?? []
				: currentCommunities?.pages?.[0]?.slice(
					0,
					currentCommunityCount
				) ?? [], // prettier-ignore
		[currentCommunities, fixed, currentCommunityCount]
	);

	const isExtraSmall = useMediaQuery.down('sm');

	return (
		<>
			<ButtonBase
				onClick={handleOpenNavMenu}
				disableRipple
				aria-controls={open ? 'title-menu' : undefined}
				aria-haspopup='true'
				aria-expanded={open ? 'true' : undefined}
				sx={brandTitleStyle}
				data-test-id='community-navigation-menu'
			>
				{fixed ? (
					<HeaderLogo white logoOnly />
				) : (
					<Typography
						variant='button1'
						maxWidth={getCurrentLanguage() === 'fr' ? 500 : 300}
					>
						{isLoadingCommunity ? (
							t('common.loading')
						) : (
							<>
								{community?.name ??
									t('community-header.communities')}
							</>
						)}
					</Typography>
				)}
				<ArrowBackIosNewIcon
					className={classNames({
						open: open,
					})}
					sx={arrowMenuIconStyle}
				/>
			</ButtonBase>
			<Menu
				id='title-menu'
				className={open ? 'open' : undefined}
				anchorEl={anchorElNav}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				keepMounted
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				open={open}
				onClose={handleCloseNavMenu}
				PaperProps={{
					elevation: 0,
					sx: menuPaperStyle,
				}}
				MenuListProps={{
					sx: {
						paddingTop: {
							xs: 0,
							sm: 1,
						},
					},
				}}
				TransitionComponent={Fade}
			>
				{(fixed || isExtraSmall) && (
					<MenuItem
						sx={currentCommunityContainer}
						disableRipple
						onClick={handleCloseNavMenu}
					>
						<Typography
							variant='button2'
							overflow='hidden'
							textOverflow='ellipsis'
							display='block'
							whiteSpace='nowrap'
						>
							{community?.name}
						</Typography>
						{isExtraSmall && <Box px={2}>X</Box>}
					</MenuItem>
				)}
				{lastActiveCommunities && [
					<Box key='label-my-communities' component='li'>
						<Typography sx={dropDownTitleStyle}>
							{t('community-header.your-communities')}
						</Typography>
					</Box>,
					lastActiveCommunities.map(community => (
						<CommunityMenuItem
							key={community.uuid}
							community={community}
							to={router.getPathFor('community.home', {
								communityId: community.slug,
							})}
							href={community.switch_to_url}
							onClick={handleCloseNavMenu}
							sx={itemStyle}
						/>
					)),
				]}
				{lastInvitedCommunities.length > 0 && [
					<Box key='label-invited' component='li'>
						<Typography sx={dropDownTitleStyle}>
							{t('community-header.invited-communities')}
						</Typography>
					</Box>,
					lastInvitedCommunities.map(community => (
						<CommunityMenuItem
							key={community.uuid}
							community={community}
							to={router.getPathFor(
								'community.invitation.email-target',
								{
									communityId: community.slug,
								},
								{
									t: community.invite_token,
								}
							)}
							href={community.switch_to_url}
							onClick={handleCloseNavMenu}
							sx={itemStyle}
						/>
					)),
				]}
				{lastPendingCommunities.length > 0 && [
					<Box key='label-pending' component='li'>
						<Typography sx={dropDownTitleStyle}>
							{t('community-header.pending-communities')}
						</Typography>
					</Box>,
					lastPendingCommunities.map(community => (
						<PendingCommunityMenuItem
							key={community.uuid}
							community={community}
							sx={pendingItemStyle}
						/>
					)),
				]}
				<Divider sx={{ my: 1 }} />
				<MenuItem
					component={CommunityMenuLink}
					sx={itemStyle}
					onClick={handleCloseNavMenu}
					to={router.getPathFor('community.switch')}
					disableRipple
					data-test-id='community-navigation-menu_switch-community'
				>
					{t('community-header.show-all-communities')}
				</MenuItem>
				<Divider />
				<Box component='li'>
					<ButtonLink
						onClick={handleCloseNavMenu}
						to={router.getPathFor('community.search')}
						data-test-id='community-navigation-menu_search-community'
					>
						<Box
							component='img'
							src={joinCommunityIcon}
							alt='Join Community'
						/>
						{t('community-header.join-community')}
					</ButtonLink>
				</Box>
				<Box component='li'>
					<ButtonLink
						onClick={handleCloseNavMenu}
						to={router.getPathFor('community.create')}
						data-test-id='community-navigation-menu_create-community'
					>
						<Box
							component='img'
							src={createCommunityIcon}
							alt='Create Community'
						/>
						{t('community-header.create-community')}
					</ButtonLink>
				</Box>
			</Menu>
		</>
	);
};
