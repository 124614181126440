import { Trans } from 'react-i18next';
import {
	Community,
	Identity,
	InvitationTokenStatus,
	InvitationTokenStatusCode,
} from 'core/types';
import { RouterLink } from 'components';
import { useRouter } from 'hooks';
import { t } from 'i18next';
import { useMemo } from 'react';

type ErrorCode = 2 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;

export const useInvalidTokenConstants = (
	currentIdentity: Identity | undefined,
	community: Community | undefined,
	tokenStatus: InvitationTokenStatus | undefined
) => {
	const router = useRouter();

	const translationMap = useMemo(
		() => ({
			primaryErrorCodeMap: {
				[InvitationTokenStatusCode.ExpiredToken]: t(
					'invitation.invitation-expired'
				),
				[InvitationTokenStatusCode.AcceptedInvitationUserNotCommunityMember]:
					t('invitation.you-accepted-not-member'),
				[InvitationTokenStatusCode.RejectedInvitationUser]: t(
					'invitation.you-declined'
				),
				[InvitationTokenStatusCode.AcceptedAnotherUserUserCommunityMember]:
					t('invitation.another-user-accepted', {
						first_name: currentIdentity?.first_name,
						last_name: currentIdentity?.last_name,
						email: currentIdentity?.email,
					}),
				[InvitationTokenStatusCode.AcceptedAnotherUserUserNotCommunityMember]:
					t('invitation.another-user-accepted-not-member', {
						first_name: currentIdentity?.first_name,
						last_name: currentIdentity?.last_name,
						email: currentIdentity?.email,
						community_name: community?.name,
					}),
				[InvitationTokenStatusCode.RejectedAnotherUser]: t(
					'invitation.another-user-declined',
					{
						first_name: currentIdentity?.first_name,
						last_name: currentIdentity?.last_name,
						email: currentIdentity?.email,
					}
				),
				[InvitationTokenStatusCode.AcceptedUnknownUserCommunityMember]:
					t('invitation.some-user-accepted'),
				[InvitationTokenStatusCode.AcceptedUnknownUserNotCommunityMember]:
					t('invitation.some-user-accepted-not-member', {
						first_name: currentIdentity?.first_name,
						last_name: currentIdentity?.last_name,
					}),
				[InvitationTokenStatusCode.RejectedUnknownUser]: t(
					'invitation.some-user-declined'
				),
			},
			secondaryErrorCodeMap: {
				[InvitationTokenStatusCode.ExpiredToken]: (
					<Trans
						i18nKey='invitation.request-to-join-again'
						components={{
							community: (
								<RouterLink
									to={router.getPathFor('community.home', {
										communityId: community?.slug,
									})}
								/>
							),
						}}
					/>
				),
				[InvitationTokenStatusCode.AcceptedInvitationUserNotCommunityMember]:
					t('invitation.you-are-logged-in-as-user', {
						first_name: currentIdentity?.first_name,
						last_name: currentIdentity?.last_name,
						email: currentIdentity?.email,
					}),
				[InvitationTokenStatusCode.RejectedInvitationUser]: t(
					'invitation.you-are-logged-in-as-user',
					{
						first_name: currentIdentity?.first_name,
						last_name: currentIdentity?.last_name,
						email: currentIdentity?.email,
					}
				),
				[InvitationTokenStatusCode.AcceptedAnotherUserUserCommunityMember]:
					(
						<Trans
							i18nKey='invitation.if-mistake-request-to-join'
							components={{
								community: (
									<RouterLink
										to={router.getPathFor(
											'community.home',
											{
												communityId: community?.slug,
											}
										)}
									/>
								),
							}}
						/>
					),
				[InvitationTokenStatusCode.AcceptedAnotherUserUserNotCommunityMember]:
					(
						<Trans
							i18nKey='invitation.if-mistake-request-to-join'
							components={{
								community: (
									<RouterLink
										to={router.getPathFor(
											'community.home',
											{
												communityId: community?.slug,
											}
										)}
									/>
								),
							}}
						/>
					),
				[InvitationTokenStatusCode.RejectedAnotherUser]: (
					<Trans
						i18nKey='invitation.if-mistake-request-to-join'
						components={{
							community: (
								<RouterLink
									to={router.getPathFor('community.home', {
										communityId: community?.slug,
									})}
								/>
							),
						}}
					/>
				),
				[InvitationTokenStatusCode.AcceptedUnknownUserCommunityMember]:
					(
						<Trans
							i18nKey='invitation.if-mistake-request-to-join'
							components={{
								community: (
									<RouterLink
										to={router.getPathFor(
											'community.home',
											{
												communityId: community?.slug,
											}
										)}
									/>
								),
							}}
						/>
					),
				[InvitationTokenStatusCode.AcceptedUnknownUserNotCommunityMember]:
					(
						<Trans
							i18nKey='invitation.if-mistake-request-to-join'
							components={{
								community: (
									<RouterLink
										to={router.getPathFor(
											'community.home',
											{
												communityId: community?.slug,
											}
										)}
									/>
								),
							}}
						/>
					),
				[InvitationTokenStatusCode.RejectedUnknownUser]: (
					<Trans
						i18nKey='invitation.if-mistake-request-to-join'
						components={{
							community: (
								<RouterLink
									to={router.getPathFor('community.home', {
										communityId: community?.slug,
									})}
								/>
							),
						}}
					/>
				),
			},
		}),
		[]
	);

	return {
		primaryText:
			translationMap.primaryErrorCodeMap[tokenStatus?.code as ErrorCode],
		secondaryText:
			translationMap.secondaryErrorCodeMap[
				tokenStatus?.code as ErrorCode
			],
	};
};
