import { selectCommunity } from 'core/selectors/communities';
import { useServices } from 'core/services';
import { BaseHttpError } from 'core/services/api/errors';
import { CommunitiesResponse } from 'core/services/api/response-types';
import { Community } from 'core/types';
import { communityKeys } from 'core/utils/query-key-factory';
import { InfiniteData, useQuery } from 'react-query';

export const useGetCommunity = (
	id: string,
	options: { enabled: boolean } = { enabled: true }
) => {
	const { api, authentication, queryClient } = useServices();
	const isLoggedIn = authentication.isAuthenticated();

	const { data, isSuccess, isLoading, isError } = useQuery<
		Community,
		BaseHttpError
	>(
		communityKeys.detail(id),
		() => api.getCommunity(id).then(response => response.data),
		{
			enabled: !!id && isLoggedIn && options.enabled,
			select: selectCommunity,
			keepPreviousData: true,
			refetchOnWindowFocus: true,
			onSuccess: community => {
				const currentCommunities = queryClient.getQueryData<
					InfiniteData<CommunitiesResponse>
				>(communityKeys.list('my_communities'));

				if (currentCommunities) {
					const communityIsFirst =
						currentCommunities.pages?.[0]?.data?.[0]?.uuid ===
						community.uuid;

					if (!communityIsFirst) {
						queryClient.invalidateQueries(
							communityKeys.list('my_communities')
						);
					}
				}
			},
		}
	);

	return { community: data, isSuccess, isLoading, isError };
};

export const useGetPublicCommunity = (
	id: string,
	options: { enabled: boolean } = { enabled: true }
) => {
	const { api } = useServices();

	return useQuery<Community, BaseHttpError>(
		communityKeys.publicDetail(id),
		() => api.getCommunity(id).then(response => response.data),
		{
			enabled: !!id && options.enabled,
			select: selectCommunity,
		}
	);
};
