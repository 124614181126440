import {
	Box,
	InputLabel,
	Checkbox,
	CheckboxGroupField,
	When,
	SelectField,
	SelectItem,
	SelectMultipleField,
	TimePickerField,
	Field,
	calendarUtils,
	getRangeOfDates,
} from 'components';
import { useTranslation } from 'hooks';
import { DateTime } from 'luxon';
import { LiHTMLAttributes, useMemo } from 'react';
import { PREFERENCES, DIGEST_FREQUENCY } from './constants';

export const EmailPreferencesSection = () => {
	const { t } = useTranslation();

	const preferenceOptions = [
		{
			label: t('email-preferences-form.announcement-subscription'),
			value: PREFERENCES.ANNOUNCEMENT_SUBSCRIPTION,
		},
		{
			label: t('email-preferences-form.calendar-digest-subscription'),
			value: PREFERENCES.DIGEST_SUBSCRIPTION,
		},
	];

	const digestFrequencyOptions = [
		{
			label: t('email-preferences-form.digest-frequency-daily'),
			value: DIGEST_FREQUENCY.DAILY,
		},
		{
			label: t('email-preferences-form.digest-frequency-weekly'),
			value: DIGEST_FREQUENCY.WEEKLY,
		},
		{
			label: t('email-preferences-form.digest-frequency-custom'),
			value: DIGEST_FREQUENCY.CUSTOM,
		},
	];

	const weekDaysOptions = useMemo(() => {
		const today = DateTime.now();
		const startDate = calendarUtils.getFirstDayOfWeek(today);
		const endDate = calendarUtils.getLastDayOfWeek(today);
		const daysRange = getRangeOfDates(startDate, endDate);

		return daysRange.map(day => ({
			label: day.toFormat('EEEE'),
			value: day.setLocale('en-US').toFormat('EEEE'),
		}));
	}, []);

	return (
		<>
			<Field
				name='emailPreferences'
				component={CheckboxGroupField}
				options={preferenceOptions}
			/>
			<When<string[]>
				field='emailPreferences'
				is={values => {
					return values.includes(PREFERENCES.DIGEST_SUBSCRIPTION);
				}}
			>
				<Box>
					<InputLabel>
						{t('email-preferences-form.digest-frequency')}
					</InputLabel>
					<Box
						display='flex'
						gap={2}
						flexWrap={{
							xs: 'wrap',
							md: 'nowrap',
						}}
					>
						<Box width={200}>
							<Field
								name='digestFrequency'
								component={SelectField} // prettier-ignore
								fullWidth
							>
								{digestFrequencyOptions.map(option => (
									<SelectItem
										key={option.value}
										value={option.value}
									>
										{option.label}
									</SelectItem>
								))}
							</Field>
						</Box>
						<When
							field='digestFrequency'
							is={DIGEST_FREQUENCY.WEEKLY}
						>
							<Box width={200}>
								<Field
									component={SelectField} // prettier-ignore
									name='deliveryDay'
									fullWidth
									validateImmediately
								>
									{weekDaysOptions.map(option => (
										<SelectItem
											key={option.value}
											value={option.value}
										>
											{option.label}
										</SelectItem>
									))}
								</Field>
							</Box>
						</When>
						<When
							field='digestFrequency'
							is={DIGEST_FREQUENCY.CUSTOM}
						>
							<Box width={200}>
								<Field
									name='deliveryDays'
									component={SelectMultipleField} // prettier-ignore
									options={weekDaysOptions} // prettier-ignore
									fullWidth
									disableCloseOnSelect
									renderOption={(
										props: LiHTMLAttributes<HTMLLIElement>,
										option: {
											label: string;
										},
										{
											selected,
										}: {
											selected: boolean;
										}
									) => (
										<li {...props}>
											<Checkbox
												checked={selected} // prettier-ignore
											/>
											{option.label}
										</li>
									)}
								/>
							</Box>
						</When>
						<Box width={200}>
							<Field
								component={TimePickerField} // prettier-ignore
								name='deliveryTime'
								fullWidth
								views={['hours']}
							/>
						</Box>
					</Box>
				</Box>
			</When>
		</>
	);
};
