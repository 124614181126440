import { forwardRef } from 'react';
import MuiTextField, {
	TextFieldProps as MuiTextFieldProps,
} from '@mui/material/TextField';
import { FieldRenderProps } from 'react-final-form';
import { Box } from 'components/common';
import { InputAdornment } from '@mui/material';

export type TextFieldProps = Omit<MuiTextFieldProps, 'variant'> & {
	meta?: FieldRenderProps<any>['meta'];
	startIcon?: string;
};

export const TextField = forwardRef<HTMLDivElement, TextFieldProps>(
	function TextField(props, ref) {
		const { startIcon, InputProps = {}, ...rest } = props;

		const iconInputProps = startIcon && {
			startAdornment: (
				<InputAdornment position='start'>
					<Box component='img' src={startIcon} />
				</InputAdornment>
			),
		};

		const customInputProps = { ...iconInputProps, ...InputProps };

		return (
			<MuiTextField
				variant='outlined'
				ref={ref}
				InputProps={customInputProps}
				{...rest}
			/>
		);
	}
);
