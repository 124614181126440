export const identityWithParams = 'image,my_communities';

export const communityWithParams =
	'image,calendar,partner,purpose,owner,preferences,last_active_dt,groups,groups.members,member_stats,contact_info,team_stats,switch_to';

export const communityFeedWithParams =
	'photos,albumimage.discussion_stats,' +
	'announcement.author,announcement.image,announcement.video,announcement.author.image,announcement.identity_permissions,' +
	'wellwish.author.image,wellwish.image,wellwish.author.image,wellwish.identity_permissions,' +
	'note.owner,note.owner.image,note.identity_permissions,announcement.discussion_stats';

export const announcementWithParams =
	'image,video,video.cover,discussion_stats,author.image,identity_permissions,who_can_see';

export const noteWithParams =
	'owner,owner.image,identity_permissions,who_can_see,managers';

export const wellWishWithParams = 'image,author.image,identity_permissions';

export const entityParams =
	'community,announcement,album,album_image,member,task,well_wish,group,note';

export const albumWithParams = 'identity_permissions,settings';

export const photoWithParams =
	'discussion_stats,identity_permissions,owner,owner.image';

export const eventListWithParams =
	'image,asset_collection,volunteers_needed,locations,volunteers_available,volunteers,settings,identity_permissions,event_series,event_series_count';

export const eventWithParams =
	'image,asset_collection,locations,coordinators,volunteers,volunteers_needed,volunteers_available,volunteers,settings,identity_permissions,discussion_stats,event_series,event_series_count';

export const membershipRequestsWithParams = 'requester';

export const membershipInvitationsWithParams = 'inviter,status';

export const commentWitParams =
	'discussion_stats,replies.commenter.image,commenter.image';

export const groupWithParams =
	'members,who_can_see,managers,identity_permissions,permissions';
