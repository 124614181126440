import {
	DesktopTimePicker,
	DesktopTimePickerProps,
} from '@mui/x-date-pickers/DesktopTimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { TextFieldProps } from '../text-field';

export type TimePickerFieldProps = DesktopTimePickerProps<DateTime> &
	Omit<
		TextFieldProps,
		| 'value'
		| 'onChange'
		| 'multiline'
		| 'type'
		| 'rows'
		| 'maxRows'
		| 'minRows'
		| 'select'
		| 'SelectProps'
		| 'type'
		| 'defaultValue'
	>;

export const TimePickerField = (props: TimePickerFieldProps) => {
	const { helperText, error, onFocus, onBlur, ...rest } = props;

	return (
		<DesktopTimePicker
			{...rest}
			ampmInClock
			format='hh:mm a'
			viewRenderers={{
				hours: renderTimeViewClock,
				minutes: renderTimeViewClock,
				seconds: renderTimeViewClock,
			}}
			slotProps={{
				textField: {
					error,
					helperText,
					onFocus,
					onBlur,
				},
			}}
		/>
	);
};
