import { useGetCurrentIdentity } from 'core/uses-cases/authentication';
import {
	useGetInvitationTokenStatus,
	useGetPublicCommunity,
} from 'core/uses-cases/community';
import { useInvalidTokenConstants } from './use-invalid-token-constants';

export const useGetInvalidTokenData = (
	communityId: string,
	tokenId: string
) => {
	const { data: currentIdentity, isLoading: identityIsLoading } =
		useGetCurrentIdentity();

	const { data: tokenStatus, isLoading: tokenIsLoading } =
		useGetInvitationTokenStatus(communityId, tokenId);

	const { data: community, isLoading: communityIsLoading } =
		useGetPublicCommunity(communityId);

	const { primaryText, secondaryText } = useInvalidTokenConstants(
		currentIdentity,
		community,
		tokenStatus
	);

	return {
		isLoading: identityIsLoading || tokenIsLoading || communityIsLoading,
		primaryText,
		secondaryText,
	};
};
