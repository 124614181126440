import { useServices } from 'core/services';
import { BaseHttpError } from 'core/services/api/errors';
import { CommentsResponse } from 'core/services/api/response-types';
import { Comment } from 'core/types';
import { getPageParams } from 'core/utils/pagination';
import { discussionKeys } from 'core/utils/query-key-factory';
import { useInfiniteQuery } from 'react-query';

export const useGetComments = (
	discussionId: string,
	options: {
		enabled?: boolean;
		refreshInterval?: number | false;
	} = {
		enabled: true,
		refreshInterval: false,
	}
) => {
	const { api, authentication } = useServices();

	const isLoggedIn = authentication.isAuthenticated();

	const commentsQuery = useInfiniteQuery<
		CommentsResponse,
		BaseHttpError,
		Comment[]
	>(
		discussionKeys.comments(discussionId),
		({ pageParam = null, signal }) =>
			api.getComments(discussionId, {
				pagination: pageParam,
				signal,
			}),
		{
			enabled: !!discussionId && isLoggedIn && options.enabled,
			select: data => {
				return {
					pages: data.pages.map(page => page.data),
					pageParams: data.pageParams,
				};
			},
			refetchInterval: options.refreshInterval,
			getPreviousPageParam: firstPage =>
				getPageParams(firstPage.pagination).previousParam,
			getNextPageParam: lastPage =>
				getPageParams(lastPage.pagination).nextParam,
			notifyOnChangeProps: 'tracked',
			refetchOnMount: true,
		}
	);

	return commentsQuery;
};
