import { SxProps } from '@mui/system';
import { Theme } from 'theme';

export const containerStyle: SxProps<Theme> = {
	'> .ql-toolbar': {
		border: 'none',
	},
};

export const editorContentStyle: SxProps<Theme> = theme => ({
	borderRadius: '4px',
	borderColor: theme.palette.text.primary,
	borderWidth: '1px',
	borderStyle: 'solid',
	cursor: 'text',

	'& > .quill': {
		border: 'none',
		height: 'calc(100% - 1px)',

		'& .ql-container': {
			border: 'none',
			fontSize: theme.typography.body1.fontSize,
		},

		'& .ql-toolbar': {
			display: 'none',
		},
	},

	'&.Mui-error': {
		borderColor: theme.palette.error.main,
	},

	'&.Mui-focused': {
		borderColor: theme.palette.text.primary,
		borderStyle: 'dashed',

		'&.Mui-error': {
			borderColor: theme.palette.error.main,
		},
	},
});
