import { Box, CircularProgress, PageHeading, Paper } from 'components/common';
import {
	GroupMembersFormSubmittedValues,
	GroupMembersForm,
} from 'components/groups';
import { TeamHeader } from 'components/team';
import {
	useGetCommunityGroup,
	usePatchCommunityGroup,
} from 'core/uses-cases/group';
import {
	useCurrentCommunity,
	useParams,
	useRouter,
	useServices,
	useSubmitHandlerForPausedMutation,
	useTranslation,
	useVerifyAccess,
} from 'hooks';
import { Navigate } from 'navigation';
import { useGroupFormData } from 'components/groups/use-group-form-data';
import { useCurrentIdentity } from 'core/uses-cases/authentication';
import { useMemo } from 'react';
import { useGroupNameTranslation } from 'components/groups/use-group-name-translation';
import { useGetGroupPermissions } from './use-group-access';

export const EditGroupMembers = () => {
	const { t } = useTranslation();
	const router = useRouter();
	const { communityId, groupId } = useParams() as {
		communityId: string;
		groupId: string;
	};
	const { notification } = useServices();
	const identity = useCurrentIdentity();
	const community = useCurrentCommunity();

	const {
		data: group,
		isSuccess,
		isLoading,
	} = useGetCommunityGroup(communityId, groupId);

	const { canEditMembers } = useGetGroupPermissions(group);

	const { initialValues, identities, groups } = useGroupFormData(
		communityId,
		group
	);

	const membersMutation = usePatchCommunityGroup();
	const visibilityMutation = usePatchCommunityGroup();

	const handleOnSubmit =
		useSubmitHandlerForPausedMutation<GroupMembersFormSubmittedValues>(
			membersMutation.isPaused || visibilityMutation.isPaused,
			({ members, visibility, visibilityGroups }) => {
				return Promise.allSettled([
					membersMutation.mutateAsync({
						communityId,
						groupId,
						type: 'members',
						members,
					}),
					visibilityMutation.mutateAsync({
						communityId,
						groupId,
						type: 'visibility',
						visibility,
						visibilityGroups,
					}),
				]);
			},
			{
				onSuccess: () => {
					notification.show(
						'success',
						t('group.group-was-successfully-updated', {
							name: group?.description,
						})
					);
					router.navigate('community.group-view', {
						communityId,
						groupId,
					});
				},
			},
			[communityId, groupId]
		);

	const verify = useVerifyAccess();

	const isGranted = useMemo(() => {
		let isGranted = true;

		if (community && identity && group) {
			isGranted =
				group.has_locked_permissions &&
				verify('update', 'group', group);

			const isSiteAdmin = groups.some(
				group =>
					group.name === 'site_admin' &&
					group.members?.some(
						member => member.uuid === identity?.uuid
					)
			);

			const isCommunityOwner = community?.owner?.uuid === identity?.uuid;

			if (
				isGranted &&
				group.name === 'site_admin' &&
				!isSiteAdmin &&
				!isCommunityOwner
			) {
				isGranted = false;
			}
		}

		return isGranted;
	}, [community, identity, group]);

	const title = useGroupNameTranslation(group);

	if (!isGranted || (group && !canEditMembers)) {
		return (
			<Navigate
				to={router.getPathFor('community.group-view', {
					communityId,
					groupId,
				})}
			/>
		);
	}

	if (isLoading) {
		return (
			<Box display='flex' justifyContent='center' mt={4}>
				<CircularProgress message={t('common.loading')} />
			</Box>
		);
	}

	return (
		<>
			<TeamHeader title={t('group.edit-group')} />
			<Box component={Paper} elevation={0} mt={6} px={5} py={5}>
				{isSuccess && group && (
					<>
						<PageHeading title={title} />
						<GroupMembersForm
							onSubmit={handleOnSubmit}
							groups={groups}
							identities={identities}
							initialValues={initialValues}
							submitText={t('group-form.update-group')}
							submittingText={t('group-form.updating-group')}
						/>
					</>
				)}
			</Box>
		</>
	);
};
