import {
	PREFERENCES,
	DIGEST_FREQUENCY,
} from 'components/email-preferences/constants';
import { DigestFrequency } from 'core/services/api/api-client/types';
import { Preferences } from 'core/types';
import { DateTime } from 'luxon';

export const parsePreferences = (subscriptions?: Preferences) => {
	if (!subscriptions) {
		return;
	}

	const { digest_subscription, announcement_subscription, digest_frequency } =
		subscriptions;

	const emailPreferences = [];

	if (digest_subscription) {
		emailPreferences.push(PREFERENCES.DIGEST_SUBSCRIPTION);
	}

	if (announcement_subscription) {
		emailPreferences.push(PREFERENCES.ANNOUNCEMENT_SUBSCRIPTION);
	}

	const [digestFrequency, ...rest] = digest_frequency.split('@');
	let deliveryDay;
	let deliveryDays: string[] = [];
	let deliveryTime;

	if (DIGEST_FREQUENCY.DAILY === digestFrequency) {
		deliveryTime = rest[0].length === 1 ? '0' + rest[0] : rest[0];
	} else if (DIGEST_FREQUENCY.WEEKLY === digestFrequency) {
		deliveryDay = rest[0];
		deliveryTime = rest[1].length === 1 ? '0' + rest[1] : rest[1];
	} else if (DIGEST_FREQUENCY.CUSTOM === digestFrequency) {
		deliveryDays = rest[0].split(',');
		deliveryTime = rest[1].length === 1 ? '0' + rest[1] : rest[1];
	}

	return {
		eventReminderTime: subscriptions.event_reminder_time,
		emailPreferences,
		digestFrequency: digestFrequency as DigestFrequency,
		...(deliveryDay && { deliveryDay }),
		...(deliveryDays && { deliveryDays }),
		...(deliveryTime && {
			deliveryTime: DateTime.fromISO(`${deliveryTime}:00:00`),
		}),
	};
};

export const buildDigestFrequency = (
	frequency: DigestFrequency,
	day: string,
	days: string[],
	time: number
) => {
	if (frequency === 'Daily') {
		return `Daily@${time}`;
	} else if (frequency === 'Weekly') {
		return `Weekly@${day}@${time}`;
	} else if (frequency === 'Custom') {
		return `Custom@${days.join(',')}@${time}`;
	}

	return '';
};
