import {
	PageHeading,
	Grid,
	Box,
	Paper,
	PaginatedList,
	FORM_ERROR,
} from 'components/common';
import {
	useInfinityScrollPaginationHandler,
	useParams,
	useServices,
	useSubmitHandlerForPausedMutation,
	useTranslation,
} from 'hooks';
import { Fragment, useMemo } from 'react';
import {
	WellWishCard,
	WellWishForm,
	WellWishFormSubmittedValues,
} from 'components/well-wishes';
import { useGetWellWishes, usePostWellWish } from 'core/uses-cases/well-wish';
import { isNotAllowToPostWellWish } from 'core/services/api/errors/app-errors';
import { WellWish } from 'core/types';

const FormSection = ({ communityId }: { communityId: string }) => {
	const { t } = useTranslation();

	const { notification } = useServices();

	const { mutateAsync, isLoading, isPaused } = usePostWellWish();

	const handleOnSubmit =
		useSubmitHandlerForPausedMutation<WellWishFormSubmittedValues>(
			isPaused,
			values => {
				return mutateAsync({
					communityId,
					...values,
				}).catch(error => {
					if (isNotAllowToPostWellWish(error)) {
						throw {
							[FORM_ERROR]: t(`api-errors.${error.message}`),
						};
					}

					throw error;
				});
			},
			{
				onSuccess: () => {
					notification.show(
						'success',
						t('well-wishes.well-wishes-was-successfully-created')
					);
				},
			},
			[communityId]
		);

	return (
		<Box component={Paper} elevation={0} mt={1} px={5} py={5}>
			<WellWishForm
				onSubmit={handleOnSubmit}
				isSubmitting={isLoading && !isPaused}
			/>
		</Box>
	);
};

export const WellWishHome = () => {
	const { t } = useTranslation();
	const { communityId } = useParams() as { communityId: string };

	const {
		data: wishes,
		isFetching,
		isFetchingNextPage,
		fetchNextPage,
		hasNextPage,
	} = useGetWellWishes(communityId, {
		enable: !!communityId,
	});

	const hasWishes = useMemo(() => {
		if (!wishes?.pages) return false;
		return wishes.pages.some(page => page.length > 0);
	}, [wishes?.pages]);

	const showRightBar = hasWishes || isFetching;

	const { handleOnLoad, showMoreButton, setShowMoreButton } =
		useInfinityScrollPaginationHandler<WellWish>({
			data: wishes,
			fetchNextPage,
			hasNextPage,
		});

	return (
		<>
			<PageHeading title={t('well-wishes.well-wishes')} />
			<Grid container spacing={3}>
				<Grid item xs={12} md={showRightBar ? 6 : 12}>
					<FormSection communityId={communityId} />
				</Grid>
				<Grid
					item
					xs={12}
					md={6}
					display={showRightBar ? 'block' : 'none'}
				>
					<PaginatedList
						onReachThreshold={handleOnLoad}
						onLoadMore={fetchNextPage}
						isFetching={isFetching}
						isFetchingMore={isFetchingNextPage}
						hasMore={!!hasNextPage}
						showButton={showMoreButton}
						setShowButton={setShowMoreButton}
					>
						<>
							{wishes?.pages.map(page => (
								<Fragment
									key={`page-${
										page?.[page.length - 1]?.uuid
									}`}
								>
									{page.map(item => {
										return (
											<Fragment key={item.uuid}>
												<WellWishCard wellWish={item} />
											</Fragment>
										);
									})}
								</Fragment>
							))}
						</>
					</PaginatedList>
				</Grid>
			</Grid>
		</>
	);
};
