import { Droppable } from 'react-beautiful-dnd';

export type DropLocationProps = {
	id: string;
	color: string;
	dragOverColor: string;
	children: React.ReactNode;
};

export const DropLocation = ({
	id,
	color,
	dragOverColor,
	children,
}: DropLocationProps) => {
	const getListStyle = (isDraggingOver: boolean) => ({
		background: isDraggingOver ? dragOverColor : color,
	});

	return (
		<Droppable droppableId={id}>
			{(provided, snapshot) => (
				<div
					{...provided.droppableProps}
					ref={provided.innerRef}
					style={getListStyle(snapshot.isDraggingOver)}
				>
					{children}
					{provided.placeholder}
				</div>
			)}
		</Droppable>
	);
};
