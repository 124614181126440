import { Box, ButtonBase, TextProps, Typography } from 'components/common';
import Popover, { PopoverProps } from '@mui/material/Popover';
import { useMemo } from 'react';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { truncateCollection } from 'utils/collections';
import { useTranslation } from 'hooks';
import { SxProps } from '@mui/system';
import { Theme } from 'theme';
import { popupContainerStyle } from './styles';

type TruncatedListProps<T> = {
	maxRenderCount?: number;
	records: T[];
	renderItem: (record: T, inPopup: boolean) => React.ReactNode;
	PopoverProps?: Omit<PopoverProps, 'open'>;
	popoverContainerStyle?: SxProps<Theme>;
	moreTextProps?: TextProps;
};

export function TruncatedList<T>({
	records,
	maxRenderCount = 5,
	renderItem,
	PopoverProps,
	popoverContainerStyle,
	moreTextProps,
}: TruncatedListProps<T>) {
	const { t } = useTranslation();

	const [truncatedRecords, moreRecords] = useMemo(() => {
		return truncateCollection(records, maxRenderCount);
	}, [records]);

	return (
		<Box>
			{truncatedRecords.map(record => renderItem(record, false))}
			{moreRecords.length > 0 && (
				<PopupState variant='popover'>
					{popupState => (
						<>
							<ButtonBase
								disableRipple
								{...bindTrigger(popupState)}
							>
								<Typography variant='body2' {...moreTextProps}>
									{t('truncated-list.more', {
										count: moreRecords.length,
									})}
								</Typography>
							</ButtonBase>
							<Popover
								{...bindPopover(popupState)}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'center',
								}}
								transformOrigin={{
									vertical: 'top',
									horizontal: 'center',
								}}
								{...PopoverProps}
							>
								<Box
									sx={
										[
											popupContainerStyle,
											popoverContainerStyle,
										] as SxProps
									}
								>
									{moreRecords.map(record =>
										renderItem(record, true)
									)}
								</Box>
							</Popover>
						</>
					)}
				</PopupState>
			)}
		</Box>
	);
}
