import { BaseHttpError } from 'core/services';
import { AccessTokenResponse } from 'core/services/api/response-types';
import { postSessionDataMutationKey } from 'core/utils/mutation-key-factory';
import { useServices } from 'hooks';
import { useMutation } from 'react-query';
import { useSetCurrentIdentity } from './use-login';
import { useLogout } from './use-logout';

type ValidateSessionInput = {
	data: string;
	iv: string;
};

export const useValidateSession = () => {
	const { api } = useServices();
	const { doLogout } = useLogout();

	const setCurrentIdentity = useSetCurrentIdentity();

	const mutation = useMutation<
		AccessTokenResponse,
		BaseHttpError,
		ValidateSessionInput
	>(
		({ data, iv }) => {
			return api
				.postSessionData(data, iv)
				.then(response => response.data);
		},
		{
			mutationKey: postSessionDataMutationKey,
			onSuccess: data => {
				const {
					data: { uuid },
					identity,
				} = data;

				doLogout({ keepCurrentPageAfterLogout: true });

				setCurrentIdentity(uuid, identity);
			},
		}
	);

	return mutation;
};
