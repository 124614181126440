import { Box, Grid, PageHeading, Paper, Typography } from 'components/common';
import { AppLayout } from 'components/layout';
import { Community } from 'core/types';
import { useTranslation } from 'hooks';
import { JoinCommunityCard } from '../request-to-join';

export type InvitationLayoutProps = {
	community: Community;
	children: React.ReactNode;
};

export const InvitationLayout = (props: InvitationLayoutProps) => {
	const { t } = useTranslation();

	const { community, children } = props;

	return (
		<AppLayout>
			<PageHeading title={community.name}>
				<Grid container>
					<Grid item xs={12} md={6}>
						<Typography variant='body2'>
							{t('invitation.private-message')}
						</Typography>
					</Grid>
				</Grid>
			</PageHeading>
			<Grid container spacing={6}>
				<Grid item xs={12} md={4}>
					<JoinCommunityCard community={community} />
				</Grid>
				<Grid item xs={12} md={8}>
					<Box component={Paper} elevation={0} px={3.5} py={5}>
						{children}
					</Box>
				</Grid>
			</Grid>
		</AppLayout>
	);
};
