import { Community, Nullable } from 'core/types';
import { useEffect, createContext, useContext, useMemo } from 'react';
import { useStore } from 'store';
import { useGetCommunity } from 'core/uses-cases/community';
import { useGetCommunityMembers } from 'core/uses-cases/member';
import { Navigate, useParams } from 'react-router-dom';
import { getCurrentTenant } from 'tenants/utils';
import { Splash } from 'components';
import { useRouter } from 'hooks';
import { communityKeys } from 'core/utils/query-key-factory';
import { useServices } from 'core/services';
import { useHandleCommunitySetupConstraint } from './use-handle-community-setup-constraint';
import { PermissionProvider } from './permission-context';

export const CommunityContext = createContext<{
	community: Nullable<Community>;
	isLoading: boolean;
}>({ community: null, isLoading: false });

export const CommunityProvider: React.FC = ({ children }) => {
	const { queryClient } = useServices();
	const { communityId } = useParams() as { communityId: string };
	const router = useRouter();
	const { community, isError, isLoading } = useGetCommunity(communityId);
	const { feedFilter } = useStore();
	const tenantCode = getCurrentTenant();
	const renderedContent = useMemo(() => children, []);

	const needsTenantRedirect = community?.partner?.code !== tenantCode;
	const isMember = !!community?.groups;

	useGetCommunityMembers(communityId, {
		enabled: isMember && !needsTenantRedirect && !community.is_legacy,
	});

	useEffect(() => {
		if (isMember) {
			feedFilter.setCommunitySections(community);
		}

		return () => {
			if (!isMember) {
				queryClient.removeQueries(communityKeys.detail(communityId));
			}
		};
	}, [community]);

	if (isLoading) {
		return <Splash />;
	}

	if (isError || !community) {
		return <Navigate to={router.getPathFor('root')} replace />;
	}

	if (community?.is_legacy) {
		window.location.href = community.switch_to_url ?? '';
		return null;
	}

	if (!isMember) {
		return (
			<Navigate
				to={router.getPathFor('request.root', {
					communityId,
				})}
				replace
			/>
		);
	}

	if (needsTenantRedirect && community.switch_to_url) {
		window.location.replace(community.switch_to_url ?? '');
		return null;
	}

	return (
		<PermissionProvider community={community} enabled={isMember}>
			<CommunityContext.Provider
				value={{
					community: community || null,
					isLoading,
				}}
			>
				<ConstrainSetupHandler community={community} />
				{renderedContent}
			</CommunityContext.Provider>
		</PermissionProvider>
	);
};

const ConstrainSetupHandler = ({
	community,
}: React.PropsWithChildren<{ community?: Community }>) => {
	const redirectUrl = useHandleCommunitySetupConstraint(community);

	if (!redirectUrl) return null;

	return <Navigate to={redirectUrl} replace />;
};

export const CommunityConsumer = CommunityContext.Consumer;

export const useIsLoadingCurrentCommunity = () => {
	return useContext(CommunityContext).isLoading;
};

export const useCurrentCommunity = () => {
	const data = useContext(CommunityContext);

	if (data.community === undefined) {
		throw new Error(
			'useCurrentCommunity must be used within CommunityContext.Provider'
		);
	}

	return data.community;
};
