import {
	Box,
	CircularProgress,
	LoadingButton,
	PageHeading,
	Paper,
	Typography,
} from 'components/common';
import {
	useGetCurrentIdentity,
	useLogout,
	useSetCurrentIdentity,
} from 'core/uses-cases/authentication';
import { useGetPublicCommunity } from 'core/uses-cases/community';
import {
	useGetTransferRequestToken,
	useRespondTransferRequest,
} from 'core/uses-cases/transfer-request';
import {
	useParams,
	useTranslation,
	useRouter,
	useSearchParams,
	useServices,
} from 'hooks';
import { Navigate } from 'navigation';
import { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';

export const ConfirmTransferRequest = () => {
	const { t } = useTranslation();
	const router = useRouter();
	const [queryParams] = useSearchParams();
	const { communityId } = useParams() as { communityId: string };
	const { notification } = useServices();
	const [checking, setChecking] = useState(true);
	const tokenId = queryParams.get('t') || '';
	const { doLogout } = useLogout();
	const setCurrentIdentity = useSetCurrentIdentity();
	const currentIdentityQuery = useGetCurrentIdentity();

	const tokenQuery = useGetTransferRequestToken(communityId, tokenId);

	const communityQuery = useGetPublicCommunity(communityId, {
		enabled: !!currentIdentityQuery.data && !!tokenQuery.data,
	});

	const acceptMutation = useRespondTransferRequest();
	const rejectMutation = useRespondTransferRequest();

	/**
	 * This effect is used to check if to log in the user from the token.
	 */
	useEffect(() => {
		if (tokenQuery.data) {
			const { data: currentIdentity } = currentIdentityQuery;

			const {
				details: { new_owner_uuid },
				access_token,
				identity,
			} = tokenQuery.data;

			if (currentIdentity && currentIdentity.uuid !== new_owner_uuid) {
				doLogout({ keepCurrentPageAfterLogout: true });
			}

			setCurrentIdentity(access_token, identity, true);
		}
	}, [tokenQuery.data, currentIdentityQuery.isSuccess, tokenId]);

	/**
	 * This effect is used to check if the loaded community is actually the community coming from the token.
	 */
	useEffect(() => {
		if (tokenQuery.data && communityQuery.data && checking) {
			const { data } = communityQuery;
			const {
				details: { community_uuid, current_owner_uuid },
			} = tokenQuery.data;

			if (
				community_uuid !== data.uuid ||
				current_owner_uuid !== data.owner?.uuid
			) {
				notification.show(
					'error',
					t('transfer-ownership.invalid-token')
				);

				return router.navigate('community.home', { communityId });
			}

			setChecking(false);
		}
	}, [tokenQuery.data, communityQuery.data, checking]);

	if (!tokenId) {
		return (
			<Navigate
				to={router.getPathFor('community.home', { communityId })}
				alert={{
					level: 'error',
					message: t('transfer-ownership.not-giving-token'),
				}}
			/>
		);
	}

	const handleOnAccept = () => {
		acceptMutation.mutate(
			{
				communityId,
				transferRequestId: tokenQuery.data?.identity.uuid as string,
				response: 'accept',
			},
			{
				onSuccess: () => {
					notification.show(
						'success',
						t(
							'transfer-ownership.you-are-now-the-owner-of-this-community'
						)
					);

					router.navigate('community.home', { communityId });
				},
			}
		);
	};

	const handleOnReject = () => {
		rejectMutation.mutate(
			{
				communityId,
				transferRequestId: tokenQuery.data?.identity.uuid as string,
				response: 'reject',
			},
			{
				onSuccess: () => {
					router.navigate('community.home', { communityId });
				},
			}
		);
	};

	if (communityQuery.isError) {
		return (
			<Navigate
				to={router.getPathFor('root')}
				alert={{
					level: 'error',
					message: t('transfer-ownership.invalid-community'),
				}}
			/>
		);
	} else if (tokenQuery.isError) {
		return (
			<Navigate
				to={router.getPathFor('community.home', { communityId })}
				alert={{
					level: 'error',
					message: t('transfer-ownership.invalid-token'),
				}}
			/>
		);
	}

	const isLoading =
		communityQuery.isLoading ||
		tokenQuery.isLoading ||
		currentIdentityQuery.isLoading ||
		checking;

	if (isLoading) {
		return (
			<Box display='flex' justifyContent='center' mt={4}>
				<CircularProgress message={t('common.loading')} />
			</Box>
		);
	}

	return (
		<Box>
			<PageHeading title={t('transfer-ownership.transfer-request')} />
			<Box component={Paper} elevation={0} mt={6} px={5} py={5}>
				<Typography>
					<Trans
						i18nKey='transfer-ownership.transfer-request-confirm'
						values={{
							name: `${communityQuery.data?.owner?.first_name} ${communityQuery.data?.owner?.last_name}`,
							community: communityQuery.data?.name,
						}}
						components={{
							bold: <strong />,
						}}
					/>
				</Typography>
				<Box
					mt={4}
					display='flex'
					flexDirection={{ xs: 'column', sm: 'row' }}
					alignItems={{ xs: 'center', sm: 'normal' }}
					gap={3}
				>
					<LoadingButton
						size='small'
						onClick={handleOnAccept}
						loading={acceptMutation.isLoading}
						disabled={rejectMutation.isLoading}
						loadingIndicator={t(
							'transfer-ownership.accepting-invitation'
						)}
						noWrap
					>
						{t('transfer-ownership.accept')}
					</LoadingButton>
					<LoadingButton
						size='small'
						color='error'
						onClick={handleOnReject}
						loading={rejectMutation.isLoading}
						disabled={acceptMutation.isLoading}
						loadingIndicator={t(
							'transfer-ownership.rejecting-invitation'
						)}
						noWrap
					>
						{t('transfer-ownership.reject')}
					</LoadingButton>
				</Box>
			</Box>
		</Box>
	);
};
