import { useMutation, useQueryClient } from 'react-query';
import { Community } from 'core/types';
import { useServices } from 'hooks';
import { communityKeys } from 'core/utils/query-key-factory';
import { shutdownCommunityMutationKey } from 'core/utils/mutation-key-factory';
import { onDeleteMutateOptimisticInfinityQueryCache } from 'core/utils/optimistic-utils';
import { BaseHttpError } from '../../services/api/errors';

export interface ShutdownCommunityMutationInput {
	communityId: string;
	reason: string;
}

type MutationContext = {
	previousCommunities: Community[];
};

export const useShutdownCommunity = () => {
	const { api } = useServices();
	const queryClient = useQueryClient();

	const mutation = useMutation<
		null,
		BaseHttpError,
		ShutdownCommunityMutationInput,
		MutationContext
	>({
		mutationKey: shutdownCommunityMutationKey,
		mutationFn: input => {
			const { communityId, reason } = input;

			return api.shutdownCommunity(communityId, {
				reason,
			});
		},
		onMutate: async input => {
			const { communityId } = input;

			await queryClient.cancelQueries(communityKeys.all());

			const previousCommunities =
				queryClient.getQueryData<Community[]>(communityKeys.list()) ??
				[];

			await onDeleteMutateOptimisticInfinityQueryCache<Community>(
				queryClient,
				communityKeys.list(),
				communityKeys.detail(communityId),
				communityId
			);

			return { previousCommunities };
		},
		onSuccess: () => {
			return queryClient.invalidateQueries(communityKeys.all());
		},
		onError: (error, input, context) => {
			queryClient.setQueryData(
				communityKeys.list(),
				context?.previousCommunities
			);
		},
	});

	return mutation;
};
