import { Box, PageHeading, Paper, Typography } from 'components/common';
import { useServices, useTranslation } from 'hooks';
import { ContactSupportForm } from 'components/support';
import { ContactSupportInput } from 'core/services/api/api-client/types';
import { useCallback } from 'react';
import { useContactSupport } from 'core/uses-cases/support';

export const ContactSupport = () => {
	const { notification } = useServices();
	const { t } = useTranslation();
	const mutation = useContactSupport();

	const handleOnSubmit = useCallback((values: ContactSupportInput) => {
		mutation.mutate(values, {
			onSuccess: () => {
				notification.show(
					'success',
					t('help-finding.form-submit-success', {
						email: values.email,
					})
				);
			},
		});
	}, []);

	return (
		<Box sx={{ width: { xs: 1, sm: 3 / 4 } }}>
			<PageHeading
				title={t('help-finding.need-help-finding-a-private-community')}
			>
				<Typography>
					{t('help-finding.best-way-to-find-community')}
				</Typography>
				<Typography>
					{t('help-finding.if-looking-for-private')}
				</Typography>
			</PageHeading>
			<Box component={Paper} elevation={0} mt={5} mb={3} px={5} py={5}>
				<ContactSupportForm onSubmit={handleOnSubmit} />
			</Box>
		</Box>
	);
};
