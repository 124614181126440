import { SetupMutationFn } from 'core/configure/types';
import { BaseHttpError } from 'core/services/api/errors';
import { WellWish } from 'core/types';
import { communityKeys, wellWishKeys } from 'core/utils/query-key-factory';
import { isCancelledError, useMutation } from 'react-query';
import { deleteWellWishMutationKey } from 'core/utils/mutation-key-factory';
import {
	cancelPreviousMutation,
	onDeleteMutateOptimisticInfinityQueryCache,
	findRecordFromInfinityQueryCache,
	PaginatedRecordsSnapshot,
	removeRecordFromInfinityQueryCache,
} from 'core/utils/optimistic-utils';
import { isPostPatchWellWishMutation } from './use-patch-well-wish';

export interface DeleteWellWishMutationInput {
	communityId: string;
	wellWishId: string;
}

export const useDeleteWellWish = () => {
	const mutation = useMutation<
		WellWish,
		BaseHttpError,
		DeleteWellWishMutationInput
	>(deleteWellWishMutationKey);

	return mutation;
};

type MutationContext = {
	communityId: string;
	wellWishId: string;
	currentWellWishInfo: {
		listSnapshot: PaginatedRecordsSnapshot<WellWish>;
	} | null;
};

export const setupDeleteWellWish: SetupMutationFn = (
	services,
	createTrackedParallelMutation,
	mutationTracker
) => {
	const { queryClient, api } = services;

	const mutation = createTrackedParallelMutation<
		null,
		BaseHttpError,
		DeleteWellWishMutationInput,
		MutationContext
	>({
		mutationFn: ({ wellWishId }) => {
			if (wellWishId.length === 22) {
				return api.deleteWellWish(wellWishId);
			}

			return Promise.resolve(null);
		},
		onMutate: async input => {
			const { wellWishId, communityId } = input;

			await cancelPreviousMutation<MutationContext>(
				queryClient,
				mutation => {
					return (
						isPostPatchWellWishMutation(mutation) &&
						mutation.state.context?.wellWishId === wellWishId
					);
				}
			);

			const currentWellWishInfo =
				findRecordFromInfinityQueryCache<WellWish>(
					queryClient,
					wellWishKeys.list(communityId),
					wellWishId
				);

			if (currentWellWishInfo) {
				await onDeleteMutateOptimisticInfinityQueryCache<WellWish>(
					queryClient,
					wellWishKeys.list(communityId),
					wellWishKeys.detail(communityId, wellWishId),
					wellWishId
				);
			}

			removeRecordFromInfinityQueryCache(
				queryClient,
				communityKeys.feed(communityId),
				wellWishId
			);

			return {
				communityId,
				wellWishId,
				currentWellWishInfo,
			};
		},
		onError: (error, input, context) => {
			if (context?.currentWellWishInfo && !isCancelledError(error)) {
				queryClient.setQueryData(
					wellWishKeys.list(context.communityId),
					context?.currentWellWishInfo.listSnapshot
				);
			}
		},
		onSettled: (data, error, { wellWishId }, context) => {
			if (context && !isCancelledError(error)) {
				if (wellWishId.length === 22) {
					// Only trigger the refetch when it is a real resource
					mutationTracker.queueInvalidations(
						wellWishKeys.list(context.communityId),
						communityKeys.feed(context.communityId)
					);
				}
			}
		},
		retry: 1,
	});

	queryClient.setMutationDefaults(deleteWellWishMutationKey, mutation);
};
