/* eslint-disable react/display-name */
import { DiscussionFeedFooter } from 'components/discussion';
import { Announcement } from 'core/types';
import { useGetPublishedAnnouncements } from 'core/uses-cases/announcement';
import {
	useTranslation,
	useRouter,
	useInfinityScrollPaginationHandler,
} from 'hooks';
import { Fragment, memo, useCallback, useMemo } from 'react';
import { formatDate } from 'utils/dates';
import { Box, Paper, Typography, RouterLink, PaginatedList } from '../common';
import { AnnouncementCard } from './card';

type PublishedAnnouncementsListProps = {
	communityId: string;
	onDelete?: (announcement: Announcement) => void;
	onEdit?: (announcement: Announcement) => void;
	onClick?: (announcement: Announcement) => void;
	canCreate?: boolean;
};

export const PublishedAnnouncementsList = memo(
	({
		communityId,
		onDelete,
		onEdit,
		onClick,
		canCreate,
	}: PublishedAnnouncementsListProps) => {
		const {
			data,
			isFetching,
			isSuccess,
			isFetchingNextPage,
			fetchNextPage,
			hasNextPage,
		} = useGetPublishedAnnouncements(communityId, {
			enable: !!communityId,
		});

		const { t } = useTranslation();

		const router = useRouter();

		let lastDate: string | null = null;

		const hasAnnouncements = useMemo(() => {
			if (!data?.pages) {
				return false;
			}

			return data.pages.some(page => page.length > 0);
		}, [data?.pages]);

		const renderDiscussion = useCallback(
			(announcement: Announcement) => {
				if (
					!announcement.created_dt ||
					announcement.discussion_state !== 'enabled'
				)
					return null;

				return (
					<DiscussionFeedFooter
						discussionId={announcement.discussion_uuid}
						onClick={() => onClick?.(announcement)}
					/>
				);
			},
			[onClick]
		);

		const { handleOnLoad, showMoreButton, setShowMoreButton } =
			useInfinityScrollPaginationHandler<Announcement>({
				data,
				fetchNextPage,
				hasNextPage,
			});

		return (
			<PaginatedList
				onReachThreshold={handleOnLoad}
				onLoadMore={fetchNextPage}
				isFetching={isFetching}
				isFetchingMore={isFetchingNextPage}
				hasMore={!!hasNextPage}
				showButton={showMoreButton}
				setShowButton={setShowMoreButton}
			>
				{isSuccess && !isFetching && !hasAnnouncements && (
					<Box
						component={Paper}
						elevation={0}
						p={3}
						mb={2}
						mt={1}
						display='flex'
						justifyContent='center'
						alignItems='center'
						flexDirection='column'
					>
						<Typography variant='body2'>
							{t(
								"announcements.you-don't-have-any-announcements"
							)}
						</Typography>
						{canCreate && (
							<RouterLink
								to={router.getPathFor('announcement.create', {
									communityId,
								})}
							>
								{t('announcements.add-one-now')}!
							</RouterLink>
						)}
					</Box>
				)}
				{hasAnnouncements && (
					<>
						{data?.pages.map(page => (
							<Fragment
								key={`page-${page?.[page.length - 1]?.uuid}`}
							>
								{page.map(item => {
									let renderDate = false;
									const date = formatDate(
										item.published_dt || '',
										'yyyy-MM-dd'
									);

									if (lastDate !== date) {
										lastDate = date;
										renderDate = true;
									}

									return (
										<Fragment key={item.uuid}>
											{renderDate && (
												<Box mb={1}>
													<Typography variant='button2'>
														{formatDate(
															item.published_dt as string,
															'DD'
														)}
													</Typography>
												</Box>
											)}
											<AnnouncementCard
												truncate
												className='published-announcement'
												announcement={item}
												onDelete={onDelete}
												onEdit={onEdit}
												renderFooter={renderDiscussion(
													item
												)}
											/>
										</Fragment>
									);
								})}
							</Fragment>
						))}
					</>
				)}
			</PaginatedList>
		);
	}
);
