import {
	Box,
	CheckboxField,
	FancyTable,
	FormControlLabel,
	PaginatedList,
	Paper,
	Typography,
	ButtonRouterLink,
	Icon,
	TeamHeader,
	useMediaQuery,
} from 'components';
import {
	useCheckboxMultiSelectionHandler,
	useInfinityScrollPaginationHandler,
	useParams,
	useRouter,
	useServices,
	useSubmitHandlerForPausedMutation,
	useTranslation,
} from 'hooks';
import { PendingRequestCard } from 'components/community/team';
import { Fragment, useMemo } from 'react';
import {
	usePatchPendingRequests,
	useGetPendingRequests,
} from 'core/uses-cases/community';
import { MembershipRequest } from 'core/types';
import {
	FooterActionButton,
	FooterActions,
} from 'components/common/footer-action';

export const PendingRequests = () => {
	const { t } = useTranslation();
	const router = useRouter();
	const { mutateAsync, isPaused } = usePatchPendingRequests();
	const { notification } = useServices();
	const { communityId } = useParams() as { communityId: string };
	const {
		data,
		isSuccess,
		isFetching,
		isFetchingNextPage,
		hasNextPage,
		fetchNextPage,
	} = useGetPendingRequests(communityId);

	const isLargeScreen = useMediaQuery.up('sm');

	const requests = useMemo(() => {
		if (!data?.pages) return [];

		return data.pages.reduce(
			(requests, page) => requests.concat(page),
			[] as MembershipRequest[]
		);
	}, [data]);

	const {
		selected,
		select,
		selectedSize,
		selectAll,
		isSelected,
		isAllSelected,
		isIndeterminate,
		clearAll,
	} = useCheckboxMultiSelectionHandler(requests);

	const handleOnSubmit = useSubmitHandlerForPausedMutation<
		'approve' | 'decline' | 'delete'
	>(
		isPaused,
		action => {
			return mutateAsync({
				communityId,
				requestIds: selected,
				action,
			});
		},
		{
			onSuccess: () => {
				clearAll();
				notification.show(
					'success',
					t('pending-requests.request-processed')
				);
			},
		},
		[communityId, selected, clearAll, isPaused]
	);

	const { handleOnLoad, showMoreButton, setShowMoreButton } =
		useInfinityScrollPaginationHandler<MembershipRequest>({
			data,
			fetchNextPage,
			hasNextPage,
		});

	return (
		<>
			<TeamHeader
				title={t('team.community-members')}
				button={
					<ButtonRouterLink
						to={router.getPathFor('community.invite', {
							communityId,
						})}
						startIcon={
							<Icon name='add_circle_outline' fontSize='large' />
						}
						size='small'
					>
						{t('team.add-members')}
					</ButtonRouterLink>
				}
			/>
			{isSuccess && !isFetching && data?.pages[0].length === 0 ? (
				<Box component={Paper} mt={2} px={4} py={4}>
					{t('pending-requests.no-outstanding-requests')}
				</Box>
			) : (
				<>
					<FancyTable
						renderColumns={{
							2: { xs: false },
							3: { xs: false, sm: false },
						}}
					>
						<FancyTable.Header>
							<FancyTable.Header.Cell>
								<FormControlLabel
									control={
										<CheckboxField
											CheckBoxProps={{
												indeterminate: isIndeterminate,
											}}
											value={isAllSelected}
											onChange={selectAll}
										/>
									}
									label={
										<Typography variant='button2'>
											{t('pending-requests.name')}
										</Typography>
									}
								/>
							</FancyTable.Header.Cell>
							<FancyTable.Header.Cell
								title={t('pending-requests.comments')}
							/>
							<FancyTable.Header.Cell
								title={t('pending-requests.date-requested')}
							/>
						</FancyTable.Header>
						<PaginatedList
							onReachThreshold={handleOnLoad}
							onLoadMore={fetchNextPage}
							isFetching={isFetching}
							isFetchingMore={isFetchingNextPage}
							hasMore={!!hasNextPage}
							showButton={showMoreButton}
							setShowButton={setShowMoreButton}
						>
							{requests.map(request => (
								<Fragment key={request.uuid}>
									<PendingRequestCard
										request={request}
										isSelected={isSelected(request.uuid)}
										onSelect={select}
									/>
								</Fragment>
							))}
						</PaginatedList>
					</FancyTable>
					<FooterActions
						selectedCount={selectedSize}
						isLargeScreen={isLargeScreen}
					>
						<FooterActionButton
							icon='check'
							onClick={() => handleOnSubmit('approve')}
							color='primary'
						>
							{isLargeScreen && t('pending-requests.approve')}
						</FooterActionButton>
						<FooterActionButton
							icon='cancel'
							onClick={() => handleOnSubmit('decline')}
							color='error'
						>
							{isLargeScreen && t('pending-requests.decline')}
						</FooterActionButton>
						<FooterActionButton
							icon='delete_outline'
							onClick={() => handleOnSubmit('delete')}
						>
							{isLargeScreen && t('common.delete')}
						</FooterActionButton>
					</FooterActions>
				</>
			)}
		</>
	);
};
