import {
	Box,
	Paper,
	Typography,
	Divider,
	Avatar,
	DescriptionListItem,
} from 'components/common';
import { CommunityMember } from 'core/types';
import { useTranslation } from 'hooks';
import { avatarStyle } from './styles';

export type MemberProfileCardProps = {
	member: CommunityMember;
	showAdministrativeNotes?: boolean;
	showPrivateInfo?: boolean;
	renderActions?: React.ReactNode;
};

export const MemberProfileCard = ({
	member,
	showAdministrativeNotes = false,
	showPrivateInfo = false,
	renderActions,
}: MemberProfileCardProps) => {
	const { t } = useTranslation();

	const fullName = `${member.identity.first_name} ${member.identity.last_name}`;

	return (
		<Box component={Paper} elevation={0} p={5}>
			<Box display='flex' alignItems='center'>
				<Box
					component={Avatar}
					sx={avatarStyle}
					src={member?.identity?.image?.url}
					alt={fullName}
				/>
				<Typography ml={1.25} variant='h4'>
					{fullName}
				</Typography>
				{member.is_new ? (
					<Typography
						ml={1.25}
						mt={1}
						variant='caption'
						color='text.secondary'
					>
						{t('member-profile.new-member')}
					</Typography>
				) : null}
			</Box>
			<Divider my={3} />
			<Box>
				<DescriptionListItem title={t('member-profile.groups')}>
					<Box component='span'>
						{member.groups
							.map(group => group.description)
							.join(', ')}
					</Box>
				</DescriptionListItem>
				<DescriptionListItem
					title={t('member-profile.help-with')}
					description={member.help_provided}
				/>
				{showAdministrativeNotes && (
					<DescriptionListItem
						title={t('member-profile.administrative-notes')}
						description={member.administrative_notes}
					/>
				)}
			</Box>
			<Divider my={3} />
			{showPrivateInfo && member.identity.phone && (
				<DescriptionListItem
					title={t('member-profile.phone')}
					description={member.identity.phone}
				/>
			)}
			{showPrivateInfo && member.identity.email && (
				<DescriptionListItem
					title={t('member-profile.email')}
					description={member.identity.email}
				/>
			)}
			{showPrivateInfo && member.identity.address && (
				<DescriptionListItem
					title={t('member-profile.address')}
					description={member.identity.address}
				/>
			)}
			{showPrivateInfo && member.identity.location && (
				<DescriptionListItem
					title={t('member-profile.location')}
					description={member.identity.location}
				/>
			)}
			{renderActions && (
				<Box mt={4} display='flex' justifyContent='center'>
					{renderActions}
				</Box>
			)}
		</Box>
	);
};
