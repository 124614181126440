export const postAccessToken = 'post-access-token';
export const postCommunityMutationKey = 'post-community';
export const patchCommunityMutationKey = 'patch-community';
export const shutdownCommunityMutationKey = 'shutdown-community';
export const initiateTransferOwnershipMutationKey =
	'initiate-transfer-ownership';
export const deleteTransferOwnershipMutationKey = 'delete-transfer-ownership';
export const patchTransferOwnershipMutationKey = 'patch-transfer-ownership';
export const postMembershipInvitationMutationKey = 'post-membership-invitation';
export const respondInvitationMutationKey = 'respond-membership-invitation';
export const postIdentityMutationKey = 'post-identity';
export const patchIdentityMutationKey = 'patch-identity';
export const checkIdentityEmailMutationKey = 'check-identity-email';
export const postAnnouncementMutationKey = 'post-announcement';
export const patchAnnouncementMutationKey = 'patch-announcement';
export const deleteAnnouncementMutationKey = 'delete-announcement';
export const postNoteMutationKey = 'post-note';
export const patchNoteMutationKey = 'patch-note';
export const deleteNoteMutationKey = 'delete-note';
export const postWellWishMutationKey = 'post-well-wish';
export const patchWellWishMutationKey = 'patch-well-wish';
export const deleteWellWishMutationKey = 'delete-well-wish';
export const postContactSupportMutationKey = 'post-contact-support';
export const postEventsMutationKey = 'post-event';
export const deleteEventMutationKey = 'delete-event';
export const postVolunteerMutationKey = 'post-volunteer-event';
export const removeVolunteerMutationKey = 'remove-volunteer-event';
export const duplicateEventMutationKey = 'duplicate-event';
export const patchEventMutationKey = 'patch-event';
export const postAlbumPhotoMutationKey = 'post-album-photo';
export const patchAlbumPhotoMutationKey = 'patch-album-photo';
export const deleteAlbumPhotoMutationKey = 'delete-album-photo';
export const postCommunityPhotoMutationKey = 'post-community-photo';
export const patchCommunityPhotoMutationKey = 'patch-community-photo';
export const deleteCommunityPhotoMutationKey = 'delete-community-photo';
export const postAlbumMutationKey = 'post-album';
export const patchAlbumMutationKey = 'patch-album';
export const deleteAlbumMutationKey = 'delete-album';
export const patchAlbumOrderMutationKey = 'patch-album-order';
export const patchPendingRequestKey = 'patch-pending-request';
export const patchPendingInvitationKey = 'patch-pending-invitation';
export const deleteFailedInvitationKey = 'delete-failed-invitation';
export const patchDeclinedInvitationKey = 'patch-declined-invitation';
export const postRequestMutationKey = 'post-request';
export const postPasswordRequestMutationKey = 'post-password-reset-request';
export const patchPasswordMutationKey = 'patch-password';
export const postRequestToJoinMutationKey = 'post-request-to-join';
export const postCommentMutationKey = 'post-comment';
export const patchCommentMutationKey = 'patch-comment';
export const deleteCommentMutationKey = 'delete-comment';
export const deleteCommunityGroupMutationKey = 'delete-community-group';
export const postCommunityGroupMutationKey = 'post-community-group';
export const putCommunityGroupMutationKey = 'put-community-group';
export const patchCommunityGroupMutationKey = 'patch-community-group';
export const postSessionDataMutationKey = 'post-session-data';
export const patchSubscriptionPreferencesMutationKey =
	'patch-subscription-preferences';
export const patchCommunityMemberMutationKey = 'patch-community-member';
export const deleteCommunityMemberMutationKey = 'delete-community-member';
export const putCommunityGlobalPermissionsMutationKey =
	'put-community-global-permissions';
export const patchMemberPreferencesMutationKey = 'patch-member-preferences';
export const manageCommunityGroupMutationKey = 'manage-community-group';
