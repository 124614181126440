import Box from '@mui/material/Box';
import { Grid, Typography } from 'components';
import { useCalendar } from 'components/calendar/context';
import {
	CalendarNavigation,
	useCalendarHeaderDays,
} from 'components/calendar/shared';
import { calendarUtils } from 'components/calendar/utils';
import { Fragment, useCallback, useEffect } from 'react';
import { MonthWeekRow } from './row';
import { weekHeaderContainerStyle } from './styles';

export const Month = () => {
	const { displayed, formatDate, setDisplayed, loadEvents, timezone } =
		useCalendar();

	const headerDays = useCalendarHeaderDays(timezone);

	const month = calendarUtils.getMonthDates(displayed.year, displayed.month);

	const handleOnPressNext = useCallback(() => {
		setDisplayed(displayed.plus({ month: 1 }));
	}, [displayed]);

	const handleOnPressPrev = useCallback(() => {
		setDisplayed(displayed.minus({ month: 1 }));
	}, [displayed]);

	useEffect(() => {
		// Load +/- 1 week of the current month in the first render.
		const startDate = calendarUtils.getFirstCalendarDayOfMonth(displayed);
		const endDate = calendarUtils.getLastCalendarDayOfMonth(displayed);
		loadEvents({ startDate, endDate });
	}, [displayed]);

	return (
		<Box>
			<CalendarNavigation
				display={formatDate(displayed, 'MMMM yyyy')}
				onNext={handleOnPressNext}
				onPrev={handleOnPressPrev}
			/>
			<Grid container item spacing={0} sx={weekHeaderContainerStyle}>
				{headerDays.map(day => (
					<Fragment key={`week-header-${day.toISO()}`}>
						<Grid item xs={12 / 7}>
							<Typography variant='body1'>
								{formatDate(day, 'EEE')}
							</Typography>
						</Grid>
					</Fragment>
				))}
			</Grid>
			<Grid container spacing={0}>
				{month.map(week => (
					<MonthWeekRow
						weekDays={week}
						key={`week-${week[0].toISO()}`}
					/>
				))}
			</Grid>
		</Box>
	);
};
