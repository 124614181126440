import { SetupMutationFn } from 'core/configure/types';
import { BaseHttpError } from 'core/services/api/errors';
import { BatchMembershipInvite, Community, MembershipInvite } from 'core/types';
import { patchPendingInvitationKey } from 'core/utils/mutation-key-factory';
import {
	findRecordFromInfinityQueryCache,
	removeRecordFromInfinityQueryCache,
	updatePartiallyRecordFromInfinityQueryCache,
} from 'core/utils/optimistic-utils';
import { communityKeys } from 'core/utils/query-key-factory';
import { isCancelledError, useMutation } from 'react-query';

export interface PatchPendingInvitesInput {
	communityId: string;
	invitationIds: string[];
	action: 'reinvite' | 'delete';
}

export const usePatchPendingInvites = () => {
	const mutation = useMutation<
		MembershipInvite,
		BaseHttpError,
		PatchPendingInvitesInput
	>(patchPendingInvitationKey);

	return mutation;
};

export const setupPatchPendingInvites: SetupMutationFn = (
	services,
	createTrackedParallelMutation,
	mutationTracker
) => {
	const { api, queryClient } = services;

	const mutation = createTrackedParallelMutation<
		BatchMembershipInvite,
		BaseHttpError,
		PatchPendingInvitesInput,
		null
	>({
		mutationFn: ({ communityId, invitationIds, action }) =>
			api
				.patchCommunityMembershipInvitations(
					communityId,
					invitationIds,
					action
				)
				.then(response => response.data),
		onMutate: async ({ communityId, invitationIds, action }) => {
			for (const inviteId of invitationIds) {
				const currentInviteInfo =
					findRecordFromInfinityQueryCache<MembershipInvite>(
						queryClient,
						communityKeys.pendingInvites(communityId),
						inviteId
					);

				if (action === 'delete') {
					await removeRecordFromInfinityQueryCache<MembershipInvite>(
						queryClient,
						communityKeys.pendingInvites(communityId),
						inviteId
					);

					queryClient.setQueryData<Community | undefined>(
						communityKeys.detail(communityId),
						community => {
							if (community) {
								community.team_stats.invites--;
							}

							return community;
						}
					);
				} else {
					const updatedRecord = {
						...currentInviteInfo?.record,
						created_dt: new Date().toISOString(),
					} as MembershipInvite;

					updatePartiallyRecordFromInfinityQueryCache<MembershipInvite>(
						queryClient,
						communityKeys.pendingInvites(communityId),
						updatedRecord,
						['created_dt']
					);
				}
			}

			return null;
		},
		onSettled: (_data, error, variables) => {
			if (!isCancelledError(error)) {
				mutationTracker.queueInvalidations(
					communityKeys.pendingInvites(variables.communityId),
					communityKeys.detail(variables.communityId)
				);
			}
		},
		retry: 1,
	});

	queryClient.setMutationDefaults(patchPendingInvitationKey, mutation);
};
