import { DateTime } from 'luxon';
import { Memoize } from 'typescript-memoize';

export const MONDAY = 1 as const;
export const SUNDAY = 0 as const;

class CalendarUtils {
	getFirstCalendarDayOfMonth(date: DateTime, startOfWeek = SUNDAY): DateTime {
		const firstDayOfMonth = date.startOf('month');
		const firstDayOfWeek = firstDayOfMonth.startOf('week');

		if (startOfWeek === SUNDAY) {
			return firstDayOfWeek
				.plus({ week: firstDayOfMonth.weekday === 7 ? 1 : 0 })
				.minus({ day: 1 });
		}

		return firstDayOfWeek;
	}

	getLastCalendarDayOfMonth(date: DateTime, startOfWeek = SUNDAY): DateTime {
		const endOfMonth = date.endOf('month');

		if (startOfWeek === SUNDAY) {
			return endOfMonth
				.plus({
					day: endOfMonth.weekday === 7 ? 1 : 0,
				})
				.endOf('week')
				.minus({ day: 1 });
		}

		return endOfMonth.endOf('week');
	}

	@Memoize((...args) => args.join('-'))
	getMonthDates(
		year: number,
		month: number,
		startOfWeek = SUNDAY
	): DateTime[][] {
		const date = DateTime.fromFormat(`${year}-${month}-01`, 'yyyy-M-dd');
		const firstDayOfMonth = this.getFirstCalendarDayOfMonth(
			date,
			startOfWeek
		);
		const lastDayOfMonth = this.getLastCalendarDayOfMonth(
			date,
			startOfWeek
		);

		const dates: DateTime[][] = [];

		let week = [];
		let cursor = firstDayOfMonth;
		let lastDayOfWeek = this.getLastDayOfWeek(firstDayOfMonth);

		while (cursor < lastDayOfMonth.plus({ day: 1 })) {
			if (cursor > lastDayOfWeek) {
				lastDayOfWeek = this.getLastDayOfWeek(cursor);
				dates.push(week);
				week = [];
			}
			week.push(cursor);
			cursor = cursor.plus({ day: 1 });
		}

		return dates;
	}

	getFirstDayOfWeek(date: DateTime, startOfWeek = SUNDAY): DateTime {
		if (startOfWeek === SUNDAY) {
			return date.plus({ day: 1 }).startOf('week').minus({ day: 1 });
		}

		return date.startOf('week');
	}

	getLastDayOfWeek(date: DateTime, startOfWeek = SUNDAY): DateTime {
		if (startOfWeek === SUNDAY) {
			const offset = date.weekday === 7 ? 1 : 0;
			return date.plus({ day: offset }).endOf('week').minus({ day: 1 });
		}

		return date.endOf('week');
	}
}

export const calendarUtils = new CalendarUtils();

export const getRangeOfDates = (startDate: DateTime, endDate: DateTime) => {
	let pivot = startDate.set({
		hour: 0,
		minute: 0,
		second: 0,
		millisecond: 0,
	});
	const dates = [];

	while (pivot <= endDate) {
		dates.push(pivot);
		pivot = pivot.plus({ days: 1 });
	}

	return dates;
};

export const isToday = (date: DateTime, timezone: string) => {
	return date.hasSame(DateTime.local({ zone: timezone }), 'day');
};

export const hasSameMonth = (date: DateTime, date2: DateTime) => {
	return date.hasSame(date2, 'month');
};

export const hasSameDay = (date: DateTime, date2: DateTime) => {
	return date.hasSame(date2, 'day');
};
