export const COMMUNITY_SETUP_PROFILE = 0;
export const COMMUNITY_SETUP_MEMBERS = 1;
export const COMMUNITY_SETUP_ANNOUNCEMENT = 2;
export const COMMUNITY_SETUP_COMPLETED = 3;

// Languages
export const ENGLISH_CODE = 'en_US.UTF-8';
export const FRENCH_CODE = 'fr_FR.UTF-8';

export const EVENT_SCOPES = {
	INSTANCE: 1,
	ALL: 2,
	FUTURE: 3,
	PREVIOUS: 4,
};

// Type for event scope values
export type EventScopes = keyof typeof EVENT_SCOPES;

export type EventScopeValues = (typeof EVENT_SCOPES)[EventScopes];

export const COOKIE_CONSENT_KEY = 'lotsaCookieConsent';

export const LANGUAGE_PREFERENCE_KEY = 'languagePreference';

export const LAST_LOGIN_DT_KEY = 'lastLoginDt';

export const USE_PHP_SITE = 'usePhpSite';
