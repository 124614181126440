import { Box, Typography, Paper } from 'components/common';
import { Community } from 'core/types';
import { useTranslation } from 'hooks';
import { useMemo } from 'react';
import { formatDate } from 'utils/dates';
import { imageStyle } from './styles';

export const JoinCommunityCard = ({ community }: { community: Community }) => {
	const { t } = useTranslation();

	const contactList = useMemo(
		() =>
			community?.contact_info?.contact_list
				.map(contact => `${contact.first_name} ${contact.last_name}`)
				.join(', ') || 'N/A',
		[community]
	);
	const count = community?.member_stats?.members || 'N/A';

	return (
		<Box
			component={Paper}
			elevation={0}
			p={3}
			display='flex'
			flexDirection='column'
		>
			<Box sx={imageStyle}>
				<img src={community?.image?.url} alt={community?.name} />
			</Box>
			<Box>
				<Typography variant='body1' fontWeight='600'>
					{community?.description}
				</Typography>
				<Typography variant='body1' mt={1}>
					{t('select-email.community-started', {
						date: formatDate(
							community?.created_dt as Date,
							'M/d/y'
						),
					})}
				</Typography>
				<Typography variant='body1' mt={1}>
					{t('select-email.members', {
						count,
					})}
				</Typography>
				<Typography variant='body1' mt={1}>
					{t('select-email.contact', {
						list: contactList,
					})}
				</Typography>
			</Box>
		</Box>
	);
};
