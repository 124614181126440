import { useParams, useRouter, useSearchParams } from 'hooks';
import { Navigate } from 'navigation';

export type CustomAliasNavigationProps = {
	targetRoute: string;
	params: Record<string, any>;
};

export type AliasNavigationProps = {
	CustomComponent?: React.FC<CustomAliasNavigationProps>;
	targetRoute: string;
	getParams?: (
		params: Record<string, any>,
		queryParams: URLSearchParams
	) => Record<string, any>;
};

export const AliasNavigation = ({
	targetRoute,
	getParams,
	CustomComponent,
}: AliasNavigationProps) => {
	const router = useRouter();
	const params = useParams();
	const [searchParams] = useSearchParams();
	const routeParams = getParams
		? {
			...params,
			...getParams(params, searchParams),
		  }
		: params; // prettier-ignore

	if (CustomComponent) {
		return (
			<CustomComponent params={routeParams} targetRoute={targetRoute} />
		);
	}

	return (
		<Navigate to={router.getPathFor(targetRoute, routeParams)} replace />
	);
};

export const createAliasRedirectElement = (props: AliasNavigationProps) => {
	return <AliasNavigation {...props} />;
};
