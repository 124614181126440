import { SxProps } from '@mui/system';
import { Theme } from 'theme';

export const monthTitleSectionStyles: SxProps<Theme> = (theme: Theme) => ({
	width: '100%',
	borderBottom: '1px dotted',
	borderBottomColor: theme.palette.grey[300],
	paddingX: 2,
	paddingY: 2,
});

export const timeRowStyle: SxProps<Theme> = theme => ({
	borderBottom: '1px dotted ',
	borderBottomColor: theme.palette.grey[300],

	'&:last-child': {
		borderBottomColor: 'transparent',
	},

	'> *': {
		minHeight: 64,
		borderRight: '1px dotted',
		borderRightColor: theme.palette.grey[300],
		'&:first-of-type': {
			paddingX: theme.spacing(0.5),
			paddingY: theme.spacing(1),
			textAlign: 'right',
			backgroundColor: theme.palette.grey[300],
		},
		'&:last-child': {
			borderRightColor: 'transparent',
			paddingY: theme.spacing(1),
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(1),
		},

		'> div': {
			marginBottom: theme.spacing(1),
		},
	},
});
