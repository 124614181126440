import { BaseHttpError } from 'core/services/api/errors';
import { AlbumPhotosResponse } from 'core/services/api/response-types';
import { AlbumPhoto } from 'core/types';
import { albumPhotoKeys } from 'core/utils/query-key-factory';
import { useServices } from 'core/services';
import { getPageParams } from 'core/utils/pagination';
import { useInfiniteQuery } from 'react-query';
import { photoWithParams } from 'core/utils/request-with-params';

export const useGetCommunityPhotos = (communityId: string) => {
	const { api, authentication } = useServices();

	const isLoggedIn = authentication.isAuthenticated();

	const albums = useInfiniteQuery<
		AlbumPhotosResponse,
		BaseHttpError,
		AlbumPhoto[]
	>(
		albumPhotoKeys.list(communityId),
		({ pageParam = null, signal }) => {
			return api.getCommunityPhotos(communityId, {
				pagination: {
					...pageParam,
					with: photoWithParams,
				},
				signal,
			});
		},
		{
			enabled: !!communityId && isLoggedIn,
			select: response => {
				return {
					pages: response.pages.map(page => page.data),
					pageParams: response.pageParams,
				};
			},
			getPreviousPageParam: firstPage =>
				getPageParams(firstPage.pagination).previousParam,
			getNextPageParam: lastPage =>
				getPageParams(lastPage.pagination).nextParam,
			notifyOnChangeProps: 'tracked',
			refetchOnMount: true,
		}
	);

	return albums;
};
