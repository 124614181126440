import { forwardRef } from 'react';
import lotsaWhiteLogo from 'assets/images/lotsa-logo-white.svg?url';
import lotsaLogo from 'assets/images/lotsa-logo.svg?url';
import { Button, ButtonProps } from '../button';
import { spinnerStyle } from './styles';
import { Box } from '../layout';

export interface LoadingButtonProps extends ButtonProps {
	loading?: boolean;
	loadingIndicator?: string | React.ReactNode;
}

export const LoadingButton = forwardRef<HTMLButtonElement, LoadingButtonProps>(
	function LoadingButton(props, ref) {
		const {
			children,
			loading,
			disabled,
			loadingIndicator,
			variant = 'contained',
			...rest
		} = props;

		const buttonDisabled = disabled || loading;

		return (
			<Button
				{...rest}
				variant={variant}
				disabled={buttonDisabled}
				ref={ref}
			>
				{loading ? (
					<Box sx={spinnerStyle}>
						<img
							src={
								variant !== 'contained'
									? lotsaLogo
									: lotsaWhiteLogo
							}
						/>
						{loadingIndicator}
					</Box>
				) : null}
				{!loading ? children : null}
			</Button>
		);
	}
);
