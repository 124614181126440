import {
	Avatar,
	Box,
	CardMenuItem,
	CircularProgress,
	LoadingButton,
	Typography,
	useCardMenu,
} from 'components/common';
import { Comment, CommentReply } from 'core/types';
import {
	useAvatarImage,
	useSubmitHandlerForPausedMutation,
	useTranslation,
} from 'hooks';
import { Fragment, useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import { usePatchComment, usePostComment } from 'core/uses-cases/discussion';
import { Button } from '@mui/material';
import config from 'config';
import { toRelative } from '../../utils/dates';
import { useGetCommentReplies } from '../../core/uses-cases/discussion/use-get-comment-replies';
import { useDiscussion } from './context';
import { useVerifyAccess } from '../../hooks/use-verify-access';
import { useDeleteCommentHandler } from './hooks';
import { CommentForm, CommentFormValues } from './comment-form';
import { usePrevious } from '../../hooks/use-previous';
import { commentTopInfoStyle, replyButtonStyle } from './styles';

type InnerCommentRowProps =
	| {
			isReply?: false;
			comment: Comment;
			parentId?: string;
	  }
	| {
			isReply: true;
			comment: CommentReply;
			parentId: string;
	  };

export const InnerCommentRow = ({
	comment,
	isReply,
	parentId,
}: InnerCommentRowProps) => {
	const { t } = useTranslation();
	const { uuid, created_dt, message, commenter } = comment;
	const { discussionId, identity } = useDiscussion();
	const avatarImage = useAvatarImage(commenter);
	const [showReplyForm, setShowReplyForm] = useState(false);
	const [isEditing, setIsEditing] = useState(false);

	useEffect(() => {
		// Hide reply form when comment is editing.
		if (isEditing === true && showReplyForm === true) {
			setShowReplyForm(false);
		}
	}, [isEditing, showReplyForm]);

	const createdDateTime = created_dt
		? DateTime.fromISO(created_dt)
		: DateTime.invalid('not set');

	const canEditComment =
		createdDateTime.isValid &&
		DateTime.local().diff(createdDateTime).milliseconds <=
			config.commentWidget.editingTimeout && // Haven't passed 10 minutes
		identity.uuid === commenter.uuid; // Is the owner

	const canDelete = useVerifyAccess()('delete_comments', 'community');

	let menuOptions: CardMenuItem[] = [];

	const handler = useDeleteCommentHandler();

	const handleOnDelete = () => {
		handler({
			commentId: uuid,
			discussionId,
			parentId,
		});
	};

	// If the comment was created in offline, the uuid does not length 22 characters.
	const isOffline = !createdDateTime.isValid && uuid.length !== 22;

	if (canEditComment || isOffline) {
		menuOptions = [
			...(!isEditing
				? [
					{
						label: t('comment-widget.edit'),
						icon: 'edit',
						onClick: () => setIsEditing(true),
					},
				  ]
				: []), // prettier-ignore
			{
				label: t('comment-widget.delete'),
				icon: 'delete',
				variant: 'error',
				onClick: handleOnDelete,
			},
		];
	} else if (canDelete) {
		menuOptions.push({
			label: t('comment-widget.delete'),
			icon: 'delete',
			variant: 'error',
			onClick: handleOnDelete,
		});
	}

	const menuActions = useCardMenu(menuOptions);

	const createMutation = usePostComment();

	const handleOnSubmit = useSubmitHandlerForPausedMutation<CommentFormValues>(
		createMutation.isPaused,
		values => {
			setShowReplyForm(false);
			return createMutation.mutateAsync({
				discussionId,
				parentId: uuid,
				...values,
			});
		},
		{},
		[discussionId]
	);

	const updateMutation = usePatchComment();

	const handleOnEditingSubmit =
		useSubmitHandlerForPausedMutation<CommentFormValues>(
			updateMutation.isPaused,
			values => {
				setIsEditing(false);
				return updateMutation.mutateAsync({
					parentId,
					discussionId,
					commentId: uuid,
					createdDt: created_dt,
					...values,
				});
			},
			{},
			[discussionId]
		);

	return (
		<Fragment>
			<Box
				display='flex'
				alignItems='flex-start'
				mb={3}
				ml={isReply ? 6 : 0}
			>
				<Box>
					<Avatar {...avatarImage} />
				</Box>
				<Box flex={1} ml={2} flexDirection='column'>
					<Box display='flex' mb={1}>
						<Box sx={commentTopInfoStyle}>
							<Typography variant='body2'>
								{commenter.first_name} {commenter.last_name}
							</Typography>
							{created_dt && (
								<Typography>
									{toRelative(created_dt) ||
										t('comment-widget.a-few-seconds-ago')}
								</Typography>
							)}
						</Box>
					</Box>
					<Box flex={1}>
						{isEditing ? (
							<CommentForm
								initialValues={{ message }}
								onCancel={() => setIsEditing(false)}
								submitText={t('comment-widget.save')}
								submittingText={t('comment-widget.saving')}
								onSubmit={handleOnEditingSubmit}
								hideIdentity
								autoFocus
							/>
						) : (
							<Typography variant='body1'>{message}</Typography>
						)}
					</Box>
				</Box>
				<Box
					display='flex'
					flexDirection='column'
					alignItems='flex-end'
				>
					{menuActions}
					{created_dt && !isEditing && !isReply && (
						<Button
							variant='text'
							size='small'
							onClick={() => setShowReplyForm(!showReplyForm)}
							sx={replyButtonStyle}
						>
							{showReplyForm
								? t('comment-widget.hide')
								: t('comment-widget.reply')}
						</Button>
					)}
				</Box>
			</Box>
			{!isEditing && showReplyForm && (
				<Box ml={6}>
					<CommentForm
						onSubmit={handleOnSubmit}
						submitText={t('comment-widget.reply')}
						submittingText={t('comment-widget.replying')}
						onCancel={() => setShowReplyForm(false)}
						autoFocus
					/>
				</Box>
			)}
		</Fragment>
	);
};

type CommentRowProps = {
	comment: Comment;
	discussionId: string;
};

export const CommentRow = ({ discussionId, comment }: CommentRowProps) => {
	const { uuid, replies = [], more_replies: moreReplies = false } = comment;

	const { t } = useTranslation();

	const {
		data,
		isLoading,
		refetch,
		hasNextPage,
		isFetchingNextPage,
		fetchNextPage,
	} = useGetCommentReplies(discussionId, uuid, {
		initialReplies: replies,
		moreReplies,
	});

	const handleOnLoadMore = () => fetchNextPage();

	const prevMoreReplies = usePrevious(moreReplies);

	useEffect(() => {
		if (prevMoreReplies === false && moreReplies === true) {
			refetch();
		}
	}, [moreReplies]);

	return (
		<Fragment>
			<InnerCommentRow comment={comment} />
			{data &&
				data.pages.map(page => (
					<Fragment
						key={`reply-page-${page?.[page.length - 1]?.uuid}`}
					>
						{page.map(reply => (
							<InnerCommentRow
								key={reply.uuid}
								comment={reply}
								parentId={uuid}
								isReply
							/>
						))}
					</Fragment>
				))}
			{isLoading && (
				<Box display='flex' justifyContent='center' mt={4}>
					<CircularProgress
						message={t('comment-widget.loading-replies')}
					/>
				</Box>
			)}
			{hasNextPage ? (
				<Box textAlign='center' mb={1}>
					<LoadingButton
						onClick={handleOnLoadMore}
						disabled={isLoading || isFetchingNextPage}
						size='small'
						variant='text'
						loading={isFetchingNextPage}
						loadingIndicator={t('comment-widget.loading-replies')}
					>
						{t('comment-widget.load-older-replies')}
					</LoadingButton>
				</Box>
			) : null}
		</Fragment>
	);
};
