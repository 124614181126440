import { createWebStoragePersistor } from 'react-query/createWebStoragePersistor-experimental';
import { Store, useStore } from 'store';
import { v4 as uuid } from 'uuid';
import {
	HttpClientError,
	AlertLevel,
	AuthenticationInterface,
	I18nInterface,
	NotificationInterface,
	HttpNetworkError,
	ResetAuthOptions,
	BaseHttpError,
} from 'core/services';
import { createQueryClient } from 'core/configure';
import { onlineManager } from 'react-query';

export const configureNotification = (store: Store): NotificationInterface => {
	// Setup notification service based on local store
	const notification = {
		show: (level: AlertLevel, message: string, options: object = {}) => {
			const existingIndex = store.alert.stack.findIndex(
				alert => alert.message === message
			);

			if (existingIndex === -1) {
				const id = uuid();

				store.alert.showAlert({
					id,
					message,
					level,
					...options,
				});
			}
		},
		dismiss: (id: string) => {
			store.alert.dismissAlert(id);
		},
	};

	return notification;
};

export const configureAuthentication = (
	store: Store
): AuthenticationInterface => {
	// Setup authentication service based on local store
	const authentication = {
		isAuthenticated: () => store.auth.isLoggedIn,
		getAccessToken: () => store.auth.accessToken,
		setAccessToken: (accessToken: string | null) => {
			store.auth.setAccessToken(accessToken);
		},
		reset: (options?: ResetAuthOptions) => {
			store.auth.reset(options);
		},
		hooks: {
			useIsAuthenticated: () => useStore().auth.isLoggedIn,
			useGetAccessToken: () => useStore().auth.accessToken,
		},
	};

	return authentication;
};

export const configureErrorReport = (
	notification: NotificationInterface,
	i18n: I18nInterface
) => {
	return {
		recordError: (error: Error) => {
			if (
				error instanceof HttpClientError ||
				error instanceof BaseHttpError ||
				(onlineManager.isOnline() && error instanceof HttpNetworkError)
			) {
				if (error.displayError) {
					const translatedError = i18n(
						`api-errors.${error.message}`,
						{
							defaultValue: i18n('api-errors.generic-error'),
						}
					);

					notification.show('error', translatedError);
				}
			}
		},
	};
};

export const configureQueryClient = () => {
	const localStoragePersistor = createWebStoragePersistor({
		storage: window.localStorage,
		key: 'lotsa@reactQueryOfflineCache',
	});

	const onlineSubscription = (setOnline: (isOnline: boolean) => void) => {
		setOnline(navigator.onLine);
		window.addEventListener('online', () => setOnline(true));
		window.addEventListener('offline', () => setOnline(false));
	};

	const queryClient = createQueryClient(
		{},
		localStoragePersistor,
		onlineSubscription
	);

	return queryClient;
};
