import { AppLayout } from 'components/layout';
import { useCurrentIdentity } from 'core/uses-cases/authentication';
import { useRouter } from 'hooks';
import { ComponentType, Fragment } from 'react';
import {
	matchRoutes,
	Navigate,
	Outlet,
	useLocation,
	useParams,
} from 'react-router-dom';
import { useTransientStore } from 'store';

type GuardProps = {
	useDefaultLayout?: boolean;
	layout?: ComponentType;
};

export const RequestToJoinGuard = (props: GuardProps) => {
	const {
		useDefaultLayout = true,
		layout: Layout = useDefaultLayout ? AppLayout : Fragment,
	} = props;
	const router = useRouter();
	const store = useTransientStore();
	const location = useLocation();
	const identity = useCurrentIdentity();

	const { communityId } = useParams() as { communityId: string };
	const [{ route }] = matchRoutes(router.getRoutes(), location) ?? [];

	if (
		store.auth.isLoggedIn &&
		route.path === '/request/:communityId/select-email'
	) {
		return (
			<Navigate
				to={router.getPathFor('request.join', {
					communityId,
				})}
				replace
			/>
		);
	}

	if (
		store.auth.isLoggedIn &&
		identity?.my_communities.includes(communityId)
	) {
		return (
			<Navigate
				to={router.getPathFor('community.home', {
					communityId,
				})}
				replace
			/>
		);
	}

	// TODO add redirect to accept invitation if there is a pending invite

	return (
		<Layout>
			<Outlet />
		</Layout>
	);
};
