import { Theme } from 'theme';
import { SxProps } from '@mui/system';
import { alpha } from '@mui/material';

export const menuCardStyle: SxProps<Theme> = theme => ({
	marginTop: theme.spacing(1),
	minWidth: 180,
	'& .MuiMenuItem-root': {
		'& .MuiSvgIcon-root, & .MuiIcon-root': {
			color: theme.palette.text.secondary,
			marginRight: theme.spacing(1.5),
		},
		'&:active': {
			backgroundColor: alpha(
				theme.palette.primary.main,
				theme.palette.action.selectedOpacity
			),
		},
		'&.error': {
			color: theme.palette.error.main,
			'& .MuiSvgIcon-root, & .MuiIcon-root': {
				color: theme.palette.error.main,
			},

			'&:hover': {
				color: theme.palette.common.white,
				backgroundColor: theme.palette.error.main,

				'& .MuiSvgIcon-root, & .MuiIcon-root': {
					color: theme.palette.common.white,
				},
			},
		},
	},
});
