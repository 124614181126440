import {
	FormControl,
	FormHelperText,
	TextFieldProps,
	renderHelperText,
} from 'components/common';
import InputLabel from '@mui/material/InputLabel';
import {
	FieldArray as FFFieldArray,
	FieldArrayRenderProps,
} from 'react-final-form-arrays';
import { FieldRenderProps } from 'react-final-form';
import { useMemo } from 'react';

export type FieldArrayProps<T, E extends HTMLElement = HTMLElement> = Omit<
	TextFieldProps,
	| 'value'
	| 'onChange'
	| 'onBlur'
	| 'onFocus'
	| 'multiline'
	| 'type'
	| 'rows'
	| 'maxRows'
	| 'minRows'
	| 'select'
	| 'SelectProps'
	| 'type'
	| 'defaultValue'
> & {
	name: string;
	defaultValue?: T[];
	initialValue?: T[];
	children: ({
		fields,
		meta,
	}: {
		fields: FieldArrayRenderProps<T, E>['fields'];
		meta: FieldRenderProps<T>['meta'];
		hasError: boolean;
	}) => React.ReactNode;
};

export function FieldArray<T, E extends HTMLElement = HTMLElement>(
	props: FieldArrayProps<T, E>
) {
	const {
		name,
		label,
		InputLabelProps,
		defaultValue,
		initialValue,
		helperText,
		children,
		...rest
	} = props;

	const _defaultValue = useMemo(() => defaultValue, []);
	const _initialValue = useMemo(() => initialValue, [initialValue]);

	return (
		<FFFieldArray<T>
			name={name}
			defaultValue={_defaultValue}
			initialValue={_initialValue}
		>
			{({ fields, meta }) => {
				const { error, submitError } = meta;

				const innerHelperText = renderHelperText(meta, helperText);

				const _errors = Array.isArray(error)
					? error
					: error
						? [error]
						: []; // prettier-ignore

				const hasError = _errors.reduce(
					(carry: boolean, entry: object | undefined) => {
						return (
							(entry && Object.values(entry).length > 0) || carry
						);
					},

					false
				);

				return (
					<FormControl {...rest} error={!!(hasError || submitError)}>
						{label && (
							<InputLabel {...InputLabelProps}>
								{label}
							</InputLabel>
						)}
						{children({ fields, meta, hasError })}
						{innerHelperText && (
							<FormHelperText>{innerHelperText}</FormHelperText>
						)}
					</FormControl>
				);
			}}
		</FFFieldArray>
	);
}
