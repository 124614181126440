import { Form, TextField, Grid, LoadingButton, Field } from 'components';
import { useTranslation } from 'hooks';
import { TFunction } from 'i18next';
import { useCallback, useMemo, useRef } from 'react';
import * as Yup from 'yup';
import { sleep } from 'utils/delay';

export type ResetPasswordFormSubmittedValues = {
	email: string;
};

type ResetPasswordFormProps = {
	onSubmit?: (values: ResetPasswordFormSubmittedValues) => Promise<void>;
	initialValues?: { email: string };
};

export const ResetPasswordForm = (props: ResetPasswordFormProps) => {
	const { onSubmit, initialValues } = props;

	const { t } = useTranslation();

	const handleOnSubmit = useCallback(
		async values => {
			await sleep(1);

			const result = onSubmit?.(values);

			return Promise.resolve(result).catch(error => error);
		},
		[onSubmit]
	);

	const cachedInitialValues = useRef({
		...initialValues,
	}).current;

	const validationSchema = useMemo(() => createValidationSchema(t), []);

	return (
		<Form
			onSubmit={handleOnSubmit}
			validationSchema={validationSchema}
			subscription={{ submitting: true }}
			initialValues={cachedInitialValues}
		>
			{({ handleSubmit, submitting }) => (
				<form onSubmit={handleSubmit} noValidate>
					<Grid container justifyContent='center'>
						<Grid item xs={12} md={10}>
							<Field
								placeholder={t('reset-password.email-address')}
								name='email'
								type='text'
								component={TextField}
								fullWidth
								required
							/>
						</Grid>
						<Grid item xs={12} mt={2}>
							<LoadingButton
								loading={submitting}
								loadingIndicator={t('form.submitting')}
								size='small'
								type='submit'
							>
								{t('form.submit')}
							</LoadingButton>
						</Grid>
					</Grid>
				</form>
			)}
		</Form>
	);
};

function createValidationSchema(t: TFunction) {
	return Yup.object().shape({
		email: Yup.string()
			.email(t('form.invalid-email'))
			.required(t('form.generic-required')),
	});
}
