import { Suspense } from 'react';
import { suspend } from 'suspend-react';

export const ReactQueryDevtools = () => {
	if (process.env.NODE_ENV === 'development') {
		const InternalReactQueryDevtools = suspend(
			() =>
				import('react-query/lib/devtools/devtools').then(module => {
					return module.ReactQueryDevtools;
				}),
			['react-query-devtools']
		);

		return (
			<Suspense fallback={null}>
				<InternalReactQueryDevtools
					initialIsOpen={false}
					position='bottom-right'
				/>
			</Suspense>
		);
	}

	return null;
};
