import { env } from 'utils/env';
import { api } from './api';
import { analytics } from './analytics';
import { errorLogger } from './error-logger';
import { hosts } from './hosts';
import { microsoft, gmail } from './email-imports';
import { wp } from './wp';

export default {
	env: process.env.NODE_ENV,
	version: {
		packageName: VERSION_PACKAGE_NAME,
		packageVersion: VERSION_PACKAGE_VERSION,
	},
	api,
	analytics,
	errorLogger,
	hosts,
	wp,
	microsoft,
	gmail,
	maxFileSize: +env.APP_MAX_FILE_SIZE || 10,
	debug: {
		store: env.APP_DEBUG_STORE === 'true',
		routes: env.APP_DEBUG_ROUTER === 'true',
		whyDidYouRender: env.APP_DEBUG_ENABLE_WHYDIDYOURENDER === 'true',
	},
	imageServer: env.APP_IMAGE_SERVER,
	pagination: {
		defaultPrefetchPageSize: Number(
			env.APP_DEFAULT_PREFETCH_PAGE_SIZE || 3
		),
		feedPrefetchPageSize: Number(env.APP_FEED_PREFETCH_PAGE_SIZE || 3),
	},
	recaptcha: {
		siteKey: env.APP_RECAPTCHA_SITE_KEY,
	},
	mobileApp: {
		apple: env.APP_APPLE_STORE,
		google: env.APP_GOOGLE_PLAY_STORE,
	},
	urls: {
		wordPress: env.APP_WP_URL,
		faq: env.APP_FAQ_URL,
	},
	supportEmail:
		env.APP_SUPPORT_EMAIL || 'support+login@lotsahelpinghands.com',
	defaultTimezone: env.APP_DEFAULT_TIMEZONE || 'America/Chicago',
	google: {
		placesApiKey: env.APP_GOOGLE_PLACES_API_KEY || '',
	},
	enableLanguage: env.APP_ENABLE_LANGUAGE === 'true',
	commentWidget: {
		pollerTimeout: Number(env.APP_COMMENT_WIDGET_POLLER_TIMEOUT || 30000),
		editingTimeout: Number(
			env.APP_COMMENT_WIDGET_EDITING_TIMEOUT || 600000
		),
	},
};
