import { useCallback, Fragment } from 'react';
import { useRouter } from 'hooks';
import { Album } from 'core/types';
import { AlbumCard } from './card';

export const AlbumList = ({
	communityId,
	albums,
}: {
	communityId: string;
	albums: Album[];
}) => {
	const router = useRouter();

	const handleOnClick = useCallback(album => {
		router.navigate('album.view', {
			communityId,
			albumId: album.uuid,
		});
	}, []);

	return (
		<Fragment>
			{albums?.map((item: Album) => (
				<AlbumCard
					key={item.uuid}
					album={item}
					onClick={handleOnClick}
				/>
			))}
		</Fragment>
	);
};
