import { Box, Button, DialogHookOptions, useDialog } from 'components/common';
import { useServices, useTranslation } from 'hooks';
import { Event } from 'core/types';
import MuiDialogActions from '@mui/material/DialogActions';
import { EVENT_SCOPES } from 'core/constants';
import { useDeleteEvent } from 'core/uses-cases/calendar';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogContentText from '@mui/material/DialogContentText';

type DialogContextValue = {
	event: Event;
	communityId: string;
	calendarId: string;
};

export const useDeleteEventHandler = (
	options?: DialogHookOptions<DialogContextValue, number>
) => {
	const { t } = useTranslation();
	const { notification } = useServices();
	const { mutateAsync } = useDeleteEvent();

	const handleOnConfirm = (
		contextData: DialogContextValue,
		scope: number
	) => {
		const { event, calendarId, communityId } = contextData;

		mutateAsync({
			eventId: event.uuid,
			calendarId,
			communityId,
			scope,
		}).then(() => {
			const message =
				scope === EVENT_SCOPES.INSTANCE
					? t('event.event-was-successfully-deleted')
					: t('event.events-were-successfully-deleted');

			notification.show('success', message);
		});

		options?.onConfirm?.(contextData, scope);
	};

	const renderTitle = (contextData: DialogContextValue) => {
		const eventSeriesCount = contextData.event?.event_series_count;

		return (
			<MuiDialogTitle>
				{eventSeriesCount === 1
					? t('event.delete-instance-task')
					: t('event.delete-recurring-task')}
			</MuiDialogTitle>
		);
	};

	const renderContent = (
		confirmHandler: (extra: number) => void,
		cancelHandler: () => void,
		contextData: DialogContextValue
	) => {
		if (!contextData?.event) return null;
		const eventSeriesCount = contextData.event?.event_series_count;

		return (
			<MuiDialogContent>
				<MuiDialogContentText>
					{eventSeriesCount === 1
						? t('event.would-you-like-to-delete-this-task')
						: t('event.delete-task-prompt')}
				</MuiDialogContentText>
			</MuiDialogContent>
		);
	};

	const renderActions = (
		confirmHandler: (extra: number) => void,
		cancelHandler: () => void,
		context: DialogContextValue
	) => {
		const { event } = context;

		const eventSeriesCount = event?.event_series_count;

		return (
			<MuiDialogActions sx={{ paddingX: 2, paddingBottom: 2 }}>
				<Box>
					<Button size='small' onClick={cancelHandler}>
						{t('common.cancel')}
					</Button>
				</Box>
				<Box
					flex={1}
					display='flex'
					flexWrap='nowrap'
					justifyContent='flex-end'
				>
					{eventSeriesCount === 1 ? (
						<Button
							size='small'
							onClick={() => {
								confirmHandler(EVENT_SCOPES.INSTANCE);
							}}
						>
							{t('event.delete-task')}
						</Button>
					) : (
						<>
							<Button
								size='small'
								sx={{ mx: 0.5 }}
								onClick={() => {
									confirmHandler(EVENT_SCOPES.FUTURE);
								}}
							>
								{t('event.delete-all-future-tasks')}
							</Button>
							<Button
								size='small'
								onClick={() => {
									confirmHandler(EVENT_SCOPES.INSTANCE);
								}}
							>
								{t('event.delete-only-this-task')}
							</Button>
						</>
					)}
				</Box>
			</MuiDialogActions>
		);
	};

	const { open } = useDialog<DialogContextValue, number>({
		title: renderTitle,
		content: renderContent,
		renderActions,
		fullWidth: true,
		maxWidth: (contextData: DialogContextValue) =>
			(contextData?.event?.event_series_count ?? 1) > 1 ? 'md' : 'sm',
		onConfirm: handleOnConfirm,
		onCancel: options?.onCancel,
	});

	return open;
};
