import {
	Box,
	Grid,
	PageHeading,
	ButtonRouterLink,
	CircularProgress,
} from 'components/common';
import { useParams, useRouter, useTranslation, useVerifyAccess } from 'hooks';
import { useGetAlbum } from 'core/uses-cases/albums';
import { AlbumPhotoListContainer } from 'components/photos/album-list';

export const ViewAlbum = () => {
	const router = useRouter();
	const { t } = useTranslation();
	const verify = useVerifyAccess();
	const { albumId, communityId } = useParams() as {
		albumId: string;
		communityId: string;
	};

	const { data: album, isLoading } = useGetAlbum(communityId, albumId);

	if (isLoading) {
		return <CircularProgress />;
	}

	const canEdit = verify('update', 'album', {
		identity_permissions: album?.identity_permissions ?? [],
	});

	const canUploadPhoto = verify('upload_photo', 'album', {
		identity_permissions: album?.identity_permissions ?? [],
	});

	return (
		<Box>
			<PageHeading
				title={album?.name}
				rightSection={
					album?.uuid.length === 22 && canEdit ? (
						<ButtonRouterLink
							to={router.getPathFor('album.edit', {
								communityId,
								albumId,
							})}
							size='small'
						>
							{t('albums.view.edit')}
						</ButtonRouterLink>
					) : null
				}
			/>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<AlbumPhotoListContainer
						albumId={albumId}
						communityId={communityId}
						canUploadPhoto={canUploadPhoto}
					/>
				</Grid>
			</Grid>
		</Box>
	);
};
