import { BaseHttpError, useServices } from 'core/services';
import { Event } from 'core/types';
import { calendarKeys } from 'core/utils/query-key-factory';
import { useQuery } from 'react-query';

export const useGetUpcomingEvents = (
	calendarId: string,
	beforeDate = new Date(Date.now() + 12096e5)
) => {
	const { api } = useServices();

	return useQuery<Event[], BaseHttpError>(
		calendarKeys.upcoming(calendarId),
		() =>
			api
				.getEvents(calendarId, {
					params: {
						after_dt: new Date().toISOString(),
						before_dt: beforeDate.toISOString(),
					},
				})
				.then(response => response.data),
		{
			enabled: !!calendarId,
		}
	);
};
