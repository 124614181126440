import { DateTime } from 'luxon';
import { CountryCode, isValidPhoneNumber } from 'libphonenumber-js/max';
import * as Yup from 'yup';
import { PhoneNumberValue } from 'core/types';

export const dateFromLuxon = () =>
	Yup.date().transform((value, originalValue, context) => {
		if (context.isType(value)) return value;

		// check to see if the previous transform already parsed the date
		if (value instanceof DateTime && value.isValid) {
			return value.toJSDate();
		}

		return Yup.date.INVALID_DATE;
	});

export const luxonDateTime = (message?: string, format = 'd/m/yyyy') =>
	Yup.mixed<DateTime>()
		.transform(function (value, _originalValue, context) {
			let datetime = value;

			if (typeof value === 'string') {
				datetime = DateTime.fromFormat(value, format);
			} else if (value instanceof Date) {
				datetime = DateTime.fromJSDate(value);
			} else if (!(datetime instanceof DateTime)) {
				datetime = DateTime.invalid('Unparseable date');
			}

			if (datetime.isValid) return datetime;

			if (
				(value === '' || value === undefined) &&
				context.spec.presence === 'optional'
			) {
				return undefined;
			} else if (value === null && context.spec.nullable) {
				return null;
			} else if (value === null && context.spec.presence === 'required') {
				return undefined;
			}

			return datetime;
		})
		.test('luxonDateTime', message || 'Invalid date', function (value) {
			if (this.schema.spec.nullable && value === null) return true;
			return value
				? value.isValid
				: this.schema.spec.presence === 'optional';
		});

export const phoneNumber = (message?: string) => {
	return Yup.mixed<PhoneNumberValue>()
		.transform(value => {
			if (
				!value ||
				typeof value !== 'object' ||
				!value.number ||
				!value.regionCode
			) {
				return null;
			}

			return value;
		})
		.test(
			'phoneNumber',
			message || 'Invalid phone number',
			function (value) {
				return value
					? isValidPhone(value)
					: this.schema.spec.presence === 'optional';
			}
		);
};

export const isValidPhone = (value?: PhoneNumberValue): boolean => {
	if (
		!value ||
		typeof value !== 'object' ||
		!value.number ||
		!value.regionCode
	) {
		return false;
	}

	const { number, regionCode, dialCode } = value;

	const isValid = isValidPhoneNumber(
		`+${dialCode}${number}`,
		regionCode as CountryCode
	);

	return isValid;
};
