import { useServices } from 'core/services';
import { PaginationArgs } from 'core/services/api/api-client/types';
import { transformCommunityMember } from 'core/services/api/transformers';
import { CommunityMember } from 'core/types';
import { communityKeys } from 'core/utils/query-key-factory';
import { useQuery, useQueryClient } from 'react-query';

export const useGetCommunityMembers = (
	id: string,
	options: { enabled: boolean } = { enabled: true }
) => {
	const { api, authentication } = useServices();

	const isLoggedIn = authentication.isAuthenticated();

	const { data, isSuccess, isLoading, isError, status, error } = useQuery(
		communityKeys.members(id),
		async () => {
			const allMembers = [];
			const requestPagination: PaginationArgs = {};

			do {
				const { data, pagination } = await api.getCommunityMembers(
					id,
					undefined,
					requestPagination
				);

				const mappedData = data.map(member =>
					transformCommunityMember(member)
				);

				allMembers.push(...mappedData);

				if (pagination.next) {
					requestPagination.after =
						allMembers[allMembers.length - 1].identity.uuid;
				} else {
					requestPagination.after = undefined;
				}
			} while (requestPagination.after);

			return allMembers;
		},
		{
			enabled: !!id && isLoggedIn && options.enabled,
			staleTime: 20000, // keep the data for 20 seconds
		}
	);

	return { data, isSuccess, isLoading, isError, status, error };
};

export const useCommunityMembers = (id: string) => {
	const queryClient = useQueryClient();

	return (
		queryClient.getQueryData<CommunityMember[]>(
			communityKeys.members(id)
		) ?? []
	);
};
