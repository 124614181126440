import { Bootstrap } from 'bootstrap';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'theme';
import { Splash } from 'components/common/splash';
import config from 'config';
import Application from './app';
import './base.css';
import 'intl-pluralrules';

// Prevents application from running in an iframe
const isInIframe = config.env !== 'test' && !!window.top?.[0];

if (isInIframe && window.top) {
	window.top.location.href = window.location.pathname;
} else {
	ReactDOM.render(
		<StrictMode>
			<ThemeProvider>
				<BrowserRouter>
					<Bootstrap
						fallback={<Splash message='Loading Application' />}
					>
						<Application />
					</Bootstrap>
				</BrowserRouter>
			</ThemeProvider>
		</StrictMode>,
		document.getElementById('root')
	);
}
