import axios, { AxiosResponse, AxiosError } from 'axios';
import { knownMessages, getErrorMessage } from './messages';
import { HttpErrorTypes } from './types';

type ErrorConstructor = {
	type: HttpErrorTypes;
	message: string;
	originalError: AxiosError;
	remoteMessage?: string;
	errorCode?: number;
	displayError: boolean;
};

export class BaseHttpError extends Error {
	type?: HttpErrorTypes | null;
	response?: AxiosResponse<unknown>;
	originalError: AxiosError;
	remoteMessage?: string;
	errorCode?: number;
	displayError: boolean;

	constructor({
		type,
		message,
		originalError,
		errorCode,
		displayError,
	}: ErrorConstructor) {
		super(message);

		this.type = type;
		this.errorCode = errorCode;
		this.displayError = displayError;
		this.response = originalError?.response;
		this.originalError = originalError;
		this.remoteMessage = originalError.message;

		const newMessage = getProperMessageFromError(this);

		if (newMessage) {
			this.message = newMessage;
		}
	}

	static fromError(error: AxiosError, displayError = true): BaseHttpError {
		return fromError(error, displayError);
	}
}

export class HttpNetworkError extends BaseHttpError {}

export class HttpServerError extends BaseHttpError {
	constructor(params: Omit<ErrorConstructor, 'type'>) {
		super({ type: 'server', ...params });
	}
}
export class HttpUnknownError extends BaseHttpError {
	constructor(displayError: boolean, originalError?: Error) {
		super({
			type: 'unknown',
			message: originalError?.message ?? 'unknown-error',
			originalError: originalError as AxiosError,
			displayError: displayError,
		});
		this.stack = originalError?.stack;
	}
}

export class HttpClientError extends BaseHttpError {
	constructor(params: ErrorConstructor) {
		super({ ...params });
	}
}

/**
 * Check if the given response can be transformed in a custom message
 */
export const getProperMessageFromError = (error: BaseHttpError): string => {
	const { response, errorCode, type } = error;
	const { config, status = '' } = response ?? {};
	const { url = '', baseURL = '', method = '' } = config ?? {};
	const path = url.replace(baseURL, '');

	const isValidType = (input: any) =>
		typeof input === 'string' || typeof input === 'function';

	const statusCode = `${status}`;
	const knownCode = `${errorCode}`;
	const knownType = `${type}`;

	let message = getErrorMessage(path, method, +statusCode);

	if (!message) {
		if (isValidType(knownMessages[knownCode])) {
			message = knownMessages[knownCode];
		} else if (isValidType(knownMessages[knownType])) {
			message = knownMessages[knownType];
		}
	}

	// If message is a function, call it with the error
	if (typeof message === 'function') {
		return message.call(null, error.originalError);
	}

	if (typeof message === 'string') {
		return message;
	}

	return knownMessages.generic;
};

const is400 = (status: number) => status >= 400 && status <= 499;
const is500 = (status: number) => status >= 500 && status <= 599;

const exchangeStatusCodeToType: {
	[key: number]: HttpErrorTypes;
} = {
	401: 'unauthorized',
	403: 'forbidden',
	404: 'not-found',
	400: 'bad-request',
	422: 'unprocessable-entity',
	429: 'too-many-requests',
	0: 'rejected',
};

export const fromError = (
	error: AxiosError,
	displayError: boolean
): BaseHttpError => {
	if (
		error.message.includes('Network Error') ||
		error.message.includes('ECONNREFUSED')
	) {
		return new HttpNetworkError({
			type: 'connection',
			message: 'connection-error',
			originalError: error,
			displayError: false,
		});
	}

	if (
		error.message.startsWith('timeout') ||
		error.code?.includes('ECONNABORTED')
	) {
		return new HttpNetworkError({
			type: 'timeout',
			message: 'timeout-error',
			originalError: error,
			displayError: displayError,
		});
	}

	if (axios.isCancel(error)) {
		return new HttpClientError({
			type: 'canceled',
			message: 'cancelled',
			originalError: error,
			displayError: false,
		});
	}

	if (error.response?.status) {
		const statusCode = error.response.status;

		try {
			error.response.data =
				typeof error.response.data === 'string' && !!error.response.data
					? JSON.parse(error.response.data)
					: error.response.data;
		} catch (e) {
			error.response.data = {};
		}

		if (is400(statusCode)) {
			return new HttpClientError({
				message: 'client-error',
				originalError: error,
				type: exchangeStatusCodeToType[statusCode || 0],
				errorCode: error.response.data?.code ?? -1,
				displayError: displayError,
			});
		} else if (is500(statusCode)) {
			return new HttpServerError({
				message: 'server-error',
				originalError: error,
				errorCode: error.response.data?.code ?? -1,
				displayError: displayError,
			});
		}
	} else if (error instanceof BaseHttpError) {
		return error;
	}

	return new HttpUnknownError(displayError, error);
};

export const isUnauthorizedError = (error: Error): boolean => {
	return error instanceof HttpClientError && error.type === 'unauthorized';
};

export const isTooManyRequestsError = (error: Error): boolean => {
	return (
		error instanceof HttpClientError && error.type === 'too-many-requests'
	);
};

export const isBadRequestError = (
	error: Error
): error is HttpClientError & { type: 'bad-request'; response: any } => {
	return (
		error instanceof HttpClientError &&
		error.type === 'bad-request' &&
		Boolean(error.response)
	);
};

export const isServerError = (error: Error): boolean => {
	return error instanceof HttpServerError;
};
