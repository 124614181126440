import { ButtonProps } from '@mui/material/Button';
import { Theme } from 'theme';

export const styles = ({
	theme,
	variant,
	color,
}: ButtonProps & { theme: Theme }) => ({
	'&:focus': {
		boxShadow: '0 0 0 0.2rem rgb(38 143 255 / 50%)',
	},
	'&.Mui-disabled': {
		opacity: 0.65,
		...(variant === 'outlined' && {
			border: `1px solid ${theme.palette.buttonPrimary.main}`,
			color: theme.palette.buttonPrimary.main,
		}),
		...(variant === 'outlined' &&
			color === 'secondary' && {
			border: `1px solid ${theme.palette.secondary.main}`,
			color: theme.palette.secondary.main,
		}), // prettier-ignore
		...(variant === 'outlined' &&
			color === 'error' && {
			border: `1px solid ${theme.palette.error.main}`,
			color: theme.palette.error.main,
		}), // prettier-ignore
		...(variant === 'outlined' &&
			color === 'info' && {
			border: `1px solid ${theme.palette.info.main}`,
			color: theme.palette.info.main,
		}), // prettier-ignore
		...(variant === 'outlined' &&
			color === 'warning' && {
			border: `1px solid ${theme.palette.warning.main}`,
			color: theme.palette.warning.main,
		}), // prettier-ignore
		...(variant === 'contained' && {
			color: theme.palette.common.white,
			backgroundColor: theme.palette.buttonPrimary.main,
		}),
		...(variant === 'contained' &&
			color === 'secondary' && {
			backgroundColor: theme.palette.secondary.main,
		}), // prettier-ignore
		...(variant === 'contained' &&
			color === 'error' && {
			backgroundColor: theme.palette.error.main,
		}), // prettier-ignore
		...(variant === 'contained' &&
			color === 'warning' && {
			backgroundColor: theme.palette.warning.main,
		}), // prettier-ignore
		...(variant === 'contained' &&
			color === 'info' && {
			backgroundColor: theme.palette.info.main,
		}), // prettier-ignore
		...(variant === 'text' && {
			color: theme.palette.buttonPrimary.main,
		}),
		...(variant === 'text' &&
			color === 'secondary' && {
			color: theme.palette.secondary.main,
		}), // prettier-ignore
		...(variant === 'text' &&
			color === 'error' && {
			color: theme.palette.error.main,
		}), // prettier-ignore
		...(variant === 'text' &&
			color === 'warning' && {
			color: theme.palette.warning.main,
		}), // prettier-ignore
		...(variant === 'text' &&
			color === 'info' && {
			color: theme.palette.info.main,
		}), // prettier-ignore
	},
});
