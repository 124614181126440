import { BaseHttpClient } from '../base-http-client';
import {
	MemberPreferencesResponse,
	SubscriptionPreferencesResponse,
} from '../response-types';
import {
	PatchIdentityPreferencesInput,
	SubscriptionPreferencesInput,
} from './types';

export const createSubscriptionApi = (http: BaseHttpClient) => ({
	/**
	 * Gets the subscription preferences for the account+community in the token.
	 *
	 * @param {string} communityId
	 * @param {string} identityId
	 * @return {*}
	 */
	getMemberPreferences(communityId: string, identityId: string) {
		return http
			.get<MemberPreferencesResponse>(
				`community/${communityId}/identity/${identityId}/notification`
			)
			.then(response => response.data);
	},

	patchMemberPreferences(
		communityId: string,
		identityId: string,
		input: PatchIdentityPreferencesInput
	) {
		const {
			eventReminderTime,
			digestFrequency,
			announcementSubscriptionStatus,
			emailDigestSubscriptionStatus,
			dismissedWelcomeMessage,
		} = input;

		return http
			.patch<MemberPreferencesResponse>(
				`community/${communityId}/identity/${identityId}/notification`,
				{
					event_reminder_time: eventReminderTime,
					announcement_subscription_status:
						announcementSubscriptionStatus,
					email_digest_subscription_status:
						emailDigestSubscriptionStatus,
					digest_frequency: digestFrequency,
					dismissed_welcome_message: dismissedWelcomeMessage,
				}
			)
			.then(response => response.data);
	},

	/**
	 * Gets the subscription preferences for the account+community in the token.
	 *
	 * @param {string} jwtToken
	 * @return {*}
	 */
	getSubscriptionPreferences(jwtToken: string) {
		return http
			.get<SubscriptionPreferencesResponse>('subscription', {
				jwt: jwtToken,
			})
			.then(response => response.data);
	},

	/**
	 * Update subscription preferences
	 *
	 * @param {string} jwtToken
	 * @param {SubscriptionPreferencesInput} input
	 * @return {*}
	 */
	patchSubscriptionPreferences(
		jwtToken: string,
		input: SubscriptionPreferencesInput
	) {
		const {
			digestFrequency,
			announcementSubscriptionStatus,
			emailDigestSubscriptionStatus,
			deliveryTime,
			reason,
			otherReason,
		} = input;

		return http
			.patch<SubscriptionPreferencesResponse>(
				'subscription',
				{
					announcement_subscription_status:
						announcementSubscriptionStatus,
					email_digest_subscription_status:
						emailDigestSubscriptionStatus,
					digest_frequency: digestFrequency,
					delivery_time: deliveryTime,
					reason,
					other_reason: otherReason,
					...(digestFrequency === 'Weekly' && {
						custom_day: input.customDay,
					}),
					...(digestFrequency === 'Custom' && {
						custom_days: input.customDays,
					}),
				},
				{
					params: {
						jwt: jwtToken,
					},
				}
			)
			.then(response => response.data);
	},
});
