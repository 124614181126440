import {
	NumberFormat,
	ParsedNumber,
	parsePhoneNumberFromString,
	formatNumber as libFormatNumber,
} from 'libphonenumber-js';

export const parsePhoneNumber = (phoneNumber: string): ParsedNumber => {
	const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber);

	return {
		phone: parsedPhoneNumber?.nationalNumber,
		country: parsedPhoneNumber?.country,
		countryCallingCode: parsedPhoneNumber?.countryCallingCode,
	} as ParsedNumber;
};

export const formatNumber = (
	parsedPhoneNumber: ParsedNumber,
	format: NumberFormat
): string => libFormatNumber(parsedPhoneNumber, format);
