import { SxProps } from '@mui/system';
import { Theme } from 'theme';

export const navButtonStyle: SxProps<Theme> = {
	marginX: 0.5,
	textTransform: 'none',
	paddingX: 1.5,
	paddingY: 1,
};

export const labelStyle: SxProps<Theme> = {
	paddingTop: 1,
	marginRight: 2,
	width: 200,
	whiteSpace: 'normal',
};

export const checkboxSummaryLabelStyle: SxProps<Theme> = theme => ({
	display: 'block',
	alignSelf: 'center',
	paddingX: 1,
	borderWidth: 1,
	borderStyle: 'dotted',
	borderColor: 'transparent',
	cursor: 'pointer',
	textDecoration: 'underline',
	textDecorationStyle: 'dotted',
	textUnderlinePosition: 'under',
	textDecorationColor: theme.primaryColors.primary5,
	textDecorationThickness: '1px',
	textUnderlineOffset: '3px',
	'&:hover': {
		textDecoration: 'none',
		borderColor: theme.primaryColors.primary5,
	},
});

export const gridStyle: SxProps<Theme> = theme => ({
	overflow: 'scroll',
	height: 'calc(100vh - 410px)',
	paddingBottom: 5,

	'&::-webkit-scrollbar': {
		WebkitAppearance: 'none',
		width: 5,
		height: 5,
	},

	'&::-webkit-scrollbar-thumb': {
		borderRadius: 5,
		backgroundColor: theme.blacks[0.54],
	},
});

export const gridRowStyle: SxProps<Theme> = theme => ({
	minWidth: 'auto',
	display: 'flex',
	flexDirection: 'row',
	flexWrap: 'nowrap',
	borderBottomWidth: '1px',
	borderBottomStyle: 'solid',
	borderBottomColor: theme.borderColors.default,
	backgroundColor: theme.palette.background.paper,

	'&.grid-row': {
		width: '100%',
	},

	'&.grid-group-row': {
		'& div.grid-cell, & .group-cell-header': {
			backgroundColor: theme.secondaryColors.secondary10,
		},
	},
});

export const gridCellStyle: SxProps<Theme> = theme => ({
	display: 'flex',
	alignItems: 'center',
	borderRightWidth: '1px',
	borderRightStyle: 'solid',
	borderRightColor: theme.borderColors.default,
	backgroundColor: theme.palette.background.paper,
	'& > p, & > span': {
		display: '-webkit-box',
		textAlign: 'left',
		lineHeight: '1',
		WebkitLineClamp: '2',
		WebkitBoxOrient: 'vertical',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	'&.group-cell-header': {
		cursor: 'pointer',
	},
});
