import { useCurrentIdentity } from 'core/uses-cases/authentication';
import { useCurrentCommunity } from 'hooks';

export const useShowGlobalPermission = (): boolean => {
	const community = useCurrentCommunity();
	const identity = useCurrentIdentity();

	return (
		identity?.uuid === community?.owner?.uuid &&
		(community?.has_advanced_permissions ?? false)
	);
};
