import {
	Box,
	Field,
	FieldForSubscription,
	TimePickerField,
	FieldArray,
	TextFieldProps,
	Button,
	IconButton,
	Icon,
	Grid,
} from 'components/common';
import { FieldMetaState } from 'react-final-form';
import { useTranslation } from 'hooks';

export type TimeSlotValue = {
	start: string | null;
	end: string | null;
};

export type TimeSlotsFieldProps = {
	name: string;
	meta: FieldMetaState<unknown>;
	onDelete: () => void;
	showLabels?: boolean;
};

export const TimeSlotsField = (props: TimeSlotsFieldProps) => {
	const { name, onDelete, showLabels = false } = props;

	const { t } = useTranslation();

	return (
		<Grid container spacing={1}>
			<Grid item xs={5}>
				<Field
					component={TimePickerField}
					name={`${name}.start`}
					label={showLabels ? t('event-form.start-time') : null}
					validateImmediately
				/>
			</Grid>
			<Grid item xs={5}>
				<Field
					component={TimePickerField}
					name={`${name}.end`}
					label={showLabels ? t('event-form.end-time') : null}
					validateFields={[`${name}.start`]}
				/>
			</Grid>
			<Grid item xs={2} alignSelf='flex-start'>
				<Box mt={showLabels ? 4 : 0}>
					<IconButton onClick={onDelete}>
						<Icon name='delete' />
					</IconButton>
				</Box>
			</Grid>
		</Grid>
	);
};

export type TimeSlotsFieldsProps = Omit<
	TextFieldProps,
	| 'value'
	| 'onChange'
	| 'onBlur'
	| 'onFocus'
	| 'multiline'
	| 'type'
	| 'rows'
	| 'maxRows'
	| 'minRows'
	| 'select'
	| 'SelectProps'
	| 'type'
	| 'defaultValue'
> & {
	name: string;
};

export const TimeSlotsFields = (props: TimeSlotsFieldsProps) => {
	const { name, label } = props;
	const { t } = useTranslation();

	return (
		<FieldArray<TimeSlotValue> name={name} label={label} defaultValue={[]}>
			{({ fields, meta, hasError }) => {
				return (
					<>
						{fields.map((name, index) => (
							<Box key={name} mb={1}>
								<TimeSlotsField
									name={name}
									onDelete={() => fields.remove(index)}
									meta={meta}
									showLabels={index === 0}
								/>
							</Box>
						))}
						<Box>
							<FieldForSubscription
								name={name}
								subscription={{ value: true }}
							>
								{({ input: { value } }) => {
									const lastItem =
										value?.slice(-1)?.pop() ?? null;

									const disabledButton =
										lastItem?.start === null &&
										lastItem?.end === null;

									return (
										<Button
											type='button'
											variant='outlined'
											size='small'
											disabled={
												hasError || disabledButton
											}
											onClick={() => {
												fields.push({
													start: null,
													end: null,
												});
											}}
											startIcon={
												<Icon name='add_circle_outline' />
											}
										>
											{t('event-form.add-time-slot')}
										</Button>
									);
								}}
							</FieldForSubscription>
						</Box>
					</>
				);
			}}
		</FieldArray>
	);
};
