import { useMutation, Mutation } from 'react-query';
import { Nullable, Identity, Image } from 'core/types';
import { patchIdentityMutationKey } from 'core/utils/mutation-key-factory';
import { SetupMutationFn } from 'core/configure/types';
import { cancelPreviousMutation } from 'core/utils/optimistic-utils';
import { authenticationKeys } from 'core/utils/query-key-factory';
import { mapLanguage } from 'core/utils/mapper';
import { LAST_LOGIN_DT_KEY } from 'core/constants';
import { BaseHttpError } from '../../services/api/errors';
import { PatchIdentityInput } from '../../services/api/api-client/types';

export interface PatchIdentityMutationInput
	extends Omit<PatchIdentityInput, 'image'> {
	identityId: string;
	image?: Nullable<Image>;
}

export const usePatchIdentity = () => {
	const mutation = useMutation<
		Identity,
		BaseHttpError,
		PatchIdentityMutationInput
	>(patchIdentityMutationKey);

	return mutation;
};

type MutationContext = {
	identityId: string;
	originalIdentity: Identity | null;
};

export const setupPatchIdentity: SetupMutationFn = (
	services,
	createTrackedParallelMutation
) => {
	const { queryClient, api, uploader, cookie } = services;

	const mutation = createTrackedParallelMutation<
		Identity,
		BaseHttpError,
		PatchIdentityMutationInput,
		MutationContext
	>({
		mutationFn: async ({ identityId, ...values }) => {
			const remoteImage = await uploader.uploadImage(values.image);

			return api
				.patchIdentity(identityId, {
					...values,
					image: remoteImage?.uuid ?? null,
				})
				.then(response => response.data);
		},
		onMutate: async input => {
			const {
				identityId,
				firstName,
				lastName,
				email,
				phone,
				address,
				detectTimezone,
				image,
				language,
				location,
			} = input;

			const originalIdentity = queryClient.getQueryData<Identity>(
				authenticationKeys.identity
			) as Identity;

			const updatedIdentity: Identity = {
				uuid: identityId,
				first_name: firstName ?? '',
				last_name: lastName ?? '',
				email: email ?? '',
				phone: phone ?? '',
				address: address ?? '',
				location: location ?? '',
				autodetect_timezone: detectTimezone ?? false,
				language: mapLanguage(language ?? 'english'),
				image: image,
				timezone: originalIdentity?.timezone ?? '',
				created_dt: originalIdentity?.created_dt ?? '',
				my_communities: originalIdentity?.my_communities ?? [],
				my_pending_communities:
					originalIdentity?.my_pending_communities ?? [],
				my_invited_communities:
					originalIdentity?.my_invited_communities ?? [],
				not_before_dt: originalIdentity.not_before_dt,
			};

			await cancelPreviousMutation<MutationContext>(
				queryClient,
				mutation => {
					return (
						mutation.state.context?.identityId ===
							input.identityId &&
						isPatchIdentityMutation(mutation)
					);
				}
			);

			queryClient.setQueryData(
				authenticationKeys.identity,
				updatedIdentity
			);

			return {
				identityId,
				originalIdentity: originalIdentity,
			};
		},
		onSuccess: (result, { logMeOut }) => {
			if (result.not_before_dt && logMeOut === true) {
				// Update last login time
				cookie.set(LAST_LOGIN_DT_KEY, result.not_before_dt);
			}
		},
		onError: (error, _newIdentity, context) => {
			if (context?.originalIdentity) {
				queryClient.setQueryData(
					authenticationKeys.identity,
					context.originalIdentity
				);
			}
		},
		onSettled: () => {
			queryClient.invalidateQueries(authenticationKeys.identity);
		},
		retry: 1,
	});

	queryClient.setMutationDefaults(patchIdentityMutationKey, mutation);
};

export const isPatchIdentityMutation = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	mutation: Mutation<any, any, any, any>
) =>
	[patchIdentityMutationKey].includes(String(mutation?.options?.mutationKey));
