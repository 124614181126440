import { Dispatch, SetStateAction } from 'react';
import {
	DraggableContainer,
	DraggableItem,
	DropLocation,
} from 'components/common/draggableList';
import { Box } from 'components/common';
import { Album } from 'core/types';
import { useTheme } from '@mui/material';
import { Theme } from 'theme';
import { AlbumCard } from './card';

export const DraggableAlbumListContainer = ({
	albums,
	setAlbums,
}: {
	albums: Album[];
	setAlbums: Dispatch<SetStateAction<Album[]>>;
}) => {
	const theme = useTheme<Theme>();

	return (
		<Box sx={{ mb: 2 }}>
			<DraggableContainer list={albums} setList={setAlbums}>
				<DropLocation
					id='albumDrop'
					color=''
					dragOverColor={theme.secondaryColors.secondary9 as string}
				>
					{albums?.map((item: Album, index: number) => (
						<DraggableItem
							key={item.uuid}
							id={item.uuid}
							index={index}
						>
							{item.type === 'user' && (
								<AlbumCard album={item} draggable />
							)}
						</DraggableItem>
					))}
				</DropLocation>
			</DraggableContainer>
		</Box>
	);
};
