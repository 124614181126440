import { Checkbox, Collapse } from '@mui/material';
import { Box, Typography, Icon } from 'components/common';
import { Group, AdvanceCommunityPermissions } from 'core/types';
import { getDefaultGlobalPermissions } from 'core/uses-cases/community';
import { FC, useState, useRef } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { GridRow, GridCellHeader, GridCell } from './grid';

const defaultPermissions = getDefaultGlobalPermissions();

export const ResourceGridGroupRow: FC<{
	label: string;
	groups: Group[];
	resourceKey: keyof AdvanceCommunityPermissions;
}> = ({ label, children, resourceKey, groups }) => {
	const [open, setOpen] = useState(true);
	const prevSelectedBeforeEveryone = useRef<Record<string, string[]>>();

	const form = useForm();
	const { values } = useFormState<Record<string, Record<string, string[]>>>({
		subscription: { values: true },
	});

	const handleClick = () => setOpen(!open);

	const memberUuid = groups.find(group => group.name === 'member')
		?.uuid as string;
	const adminUuid = groups.find(group => group.name === 'site_admin')
		?.uuid as string;

	const getCheckboxProps = (groupId: string, name: string) => {
		const permissionsIterable = Object.entries(
			defaultPermissions[resourceKey]
		);
		const { total, checked, totalCheckedMembers } =
			permissionsIterable.reduce(
				(carry, [permission]) => {
					const value = values[resourceKey][permission] || [];
					carry.total++;
					carry.checked += value.includes(groupId) ? 1 : 0;
					carry.totalCheckedMembers += value.includes(memberUuid)
						? 1
						: 0;
					return carry;
				},
				{ total: 0, checked: 0, totalCheckedMembers: 0 }
			);

		const isChecked = checked === total;
		const isIndeterminate = checked !== 0 && checked !== total;
		const isDisabled = name !== 'member' && totalCheckedMembers === total;

		return {
			checked: name === 'site_admin' ? !isDisabled : isChecked,
			indeterminate: isIndeterminate,
			disabled: name !== 'site_admin' ? isDisabled : true,
			onChange: () => {
				const wasChecked = isChecked;

				permissionsIterable.forEach(([permission]) => {
					const currentValues =
						values?.[resourceKey]?.[permission] ?? [];

					if (wasChecked) {
						let nextValues = currentValues.filter(
							uuid => uuid !== groupId
						);

						if (
							name === 'member' &&
							prevSelectedBeforeEveryone.current &&
							nextValues.length === 0
						) {
							nextValues =
								prevSelectedBeforeEveryone.current?.[
									permission
								] ?? [];
						}

						form.change(`${resourceKey}.${permission}`, nextValues);
					} else {
						if (name === 'member') {
							prevSelectedBeforeEveryone.current =
								values?.[resourceKey];

							form.change(`${resourceKey}.${permission}`, [
								groupId,
							]);
						} else if (!currentValues.includes(memberUuid)) {
							form.change(`${resourceKey}.${permission}`, [
								...currentValues,
								groupId,
							]);
						}
					}
				});
			},
		};
	};

	const groupHeaders = groups.filter(
		({ name }) => name !== 'site_admin' && name !== 'member'
	);

	return (
		<>
			<GridRow className='grid-group-row'>
				<GridCellHeader onClick={handleClick}>
					<Box flex={1}>
						<Typography variant='button2'>{label}</Typography>
					</Box>
					{open ? (
						<Icon name='expand_less' />
					) : (
						<Icon name='expand_more' />
					)}
				</GridCellHeader>
				<GridCell>
					<Checkbox {...getCheckboxProps(memberUuid, 'member')} />
				</GridCell>
				{groupHeaders.map(({ uuid, name }) => (
					<GridCell key={`permission-group-resource-${uuid}`}>
						<Checkbox {...getCheckboxProps(uuid, name)} />
					</GridCell>
				))}
				<GridCell>
					<Checkbox {...getCheckboxProps(adminUuid, 'site_admin')} />
				</GridCell>
			</GridRow>
			<Collapse in={open} timeout='auto' unmountOnExit>
				{children}
			</Collapse>
		</>
	);
};
