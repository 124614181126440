import { useState } from 'react';
import {
	Box,
	Button,
	Field,
	Form,
	FormStack,
	LoadingButton,
	TextField,
	Typography,
} from 'components/common';
import pencilIcon from 'assets/images/pencil.svg?url';
import { useTranslation } from 'react-i18next';
import { buttonContainer, editBox } from './styles';

export type NameEditorSubmittedValues = {
	name: string;
};

type NameEditorProps = {
	name: string;
	canEditName: boolean;
	onSubmit: (values: NameEditorSubmittedValues) => Promise<void>;
	submitText?: string;
	submittingText?: string;
};

export const NameEditor = ({
	name,
	canEditName,
	onSubmit,
	submitText,
	submittingText,
}: NameEditorProps) => {
	const [showEditor, setShowEditor] = useState(false);
	const { t } = useTranslation();

	const toggleEditor = () => {
		setShowEditor(!showEditor);
	};

	const handleSubmit = (values: NameEditorSubmittedValues) => {
		onSubmit(values);

		toggleEditor();
	};

	return (
		<Box mt={3}>
			{!showEditor && canEditName ? (
				<Box sx={editBox} onClick={toggleEditor}>
					<Typography textAlign='center' fontWeight='bold'>
						<img
							src={pencilIcon}
							alt={t('photos.view.pencil-icon')}
						/>
						{name}
					</Typography>
				</Box>
			) : (
				<Typography textAlign='center' fontWeight='bold'>
					{name}
				</Typography>
			)}
			{showEditor && (
				<Box>
					<Form onSubmit={handleSubmit} initialValues={{ name }}>
						{({ handleSubmit, submitting }) => (
							<form onSubmit={handleSubmit} noValidate>
								<FormStack responsive={false}>
									<Box>
										<Field
											name='name'
											component={TextField}
											type='text'
											fullWidth
											required
										/>
									</Box>
									<Box sx={buttonContainer}>
										<LoadingButton
											loading={submitting}
											loadingIndicator={
												submittingText ||
												t('form.submitting')
											}
											size='small'
											type='submit'
										>
											{submitText || t('form.submit')}
										</LoadingButton>
										<Button
											variant='outlined'
											size='small'
											type='button'
											onClick={toggleEditor}
										>
											{t('common.cancel')}
										</Button>
									</Box>
								</FormStack>
							</form>
						)}
					</Form>
				</Box>
			)}
		</Box>
	);
};
