import { QueryClient, QueryClientConfig } from 'react-query';
import {
	Persistor,
	persistQueryClient,
} from 'react-query/persistQueryClient-experimental';

export const createQueryClient = (
	config?: QueryClientConfig,
	persistor?: Persistor,
	onlineSubscription?: (setOnline: (isOnline: boolean) => void) => void
) => {
	const defaultConfig = {
		...config,
		defaultOptions: {
			...config?.defaultOptions,
			queries: {
				refetchOnWindowFocus: false,
				retryOnMount: false,
				refetchOnReconnect: true,
				retry: false,
				cacheTime: 1000 * 60 * 2, // 2 minutes
				...config?.defaultOptions?.queries,
			},
		},
	};

	const queryClient = new QueryClient({
		...defaultConfig,
	});

	if (persistor) {
		persistQueryClient({
			queryClient,
			persistor,
		});
	}

	const handleOfflineModeForQueries = (isOnline: boolean) => {
		queryClient.setDefaultOptions({
			queries: {
				...defaultConfig.defaultOptions.queries,
				...(!isOnline && {
					cacheTime: 1000 * 60 * 60 * 24, // 24 hours
				}),
				staleTime: !isOnline ? Infinity : 0,
			},
		});
	};

	onlineSubscription?.(handleOfflineModeForQueries);

	return queryClient;
};
