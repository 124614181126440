import { Box, CircularProgress, Text } from 'components';
import { withTenantTemplate } from 'tenants/react';

type SplashProps = {
	message?: string;
};

export function SplashBase(props: SplashProps) {
	const { message } = props;

	return (
		<Box
			className='splash'
			alignItems='center'
			justifyContent='center'
			display='flex'
			flexDirection='column'
			minHeight='100vh'
			width='100%'
		>
			<CircularProgress />
			{message && <Text mt='2'>{message}</Text>}
		</Box>
	);
}

export const Splash = withTenantTemplate(
	SplashBase,
	// This is the current relative path (TODO: make this dynamic)
	'components/common/splash/splash.tsx'
);
