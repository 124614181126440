import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl, { FormControlProps } from '@mui/material/FormControl';
import InputLabel, { InputLabelProps } from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText/FormHelperText';
import { useMemo } from 'react';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import FormGroup, { FormGroupProps } from '@mui/material/FormGroup';
import { Box } from '../../layout';

interface CheckboxGroupFieldProps extends FormControlProps {
	options: {
		label: string | React.ReactElement;
		value: any;
		disabled?: boolean;
	}[];
	value: any;
	label?: string;
	inlineLabel?: boolean;
	LabelProps?: InputLabelProps;
	CheckBoxProps?: CheckboxProps;
	FormGroupProps?: FormGroupProps;
	name?: string;
	helperText?: string;
	onChange: (value: any) => void;
	renderOptions?: (option: any) => React.ReactElement;
}

export const CheckboxGroupItem = ({
	label,
	disabled,
	checked,
	onChange,
	...props
}: {
	label: string | React.ReactElement;
	disabled?: boolean;
	checked?: boolean;
	onChange?: (value: any) => void;
} & CheckboxProps) => {
	return (
		<FormControlLabel
			control={
				<Checkbox {...props} checked={checked} onChange={onChange} />
			}
			label={label}
			disabled={disabled}
			componentsProps={{
				typography: {
					variant: 'body2',
				},
			}}
		/>
	);
};

export function CheckboxGroupField(props: CheckboxGroupFieldProps) {
	const {
		options = [],
		value: currentValue = [],
		label,
		inlineLabel = false,
		LabelProps,
		CheckBoxProps,
		FormGroupProps,
		helperText,
		onChange,
		children,
		...rest
	} = props;

	const handleChange = (value: any) => {
		let newValue = [...currentValue];

		const index = newValue.findIndex((item: any) => item === value);

		if (index < 0) {
			newValue = [...currentValue, value];
		} else {
			newValue.splice(index, 1);
		}

		onChange?.(newValue);
	};

	const items = useMemo(() => {
		return options.map(({ label, value, disabled }) => {
			return (
				<CheckboxGroupItem
					key={`${String(value)}-${label}`}
					{...CheckBoxProps}
					checked={Boolean(currentValue.includes(value))}
					onChange={handleChange.bind(null, value)}
					label={label}
					disabled={disabled}
				/>
			);
		});
	}, [options, currentValue]);

	return (
		<FormControl {...rest}>
			<Box display='flex' flexDirection={inlineLabel ? 'row' : 'column'}>
				{label && (
					<InputLabel
						{...(inlineLabel ? { sx: { pt: 1, mr: 2 } } : {})}
						{...LabelProps}
					>
						{label}
					</InputLabel>
				)}
				<FormGroup {...FormGroupProps}>
					{items}
					{children}
				</FormGroup>
			</Box>
			{helperText && <FormHelperText>{helperText}</FormHelperText>}
		</FormControl>
	);
}
