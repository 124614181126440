import { Identity } from 'core/types';
import { DateTime } from 'luxon';
import { createContext, useContext } from 'react';
import { CalendarEvent, CalendarViewTypes } from '../types';

export type EventClickCallback = (event: CalendarEvent) => void;

export type CalendarLoadEventsFilter = {
	startDate: DateTime;
	endDate: DateTime;
};

export type CalendarFilter = {
	statuses: string[];
	eventTypes: string[];
	memberIds: string[];
};

export type LoadEventFn = (
	options:
		| CalendarLoadEventsFilter
		| ((
				currentFilter: CalendarLoadEventsFilter
		  ) => CalendarLoadEventsFilter)
) => void;

export interface CalendarContextValue {
	timezone: string;
	locale: string;
	loadEventsStatus: 'error' | 'idle' | 'loading' | 'success';
	displayed: DateTime;
	selected: DateTime | null;
	events: CalendarEvent[];
	eventsMap: Map<string, CalendarEvent[]>;
	members: Identity[];
	setDisplayed: (date: DateTime) => void;
	setSelected: (date: DateTime | null) => void;
	currentView?: CalendarViewTypes;
	setCurrentView: (view: CalendarViewTypes) => void;
	formatDate: (date: DateTime, format: string) => string;
	loadEvents: LoadEventFn;
	onEventClick: EventClickCallback;
	// UI filter
	filter: CalendarFilter;
	setFilter: (filter: CalendarFilter) => void;
	exportLink: string;
}

export const CalendarContext = createContext<CalendarContextValue>(
	{} as CalendarContextValue
);

export const useCalendar = (): CalendarContextValue => {
	const calendar = useContext(CalendarContext);

	if (!calendar) {
		throw new Error('useCalendar must be used within a CalendarProvider');
	}

	return calendar;
};
