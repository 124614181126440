import { BaseHttpError, useServices } from 'core/services';
import { PatchPasswordResponse } from 'core/services/api/response-types';
import { patchPasswordMutationKey } from 'core/utils/mutation-key-factory';
import { useMutation } from 'react-query';
import { useSetCurrentIdentity } from '../authentication/use-login';

interface PatchPasswordMutationInput {
	password: string;
	logout: boolean;
	token: string;
}

export const usePatchPassword = () => {
	const { api } = useServices();
	const setCurrentIdentity = useSetCurrentIdentity();

	const mutation = useMutation<
		PatchPasswordResponse,
		BaseHttpError,
		PatchPasswordMutationInput
	>(
		({ password, logout, token }) =>
			api.patchPassword(password, logout, token),
		{
			mutationKey: patchPasswordMutationKey,
			onSuccess: data => {
				setCurrentIdentity(data.data.access_token, data.data.identity);
			},
		}
	);

	return {
		post: mutation.mutate,
		postAsync: mutation.mutateAsync,
		...mutation,
	};
};
