import { Fragment } from 'react';
import { Footer, Content } from '../shared';
import { AppHeader } from '../app/header';

export const OuterLayout: React.FC = ({ children }) => {
	return (
		<Fragment>
			<AppHeader />
			<Content withBackground>{children}</Content>
			<Footer />
		</Fragment>
	);
};
