import { SxProps } from '@mui/system';
import { Theme } from 'theme';

export const imageStyle: SxProps<Theme> = {
	width: 200,
	mb: 2,
	alignSelf: 'center',
	'> img': {
		width: '100%',
	},
};

export const dividerStyle: SxProps<Theme> = theme => ({
	color: theme.textColors.text,
	marginTop: 4,
	marginBottom: 2,
});

export const buttonSectionStyle: SxProps<Theme> = {
	display: 'flex',
	justifyContent: 'space-between',
};
