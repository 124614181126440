import { BaseHttpError } from 'core/services/api/errors';
import { Note } from 'core/types';
import { PaginatedRecords } from 'core/utils/optimistic-utils';
import { noteKeys } from 'core/utils/query-key-factory';
import { useServices } from 'hooks';
import { useCallback } from 'react';
import { useQuery, useQueryClient, onlineManager } from 'react-query';

export const useGetNote = (
	communityId: string,
	noteId: string,
	options: { enable: boolean } = { enable: true }
) => {
	const { api, authentication } = useServices();

	const isLoggedIn = authentication.isAuthenticated();

	const queryClient = useQueryClient();

	const getInitialData = useCallback(() => {
		let note;

		// If it is online, then fetch the announcement from the API
		if (onlineManager.isOnline()) return note;

		const result = queryClient.getQueryData<PaginatedRecords<Note>>(
			noteKeys.list(communityId)
		);

		for (const page of result?.pages ?? []) {
			note = page.data.find(record => record.uuid === noteId);
			if (note) break;
		}

		return note;
	}, [noteId]);

	const noteQuery = useQuery<Note, BaseHttpError>(
		noteKeys.detail(communityId, noteId),
		() => api.getNote(noteId).then(response => response.data),
		{
			enabled: !!noteId && isLoggedIn && options.enable,
			initialData: getInitialData,
		}
	);

	return noteQuery;
};
