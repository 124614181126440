import { SxProps } from '@mui/system';
import { Theme } from 'theme';

export const containerStyle: SxProps<Theme> = {
	position: 'relative',
};

export const listStyle: SxProps<Theme> = {
	position: 'relative',
};

export const buttonStyle: SxProps<Theme> = {
	position: 'relative',
	display: 'flex',
	justifyContent: 'center',
};

export const endListStyle: SxProps<Theme> = {
	position: 'absolute',
	bottom: 0,
	left: -4,
	right: -4,
	zIndex: -1,
};
