import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';

class DateAdapter extends AdapterLuxon {
	public getWeekdays = () => {
		const language = this.getCurrentLocaleCode();

		if (language === 'fr-FR') {
			return ['L', 'M', 'M', 'J', 'V', 'S', 'D'];
		}

		return ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
	};

	getWeekArray = (date: DateTime) => {
		const monthStart = date.startOf('month').startOf('week');
		const monthEnd = date.endOf('month').endOf('week');

		const { days } = monthEnd.diff(monthStart, 'days');

		const weeks = Array(Math.round(days ?? 0) + 7)
			.fill(0)
			.map((_, day) => monthStart.plus({ days: day - 1 }))
			.reduce((acc, curr) => {
				const language = this.getCurrentLocaleCode();
				const luxonDayOfTheWeeK = curr.toFormat('c');

				if (language === 'fr-FR') {
					if (luxonDayOfTheWeeK === '1') {
						acc.push([curr]);
						return acc;
					}
				} else {
					if (luxonDayOfTheWeeK === '7') {
						acc.push([curr]);
						return acc;
					}
				}

				if (acc.length > 0) {
					acc[acc.length - 1].push(curr);
				}

				return acc;
			}, [] as DateTime[][])
			.filter(week => {
				return (
					week[0].hasSame(date, 'month') ||
					week[week.length - 1].hasSame(date, 'month')
				);
			});

		return weeks;
	};
}

export default DateAdapter;
