import { DialogPayload } from './dialog-portal';
import { DialogManager } from './manager';

export type DialogHookOptions<T = void, E = void> = Pick<
	DialogPayload<T, E>,
	'onConfirm' | 'onCancel'
>;

export const useDialog = <T = void, E = void>(props: DialogPayload<T, E>) => {
	return {
		open: (context: T = undefined as unknown as T) => {
			const id = DialogManager.open<T, E>({
				...props,
				contextData: context,
			});
			return () => DialogManager.close(id);
		},
	};
};
