import { DateTime, Duration } from 'luxon';
import { Event, ScheduledEvent } from 'core/types';

export const getEventDateTimes = (
	event: Event | ScheduledEvent
): {
	startDate: DateTime;
	endDate: DateTime;
	startDateTime: DateTime | null;
	endDateTime: DateTime | null;
	duration: Duration | null;
} => {
	const startDate = DateTime.fromISO(event.start_date);
	const endDate = DateTime.fromISO(event.end_date);

	let startDateTime = null;
	let endDateTime = null;
	let duration = null;

	if (event.start_time) {
		startDateTime = DateTime.fromFormat(
			`${event.start_date} ${event.start_time}`,
			'yyyy-MM-dd HH:mm:ss',
			{ zone: 'utc' }
		);

		if (event.end_time) {
			endDateTime = DateTime.fromFormat(
				`${event.end_date} ${event.end_time}`,
				'yyyy-MM-dd HH:mm:ss',
				{ zone: 'utc' }
			);

			duration = endDateTime.diff(startDateTime);
		}
	}

	return { startDate, endDate, startDateTime, endDateTime, duration };
};
