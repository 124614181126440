import { useCallback } from 'react';
import { Backdrop, PickerFile } from 'components/common';
import { useRouter, useSubmitHandlerForPausedMutation } from 'hooks';
import { AlbumPhoto } from 'core/types';
import { useDeletePhotoHandler } from 'components/photos';
import {
	useGetCommunityPhotos,
	usePostCommunityPhoto,
} from 'core/uses-cases/photo';
import { PhotoList } from './list';
import { EmptyPhotoList } from './empty-list';

export type CommunityPhotoListProps = {
	filter: string;
	canUploadPhoto: boolean;
	communityId: string;
};

export const CommunityPhotoListContainer = ({
	filter,
	canUploadPhoto,
	communityId,
}: CommunityPhotoListProps) => {
	const queryResult = useGetCommunityPhotos(communityId);

	const router = useRouter();

	const handleOnClick = useCallback(
		(photo: AlbumPhoto) => {
			router.navigate('photo.view', {
				albumId: photo.album_uuid,
				communityId,
				photoId: photo.uuid,
			});
		},
		[communityId]
	);

	const { open: deletePhotoHandler, isDeletingPhoto } =
		useDeletePhotoHandler();

	const handleOnDelete = useCallback(
		(photo: AlbumPhoto) => {
			deletePhotoHandler({
				communityId,
				photoId: photo.uuid,
				albumId: photo.album_uuid,
			});
		},
		[communityId]
	);

	const { mutateAsync, isPaused } = usePostCommunityPhoto();

	const handleOnChange = useSubmitHandlerForPausedMutation<PickerFile[]>(
		isPaused,
		values => {
			return mutateAsync({
				communityId,
				image: values[0],
			});
		},
		{},
		[communityId]
	);

	return (
		<>
			<PhotoList
				{...queryResult}
				filter={filter}
				onClick={handleOnClick}
				onChange={handleOnChange}
				onDelete={handleOnDelete}
				canUploadPhoto={canUploadPhoto}
				emptyState={<EmptyPhotoList />}
			/>
			<Backdrop open={isDeletingPhoto} />
		</>
	);
};
