import MuiTypography, { TypographyProps } from '@mui/material/Typography';
import { BoxProps } from '@mui/system';

export interface TextProps extends TypographyProps {
	component?: BoxProps['component'];
	variant?:
		| 'body1'
		| 'body2'
		| 'body3'
		| 'quote1'
		| 'quote2'
		| 'button1'
		| 'button2'
		| 'button3'
		| 'navitem'
		| 'caption';
}

export const Text = (props: TextProps) => {
	const { variant = 'body2', ...rest } = props;

	return <MuiTypography {...rest} variant={variant} />;
};

export const Typography = (
	props: TypographyProps & { component?: BoxProps['component'] }
) => {
	const { variant = 'body2', ...rest } = props;

	return <MuiTypography {...rest} variant={variant} />;
};
