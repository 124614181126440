import { Preferences } from 'core/types';
import { subscriptionKeys } from 'core/utils/query-key-factory';
import { useServices } from 'hooks';
import { useQuery } from 'react-query';

export const useGetMemberSubscriptionPreferences = (
	communityId: string,
	identityId: string,
	enable: boolean
) => {
	const { api } = useServices();

	return useQuery<Preferences>(
		subscriptionKeys.preference({ communityId, memberId: identityId }),
		() =>
			api
				.getMemberPreferences(communityId, identityId)
				.then(response => ({
					event_reminder_time: response.data.event_reminder_time,
					digest_subscription:
						response.data.email_digest_opt_out === '0',
					announcement_subscription:
						response.data.announcement_subscription_status === '1',
					digest_frequency: response.data.digest_frequency,
					dismissed_welcome_message:
						(response.data?.dismissed_welcome_message ?? '0') ===
						'1',
				})),
		{
			enabled: !!communityId && enable,
			notifyOnChangeProps: 'tracked',
		}
	);
};
