import { useServices } from 'core/services';
import { BaseHttpError } from 'core/services/api/errors';
import { Event, Nullable } from 'core/types';
import { getPageParams } from 'core/utils/pagination';
import { calendarKeys } from 'core/utils/query-key-factory';
import { useQuery, useQueryClient } from 'react-query';
import { EventsParamArgs } from 'core/services/api/api-client/types';
import produce from 'immer';

export type EventsCache = {
	byId: Record<string, Event>;
	allIds: string[];
};

export type GetEventsParams = {
	startDate: Nullable<Date>;
	endDate: Nullable<Date>;
};

const buildQueryKey = (calendarId: string, params: GetEventsParams) =>
	calendarKeys.list(calendarId, {
		startDate: params.startDate ? params.startDate?.toISOString() : 'all',
		endDate: params.endDate ? params.endDate?.toISOString() : 'all',
	});

export const useGetEvents = (
	calendarId: string,
	params: GetEventsParams,
	options: {
		enabled?: boolean;
	} = {
		enabled: true,
	}
) => {
	const { api, authentication } = useServices();

	const isLoggedIn = authentication.isAuthenticated();

	const queryClient = useQueryClient();

	const enabled =
		!!options.enabled &&
		isLoggedIn &&
		!!calendarId &&
		!!params &&
		!!params.startDate &&
		!!params.endDate;

	const fetchEvents = async ({
		params,
		signal,
	}: {
		params: GetEventsParams;
		signal?: AbortSignal;
	}) => {
		const innerFetchEvents = (pageParam?: EventsParamArgs) =>
			api.getEvents(calendarId, {
				params: {
					after_dt: params.startDate?.toISOString(),
					before_dt: params.endDate?.toISOString(),
					...pageParam,
				},
				signal,
			});

		let lastResponse = await innerFetchEvents();
		const data: Event[] = lastResponse.data.slice();

		while (lastResponse.pagination.next) {
			const pageParam = getPageParams(lastResponse.pagination).nextParam;
			lastResponse = await innerFetchEvents({ ...pageParam });
			data.push(...lastResponse.data);
		}

		return data;
	};

	const eventsQuery = useQuery<Event[], BaseHttpError>(
		buildQueryKey(calendarId, params),
		({ signal }) => fetchEvents({ params, signal }),
		{
			enabled,
			staleTime: 0,
			cacheTime: 60000,
			notifyOnChangeProps: 'tracked',
			refetchOnMount: false,
			onSuccess: events => {
				// Update the global cache
				queryClient.setQueryData<EventsCache>(
					calendarKeys.all(calendarId),
					data => {
						const cache = data || {
							byId: {},
							allIds: [],
						};

						return produce(cache, draft => {
							const ids = new Set([...draft.allIds]);

							events.forEach(event => {
								draft.byId[event.uuid] = event;
								ids.add(event.uuid);
							});

							draft.allIds = Array.from(ids.values());
						});
					}
				);
			},
		}
	);

	const { data } = useQuery<EventsCache, unknown, Event[]>(
		calendarKeys.all(calendarId),
		{
			enabled: !!options.enabled,
			queryFn: () => ({ allIds: [], byId: {} }),
			select: data => {
				return data ? data.allIds.map(uuid => data.byId[uuid]) : [];
			},
			cacheTime: 60000,
			staleTime: 0,
			notifyOnChangeProps: 'tracked',
			refetchOnMount: false,
		}
	);

	const prefetch = (params: GetEventsParams) => {
		return queryClient.prefetchQuery(
			buildQueryKey(calendarId, params),
			({ signal }) => fetchEvents({ params, signal })
		);
	};

	return { ...eventsQuery, prefetch, data };
};
