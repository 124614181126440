import { isCancelledError, Mutation, useMutation } from 'react-query';
import { v4 as uuid } from 'uuid';
import { Event, Community, Image, Nullable, Asset, Identity } from 'core/types';
import { SetupMutationFn } from 'core/configure/types';
import {
	authenticationKeys,
	calendarKeys,
	communityKeys,
} from 'core/utils/query-key-factory';
import { postEventsMutationKey } from 'core/utils/mutation-key-factory';
import { mapVisibilityWithGroupsForSetting } from 'core/utils/mapper';
import { DateTime } from 'luxon';
import { Services } from '../../services/types';
import { BaseHttpError } from '../../services/api/errors';
import { EventInput } from '../../services/api/api-client/types';
import { EventsCache } from './use-get-events';
import { removeEventsFromCache, saveEventsToCache } from './utils';

export interface PostEventsMutationInput
	extends Omit<EventInput, 'image' | 'attachments'> {
	calendarId: string;
	communityId: string;
	image: Nullable<Image>;
	attachments: Asset[] | null;
}

export const usePostEvents = () => {
	const mutation = useMutation<
		Event[],
		BaseHttpError,
		PostEventsMutationInput
	>(postEventsMutationKey);

	return mutation;
};

type Context = {
	eventsSnapshot: EventsCache | undefined;
	communityId: string;
	calendarId: string;
	events: Event[];
};

export const setupPostEvents: SetupMutationFn = (
	services: Services,
	createTrackedParallelMutation,
	mutationTracker
) => {
	const { queryClient, api, uploader } = services;

	const mutation = createTrackedParallelMutation<
		Event[],
		BaseHttpError,
		PostEventsMutationInput,
		Context
	>({
		mutationFn: async ({
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			communityId,
			calendarId,
			image,
			attachments,
			...input
		}) => {
			const remoteImage = await uploader.uploadImage(image);

			const remoteAttachments = await Promise.all(
				(attachments ?? []).map(uploader.uploadAsset)
			).catch(() => []);

			const attachmentUuids = remoteAttachments
				.filter(attachment => !!attachment?.uuid)
				.map(attachment => attachment?.uuid) as string[];

			return api
				.postEvent(calendarId, {
					...input,
					image: remoteImage?.uuid ?? null,
					attachments: attachmentUuids,
				})
				.then(response => response.data);
		},
		onMutate: async input => {
			const {
				name,
				description,
				type,
				startTimes,
				endTimes,
				volunteersNeeded,
				communityId,
				calendarId,
				image,
				attachments,
				visibility,
				visibilityGroups,
				dates,
				volunteer,
				locations,
				volunteerGroups,
			} = input;

			const community = queryClient.getQueryData<Community>(
				communityKeys.detail(communityId)
			);
			const identity = queryClient.getQueryData<Identity>(
				authenticationKeys.identity
			);

			const baseEvent = {
				name,
				description,
				type,
				image,
				volunteers_needed: volunteersNeeded,
				volunteers_available: volunteersNeeded,
				volunteers: [],
				locations: locations.map(location => ({
					uuid: uuid(),
					created_dt: '',
					...location,
				})),
				user_timezone: identity?.timezone,
				asset_collection: {
					uuid: uuid(),
					name: null,
					assets: attachments ?? [],
				},
				settings: {
					visibilitySettings: mapVisibilityWithGroupsForSetting(
						visibility,
						visibilityGroups,
						community?.groups.all ?? []
					),
					volunteerSettings: mapVisibilityWithGroupsForSetting(
						volunteer,
						volunteerGroups,
						community?.groups.all ?? []
					),
				},
				created_dt: null,
				discussion_uuid: null,
				deleted_dt: null,
			};

			const eventsSnapshot = queryClient.getQueryData<EventsCache>(
				calendarKeys.all(calendarId)
			);

			const eventSeriesUuid = uuid();

			let events = dates.reduce((events, date) => {
				const eventCount = startTimes.length ? startTimes.length : 1;

				for (let i = 0; i < eventCount; i++) {
					const startTime = startTimes[i] ?? null;
					const endTime = endTimes[i] ?? null;
					let startDateTime = DateTime.invalid('no set');
					let endDateTime = DateTime.invalid('no set');

					if (startTime) {
						startDateTime = DateTime.fromFormat(
							`${date} ${startTime}`,
							'yyyy-MM-dd HH:mm:ss'
						).toUTC();

						if (endTime) {
							endDateTime = DateTime.fromFormat(
								`${date} ${endTime}`,
								'yyyy-MM-dd HH:mm:ss'
							).toUTC();
						}
					}

					const start_time = startDateTime.isValid
						? startDateTime.toFormat('HH:mm:ss')
						: startTime;
					const end_time = endDateTime.isValid
						? endDateTime.toFormat('HH:mm:ss')
						: endTime;
					const isOvernight =
						endDateTime.isValid && endDateTime < startDateTime;
					const end_date = isOvernight
						? startDateTime.plus({ day: 1 }).toFormat('yyyy-MM-dd')
						: date;

					const event: Event = {
						uuid: uuid(),
						event_series_uuid: eventSeriesUuid,
						...baseEvent,
						start_time,
						end_time,
						start_date: date,
						end_date,
						identity_permissions: [],
					};

					events.push(event);
				}

				return events;
			}, [] as Event[]);

			events = events.map(event => ({
				...event,
				event_series_count: events.length,
			}));

			// Save all new event in the cache
			await saveEventsToCache(queryClient, calendarId, events);

			return {
				eventsSnapshot,
				communityId,
				calendarId,
				events,
			};
		},
		onSuccess: (result, { calendarId }, context) => {
			if (context) {
				const { events } = context;
				const eventIds = events.map(({ uuid }) => uuid);

				removeEventsFromCache(queryClient, calendarId, eventIds);
			}
		},
		onError: (error, { calendarId }, context) => {
			if (context?.eventsSnapshot && !isCancelledError(error)) {
				queryClient.setQueryData<EventsCache>(
					calendarKeys.lists(calendarId),
					context.eventsSnapshot
				);
			}
		},
		onSettled: (data, error, variables, context) => {
			if (context && !isCancelledError(error)) {
				mutationTracker.queueInvalidations(
					calendarKeys.lists(context.calendarId)
				);
			}
		},
		retry: 1,
	});

	queryClient.setMutationDefaults(postEventsMutationKey, mutation);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isPostEventsMutation = (mutation: Mutation<any, any, any, any>) =>
	[postEventsMutationKey].includes(String(mutation?.options?.mutationKey));
