import { SetupMutationFn } from 'core/configure/types';
import { BaseHttpError } from 'core/services/api/errors';
import { Note, Nullable, Community } from 'core/types';
import { noteKeys, communityKeys } from 'core/utils/query-key-factory';
import { isCancelledError, Mutation, useMutation } from 'react-query';
import {
	patchNoteMutationKey,
	postNoteMutationKey,
} from 'core/utils/mutation-key-factory';
import {
	onPatchMutateOptimisticInfinityQueryCache,
	cancelPreviousMutation,
	onSuccessOptimisticInInfinityQueryCache,
	findRecordFromInfinityQueryCache,
	PaginatedRecordsSnapshot,
	updatePartiallyRecordFromInfinityQueryCache,
} from 'core/utils/optimistic-utils';
import { mapVisibilityWithGroups } from 'core/utils/mapper';
import { NoteInput } from '../../services/api/api-client/types';

export interface PatchNoteMutationInput extends NoteInput {
	communityId: string;
	noteId: string;
	createdDt: Nullable<string>;
}

export const usePatchNote = () => {
	const mutation = useMutation<Note, BaseHttpError, PatchNoteMutationInput>(
		patchNoteMutationKey
	);

	return mutation;
};

type MutationContext = {
	communityId: string;
	noteId: string;
	currentNoteInfo: {
		listSnapshot: PaginatedRecordsSnapshot<Note>;
	} | null;
};

export const setupPatchNote: SetupMutationFn = (
	services,
	createTrackedParallelMutation,
	mutationTracker
) => {
	const { queryClient, api } = services;

	const mutation = createTrackedParallelMutation<
		Note,
		BaseHttpError,
		PatchNoteMutationInput,
		MutationContext
	>({
		mutationFn: async ({ noteId, communityId, createdDt, ...input }) => {
			// If created dt is given, we need to set the call to be an update
			const actionCall = createdDt
				? api.patchNote.bind(api, noteId)
				: api.postNote.bind(api, communityId);

			return actionCall(input).then(response => response.data);
		},
		onMutate: async input => {
			const {
				noteId,
				communityId,
				content,
				title,
				visibility,
				visibilityGroups,
				edit,
				editGroups,
				createdDt,
			} = input;

			const community = queryClient.getQueryData<Community>(
				communityKeys.detail(communityId)
			);

			const currentNoteInfo = findRecordFromInfinityQueryCache<Note>(
				queryClient,
				noteKeys.list(communityId),
				noteId
			);

			if (currentNoteInfo) {
				//  optimistic Note
				const updatedNote: Note = {
					...currentNoteInfo.record,
					title,
					content,
					created_dt: createdDt ?? null,
					updated_dt: null,
					who_can_see: mapVisibilityWithGroups(
						visibility,
						visibilityGroups,
						community?.groups.all ?? []
					),
					managers: mapVisibilityWithGroups(
						edit,
						editGroups,
						community?.groups.all ?? []
					),
				};

				// Cancel the previous mutation if it exists
				await cancelPreviousMutation<MutationContext>(
					queryClient,
					mutation => {
						return (
							mutation.state.context?.noteId === noteId &&
							isPostPatchNoteMutation(mutation)
						);
					}
				);

				await onPatchMutateOptimisticInfinityQueryCache<Note>(
					queryClient,
					noteKeys.list(communityId),
					noteKeys.detail(communityId, noteId),
					updatedNote
				);

				updatePartiallyRecordFromInfinityQueryCache(
					queryClient,
					communityKeys.feed(communityId),
					updatedNote,
					['title', 'content']
				);
			}

			return {
				communityId,
				noteId,
				currentNoteInfo,
			};
		},
		onSuccess: (result, input, { communityId, noteId }) => {
			onSuccessOptimisticInInfinityQueryCache<Note>(
				queryClient,
				noteKeys.list(communityId),
				noteKeys.detail(communityId, noteId),
				noteId,
				result
			);
		},
		onError: (error, input, context) => {
			if (context?.currentNoteInfo && !isCancelledError(error)) {
				queryClient.setQueryData(
					noteKeys.list(context.communityId),
					context?.currentNoteInfo.listSnapshot
				);
			}
		},
		onSettled: (data, error, variables, context) => {
			if (context && !isCancelledError(error)) {
				mutationTracker.queueInvalidations(
					noteKeys.list(context.communityId),
					noteKeys.detail(variables.communityId, context.noteId),
					communityKeys.feed(context.communityId)
				);
			}
		},
		retry: 1,
	});

	queryClient.setMutationDefaults(patchNoteMutationKey, mutation);
};

export const isPostPatchNoteMutation = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	mutation: Mutation<any, any, any, any>
) =>
	[patchNoteMutationKey, postNoteMutationKey].includes(
		String(mutation?.options?.mutationKey)
	);
