import get from 'lodash.get';

export const convertCollectionToMap = <T, K = string>(
	collection: T[],
	keyPath: string
) => {
	return new Map<K, T>(collection.map(entry => [get(entry, keyPath), entry]));
};

export const truncateCollection = <T>(
	collection: T[],
	maxLength: number
): [T[], T[]] => {
	if (maxLength > 0 && collection.length > maxLength) {
		return [
			collection.slice(0, maxLength - 1),
			collection.slice(maxLength - 1),
		];
	}

	return [collection, []];
};

export function eqSet<T>(setA: Set<T>, setB: Set<T>) {
	if (setA.size !== setB.size) return false;
	for (const a of setA) if (!setB.has(a)) return false;
	return true;
}
