import { Box } from 'components';
import { Image } from 'core/types';
import { useImageServer, ImageServerConfig } from 'hooks';
import { imageStyle } from './style';

type FeedImageProps = {
	image: Image;
	config?: ImageServerConfig;
};

export const feedImageConfigs = {
	w: 600,
	h: 400,
	fit: 'fill',
	bg: 'ffffff',
};

export const FeedImage = ({ image, config }: FeedImageProps) => {
	const feedImage = useImageServer(image, {
		...feedImageConfigs,
		...config,
	});

	return (
		<Box sx={imageStyle}>
			<img src={feedImage?.url} alt={feedImage?.name} />
		</Box>
	);
};
