import { BaseHttpClient } from '../base-http-client';
import { InvitationResponse, InvitationsResponse } from '../response-types';

export const createInvitationApi = (http: BaseHttpClient) => ({
	/**
	 * Gets an invitation by token
	 *
	 * @param {string} communityId
	 * @param {number} invitationId
	 * @return {*}
	 */
	getInvitation(communityId: string, invitationId: number) {
		return http
			.get<InvitationResponse>(
				`community/${communityId}/membership-invitation/${invitationId}`
			)
			.then(response => response.data[0]);
	},
	/**
	 * Responds to a membership invitation
	 *
	 * @param {string} communityId
	 * @param {number} invitationId
	 * @param {('reinvite' | 'accept' | 'decline')} action
	 * @return {*}
	 */
	respondInvitation(
		communityId: string,
		invitationId: number,
		action: 'reinvite' | 'accept' | 'decline'
	) {
		return http
			.patch<InvitationsResponse>(
				`community/${communityId}/membership-invitation/${invitationId}`,
				{ action }
			)
			.then(response => response.data);
	},
});
