import CssBaseline from '@mui/material/CssBaseline';
import { useGetCurrentIdentity } from 'core/uses-cases/authentication';
import { BackdropPortal, Box, Splash } from 'components';
import { Navigator } from 'navigation';
import { DialogPortal } from 'components/common/dialog/dialog-portal';
import { useEffect } from 'react';
import { useServices } from 'core/services';
import { ENGLISH_CODE, LANGUAGE_PREFERENCE_KEY } from 'core/constants';
import { useAutoLoginHandler } from 'hooks/use-auto-login-handler';
import { changeLanguage } from 'utils/lang';
import { CookieConsent } from 'components/common/cookie-consent';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { transformServerToClientLocal } from 'utils/dates';
import DateAdapter from 'components/date-adapter/date-adapter';

function Application() {
	const { data, isSuccess, isLoading } = useGetCurrentIdentity();
	const { cookie } = useServices();

	useEffect(() => {
		const userLanguage = data?.language;

		if (userLanguage && isSuccess) {
			const language = userLanguage ?? ENGLISH_CODE;
			changeLanguage(language);
		} else {
			const languageCookie =
				cookie.get(LANGUAGE_PREFERENCE_KEY) ?? ENGLISH_CODE;
			changeLanguage(languageCookie);
		}
	}, [data, isSuccess]);

	const { isChecking } = useAutoLoginHandler();

	const locale = transformServerToClientLocal(data?.language);

	return (
		<LocalizationProvider dateAdapter={DateAdapter} adapterLocale={locale}>
			<CssBaseline />
			<Box className='app-container'>
				{isLoading || isChecking ? (
					<Splash message='Checking session...' />
				) : (
					<>
						<Navigator />
						<DialogPortal />
						<BackdropPortal />
						<CookieConsent />
					</>
				)}
			</Box>
		</LocalizationProvider>
	);
}

export default Application;
