import Alert from '@mui/material/Alert';
import { onlineManager } from 'react-query';
import { useEffect, useState } from 'react';
import { Box } from '../layout';
import { containerStyle, separatorStyle } from './styles';

export const OfflinePrompt = () => {
	const [isOnline, setIsOnline] = useState(() => onlineManager.isOnline());

	useEffect(() => {
		const handleOnline = () => setIsOnline(onlineManager.isOnline());

		const unsubscribe = onlineManager.subscribe(handleOnline);

		return () => {
			unsubscribe();
		};
	}, []);

	if (isOnline) return null;

	return (
		<>
			<Box sx={separatorStyle} />
			<Box sx={containerStyle}>
				<Alert variant='filled' severity='warning' color='warning'>
					<span>
						The app is currently offline. Some actions will be
						posted after getting back online.
					</span>
				</Alert>
			</Box>
		</>
	);
};
