import * as Yup from 'yup';
import FormControl, { FormControlProps } from '@mui/material/FormControl';
import InputLabel, { InputLabelProps } from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText/FormHelperText';
import { FieldRenderProps } from 'react-final-form';
import { MultiChipsInput } from 'components/common/multi-chips-input';
import { OnErrorScrollPoint } from 'components/common/on-error-scroll-point';
import { contentStyle } from './styles';

export interface EmailsChipsProps extends Omit<FormControlProps, 'onChange'> {
	value: string[];
	label?: string;
	placeholder?: string;
	LabelProps?: InputLabelProps;
	name: string;
	helperText?: string;
	onChange: (value: string[]) => void;
	meta: FieldRenderProps<string>['meta'];
}

const isValidEmail = (email: string) => {
	return Yup.string().email().isValidSync(email);
};

export const EmailsChips = (props: EmailsChipsProps) => {
	const {
		value = [],
		placeholder,
		label,
		LabelProps,
		helperText,
		onChange,
		meta,
		error,
		disabled,
		...rest
	} = props;

	return (
		<FormControl
			sx={contentStyle}
			disabled={disabled}
			error={error}
			{...rest}
		>
			<OnErrorScrollPoint name={rest.name} />
			{label && <InputLabel {...LabelProps}>{label}</InputLabel>}
			<MultiChipsInput
				value={value}
				onChange={onChange}
				dataValidation={isValidEmail}
				placeholder={placeholder}
				fullWidth={props.fullWidth}
				{...{ active: meta.active, error, disabled }}
			/>
			{helperText && <FormHelperText>{helperText}</FormHelperText>}
		</FormControl>
	);
};
