import {
	Button,
	Box,
	Heading,
	Stack,
	LoadingButton,
	DatePickerMultipleField,
	DatePickerCalendarMultipleField,
	TimeZoneField,
	LocationField,
	Typography,
} from 'components/common';

export const Preview = () => {
	return (
		<Box
			flex={1}
			alignItems='center'
			display='flex'
			flexDirection='column'
			minHeight='100vh'
			width='100%'
		>
			<Heading>Preview Page</Heading>
			<Stack spacing={2}>
				<Heading variant='h3'>Buttons</Heading>
				<Button size='small'>Primary Small</Button>
				<Button size='medium'>Primary Medium</Button>
				<Button size='large'>Primary Large</Button>
			</Stack>
			<Stack spacing={2}>
				<Heading variant='h3'>Loading Buttons</Heading>
				<Box>
					<LoadingButton
						loading
						loadingIndicator='Loading...'
						size='small'
					>
						Primary Small
					</LoadingButton>
					<LoadingButton
						loading
						loadingIndicator='Loading...'
						size='small'
						color='secondary'
					>
						Primary Small
					</LoadingButton>
					<LoadingButton
						loading
						loadingIndicator='Loading...'
						size='small'
						color='error'
					>
						Error Small
					</LoadingButton>
					<LoadingButton
						loading
						loadingIndicator='Loading...'
						size='small'
						color='info'
					>
						Error Small
					</LoadingButton>
					<LoadingButton
						loading
						loadingIndicator='Loading...'
						size='small'
						color='warning'
					>
						Error Small
					</LoadingButton>
				</Box>
				<Box>
					<LoadingButton
						variant='outlined'
						size='medium'
						loading
						loadingIndicator='Loading...'
					>
						Primary Medium
					</LoadingButton>
					<LoadingButton
						variant='outlined'
						size='medium'
						loadingIndicator='Loading...'
						color='secondary'
						loading
					>
						Primary Medium
					</LoadingButton>
					<LoadingButton
						variant='outlined'
						size='medium'
						loadingIndicator='Loading...'
						color='error'
						loading
					>
						Primary Medium
					</LoadingButton>
					<LoadingButton
						variant='outlined'
						size='medium'
						loadingIndicator='Loading...'
						color='info'
						loading
					>
						Primary Medium
					</LoadingButton>
					<LoadingButton
						variant='outlined'
						size='medium'
						loadingIndicator='Loading...'
						color='warning'
						loading
					>
						Primary Medium
					</LoadingButton>
				</Box>
				<Box>
					<LoadingButton
						variant='outlined'
						size='large'
						loading
						loadingIndicator='Loading Large...'
					>
						Primary Medium
					</LoadingButton>
					<LoadingButton
						variant='outlined'
						size='large'
						loadingIndicator='Loading Large...'
						color='secondary'
						loading
					>
						Primary Medium
					</LoadingButton>
				</Box>
				<Box>
					<LoadingButton
						variant='text'
						size='small'
						loading
						loadingIndicator='Loading Large...'
					>
						Primary Medium
					</LoadingButton>
					<LoadingButton
						variant='text'
						size='small'
						loadingIndicator='Loading Large...'
						color='secondary'
						loading
					>
						Primary Medium
					</LoadingButton>
					<LoadingButton
						variant='text'
						size='small'
						loadingIndicator='Loading Large...'
						color='error'
						loading
					>
						Primary Medium
					</LoadingButton>
					<LoadingButton
						variant='text'
						size='small'
						loadingIndicator='Loading Large...'
						color='warning'
						loading
					>
						Primary Medium
					</LoadingButton>
					<LoadingButton
						variant='text'
						size='small'
						loadingIndicator='Loading Large...'
						color='info'
						loading
					>
						Primary Medium
					</LoadingButton>
				</Box>
			</Stack>
			<Stack>
				<Typography>None</Typography>
				<Typography variant='body1'>Body1</Typography>
				<Typography variant='body2'>Body2</Typography>
				<Typography variant='body3'>Body3</Typography>
				<Typography variant='quote1'>quote1</Typography>
				<Typography variant='quote2'>quote2</Typography>
				<Typography variant='button1'>button1</Typography>
				<Typography variant='button2'>button2</Typography>
				<Typography variant='button3'>button3</Typography>
				<Typography variant='navitem'>navitem</Typography>
				<Typography variant='caption'>caption</Typography>
			</Stack>
			<Stack mt={10} spacing={2}>
				<Heading variant='h3'>TimeZone Picker</Heading>
				<Box>
					<TimeZoneField label='Timezone' update />
				</Box>
			</Stack>
			<Stack mt={10} spacing={2}>
				<Heading variant='h3'>Location Picker</Heading>
				<LocationField />
			</Stack>
			<Stack mt={10} spacing={2}>
				<Heading variant='h3'>Date Picker</Heading>
				<Box>
					<Heading variant='h5'>
						Date Picker Multiple (with input)
					</Heading>
					<DatePickerMultipleField value={[]} />
				</Box>
				<Box alignSelf='flex-start'>
					<Heading variant='h5'>
						Date Picker Multiple (with calendar)
					</Heading>
					<DatePickerCalendarMultipleField value={[]} />
				</Box>
			</Stack>
		</Box>
	);
};
