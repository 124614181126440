import { ImageServerConfig } from 'hooks';
import appConfig from '../config';

export function parseUrl(url: string) {
	const parseUrl =
		/^(?:([A-Za-z]+):)?(\/{0,3})([0-9.\-A-Za-z]+)(?::(\d+))?(?:\/([^?#]*))?(?:\?([^#]*))?(?:#(.*))?$/;
	const result = parseUrl.exec(url);

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	delete result.index;
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	delete result.input;

	return result ?? [];
}

export function getCurrentUrl() {
	return window.location.href;
}

export function getBaseUrl() {
	return window.location.origin;
}

export function createImageServerUrl(
	originalUrl: string,
	config: ImageServerConfig = {}
) {
	const { fit, bg, w, h } = {
		fit: 'max',
		...config,
	};

	const [, protocol, , host, port, path] = parseUrl(originalUrl);

	if (!host || !protocol) return originalUrl;

	let url = `${appConfig.imageServer}/${host}`;

	if (typeof port !== 'undefined') {
		url += `:${port}`;
	}

	url += `/${fit}_${w ? w : 0}x${h ? h : 0}`;

	if (bg) {
		url += `_${bg}`;
	}

	url += `/${path}`;

	return url;
}

export async function getScaledImageUrl(
	originalUrl: string,
	configs: ImageServerConfig = {}
) {
	return await new Promise<string>(resolve => {
		const image = new Image();

		image.src = originalUrl;

		image.onload = () => {
			const canvas = document.createElement('canvas');
			const ctx = canvas.getContext('2d');

			if (ctx) {
				const { w, h, bg, fit } = {
					fit: 'max',
					w: image.width,
					h: image.height,
					bg: 'ffffff',
					...configs,
				};

				const ratio = Math.min(w / image.width, h / image.height);

				let newWidth = image.width;
				let newHeight = image.height;

				if (fit === 'max') {
					if (image.width > w || image.height > h) {
						newWidth = image.width * ratio;
						newHeight = image.height * ratio;
					}
				} else {
					newWidth = image.width * ratio;
					newHeight = image.height * ratio;
				}

				canvas.width = w;
				canvas.height = h;
				ctx.fillStyle = `#${bg}`;

				ctx.fillRect(0, 0, canvas.width, canvas.height);

				const x = (canvas.width - newWidth) / 2;
				const y = (canvas.height - newHeight) / 2;

				ctx.drawImage(image, x, y, newWidth, newHeight);
			}

			canvas.remove();

			resolve(canvas.toDataURL('image/png'));
		};
	});
}

export async function cacheImage(url: string, configs: ImageServerConfig = {}) {
	return await new Promise<void>(resolve => {
		const newImage = new Image();

		newImage.onload = () => {
			resolve();
		};

		newImage.onerror = () => {
			resolve();
		};

		newImage.src = createImageServerUrl(url, configs);
	});
}
