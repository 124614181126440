import { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';

export class LogMiddleware {
	async onRequest(config: AxiosRequestConfig): Promise<AxiosRequestConfig> {
		console.group('%cApi Debug', 'color: purple');
		console.group('REQUEST');
		console.log(`${config?.method?.toUpperCase()} ${config.url}`);
		console.log('Config', config);
		console.groupEnd();

		if (config.data instanceof FormData) {
			const formDataEntries: Record<string, unknown> = {};
			config.data.forEach((value, key) => (formDataEntries[key] = value));
			console.log('FORM DATA', formDataEntries);
		}

		if (typeof config.data === 'string') {
			try {
				const data = JSON.parse(config.data);
				console.log('JSON DATA', data);
			} catch (error) {}
		}

		return config;
	}

	async onResponse(response: AxiosResponse): Promise<AxiosResponse> {
		console.log(
			'RESPONSE',
			response.request.fromCache ? '[FROM CACHE]' : '',
			response
		);
		console.groupEnd();
		return response;
	}

	async onResponseError(error: AxiosError): Promise<never> {
		console.log('RESPONSE ERROR', error.response);
		console.groupEnd();
		throw error;
	}
}
