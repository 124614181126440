import { createTheme, Theme as MUITheme } from '@mui/material/styles';
import { overrides, typography, palette } from './definitions';

export const createBaseTheme = (): MUITheme => {
	let theme = createTheme();

	theme = createTheme({ ...palette(theme), typography });

	return createTheme(theme, {
		components: overrides(theme),
	});
};

export type Theme = MUITheme;
