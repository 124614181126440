import React from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { useRouter, useTranslation } from 'hooks';
import config from 'config';
import downCaret from 'assets/images/down_caret.svg?url';
import { useServices } from 'core/services';
import {
	ENGLISH_CODE,
	FRENCH_CODE,
	LANGUAGE_PREFERENCE_KEY,
} from 'core/constants';
import { changeLanguage, getCurrentLanguage } from 'utils/lang';
import {
	IconButton,
	Box,
	RouterLink,
	Typography,
	Menu,
	MenuItem,
	Button,
	Divider,
} from 'components/common';
import { languageButtonStyles, downCaretStyles } from './public-menu-styles';

export const PublicMenu = () => {
	const router = useRouter();
	const { t } = useTranslation();
	const enableLanguage = config.enableLanguage;
	const [anchorElNav, setAnchorElNav] = React.useState<Element>();
	const open = Boolean(anchorElNav);
	const [anchorElLanguage, setAnchorElLanguage] = React.useState<Element>();
	const openLanguage = Boolean(anchorElLanguage);
	const { cookie } = useServices();

	const pages = [
		{
			title: t('public-menu.log-in'),
			path: router.getPathFor('account.login'),
			dataTestId: 'public-menu_log-in',
		},
		{
			title: t('public-menu.join-a-community'),
			path: router.getPathFor('community.search'),
			dataTestId: 'public-menu_search-community',
		},
		{
			title: t('public-menu.create-a-community'),
			path: router.getPathFor('community.create'),
			dataTestId: 'public-menu_create-community',
		},
	];

	const languageMenuItems = [
		{
			title: t('languages.english'),
			code: ENGLISH_CODE,
		},
		{
			title: t('languages.french'),
			code: FRENCH_CODE,
		},
	];

	const handleOpenNavMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorElNav(event.currentTarget);
	};
	const handleCloseNavMenu = () => {
		setAnchorElNav(undefined);
	};

	const handleOpenLanguageMenu = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		setAnchorElLanguage(event.currentTarget);
	};
	const handleCloseLanguageMenu = () => {
		setAnchorElLanguage(undefined);
	};

	const handleChangeLanguage = (locale: string) => {
		changeLanguage(locale);
		cookie.set(LANGUAGE_PREFERENCE_KEY, locale);
		setAnchorElLanguage(undefined);
	};

	return (
		<Box display='flex'>
			<Box
				justifyContent='flex-end'
				alignItems='center'
				sx={{
					flexGrow: 1,
					display: { xs: 'flex', md: 'none' },
				}}
			>
				<IconButton
					size='large'
					aria-label='account of current user'
					aria-controls='menu-appbar'
					aria-haspopup='true'
					onClick={handleOpenNavMenu}
					color='inherit'
				>
					<MenuIcon />
				</IconButton>
				<Menu
					id='menu-appbar'
					anchorEl={anchorElNav}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
					keepMounted
					transformOrigin={{
						vertical: 'top',
						horizontal: 'left',
					}}
					open={open}
					onClose={handleCloseNavMenu}
					sx={{
						display: { xs: 'block', md: 'none' },
					}}
				>
					{pages.map(page => (
						<MenuItem
							key={page.title}
							onClick={handleCloseNavMenu}
							component={RouterLink}
							to={page.path}
						>
							<Typography textAlign='center'>
								{page.title}
							</Typography>
						</MenuItem>
					))}
					<Typography textAlign='center' marginTop={1}>
						{t('account.language')}
					</Typography>
					<Divider />
					{languageMenuItems.map(item => (
						<MenuItem
							key={item.title}
							onClick={() => handleChangeLanguage(item.code)}
						>
							{item.title}
						</MenuItem>
					))}
				</Menu>
			</Box>
			<Box
				justifyContent='flex-end'
				alignItems='center'
				flexGrow={1}
				display={{ xs: 'none', md: 'flex' }}
			>
				{pages.map(page => (
					<RouterLink
						key={page.title}
						to={page.path}
						data-test-id={page.dataTestId}
						sx={{ mr: 2, display: 'block' }}
					>
						{page.title}
					</RouterLink>
				))}
			</Box>
			{enableLanguage && (
				<Box display={{ xs: 'none', md: 'flex' }}>
					<Button
						onClick={handleOpenLanguageMenu}
						aria-controls={openLanguage ? 'lang-menu' : undefined}
						aria-haspopup='true'
						aria-expanded={openLanguage ? 'true' : undefined}
						sx={languageButtonStyles}
						size='small'
					>
						{t(`languages.${getCurrentLanguage()}`)}
						<Box
							component='img'
							src={downCaret}
							alt={t('public-menu.down-caret')}
							sx={downCaretStyles}
						/>
					</Button>
					<Menu
						id='lang-menu'
						anchorEl={anchorElLanguage}
						open={openLanguage}
						onClose={handleCloseLanguageMenu}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'center',
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'center',
						}}
					>
						{languageMenuItems.map(item => (
							<MenuItem
								key={item.title}
								onClick={() => handleChangeLanguage(item.code)}
							>
								{item.title}
							</MenuItem>
						))}
					</Menu>
				</Box>
			)}
		</Box>
	);
};
