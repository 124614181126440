import {
	Box,
	Button,
	CheckboxField,
	DialogActions,
	FormControlLabel,
	Typography,
} from 'components/common';
import { useCheckboxMultiSelectionHandler, useTranslation } from 'hooks';

export type ImportEmailsContextValue = {
	uuid: string;
	name: string;
	email: string;
}[];

type ImportEmailsProps = {
	contacts: ImportEmailsContextValue;
	handleOnConfirm: (extraData: string[]) => void;
};

export const ImportEmailsModal = ({
	contacts,
	handleOnConfirm,
}: ImportEmailsProps) => {
	const {
		select,
		selectAll,
		isSelected,
		isAllSelected,
		isIndeterminate,
		selected,
	} = useCheckboxMultiSelectionHandler(contacts);
	const { t } = useTranslation();

	if (contacts.length === 0) {
		return (
			<Box padding={4}>
				<Typography variant='body1'>
					{t('community-form.there-are-no-contacts')}
				</Typography>
			</Box>
		);
	}
	return (
		<Box padding={4}>
			<FormControlLabel
				sx={{ marginLeft: 0 }}
				control={
					<CheckboxField
						CheckBoxProps={{
							indeterminate: isIndeterminate,
						}}
						value={isAllSelected}
						onChange={selectAll}
					/>
				}
				label={
					<Typography variant='button2'>
						{t('community-form.select-all')}
					</Typography>
				}
			/>
			<Box maxHeight='50vh' sx={{ overflowY: 'auto' }}>
				{contacts.map(contact => (
					<FormControlLabel
						sx={{ display: 'flex', marginTop: 1, marginLeft: 0 }}
						componentsProps={{
							typography: { sx: { minWidth: 0 } },
						}}
						key={JSON.stringify(contact)}
						control={
							<CheckboxField
								sx={{ minWidth: 47 }}
								value={isSelected(contact.uuid)}
								onChange={
									() => select(contact.uuid)
									// eslint-disable-next-line react/jsx-curly-newline
								}
							/>
						}
						label={
							<Box>
								<Typography
									variant='h6'
									textOverflow='ellipsis'
									overflow='hidden'
									whiteSpace='nowrap'
								>
									{contact.name}
								</Typography>
								<Typography
									variant='body1'
									textOverflow='ellipsis'
									overflow='hidden'
									whiteSpace='nowrap'
								>
									{contact.email}
								</Typography>
							</Box>
						}
					/>
				))}
			</Box>
			<DialogActions
				sx={{
					padding: 2,
				}}
			>
				<Button
					size='small'
					type='submit'
					onClick={() => handleOnConfirm(selected)}
				>
					{t('community-form.import-selected-contacts')}
				</Button>
			</DialogActions>
		</Box>
	);
};
