import { useServices } from 'core/services';
import { FilterCommunityMembersInput } from 'core/services/api/api-client/types';
import { transformCommunityMember } from 'core/services/api/transformers';
import { communityKeys } from 'core/utils/query-key-factory';
import { useQuery } from 'react-query';

export const useGetTeamMembers = (
	id: string,
	input: FilterCommunityMembersInput
) => {
	const { api, authentication } = useServices();
	const isLoggedIn = authentication.isAuthenticated();

	return useQuery(
		communityKeys.team(id, input),
		() => {
			return api
				.getCommunityMembers(id, input)
				.then(response => response.data.map(transformCommunityMember));
		},
		{
			enabled: !!id && isLoggedIn,
			notifyOnChangeProps: 'tracked',
			keepPreviousData: true,
		}
	);
};
