import { isCancelledError, Mutation, useMutation } from 'react-query';
import { v4 as uuid } from 'uuid';
import {
	Asset,
	Image,
	Event,
	Nullable,
	Location,
	Community,
	CommunityMember,
	Identity,
} from 'core/types';
import { SetupMutationFn } from 'core/configure/types';
import { calendarKeys, communityKeys } from 'core/utils/query-key-factory';
import {
	patchEventMutationKey,
	postEventsMutationKey,
} from 'core/utils/mutation-key-factory';
import {
	cancelPreviousMutation,
	updateRecordCacheForOfflineMode,
} from 'core/utils/optimistic-utils';
import { DateTime } from 'luxon';
import { getEventDateTimes } from 'core/utils/calender-events';
import { EventInput } from 'core/services/api/api-client/types';
import { mapCommunityGroupsForSetting } from 'core/utils/mapper';
import { convertCollectionToMap } from 'utils/collections';
import { Services } from '../../services/types';
import { BaseHttpError } from '../../services/api/errors';
import { EventScopeValues, EVENT_SCOPES } from '../../constants';
import { EventsCache } from './use-get-events';
import { updateSingleEventInCache } from './utils';

type LocationInputPatch = Location & { uuid?: string };

export interface PatchEventMutationInput
	extends Omit<
		EventInput,
		| 'image'
		| 'attachments'
		| 'startTimes'
		| 'endTimes'
		| 'dates'
		| 'locations'
	> {
	image: Nullable<Image>;
	startTime: string | null;
	endTime: string | null;
	date: string;
	attachments: Asset[];
	locations: LocationInputPatch[];
	volunteers: string[];
	eventId: string;
	calendarId: string;
	communityId: string;
	scope: EventScopeValues;
}

export const usePathEvent = () => {
	const mutation = useMutation<Event, BaseHttpError, PatchEventMutationInput>(
		patchEventMutationKey
	);

	return mutation;
};

type Context = {
	eventsSnapshot: EventsCache | undefined;
	calendarId: string;
	eventId: string;
	communityId: string;
};

export const setupPathEvent: SetupMutationFn = (
	services: Services,
	createTrackedParallelMutation,
	mutationTracker
) => {
	const { queryClient, uploader, api } = services;

	const mutation = createTrackedParallelMutation<
		Event,
		BaseHttpError,
		PatchEventMutationInput,
		Context
	>({
		mutationFn: async input => {
			const { calendarId, eventId, image, scope } = input;

			const currentEvent = queryClient.getQueryData<Event>([
				...calendarKeys.detail(calendarId, eventId),
				'original',
			]);

			const { attachments, locations, ...restInput } =
				transformEventPathInput(currentEvent, input);

			const imageUuid = await uploader
				.uploadImage(image)
				.then(image => image?.uuid ?? '');

			// Upload new assets
			const newAssetUuids = (
				await Promise.all(
					attachments.add.map(attachment =>
						uploader
							.uploadAsset(attachment)
							.then(attachment => attachment?.uuid)
					)
				)
			).filter(uuid => Boolean(uuid)) as string[];

			// Upload new locations
			const newLocationUuids = (
				await Promise.all(
					(locations.add ?? []).map(location =>
						api
							.postLocation(location)
							.then(response => response.data.uuid)
							.catch(() => null)
					)
				)
			).filter(uuid => Boolean(uuid)) as string[];

			return api
				.patchEvent(calendarId, eventId, {
					scope,
					action: 'details',
					data: {
						...restInput,
						image: imageUuid,
						assets: {
							add: newAssetUuids,
							remove: attachments.remove,
						},
						locations: {
							add: newLocationUuids,
							remove: locations.remove,
						},
					},
				})
				.then(response => response.data);
		},
		onMutate: async input => {
			const {
				name,
				type,
				description,
				date,
				startTime,
				endTime,
				communityId,
				calendarId,
				eventId,
				scope,
				visibility,
				visibilityGroups,
				volunteer,
				volunteerGroups,
				image,
				locations,
				attachments,
				coordinators,
				volunteers,
			} = input;

			const singleKey = calendarKeys.detail(calendarId, eventId);

			const community = queryClient.getQueryData<Community>(
				communityKeys.detail(communityId)
			);

			const currentEvent = queryClient.getQueryData<Event>(singleKey);

			// Save the current info as snapshot
			const eventsSnapshot = queryClient.getQueryData<EventsCache>(
				calendarKeys.all(calendarId)
			);

			if (currentEvent) {
				let startDateTime = DateTime.invalid('no set');
				let endDateTime = DateTime.invalid('no set');

				if (startTime) {
					startDateTime = DateTime.fromFormat(
						`${date} ${startTime}`,
						'yyyy-MM-dd HH:mm:ss'
					).toUTC();

					if (endTime) {
						endDateTime = DateTime.fromFormat(
							`${date} ${endTime}`,
							'yyyy-MM-dd HH:mm:ss'
						).toUTC();
					}
				}

				const members =
					queryClient.getQueryData<CommunityMember[]>(
						communityKeys.members(communityId)
					) ?? [];

				const membersMap = convertCollectionToMap(
					members,
					'identity.uuid'
				);

				const updatedEvent: Event = {
					...currentEvent,
					name,
					type,
					description,
					coordinators: coordinators
						.map(
							coordinatorUuid =>
								membersMap.get(coordinatorUuid)?.identity
						)
						.filter(Boolean) as Identity[],
					volunteers: volunteers
						.map(
							volunteerUuid =>
								membersMap.get(volunteerUuid)?.identity
						)
						.filter(Boolean) as Identity[],
					locations: locations.map(location => ({
						uuid: uuid(),
						created_dt: '',
						...location,
					})),
					asset_collection: {
						...currentEvent.asset_collection,
						uuid: uuid(),
						name: 'temporal-asset-collection',
						assets: attachments,
					},
					image: image || null,
					start_time: startDateTime.isValid
						? startDateTime.toFormat('HH:mm:ss')
						: startTime,
					end_time: endDateTime.isValid
						? endDateTime.toFormat('HH:mm:ss')
						: endTime,
					start_date: date,
					end_date: endDateTime.isValid && endDateTime < startDateTime
						? startDateTime
							.plus({ day: 1 })
							.toFormat('yyyy-MM-dd')
						: date, // prettier-ignore
					settings: {
						visibilitySettings: {
							privacy: visibility,
							groups: mapCommunityGroupsForSetting(
								community?.groups?.all ?? [],
								visibilityGroups
							),
						},
						volunteerSettings: {
							privacy: volunteer,
							groups: mapCommunityGroupsForSetting(
								community?.groups?.all ?? [],
								volunteerGroups
							),
						},
					},
				};

				await cancelPreviousMutation<Context>(queryClient, mutation => {
					return (
						mutation.state.context?.eventId === eventId &&
						isPostPathEventMutation(mutation)
					);
				});

				if (
					eventsSnapshot &&
					(scope === EVENT_SCOPES.FUTURE ||
						scope === EVENT_SCOPES.ALL)
				) {
					const { startDate, startDateTime } =
						getEventDateTimes(currentEvent);

					const eventStartDate = startDateTime
						? startDateTime
						: startDate;

					const eventsUuidsToUpdate = eventsSnapshot?.allIds.filter(
						uuid => {
							const event = eventsSnapshot.byId[uuid];

							const isSameEventInSeries =
								currentEvent.event_series_uuid ===
									event.event_series_uuid &&
								event.uuid !== eventId &&
								event.start_time === currentEvent.start_time &&
								event.end_time === currentEvent.end_time;

							if (scope === EVENT_SCOPES.ALL) {
								return isSameEventInSeries;
							}

							const { startDateTime, startDate } =
								getEventDateTimes(event);

							const innerStartDate = startDateTime
								? startDateTime
								: startDate;

							return (
								isSameEventInSeries &&
								innerStartDate >= eventStartDate
							);
						}
					);

					await queryClient.cancelQueries(
						calendarKeys.details(calendarId)
					);

					for (const uuid of eventsUuidsToUpdate) {
						const event = eventsSnapshot.byId[uuid];

						const nextEvent = {
							...event,
							name: updatedEvent.name,
							image: updatedEvent.image,
							type: updatedEvent.type,
							description: updatedEvent.description,
							start_time: updatedEvent.start_time,
							end_time: updatedEvent.end_time,
							locations: updatedEvent.locations,
							asset_collection: updatedEvent.asset_collection,
							volunteers: updatedEvent.volunteers,
							coordinators: updatedEvent.coordinators,
							settings: updatedEvent.settings,
						};

						await updateRecordCacheForOfflineMode(
							queryClient,
							calendarKeys.detail(calendarId, event.uuid),
							nextEvent
						);

						updateSingleEventInCache(
							queryClient,
							calendarId,
							nextEvent
						);
					}
				}

				await updateRecordCacheForOfflineMode(
					queryClient,
					calendarKeys.detail(calendarId, updatedEvent.uuid),
					updatedEvent
				);

				updateSingleEventInCache(queryClient, calendarId, updatedEvent);

				// Save original to query it in the mutationFn
				queryClient.setQueryData<Event>(
					[...singleKey, 'original'],
					currentEvent
				);
			}

			return {
				eventsSnapshot,
				eventId,
				communityId,
				calendarId,
			};
		},
		onSuccess: (data, { calendarId, eventId }) => {
			// Remove temporal key
			queryClient.removeQueries([
				...calendarKeys.detail(calendarId, eventId),
				'original',
			]);
		},
		onError: (error, { calendarId }, context) => {
			if (context?.eventsSnapshot && !isCancelledError(error)) {
				queryClient.setQueryData<EventsCache>(
					calendarKeys.lists(calendarId),
					context.eventsSnapshot
				);
			}
		},
		onSettled: (data, error, variables, context) => {
			if (context && !isCancelledError(error)) {
				mutationTracker.queueInvalidations(
					calendarKeys.lists(context.calendarId)
				);
			}
		},
		retry: 1,
	});

	queryClient.setMutationDefaults(patchEventMutationKey, mutation);
};

type EditingListType = { add: string[]; remove: string[] };

const transformEventPathInput = (
	currentEvent: Event | undefined,
	input: PatchEventMutationInput
) => {
	const {
		type,
		name,
		description,
		timezone,
		startTime,
		endTime,
		date: startDate,
		locations,
		coordinators,
		volunteers,
		volunteersNeeded,
		attachments,
		visibility,
		visibilityGroups,
		volunteer,
		volunteerGroups,
	} = input;

	const coordinatorsInput: EditingListType = {
		add: [],
		remove: [],
	};
	const volunteersInput: EditingListType = {
		add: [],
		remove: [],
	};
	const locationsInput: { add: LocationInputPatch[]; remove: string[] } = {
		add: [],
		remove: [],
	};
	const attachmentsInput: { add: Asset[]; remove: string[] } = {
		add: [],
		remove: [],
	};

	if (currentEvent) {
		const coordinatorUuids = (currentEvent?.coordinators ?? []).map(
			({ uuid }) => uuid
		);
		const volunteerUuids = (currentEvent?.volunteers ?? []).map(
			({ uuid }) => uuid
		);
		const locationUuids = (currentEvent?.locations ?? []).map(
			({ uuid }) => uuid
		);
		const attachmentUuids = (
			currentEvent?.asset_collection?.assets ?? []
		).map(({ uuid }) => uuid as string);

		coordinatorsInput.add = coordinators.filter(
			coordinatorUuid => !coordinatorUuids.includes(coordinatorUuid)
		);
		coordinatorsInput.remove = coordinatorUuids.filter(
			coordinatorUuid => !coordinators.includes(coordinatorUuid)
		);

		volunteersInput.add = volunteers.filter(
			volunteerUuid => !volunteerUuids.includes(volunteerUuid)
		);

		volunteersInput.remove = volunteerUuids.filter(
			volunteerUuid => !volunteers.includes(volunteerUuid)
		);

		locationsInput.add = locations.filter(location => !location.uuid);

		locationsInput.remove = locationUuids.filter(
			locationUuid =>
				!locations.find(location => location?.uuid === locationUuid)
		);

		attachmentsInput.add = attachments.filter(
			attachment => !attachment.uuid
		);

		attachmentsInput.remove = attachmentUuids.filter(
			attachmentUuid =>
				!attachments.find(
					attachment => attachment?.uuid === attachmentUuid
				)
		);
	}

	const newInput = {
		basic: { name, description, type },
		times: {
			start_date: startDate,
			start_time: startTime,
			end_time: endTime,
			timezone,
		},
		coordinators: coordinatorsInput,
		volunteers: { ...volunteersInput, needed: +volunteersNeeded },
		locations: locationsInput,
		attachments: attachmentsInput,
		visibility: {
			visibility,
			groups: visibilityGroups,
		},
		volunteer: {
			volunteer,
			groups: volunteerGroups,
		},
	};

	return newInput;
};

export const isPostPathEventMutation = (
	mutation: Mutation<any, any, any, any>
) =>
	[patchEventMutationKey, postEventsMutationKey].includes(
		String(mutation?.options?.mutationKey)
	);
