import Radio, { RadioProps } from '@mui/material/Radio';
import MuiRadioGroup, {
	RadioGroupProps as MuiRadioGroupProps,
} from '@mui/material/RadioGroup';
import FormControl, { FormControlProps } from '@mui/material/FormControl';
import InputLabel, { InputLabelProps } from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText/FormHelperText';
import { useCallback, useMemo } from 'react';
import { FormControlLabel } from '../form-control-label';

interface RadioGroupFieldProps extends FormControlProps {
	options: {
		label: string;
		value: any;
		disabled?: boolean;
	}[];
	value: any;
	label?: string;
	LabelProps?: InputLabelProps;
	RadioProps?: RadioProps;
	RadioGroupProps?: MuiRadioGroupProps;
	name: string;
	helperText?: string;
	onChange: (value: any) => void;
}

export function RadioGroupField(props: RadioGroupFieldProps) {
	const {
		options = [],
		value: checkedValue,
		label,
		LabelProps,
		RadioProps,
		RadioGroupProps,
		helperText,
		onChange,
		...rest
	} = props;

	const handleChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			onChange?.((event.target as HTMLInputElement).value);
		},
		[]
	);

	const radioItems = useMemo(() => {
		return options.map(({ label, value, disabled }) => (
			<FormControlLabel
				key={`${String(value)}-${label}`}
				control={<Radio disableRipple {...RadioProps} />}
				value={value}
				label={label}
				disabled={disabled}
				componentsProps={{
					typography: {
						variant: 'body2',
					},
				}}
			/>
		));
	}, [options]);

	return (
		<FormControl {...rest}>
			{label && <InputLabel {...LabelProps}>{label}</InputLabel>}
			<MuiRadioGroup
				{...RadioGroupProps}
				onChange={handleChange}
				value={checkedValue}
			>
				{radioItems}
			</MuiRadioGroup>
			{helperText && <FormHelperText>{helperText}</FormHelperText>}
		</FormControl>
	);
}
