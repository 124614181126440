import { v4 as uuid } from 'uuid';
import EventNoteIcon from '@mui/icons-material/EventNote';
import InputAdornment from '@mui/material/InputAdornment';
import { DateTime } from 'luxon';
import Chip from '@mui/material/Chip';
import Popper from '@mui/material/Popper';
import { Box, IconButton } from 'components/common';
import { MouseEvent, KeyboardEvent, useRef, useState } from 'react';
import { useControlledValue } from 'hooks';
import { TextField } from '../../text-field';
import {
	DatePickerCalendarMultipleField,
	DatePickerCalendarMultipleProps,
} from './date-picker-calendar';
import { datePickerMultipleStyle } from './styles';

type DatePickerMultipleProps = DatePickerCalendarMultipleProps;

/**
 * The behavior of this component is very similar to the `AutoComplete` component.
 * Most of the code was based on the `AutoComplete` component.
 */
export const DatePickerMultipleField = (props: DatePickerMultipleProps) => {
	const { disabled, value, onChange, ...rest } = props;

	const id = useState(() => `multi-date-picker-field-${uuid()}`)[0];
	const [popupOpen, setPopupOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);

	const inputRef = useRef<HTMLInputElement>(null);
	const calendarContainerRef = useRef<HTMLDivElement>(null);

	const [values, setValues] = useControlledValue<DateTime[]>(value, onChange);

	const handleOpen = () => {
		if (popupOpen) return;
		setPopupOpen(true);
	};

	const handleClose = () => {
		if (!popupOpen) return;
		setPopupOpen(false);
	};

	const handlePopupIndicator = () => {
		if (popupOpen) {
			handleClose();
		} else {
			handleOpen();
		}
	};

	const inputProps = {
		id: id,
		ref: inputRef,
		readOnly: true,
		onFocus: () => handleOpen(),
		onBlur: () => {
			if (
				calendarContainerRef.current !== null &&
				calendarContainerRef.current?.parentElement?.contains(
					document.activeElement
				)
			) {
				inputRef.current?.focus();
				return;
			}

			handleClose();
		},
	};

	const rootProps = {
		onClick: () => {
			inputRef.current?.focus();
			// In case the current input is already focused
			if (document.activeElement === inputRef.current) {
				handleOpen();
			}
		},
		onKeyDown: (event: KeyboardEvent<HTMLDivElement>) => {
			if (event.which !== 229 && event.key === 'Escape') {
				if (!popupOpen) return;
				event.preventDefault();
				event.stopPropagation();
				handleClose();
			}
		},
		onMouseDown: (event: MouseEvent<HTMLDivElement>) => {
			if (event.currentTarget.getAttribute('id') !== id) {
				event.preventDefault();
			}
		},
	};

	const renderPopper = () => {
		return (
			<Popper
				open
				anchorEl={anchorEl}
				placement='bottom-start'
				disablePortal={false}
				role='presentation'
			>
				<DatePickerCalendarMultipleField
					ref={calendarContainerRef}
					onChange={setValues}
					value={values}
					PaperProps={{
						id: `${id}-calendar-container`,
						onMouseDown: (event: MouseEvent<HTMLDivElement>) => {
							event.preventDefault();
						},
					}}
				/>
			</Popper>
		);
	};

	const renderChips = () => {
		if (values.length > 0) {
			return values.map((value: DateTime) => (
				<Chip
					color='primary'
					label={value.toISODate()}
					size='small'
					disabled={disabled}
					// onDelete={() => handleChange(value)}
					key={value.toISO()}
				/>
			));
		}

		return null;
	};

	return (
		<>
			<Box sx={datePickerMultipleStyle} {...rootProps}>
				<TextField
					{...rest}
					disabled={disabled}
					InputLabelProps={{
						htmlFor: id,
						id: `${id}-label`,
					}}
					inputProps={inputProps}
					InputProps={{
						ref: setAnchorEl,
						startAdornment: renderChips(),
						endAdornment: (
							<InputAdornment position='end'>
								<IconButton onClick={handlePopupIndicator}>
									<EventNoteIcon fontSize='inherit' />
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
			</Box>
			{anchorEl && popupOpen && renderPopper()}
		</>
	);
};
