import { ChangeEventHandler, useCallback, useMemo, useRef } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'hooks';
import { Community, Nullable } from 'core/types';
import { getBaseUrl } from 'utils/url';
import {
	Box,
	CheckboxField,
	CheckboxGroupField,
	CustomTooltip,
	Field,
	Form,
	FormStack,
	FormOnSubmitReturn,
	Grid,
	ImagePickerField,
	LoadingButton,
	PickerFile,
	SelectField,
	SelectItem,
	TextField,
	Typography,
	When,
} from 'components/common';
import infoIcon from 'assets/images/info.svg?url';
import { TFunction } from 'i18next';
import { OptionType } from 'components';
import { warningMessageStyle } from './styles';

export type EditCommunityFormSubmittedValues = {
	allowMemberImageUploads: boolean;
	allowMemberInvites: boolean;
	availableSections: string[];
	contactGroup: string;
	description: string;
	image: Nullable<PickerFile[]>;
	name: string;
	purpose: string;
	searchEnabled: boolean;
	slug: string;
	zipCode: string;
};

export type EditCommunityFormInitialValues =
	Partial<EditCommunityFormSubmittedValues>;

export type EditCommunityFormProps = {
	initialValues: EditCommunityFormInitialValues;
	purposes: OptionType[];
	contactList: (OptionType & { size: number })[];
	availableSections: OptionType[];
	onSubmit?: (
		values: EditCommunityFormSubmittedValues
	) => FormOnSubmitReturn<Community>;
	submitText?: string;
	submittingText?: string;
};

export const EditCommunityForm = (props: EditCommunityFormProps) => {
	const {
		initialValues,
		purposes,
		contactList,
		availableSections,
		onSubmit,
		submitText,
		submittingText,
	} = props;

	const { t } = useTranslation();
	const baseUrl = getBaseUrl() + '/community/';

	const cachedInitialValues = useRef({
		...initialValues,
		image: initialValues?.image ? [initialValues?.image] : null,
	}).current;

	const handleOnSubmit = useCallback(
		values => {
			values.availableSections = values.availableSections.filter(
				(value: string) => value !== ''
			);
			values.searchEnabled = values.searchEnabled ? 1 : 0;

			const result = onSubmit?.(values);
			return Promise.resolve(result).catch(error => error);
		},
		[onSubmit]
	);

	const adminGroupSize = useMemo(() => {
		contactList.find(({ value }) => value === 'admin')?.size ?? 0;
	}, [contactList]);

	const validationSchema = useMemo(() => createValidationSchema(t), []);

	const handleDescriptionInput: ChangeEventHandler<
		HTMLTextAreaElement
	> = e => {
		e.target.style.height = 'auto';
		e.target.style.height = Math.min(e.target.scrollHeight, 500) + 'px';
	};

	return (
		<Form
			onSubmit={handleOnSubmit}
			initialValues={cachedInitialValues}
			validationSchema={validationSchema}
			subscription={{ submitting: true }}
		>
			{({ handleSubmit, submitting }) => (
				<form onSubmit={handleSubmit} noValidate>
					<FormStack spacing={3} responsive={false} mb={4}>
						<Box maxWidth={300}>
							<Field
								name='image'
								label={t('edit-community.profile-photo')}
								component={ImagePickerField}
							/>
						</Box>
						<Box>
							<Field
								name='name'
								label={t('edit-community.community-name')}
								component={TextField}
								required
								fullWidth
							/>
						</Box>
						<Box>
							<Box display='flex' alignItems='center'>
								<Field
									label={t(
										'edit-community.allow-community-search'
									)}
									name='searchEnabled'
									component={CheckboxField}
								/>
								<CustomTooltip
									title={
										t(
											'edit-community.allow-community-search-tooltip'
										) ?? ''
									}
									placement='top'
									arrow
								>
									<Box
										component='img'
										src={infoIcon}
										width={20}
										height={20}
									/>
								</CustomTooltip>
							</Box>
							<Box display='flex' alignItems='center'>
								<Field
									label={t(
										'edit-community.allow-member-invites'
									)}
									name='allowMemberInvites'
									component={CheckboxField}
								/>
								<CustomTooltip
									title={
										t(
											'edit-community.allow-member-invites-tooltip'
										) ?? ''
									}
									placement='top'
									arrow
								>
									<Box
										component='img'
										src={infoIcon}
										width={20}
										height={20}
									/>
								</CustomTooltip>
							</Box>
							<Box>
								<When
									field='allowMemberInvites'
									is={(value: boolean) => value}
								>
									<Box sx={warningMessageStyle}>
										{t(
											'edit-community.allow-member-invites-warning'
										)}
									</Box>
								</When>
							</Box>
							<Box display='flex' alignItems='center'>
								<Field
									label={t(
										'edit-community.allow-member-image-uploads'
									)}
									name='allowMemberImageUploads'
									component={CheckboxField}
								/>
								<CustomTooltip
									title={
										t(
											'edit-community.allow-member-image-uploads-tooltip'
										) ?? ''
									}
									placement='top'
									arrow
								>
									<Box
										component='img'
										src={infoIcon}
										width={20}
										height={20}
									/>
								</CustomTooltip>
							</Box>
						</Box>
						<Grid container mt={0}>
							<Grid item xs={12}>
								<Typography>
									{t('edit-community.community-url')}
								</Typography>
							</Grid>
							<Grid
								item
								display='flex'
								alignItems='center'
								justifyContent='flexStart'
								md={6}
								xs={12}
							>
								<Box>{baseUrl}</Box>
							</Grid>
							<Grid item md={6} xs={12}>
								<Box>
									<Field
										name='slug'
										fullWidth
										component={TextField}
									/>
								</Box>
							</Grid>
						</Grid>
						<Box>
							<Field
								name='purpose'
								label={t('edit-community.purpose')}
								component={SelectField}
								fullWidth
								required
								displayEmpty
							>
								{purposes.map(purpose => (
									<SelectItem
										key={purpose.value}
										value={purpose.value}
									>
										{purpose.label}
									</SelectItem>
								))}
							</Field>
						</Box>
						<Box>
							<Field
								name='zipCode'
								label={t('edit-community.zip-code')}
								component={TextField}
								required
								fullWidth
							/>
						</Box>
						<Box>
							<Field
								name='description'
								label={t('edit-community.public-description')}
								component={TextField}
								onInput={handleDescriptionInput}
								multiline
								rows={2}
								required
								fullWidth
							/>
						</Box>
						<Box>
							<Field
								name='contactGroup'
								label={t('edit-community.community-contact')}
								component={SelectField}
								fullWidth
								required
								displayEmpty
							>
								{contactList?.map(contact => (
									<SelectItem
										key={contact.value}
										value={contact.value}
									>
										{contact.label}
									</SelectItem>
								))}
							</Field>
						</Box>
						<Box>
							<When
								field='contactGroup'
								is={(value: string) => {
									const size = contactList.find(
										group => group.value === value
									)?.size;

									return size === 0;
								}}
							>
								<Box sx={warningMessageStyle}>
									{t(
										adminGroupSize === 0
											? 'edit-community.empty-admin-group-warning'
											: 'edit-community.empty-contact-group-warning'
									)}
								</Box>
							</When>
						</Box>
						<Box>
							<Field
								label={t('edit-community.available-sections')}
								name='availableSections'
								component={CheckboxGroupField}
								options={availableSections}
							/>
						</Box>
						<Box>
							<LoadingButton
								loading={submitting}
								loadingIndicator={
									submittingText ||
									t('edit-community.updating-community')
								}
								size='small'
								type='submit'
							>
								{submitText ||
									t('edit-community.update-community')}
							</LoadingButton>
						</Box>
					</FormStack>
				</form>
			)}
		</Form>
	);
};

function createValidationSchema(t: TFunction) {
	return Yup.object().shape({
		name: Yup.string()
			.trim()
			.required(
				t('form.required', {
					name: t('edit-community.community-name'),
				})
			),
		slug: Yup.string()
			.trim()
			.required(
				t('form.required', {
					name: t('edit-community.community-slug'),
				})
			),
		zipCode: Yup.string()
			.trim()
			.required(
				t('form.required', {
					name: t('edit-community.zip-code'),
				})
			),
		description: Yup.string()
			.trim()
			.required(
				t('form.required', {
					name: t('edit-community.public-description'),
				})
			),
	});
}
