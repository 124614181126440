import { photoCardConfigs } from 'components/photos';
import config from 'config';
import { Image } from 'core/types';
import { ImageServerConfig, useServices, useTranslation } from 'hooks';
import { useCallback, useEffect, useMemo } from 'react';
import { FileWithPath, useDropzone, FileRejection } from 'react-dropzone';
import { getScaledImageUrl } from 'utils/url';

export interface PickerFile extends Image {
	file: FileWithPath | null;
	scaledUrl?: string;
}

export type ImagePickerOptions = {
	value: Image[];
	disabled?: boolean;
	maxFiles?: number;
	isMultiple?: boolean;
	onChange?: (value: PickerFile[]) => void;
	imageServerConfig?: ImageServerConfig;
	onOpen?: () => void;
	onCancel?: () => void;
};

export const useImagePicker = ({
	value,
	onChange,
	disabled,
	isMultiple,
	maxFiles: _maxFiles,
	imageServerConfig,
	onOpen,
	onCancel,
}: ImagePickerOptions) => {
	const maxFiles = isMultiple ? _maxFiles : 1;
	const { notification } = useServices();
	const { t } = useTranslation();

	const handleOnDropImage = useCallback(
		async (acceptedFiles: FileWithPath[], errors: FileRejection[]) => {
			if (errors.length > 0) {
				const errorCodes = errors.map(error => error.errors[0].code);

				if (errorCodes.includes('too-many-files')) {
					notification.show(
						'error',
						t('image-picker.too-many-files', {
							count: maxFiles,
						})
					);

					return;
				}

				if (errorCodes.includes('file-too-large')) {
					notification.show(
						'error',
						t('image-picker.image-is-too-large', {
							maxFileSize: config.maxFileSize,
						})
					);
				}

				if (errorCodes.includes('file-invalid-type')) {
					notification.show('error', t('image-picker.not-supported'));
				}
			}

			if (acceptedFiles.length === 0) {
				return;
			}

			const currentFiles = [...(value || [])];

			const images = await Promise.all(
				acceptedFiles.map(async file => {
					const url = URL.createObjectURL(file);

					return {
						uuid: null,
						name: file.name,
						url,
						scaledUrl: await getScaledImageUrl(
							url,
							imageServerConfig
						),
						width: 0,
						height: 0,
						size: file.size,
						created_dt: new Date(file.lastModified).toISOString(),
						file,
					};
				})
			);

			if (maxFiles === 1) {
				onChange?.(images);
			} else {
				onChange?.([...currentFiles, ...images]);
			}
		},
		[value]
	);

	const { getRootProps, getInputProps, open } = useDropzone({
		accept: 'image/*',
		disabled,
		// Disable click and keydown behavior
		noClick: true,
		noKeyboard: true,
		multiple: isMultiple,
		maxFiles,
		maxSize: config.maxFileSize * 1000000,
		onDrop: handleOnDropImage,
		onFileDialogCancel: onCancel,
		onFileDialogOpen: onOpen,
	});

	const images = useMemo(() => {
		return (
			(value || []).map(image => {
				try {
					if (!image.file) {
						throw new Error(t('file-is-not-defined'));
					}

					return {
						...image,
						url: URL.createObjectURL(image.file),
					};
				} catch (e) {
					// Failed loading the image, probably the blob is not available
					return image;
				}
			}) ?? []
		);
	}, [value]);

	useEffect(() => {
		// Make sure to revoke the data uris to avoid memory leaks
		return () => {
			images.forEach(image => {
				if (image?.file) {
					URL.revokeObjectURL(image.url);
				}
			});
		};
	}, [images]);

	const handleOnRemoveImage = useCallback(
		index => {
			const newImages = images.filter((_, i) => i !== index);

			onChange?.(newImages);
		},
		[images]
	);

	return {
		images,
		removeImage: handleOnRemoveImage,
		getRootProps,
		getInputProps,
		open,
	};
};
