import { useServices } from 'core/services';
import { BaseHttpError } from 'core/services/api/errors';
import { FeedResponse } from 'core/services/api/response-types';
import { FeedRecord } from 'core/types';
import { getPageParams } from 'core/utils/pagination';
import { communityKeys } from 'core/utils/query-key-factory';
import { useCallback } from 'react';
import { useInfiniteQuery } from 'react-query';

export const useGetCommunityFeed = (
	id: string,
	options: { enable: boolean } = { enable: true }
) => {
	const { api, authentication } = useServices();

	const isLoggedIn = authentication.isAuthenticated();

	return useInfiniteQuery<FeedResponse, BaseHttpError, FeedRecord[]>(
		communityKeys.feed(id),
		useCallback(
			({ pageParam = null, signal }) => {
				return api.getCommunityFeed(id, {
					pagination: pageParam,
					signal,
				});
			},
			[id]
		),
		{
			enabled: !!id && isLoggedIn && options.enable,
			select: response => {
				return {
					pages: response.pages.map(page => page.data),
					pageParams: response.pageParams,
				};
			},
			getPreviousPageParam: firstPage =>
				getPageParams(firstPage.pagination).previousParam,
			getNextPageParam: lastPage =>
				getPageParams(lastPage.pagination).nextParam,
			notifyOnChangeProps: 'tracked',
			refetchOnMount: true,
		}
	);
};
