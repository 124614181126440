import { CommentApp, CommentAppProps } from './comment-app';
import { DiscussionProvider } from './context';

type CommentsWidgetProps = {
	discussionId: string;
	enabled?: boolean;
} & CommentAppProps;

export const CommentsWidget = (props: CommentsWidgetProps) => {
	const { discussionId, enabled = true, ...rest } = props;

	return (
		<DiscussionProvider discussionId={discussionId} enabled={enabled}>
			<CommentApp {...rest} />
		</DiscussionProvider>
	);
};
