import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { calendarUtils, getRangeOfDates } from '../utils';

export const useCalendarHeaderDays = (timezone: string) => {
	const headerDays = useMemo(() => {
		const now = DateTime.local({ zone: timezone });
		const startDayOfWeek = calendarUtils.getFirstDayOfWeek(now);
		const endDayOfWeek = calendarUtils.getLastDayOfWeek(now);
		return getRangeOfDates(startDayOfWeek, endDayOfWeek);
	}, []);

	return headerDays;
};
