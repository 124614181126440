import { Box, CircularProgress, Typography } from 'components/common';
import { TransferableMembers } from 'components/community/transfer-ownership';
import { Identity } from 'core/types';
import { useCurrentIdentity } from 'core/uses-cases/authentication';
import { useCommunityMembers } from 'core/uses-cases/member';
import { useInitiateTransferCommunity } from 'core/uses-cases/transfer-request';
import {
	useTranslation,
	useCurrentCommunity,
	useRouter,
	useServices,
	useSubmitHandlerForPausedMutation,
} from 'hooks';
import { Navigate } from 'navigation';

export const InitiateTransferRequest = () => {
	const community = useCurrentCommunity();
	const identity = useCurrentIdentity();
	const { t } = useTranslation();
	const router = useRouter();
	const { notification } = useServices();

	const communityId = community?.slug ?? '';

	const { isPaused, mutateAsync } = useInitiateTransferCommunity();

	const members = useCommunityMembers(communityId);

	const membersToTransfer = members?.filter(
		member => member.identity.uuid !== identity?.uuid
	);

	const handleOnInitiateTransfer =
		useSubmitHandlerForPausedMutation<Identity>(
			isPaused,
			identity => {
				return mutateAsync({
					communityId,
					identityId: identity.uuid,
				});
			},
			{
				onSuccess: ({ values }) => {
					notification.show(
						'success',
						t(
							'transfer-ownership.a-transfer-request-has-been-emailed',
							{
								name: `${values.first_name} ${values.last_name}`,
							}
						)
					);
				},
				onSettled: () => {
					router.navigate('community.team', {
						communityId,
					});
				},
			},
			[communityId]
		);

	if (!community || members.length === 0) {
		return (
			<Box display='flex' justifyContent='center' mt={4}>
				<CircularProgress message={t('common.loading')} />
			</Box>
		);
	}

	if (!community.owner || community.owner.uuid !== identity?.uuid) {
		return (
			<Navigate
				to={router.getPathFor('community.home', {
					communityId: community.slug,
				})}
				alert={{
					level: 'error',
					message: t('transfer-ownership.no-owner'),
				}}
			/>
		);
	}

	return (
		<Box>
			<Typography mb={4}>
				{t('transfer-ownership.initiate-transfer-request-description')}
			</Typography>
			<TransferableMembers
				onInitiateTransfer={handleOnInitiateTransfer}
				members={membersToTransfer}
			/>
		</Box>
	);
};
