import { v4 as uuid } from 'uuid';
import {
	ADD_DIALOG_EVENT,
	DialogPayloadWithContext,
	REMOVE_DIALOG_EVENT,
} from './dialog-portal';

export const DialogManager = {
	open: <T, E = unknown>(payload: DialogPayloadWithContext<T, E>) => {
		const id = uuid();

		const addDialogEvent = new CustomEvent(ADD_DIALOG_EVENT, {
			detail: { id, ...payload },
		});

		document.dispatchEvent(addDialogEvent);

		return id;
	},
	close: (id: string) => {
		const removeDialogEvent = new CustomEvent(REMOVE_DIALOG_EVENT, {
			detail: id,
		});

		document.dispatchEvent(removeDialogEvent);
	},
};
