import {
	Button,
	CheckboxField,
	DialogActions,
	DialogContent,
	DialogHookOptions,
	Field,
	Form,
	LoadingButton,
	useDialog,
} from 'components/common';
import { useDeleteCommunityMember } from 'core/uses-cases/community';
import { useServices, useTranslation } from 'hooks';

type DeleteMemberContextValue = {
	communityId: string;
	memberId: string;
	isSelf: boolean;
};

type InnerFormValues = {
	sendNotification: boolean;
};

export const useDeleteCommunityMemberHandler = (
	options?: DialogHookOptions<DeleteMemberContextValue>
) => {
	const { t } = useTranslation();
	const { notification } = useServices();
	const { mutateAsync, isLoading } = useDeleteCommunityMember();

	const runMutation = (
		contextData: DeleteMemberContextValue,
		values: InnerFormValues
	) => {
		const { memberId, communityId, isSelf } = contextData;
		const { sendNotification } = values;

		mutateAsync({
			memberId,
			communityId,
			sendNotification,
			leaveCommunity: isSelf,
		}).then(() => {
			notification.show(
				'success',
				isSelf
					? t('team.you-are-not-longer-part-of-the-community')
					: t('team.member-has-been-successfully-removed')
			);
		});

		options?.onConfirm?.(contextData);
	};

	const handleOnConfirm = (
		contextData: DeleteMemberContextValue,
		values: InnerFormValues
	) => {
		runMutation(contextData, values);
	};

	const renderContent = (
		confirmHandler: (extra: InnerFormValues) => void,
		handleOnCancel: () => void
	) => {
		return (
			<Form onSubmit={confirmHandler} subscription={{ submitting: true }}>
				{({ handleSubmit }) => {
					return (
						<form onSubmit={handleSubmit} noValidate>
							<DialogContent sx={{ pt: 0 }}>
								<Field
									component={CheckboxField}
									name='sendNotification'
									label={t(
										'team.delete-member-dialog.send-notification-to-member-after-deletion'
									)}
									defaultValue={false}
								/>
							</DialogContent>
							<DialogActions
								sx={{
									paddingX: 2,
									paddingBottom: 2,
									paddingTop: 0,
								}}
							>
								<Button
									size='small'
									variant='text'
									onClick={handleOnCancel}
								>
									{t('common.cancel')}
								</Button>
								<LoadingButton
									loading={isLoading}
									loadingIndicator={t('team.removing-member')}
									size='small'
									type='submit'
								>
									{t('team.remove-member')}
								</LoadingButton>
							</DialogActions>
						</form>
					);
				}}
			</Form>
		);
	};

	const renderSelfContent = (
		confirmHandler: (extra: InnerFormValues) => void,
		handleOnCancel: () => void
	) => {
		return (
			<Form onSubmit={confirmHandler} subscription={{ submitting: true }}>
				{({ handleSubmit }) => {
					return (
						<form onSubmit={handleSubmit} noValidate>
							<DialogActions
								sx={{
									paddingX: 2,
									paddingBottom: 2,
									paddingTop: 0,
								}}
							>
								<Button
									size='small'
									variant='text'
									onClick={handleOnCancel}
								>
									{t('common.cancel')}
								</Button>
								<LoadingButton
									loading={isLoading}
									loadingIndicator={t(
										'member-profile.leaving-community'
									)}
									size='small'
									type='submit'
								>
									{t('member-profile.leave-community')}
								</LoadingButton>
							</DialogActions>
						</form>
					);
				}}
			</Form>
		);
	};

	const { open } = useDialog<DeleteMemberContextValue, InnerFormValues>({
		title: t('team.delete-member-dialog.title'),
		content: renderContent,
		fullWidth: true,
		hideActions: true,
		maxWidth: 'md',
		onConfirm: handleOnConfirm,
	});

	const { open: openSelf } = useDialog<
		DeleteMemberContextValue,
		InnerFormValues
	>({
		title: t('team.delete-self-dialog.title'),
		content: renderSelfContent,
		fullWidth: true,
		hideActions: true,
		maxWidth: 'md',
		onConfirm: handleOnConfirm,
	});

	const handleDeleteMember = (input: DeleteMemberContextValue) => {
		if (input.isSelf) {
			openSelf(input);
		} else {
			open(input);
		}
	};

	return handleDeleteMember;
};
