import { isCancelledError, Mutation, useMutation } from 'react-query';
import { v4 as uuid } from 'uuid';
import { Event } from 'core/types';
import { SetupMutationFn } from 'core/configure/types';
import { calendarKeys } from 'core/utils/query-key-factory';
import { duplicateEventMutationKey } from 'core/utils/mutation-key-factory';
import { DateTime } from 'luxon';
import { getEventDateTimes } from 'core/utils/calender-events';
import { Services } from '../../services/types';
import { BaseHttpError } from '../../services/api/errors';
import { EVENT_SCOPES } from '../../constants';
import { EventsCache } from './use-get-events';
import { removeEventsFromCache, saveEventsToCache } from './utils';

export interface DuplicateEventMutationInput {
	calendarId: string;
	baseEventId: string;
	dates: string[];
}

export const useDuplicateEvent = () => {
	const mutation = useMutation<
		Event,
		BaseHttpError,
		DuplicateEventMutationInput
	>(duplicateEventMutationKey);

	return mutation;
};

type Context = {
	eventsSnapshot: EventsCache | undefined;
	calendarId: string;
	events: Event[];
};

export const setupDuplicateEvent: SetupMutationFn = (
	services: Services,
	createTrackedParallelMutation,
	mutationTracker
) => {
	const { queryClient, api } = services;

	const mutation = createTrackedParallelMutation<
		Event,
		BaseHttpError,
		DuplicateEventMutationInput,
		Context
	>({
		mutationFn: ({ calendarId, baseEventId, dates }) => {
			return api
				.patchEvent(calendarId, baseEventId, {
					scope: EVENT_SCOPES.INSTANCE,
					action: 'dates',
					data: dates,
				})
				.then(response => response.data);
		},
		onMutate: async input => {
			const { calendarId, baseEventId, dates } = input;

			const baseEvent = queryClient.getQueryData<Event>(
				calendarKeys.detail(calendarId, baseEventId)
			);

			let events: Event[] = [];

			if (baseEvent) {
				const { startDateTime, duration } =
					getEventDateTimes(baseEvent);

				events = dates.map(date => {
					const startDate = DateTime.fromISO(date).toUTC();

					// Regexp to check format yyyy-MM-dd
					const regexp = /^\d{4}-\d{2}-\d{2}$/;
					const isOnlyDate = regexp.test(date);

					let newEndDateTime: DateTime = DateTime.invalid('not set');

					if (!isOnlyDate && startDateTime && duration) {
						newEndDateTime = startDate.plus(duration);
					}

					const event: Event = {
						...baseEvent,
						uuid: uuid(),
						start_time: !isOnlyDate
							? startDate.toFormat('HH:mm:ss')
							: null,
						end_time: newEndDateTime.isValid
							? newEndDateTime.toFormat('HH:mm:ss')
							: null,
						start_date: startDate.toFormat('yyyy-MM-dd'),
						end_date: newEndDateTime.isValid
							? newEndDateTime.toFormat('yyyy-MM-dd')
							: startDate.toFormat('yyyy-MM-dd'),
						created_dt: null,
					};

					return event;
				}, [] as Event[]);

				events = events.map(event => ({
					...event,
					event_series_count:
						baseEvent?.event_series_count ?? 0 + events.length,
				}));
			}

			const eventsSnapshot = queryClient.getQueryData<EventsCache>(
				calendarKeys.all(calendarId)
			);

			// Save all new event in the cache
			await saveEventsToCache(queryClient, calendarId, events);

			return {
				eventsSnapshot,
				calendarId,
				events,
			};
		},
		onSuccess: (result, { calendarId }, { events }) => {
			const eventIds = events.map(({ uuid }) => uuid);

			// Clean up local created local event versions
			// @TODO: we can probably update local when the result contains an array of events (from the api response)/
			// Currently, the response has the source event
			removeEventsFromCache(queryClient, calendarId, eventIds);
		},
		onError: (error, { calendarId }, context) => {
			if (context?.eventsSnapshot && !isCancelledError(error)) {
				queryClient.setQueryData<EventsCache>(
					calendarKeys.all(calendarId),
					context.eventsSnapshot
				);
			}
		},
		onSettled: (data, error, variables, context) => {
			if (context && !isCancelledError(error)) {
				mutationTracker.queueInvalidations(
					calendarKeys.lists(context.calendarId)
				);
			}
		},
		retry: 1,
	});

	queryClient.setMutationDefaults(duplicateEventMutationKey, mutation);
};

export const isDuplicateEventMutation = (
	mutation: Mutation<any, any, any, any>
) =>
	[duplicateEventMutationKey].includes(
		String(mutation?.options?.mutationKey)
	);
