import { useVisibilityFormData } from 'components/community/hooks';
import { Note } from 'core/types';
import { useMemo } from 'react';
import { NoteFormInitialValues } from '../form';

export const useNoteFormData = (record?: Note) => {
	const { visibilityData, groups } =
		useVisibilityFormData<NoteFormInitialValues>(record);

	const data = useMemo(() => {
		if (!record) return {} as unknown as NoteFormInitialValues;

		return {
			title: record.title,
			content: record.content,
		} as unknown as NoteFormInitialValues;
	}, [record]);

	const { edit, editGroups, visibility, visibilityGroups } = visibilityData;

	return {
		initialValues: {
			...data,
			edit,
			editGroups,
			visibility,
			visibilityGroups,
		},
		groups,
	};
};
