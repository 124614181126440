import { SetupMutationFn } from 'core/configure/types';
import { BaseHttpError } from 'core/services/api/errors';
import { AdvanceCommunityPermissions } from 'core/types';
import { communityKeys, permissionKeys } from 'core/utils/query-key-factory';
import { isCancelledError, Mutation, useMutation } from 'react-query';
import { putCommunityGlobalPermissionsMutationKey } from 'core/utils/mutation-key-factory';
import { cancelPreviousMutation } from 'core/utils/optimistic-utils';

export type PutGlobalPermissionsMutationInput = {
	communityId: string;
	permissions: AdvanceCommunityPermissions;
};

export const usePutGlobalPermissions = () => {
	const mutation = useMutation<
		AdvanceCommunityPermissions,
		BaseHttpError,
		PutGlobalPermissionsMutationInput
	>(putCommunityGlobalPermissionsMutationKey);

	return mutation;
};

type MutationContext = {
	communityId: string;
	snapshot: AdvanceCommunityPermissions | undefined;
};

export const setupPutGlobalPermissions: SetupMutationFn = (
	services,
	createTrackedParallelMutation,
	mutationTracker
) => {
	const { queryClient, api } = services;

	const mutation = createTrackedParallelMutation<
		AdvanceCommunityPermissions,
		BaseHttpError,
		PutGlobalPermissionsMutationInput,
		MutationContext
	>({
		mutationFn: async ({ communityId, permissions }) => {
			return api.putCommunityGlobalPermissions(communityId, permissions);
		},
		onMutate: async input => {
			const { communityId, permissions } = input;

			const snapshot =
				queryClient.getQueryData<AdvanceCommunityPermissions>(
					communityKeys.globalPermissions(communityId)
				);

			// Cancel the previous mutation if it exists
			await cancelPreviousMutation<MutationContext>(
				queryClient,
				mutation => {
					return (
						mutation.state.context?.communityId === communityId &&
						isPutGlobalPermissionsMutation(mutation)
					);
				}
			);

			await queryClient.cancelQueries(
				communityKeys.globalPermissions(communityId)
			);

			queryClient.setQueryData<AdvanceCommunityPermissions>(
				communityKeys.globalPermissions(communityId),
				permissions
			);

			return {
				communityId,
				snapshot,
			};
		},
		onSuccess: (result, input, { communityId }) => {
			queryClient.setQueryData<AdvanceCommunityPermissions>(
				communityKeys.globalPermissions(communityId),
				result
			);
		},
		onError: (error, { communityId }, context) => {
			if (context?.snapshot && !isCancelledError(error)) {
				queryClient.setQueryData(
					communityKeys.globalPermissions(communityId),
					context?.snapshot
				);
			}
		},
		onSettled: (data, error, variables, context) => {
			if (context && !isCancelledError(error)) {
				mutationTracker.queueInvalidations(
					communityKeys.globalPermissions(context.communityId),
					permissionKeys.all(context.communityId)
				);
			}
		},
		retry: 1,
	});

	queryClient.setMutationDefaults(
		putCommunityGlobalPermissionsMutationKey,
		mutation
	);
};

export const isPutGlobalPermissionsMutation = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	mutation: Mutation<any, any, any, any>
) =>
	[putCommunityGlobalPermissionsMutationKey].includes(
		String(mutation?.options?.mutationKey)
	);
