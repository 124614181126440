import { selectImage } from 'core/selectors';
import defaultImage from 'assets/images/community_profile.svg?url';
import { communityWithParams } from 'core/utils/request-with-params';
import { BaseHttpClient } from '../base-http-client';
import { CommunitiesResponse } from '../response-types';
import { PaginationArgs } from './types';

export const createIdentityCommunityApi = (http: BaseHttpClient) => ({
	/**
	 * Gets all identity communities
	 *
	 * @param identityId The identity id
	 */
	getMyCommunities(
		identityId: string,
		type?: 'invited' | 'pending',
		{
			pagination,
			signal,
		}: {
			pagination?: PaginationArgs;
			signal?: AbortSignal;
		} = {}
	) {
		return http
			.get<CommunitiesResponse>(
				`identity/${identityId}/community`,
				{
					with: communityWithParams,
					order: 'DESC',
					type,
					...pagination,
				},
				{
					signal,
				}
			)
			.then(response => response.data);
	},

	/**
	 * Gets all invited or pending communities
	 *
	 * @param identityId The identity id
	 */
	getMyCommunitiesByType(identityId: string, type: 'invited' | 'pending') {
		return http
			.get<CommunitiesResponse>(`identity/${identityId}/community`, {
				with: communityWithParams,
				type,
			})
			.then(response =>
				response.data.data.map(community => ({
					...community,
					image: selectImage(community.image, defaultImage),
				}))
			);
	},
});
