import { memo, useCallback, useMemo } from 'react';
import {
	CalendarLoadEventsFilter,
	transformEventToCalendarEvent,
	Box,
	Paper,
	CalendarWidget,
} from 'components';
import { CalendarEvent } from 'components/calendar/types';
import config from 'config';
import { Community, Identity } from 'core/types';
import { useGetEvents } from 'core/uses-cases/calendar';
import { useCommunityMembers } from 'core/uses-cases/member';
import { useRouter, useSafeState } from 'hooks';
import { transformServerToClientLocal } from 'utils/dates';

export type CommunityCalendarWidgetProps = {
	community: Community;
	identity: Identity;
};

const CommunityCalendarWidget = memo(
	({ community, identity }: CommunityCalendarWidgetProps) => {
		const [filter, setFilter] = useSafeState<
			Partial<CalendarLoadEventsFilter>
		>(() => {
			return { startDate: undefined, endDate: undefined };
		});

		const router = useRouter();
		const communityId = community?.slug || '';
		const members = useCommunityMembers(communityId);
		const calendarId = community?.calendar?.uuid || '';

		const { data, status } = useGetEvents(calendarId, {
			startDate: filter.startDate?.toJSDate() ?? null,
			endDate: filter.endDate?.toJSDate() ?? null,
		});

		const handleOnLoadEvents = useCallback(
			(options: CalendarLoadEventsFilter) => {
				setFilter({ ...options });
			},
			[]
		);

		const onEventClick = (event: CalendarEvent) => {
			router.navigate('community.calendar.event.view', {
				communityId,
				calendarId,
				eventId: event.uuid,
			});
		};

		const transformedEvents = useMemo(
			() => (data || []).map(transformEventToCalendarEvent),
			[data]
		);

		const locale = transformServerToClientLocal(identity.language);

		return (
			<Box component={Paper} elevation={0} mb={2} p={3}>
				<CalendarWidget
					timezone={identity?.timezone || config.defaultTimezone}
					locale={locale}
					members={members.map(member => member.identity)}
					events={transformedEvents}
					loadEventsStatus={status}
					loadEvents={handleOnLoadEvents}
					onEventClick={onEventClick}
					exportLink=''
				/>
			</Box>
		);
	}
);

CommunityCalendarWidget.displayName = 'CommunityCalendarWidget';

export { CommunityCalendarWidget };
