import { Box, Button, Link } from 'components';
import {
	COOKIE_CONSENT_KEY,
	LANGUAGE_PREFERENCE_KEY,
	USE_PHP_SITE,
} from 'core/constants';
import { useServices, useTranslation } from 'hooks';
import { useEffect, useState } from 'react';
import ReactCookieConsent, {
	resetCookieConsentValue,
} from 'react-cookie-consent';
import { v4 as uuid } from 'uuid';
import { containerStyle } from './styles';

export const RESET_COOKIE_CONSENT_VALUE = 'reset-cookie-consent-value' as const;

export const CookieConsent: React.FC = () => {
	const { t } = useTranslation();
	const { cookie } = useServices();
	const [id, setId] = useState(() => uuid());

	useEffect(() => {
		const handleOnRemove = () => {
			resetCookieConsentValue(COOKIE_CONSENT_KEY);
			setId(uuid());
		};

		document.addEventListener(
			RESET_COOKIE_CONSENT_VALUE,
			handleOnRemove,
			false
		);

		return () => {
			document.removeEventListener(
				RESET_COOKIE_CONSENT_VALUE,
				handleOnRemove
			);
		};
	}, []);

	const handleOnDecline = () => {
		const cookies = cookie.getAll() as Record<string, string>;

		// Remove all cookies, except language and consent
		Object.keys(cookies).forEach(key => {
			if (
				![
					COOKIE_CONSENT_KEY,
					LANGUAGE_PREFERENCE_KEY,
					USE_PHP_SITE,
				].includes(key)
			) {
				cookie.remove(key);
			}
		});
	};

	return (
		<Box sx={containerStyle}>
			<ReactCookieConsent
				key={id}
				disableStyles
				enableDeclineButton
				cookieName={COOKIE_CONSENT_KEY}
				containerClasses='rcc-container'
				contentClasses='rcc-content'
				ButtonComponent={Button}
				customButtonProps={{
					variant: 'contained',
					size: 'small',
				}}
				customDeclineButtonProps={{
					variant: 'text',
					size: 'small',
				}}
				buttonText={t('cookie-consent.allow-cookie')}
				declineButtonText={t('cookie-consent.decline')}
				onDecline={handleOnDecline}
			>
				{t('cookie-consent.text')}{' '}
				<Link
					href='https://www.cookiesandyou.com/'
					rel='noreferrer'
					target='_blank'
					className='rcc-link'
				>
					{t('cookie-consent.learn-more')}
				</Link>
			</ReactCookieConsent>
		</Box>
	);
};
