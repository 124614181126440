import { isCancelledError, useMutation } from 'react-query';
import { useServices } from 'hooks';
import { patchMemberPreferencesMutationKey } from 'core/utils/mutation-key-factory';
import { PatchIdentityPreferencesInput } from 'core/services/api/api-client/types';
import { MemberPreferencesResponse } from 'core/services/api/response-types';
import { subscriptionKeys } from 'core/utils/query-key-factory';
import { Preferences } from 'core/types';
import { BaseHttpError } from '../../services/api/errors';

export type PatchMemberPreferencesMutationInput = {
	communityId: string;
	identityId: string;
} & PatchIdentityPreferencesInput;

type Context = {
	preferencesSnapshot: Preferences | undefined;
};

export const usePatchMemberPreferences = () => {
	const { queryClient, api } = useServices();

	const mutation = useMutation<
		MemberPreferencesResponse['data'],
		BaseHttpError,
		PatchMemberPreferencesMutationInput,
		Context
	>(
		({ communityId, identityId, ...rest }) => {
			return api
				.patchMemberPreferences(communityId, identityId, rest)
				.then(response => response.data);
		},
		{
			mutationKey: patchMemberPreferencesMutationKey,
			onMutate: ({ communityId, identityId, ...rest }) => {
				const key = subscriptionKeys.preference({
					communityId,
					memberId: identityId,
				});

				const preferences = queryClient.getQueryData<Preferences>(key);

				queryClient.setQueryData<Preferences | undefined>(key, prev => {
					if (!prev) return;

					return {
						...prev,
						...(rest.eventReminderTime
							? { event_reminder_time: rest.eventReminderTime }
							: {}),
						...('announcementSubscriptionStatus' in rest
							? {
								announcement_subscription:
										rest.announcementSubscriptionStatus,
							  }
							: {}), // prettier-ignore
						...('emailDigestSubscriptionStatus' in rest
							? {
								digest_subscription:
										rest.emailDigestSubscriptionStatus,
							  }
							: {}), // prettier-ignore
						...(rest.digestFrequency
							? {
								digest_subscription:
										rest.emailDigestSubscriptionStatus,
							  }
							: {}), // prettier-ignore
						...('dismissedWelcomeMessage' in rest
							? {
								dismissed_welcome_message:
										rest.dismissedWelcomeMessage,
							  }
							: {}), // prettier-ignore
					};
				});

				return {
					preferencesSnapshot: preferences,
				};
			},
			onError: (error, { communityId, identityId }, context) => {
				if (!isCancelledError(error)) {
					queryClient.setQueryData(
						subscriptionKeys.preference({
							communityId,
							memberId: identityId,
						}),
						context?.preferencesSnapshot
					);
				}
			},
		}
	);

	return mutation;
};
