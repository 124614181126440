import { useCurrentCommunityPermissions } from 'components/layout/community';
import {
	EntityKey,
	Nullable,
	PermissibleItem,
	PermissionsKey,
} from 'core/types';

export const useVerifyAccess = () => {
	const communityPermissions = useCurrentCommunityPermissions();

	return (
		action: PermissionsKey,
		type: EntityKey,
		item?: Nullable<PermissibleItem>
	) => {
		let verified = false;

		if (communityPermissions) {
			verified = communityPermissions[type].permissions[action] ?? false;
		}

		if (!verified && item) {
			verified = item.identity_permissions.includes(action);
		}

		return verified;
	};
};
