import { Box, BoxProps, Link } from 'components/common';
import { useCurrentPartnerInfo, useTranslation } from 'hooks';
import lotsaIosMobileDownload from 'assets/images/ios-download-button.svg?url';
import lotsaAndroidMobileDownload from 'assets/images/android-download-button.svg?url';
import config from 'config';
import { RESET_COOKIE_CONSENT_VALUE } from 'components/common/cookie-consent';

export const FooterLinkBox = (props: BoxProps) => (
	<Box
		display='flex'
		color='inherit'
		mr={{ md: 6, xs: 0 }}
		mb={{ xs: 2, md: 0 }}
		sx={{
			'& a': {
				color: 'inherit',
			},
		}}
		{...props}
	/>
);

export const FooterMenu = () => {
	const { t } = useTranslation();
	const { contactUrl } = useCurrentPartnerInfo();

	const handleOnClickCookiePolicy = () => {
		document.dispatchEvent(new CustomEvent(RESET_COOKIE_CONSENT_VALUE));
	};

	return (
		<Box
			display='flex'
			flexDirection={{ xs: 'column', md: 'row' }}
			justifyContent={{ xs: 'center', md: 'flex-end' }}
			mt={{ xs: 2 }}
			alignItems='center'
			flexGrow={1}
			color={theme => theme.blacks[0.6]}
		>
			<FooterLinkBox>
				<Link href={contactUrl} style={{ textDecoration: 'none' }}>
					{t('footer.contact-us')}
				</Link>
			</FooterLinkBox>
			<FooterLinkBox>
				<Link onClick={handleOnClickCookiePolicy}>
					{t('footer.cookie-policy')}
				</Link>
			</FooterLinkBox>
			<FooterLinkBox
				alignItems='center'
				flexDirection={{ xs: 'column', md: 'row' }}
			>
				<Box component='span'>{t('footer.get-the-app')}:</Box>
				<Box
					display='flex'
					justifyContent={{ xs: 'center' }}
					alignContent='center'
				>
					<Box
						component={Link}
						lineHeight='16px'
						href={config.mobileApp.apple}
						ml={{ md: 1, xs: 0 }}
						mb={{ xs: 1, md: 0 }}
					>
						<img
							src={lotsaIosMobileDownload}
							alt={t('footer.download-lotsa-app')}
						/>
					</Box>
					<Box
						component={Link}
						lineHeight='16px'
						href={config.mobileApp.google}
						ml={1}
						mb={{ xs: 1, md: 0 }}
					>
						<img
							src={lotsaAndroidMobileDownload}
							alt={t('footer.download-lotsa-app')}
						/>
					</Box>
				</Box>
			</FooterLinkBox>
		</Box>
	);
};
