import { authenticationKeys } from 'core/utils/query-key-factory';
import { useServices } from 'hooks';
import { useQuery } from 'react-query';

export const useValidateJwt = (token: string, scope: string) => {
	const { api, authentication } = useServices();

	return useQuery(
		authenticationKeys.validateJwt(token),
		() =>
			api.validateJwt(token, scope).then(response => {
				if (
					response.status === 200 &&
					authentication.getAccessToken()
				) {
					authentication.reset({
						keepCurrentPageAfterLogout: true,
					});
				}

				authentication.setAccessToken(response.data.data.access_token);

				return response.data;
			}),
		{
			enabled: !!token && !!scope,
			notifyOnChangeProps: 'tracked',
		}
	);
};
