import { isCancelledError, useMutation, QueryKey } from 'react-query';
import { v4 as uuid } from 'uuid';
import { Comment, Identity } from 'core/types';
import { SetupMutationFn } from 'core/configure/types';
import {
	authenticationKeys,
	discussionKeys,
} from 'core/utils/query-key-factory';
import { postCommentMutationKey } from 'core/utils/mutation-key-factory';
import {
	findRecordFromInfinityQueryCache,
	onErrorOptimisticInInfinityQueryCache,
	onPostMutateSingleRecordInInfinityQueryCache,
	onSuccessSingleRecordInInfinityQueryCache,
	PaginatedRecordsSnapshot,
	updateRecordFromInfinityQueryCache,
} from 'core/utils/optimistic-utils';
import produce from 'immer';
import { Services } from '../../services/types';
import { BaseHttpError } from '../../services/api/errors';
import { CommentInput } from '../../services/api/api-client/types';

export interface PostCommentMutationInput extends CommentInput {
	discussionId: string;
	parentId?: string;
}

export const usePostComment = () => {
	const mutation = useMutation<
		Comment,
		BaseHttpError,
		PostCommentMutationInput
	>(postCommentMutationKey);

	return mutation;
};

type MutationContext = {
	commentId: string;
	comment: Comment;
	listKey: QueryKey;
};

export const setupPostComment: SetupMutationFn = (
	services: Services,
	createTrackedParallelMutation,
	mutationTracker
) => {
	const { queryClient, api } = services;

	const mutation = createTrackedParallelMutation<
		Comment,
		BaseHttpError,
		PostCommentMutationInput,
		MutationContext
	>({
		mutationFn: ({ discussionId, parentId, ...input }) => {
			const actionCall = parentId
				? api.postCommentReply.bind(api, parentId)
				: api.postComment.bind(api, discussionId);

			return actionCall(input).then(response => response.data);
		},
		onMutate: async input => {
			const { discussionId, parentId, message } = input;

			const commentId = uuid();

			const identity = queryClient.getQueryData<Identity>(
				authenticationKeys.identity
			) as Identity;

			const discussion = queryClient.getQueryData<
				PaginatedRecordsSnapshot<Comment>
			>(discussionKeys.comments(discussionId));

			const commentCount =
				discussion?.pages?.[0]?.data?.[0]?.discussion_stats?.comments ||
				0;

			const comment: Comment = {
				uuid: commentId,
				message,
				commenter: identity,
				created_dt: null,
				...(!parentId && {
					replies: [],
					more_replies: false,
					discussion_stats: { comments: commentCount + 1 },
				}),
			};

			const listKey = parentId
				? discussionKeys.replies(discussionId, parentId)
				: discussionKeys.comments(discussionId);

			await onPostMutateSingleRecordInInfinityQueryCache<Comment>(
				queryClient,
				listKey,
				comment
			);

			if (parentId) {
				const { record } =
					findRecordFromInfinityQueryCache<Comment>(
						queryClient,
						discussionKeys.comments(discussionId),
						parentId
					) ?? {};

				if (record) {
					await queryClient.cancelQueries(
						discussionKeys.comments(discussionId)
					);

					updateRecordFromInfinityQueryCache(
						queryClient,
						discussionKeys.comments(discussionId),
						{
							...record,
							replies: [comment, ...(record.replies ?? [])],
						}
					);
				}

				// Update comment count
				queryClient.setQueryData<PaginatedRecordsSnapshot<Comment>>(
					discussionKeys.comments(discussionId),
					currentData => {
						if (!currentData) return currentData;
						return produce(currentData, draft => {
							if (draft.pages?.[0].data?.[0]?.discussion_stats) {
								draft.pages[0].data[0].discussion_stats.comments =
									commentCount + 1;
							}
						});
					}
				);
			}

			return { commentId, listKey, comment };
		},
		onSuccess: (result, input, { listKey, commentId }) => {
			onSuccessSingleRecordInInfinityQueryCache(
				queryClient,
				listKey,
				commentId,
				result
			);
		},
		onError: (error, input, context) => {
			if (context && !isCancelledError(error)) {
				onErrorOptimisticInInfinityQueryCache(
					queryClient,
					context.listKey,
					context.commentId
				);
			}
		},
		onSettled: (data, error, input, context) => {
			if (context && !isCancelledError(error)) {
				mutationTracker.queueInvalidations(context.listKey);
			}
		},
		retry: 1,
	});

	queryClient.setMutationDefaults(postCommentMutationKey, mutation);
};
