import { isCancelledError, Mutation, useMutation } from 'react-query';
import { communityKeys, permissionKeys } from 'core/utils/query-key-factory';
import { deleteCommunityGroupMutationKey } from 'core/utils/mutation-key-factory';
import { SetupMutationFn } from 'core/configure/types';
import { Services } from 'core/services';
import { Community, Group } from 'core/types';
import {
	cancelPreviousMutation,
	findRecordFromInfinityQueryCache,
	onDeleteMutateOptimisticInfinityQueryCache,
	PaginatedRecordsSnapshot,
	removeRecordFromInfinityQueryCache,
} from 'core/utils/optimistic-utils';
import produce from 'immer';
import { BaseHttpError } from '../../services/api/errors';

export interface DeleteCommunityGroupMutationInput {
	communityId: string;
	groupId: string;
}

export const useDeleteCommunityGroup = () => {
	const mutation = useMutation<
		null,
		BaseHttpError,
		DeleteCommunityGroupMutationInput
	>(deleteCommunityGroupMutationKey);

	return mutation;
};

type MutationContext = {
	listSnapshot: PaginatedRecordsSnapshot<Group>;
};

export const setupDeleteCommunityGroup: SetupMutationFn = (
	services: Services,
	createTrackedParallelMutation,
	mutationTracker
) => {
	const { queryClient, api } = services;

	const mutation = createTrackedParallelMutation<
		null,
		BaseHttpError,
		DeleteCommunityGroupMutationInput,
		MutationContext
	>({
		mutationFn: ({ communityId, groupId }) => {
			if (groupId.length === 22) {
				return api.deleteGroup(communityId, groupId);
			}

			return Promise.resolve(null);
		},
		onMutate: async input => {
			const { communityId, groupId } = input;

			// Cancel the previous mutation if it exists
			await cancelPreviousMutation<MutationContext>(
				queryClient,
				mutation => {
					return (
						mutation.state.variables?.communityId === communityId &&
						isDeleteCommunityGroup(mutation)
					);
				}
			);

			const { listSnapshot } =
				findRecordFromInfinityQueryCache<Group>(
					queryClient,
					communityKeys.groups(communityId),
					groupId
				) ?? {};

			await onDeleteMutateOptimisticInfinityQueryCache<Group>(
				queryClient,
				communityKeys.groups(communityId),
				communityKeys.group(communityId, groupId),
				groupId
			);

			queryClient.setQueryData<Community | undefined>(
				communityKeys.detail(communityId),
				data => {
					if (!data) return data;

					return produce(data, draft => {
						const groupIndex = draft.groups.all.findIndex(
							group => group.uuid === groupId
						);
						if (groupIndex !== -1) {
							draft.groups.all.splice(groupIndex, 1);
						}
					});
				}
			);

			removeRecordFromInfinityQueryCache(
				queryClient,
				communityKeys.groups(communityId),
				groupId
			);

			return { listSnapshot };
		},
		onError: (error, { communityId }, context) => {
			if (context?.listSnapshot && !isCancelledError(error)) {
				queryClient.setQueryData(
					communityKeys.groups(communityId),
					context?.listSnapshot
				);
			}
		},
		onSettled: (data, error, { communityId, groupId }, context) => {
			if (context && !isCancelledError(error)) {
				if (groupId.length === 22) {
					// Only trigger the refetch when it is a real resource
					mutationTracker.queueInvalidations(
						communityKeys.groups(communityId),
						communityKeys.detail(communityId),
						permissionKeys.all(communityId)
					);
				}
			}
		},
		retry: 1,
	});

	queryClient.setMutationDefaults(deleteCommunityGroupMutationKey, mutation);
};

export const isDeleteCommunityGroup = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	mutation: Mutation<any, any, any, any>
) =>
	[deleteCommunityGroupMutationKey].includes(
		String(mutation?.options?.mutationKey)
	);
