import { Identity, PhoneNumberValue, Nullable } from 'core/types';
import { useTranslation } from 'hooks';
import { useCallback, useRef } from 'react';
import { sleep } from 'utils/delay';
import * as Yup from 'yup';
import { phoneNumber } from 'utils/validation';
import {
	Box,
	TextField,
	LoadingButton,
	Form,
	Field,
	FormOnSubmitReturn,
	FormStack,
	TelephoneField,
	CheckboxField,
	SelectField,
	SelectItem,
	ImagePickerField,
	PickerFile,
	PasswordRulesChecker,
	PasswordField,
	Grid,
	Paper,
	FieldForSubscription,
	TimeZoneField,
} from 'components/common';
import { TFunction } from 'i18next';
import config from 'config';
import { ENGLISH_CODE, FRENCH_CODE } from 'core/constants';
import { getCurrentTimeZone } from 'utils/dates';

export type EditAccountFormSubmittedValues = {
	firstName: string;
	lastName: string;
	email: string;
	phone: PhoneNumberValue;
	location: string;
	address: string;
	detectTimezone: boolean;
	timezone: string;
	language: string;
	image: Nullable<PickerFile>;
	passwordOld: string;
	passwordNew: string;
};

export type EditAccountFormInitialValues =
	Partial<EditAccountFormSubmittedValues>;

export type EditAccountFormProps = {
	initialValues: EditAccountFormInitialValues;
	onSubmit?: (
		values: EditAccountFormSubmittedValues
	) => FormOnSubmitReturn<Identity>;
	submitText?: string;
	submittingText?: string;
	submitPasswordText?: string;
	submittingPasswordText?: string;
};

export const EditAccountForm = (props: EditAccountFormProps) => {
	const {
		initialValues,
		onSubmit,
		submitText,
		submittingText,
		submitPasswordText,
		submittingPasswordText,
	} = props;

	const cachedInitialValues = useRef({
		...initialValues,
		image: initialValues?.image ? [initialValues?.image] : null,
	}).current;

	const { t } = useTranslation();
	const enableLanguage = config.enableLanguage;

	const languages = [
		{
			value: ENGLISH_CODE,
			label: t('languages.english'),
		},
		{
			value: FRENCH_CODE,
			label: t('languages.french'),
		},
	];

	const handleOnSubmit = useCallback(
		async (values, form) => {
			await sleep(1);
			const image = values.image?.[0] ? values.image[0] : undefined;

			if (values.detectTimezone) {
				values.timezone = getCurrentTimeZone();
			}

			const result = onSubmit?.({ ...values, image });

			return Promise.resolve(result)
				.then(() => {
					form.change('passwordNew', '');
					form.change('passwordOld', '');
					form.change('logMeOut', false);
				})
				.catch(error => error);
		},
		[onSubmit]
	);

	const validationSchema = createValidationSchema(t);

	return (
		<Form
			onSubmit={handleOnSubmit}
			validationSchema={validationSchema}
			subscription={{ submitting: true }}
			initialValues={cachedInitialValues}
		>
			{({ handleSubmit, submitting }) => (
				<form onSubmit={handleSubmit} noValidate>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={6}>
							<Box
								component={Paper}
								px={4.5}
								py={3}
								elevation={0}
								display='flex'
								flexDirection='column'
							>
								<FormStack responsive={false}>
									<Box>
										<Field
											label={t('common-label.first-name')}
											name='firstName'
											component={TextField}
											type='text'
											placeholder={t(
												'common-label.first-name'
											)}
											fullWidth
											required
										/>
									</Box>
									<Box>
										<Field
											label={t('common-label.last-name')}
											name='lastName'
											component={TextField}
											type='text'
											placeholder={t(
												'common-label.last-name'
											)}
											fullWidth
											required
										/>
									</Box>
									<Box>
										<Field
											label={t('common-label.email')}
											name='email'
											component={TextField}
											type='text'
											placeholder={t(
												'common-label.email'
											)}
											fullWidth
											required
										/>
									</Box>
									<Box>
										<Field
											label={t('common-label.phone')}
											name='phone'
											component={TelephoneField}
											type='text'
											placeholder='(201) 555-0123'
											fullWidth
											required
										/>
									</Box>
									<Box>
										<Field
											label={t('account.location')}
											name='location'
											component={TextField}
											type='text'
											placeholder={t('account.type-here')}
											fullWidth
										/>
									</Box>
									<Box>
										<Field
											label={t('account.address')}
											name='address'
											component={TextField}
											type='text'
											placeholder={t('account.type-here')}
											fullWidth
											multiline
											rows={2}
										/>
									</Box>
									<Box>
										<FieldForSubscription
											name='detectTimezone'
											subscription={{ value: true }}
										>
											{({ input: { value } }) => (
												<Field
													name='timezone'
													component={TimeZoneField}
													update={!value}
												/>
											)}
										</FieldForSubscription>
									</Box>
									<Box style={{ marginTop: 0 }}>
										<Field
											label={t('account.autodetect')}
											name='detectTimezone'
											component={CheckboxField}
										/>
									</Box>
									{enableLanguage && (
										<Box>
											<Field
												label={t('account.language')}
												name='language'
												component={SelectField}
												fullWidth
												required
											>
												{languages.map(language => (
													<SelectItem
														key={language.value}
														value={language.value}
													>
														{language.label}
													</SelectItem>
												))}
											</Field>
										</Box>
									)}
									<Box>
										<Field
											name='image'
											label={t('account.profile-photo')}
											component={ImagePickerField}
										/>
									</Box>
									<Box>
										<LoadingButton
											loading={submitting}
											loadingIndicator={
												submittingText ||
												t('form.submitting')
											}
											size='small'
											type='submit'
										>
											{submitText || t('form.submit')}
										</LoadingButton>
									</Box>
								</FormStack>
							</Box>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Box
								component={Paper}
								px={4.5}
								py={3}
								elevation={0}
								display='flex'
								flexDirection='column'
							>
								<FormStack responsive={false}>
									<Box>
										<Field
											label={t('account.passwordOld')}
											name='passwordOld'
											component={PasswordField}
											type='password'
											fullWidth
										/>
									</Box>
									<Box>
										<Field
											label={t('account.passwordNew')}
											name='passwordNew'
											component={PasswordField}
											type='password'
											fullWidth
										/>
										<PasswordRulesChecker
											forField='passwordNew'
											usernameField='email'
											initialEmail={
												initialValues.email || ''
											}
											required={false}
										/>
									</Box>
									<Box>
										<Field
											label={t(
												'account.log-me-out-on-other-devices'
											)}
											name='logMeOut'
											component={CheckboxField}
										/>
									</Box>
									<Box>
										<LoadingButton
											loading={submitting}
											loadingIndicator={
												submittingPasswordText ||
												t('form.submitting')
											}
											size='small'
											type='submit'
										>
											{submitPasswordText ||
												t('form.submit')}
										</LoadingButton>
									</Box>
								</FormStack>
							</Box>
						</Grid>
					</Grid>
				</form>
			)}
		</Form>
	);
};

function createValidationSchema(t: TFunction) {
	const regex = new RegExp(`${ENGLISH_CODE}|${FRENCH_CODE}`);

	const schema = Yup.object().shape(
		{
			firstName: Yup.string().trim().required(t('form.generic-required')),
			lastName: Yup.string().trim().required(t('form.generic-required')),
			email: Yup.string().trim().required(t('form.generic-required')),
			phone: phoneNumber(t('form.invalid-phone')).required(
				t('form.generic-required')
			),
			language: Yup.string()
				.trim()
				.matches(regex, {
					message: t('form.generic-required'),
				})
				.required(t('form.generic-required')),
			timezone: Yup.string().trim().required(t('form.generic-required')),
			passwordOld: Yup.string().when('passwordNew', {
				is: (value: string) => !!value,
				then: schema =>
					schema.required(t('form.old-password-required')),
				otherwise: schema => schema.notRequired(),
			}),
			passwordNew: Yup.string().when('passwordOld', {
				is: (value: string) => !!value,
				then: schema =>
					schema.required(t('form.new-password-required')),
				otherwise: schema => schema.notRequired(),
			}),
		},
		[['passwordNew', 'passwordOld']]
	);

	return schema;
}
