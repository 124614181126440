import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import EventNoteIcon from '@mui/icons-material/EventNote';
import { DateTime } from 'luxon';
import { TextFieldProps } from '../text-field';

export type DatePickerFieldProps = DatePickerProps<DateTime> &
	Omit<
		TextFieldProps,
		| 'value'
		| 'onChange'
		| 'multiline'
		| 'type'
		| 'rows'
		| 'maxRows'
		| 'minRows'
		| 'select'
		| 'SelectProps'
		| 'type'
		| 'defaultValue'
	> & {
		'data-test-id'?: string;
	};

export const DatePickerField = (props: DatePickerFieldProps) => {
	const {
		helperText,
		error,
		onFocus,
		onBlur,
		size,
		name,
		inputProps,
		...rest
	} = props;

	return (
		<DatePicker
			{...rest}
			slots={{
				openPickerIcon: EventNoteIcon,
			}}
			format='M/d/yyyy'
			slotProps={{
				textField: {
					...(rest['data-test-id'] && {
						'data-test-id': rest['data-test-id'],
					}),
					name,
					error,
					helperText,
					onFocus,
					onBlur,
					inputProps,
					size,
				},
			}}
			views={['month', 'day']}
		/>
	);
};
