import {
	Box,
	Grid,
	Typography,
	Heading,
	Paper,
	RouterLink,
} from 'components/common';
import {
	CreateCommunityForm,
	CreateCommunityFormSubmittedValues,
} from 'components/community/setup';
import { AUTH_INVALID, HttpClientError } from 'core/services/api/errors';
import { NotOptionals } from 'core/types';
import { useLogin } from 'core/uses-cases/authentication';
import { usePostCommunity } from 'core/uses-cases/community';
import {
	useGetCheckIdentityEmail,
	usePostIdentity,
} from 'core/uses-cases/identity';
import { useRouter, useServices, useTranslation } from 'hooks';
import { useCallback, useState } from 'react';
import { useStore } from 'store';
import { getCurrentTenant, getPartner } from 'tenants/utils';

const tenant = getCurrentTenant();
const partner = getPartner(tenant);

export const CreateCommunity = () => {
	const [email, setEmail] = useState('');
	const { t } = useTranslation();

	const {
		auth: { isLoggedIn },
	} = useStore();

	const router = useRouter();
	const { api, notification } = useServices();
	const communityMutation = usePostCommunity();
	const identityMutation = usePostIdentity();
	const loginMutation = useLogin();
	const { data: emailExists = false, status } =
		useGetCheckIdentityEmail(email);

	const handleOnSubmit = useCallback(
		async (values: CreateCommunityFormSubmittedValues) => {
			try {
				const {
					name,
					purpose,
					description,
					zipCode,
					contact,
					searchEnabled,
					recaptchaToken,
					...identityValues
				} = values;

				if (!isLoggedIn) {
					const { email, password, ...rest } =
						identityValues as NotOptionals<CreateCommunityFormSubmittedValues>;

					if (!emailExists) {
						await identityMutation.mutateAsync({
							email,
							password,
							...rest,
						});
					}

					await loginMutation.doLoginAsync({
						username: email,
						password,
					});
				}

				if (recaptchaToken) {
					await api.postReCaptchaToken(recaptchaToken);
				}

				const community = await communityMutation.postAsync({
					name,
					purpose,
					partner: tenant ?? 'lotsa',
					description,
					zipCode,
					searchEnabled,
					contactGroup: contact,
				});

				notification.show(
					'success',
					t('create-community.community-created')
				);

				router.navigate('community.setup-profile', {
					communityId: community.slug,
				});
			} catch (error) {
				if (
					error instanceof HttpClientError &&
					error?.errorCode === AUTH_INVALID
				) {
					throw { password: t('login.invalid-password') };
				}
			}
		},
		[isLoggedIn, emailExists]
	);

	return (
		<Box>
			<Grid mb={4} container spacing={3}>
				<Grid item xs={12} md={8}>
					<Heading variant='h4' mb={1}>
						{t('create-community.create-community')}
					</Heading>
					<Typography variant='body2' mb={1}>
						{t('create-community.provides-an-easy-way', {
							partner: partner.name,
						})}
					</Typography>
					{!isLoggedIn && (
						<Typography variant='body2' mb={1}>
							{t('create-community.already-a-member')}
							<RouterLink
								to={router.getPathFor(
									'account.login',
									{},
									{ r: router.getPathFor('community.create') }
								)}
							>
								{t('create-community.log-in')}
							</RouterLink>
						</Typography>
					)}
				</Grid>
			</Grid>
			<Box component={Paper} elevation={0} mt={6} px={5} py={5}>
				<CreateCommunityForm
					onSubmit={handleOnSubmit}
					submitText={t('create-community.create-my-community')}
					submittingText={t('create-community.creating-community')}
					isLoggedIn={isLoggedIn}
					onCheckEmail={email => {
						setEmail(email);
					}}
					emailCheckStatus={status}
					emailExists={emailExists}
				/>
			</Box>
		</Box>
	);
};
