import {
	CommunityMemberRaw,
	CommunityMember,
	Event,
	RawEvent,
} from 'core/types';
import { DateTime } from 'luxon';

export const transformEvent = (event: RawEvent): Event => {
	return {
		...event,
		settings: {
			...event.settings,
			volunteerSettings: {
				...event.settings.volunteerSettings,
				privacy: event.settings.volunteerSettings.volunteer,
			},
		},
	};
};

export const transformCommunityMember = (
	member: CommunityMemberRaw
): CommunityMember => {
	return {
		...member,
		recent_activity: member.recent_activity.map(activity => {
			const [dateTime] = /^[^.]+/.exec(activity.date.date) ?? [''];

			const date = DateTime.fromFormat(dateTime, 'yyyy-MM-dd HH:mm:ss', {
				zone: activity.date.timezone,
			});

			return {
				...activity,
				date: date.toISO(),
			};
		}),
		member_schedule: member.member_schedule.map(record => {
			const startDate = DateTime.fromFormat(
				record.start_date.date.split(' ')[0],
				'yyyy-MM-dd',
				{ zone: record.start_date.timezone }
			);

			const endDate = DateTime.fromFormat(
				record.end_date.date.split(' ')[0],
				'yyyy-MM-dd',
				{ zone: record.end_date.timezone }
			);

			return {
				...record,
				start_date: startDate.toISODate(),
				end_date: endDate.toISODate(),
			};
		}),
	};
};
