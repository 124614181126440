import { DateTime } from 'luxon';
import { useCallback, useMemo, useRef, useState } from 'react';
import { debounce } from 'utils/delay';
import { FILTER_STATUSES } from '../constants';
import { CalendarEvent, CalendarViewTypes } from '../types';
import {
	CalendarContext,
	CalendarContextValue,
	CalendarLoadEventsFilter,
	CalendarFilter,
	LoadEventFn,
} from './context';

export type CalendarProviderProps = Pick<
	CalendarContextValue,
	| 'timezone'
	| 'locale'
	| 'events'
	| 'loadEventsStatus'
	| 'onEventClick'
	| 'members'
	| 'exportLink'
> & {
	loadEvents: (filter: CalendarLoadEventsFilter) => void;
};

export const CalendarProvider: React.FC<CalendarProviderProps> = ({
	timezone = 'America/Chicago',
	locale = 'en-US',
	children,
	events: rawEvents,
	loadEventsStatus,
	loadEvents: loadEventsFn,
	members,
	onEventClick,
	exportLink,
}) => {
	const loadEventsFilterRef = useRef<CalendarLoadEventsFilter>({
		startDate: DateTime.local({ zone: timezone }),
		endDate: DateTime.local({ zone: timezone }),
	});

	const [currentView, setCurrentView] = useState<CalendarViewTypes>();
	const [displayed, setDisplayed] = useState(() =>
		DateTime.local({ zone: timezone })
	);
	const [selected, setSelected] = useState<DateTime | null>(null);
	const [filter, setFilter] = useState<CalendarFilter>({
		statuses: [
			FILTER_STATUSES.HELP_NEEDED,
			FILTER_STATUSES.NEEDS_MET,
			FILTER_STATUSES.OCCASIONS,
		],
		eventTypes: [],
		memberIds: [],
	});

	const formatDate = useCallback(
		(date: DateTime, format: string) =>
			date.setLocale(locale).toFormat(format),
		[locale]
	);

	const [events, eventsMap] = useMemo(() => {
		const events = rawEvents
			.map(event => {
				return {
					...event,
					startDate: event.startDateTime
						? event.startDate.setZone(timezone)
						: event.startDate,
					endDate: event.endDateTime
						? event.endDate.setZone(timezone)
						: event.endDate,
					startDateTime: event.startDateTime
						? event.startDateTime.setZone(timezone)
						: null,
					endDateTime: event.endDateTime
						? event.endDateTime.setZone(timezone)
						: null,
				};
			})
			.sort((a, b) => {
				if (a.startDateTime && b.startDateTime) {
					return (
						a.startDateTime.toMillis() - b.startDateTime.toMillis()
					);
				}

				return -1;
			});

		// Group events by day and hours (map[iso-date-format] = events)
		const eventsMap = events.reduce((carry, event) => {
			const dayKey = (event.startDateTime || event.startDate).toFormat(
				'yyyy-MM-dd'
			);

			if (!carry.has(dayKey)) {
				carry.set(dayKey, []);
			}

			carry.get(dayKey)?.push(event);

			return carry;
		}, new Map<string, CalendarEvent[]>());

		return [events, eventsMap];
	}, [rawEvents]);

	const getNextEventFilter = (options: CalendarLoadEventsFilter) => {
		const { current: currentFilter } = loadEventsFilterRef;

		const nextFilter = {
			...currentFilter,
			...options,
		};

		const { startDate, endDate } = nextFilter;

		const incomingDatesAreIn =
			currentFilter &&
			startDate >= currentFilter.startDate &&
			endDate <= currentFilter.endDate;

		if (incomingDatesAreIn) {
			return;
		}

		loadEventsFilterRef.current = nextFilter;

		return nextFilter;
	};

	const handleLoadEvents = useCallback(
		debounce((options: CalendarLoadEventsFilter) => {
			const nextFilter = getNextEventFilter(options);

			if (!nextFilter) {
				return;
			}

			loadEventsFn({ ...nextFilter });
		}, 500),
		[loadEventsFn]
	);

	const loadEvents: LoadEventFn = useCallback(
		argument => {
			handleLoadEvents(
				typeof argument === 'function'
					? argument(loadEventsFilterRef.current)
					: argument
			);
		},
		[loadEventsFilterRef.current]
	);

	const value: CalendarContextValue = {
		timezone,
		locale,
		displayed,
		setDisplayed,
		selected,
		setSelected,
		currentView,
		setCurrentView,
		formatDate,
		events,
		eventsMap,
		members,
		loadEvents,
		onEventClick,
		loadEventsStatus,
		filter,
		setFilter,
		exportLink,
	};

	const renderedContent = useMemo(() => children, []);

	return (
		<CalendarContext.Provider value={value}>
			{renderedContent}
		</CalendarContext.Provider>
	);
};
