import { FeedRecord } from 'core/types';
import { InfiniteData } from 'react-query';
import { useStore } from 'store';
import { FEED_FILTER_VALUES } from 'store/feedFilter';

export const useFilterFeed = (data: InfiniteData<FeedRecord[]> | undefined) => {
	const {
		feedFilter: { filter },
	} = useStore();

	if (!data) {
		return data;
	}

	let pages = data.pages.slice();
	let items: FeedRecord[] = pages.flat();

	if (filter !== FEED_FILTER_VALUES.everything) {
		items = items.filter(item => item.type === filter);

		pages = items.reduce<FeedRecord[][]>(
			(endArray: FeedRecord[][], item: FeedRecord, index: number) => {
				const chunkIndex = Math.floor(index / 10);

				if (!endArray[chunkIndex]) {
					endArray[chunkIndex] = [];
				}
				endArray[chunkIndex].push(item);

				return endArray;
			},
			[]
		);
	}

	return {
		pages,
		pageParams: data.pageParams,
	};
};
