import {
	Box,
	Typography,
	Paper,
	CircularProgress,
	PageHeading,
} from 'components/common';
import {
	AnnouncementForm,
	AnnouncementFormValues,
} from 'components/announcements';
import {
	useErrorHandler,
	useParams,
	useRouter,
	useServices,
	useSubmitHandlerForPausedMutation,
	useTranslation,
} from 'hooks';
import {
	useGetAnnouncement,
	usePatchAnnouncement,
} from 'core/uses-cases/announcement';
import {
	useAnnouncementFormData,
	useDeleteAnnouncementHandler,
} from 'components/announcements/hooks';

export const EditAnnouncement = () => {
	const { t } = useTranslation();

	const { communityId, announcementId } = useParams() as {
		communityId: string;
		announcementId: string;
	};

	const router = useRouter();

	const { notification } = useServices();

	const announcementQuery = useGetAnnouncement(communityId, announcementId);

	const { mutateAsync, isPaused } = usePatchAnnouncement();

	const handleOnSubmit =
		useSubmitHandlerForPausedMutation<AnnouncementFormValues>(
			isPaused,
			values =>
				mutateAsync({
					...values,
					communityId,
					announcementId,
					createdDt: announcementQuery.data?.created_dt,
				}),
			{
				onSuccess: ({ values }) => {
					notification.show(
						'success',
						t(
							'announcements.announcement-was-successfully-updated',
							{
								title: values.title,
							}
						)
					);

					router.navigate('community.announcements', {
						communityId,
					});
				},
			},
			[communityId, announcementId, announcementQuery.data]
		);

	const { initialValues, groups, authors } = useAnnouncementFormData(
		communityId,
		announcementQuery.data
	);

	const onDeleteHandler = useDeleteAnnouncementHandler({
		onConfirm: () =>
			router.navigate('community.announcements', {
				communityId,
			}),
	});

	const handleOnDelete = () => {
		onDeleteHandler({ communityId, announcementId });
	};

	useErrorHandler(announcementQuery.error, {
		redirectTo: router.getPathFor('community.announcements', {
			communityId,
		}),
	});

	if (announcementQuery.isLoading) {
		return (
			<Box display='flex' justifyContent='center' mt={4}>
				<CircularProgress message={t('common.loading')} />
			</Box>
		);
	}

	return (
		<Box>
			<PageHeading title={t('announcement-form.edit-announcement')}>
				<Typography variant='body2' mb={1}>
					{t('announcement-form.announcements-are-great')}
				</Typography>
				<Typography variant='body2' mb={1}>
					{t('announcement-form.announcement-description-edit')}
				</Typography>
			</PageHeading>
			<Box component={Paper} elevation={0} mt={6} px={5} py={5}>
				{announcementQuery.isSuccess && announcementQuery.data && (
					<>
						<AnnouncementForm
							onSubmit={handleOnSubmit}
							onDelete={handleOnDelete}
							submitText={t(
								'announcement-form.save-announcement'
							)}
							submittingText={t(
								'announcement-form.saving-announcement'
							)}
							initialValues={initialValues}
							groups={groups}
							authors={authors}
							deleteText={t(
								'announcement-form.delete-announcement'
							)}
						/>
					</>
				)}
			</Box>
		</Box>
	);
};
