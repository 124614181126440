import { COMMUNITY_SETUP_PROFILE } from 'core/constants';
import {
	AdvanceCommunityPermissions,
	InvitationTokenStatusResponse,
} from 'core/types';
import {
	albumWithParams,
	announcementWithParams,
	communityFeedWithParams,
	communityWithParams,
	noteWithParams,
	photoWithParams,
	wellWishWithParams,
	entityParams,
	groupWithParams,
	membershipRequestsWithParams,
	membershipInvitationsWithParams,
	identityWithParams,
} from 'core/utils/request-with-params';
import { isUndefined } from 'utils/is';
import { BaseHttpClient } from '../base-http-client';
import {
	AlbumResponse,
	AlbumsResponse,
	AlbumPhotoResponse,
	AlbumPhotosResponse,
	AnnouncementResponse,
	AnnouncementsResponse,
	CommunityPermissionsResponse,
	CommunityResponse,
	FeedResponse,
	MembershipInvitationResponse,
	NoteResponse,
	NotesResponse,
	WellWishesResponse,
	WellWishResponse,
	PostAlbumPhotoResponse,
	SearchResponse,
	GroupsResponse,
	CommunityMembershipRequestsResponse,
	CommunityMembershipInvitesResponse,
	InvitationTokenResponse,
	TransferOwnershipRequestsResponse,
	PatchCommunityMembershipInvitationResponse,
	GroupResponse,
	CommunityGlobalPermissionsResponse,
	TransferRequestTokenResponse,
	AutoLoginTokenResponse,
} from '../response-types';
import type {
	AnnouncementInput,
	CommunityInput,
	PatchCommunityInput,
	ShutdownCommunityInput,
	MembershipInvitationInput,
	NoteInput,
	PaginationArgs,
	RequestOptions,
	WellWishInput,
	SearchInput,
	AlbumInput,
	GroupInput,
	PatchGroupInput,
} from './types';

export const createCommunityApi = (http: BaseHttpClient) => ({
	/**
	 * Creates a new community
	 *
	 * @param {CommunityInput} input
	 * @return {*}
	 */
	postCommunity(input: CommunityInput) {
		const { zipCode, searchEnabled, contactGroup, ...rest } = input;

		return http
			.post<CommunityResponse>(
				'community',
				{
					...rest,
					contact_group: contactGroup,
					zip_code: zipCode,
					search_enabled: searchEnabled,
					setup_status: COMMUNITY_SETUP_PROFILE,
				},
				{
					params: {
						with: communityWithParams,
					},
				}
			)
			.then(response => response.data);
	},

	/**
	 * Updates a community
	 *
	 * @param input
	 * @returns
	 */
	patchCommunity(communityId: string, input: Partial<PatchCommunityInput>) {
		const {
			zipCode,
			searchEnabled,
			contactGroup,
			allowMemberInvites,
			allowMemberImageUploads,
			availableSections,
			image,
			...rest
		} = input;

		return http
			.patch<CommunityResponse>(
				`community/${communityId}`,
				{
					...rest,
					zip_code: zipCode,
					search_enabled: searchEnabled,
					contact_group: contactGroup,
					invite_permission: allowMemberInvites,
					allow_members_to_upload: allowMemberImageUploads,
					available_sections: availableSections,
					...(!isUndefined(image) ? { image } : {}),
				},
				{
					params: {
						with: communityWithParams,
					},
				}
			)
			.then(response => response.data);
	},

	shutdownCommunity(communityId: string, input: ShutdownCommunityInput) {
		return http
			.delete<null>(`community/${communityId}`, { data: input })
			.then(() => null);
	},

	/**
	 * Gets the community with the given id.
	 *
	 * @param id
	 */
	getCommunity(id: string) {
		return http
			.get<CommunityResponse>(`community/${id}`, {
				with: communityWithParams,
			})
			.then(response => response.data);
	},

	/**
	 * Searches for communities
	 *
	 * @param {SearchInput} input
	 * @return {*}
	 */
	searchCommunities(input: SearchInput) {
		return http
			.get<SearchResponse>('community', {
				query: input.searchTerm,
				location: input.zipCode,
				within_distance: input.withinDistance,
				with: 'image',
			})
			.then(response => response.data);
	},

	getCommunityFeed(
		id: string,
		{
			pagination,
			signal,
		}: {
			pagination?: PaginationArgs;
			signal?: AbortSignal;
		} = {}
	) {
		return http
			.get<FeedResponse>(
				`community/${id}/feed`,
				{
					with: communityFeedWithParams,
					...pagination,
				},
				{
					signal,
				}
			)
			.then(response => response.data);
	},

	getCommunityPermissions(communityId: string, identityId: string) {
		return http
			.get<CommunityPermissionsResponse>(
				`community/${communityId}/identity/${identityId}/permission`,
				{
					entity: entityParams,
				}
			)
			.then(response => response.data);
	},

	getCommunityGlobalPermissions(communityId: string) {
		return http
			.get<CommunityGlobalPermissionsResponse>(
				`community/${communityId}/global-permission`
			)
			.then(response => response.data);
	},

	putCommunityGlobalPermissions(
		communityId: string,
		input: AdvanceCommunityPermissions
	) {
		return http
			.put<AdvanceCommunityPermissions>(
				`community/${communityId}/global-permission`,
				input
			)
			.then(response => response.data);
	},

	getCommunityMembershipRequests(
		communityId: string,
		options?: RequestOptions
	) {
		return http
			.get<CommunityMembershipRequestsResponse>(
				`community/${communityId}/membership-request`,
				{
					with: membershipRequestsWithParams,
					...options?.pagination,
				},
				{
					...(options?.signal ? { signal: options.signal } : {}),
				}
			)
			.then(response => response.data);
	},

	getCommunityMembershipInvitations(
		communityId: string,
		options?: RequestOptions
	) {
		return http
			.get<CommunityMembershipInvitesResponse>(
				`community/${communityId}/membership-invitation`,
				{
					with: membershipInvitationsWithParams,
					type: 'pending',
					...options?.pagination,
				},
				{
					...(options?.signal ? { signal: options.signal } : {}),
				}
			)
			.then(response => response.data);
	},

	patchCommunityMembershipRequests(
		communityId: string,
		ids: string[],
		action: string
	) {
		return http
			.patch(
				`community/${communityId}/membership-request`,
				{
					action,
					ids,
				},
				{
					params: {
						with: membershipRequestsWithParams,
					},
				}
			)
			.then(response => response.data);
	},

	patchCommunityMembershipInvitations(
		communityId: string,
		ids: string[],
		action: string
	) {
		return http
			.patch<PatchCommunityMembershipInvitationResponse>(
				`community/${communityId}/membership-invitation`,
				{
					action,
					ids,
				},
				{
					params: {
						with: membershipInvitationsWithParams,
					},
				}
			)
			.then(response => response.data);
	},

	getFailedCommunityMembershipInvitations(
		communityId: string,
		options?: RequestOptions
	) {
		return http
			.get<CommunityMembershipInvitesResponse>(
				`community/${communityId}/membership-invitation`,
				{
					with: membershipInvitationsWithParams,
					type: 'failed',
					...options?.pagination,
				},
				{
					...(options?.signal ? { signal: options.signal } : {}),
				}
			)
			.then(response => response.data);
	},

	getDeclinedCommunityMembershipInvitations(
		communityId: string,
		options?: RequestOptions
	) {
		return http
			.get<CommunityMembershipInvitesResponse>(
				`community/${communityId}/membership-invitation`,
				{
					with: membershipInvitationsWithParams,
					type: 'declined',
					...options?.pagination,
				},
				{
					...(options?.signal ? { signal: options.signal } : {}),
				}
			)
			.then(response => response.data);
	},

	deleteCommunityMembershipRequest(communityId: string, requestId: string) {
		return http
			.delete<null>(
				`community/${communityId}/membership-request/${requestId}`
			)
			.then(() => null);
	},

	getAnnouncements(
		communityId: string,
		types?: string[],
		{
			pagination,
			signal,
		}: {
			pagination?: PaginationArgs;
			signal?: AbortSignal;
		} = {}
	) {
		return http
			.get<AnnouncementsResponse>(
				`community/${communityId}/announcement`,
				{
					with: announcementWithParams,
					type: types?.join(',') || null,
					order: 'DESC',
					...pagination,
				},
				{
					signal,
				}
			)
			.then(response => response.data);
	},

	getAnnouncement(announcementId: string) {
		return http
			.get<AnnouncementResponse>(`announcement/${announcementId}`, {
				with: announcementWithParams,
			})
			.then(response => response.data);
	},

	postAnnouncement(
		communityId: string,
		{
			publishedDt,
			allowDiscussion,
			visibility,
			visibilityGroups,
			...rest
		}: AnnouncementInput
	) {
		return http
			.post<AnnouncementResponse>(
				`community/${communityId}/announcement`,
				{
					...rest,
					published_dt: publishedDt ?? null,
					allow_discussion: allowDiscussion,
					community_announcement_visibility: visibility,
					community_announcement_custom_groups: visibilityGroups,
					type: 'html',
				},
				{
					params: {
						with: announcementWithParams,
					},
				}
			)
			.then(response => response.data);
	},

	patchAnnouncement(
		announcementId: string,
		{
			publishedDt,
			allowDiscussion,
			visibility,
			visibilityGroups,
			...rest
		}: AnnouncementInput
	) {
		return http
			.patch<AnnouncementResponse>(
				`announcement/${announcementId}`,
				{
					...rest,
					published_dt: publishedDt ?? null,
					allow_discussion: allowDiscussion,
					visibility,
					visibility_groups: visibilityGroups,
				},
				{
					params: {
						with: announcementWithParams,
					},
				}
			)
			.then(response => response.data);
	},

	deleteAnnouncement(announcementId: string) {
		return http
			.delete<null>(`announcement/${announcementId}`)
			.then(() => null);
	},

	postMembershipInvitation(
		communityId: string,
		input: MembershipInvitationInput
	) {
		return http
			.post<MembershipInvitationResponse>(
				`community/${communityId}/membership-invitation`,
				input
			)
			.then(response => response.data);
	},

	/**
	 * Retrieves notes for a community.
	 *
	 * @param {string} communityId
	 * @param {{
	 * 			pagination?: PaginationArgs;
	 * 			signal?: AbortSignal;
	 * 		}}
	 * @return {*}
	 */
	getNotes(communityId: string, options?: RequestOptions) {
		return http
			.get<NotesResponse>(
				`community/${communityId}/note`,
				{
					with: noteWithParams,
					order: 'DESC',
					...options?.pagination,
				},
				{
					...(options?.signal ? { signal: options.signal } : {}),
				}
			)
			.then(response => response.data);
	},

	/**
	 * Retrieves a note for a community.
	 *
	 * @param {string} communityId
	 * @param {string} noteId
	 * @param {RequestOptions} options
	 * @return {*}
	 */
	getNote(noteId: string, options?: RequestOptions) {
		return http
			.get<NoteResponse>(
				`note/${noteId}`,
				{
					with: noteWithParams,
				},
				{
					...(options?.signal ? { signal: options.signal } : {}),
				}
			)
			.then(response => response.data);
	},

	/**
	 * Creates a note for a community.
	 *
	 * @param {string} communityId
	 * @param {NoteInput} { visibilityGroups, editGroups, ...input }
	 * @return {*}
	 */
	postNote(
		communityId: string,
		{ visibilityGroups, editGroups, ...input }: NoteInput
	) {
		return http
			.post<NoteResponse>(
				`community/${communityId}/note`,
				{
					visibility_groups: visibilityGroups,
					edit_groups: editGroups,
					type: 'html',
					...input,
				},
				{
					params: {
						with: noteWithParams,
					},
				}
			)
			.then(response => response.data);
	},

	/**
	 * Updates a note for a community.
	 *
	 * @param {string} noteId
	 * @param {NoteInput} { visibilityGroups, editGroups, edit, ...input }
	 * @return {*}
	 */
	patchNote(
		noteId: string,
		{ visibilityGroups, editGroups, edit, ...input }: NoteInput
	) {
		return http
			.patch<NoteResponse>(
				`note/${noteId}`,
				{
					visibility_groups: visibilityGroups,
					management: edit,
					management_groups: editGroups,
					type: 'html',
					...input,
				},
				{
					params: {
						with: noteWithParams,
					},
				}
			)
			.then(response => response.data);
	},

	/**
	 * Deletes a note for a community.
	 *
	 * @param {string} noteId
	 * @return {*}
	 */
	deleteNote(noteId: string) {
		return http.delete<null>(`note/${noteId}`).then(() => null);
	},

	/**
	 * Retrieves well wishes for a community.
	 *
	 * @param {string} communityId
	 * @param {RequestOptions} [options]
	 * @return {*}
	 */
	getWellWishes(communityId: string, options?: RequestOptions) {
		return http
			.get<WellWishesResponse>(
				`community/${communityId}/well-wish`,
				{
					with: wellWishWithParams,
					order: 'DESC',
					...options?.pagination,
				},
				{
					...(options?.signal ? { signal: options.signal } : {}),
				}
			)
			.then(response => response.data);
	},

	/**
	 * Retrieves a well wish for a community.
	 *
	 * @param {string} wellWishId
	 * @param {RequestOptions} [options]
	 * @return {*}
	 */
	getWellWish(wellWishId: string, options?: RequestOptions) {
		return http
			.get<WellWishResponse>(
				`well-wish/${wellWishId}`,
				{
					with: wellWishWithParams,
				},
				{
					...(options?.signal ? { signal: options.signal } : {}),
				}
			)
			.then(response => response.data);
	},

	/**
	 * Creates a well wish for a community.
	 *
	 * @param {string} communityId
	 * @param {WellWishInput} input
	 * @return {*}
	 */
	postWellWish(communityId: string, input: WellWishInput) {
		return http
			.post<WellWishResponse>(
				`community/${communityId}/well-wish`,
				input,
				{
					params: {
						with: wellWishWithParams,
					},
				}
			)
			.then(response => response.data);
	},
	/**
	 * Updates a well wish
	 *
	 * @param {string} wellWishId
	 * @param {WellWishInput} input
	 * @return {*}
	 */
	patchWellWish(wellWishId: string, input: WellWishInput) {
		return http
			.patch<WellWishResponse>(`well-wish/${wellWishId}`, input, {
				params: {
					with: wellWishWithParams,
				},
			})
			.then(response => response.data);
	},

	/**
	 * Deletes a well wish
	 *
	 * @param {string} wellWishId
	 * @return {*}
	 */
	deleteWellWish(wellWishId: string) {
		return http.delete<null>(`well-wish/${wellWishId}`).then(() => null);
	},

	getAlbum(communityId: string, albumId: string) {
		return http
			.get<AlbumResponse>(`community/${communityId}/album/${albumId}`, {
				with: albumWithParams,
			})
			.then(response => response.data);
	},

	getAlbums(communityId: string) {
		return http
			.get<AlbumsResponse>(`community/${communityId}/album`, {
				with: 'cover-photo',
				limit: -1,
			})
			.then(response => response.data);
	},

	getCommunityPhotos(
		communityId: string,
		{
			pagination,
			signal,
		}: {
			pagination?: PaginationArgs;
			signal?: AbortSignal;
		} = {}
	) {
		return http
			.get<AlbumPhotosResponse>(
				`community/${communityId}/photo`,
				{
					...pagination,
					order: 'DESC',
					with: photoWithParams,
				},
				{
					signal,
				}
			)
			.then(response => response.data);
	},

	getPhoto(albumId: string, photoId: string, options?: RequestOptions) {
		return http
			.get<AlbumPhotoResponse>(
				`album/${albumId}/photo/${photoId}`,
				{
					with: photoWithParams,
				},
				{
					...(options?.signal ? { signal: options.signal } : {}),
				}
			)
			.then(response => response.data);
	},

	patchPhoto(
		albumId: string,
		photoId: string,
		{ name, sortOrder }: { name: string; sortOrder: number }
	) {
		return http
			.patch<AlbumPhotoResponse>(
				`album/${albumId}/photo/${photoId}`,
				{
					name,
					sort_order: sortOrder,
				},
				{
					params: {
						with: photoWithParams,
					},
				}
			)
			.then(response => response.data);
	},

	postCommunityAlbumPhoto(communityId: string, photoId: string) {
		return http
			.post<PostAlbumPhotoResponse>(
				`community/${communityId}/photo`,
				{
					images: [photoId],
				},
				{
					params: {
						with: photoWithParams,
					},
				}
			)
			.then(response => response.data);
	},

	postAlbum(
		communityId: string,
		{ visibilityGroups, uploadGroups, ...input }: AlbumInput
	) {
		return http
			.post<AlbumResponse>(
				`community/${communityId}/album`,
				{
					visibility_groups: visibilityGroups,
					upload_groups: uploadGroups,
					...input,
				},
				{
					params: {
						with: albumWithParams,
					},
				}
			)
			.then(response => response.data);
	},

	getCommunityToken<T>(
		communityId: string,
		tokenId: string,
		type: string,
		withOptions = {}
	) {
		return http
			.get<T>(`community/${communityId}/token/${tokenId}?type=${type}`, {
				with: withOptions,
			})
			.then(response => response.data);
	},

	getInvitationToken(communityId: string, tokenId: string) {
		return this.getCommunityToken<InvitationTokenResponse>(
			communityId,
			tokenId,
			'invitation'
		);
	},

	getTransferRequestToken(communityId: string, tokenId: string) {
		return this.getCommunityToken<TransferRequestTokenResponse>(
			communityId,
			tokenId,
			'community_transfer',
			identityWithParams
		);
	},

	getAutoLoginToken(communityId: string, tokenId: string) {
		return this.getCommunityToken<AutoLoginTokenResponse>(
			communityId,
			tokenId,
			'auto_login',
			identityWithParams
		);
	},

	getInvitationTokenStatus(communityId: string, tokenId: string) {
		return http
			.get<InvitationTokenStatusResponse>(
				`community/${communityId}/token-status/${tokenId}`
			)
			.then(response => response.data);
	},

	/**
	 * Gets community groups.
	 *
	 * @param {string} communityId
	 * @param {RequestOptions} [options]
	 * @return {*}
	 */
	getGroups(communityId: string, options?: RequestOptions) {
		return http
			.get<GroupsResponse>(
				`community/${communityId}/group`,
				{
					with: groupWithParams,
					...options?.pagination,
				},
				{
					...(options?.signal ? { signal: options.signal } : {}),
				}
			)
			.then(response => response.data);
	},

	/**
	 * Gets community group.
	 *
	 * @param {string} communityId
	 * @param {string} groupId
	 * @return {*}
	 */
	getGroup(communityId: string, groupId: string) {
		return http
			.get<GroupResponse>(`community/${communityId}/group/${groupId}`, {
				with: groupWithParams,
			})
			.then(response => response.data);
	},

	/**
	 * Creates a community group.
	 *
	 * @param {string} communityId
	 * @param {GroupInput} input
	 * @return {*}
	 */
	postGroup(
		communityId: string,
		{
			visibilityGroups,
			managementGroups,
			openEnrollment,
			...input
		}: GroupInput
	) {
		return http
			.post<GroupResponse>(
				`community/${communityId}/group`,
				{
					...input,
					open_enrollment: openEnrollment,
					visibility_groups: visibilityGroups,
					management_groups: managementGroups,
				},
				{
					params: {
						with: groupWithParams,
					},
				}
			)
			.then(response => response.data);
	},

	/**
	 * Updates a community group.
	 *
	 * @param {string} communityId
	 * @param {string} groupId
	 * @param {GroupInput} input
	 * @return {*}
	 */
	putGroup(
		communityId: string,
		groupId: string,
		{
			visibilityGroups,
			managementGroups,
			openEnrollment,
			...input
		}: GroupInput
	) {
		return http
			.put<GroupResponse>(
				`community/${communityId}/group/${groupId}`,
				{
					...input,
					open_enrollment: openEnrollment,
					visibility_groups: visibilityGroups,
					management_groups: managementGroups,
				},
				{
					params: {
						with: groupWithParams,
					},
				}
			)
			.then(response => response.data);
	},

	/**
	 * Updates a community group partially.
	 *
	 * @param {string} communityId
	 * @param {string} groupId
	 * @param {(PatchGroupInput} input
	 * @return {*}
	 */
	patchGroup(communityId: string, groupId: string, input: PatchGroupInput) {
		const { type } = input;

		return http
			.patch<GroupResponse>(
				`community/${communityId}/group/${groupId}`,
				{
					type,
					...(type === 'visibility' && {
						visibility: input.visibility,
						visibilityGroups: input.visibilityGroups,
					}),
					...(type === 'members' && {
						members: input.members,
					}),
					...(type === 'name' && {
						name: input.name,
						description: input.description,
					}),
					...(type === 'permissions' && {
						permissions: input.permissions,
					}),
				},
				{
					params: {
						with: groupWithParams,
					},
				}
			)
			.then(response => response.data);
	},

	patchOpenGroup(
		communityId: string,
		groupId: string,
		identityId: string,
		add: boolean
	) {
		return http
			.put<GroupResponse>(
				`community/${communityId}/identity/${identityId}/group`,
				{
					group: groupId,
					add,
				},
				{
					params: {
						with: groupWithParams,
					},
				}
			)
			.then(response => response.data);
	},

	/**
	 * Deletes a group.
	 *
	 * @param {string} communityId
	 * @param {string} groupId
	 * @return {*}
	 */
	deleteGroup(communityId: string, groupId: string) {
		return http
			.delete<null>(`community/${communityId}/group/${groupId}`)
			.then(() => null);
	},

	/**
	 * Initiates a transfer ownership for a community.
	 *
	 * @param {string} communityId
	 * @param {string} identityId
	 * @return {*}
	 */
	initiateTransferOwnership(communityId: string, identityId: string) {
		return http
			.post(
				`community/${communityId}/transfer-request`,
				{ new_owner_id: identityId },
				{
					params: {},
				}
			)
			.then(() => null);
	},

	/**
	 * Get all pending transfer requests for a community.
	 *
	 * @param {string} communityId
	 * @return {*}
	 */
	getTransferOwnershipRequests(communityId: string) {
		return http
			.get<TransferOwnershipRequestsResponse>(
				`community/${communityId}/transfer-request`
			)
			.then(response => response.data);
	},

	/**
	 * Removes a pending transfer request for a community.
	 *
	 * @param {string} communityId
	 * @return {*}
	 */
	deleteTransferOwnershipRequest(communityId: string) {
		return http
			.delete(`community/${communityId}/transfer-request`)
			.then(() => null);
	},

	/**
	 * Accepts/Rejects a pending transfer request for a community.
	 *
	 * @param {string} communityId
	 * @param {string} transferRequestId
	 * @param {('accept' | 'reject')} response
	 * @return {*}
	 */
	patchTransferOwnershipRequest(
		communityId: string,
		transferRequestId: string,
		response: 'accept' | 'reject'
	) {
		return http
			.patch<null>(
				`community/${communityId}/transfer-request/${transferRequestId}`,
				{
					response,
				}
			)
			.then(() => null);
	},
});
