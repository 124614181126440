import { DialogHookOptions, useDialog } from 'components/common/dialog';
import {
	DeleteAnnouncementMutationInput,
	useDeleteAnnouncement,
} from 'core/uses-cases/announcement';
import { useServices, useTranslation } from 'hooks';

export const useDeleteAnnouncementHandler = (
	options?: DialogHookOptions<DeleteAnnouncementMutationInput>
) => {
	const { t } = useTranslation();
	const { notification } = useServices();
	const { mutateAsync } = useDeleteAnnouncement();

	const { open } = useDialog<DeleteAnnouncementMutationInput>({
		title: t('announcements.delete-confirm-title'),
		content: t('announcements.delete-confirm-content'),
		confirmText: t('common.delete'),
		onConfirm: input => {
			if (!input) return;

			mutateAsync(input).then(() => {
				notification.show(
					'success',
					t('announcements.announcement-was-successfully-deleted')
				);
			});

			options?.onConfirm?.(input);
		},
		onCancel: options?.onCancel,
		PaperProps: {
			id: 'announcement-delete-dialog',
		},
	});

	return open;
};
