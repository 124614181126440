import { useMutation, useQuery } from 'react-query';
import { checkIdentityEmailMutationKey } from 'core/utils/mutation-key-factory';
import { useServices } from 'hooks';
import { authenticationKeys } from 'core/utils/query-key-factory';
import * as Yup from 'yup';
import { BaseHttpError } from '../../services/api/errors';

export interface CheckIdentityEmailMutationInput {
	email: string;
}

export const useCheckIdentityEmail = () => {
	const { api } = useServices();

	return useMutation<boolean, BaseHttpError, CheckIdentityEmailMutationInput>(
		({ email }) => {
			return api
				.checkEmail(email)
				.then(response => response.data.has_account);
		},
		{
			mutationKey: checkIdentityEmailMutationKey,
		}
	);
};

export const useGetCheckIdentityEmail = (email: string) => {
	const { api } = useServices();

	return useQuery(
		authenticationKeys.checkEmail(email),
		() => {
			if (!Yup.string().email().isValidSync(email)) {
				return Promise.resolve(false);
			}

			return api
				.checkEmail(email)
				.then(response => response.data.has_account);
		},
		{
			enabled: !!email,
			staleTime: 0,
			notifyOnChangeProps: 'tracked',
			refetchOnMount: true,
		}
	);
};
