import { SxProps } from '@mui/system';
import { Theme } from 'theme';

export const weekHeaderContainerStyle: SxProps<Theme> = theme => ({
	borderBottom: '1px solid',
	borderBottomColor: theme.palette.grey[300],

	'& p': {
		fontWeight: 400,
		padding: theme.spacing(1),
	},
});

export const weekContainerStyle: SxProps<Theme> = theme => ({
	borderBottom: '1px dotted',
	borderBottomColor: theme.palette.grey[300],

	'&:last-child': {
		borderBottomColor: 'transparent',
	},

	'> *': {
		borderRight: '1px dotted',
		borderRightColor: theme.palette.grey[300],

		'&:last-child': {
			borderRightColor: 'transparent',
		},
	},
});

export const monthDayStyle: SxProps<Theme> = theme => ({
	minHeight: 120,
	maxHeight: 120,
	overflow: 'hidden',
	paddingY: theme.spacing(1),
	paddingLeft: theme.spacing(2),
	paddingRight: theme.spacing(1),

	'&.from-other-month p': {
		opacity: 0.54,
	},

	'&.is-today, &.is-selected': {
		backgroundColor: theme.secondaryColors.secondary5,
	},
});
