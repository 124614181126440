import {
	COMMUNITY_SETUP_ANNOUNCEMENT,
	COMMUNITY_SETUP_COMPLETED,
	COMMUNITY_SETUP_MEMBERS,
	COMMUNITY_SETUP_PROFILE,
} from 'core/constants';
import { Community, Nullable } from 'core/types';
import { useRouter, useVerifyAccess } from 'hooks';
import { matchPath } from 'react-router-dom';

export const useHandleCommunitySetupConstraint = (
	community: Nullable<Community>
) => {
	const router = useRouter();
	const verify = useVerifyAccess();

	if (!community) return null;

	if (!verify('update', 'community')) {
		return null;
	}

	const mapSetupCodesWithPathKeys: Record<number, string> = {
		[COMMUNITY_SETUP_PROFILE]: 'community.setup-profile',
		[COMMUNITY_SETUP_MEMBERS]: 'community.setup-members',
		[COMMUNITY_SETUP_ANNOUNCEMENT]: 'community.setup-announcement',
	};

	const currentSetupPageKey = Object.values(mapSetupCodesWithPathKeys).find(
		key => {
			return matchPath(
				{ path: router.getPathFor(key), end: true },
				window.location.pathname
			);
		}
	);

	const matchedKey = mapSetupCodesWithPathKeys[community.setup_status];

	// Checks if the community is still in setup phase
	if (matchedKey) {
		// Checks if the current page matches with the expected setup page
		if (currentSetupPageKey !== matchedKey) {
			return router.getPathFor(matchedKey, {
				communityId: community.slug,
			});
		}

		// Keeps in the setup page
		return null;
	} else if (community?.setup_status === COMMUNITY_SETUP_COMPLETED) {
		// Redirect to the community home,if being in a setup page
		if (currentSetupPageKey) {
			return router.getPathFor('community.home', {
				communityId: community.slug,
			});
		}

		return null;
	}

	// Invalid setup status,
	// @TODO, fix redirect
	return router.getPathFor('community.switch');
};
