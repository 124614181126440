import { usePrevious, useLocation } from 'hooks';
import { useEffect } from 'react';

export const DebugRouter = () => {
	const location = useLocation();

	const prevLocation = usePrevious(location);

	useEffect(() => {
		console.group('%cReact Router Debugger', 'color:deeppink');
		console.log('Route Changed', 'color:blue');
		console.log({ from: prevLocation?.pathname, to: location.pathname });
		console.groupEnd();
	}, [location]);

	return null;
};
