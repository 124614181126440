import { useServices } from 'core/services';
import { Identity } from 'core/types';
import { authenticationKeys } from 'core/utils/query-key-factory';
import { useMutation } from 'react-query';
import {
	ENGLISH_CODE,
	LANGUAGE_PREFERENCE_KEY,
	LAST_LOGIN_DT_KEY,
} from 'core/constants';
import { changeLanguage } from 'utils/lang';

type LoginMutationInput = {
	username: string;
	password: string;
};

export const useLogin = () => {
	const { api, cookie } = useServices();

	const setCurrentIdentity = useSetCurrentIdentity();

	const {
		isLoading,
		isError,
		isSuccess,
		error,
		mutate: doLogin,
		mutateAsync: doLoginAsync,
	} = useMutation(
		({ username, password }: LoginMutationInput) => {
			const languageCookie =
				cookie.get(LANGUAGE_PREFERENCE_KEY) ?? ENGLISH_CODE;
			changeLanguage(languageCookie);

			return api.postAccessToken(username, password, languageCookie);
		},
		{
			onSuccess: data => {
				const {
					data: { uuid },
				} = data.data;

				setCurrentIdentity(uuid, data.data.identity);
			},
		}
	);

	return {
		doLogin,
		doLoginAsync,
		isSuccess,
		isLoading,
		isError,
		error,
	};
};

export const useSetCurrentIdentity = () => {
	const { authentication, queryClient, errorLogger, cookie } = useServices();

	return (uuid: string, identity: Identity, invalidateQuery = false) => {
		authentication.setAccessToken(uuid);

		queryClient.setQueryData<Identity>(
			authenticationKeys.identity,
			identity
		);

		errorLogger.setUser(identity);

		// Set last login time
		const expires = new Date();
		expires.setFullYear(expires.getFullYear() + 10);
		cookie.set(LAST_LOGIN_DT_KEY, new Date().toISOString(), { expires });

		if (invalidateQuery) {
			queryClient.invalidateQueries(authenticationKeys.identity);
		}
	};
};
