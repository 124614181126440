import { Button } from 'components';
import { useServices, useTranslation } from 'hooks';
import { SxProps } from '@mui/system';
import GoogleContacts, {
	GoogleContactsResponse,
	GoogleContactsResponseOffline,
} from 'react-google-contacts';
import { useImportGmailEmails } from 'components/community/hooks';
import config from 'config';
import { useState } from 'react';

export const ImportGmailEmailsButton = ({
	onSubmit,
	sx,
}: {
	onSubmit: (contacts: string[]) => void;
	sx?: SxProps;
}) => {
	const { t } = useTranslation();
	const importEmails = useImportGmailEmails(onSubmit);
	const [disable, setDisable] = useState(false);
	const { notification } = useServices();

	const onSuccess = (
		response: GoogleContactsResponse | GoogleContactsResponseOffline
	) => {
		importEmails(
			(response as unknown as { email: string; title: string }[]).map(
				contact => ({
					uuid: contact.email,
					name: contact.title,
					email: contact.email,
				})
			)
		);
		setDisable(false);
	};

	const onFailure = ({ error }: { error: string }) => {
		if (error !== 'popup_closed_by_user') {
			notification.show('error', t('community-form.could-not-import'));
		}

		setDisable(false);
	};

	if (!config.gmail.clientId) {
		return null;
	}

	return (
		<GoogleContacts
			clientId={config.gmail.clientId}
			render={renderProps => (
				<Button
					sx={sx}
					variant='outlined'
					onClick={() => {
						setDisable(true);
						renderProps?.onClick();
					}}
					disabled={disable}
				>
					{t('community-form.gmail')}
				</Button>
			)}
			onSuccess={onSuccess}
			onFailure={onFailure}
		/>
	);
};
