import React, {
	createContext,
	useContext,
	ContextType,
	useCallback,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { RouterBuilder } from './router-builder';

const RouterContext = createContext<RouterBuilder>({} as RouterBuilder);

export interface RouterContextReturnValue
	extends ContextType<typeof RouterContext> {
	navigate: (
		path: string,
		params?: Record<string, string>,
		queryParams?: Record<string, string | boolean>,
		options?: { replace?: boolean; state?: unknown }
	) => void;
}

export const useRouter = (): RouterContextReturnValue => {
	const router = useContext(RouterContext);

	if (!router) {
		throw new Error('useRouter must be used within a RouterProvider');
	}

	const navigateTo = useNavigate();

	const navigate = useCallback(
		(
			path,
			params,
			queryParams = {},
			options: { replace?: boolean; state?: unknown } = {
				replace: false,
			}
		) => {
			navigateTo(router.getPathFor(path, params, queryParams), options);
		},
		[]
	);

	return Object.assign(router, { navigate });
};

export const RouterProvider: React.FC<{ router: RouterBuilder }> = ({
	router,
	...rest
}) => {
	return <RouterContext.Provider value={router} {...rest} />;
};
