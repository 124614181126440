import i18n from 'i18next';
import { Settings } from 'luxon';
import { transformServerToClientLocal } from './dates';

export function updateLocaleForLuxon(language: string) {
	const locale = transformServerToClientLocal(language);
	Settings.defaultLocale = locale;
}

export function changeLanguage(language: string) {
	const fixedLang = language.slice(0, 2);

	if (i18n.language !== fixedLang) {
		i18n.changeLanguage(fixedLang);
	}

	updateLocaleForLuxon(fixedLang);
}

export function getCurrentLanguage() {
	return i18n.language;
}
