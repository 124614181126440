import {
	Link,
	Typography,
	FormControlLabel,
	Radio,
	RadioGroup,
	LoadingButton,
	FancyTable,
} from 'components/common';
import { useSafeState, useTranslation } from 'hooks';
import { useState } from 'react';
import { Nullable, Identity, CommunityMember } from 'core/types';
import { formatDate } from 'utils/dates';

export type TransferableMembersProps = {
	members: CommunityMember[];
	onInitiateTransfer: (identity: Identity) => Promise<void>;
};

export const TransferableMembers = ({
	members,
	onInitiateTransfer,
}: TransferableMembersProps) => {
	const { t } = useTranslation();

	const [selected, setSelected] = useState<Nullable<string>>(null);
	const [isSubmitting, setIsSubmitting] = useSafeState(false);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSelected((event.target as HTMLInputElement).value);
	};

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		if (!selected) return;

		setIsSubmitting(true);

		const member = members?.find(
			member => member.identity.uuid === selected
		);

		if (!member) return;

		onInitiateTransfer(member.identity).catch(() => {
			setIsSubmitting(false);
		});
	};

	return (
		<form onSubmit={handleSubmit} noValidate>
			<FancyTable
				renderColumns={{
					2: { xs: false },
					3: { xs: false, sm: false },
				}}
			>
				<FancyTable.Header>
					<FancyTable.Header.Cell
						title={t('transfer-ownership.name')}
					/>
					<FancyTable.Header.Cell
						title={t('transfer-ownership.email')}
					/>
					<FancyTable.Header.Cell
						title={t('transfer-ownership.date-joined')}
					/>
				</FancyTable.Header>
				<RadioGroup
					aria-labelledby='demo-controlled-radio-buttons-group'
					name='controlled-radio-buttons-group'
					value={selected}
					onChange={handleChange}
				>
					{members?.map(({ identity, joined_dt }) => {
						const fullName = `${identity.first_name} ${identity.last_name}`;

						return (
							<FancyTable.Row
								key={`member-transfer-${identity.uuid}`}
							>
								<FancyTable.Row.Cell py={1}>
									<FormControlLabel
										value={identity.uuid}
										control={<Radio />}
										label={
											<Typography
												variant='button2'
												ml={2}
											>
												{fullName}
											</Typography>
										}
									/>
								</FancyTable.Row.Cell>
								<FancyTable.Row.Cell>
									<Typography variant='body2'>
										<Link href={`mailto:${identity.email}`}>
											{identity.email}
										</Link>
									</Typography>
								</FancyTable.Row.Cell>
								<FancyTable.Row.Cell>
									{formatDate(joined_dt, 'M/d/yy')}
								</FancyTable.Row.Cell>
							</FancyTable.Row>
						);
					})}
				</RadioGroup>
				<LoadingButton
					type='submit'
					size='small'
					disabled={!selected}
					loading={isSubmitting}
					loadingIndicator={t(
						'transfer-ownership.initiating-transfer-request'
					)}
				>
					{t('transfer-ownership.initiate-transfer-request')}
				</LoadingButton>
			</FancyTable>
		</form>
	);
};
