import { SetupMutationFn } from 'core/configure/types';
import { BaseHttpError } from 'core/services/api/errors';
import { Note } from 'core/types';
import { communityKeys, noteKeys } from 'core/utils/query-key-factory';
import { isCancelledError, useMutation } from 'react-query';
import { deleteNoteMutationKey } from 'core/utils/mutation-key-factory';
import {
	cancelPreviousMutation,
	findRecordFromInfinityQueryCache,
	onDeleteMutateOptimisticInfinityQueryCache,
	PaginatedRecordsSnapshot,
	removeRecordFromInfinityQueryCache,
} from 'core/utils/optimistic-utils';
import { isPostPatchNoteMutation } from './use-patch-note';

export interface DeleteNoteMutationInput {
	communityId: string;
	noteId: string;
}

export const useDeleteNote = () => {
	const mutation = useMutation<Note, BaseHttpError, DeleteNoteMutationInput>(
		deleteNoteMutationKey
	);

	return mutation;
};

type MutationContext = {
	communityId: string;
	noteId: string;
	currentNoteInfo: {
		listSnapshot: PaginatedRecordsSnapshot<Note>;
	} | null;
};

export const setupDeleteNote: SetupMutationFn = (
	services,
	createTrackedParallelMutation,
	mutationTracker
) => {
	const { queryClient, api } = services;

	const mutation = createTrackedParallelMutation<
		null,
		BaseHttpError,
		DeleteNoteMutationInput,
		MutationContext
	>({
		mutationFn: ({ noteId }) => {
			// Means it is real note
			if (noteId.length === 22) {
				return api.deleteNote(noteId);
			}
			return Promise.resolve(null);
		},
		onMutate: async input => {
			const { noteId, communityId } = input;

			await cancelPreviousMutation<MutationContext>(
				queryClient,
				mutation => {
					return (
						isPostPatchNoteMutation(mutation) &&
						mutation.state.context?.noteId === noteId
					);
				}
			);

			const currentNoteInfo = findRecordFromInfinityQueryCache<Note>(
				queryClient,
				noteKeys.list(communityId),
				noteId
			);

			if (currentNoteInfo) {
				await onDeleteMutateOptimisticInfinityQueryCache<Note>(
					queryClient,
					noteKeys.list(communityId),
					noteKeys.detail(communityId, noteId),
					noteId
				);
			}

			removeRecordFromInfinityQueryCache(
				queryClient,
				communityKeys.feed(communityId),
				noteId
			);

			return {
				communityId,
				noteId,
				currentNoteInfo,
			};
		},
		onError: (error, input, context) => {
			if (context?.currentNoteInfo && !isCancelledError(error)) {
				queryClient.setQueryData(
					noteKeys.list(context.communityId),
					context?.currentNoteInfo.listSnapshot
				);
			}
		},
		onSettled: (data, error, { noteId }, context) => {
			if (context && !isCancelledError(error)) {
				if (noteId.length === 22) {
					// Only trigger the refetch when it is a real resource
					mutationTracker.queueInvalidations(
						noteKeys.list(context.communityId),
						communityKeys.feed(context.communityId)
					);
				}
			}
		},
		retry: 1,
	});

	queryClient.setMutationDefaults(deleteNoteMutationKey, mutation);
};
