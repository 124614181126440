import { SxProps } from '@mui/system';
import { Theme } from 'theme';

export const stackStyle: SxProps = {
	width: {
		md: 8 / 12,
		sm: 1,
	},
	'> div, > dl': {
		alignSelf: 'stretch',

		'& dt': {
			marginBottom: 1,
		},
	},
};

export const tabsStyle: SxProps<Theme> = theme => ({
	'& .MuiTabs-flexContainer': {
		borderWidth: 1,
		borderStyle: 'solid',
		borderColor: theme.palette.common.black,
		borderRadius: '3px',
		overflow: 'hidden',
	},
	'& .MuiTabs-indicator': {
		display: 'flex',
		justifyContent: 'center',
		backgroundColor: 'transparent',
	},
	'& .MuiTabs-indicatorSpan': {
		display: 'none',
	},
});

export const tabStyle: SxProps<Theme> = theme => ({
	minWidth: 160,
	padding: theme.spacing(1.3),
	fontSize: 14,
	color: theme.primaryColors.primary6,
	textTransform: 'none',
	borderRightWidth: 1,
	borderRightStyle: 'solid',
	borderColor: theme.palette.common.black,

	'&:last-of-type': {
		borderRightWidth: 0,
	},

	'&.Mui-selected': {
		backgroundColor: theme.primaryColors.primary4,
		color: theme.palette.common.white,
	},

	'&.Mui-focusVisible': {
		backgroundColor: 'transparent',
	},
});

export const shareLinkInput: SxProps<Theme> = theme => ({
	marginRight: theme.spacing(1),
	border: 'none',

	'& input': {
		backgroundColor: theme.secondaryColors.secondary6,
		padding: theme.spacing(1.5, 2),
		fontWeight: 'bold',
		fontSize: '12px',
		borderRadius: '3px',
	},

	'& .MuiOutlinedInput-notchedOutline': {
		display: 'none',
	},
});
