import { CircularProgress } from 'components/common';
import { Community, CommunityPermissions, Nullable } from 'core/types';
import { useCurrentIdentity } from 'core/uses-cases/authentication';
import { useGetCommunityPermissions } from 'core/uses-cases/community';
import { useRouter } from 'hooks';
import { Navigate } from 'navigation';
import { createContext, useContext } from 'react';

export const PermissionContext =
	createContext<Nullable<CommunityPermissions>>(null);

export const PermissionProvider: React.FC<{
	community?: Community;
	enabled: boolean;
}> = ({ community, enabled, children }) => {
	const identity = useCurrentIdentity();
	const router = useRouter();

	const { data, isError, isLoading } = useGetCommunityPermissions(
		community?.slug ?? '',
		identity?.uuid ?? '',
		{ enabled }
	);

	if (isLoading) {
		return <CircularProgress />;
	}

	if (isError) {
		return <Navigate to={router.getPathFor('root')} replace />;
	}

	return (
		<PermissionContext.Provider value={data ?? null}>
			{children}
		</PermissionContext.Provider>
	);
};

export const useCurrentCommunityPermissions = () => {
	const communityPermissions = useContext(PermissionContext);

	if (communityPermissions === undefined) {
		throw new Error(
			'useCurrentCommunityPermissions must be used within PermissionContext.Provider'
		);
	}

	return communityPermissions;
};
