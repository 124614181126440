import { photoCardConfigs } from 'components/photos';
import { SetupMutationFn } from 'core/configure/types';
import { BaseHttpError } from 'core/services/api/errors';
import { Services } from 'core/services/types';
import { AlbumPhoto, Nullable, Image as ImageType } from 'core/types';
import {
	postCommunityPhotoMutationKey,
	patchCommunityPhotoMutationKey,
} from 'core/utils/mutation-key-factory';
import {
	onPostMutateOptimisticInfinityQueryCache,
	onSuccessOptimisticInInfinityQueryCache,
} from 'core/utils/optimistic-utils';
import { albumPhotoKeys, communityKeys } from 'core/utils/query-key-factory';
import { isCancelledError, useMutation, Mutation } from 'react-query';
import { cacheImage, createImageServerUrl } from 'utils/url';
import { v4 as uuid } from 'uuid';

export interface PostCommunityPhotoMutationInput {
	communityId: string;
	image: Nullable<ImageType>;
}

export const usePostCommunityPhoto = () => {
	const mutation = useMutation<
		AlbumPhoto,
		BaseHttpError,
		PostCommunityPhotoMutationInput
	>(postCommunityPhotoMutationKey);

	return mutation;
};

type Context = {
	communityId: string;
	photoId: string;
};

export const setupPostCommunityPhoto: SetupMutationFn = (
	services: Services,
	createTrackedParallelMutation,
	mutationTracker
) => {
	const { queryClient, api, uploader } = services;

	const mutation = createTrackedParallelMutation<
		AlbumPhoto,
		BaseHttpError,
		PostCommunityPhotoMutationInput,
		Context
	>({
		mutationFn: async ({ image, communityId }) => {
			const remoteImage = await uploader.uploadImage(image);
			return api
				.postCommunityAlbumPhoto(communityId, remoteImage?.uuid ?? '')
				.then(response => response.data[0]);
		},
		onMutate: async ({ communityId, image }) => {
			const albumPhotoId = uuid();

			const newAlbumPhoto: AlbumPhoto = {
				uuid: albumPhotoId,
				album_uuid: '',
				created_dt: new Date().toISOString(),
				discussion_uuid: uuid(),
				image: image as ImageType,
				sort_order: 0,
				community_uuid: '',
				discussion_stats: {
					comments: 0,
				},
				source_entity: null,
				source_uuid: null,
				owner: null,
				can_delete: false,
				identity_permissions: [],
			};

			await onPostMutateOptimisticInfinityQueryCache<AlbumPhoto>(
				queryClient,
				albumPhotoKeys.list(communityId),
				albumPhotoKeys.detail(communityId, albumPhotoId),
				newAlbumPhoto
			);

			return {
				communityId,
				photoId: albumPhotoId,
			};
		},
		onSuccess: async (result, { communityId }, context) => {
			if (context) {
				const { photoId } = context;

				await cacheImage(result.image.url, photoCardConfigs);

				onSuccessOptimisticInInfinityQueryCache(
					queryClient,
					albumPhotoKeys.list(communityId),
					albumPhotoKeys.detail(communityId, photoId),
					photoId,
					result
				);
			}
		},
		onSettled: (_data, error, variables, context) => {
			if (context && !isCancelledError(error)) {
				mutationTracker.queueInvalidations(
					albumPhotoKeys.list(context.communityId),
					albumPhotoKeys.detail(
						variables.communityId,
						context.photoId
					),
					communityKeys.feed(context.communityId)
				);
			}
		},
		retry: 1,
	});

	queryClient.setMutationDefaults(postCommunityPhotoMutationKey, mutation);
};

export const isPostPatchPhotoMutation = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	mutation: Mutation<any, any, any, any>
) =>
	[patchCommunityPhotoMutationKey, postCommunityPhotoMutationKey].includes(
		String(mutation?.options?.mutationKey)
	);
