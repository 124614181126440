import { Box, Tooltip, Paper, RouterLink, Link } from 'components/common';
import envelopIcon from 'assets/images/green-envelop.svg?url';
import linkIcon from 'assets/images/link.svg?url';
import {
	useCopyLink,
	useCurrentCommunity,
	useRouter,
	useTranslation,
	useVerifyAccess,
} from 'hooks';

export const EventShareCard = () => {
	const community = useCurrentCommunity();
	const { t } = useTranslation();
	const router = useRouter();
	const verify = useVerifyAccess();

	const { url, isCopied, handleOnCopy } = useCopyLink();

	const canInviteMembers = verify('create', 'member');

	return (
		<Box component={Paper} elevation={0} px={0} py={1}>
			{canInviteMembers ? (
				<RouterLink
					variant='button'
					to={router.getPathFor('community.invite', {
						communityId: community?.slug,
					})}
				>
					<Box component='img' src={envelopIcon} />{' '}
					{t('event.add-members')}
				</RouterLink>
			) : (
				<Link
					href={t('community-home.mail-to-link', {
						joinUrl: url,
						communityName: community?.name,
					})}
					variant='button'
					target='_blank'
				>
					<Box
						component='img'
						src={envelopIcon}
						alt={t('community-home.add-members')}
					/>{' '}
					{t('community-home.add-members')}
				</Link>
			)}
			<Tooltip
				open={isCopied}
				title={<>{t('event.was-copies', { url })}</>}
				placement='top'
			>
				<Link variant='button' onClick={handleOnCopy}>
					<Box component='img' src={linkIcon} />{' '}
					{t('event.link-of-chain')}
				</Link>
			</Tooltip>
		</Box>
	);
};
