import { useCallback } from 'react';
import { Box, Heading, CircularProgress } from 'components/common';
import { useParams, useTranslation, useRouter, useErrorHandler } from 'hooks';
import { AnnouncementCard } from 'components/announcements';
import { useGetAnnouncement } from 'core/uses-cases/announcement';
import { useDeleteAnnouncementHandler } from 'components/announcements/hooks';
import { CommentsWidget } from 'components/discussion';

export const ViewAnnouncement = () => {
	const { t } = useTranslation();

	const { communityId, announcementId } = useParams() as {
		communityId: string;
		announcementId: string;
	};

	const router = useRouter();

	const onDeleteHandler = useDeleteAnnouncementHandler({
		onConfirm: () =>
			router.navigate('community.announcements', {
				communityId,
			}),
	});

	const { data, isLoading, isSuccess, error } = useGetAnnouncement(
		communityId as string,
		announcementId as string
	);

	const handleOnDelete = useCallback(() => {
		onDeleteHandler({ communityId, announcementId });
	}, [communityId, announcementId]);

	const handleOnEdit = useCallback(() => {
		router.navigate('announcement.edit', {
			communityId,
			announcementId,
		});
	}, [communityId, announcementId]);

	useErrorHandler(error, {
		redirectTo: router.getPathFor('community.announcements', {
			communityId,
		}),
	});

	return (
		<Box>
			{isLoading && (
				<Box display='flex' justifyContent='center' mt={4}>
					<CircularProgress message={t('common.loading')} />
				</Box>
			)}
			{isSuccess && data && (
				<>
					<Box display='flex' mb={4}>
						<Heading variant='h4'>{data.title}</Heading>
					</Box>
					<AnnouncementCard
						hideTitle
						announcement={data}
						onEdit={handleOnEdit}
						onDelete={handleOnDelete}
					/>
					<CommentsWidget
						discussionId={data.discussion_uuid}
						enabled={
							!!data.created_dt && // Offline created announcements don't have a discussion
							data.discussion_state === 'enabled'
						}
					/>
				</>
			)}
		</Box>
	);
};
