import { AssetUploader } from 'core/services/types';
import { Image, Asset, Nullable } from 'core/types';
import { ApiClient } from '../api';

export const createUploader = (api: ApiClient): AssetUploader => {
	return {
		uploadImage: (image: Nullable<Image>, throwError = true) => {
			if (image?.uuid) return Promise.resolve(image);

			if (!image?.file) return Promise.resolve(null);

			return api
				.postImage(image.name, image.file)
				.then(response => response.data);
		},
		uploadAsset: (asset: Nullable<Asset>) => {
			if (asset?.uuid) return Promise.resolve(asset);

			if (!asset?.file) return Promise.resolve(null);

			return api
				.postAttachment(asset.name, asset.file)
				.then(response => response.data[0]);
		},
	};
};
