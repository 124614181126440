import { useCurrentCommunity } from 'components';
import { Community } from 'core/types';
import { useRouter, useServices } from 'hooks';
import { Navigate, Outlet } from 'react-router-dom';

export const AvailableSectionGuard = (props: { sectionKey: string }) => {
	const { sectionKey } = props;
	const { notification, i18n: t } = useServices();
	const router = useRouter();
	const community = useCurrentCommunity();

	if (community && !community[sectionKey as keyof Community]) {
		const key = sectionKey.replaceAll('_', '-');

		notification.show(
			'error',
			t(`community-sections.${key}-are-disabled-for-this-community`)
		);

		return (
			<Navigate
				to={router.getPathFor('community.home', {
					communityId: community?.slug,
				})}
				replace
			/>
		);
	}

	return <Outlet />;
};
