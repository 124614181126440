import { useCurrentCommunity, Typography, RouterLink } from 'components';
import { router } from 'navigation';
import { Trans } from 'react-i18next';

export const EmptyPhotoList = () => {
	const community = useCurrentCommunity();
	const communityId = community?.slug;
	const adminRoleId = community?.groups.all.find(
		g => g.name === 'site_admin'
	)?.uuid;

	return (
		<Typography variant='body2' mb={0}>
			{community?.has_well_wishes ? (
				<Trans i18nKey='photos.your-community-currently-has-no-image'>
					<RouterLink
						to={router.getPathFor(
							'community.team',
							{
								communityId,
							},
							{
								role: adminRoleId ?? '',
								sort_by: 'first_name',
							}
						)}
					/>
					<RouterLink
						to={router.getPathFor('community.wishes', {
							communityId,
						})}
					/>
				</Trans>
			) : (
				<Trans i18nKey='photos.your-community-currently-has-no-image-well-wishes-disabled'>
					<RouterLink
						to={router.getPathFor(
							'community.team',
							{
								communityId,
							},
							{
								role: adminRoleId ?? '',
								sort_by: 'first_name',
							}
						)}
					/>
				</Trans>
			)}
		</Typography>
	);
};
