import { useCurrentCommunity } from 'components';
import { SingleGroup } from 'core/types';
import { useMemo } from 'react';

export const useVisibilityFormData = <T>(record?: {
	uuid: string;
	who_can_see: SingleGroup[];
	managers?: SingleGroup[];
	upload?: SingleGroup[];
}) => {
	const community = useCurrentCommunity();

	const groups = useMemo(() => {
		return (
			community?.groups.all.filter(
				group =>
					!(group.name === 'member' || group.name === 'site_admin')
			) ?? []
		);
	}, [community]);

	const getVisibilityAndSelectedGroups = (data: SingleGroup[]) => {
		let visibility = 'custom';

		if (data.length === 0) {
			visibility = 'only_admins';
		} else if (data.length === 1) {
			if (data[0].name === 'member') {
				visibility = 'members';
			} else if (data[0].uuid === record?.uuid) {
				visibility = 'admins_members';
			}
		}

		let groups: string[] = [];

		if (visibility === 'custom') {
			groups = data.map(group => group.uuid);
		}

		return [visibility, groups];
	};

	const data = useMemo(() => {
		if (!record) {
			return {
				visibility: 'members',
				edit: 'only_admins',
				upload: 'members',
				management: 'only_admins',
			} as unknown as T;
		}

		const [visibility, visibilityGroups] = getVisibilityAndSelectedGroups(
			record.who_can_see
		);

		const [edit, editGroups] = getVisibilityAndSelectedGroups(
			record?.managers ?? []
		);

		const [upload, uploadGroups] = getVisibilityAndSelectedGroups(
			record?.upload ?? []
		);

		return {
			visibility,
			visibilityGroups,
			edit,
			editGroups,
			upload,
			uploadGroups,
			management: edit,
			managementGroups: editGroups,
		} as unknown as T;
	}, [record]);

	return {
		community,
		visibilityData: data,
		groups,
	};
};
