import { Box, FORM_ERROR, Paper, TruncatedContent } from 'components/common';
import { isNotAllowToPostWellWish } from 'core/services/api/errors/app-errors';
import { WellWish } from 'core/types';
import { usePatchWellWish } from 'core/uses-cases/well-wish';
import {
	useParams,
	useDimensions,
	useServices,
	useSubmitHandlerForPausedMutation,
	useTranslation,
	useVerifyActions,
} from 'hooks';
import { DateTime } from 'luxon';
import { useState } from 'react';
import {
	FeedCardContent,
	FeedCardContentProps,
	FeedCardHeader,
	FeedCardHeaderProps,
	FeedImage,
	useFeedCardMenu,
} from '../../community/feed/shared';
import { WellWishForm, WellWishFormSubmittedValues } from '../form';
import { useDeleteWellWishHandler } from '../hooks';

export type WellWishCardProps = {
	wellWish: WellWish;
	FeedCardHeaderProps?: Partial<FeedCardHeaderProps>;
	FeedCardContentProps?: Partial<FeedCardContentProps>;
};

export const WellWishCardContent = (props: WellWishCardProps) => {
	const { t } = useTranslation();
	const { communityId } = useParams() as {
		communityId: string;
	};

	const [isEditing, setIsEditing] = useState(false);

	const { wellWish, FeedCardHeaderProps, FeedCardContentProps } = props;

	const isOfflineVersion = !wellWish.created_dt;
	const isInTimeToEdit =
		wellWish.created_dt &&
		DateTime.now() <=
			DateTime.fromISO(wellWish.created_dt).plus({
				minutes: 15,
			});

	const hasEditPermission =
		wellWish?.identity_permissions?.includes('update');

	const isEditable =
		hasEditPermission && (isInTimeToEdit || isOfflineVersion);

	const { notification } = useServices();

	const handleOnDelete = useDeleteWellWishHandler({
		communityId,
		wellWishId: wellWish.uuid,
	});

	const { mutateAsync, isLoading, isPaused } = usePatchWellWish();

	const handleOnEdit = () => setIsEditing(true);

	const handleOnSubmit =
		useSubmitHandlerForPausedMutation<WellWishFormSubmittedValues>(
			isPaused,
			values => {
				return mutateAsync({
					communityId,
					wellWishId: wellWish.uuid,
					createdDt: wellWish?.created_dt,
					...values,
				}).catch(error => {
					if (isNotAllowToPostWellWish(error)) {
						throw {
							[FORM_ERROR]: t(`api-errors.${error.message}`),
						};
					}

					throw error;
				});
			},
			{
				onSettled: () => setIsEditing(false),
				onSuccess: () => {
					notification.show(
						'success',
						t('well-wishes.well-wishes-was-successfully-updated')
					);
				},
			},
			[wellWish, communityId]
		);

	const actionObject = useVerifyActions(
		{
			type: 'well_wish',
			identity_permissions: wellWish.identity_permissions,
		},
		[{ action: 'delete', handler: handleOnDelete, key: 'onDelete' }]
	);

	if (isEditable) {
		actionObject.onEdit = handleOnEdit;
	}

	const menuAction = useFeedCardMenu(actionObject);

	const [contentRef, dimensions] = useDimensions<HTMLDivElement>();

	return (
		<>
			{isEditing && (
				<WellWishForm
					initialValues={{
						message: wellWish.message,
						image: wellWish?.image
							? wellWish?.image
							: null, // prettier-ignore
					}}
					onSubmit={handleOnSubmit}
					onCancel={() => setIsEditing(false)}
					submitText={t('form.save')}
					submittingText={t('form.saving')}
					isSubmitting={isLoading && !isPaused}
					editing
					rows={Math.max(
						2,
						Math.ceil((dimensions?.height || 40) / 40)
					)}
				/>
			)}
			{!isEditing && (
				<>
					<FeedCardHeader
						identity={wellWish.author}
						createdDt={
							wellWish.created_dt ?? new Date().toISOString()
						}
						renderAction={menuAction}
						{...FeedCardHeaderProps}
					/>
					{wellWish?.image && (
						<Box ml={8}>
							<FeedImage image={wellWish?.image} />
						</Box>
					)}
					<Box ref={contentRef}>
						<FeedCardContent
							content={wellWish.message ?? ''}
							{...FeedCardContentProps}
							contentWrapper={
								<TruncatedContent
									showText={t(
										'well-wishes.view-full-well-wish'
									)}
									hideText={t('well-wishes.hide-well-wish')}
									maxHeight={150}
								/>
							}
						/>
					</Box>
				</>
			)}
		</>
	);
};

export const WellWishCard = ({ wellWish }: { wellWish: WellWish }) => {
	return (
		<Box component={Paper} elevation={0} p={3} mb={2} mt={1}>
			<WellWishCardContent wellWish={wellWish} />
		</Box>
	);
};
