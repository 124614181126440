import { SetupMutationFn } from 'core/configure/types';
import { BaseHttpError } from 'core/services/api/errors';
import { Services } from 'core/services/types';
import { AlbumPhoto, Identity, Image as ImageType, Nullable } from 'core/types';
import { postAlbumPhotoMutationKey } from 'core/utils/mutation-key-factory';
import {
	onPostMutateOptimisticInfinityQueryCache,
	onSuccessOptimisticInInfinityQueryCache,
} from 'core/utils/optimistic-utils';
import {
	albumPhotoKeys,
	authenticationKeys,
	communityKeys,
} from 'core/utils/query-key-factory';
import { isCancelledError, useMutation } from 'react-query';
import { v4 as uuid } from 'uuid';
import { cacheImage, createImageServerUrl } from 'utils/url';
import { photoCardConfigs } from 'components/photos';

export interface PostAlbumPhotoMutationInput {
	albumId: string;
	image: Nullable<ImageType>;
}

export const usePostAlbumPhoto = () => {
	const mutation = useMutation<
		AlbumPhoto,
		BaseHttpError,
		PostAlbumPhotoMutationInput
	>(postAlbumPhotoMutationKey);

	return mutation;
};

type Context = {
	albumId: string;
	photoId: string;
};

export const setupPostAlbumPhoto: SetupMutationFn = (
	services: Services,
	createTrackedParallelMutation,
	mutationTracker
) => {
	const { queryClient, api, uploader } = services;

	const mutation = createTrackedParallelMutation<
		AlbumPhoto,
		BaseHttpError,
		PostAlbumPhotoMutationInput,
		Context
	>({
		mutationFn: async ({ image, albumId }) => {
			const remoteImage = await uploader.uploadImage(image);

			return api
				.postAlbumPhoto(albumId, remoteImage?.uuid ?? '')
				.then(response => response.data[0]);
		},
		onMutate: async ({ albumId, image }) => {
			const albumPhotoId = uuid();

			const identity = queryClient.getQueryData<Identity>(
				authenticationKeys.identity
			) as Identity;

			const newAlbumPhoto: AlbumPhoto = {
				uuid: albumPhotoId,
				album_uuid: albumId,
				created_dt: new Date().toISOString(),
				discussion_uuid: uuid(),
				owner: identity,
				image: image as ImageType,
				sort_order: 0,
				community_uuid: '',
				discussion_stats: {
					comments: 0,
				},
				source_entity: null,
				source_uuid: null,
				can_delete: false,
				identity_permissions: [],
			};

			await onPostMutateOptimisticInfinityQueryCache<AlbumPhoto>(
				queryClient,
				albumPhotoKeys.list(albumId),
				albumPhotoKeys.detail(albumId, albumPhotoId),
				newAlbumPhoto
			);

			return {
				albumId,
				photoId: albumPhotoId,
			};
		},
		onSuccess: async (result, { albumId }, context) => {
			if (context) {
				const { photoId } = context;

				await cacheImage(result.image.url, photoCardConfigs);

				onSuccessOptimisticInInfinityQueryCache(
					queryClient,
					albumPhotoKeys.list(albumId),
					albumPhotoKeys.detail(albumId, photoId),
					photoId,
					result
				);
			}
		},
		onSettled: (data, error, variables, context) => {
			if (context && !isCancelledError(error)) {
				mutationTracker.queueInvalidations(
					albumPhotoKeys.list(context.albumId),
					albumPhotoKeys.detail(variables.albumId, context.photoId),
					communityKeys.feed(context.albumId)
				);
			}
		},
		retry: 1,
	});

	queryClient.setMutationDefaults(postAlbumPhotoMutationKey, mutation);
};
