import { Box, CircularProgress } from 'components/common';
import {
	ManagePermissionsForm,
	ManagePermissionsFormSubmittedValues,
} from 'components/permissions';
import { useShowGlobalPermission } from 'components/permissions/use-show-global-permission';
import { TeamHeader } from 'components/team';
import {
	useGetGlobalPermissions,
	usePutGlobalPermissions,
} from 'core/uses-cases/community';
import {
	useTranslation,
	useCurrentCommunity,
	useParams,
	useRouter,
	useSubmitHandlerForPausedMutation,
	useServices,
} from 'hooks';
import { Navigate } from 'navigation';

export const ManagePermissions = () => {
	const { t } = useTranslation();
	const { communityId } = useParams() as {
		communityId: string;
	};
	const { notification } = useServices();
	const router = useRouter();
	const isGranted = useShowGlobalPermission();
	const community = useCurrentCommunity();
	const { data, isSuccess, isLoading } = useGetGlobalPermissions(
		communityId,
		{
			enabled: isGranted,
		}
	);

	const { mutateAsync, isPaused } = usePutGlobalPermissions();

	const handleOnSubmit =
		useSubmitHandlerForPausedMutation<ManagePermissionsFormSubmittedValues>(
			isPaused,
			values =>
				mutateAsync({
					communityId,
					permissions: values,
				}),
			{
				onSuccess: () => {
					notification.show(
						'success',
						t(
							'advance-permissions.permissions-were-successfully-saved'
						)
					);
				},
			},
			[communityId]
		);

	if (community && !isLoading && !isGranted) {
		return (
			<Navigate
				to={router.getPathFor('community.team', {
					communityId,
				})}
			/>
		);
	}

	const groups = community?.groups.all || [];

	return (
		<>
			<TeamHeader title={t('advance-permissions.permissions')} />
			<Box mt={2}>
				{(!community || isLoading) && (
					<Box display='flex' justifyContent='center' mt={4}>
						<CircularProgress message={t('common.loading')} />
					</Box>
				)}
				{isSuccess && community && data && (
					<ManagePermissionsForm
						groups={groups}
						initialValues={data}
						onSubmit={handleOnSubmit}
					/>
				)}
			</Box>
		</>
	);
};
