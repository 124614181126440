import { SxProps } from '@mui/system';
import { Theme } from 'theme';

export const rootStyle: SxProps<Theme> = theme => ({
	position: 'sticky',
	width: '100%',
	bottom: '-100px',
	backgroundColor: theme.palette.background.paper,
	transition: 'bottom ease 0.5s, opacity ease 0.5s',
	boxShadow: `0 2px 6px 0 ${theme.borderColors[0.1]}`,
	visibility: 'hidden',
	opacity: 1,
	zIndex: 100,
	flexDirection: 'column',

	'&.enabled': {
		bottom: 0,
		visibility: 'visible',
	},

	'& > div': {
		height: '68px',
	},
});

export const defaultButtonStyle: SxProps<Theme> = theme => ({
	color: theme.blacks[0.6],
});
