import { SxProps } from '@mui/system';
import { Theme } from 'theme';

export const containerStyle: SxProps<Theme> = theme => ({
	position: 'fixed',
	right: 0,
	bottom: 0,
	paddingRight: theme.spacing(1.5),

	'& > div': {
		alignItems: 'center',
	},
});
