import { useRef } from 'react';
import {
	Box,
	CircularProgress,
	PageHeading,
	Grid,
	Typography,
	Link,
	Dialog,
	Paper,
} from 'components/common';
import {
	useParams,
	useTranslation,
	useRouter,
	useErrorHandler,
	useSubmitHandlerForPausedMutation,
	useServices,
	useVerifyAccess,
} from 'hooks';
import {
	EventDetailCard,
	EventPermissionSection,
	EventShareCard,
	IdentityListCard,
	useDeleteEventHandler,
	VolunteerDialogContent,
} from 'components/events';
import {
	usePostVolunteer,
	useRemoveVolunteer,
	useGetEvent,
} from 'core/uses-cases/calendar';
import { Identity } from 'core/types';
import { useCommunityMembers } from 'core/uses-cases/member';
import { useCurrentIdentity } from 'core/uses-cases/authentication';
import { CommentsWidget } from 'components/discussion';

export const EventDetail = () => {
	const dialogRef = useRef<React.ElementRef<typeof Dialog>>(null);
	const { t } = useTranslation();
	const currentIdentity = useCurrentIdentity();
	const { notification } = useServices();
	const router = useRouter();
	const verify = useVerifyAccess();

	const { communityId, calendarId, eventId } = useParams() as {
		communityId: string;
		calendarId: string;
		eventId: string;
	};

	const communityMembers = useCommunityMembers(communityId);

	const {
		data: event,
		isLoading,
		isSuccess,
		error,
	} = useGetEvent(calendarId, eventId);

	// @TODO: if event.id changes and is different from eventId, redirect to new event

	const volunteerMutation = usePostVolunteer();
	const unvolunteerMutation = useRemoveVolunteer();

	const handleOnVolunteer = useSubmitHandlerForPausedMutation<Identity>(
		volunteerMutation.isPaused,
		identity =>
			volunteerMutation.mutateAsync({
				communityId,
				calendarId,
				eventId,
				identityId: identity.uuid,
			}),
		{
			onSuccess: () => {
				notification.show('success', t('event.volunteer-success'));
			},
		},
		[communityId]
	);

	const handleOnUnVolunteer = useSubmitHandlerForPausedMutation<Identity>(
		unvolunteerMutation.isPaused,
		identity =>
			unvolunteerMutation.mutateAsync({
				communityId,
				calendarId,
				eventId,
				identityId: identity.uuid,
			}),
		{
			onSuccess: () => {
				notification.show('success', t('event.unvolunteer-success'));
			},
		},
		[communityId]
	);

	const isIdentityVolunteer = (identity: Identity) => {
		return (
			event?.volunteers?.some(
				volunteer => volunteer.uuid === identity?.uuid
			) ?? false
		);
	};

	const isAllowedToVolunteer = (event?.volunteers_available ?? 0) > 0;

	const onDeleteEvent = useDeleteEventHandler({
		onConfirm: () =>
			router.navigate('community.calendar', {
				communityId,
				calendarId,
			}),
	});

	const handleOnDelete = () => {
		if (!event) return;

		onDeleteEvent({ event, communityId, calendarId });
	};

	const handleOnDuplicate = () => {
		if (!event) return;

		router.navigate('community.calendar.event.copy', {
			communityId,
			calendarId,
			eventId,
		});
	};

	const handleOnEdit = () => {
		if (!event) return;

		router.navigate('community.calendar.event.edit', {
			communityId,
			calendarId,
			eventId,
		});
	};

	useErrorHandler(error, {
		redirectTo: router.getPathFor('community.calendar', {
			communityId,
			calendarId,
		}),
	});

	const canUpdateEvent = verify('update', 'task', event);
	const canVolunteer = verify('volunteer', 'task', event);
	const canAssign = verify('assign', 'task', event);
	const canSeePhoneNumbers = verify('approve', 'member');

	return (
		<Box>
			{isLoading && (
				<Box display='flex' justifyContent='center' mt={4}>
					<CircularProgress message={t('common.loading')} />
				</Box>
			)}
			{isSuccess && event && (
				<>
					<PageHeading title={event.name} />
					<Grid container spacing={3}>
						<Grid item xs={12} md={7} lg={8}>
							<Box component={Paper} elevation={0} p={3}>
								<EventDetailCard
									event={event}
									identity={currentIdentity as Identity}
									isIdentityVolunteer={isIdentityVolunteer}
									canVolunteer={canVolunteer}
									onVolunteer={handleOnVolunteer}
									onUnVolunteer={handleOnUnVolunteer}
									disableActions={
										(volunteerMutation.isLoading &&
											!volunteerMutation.isPaused) ||
										(unvolunteerMutation.isLoading &&
											!unvolunteerMutation.isPaused)
									}
									onDelete={handleOnDelete}
									onEdit={handleOnEdit}
									onDuplicate={handleOnDuplicate}
								/>
								{/* Event Permissions */}
								{canUpdateEvent ? (
									<EventPermissionSection event={event} />
								) : null}
							</Box>
							<CommentsWidget
								discussionId={event.discussion_uuid ?? ''}
								enabled={!!event?.discussion_uuid}
							/>
						</Grid>
						<Grid item xs={12} md={5} lg={4} mt={-4}>
							{event.volunteers_needed > 0 && (
								<Box>
									<Typography
										variant='body2'
										fontWeight='800'
										mb={1}
									>
										{t('event.volunteers')}{' '}
										{
											canAssign &&
											isAllowedToVolunteer && (
												<Link
													role='button'
													href='#'
													onClick={() => {
														dialogRef.current?.open();
													}}
												>
													({t('event.assign')})
												</Link>
											) // prettier-ignore
										}
									</Typography>
									<IdentityListCard
										identities={event.volunteers || []}
										showPhone={canSeePhoneNumbers}
										emptyMessage={t(
											'event.there-are-presently-no-volunteers'
										)}
										onDelete={
											canAssign
												? handleOnUnVolunteer
												: undefined
										}
									/>
								</Box>
							)}
							<Box mt={3}>
								<Typography
									variant='body2'
									fontWeight='800'
									mb={1}
								>
									{t('event.coordinators')}
								</Typography>
								<IdentityListCard
									identities={event.coordinators || []}
									showPhone={canSeePhoneNumbers}
								/>
							</Box>
							<Box mt={3}>
								<Typography
									variant='body2'
									fontWeight='800'
									mb={1}
								>
									{t('event.share')}
								</Typography>
								<EventShareCard />
							</Box>
						</Grid>
					</Grid>
				</>
			)}
			<Dialog
				id='manage-volunteers-dialog'
				ref={dialogRef}
				{...{
					title: t('event.manage-volunteers'),
					content: (
						<VolunteerDialogContent
							event={event}
							isIdentityVolunteer={isIdentityVolunteer}
							members={communityMembers}
							onVolunteer={handleOnVolunteer}
							onUnVolunteer={handleOnUnVolunteer}
							disableActions={
								(volunteerMutation.isLoading &&
									!volunteerMutation.isPaused) ||
								(unvolunteerMutation.isLoading &&
									!unvolunteerMutation.isPaused)
							}
						/>
					),
					initialOpen: false,
					fullWidth: true,
					hideConfirm: true,
				}}
			/>
		</Box>
	);
};
