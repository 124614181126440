import { SxProps } from '@mui/system';
import { Theme } from 'theme';

export const containerStyle: SxProps<Theme> = theme => ({});

export const inputContainerStyle: SxProps<Theme> = theme => ({
	display: 'inline-flex',
	width: '100%',

	'& > .intl-tel-input': {
		width: '100%',

		'& .flag-container, & .flag-container .selected-flag': {
			width: theme.spacing(7.5),
		},

		'& input[type=tel]': {
			width: '100%',
			borderRadius: '4px',
			borderColor: theme.palette.text.primary,
			borderWidth: '1px',
			borderStyle: 'solid',
			paddingY: theme.spacing(1.5),
			paddingRight: theme.spacing(2),
			paddingLeft: theme.spacing(8),
			fontSize: 16,
			lineHeight: '24px',
			outline: 'none',

			'&.Mui-error': {
				borderColor: theme.palette.error.main,
			},

			'&.Mui-focused': {
				borderColor: theme.palette.text.primary,
				borderStyle: 'dashed',

				'&.Mui-error': {
					borderColor: theme.palette.error.main,
				},
			},

			'&.Mui-disabled': {
				backgroundColor: theme.palette.common.white,
			},
		},
	},

	'&.Mui-disabled': {
		'& > .intl-tel-input': {
			opacity: 0.3,
		},
	},
});
