import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
	Icon,
	IconButton,
	IconButtonProps,
	Menu,
	MenuItem,
	MenuItemProps,
} from 'components/common';
import { useState, useMemo } from 'react';
import { menuCardStyle } from './styles';

export type CardMenuItem = {
	label: string;
	icon?: React.ReactNode | string;
	variant?: 'default' | 'error' | 'success' | 'warning';
} & MenuItemProps;

export const useCardMenu = (
	items: CardMenuItem[],
	menuButtonProps?: IconButtonProps
): React.ReactNode => {
	const [anchorEl, setAnchorEl] = useState<Element>();

	const open = Boolean(anchorEl);

	const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(undefined);
	};

	const menuAction = useMemo(() => {
		if (items.length === 0) {
			return null;
		}

		return (
			<>
				<IconButton
					{...menuButtonProps}
					aria-label='more'
					className='card-menu-button'
					aria-controls={open ? 'long-menu' : undefined}
					aria-expanded={open ? 'true' : undefined}
					aria-haspopup='true'
					onClick={handleOpen}
				>
					<MoreVertIcon />
				</IconButton>
				<Menu
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}
					onClick={handleClose}
					PaperProps={{
						sx: menuCardStyle,
						className: 'card-menu-paper',
					}}
					transformOrigin={{ horizontal: 'right', vertical: 'top' }}
					anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
					id='card-menu'
				>
					{items.map(({ label, icon, variant, ...rest }) => (
						<MenuItem
							key={`menu-item-${label.toLowerCase()}`}
							className={variant}
							disableRipple
							{...rest}
						>
							{icon && (
								<>
									{typeof icon === 'string' ? (
										<Icon name={icon} />
									) : (
										icon
									)}
								</>
							)}
							{label}
						</MenuItem>
					))}
				</Menu>
			</>
		);
	}, [anchorEl, open, items]);

	return menuAction;
};
