import { Field } from 'components/common';
import { AdvanceCommunityPermissions, Group } from 'core/types';
import { Fragment } from 'react';
import { useTranslation } from 'hooks';
import { getDefaultGlobalPermissions } from 'core/uses-cases/community';
import { Typography } from '../common/text/text';
import { Grid, GridRowHeader, GridCell, GridCellHeader } from './grid';
import { PermissionGridRowField } from './grid-row-field';
import { ResourceGridGroupRow } from './grid-group-row';

export type ManagePermissionsGridFieldsProps = {
	groups: Group[];
};

const defaultPermissions = getDefaultGlobalPermissions();

export const ManagePermissionsGridFields = ({
	groups,
}: ManagePermissionsGridFieldsProps) => {
	const { t } = useTranslation();

	const groupHeaders = groups.filter(
		({ name }) => name !== 'site_admin' && name !== 'member'
	);

	return (
		<Grid mt={4}>
			<GridRowHeader>
				<GridCellHeader>&nbsp;</GridCellHeader>
				<GridCell>
					<Typography variant='button3'>
						{t('visibility-field.everyone')}
					</Typography>
				</GridCell>
				{groupHeaders.map(({ uuid, description }) => (
					<GridCell key={`permission-group-${uuid}`}>
						<Typography title={description} variant='button3'>
							{description}
						</Typography>
					</GridCell>
				))}
				<GridCell>
					<Typography variant='button3'>
						{t('visibility-field.only-admins')}
					</Typography>
				</GridCell>
			</GridRowHeader>
			{Object.entries(defaultPermissions).map(
				([resourceKey, permissions]) => (
					<Fragment key={`permission-group-${resourceKey}`}>
						<ResourceGridGroupRow
							label={t(
								`advance-permissions.resource.${resourceKey}`
							)}
							groups={groups}
							resourceKey={
								resourceKey as keyof AdvanceCommunityPermissions
							}
						>
							{Object.entries(permissions).map(
								([permissionKey]) => (
									<Field
										component={
											// eslint-disable-next-line react/jsx-indent
											PermissionGridRowField
										}
										key={`permission-${resourceKey}-${permissionKey}`}
										label={t(
											`advance-permissions.labels.${resourceKey}.${permissionKey}`
										)}
										groups={groups}
										name={`${resourceKey}.${permissionKey}`}
									/>
								)
							)}
						</ResourceGridGroupRow>
					</Fragment>
				)
			)}
		</Grid>
	);
};
