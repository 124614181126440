import React, { createContext, useContext, ContextType } from 'react';
import { Services } from './types';

const ServicesContext = createContext<Services>({} as Services);

export const useServices = (): ContextType<typeof ServicesContext> => {
	const context = useContext(ServicesContext);

	if (!context) {
		throw new Error('useServices must be used within a ServicesProvider');
	}

	return context;
};

export const ServicesProvider: React.FC<{
	services: Services;
}> = ({ services, ...rest }) => {
	return <ServicesContext.Provider value={services} {...rest} />;
};
