import { Box } from '../common/layout';
import { CalendarProvider, CalendarProviderProps } from './context';
import { CalendarWidgetHeader } from './shared/header';
import { Widget } from './views/widget';

export function CalendarWidget(props: CalendarProviderProps) {
	return (
		<CalendarProvider {...props}>
			<Box>
				<CalendarWidgetHeader />
				<Widget />
			</Box>
		</CalendarProvider>
	);
}
