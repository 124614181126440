import {
	Grid,
	Form,
	Field,
	Box,
	Button,
	SelectField,
	SelectItem,
	DateRangePickerField,
	When,
	FormControl,
} from 'components/common';
import { TFunction, useTranslation } from 'react-i18next';
import createDecorator from 'final-form-calculate';
import { luxonDateTime } from 'utils/validation';
import * as Yup from 'yup';
import { DateTime } from 'luxon';
import { useForm, useFormState } from 'react-final-form';
import { useConstantValue } from 'hooks';
import { useCallback, useEffect, useState } from 'react';
import { Decorator, FormApi } from 'final-form';
import { convertCollectionToMap } from 'utils/collections';
import { calendarUtils } from 'components/calendar/utils';

export type FilterFormProps = {
	initialValues: SubmittedValues;
	onSubmit: (values: SubmittedValues) => void;
	timezone: string;
};

export type AgendaFilterOption = {
	key: string;
	label: string;
	startDate: DateTime;
	endDate: DateTime;
};

export type SubmittedValues = {
	filterOption: string;
	dates: {
		startDate: DateTime;
		endDate: DateTime;
	};
};

const AutoSave = (props: { onSave: (values: SubmittedValues) => void }) => {
	const { values, dirty } = useFormState<SubmittedValues>({
		subscription: { values: true, dirty: true },
	});

	const { onSave } = props;

	useEffect(() => {
		if (dirty && values.filterOption !== 'custom') {
			onSave(values);
		}
	}, [values, dirty]);

	return null;
};

const CancelButton = ({ initialValue }: { initialValue: SubmittedValues }) => {
	const form = useForm();
	const { t } = useTranslation();
	return (
		<Button
			variant='text'
			size='small'
			onClick={() => form.restart(initialValue)}
		>
			{t('calendar-widget.cancel')}
		</Button>
	);
};

export const FilterForm = ({
	initialValues,
	onSubmit,
	timezone,
}: FilterFormProps) => {
	const { t } = useTranslation();

	const { getOptions, setCustomOption, decorator } = useConstantValue(
		createFilterOptionUtils(t, timezone)
	);

	const [options, setOptions] = useState(() => getOptions());

	const handleOnSubmit = useCallback(
		(
			{ filterOption, dates }: SubmittedValues,
			form: FormApi<SubmittedValues>
		) => {
			if (filterOption === 'custom') {
				const newOptions = setCustomOption(dates);
				setOptions(newOptions);
				form.initialize({ filterOption: 'custom-range' });
			} else {
				onSubmit({ filterOption, dates });
			}
		},
		[]
	);

	const validation = useConstantValue(
		Yup.object({
			filterOption: Yup.string(),
			dates: Yup.mixed().when('filterOption', {
				is: 'custom',
				then: Yup.object({
					startDate: luxonDateTime(
						t('calendar-widget.invalid-start-date')
					).required(t('calendar-widget.start-date-is-required')),
					endDate: luxonDateTime(
						t('calendar-widget.invalid-end-date')
					)
						.required(t('calendar-widget.end-date-is-required'))
						.when('startDate', {
							is: (startDate: DateTime) => startDate.isValid,
							then: schema => {
								return schema.test(
									'endDate',
									t(
										'calendar-widget.end-date-must-be-greater-than-start-date'
									),
									(endDate, context) => {
										if (!endDate?.isValid) return true;
										return (
											endDate > context.parent.startDate
										);
									}
								);
							},
						}),
				}),
				otherwise: Yup.object().shape({
					startDate: luxonDateTime(
						t('calendar-widget.invalid-start-date')
					).nullable(true),
					endDate: luxonDateTime(
						t('calendar-widget.invalid-end-date')
					).nullable(true),
				}),
			}),
		})
	);

	return (
		<Form<SubmittedValues>
			initialValues={initialValues}
			onSubmit={handleOnSubmit}
			decorators={[decorator]}
			subscription={{}}
			validationSchema={validation}
		>
			{({ handleSubmit }) => (
				<Grid
					container
					spacing={2}
					direction={{ xs: 'column', md: 'row' }}
				>
					<AutoSave onSave={handleSubmit} />
					<Grid item xs={1} md={3}>
						<Field
							name='filterOption'
							component={SelectField}
							size='small'
							fullWidth
						>
							{options.map((option: AgendaFilterOption) => (
								<SelectItem key={option.key} value={option.key}>
									{option.label}
								</SelectItem>
							))}
							<SelectItem value='custom'>
								{t('calendar-widget.custom')}
							</SelectItem>
						</Field>
					</Grid>
					<When field='filterOption' is='custom'>
						<Grid
							item
							xs={1}
							md={9}
							display='flex'
							alignItems='flex-start'
							flexDirection={{ xs: 'column', md: 'row' }}
						>
							<Field
								sx={{ flex: { md: 2 / 3, xs: 1 } }}
								name='dates'
								component={DateRangePickerField}
								fullWidth
								startFieldProps={{
									inputProps: {
										placeholder: '1/1/2015',
									},
								}}
								endFieldProps={{
									inputProps: {
										placeholder: '1/25/2015',
									},
								}}
								size='small'
							/>
							<FormControl
								fullWidth
								sx={{ flex: { md: 1 / 3, xs: 1 } }}
							>
								<Box
									display='inline-flex'
									alignItems='center'
									justifyContent='center'
									mt={{ md: 0, xs: 2 }}
									ml={{ md: 2, xs: 0 }}
								>
									<Button size='small' onClick={handleSubmit}>
										{t('calendar-widget.save')}
									</Button>
									<CancelButton
										initialValue={initialValues}
									/>
								</Box>
							</FormControl>
						</Grid>
					</When>
				</Grid>
			)}
		</Form>
	);
};

function getInitialOptions(t: TFunction, timezone: string) {
	const now = DateTime.local({ zone: timezone });

	const options: AgendaFilterOption[] = [
		{
			key: 'monthly',
			label: t('calendar-widget.monthly'),
			startDate: now.startOf('month'),
			endDate: now.endOf('month'),
		},
		{
			key: 'weekly',
			label: t('calendar-widget.weekly'),
			startDate: calendarUtils.getFirstDayOfWeek(now),
			endDate: calendarUtils.getLastDayOfWeek(now),
		},
		{
			key: 'next_7_days',
			label: t('calendar-widget.next-7-days'),
			startDate: now.startOf('day'),
			endDate: now.startOf('day').plus({ day: 7 }),
		},
		{
			key: 'next_30_days',
			label: t('calendar-widget.next-30-days'),
			startDate: now.startOf('day'),
			endDate: now.startOf('day').plus({ day: 30 }),
		},
		{
			key: 'next_90_days',
			label: t('calendar-widget.next-90-days'),
			startDate: now.startOf('day'),
			endDate: now.startOf('day').plus({ day: 90 }),
		},
	];

	return options;
}

const createFilterOptionUtils = (t: TFunction, timezone: string) => {
	const options = getInitialOptions(t, timezone);
	let optionsMaps = convertCollectionToMap(options, 'key');

	const decorator = createDecorator({
		field: 'filterOption',
		updates: {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any -- createDecorator TS is limited and does not allow to use explicit type
			dates: (value: string, values: any) => {
				if (value === 'custom') {
					return values.dates;
				}

				const option = optionsMaps.get(value);

				return {
					startDate: option?.startDate ?? null,
					endDate: option?.endDate ?? null,
				};
			},
		},
	}) as Decorator<SubmittedValues>;

	return {
		decorator,
		setCustomOption: ({ startDate, endDate }: SubmittedValues['dates']) => {
			const options = [
				...getInitialOptions(t, timezone),
				...[
					{
						key: 'custom-range',
						label: `${startDate?.toFormat(
							'M/d/yyyy'
						)} - ${endDate?.toFormat('M/d/yyyy')}`,
						startDate: startDate,
						endDate: endDate,
					},
				],
			];

			optionsMaps = convertCollectionToMap(options, 'key');

			return options;
		},
		getOptions: () => options,
	};
};
