import { Breakpoint } from '@mui/material';
import {
	Grid,
	GridProps,
	Paper,
	TextProps,
	Typography,
	Box,
	BoxProps,
} from 'components/common';
import React, {
	Children,
	cloneElement,
	createContext,
	ReactElement,
	ReactNode,
	useContext,
	useMemo,
} from 'react';
import { SxProps } from '@mui/system';
import { cellStyle, gridCellStyle } from './styles';

type ColumnGridWidth = { [k in Breakpoint]: number };
type ColumnGridLast = { [k in Breakpoint]: boolean };

type ColumnsGridInfo = {
	[k in Breakpoint]: {
		renderedColumnCount: number;
		lastColumnIndex: number;
		customColumnCount: number;
		customColumnItemCount: number;
	};
};

type RenderColumnsProp = {
	[key: number]: { [k in Breakpoint]?: boolean };
};

type CustomColumnsWidthProp = {
	[key: number]: { [k in Breakpoint]?: number };
};

type TableContextValue = {
	renderColumns?: RenderColumnsProp;
	customGridWidths?: CustomColumnsWidthProp;
};

const breakpoints: Breakpoint[] = ['xs', 'sm', 'md', 'lg', 'xl'];

const TableContext = createContext<TableContextValue>({});

const useTable = () => {
	const table = useContext(TableContext);

	if (!table) {
		throw new Error(
			'It looks like Table components are being used with <Table> tag as root.'
		);
	}

	return table;
};

const useRenderChildrenGrid = (children: ReactNode) => {
	const { renderColumns = {}, customGridWidths = {} } = useTable();

	const initialColumnCount = useMemo(
		() =>
			Children.map(children ?? [], children => children).filter(Boolean)
				.length,
		[children]
	);

	const gridInformation = useMemo(() => {
		return breakpoints.reduce((carry, breakpointKey) => {
			const columnVisibility = [...Array(initialColumnCount).keys()].map(
				columnKey => {
					return (
						renderColumns?.[columnKey + 1]?.[breakpointKey] ?? true
					);
				}
			);

			const renderedColumnCount = columnVisibility.filter(
				isVisible => isVisible
			).length;

			const lastColumnIndex = columnVisibility.lastIndexOf(true);

			const { customColumnCount, customColumnItemCount } = Object.values(
				customGridWidths
			).reduce(
				(c, r) => {
					const customColumnItemCount =
						c.customColumnItemCount + (r?.[breakpointKey] || 0);
					const customColumnCount =
						c.customColumnCount + (r?.[breakpointKey] ? 1 : 0);
					return { customColumnItemCount, customColumnCount };
				},
				{ customColumnCount: 0, customColumnItemCount: 0 }
			);

			return {
				...carry,
				[breakpointKey]: {
					renderedColumnCount,
					lastColumnIndex,
					customColumnCount,
					customColumnItemCount,
				},
			};
		}, {} as ColumnsGridInfo);
	}, [renderColumns, initialColumnCount]);

	return Children.map(children, (child, columnIndex) => {
		if (!child) return null;
		const renderedColumn = renderColumns?.[columnIndex + 1] ?? {};
		const {
			xs = true,
			sm = true,
			md = true,
			lg = true,
			xl = true,
		} = renderedColumn;

		const gridProps = breakpoints.reduce((acc, breakpointKey) => {
			const {
				renderedColumnCount,
				customColumnItemCount,
				customColumnCount,
			} = gridInformation[breakpointKey];

			const renderColumn = renderedColumn?.[breakpointKey] ?? true;
			const customColumnItem =
				customGridWidths?.[columnIndex + 1]?.[breakpointKey] ?? 0;

			let columnWidth =
				(12 - customColumnItemCount) /
				(renderedColumnCount - customColumnCount);

			columnWidth = customColumnItem ? customColumnItem : columnWidth;

			return {
				...acc,
				[breakpointKey]: renderColumn ? columnWidth : false,
			};
		}, {} as ColumnGridWidth);

		const isLast = breakpoints.reduce((acc, breakpointKey) => {
			return {
				...acc,
				[breakpointKey]:
					columnIndex ===
					gridInformation[breakpointKey].lastColumnIndex,
			};
		}, {} as ColumnGridLast);

		return cloneElement(child as ReactElement, {
			gridProps: {
				...gridProps,
				sx: gridCellStyle({ xs, md, sm, lg, xl }),
			},
			cellStyle: cellStyle(isLast),
		});
	});
};

export const Table: React.FC<TableContextValue> = ({
	renderColumns,
	customGridWidths,
	children,
}) => {
	return (
		<TableContext.Provider value={{ renderColumns, customGridWidths }}>
			{children}
		</TableContext.Provider>
	);
};

export const TableHeader: React.FC = ({ children }) => {
	const grid = useRenderChildrenGrid(children);

	return (
		<Grid container mb={3} component={Paper}>
			{grid}
		</Grid>
	);
};

export type TableHeaderCellProps = BoxProps & {
	title?: string | React.ReactNode;
	TypographyProps?: TextProps;
	cellStyle?: SxProps;
	gridProps?: GridProps;
};

export const TableHeaderCell: React.FC<TableHeaderCellProps> = props => {
	const { title, TypographyProps, cellStyle, gridProps, children, ...rest } =
		props;

	return (
		<Grid
			item
			display='flex'
			flexDirection='column'
			justifyContent='center'
			{...gridProps}
		>
			<Box my={1.5}>
				<Box px={4} sx={cellStyle} {...rest}>
					{title && (
						<Typography variant='button2' {...TypographyProps}>
							{title}
						</Typography>
					)}
					{!title && children}
				</Box>
			</Box>
		</Grid>
	);
};

export const TableRow: React.FC = props => {
	const grid = useRenderChildrenGrid(props.children);

	return (
		<Grid container component={Paper} mb={3}>
			{grid}
		</Grid>
	);
};

export type TableRowCellProps = BoxProps & {
	cellStyle?: SxProps;
	gridProps?: GridProps;
};

export const TableRowCell: React.FC<TableRowCellProps> = props => {
	const { children, cellStyle, gridProps, ...rest } = props;

	return (
		<Grid
			item
			display='flex'
			flexDirection='column'
			justifyContent='center'
			{...gridProps}
		>
			<Box my={3} sx={cellStyle}>
				<Box px={4} py={2.2} height='100%' width='100%' {...rest}>
					{children}
				</Box>
			</Box>
		</Grid>
	);
};

export const FancyTable = Object.assign(Table, {
	Header: Object.assign(TableHeader, { Cell: TableHeaderCell }),
	Row: Object.assign(TableRow, { Cell: TableRowCell }),
});
