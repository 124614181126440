import { Box, GridProps, Grid, Heading } from 'components/common';

export type PageHeadingProps = {
	title?: string | React.ReactNode;
	children?: React.ReactNode;
	subtitle?: string | React.ReactNode;
	rightSection?: React.ReactNode;
} & GridProps;

export const PageHeading = (props: PageHeadingProps) => {
	const { title, children, rightSection, ...rest } = props;

	return (
		<>
			<Grid container justifyContent='space-between' mb={4} {...rest}>
				<Grid item xs={12} sm={6}>
					<Heading variant='h4' mb={1}>
						{title}
					</Heading>
				</Grid>
				<Grid item xs={12} sm={6} display='flex' justifyContent='end'>
					{rightSection ? <Box>{rightSection}</Box> : null}
				</Grid>
			</Grid>
			{children ? (
				<Box mb={4} width={{ xs: 1, sm: 3 / 4 }}>
					{children}
				</Box>
			) : null}
		</>
	);
};
