import { useState, useLayoutEffect, useRef, RefObject } from 'react';
import { useIsMounted } from './use-is-mounted';

export interface DimensionObject {
	width: number;
	height: number;
	top: number;
	left: number;
	x: number;
	y: number;
	right: number;
	bottom: number;
}

function getDimensionObject(node: HTMLElement): DimensionObject {
	const rect = node.getBoundingClientRect();

	return {
		width: rect.width,
		height: rect.height,
		top: 'x' in rect ? rect.x : rect.top,
		left: 'y' in rect ? rect.y : rect.left,
		x: 'x' in rect ? rect.x : rect.left,
		y: 'y' in rect ? rect.y : rect.top,
		right: rect.right,
		bottom: rect.bottom,
	};
}

export const useDimensions = <T>(): [
	RefObject<T>,
	DimensionObject | undefined
] => {
	const [dimensions, setDimensions] = useState<DimensionObject>();
	const timeoutIdRef = useRef<ReturnType<typeof setTimeout> | undefined>();
	const ref = useRef<T>(null);
	const isMounted = useIsMounted();

	useLayoutEffect(() => {
		const measure = () =>
			window.requestAnimationFrame(() => {
				if (!timeoutIdRef.current) {
					timeoutIdRef.current = setTimeout(() => {
						if (ref.current && isMounted()) {
							setDimensions(
								getDimensionObject(
									ref.current as unknown as HTMLElement
								)
							);
						}

						timeoutIdRef.current = undefined;
					}, 100);
				}
			});

		measure();
		addEventListener('resize', measure);

		return () => {
			removeEventListener('resize', measure);
			if (timeoutIdRef.current) {
				clearTimeout(timeoutIdRef.current);
				timeoutIdRef.current = undefined;
			}
		};
	}, [ref.current]);

	return [ref, dimensions];
};
