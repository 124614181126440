import {
	albumWithParams,
	photoWithParams,
} from 'core/utils/request-with-params';
import { BaseHttpClient } from '../base-http-client';
import {
	AlbumPhotosResponse,
	AlbumResponse,
	AlbumsResponse,
	PostAlbumPhotoResponse,
} from '../response-types';
import { AlbumInput, RequestOptions } from './types';

export const createAlbumApi = (http: BaseHttpClient) => ({
	getAlbumPhotos(albumId: string, options?: RequestOptions) {
		return http
			.get<AlbumPhotosResponse>(
				`album/${albumId}/photo`,
				{
					with: photoWithParams,
					order: 'DESC',
					...options?.pagination,
				},
				{
					...(options?.signal ? { signal: options.signal } : {}),
				}
			)
			.then(response => response.data);
	},

	postAlbumPhoto(albumId: string, photoId: string) {
		return http
			.post<PostAlbumPhotoResponse>(
				`album/${albumId}/photo`,
				{
					images: [photoId],
				},
				{
					params: {
						with: photoWithParams,
					},
				}
			)
			.then(response => response.data);
	},

	patchAlbum(
		albumId: string,
		{ visibility, visibilityGroups, ...data }: AlbumInput
	) {
		return http
			.patch<AlbumResponse>(
				`album/${albumId}`,
				{
					...data,
					privacy: visibility,
					privacyGroups: visibilityGroups,
				},
				{
					params: {
						with: albumWithParams,
					},
				}
			)
			.then(response => response.data);
	},

	deleteAlbum(albumId: string) {
		return http.delete<null>(`album/${albumId}`).then(() => null);
	},

	saveAlbumOrder(
		communityId: string,
		newOrder: { uuid: string; sort_order: number }[]
	) {
		return http
			.patch<AlbumsResponse>(
				`community/${communityId}/album`,
				{
					albums: newOrder,
				},
				{
					params: {
						with: albumWithParams,
					},
				}
			)
			.then(response => response.data.data);
	},

	deleteAlbumPhoto(albumId: string, photoId: string) {
		return http
			.delete<null>(`album/${albumId}/photo/${photoId}`)
			.then(() => null);
	},
});
