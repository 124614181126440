import { useCallback } from 'react';
import IntlTelInput, { CountryData } from 'react-intl-tel-input';
import classnames from 'classnames';
import FormControl, { FormControlProps } from '@mui/material/FormControl';
import InputLabel, { InputLabelProps } from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText/FormHelperText';
import { FieldRenderProps } from 'react-final-form';
import { PhoneNumberValue } from 'core/types';
import { OnErrorScrollPoint } from 'components/common/on-error-scroll-point';
import { Box } from '../../layout';
import { inputContainerStyle, containerStyle } from './styles';
import 'react-intl-tel-input/dist/main.css';

interface TelephoneFieldProps extends Omit<FormControlProps, 'onChange'> {
	value: PhoneNumberValue;
	label?: string;
	placeholder?: string;
	LabelProps?: InputLabelProps;
	name: string;
	helperText?: string;
	onChange: (value: PhoneNumberValue) => void;
	meta: FieldRenderProps<string>['meta'];
}

export function TelephoneField(props: TelephoneFieldProps) {
	const {
		value,
		placeholder,
		label,
		LabelProps,
		helperText,
		meta,
		onChange,
		onBlur,
		onFocus,
		disabled,
		...rest
	} = props;

	const handleChange = useCallback(
		(isValid: boolean, value: string, selectedCountryData: CountryData) => {
			onChange?.({
				number: value ? String(value).trim() : '',
				regionCode: selectedCountryData.iso2,
				dialCode: selectedCountryData.dialCode,
			});
		},
		[onChange]
	);

	const handleBlur = useCallback(
		(
			isValid: boolean,
			value: string,
			selectedCountryData: CountryData,
			fullNumber: string,
			extension: string,
			event: React.FocusEvent<HTMLInputElement>
		) => {
			onBlur?.(event);
		},
		[onBlur]
	);

	const handleFocus = useCallback(
		(
			isValid: boolean,
			value: string,
			selectedCountryData: CountryData,
			fullNumber: string,
			extension: string,
			event: React.FocusEvent<HTMLInputElement>
		) => {
			onFocus?.(event);
		},
		[onFocus]
	);

	const classes = classnames({
		'Mui-error': rest.error,
		'Mui-disabled': disabled,
		'Mui-focused': meta.active,
	});

	return (
		<FormControl sx={containerStyle} disabled={disabled} {...rest}>
			<OnErrorScrollPoint name={rest.name} />
			{label && <InputLabel {...LabelProps}>{label}</InputLabel>}
			<Box sx={inputContainerStyle} className={classes}>
				<IntlTelInput
					placeholder={placeholder}
					value={String(value.number || '')}
					defaultCountry={value.regionCode || ''}
					onPhoneNumberChange={handleChange}
					onPhoneNumberBlur={handleBlur}
					onPhoneNumberFocus={handleFocus}
					inputClassName={classes}
					preferredCountries={['us', 'ca', 'au', 'gb']}
					format
					disabled={disabled}
				/>
			</Box>
			{helperText && <FormHelperText>{helperText}</FormHelperText>}
		</FormControl>
	);
}
