import { SxProps } from '@mui/system';
import { COLORS } from 'components/calendar/constants';
import { Theme } from 'theme';

export const containerStyle: SxProps<Theme> = (theme: Theme) => ({
	borderTop: `1px solid ${theme.palette.grey[400]}`,
	padding: theme.spacing(2),
});

export const yearMonthHeaderContainerStyle: SxProps<Theme> = theme => ({
	'&  .title': {
		fontSize: 24,
		textAlign: 'center',
		marginBottom: 1,
	},
	'& > .header': {
		borderTop: `1px solid ${theme.palette.grey[400]}`,
		borderBottom: `1px solid ${theme.palette.grey[400]}`,
		backgroundColor: theme.palette.grey[200],

		'& span': {
			display: 'inline-block',
			padding: theme.spacing(1.5),
			textAlign: 'center',

			'&:after': {
				content: '""',
				display: 'block',
				paddingBottom: '100%',
			},
		},
	},
});

export const yearMonthContainerStyle: SxProps<Theme> = theme => ({
	'& .year-day': {
		position: 'relative',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: theme.spacing(1),
		margin: theme.spacing(0.5),
		borderRadius: '50%',
		border: '2px solid transparent',
		cursor: 'pointer',

		'&:after': {
			content: "''",
			display: 'block',
			paddingBottom: '100%',
		},

		'&.from-other-month': {
			visibility: 'hidden',
		},

		'&.needs-met': {
			backgroundColor: theme.secondaryColors.secondary3,
			color: theme.palette.common.white,
		},
		'&.is-occasion': {
			backgroundColor: COLORS.occasions,
			color: theme.palette.common.white,
		},
		'&.needs-help': {
			backgroundColor: theme.palette.common.white,
			borderColor: theme.secondaryColors.secondary3,
			color: theme.secondaryColors.secondary3,
		},

		'> span': {
			display: 'block',
			fontSize: 12,
			position: 'absolute',
			left: '50%',
			top: '50%',
			transform: 'translate(-50%, -50%)',
			lineHeight: 1,
		},
	},
});
