import { useServices } from 'core/services';
import { CommunityMembershipRequestsResponse } from 'core/services/api/response-types';
import { MembershipRequest } from 'core/types';
import { communityKeys } from 'core/utils/query-key-factory';
import { useInfiniteQuery } from 'react-query';
import { BaseHttpError } from 'core/services/api/errors';
import { getPageParams } from 'core/utils/pagination';

export const useGetPendingRequests = (
	communityId: string,
	options: { enabled: boolean } = { enabled: true }
) => {
	const { api, authentication } = useServices();
	const isLoggedIn = authentication.isAuthenticated();

	return useInfiniteQuery<
		CommunityMembershipRequestsResponse,
		BaseHttpError,
		MembershipRequest[]
	>(
		communityKeys.pendingRequests(communityId),
		({ pageParam = null, signal }) =>
			api.getCommunityMembershipRequests(communityId, {
				pagination: pageParam,
				signal,
			}),
		{
			enabled: !!communityId && isLoggedIn && options.enabled,
			select: response => {
				return {
					pages: response.pages.map(page => page.data),
					pageParams: response.pageParams,
				};
			},
			getPreviousPageParam: firstPage =>
				getPageParams(firstPage.pagination).previousParam,
			getNextPageParam: lastPage =>
				getPageParams(lastPage.pagination).nextParam,
			refetchOnMount: true,
			keepPreviousData: true,
			notifyOnChangeProps: 'tracked',
		}
	);
};
