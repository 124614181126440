import { useServices } from 'core/services';
import { BaseHttpError } from 'core/services/api/errors';
import { CommentRepliesResponse } from 'core/services/api/response-types';
import { CommentReply } from 'core/types';
import { getPageParams } from 'core/utils/pagination';
import { discussionKeys } from 'core/utils/query-key-factory';
import { useInfiniteQuery } from 'react-query';

export const useGetCommentReplies = (
	discussionId: string,
	commentId: string,
	options: {
		enabled?: boolean;
		initialReplies?: CommentReply[];
		moreReplies?: boolean;
	} = {
		enabled: true,
	}
) => {
	const { api, authentication } = useServices();

	const isLoggedIn = authentication.isAuthenticated();

	const commentsQuery = useInfiniteQuery<
		CommentRepliesResponse,
		BaseHttpError,
		CommentReply[]
	>(
		discussionKeys.replies(discussionId, commentId),
		({ pageParam = null, signal }) =>
			api.getCommentReplies(commentId, {
				pagination: pageParam,
				signal,
			}),
		{
			enabled:
				!!discussionId && !!commentId && isLoggedIn && options.enabled,
			select: data => {
				return {
					pages: data.pages.map(page => page.data),
					pageParams: data.pageParams,
				};
			},
			initialData: () => {
				const { initialReplies = [], moreReplies } = options;
				const lastReply = initialReplies?.[1]?.uuid;
				const pagination = {
					next: moreReplies && lastReply ? `?after=${lastReply}` : '',
					previous: '',
				};

				return {
					pages: [
						{
							data: initialReplies || [],
							pagination,
						},
					],
					pageParams: [undefined],
				};
			},
			staleTime: Infinity, // Needed to keep the first page loaded
			getPreviousPageParam: firstPage =>
				getPageParams(firstPage.pagination).previousParam,
			getNextPageParam: lastPage =>
				getPageParams(lastPage.pagination).nextParam,
			notifyOnChangeProps: 'tracked',
			refetchOnMount: true,
		}
	);

	return commentsQuery;
};
