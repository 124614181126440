import { Box, CircularProgress, Typography } from 'components/common';
import {
	SelectEmailForm,
	SelectEmailFormSubmittedValues,
} from 'components/community/request-to-join/select-email-form';
import { useCheckIdentityEmail } from 'core/uses-cases/identity';
import { useRespondInvitation } from 'core/uses-cases/invitation';
import { useParams, useTranslation, useRouter, useConstantValue } from 'hooks';
import { Navigate } from 'navigation';
import { useTransientStore } from 'store';
import { useInvitationFlow } from './use-invitation-flow';

export const InvitationSelectEmail = () => {
	const { t } = useTranslation();
	const router = useRouter();
	const { communityId, tokenId } = useParams() as {
		communityId: string;
		tokenId: string;
	};
	const store = useTransientStore();
	const enabledFlow = useConstantValue(!store.auth.isLoggedIn); // Only allow if not logged in
	const { token } = useInvitationFlow(communityId, tokenId, enabledFlow);
	const mutation = useCheckIdentityEmail();
	const declineMutation = useRespondInvitation();

	const onSubmit = async ({ email }: SelectEmailFormSubmittedValues) => {
		return mutation.mutateAsync(
			{ email },
			{
				onSuccess: hasAccount => {
					const routeKey = hasAccount
						? 'community.invitation.login'
						: 'community.invitation.create-account';

					router.navigate(
						routeKey,
						{ communityId, tokenId },
						{},
						{ state: { email, hasAccount } }
					);
				},
			}
		);
	};

	const onCancel = () => {
		if (!token) return;

		declineMutation.mutate(
			{
				communityId,
				invitationId: token.invitation_id,
				action: 'decline',
			},
			{
				onSuccess: () => {
					router.navigate(
						'community.invitation.decline-success',
						{ communityId },
						{},
						{ state: { fromInvitation: true } }
					);
				},
			}
		);
	};

	// If there is already a user logged in, redirect to the join workflow
	if (!enabledFlow) {
		return (
			<Navigate
				to={router.getPathFor('community.invitation.join', {
					communityId,
					tokenId,
				})}
			/>
		);
	}

	if (!token) {
		return (
			<Box display='flex' justifyContent='center' mt={4}>
				<CircularProgress message={t('common.loading')} />
			</Box>
		);
	}

	const initialValues = {
		email: token.email ?? token.possible_emails?.[0] ?? '',
	};

	const noEmails = token.possible_emails.length === 0;
	const isExactMatch = token.possible_emails.length === 1;
	const noMatches = noEmails && !!token.phone;

	return (
		<Box>
			<Box mb={4} mr={{ md: '35%' }}>
				<Typography variant='caption'>
					{
						noMatches
							? t('invitation.select-email-no-matches')
							: noEmails || isExactMatch
								? t(
									'invitation.select-email-not-identities-exact-match'
									  )
								: t(
									'invitation.select-email-not-identities-match'
								) // prettier-ignore
					}
				</Typography>
			</Box>
			<SelectEmailForm
				initialValues={initialValues}
				onSubmit={onSubmit}
				onCancel={onCancel}
				cancelText={t('invitation.decline-invitation')}
				submitText={t('invitation.next')}
				availableEmails={[...token.possible_emails]}
			/>
		</Box>
	);
};
