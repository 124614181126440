import { ReloadPrompt } from 'components/common/reload-prompt';
import { OfflinePrompt } from 'components/common/offline-prompt';
import { AlertStack, Box } from '../../common';

export const AppWrapper: React.FC = ({ children }) => {
	return (
		<Box
			display='flex'
			flexDirection='column'
			minHeight='100vh'
			width='100%'
		>
			<AlertStack />
			{children}
			<ReloadPrompt />
			<OfflinePrompt />
		</Box>
	);
};
