import { Box, CircularProgress } from 'components';
import { useParams, useTranslation } from 'hooks';
import { useGetInvalidTokenData } from './use-get-invalid-token-data';

export const InvalidToken = () => {
	const { t } = useTranslation();
	const { communityId, tokenId } = useParams() as {
		communityId: string;
		tokenId: string;
	};

	const { isLoading, primaryText, secondaryText } = useGetInvalidTokenData(
		communityId,
		tokenId
	);

	if (isLoading) {
		return (
			<Box display='flex' justifyContent='center' mt={4}>
				<CircularProgress message={t('common.loading')} />
			</Box>
		);
	}

	return (
		<Box pt={1}>
			<Box mb={4}>{primaryText}</Box>
			<Box mb={4}>{secondaryText}</Box>
		</Box>
	);
};
