import { Box, CircularProgress } from 'components/common';
import { useLogout } from 'core/uses-cases/authentication';
import { useRouter } from 'hooks';
import { useEffect } from 'react';

export const LogoutAccount = () => {
	const { doLogout } = useLogout();
	const router = useRouter();

	useEffect(() => {
		doLogout();
		router.navigate('account.login');
	}, []);

	return (
		<Box display='flex' justifyContent='center' mt={4}>
			<CircularProgress />
		</Box>
	);
};
