import { useEffect } from 'react';
import { useServices } from 'core/services';
import { BaseHttpError } from 'core/services/api/errors';
import { useQuery } from 'react-query';
import { authenticationKeys } from 'core/utils/query-key-factory';
import { Identity } from 'core/types';
import { getCurrentTimeZone } from 'utils/dates';
import { LAST_LOGIN_DT_KEY } from 'core/constants';
import { DateTime } from 'luxon';
import { useLogout } from './use-logout';

export const useGetCurrentIdentity = () => {
	const { api, authentication, queryClient, cookie } = useServices();

	const isLoggedIn = authentication.isAuthenticated();

	const { doLogout } = useLogout();

	const { data, isLoading, isSuccess, isError, error } = useQuery<
		Identity,
		BaseHttpError
	>(
		authenticationKeys.identity,
		() => api.getIdentity('me').then(response => response.data),
		{
			enabled: isLoggedIn,
			refetchOnWindowFocus: true,
		}
	);

	useEffect(() => {
		if (error && error?.response?.status === 403) {
			doLogout(); // Reset current session
		}
	}, [error]);

	useEffect(() => {
		const deviceTimezone = getCurrentTimeZone();

		if (data?.autodetect_timezone && data?.timezone !== deviceTimezone) {
			data.timezone = deviceTimezone;
			queryClient.setQueryData(authenticationKeys.identity, data);
			api.patchIdentity(data.uuid, { timezone: data.timezone });
		}

		// Detect if the session is forced to be destroyed
		const lastLogin = cookie.get(LAST_LOGIN_DT_KEY);

		if (data?.not_before_dt) {
			const notBeforeDt = DateTime.fromISO(data.not_before_dt);
			const lastLoginDt = lastLogin ? DateTime.fromISO(lastLogin) : null;

			if (!lastLoginDt || lastLoginDt < notBeforeDt) {
				doLogout(); // Destroy the session
			}
		}
	}, [data]);

	return {
		data,
		isSuccess,
		isLoggedIn,
		isLoading,
		isError,
	};
};

export const useCurrentIdentity = () => {
	const { data } = useGetCurrentIdentity();

	return data;
};
