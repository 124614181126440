import { Grid, Heading } from 'components/common';
import { useCurrentCommunity } from 'components/layout/community';
import { FeedContainer } from 'components/community/feed/feed-container';
import { useTranslation } from 'hooks';
import { FeedFilter } from 'components/community/feed/feed-filter';
import { CommunityCalendarWidget } from 'components/events';
import { useCurrentIdentity } from 'core/uses-cases/authentication';
import { CommunityDashCard } from 'components/community';

export const CommunityHome = () => {
	const community = useCurrentCommunity();
	const identity = useCurrentIdentity();

	const { t } = useTranslation();

	return (
		<Grid container spacing={3}>
			<Grid item xs={12} md={4}>
				<CommunityDashCard />
			</Grid>
			<Grid item xs={12} md={8}>
				<Grid item>
					{community && identity && (
						<CommunityCalendarWidget
							community={community}
							identity={identity}
						/>
					)}
				</Grid>
				<Grid item container>
					<Grid item xs={6}>
						<Heading
							fontSize={24}
							variant='h4'
							mb={1}
							data-test-id='activity-feed'
						>
							{t('community-home.activity-feed')}
						</Heading>
					</Grid>
					<Grid item xs={6} textAlign='end'>
						<FeedFilter />
					</Grid>
				</Grid>
				<FeedContainer communityId={community?.slug ?? ''} />
			</Grid>
		</Grid>
	);
};
