export const Distances = {
	withinFiveMiles: {
		key: 'within-five-miles',
		value: 5,
	},
	withinTenMiles: {
		key: 'within-ten-miles',
		value: 10,
	},
	withinTwentyFiveMiles: {
		key: 'within-twenty-five-miles',
		value: 25,
	},
	withinFiftyMiles: {
		key: 'within-fifty-miles',
		value: 50,
	},
	withinOneHundredMiles: {
		key: 'within-one-hundred-miles',
		value: 100,
	},
};

export type DistancesTypes = keyof typeof Distances;
