import { isCancelledError, useMutation } from 'react-query';
import { v4 as uuid } from 'uuid';
import { Note, Identity, Community } from 'core/types';
import { SetupMutationFn } from 'core/configure/types';
import {
	authenticationKeys,
	communityKeys,
	noteKeys,
} from 'core/utils/query-key-factory';
import { postNoteMutationKey } from 'core/utils/mutation-key-factory';
import {
	onErrorOptimisticInInfinityQueryCache,
	onPostMutateOptimisticInfinityQueryCache,
	onSuccessOptimisticInInfinityQueryCache,
} from 'core/utils/optimistic-utils';
import { mapVisibilityWithGroups } from 'core/utils/mapper';
import { Services } from '../../services/types';
import { BaseHttpError } from '../../services/api/errors';
import { NoteInput } from '../../services/api/api-client/types';

export interface PostNoteMutationInput extends NoteInput {
	communityId: string;
}

export const usePostNote = () => {
	const mutation = useMutation<Note, BaseHttpError, PostNoteMutationInput>(
		postNoteMutationKey
	);

	return mutation;
};

type Context = {
	communityId: string;
	noteId: string;
	newNote: Note;
};

export const setupPostNote: SetupMutationFn = (
	services: Services,
	createTrackedParallelMutation,
	mutationTracker
) => {
	const { queryClient, api } = services;

	const mutation = createTrackedParallelMutation<
		Note,
		BaseHttpError,
		PostNoteMutationInput,
		Context
	>({
		mutationFn: ({ communityId, ...input }) =>
			api.postNote(communityId, input).then(response => response.data),
		onMutate: async input => {
			const {
				communityId,
				content,
				title,
				visibility,
				visibilityGroups,
				edit,
				editGroups,
			} = input;

			const noteId = uuid();

			const community = queryClient.getQueryData<Community>(
				communityKeys.detail(communityId)
			);
			const identity = queryClient.getQueryData<Identity>(
				authenticationKeys.identity
			) as Identity;

			const newNote: Note = {
				uuid: noteId,
				title,
				content,
				owner: identity,
				created_dt: null,
				updated_dt: null,
				who_can_see: mapVisibilityWithGroups(
					visibility,
					visibilityGroups,
					community?.groups.all ?? []
				),
				managers: mapVisibilityWithGroups(
					edit,
					editGroups,
					community?.groups.all ?? []
				),
			};

			await onPostMutateOptimisticInfinityQueryCache<Note>(
				queryClient,
				noteKeys.list(communityId),
				noteKeys.detail(communityId, noteId),
				newNote
			);

			return {
				communityId,
				noteId,
				newNote,
			};
		},
		onSuccess: (result, { communityId }, { noteId }) => {
			onSuccessOptimisticInInfinityQueryCache(
				queryClient,
				noteKeys.list(communityId),
				noteKeys.detail(communityId, noteId),
				noteId,
				result
			);
		},
		onError: (error, { communityId }, context) => {
			if (context && !isCancelledError(error)) {
				onErrorOptimisticInInfinityQueryCache(
					queryClient,
					noteKeys.list(communityId),
					context.noteId
				);
			}
		},
		onSettled: (data, error, variables, context) => {
			if (context && !isCancelledError(error)) {
				mutationTracker.queueInvalidations(
					noteKeys.list(context.communityId),
					noteKeys.detail(variables.communityId, context.noteId),
					communityKeys.feed(context.communityId)
				);
			}
		},
		retry: 1,
	});

	queryClient.setMutationDefaults(postNoteMutationKey, mutation);
};
