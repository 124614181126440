import { SxProps } from '@mui/system';
import { Theme } from 'theme';

export const datePickerMultipleStyle: SxProps<Theme> = theme => ({
	width: 500,
	'& .MuiTextField-root': {
		width: '100%',

		'&.MuiFocused': {
			visibility: 'visible',
		},
		'& .MuiChip-root': {
			margin: theme.spacing(0.375),
			maxWidth: 'calc(100% - 6px)',
		},
		'& .MuiInputBase-root': {
			flexWrap: 'wrap',
			padding: theme.spacing(1.125),
			paddingRight: theme.spacing(3.625),
			'& .MuiInputBase-input': {
				flexGrow: 1,
				padding: theme.spacing(0.25, 0.5, 0.25, 0.75),
				minWidth: 30,
				width: 0,
				textOverflow: 'ellipsis',
				opacity: 1,
			},
		},
		'& .MuiInputAdornment-root': {
			position: 'absolute',
			top: 22.5,
			right: 0,
		},
	},
});
