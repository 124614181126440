import React, { ComponentType } from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { AppLayout } from 'components/layout';
import { useRouter, useServices } from 'hooks';
import { authenticationKeys } from 'core/utils/query-key-factory';
import { Identity } from 'core/types';
import { useTransientStore } from 'store';

type GuardProps = {
	useDefaultLayout?: boolean;
	element: React.ReactNode;
	layout?: ComponentType;
};

export const UnAuthenticatedGuard = (props: GuardProps) => {
	const {
		useDefaultLayout = true,
		layout: Layout = useDefaultLayout ? AppLayout : React.Fragment,
		element,
		...rest
	} = props;

	const router = useRouter();
	const location = useLocation() as {
		state?: { from?: { pathname: string } };
	};
	const { queryClient } = useServices();

	const store = useTransientStore();

	if (!store.auth.isLoggedIn) {
		return <Layout {...rest}>{element ? element : <Outlet />}</Layout>;
	}

	const identity = queryClient.getQueryData<Identity>(
		authenticationKeys.identity
	);

	let to = router.getPathFor('community.switch');

	const from = location.state?.from?.pathname;
	const length =
		(identity?.my_communities.length ?? 0) +
		(identity?.my_invited_communities.length ?? 0) +
		(identity?.my_pending_communities.length ?? 0);

	if (from) {
		to = from;
	} else if (
		length === 1 &&
		identity?.my_communities.length === 1 &&
		// It's not 100% since a non legacy community could use just numbers,
		// but it's a good enough check to prevent auto redirecting to the legacy site.
		isNaN(Number(identity?.my_communities[0]))
	) {
		to = router.getPathFor('community.home', {
			communityId: identity.my_communities[0],
		});
	}

	return <Navigate to={to} replace />;
};
