import { useMutation, Mutation } from 'react-query';
import { Community, CommunityMember, Identity, Preferences } from 'core/types';
import { patchCommunityMemberMutationKey } from 'core/utils/mutation-key-factory';
import { SetupMutationFn } from 'core/configure/types';
import { cancelPreviousMutation } from 'core/utils/optimistic-utils';
import {
	authenticationKeys,
	communityKeys,
	permissionKeys,
	subscriptionKeys,
} from 'core/utils/query-key-factory';
import { PatchMemberInput } from 'core/services/api/api-client/types';
import { buildDigestFrequency } from 'utils/subscription';
import { BaseHttpError } from '../../services/api/errors';

export interface PatchCommunityMemberMutationInput {
	payload: PatchMemberInput;
	communityId: string;
	memberId: string;
}

export const usePatchCommunityMember = () => {
	const mutation = useMutation<
		Identity,
		BaseHttpError,
		PatchCommunityMemberMutationInput
	>(patchCommunityMemberMutationKey);

	return mutation;
};

type MutationContext = {
	communityId: string;
	memberId: string;
};

export const setupPatchCommunityMember: SetupMutationFn = (
	services,
	createTrackedParallelMutation
) => {
	const { queryClient, api } = services;

	const mutation = createTrackedParallelMutation<
		Identity,
		BaseHttpError,
		PatchCommunityMemberMutationInput,
		MutationContext
	>({
		mutationFn: async ({ memberId, communityId, payload }) => {
			return api
				.patchCommunityMember(communityId, memberId, payload)
				.then(response => response.data);
		},
		onMutate: async input => {
			const { memberId, communityId, payload } = input;

			const community = queryClient.getQueryData<Community>(
				communityKeys.detail(communityId)
			);

			const originalCommunityMember =
				queryClient.getQueryData<CommunityMember>(
					communityKeys.member(communityId, memberId)
				);

			await cancelPreviousMutation<MutationContext>(
				queryClient,
				mutation => {
					return (
						mutation.state.context?.memberId === memberId &&
						mutation.state.context?.communityId === communityId &&
						isPatchCommunityMemberMutation(mutation)
					);
				}
			);

			if (payload.type === 'sameAndAdmin' && originalCommunityMember) {
				const newIdentity: Identity = {
					...originalCommunityMember.identity,
					first_name: payload.first_name,
					last_name: payload.last_name,
					phone: payload.phone,
					email: payload.email,
				};

				const newMemberInfo: CommunityMember = {
					...originalCommunityMember,
					administrative_notes: payload.administrative_notes,
					groups:
						community?.groups.all.filter(group =>
							payload.groups.includes(group.name)
						) ?? [],
					help_provided: payload.help_provided,
					identity: newIdentity,
				};

				const updatedPreferences: Preferences = {
					event_reminder_time: payload.event_reminder_time,
					digest_subscription:
						payload.email_digest_subscription_status,
					announcement_subscription:
						payload.announcement_subscription_status,
					digest_frequency: buildDigestFrequency(
						payload.digest_frequency,
						payload.custom_day,
						payload.custom_days,
						payload.delivery_time
					),
				};

				queryClient.setQueryData(
					communityKeys.member(communityId, memberId),
					newMemberInfo
				);

				queryClient.setQueryData(
					subscriptionKeys.preference({ communityId, memberId }),
					updatedPreferences
				);

				queryClient.setQueryData<Identity>(
					authenticationKeys.identity,
					newIdentity
				);
			} else if (payload.type === 'same' && originalCommunityMember) {
				const newIdentity: Identity = {
					...originalCommunityMember.identity,
					first_name: payload.first_name,
					last_name: payload.last_name,
					phone: payload.phone,
					email: payload.email,
				};

				const newMemberInfo: CommunityMember = {
					...originalCommunityMember,
					help_provided: payload.help_provided,
					identity: newIdentity,
				};

				const updatedPreferences: Preferences = {
					event_reminder_time: payload.event_reminder_time,
					digest_subscription:
						payload.email_digest_subscription_status,
					announcement_subscription:
						payload.announcement_subscription_status,
					digest_frequency: buildDigestFrequency(
						payload.digest_frequency,
						payload.custom_day,
						payload.custom_days,
						payload.delivery_time
					),
				};

				queryClient.setQueryData(
					communityKeys.member(communityId, memberId),
					newMemberInfo
				);

				queryClient.setQueryData(
					subscriptionKeys.preference({ communityId, memberId }),
					updatedPreferences
				);

				queryClient.setQueryData<Identity>(
					authenticationKeys.identity,
					newIdentity
				);
			} else if (payload.type === 'admin' && originalCommunityMember) {
				const newMemberInfo: CommunityMember = {
					...originalCommunityMember,
					administrative_notes: payload.administrative_notes,
					groups:
						community?.groups.all.filter(group =>
							payload.groups.includes(group.name)
						) ?? [],
					help_provided: payload.help_provided,
				};

				queryClient.setQueryData(
					communityKeys.member(communityId, memberId),
					newMemberInfo
				);
			}

			return {
				communityId,
				memberId,
			};
		},
		onSettled: (_data, _error, variables) => {
			queryClient.invalidateQueries(
				communityKeys.member(variables.communityId, variables.memberId)
			);

			if (
				variables.payload.type === 'same' ||
				variables.payload.type === 'sameAndAdmin'
			) {
				queryClient.invalidateQueries(
					subscriptionKeys.preference({
						communityId: variables.communityId,
						memberId: variables.memberId,
					})
				);

				queryClient.invalidateQueries(authenticationKeys.identity);

				if (variables.payload.type === 'sameAndAdmin') {
					queryClient.invalidateQueries(
						permissionKeys.list(variables.communityId)
					);
				}
			}
		},
		retry: 1,
	});

	queryClient.setMutationDefaults(patchCommunityMemberMutationKey, mutation);
};

export const isPatchCommunityMemberMutation = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	mutation: Mutation<any, any, any, any>
) =>
	[patchCommunityMemberMutationKey].includes(
		String(mutation?.options?.mutationKey)
	);
