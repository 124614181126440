import {
	AlbumForm,
	AlbumFormInitialValues,
	AlbumFormSubmittedValues,
} from 'components/albums';
import { Box, PageHeading, Paper } from 'components/common';
import { useVisibilityFormData } from 'components/community/hooks';
import { usePostAlbum } from 'core/uses-cases/albums';
import {
	useParams,
	useRouter,
	useServices,
	useSubmitHandlerForPausedMutation,
	useTranslation,
} from 'hooks';

export const CreateAlbum = () => {
	const { t } = useTranslation();
	const router = useRouter();
	const { communityId } = useParams() as { communityId: string };
	const { visibilityData, groups } = useVisibilityFormData();
	const { mutateAsync, isPaused } = usePostAlbum();
	const { notification } = useServices();

	const handleOnSubmit =
		useSubmitHandlerForPausedMutation<AlbumFormSubmittedValues>(
			isPaused,
			values =>
				mutateAsync({
					...values,
					communityId,
				}),
			{
				onSuccess: ({ values }) => {
					notification.show(
						'success',
						t('albums.create.album-was-successfully-created', {
							name: values.name,
						})
					);

					router.navigate('community.albums', {
						communityId,
					});
				},
				onError: () => {
					notification.show(
						'error',
						t('albums.create.problem-creating-album')
					);
				},
			},
			[communityId]
		);

	return (
		<Box>
			<PageHeading title={t('album-form.create-album')} />
			<Box component={Paper} elevation={0} mt={6} px={5} py={5}>
				<AlbumForm
					onSubmit={handleOnSubmit}
					submitText={t('album-form.create-button')}
					submittingText={t('album-form.creating-album')}
					groups={groups}
					initialValues={visibilityData as AlbumFormInitialValues}
					edit={false}
				/>
			</Box>
		</Box>
	);
};
