import { SxProps } from '@mui/system';
import { Theme } from 'theme';

export const linksStyles: SxProps<Theme> = theme => ({
	'> a': {
		color: theme.primaryColors.primary6,
		textDecoration: 'none',
		'&.active': {
			color: theme.primaryColors.primary1,
		},
		'&:hover': {
			color: theme.primaryColors.primary1,
			textDecoration: 'none',
		},
	},
});

export const descriptionStyles: SxProps = {
	width: '50%',
	'@media (max-width: 835px)': {
		width: '100%',
	},
};
