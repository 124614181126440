import { useTranslation } from 'react-i18next';
import { useCalendar } from 'components/calendar/context';
import {
	ShowMoreLessHoursButton,
	useGetHoursRange,
} from 'components/calendar/shared';
import { calendarUtils } from 'components/calendar/utils';
import { Box, Grid, Typography } from 'components/common';
import { useCallback, useEffect } from 'react';
import { CalendarNavigation } from '../../shared/navigation';
import { TimeRow } from './row';
import { dayHeaderContainerStyle } from './styles';

export const Day = () => {
	const { t } = useTranslation();

	const { displayed, setDisplayed, timezone, formatDate, loadEvents } =
		useCalendar();

	const { hours, showLessTime, toggle } = useGetHoursRange(timezone);

	const handleOnPressNext = useCallback(() => {
		setDisplayed(displayed.plus({ day: 1 }));
	}, [displayed]);

	const handleOnPressPrev = useCallback(() => {
		setDisplayed(displayed.minus({ day: 1 }));
	}, [displayed]);

	useEffect(() => {
		const startDate = calendarUtils.getFirstDayOfWeek(
			displayed.minus({ weeks: 1 })
		);
		const endDate = calendarUtils.getLastDayOfWeek(
			displayed.plus({ weeks: 1 })
		);

		loadEvents({ startDate, endDate });
	}, [displayed]);

	return (
		<Box>
			<Box display='flex' alignItems='center'>
				<CalendarNavigation
					display={formatDate(displayed, 'DDD')}
					onNext={handleOnPressNext}
					onPrev={handleOnPressPrev}
				/>
			</Box>
			<Grid container item spacing={0} sx={dayHeaderContainerStyle}>
				<Grid item xs={12}>
					<Typography variant='body2'>
						{formatDate(displayed, 'EEEE')}
					</Typography>
				</Grid>
			</Grid>
			<Box>
				<Grid container spacing={0}>
					<Grid item xs={12} flexDirection='column'>
						<ShowMoreLessHoursButton
							onChange={toggle}
							value={showLessTime}
						/>
					</Grid>
					<TimeRow
						label={t('calendar-widget.all-day')}
						day={displayed}
					/>
					{hours.map(row => (
						<TimeRow
							key={`time-row-week-${row.startTime.toISO()}`}
							label={formatDate(
								row.startTime,
								'h a'
							).toLowerCase()}
							time={row.startTime}
							day={displayed}
						/>
					))}
				</Grid>
			</Box>
		</Box>
	);
};
