import { useServices } from 'core/services';
import { BaseHttpError } from 'core/services/api/errors';
import { TimezoneCountry } from 'core/types';
import { timezoneKeys } from 'core/utils/query-key-factory';
import { useQuery } from 'react-query';

export const useGetTimezones = () => {
	const { api } = useServices();

	const timezoneQuery = useQuery<TimezoneCountry[], BaseHttpError>(
		timezoneKeys.list(),
		() => api.getTimezones().then(response => response.data),
		{
			staleTime: Infinity,
		}
	);

	return timezoneQuery;
};
