import {
	Box,
	CheckboxField,
	FancyTable,
	FormControlLabel,
	PaginatedList,
	Paper,
	Typography,
	ButtonRouterLink,
	Icon,
	TeamHeader,
	useMediaQuery,
} from 'components';
import {
	useCheckboxMultiSelectionHandler,
	useInfinityScrollPaginationHandler,
	useParams,
	useRouter,
	useServices,
	useSubmitHandlerForPausedMutation,
	useTranslation,
} from 'hooks';
import { InviteCard } from 'components/community/team';
import { Fragment, useMemo } from 'react';
import {
	usePatchPendingInvites,
	useGetPendingInvites,
} from 'core/uses-cases/community';
import { MembershipInvite } from 'core/types';
import {
	FooterActions,
	FooterActionButton,
} from 'components/common/footer-action';

export const PendingInvites = () => {
	const { t } = useTranslation();
	const router = useRouter();
	const { communityId } = useParams() as { communityId: string };
	const { mutateAsync, isPaused } = usePatchPendingInvites();
	const { notification } = useServices();
	const isLargeScreen = useMediaQuery.up('sm');

	const {
		data,
		isSuccess,
		isFetching,
		isFetchingNextPage,
		hasNextPage,
		fetchNextPage,
	} = useGetPendingInvites(communityId);

	const invites = useMemo(() => {
		if (!data?.pages) return [];

		return data.pages.reduce(
			(invites, page) => invites.concat(page),
			[] as MembershipInvite[]
		);
	}, [data]);

	const {
		selected,
		select,
		selectedSize,
		selectAll,
		isSelected,
		isAllSelected,
		isIndeterminate,
		showResend,
		clearAll,
	} = useCheckboxMultiSelectionHandler(invites);

	const handleOnSubmit = useSubmitHandlerForPausedMutation<
		'reinvite' | 'delete'
	>(
		isPaused,
		action => {
			let ids = selected;

			if (action === 'reinvite') {
				ids = selected
					.map(id => invites.find(item => item.uuid === id))
					.filter(item => item?.method === 'email')
					.map(item => item?.uuid) as string[];
			}

			return mutateAsync({
				communityId,
				invitationIds: ids,
				action,
			});
		},
		{
			onSuccess: ({ result }) => {
				clearAll();

				if (result?.errors?.length > 0) {
					notification.show('error', t('invites.must-wait'));
				} else {
					notification.show(
						'success',
						t('pending-requests.request-processed')
					);
				}
			},
		},
		[communityId, selected, clearAll, isPaused]
	);

	const { handleOnLoad, showMoreButton, setShowMoreButton } =
		useInfinityScrollPaginationHandler<MembershipInvite>({
			data,
			fetchNextPage,
			hasNextPage,
		});

	return (
		<>
			<TeamHeader
				title={t('team.community-members')}
				button={
					<ButtonRouterLink
						to={router.getPathFor('community.invite', {
							communityId,
						})}
						startIcon={
							<Icon name='add_circle_outline' fontSize='large' />
						}
						size='small'
					>
						{t('team.add-members')}
					</ButtonRouterLink>
				}
			/>
			{isSuccess && !isFetching && data.pages[0].length === 0 ? (
				<Box component={Paper} mt={2} px={4} py={4}>
					{t('invites.no-outstanding-invitations')}
				</Box>
			) : (
				<>
					<FancyTable
						renderColumns={{
							2: { xs: false },
							4: { xs: false, sm: false },
						}}
					>
						<FancyTable.Header>
							<FancyTable.Header.Cell>
								<FormControlLabel
									control={
										<CheckboxField
											CheckBoxProps={{
												indeterminate: isIndeterminate,
											}}
											value={isAllSelected}
											onChange={selectAll}
										/>
									}
									label={
										<Typography variant='button2'>
											{t('invites.recipient')}
										</Typography>
									}
								/>
							</FancyTable.Header.Cell>
							<FancyTable.Header.Cell
								title={t('invites.invited-by')}
							/>
							<FancyTable.Header.Cell
								title={t('invites.status')}
							/>
							<FancyTable.Header.Cell
								title={t('invites.date-sent')}
							/>
						</FancyTable.Header>
						<PaginatedList
							onReachThreshold={handleOnLoad}
							onLoadMore={fetchNextPage}
							isFetching={isFetching}
							isFetchingMore={isFetchingNextPage}
							hasMore={!!hasNextPage}
							showButton={showMoreButton}
							setShowButton={setShowMoreButton}
						>
							{invites.map((invite: MembershipInvite) => (
								<Fragment key={invite.uuid}>
									<InviteCard
										invite={invite}
										isSelected={isSelected(invite.uuid)}
										onSelect={select}
									/>
								</Fragment>
							))}
						</PaginatedList>
					</FancyTable>
					<FooterActions
						selectedCount={selectedSize}
						isLargeScreen={isLargeScreen}
					>
						{showResend && (
							<FooterActionButton
								icon='rotate_right'
								onClick={() => handleOnSubmit('reinvite')}
								color='primary'
							>
								{isLargeScreen && t('invites.reinvite')}
							</FooterActionButton>
						)}
						<FooterActionButton
							icon='delete_outline'
							onClick={() => handleOnSubmit('delete')}
						>
							{isLargeScreen && t('common.delete')}
						</FooterActionButton>
					</FooterActions>
				</>
			)}
		</>
	);
};
