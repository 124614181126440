import {
	Box,
	TextField,
	LoadingButton,
	Form,
	Field,
	Typography,
	PasswordField,
	PasswordRulesChecker,
	TelephoneField,
	FormOnSubmitReturn,
	FormStack,
	Button,
	Divider,
	Link,
} from 'components/common';
import { useTranslation } from 'hooks';
import { useCallback, useMemo } from 'react';
import { sleep } from 'utils/delay';
import { Trans, TFunction } from 'react-i18next';
import * as Yup from 'yup';
import { phoneNumber } from 'utils/validation';
import config from 'config';
import { buttonSectionStyle } from './styles';

export type RequestToJoinFormSubmittedValues = {
	firstName: string;
	lastName: string;
	phone: string;
	notes: string;
	password: string;
	email: string;
};

type RequestToJoinFormProps = {
	isLoggedIn: boolean;
	hasAccount: boolean;
	onBack: () => void;
	initialValues?: Partial<RequestToJoinFormSubmittedValues>;
	onSubmit?: (values: RequestToJoinFormSubmittedValues) => FormOnSubmitReturn;
	submitText?: string;
	submittingText?: string;
};

export const RequestToJoinForm = (props: RequestToJoinFormProps) => {
	const {
		isLoggedIn,
		hasAccount,
		onBack,
		initialValues,
		onSubmit,
		submitText,
		submittingText,
	} = props;
	const { t } = useTranslation();

	const handleOnSubmit = useCallback(
		async ({ phone, ...values }) => {
			await sleep(1);

			const result = onSubmit?.({
				...values,
				phone: phone ? `+${phone.dialCode}${phone.number}` : undefined,
			});

			return Promise.resolve(result).catch(error => error);
		},
		[onSubmit]
	);

	const validationSchema = useMemo(
		() => createValidationSchema(t, !isLoggedIn && !hasAccount),
		[isLoggedIn, hasAccount]
	);

	return (
		<Form
			onSubmit={handleOnSubmit}
			validationSchema={validationSchema}
			subscription={{ submitting: true }}
			initialValues={initialValues}
		>
			{({ handleSubmit, submitting }) => (
				<form onSubmit={handleSubmit} noValidate>
					<FormStack>
						{!hasAccount && (
							<>
								<Box>
									<Field
										label={t('common-label.first-name')}
										name='firstName'
										component={TextField}
										type='text'
										fullWidth
										required
									/>
								</Box>
								<Box>
									<Field
										label={t('common-label.last-name')}
										name='lastName'
										component={TextField}
										type='text'
										fullWidth
										required
									/>
								</Box>
								<Box>
									<Field
										label={t('common-label.phone')}
										name='phone'
										component={TelephoneField}
										type='text'
										placeholder='(201) 555-0123'
										fullWidth
										required
									/>
								</Box>
							</>
						)}
						<Box>
							<Field
								label={t('common-label.email')}
								name='email'
								component={(props: { value: string }) => (
									<Typography variant='body1'>
										<Trans
											i18nKey='request-to-join.email'
											values={{
												email: props.value,
											}}
											components={{
												bold: <b />,
											}}
										/>
									</Typography>
								)}
								required
							/>
						</Box>
						<Box>
							<Field
								label={t('request-to-join.information')}
								name='notes'
								component={TextField}
								placeholder={t(
									'request-to-join.information-placeholder'
								)}
								multiline
								rows={4}
								fullWidth
							/>
						</Box>
						{!isLoggedIn && (
							<Field
								label={t('common-label.password')}
								name='password'
								component={PasswordField}
								type='password'
								fullWidth
								required
							/>
						)}
						{!hasAccount && (
							<PasswordRulesChecker
								forField='password'
								usernameField='email'
								initialEmail={initialValues?.email || ''}
							/>
						)}
					</FormStack>
					<Divider mt={5} mb={2} />
					<FormStack>
						<Box>
							<Trans
								i18nKey='request-to-join.accept-message'
								components={{
									term: (
										<Link
											href={`${config.urls.wordPress}/terms`}
											target='_blank'
										/>
									),
								}}
							/>
						</Box>
						<Box sx={buttonSectionStyle}>
							<Button
								size='small'
								type='button'
								onClick={onBack}
								color='secondary'
							>
								{t('common.back')}
							</Button>
							<LoadingButton
								loading={submitting}
								loadingIndicator={
									submittingText || t('form.submitting')
								}
								size='small'
								type='submit'
							>
								{submitText || t('form.submit')}
							</LoadingButton>
						</Box>
					</FormStack>
				</form>
			)}
		</Form>
	);
};

function createValidationSchema(t: TFunction, verifyUserFields: boolean) {
	if (verifyUserFields) {
		return Yup.object().shape({
			firstName: Yup.string().required(t('form.generic-required')),
			lastName: Yup.string().required(t('form.generic-required')),
			email: Yup.string()
				.email(t('form.invalid-email'))
				.required(t('form.generic-required')),
			phone: phoneNumber(t('form.invalid-phone')).required(
				t('form.generic-required')
			),
		});
	}

	return Yup.object().shape({});
}
