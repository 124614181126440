import { useState, Fragment } from 'react';
import { usePostComment } from 'core/uses-cases/discussion';
import { useSubmitHandlerForPausedMutation, useTranslation } from 'hooks';
import { Box, Paper, Typography } from 'components/common';
import { CommentForm, CommentFormValues } from './comment-form';
import { CommentList } from './comment-list';
import { useDiscussion } from './context';
import { commentWrapperStyle } from './styles';

export type CommentAppProps = {
	title?: string | null;
	enabledToggler?: boolean;
	withPaper?: boolean;
};

export const CommentApp = (props: CommentAppProps) => {
	const { t } = useTranslation();

	const {
		title = t('comment-widget.comments'),
		enabledToggler = true,
		withPaper = true,
	} = props;

	const [showWidget, setShowWidget] = useState(true);

	const { discussionId, isTogglerEnabled } = useDiscussion();

	const { mutateAsync, isPaused } = usePostComment();

	const handleOnSubmit = useSubmitHandlerForPausedMutation<CommentFormValues>(
		isPaused,
		values => mutateAsync({ discussionId, ...values }),
		{},
		[discussionId]
	);

	return (
		<Fragment>
			{title && (
				<Box mb={1}>
					<Typography variant='h6'>{title}</Typography>
				</Box>
			)}
			<Box
				component={withPaper ? Paper : 'div'}
				elevation={0}
				p={0}
				mt={withPaper ? 1 : 3}
			>
				<Box
					display={showWidget ? 'block' : 'none'}
					p={withPaper ? 3 : 0}
				>
					<CommentForm onSubmit={handleOnSubmit} />
					<CommentList />
				</Box>
				{enabledToggler && isTogglerEnabled && (
					<Box
						onClick={() => setShowWidget(!showWidget)}
						sx={commentWrapperStyle}
					>
						<Typography>
							{showWidget
								? t('comment-widget.hide-comments')
								: t('comment-widget.show-comments')}
						</Typography>
					</Box>
				)}
			</Box>
		</Fragment>
	);
};
