import { BaseHttpError } from 'core/services/api/errors';
import { Album } from 'core/types';
import { PaginatedRecords } from 'core/utils/optimistic-utils';
import { albumKeys } from 'core/utils/query-key-factory';
import { useServices } from 'hooks';
import { useCallback } from 'react';
import { useQuery, useQueryClient, onlineManager } from 'react-query';

export const useGetAlbum = (
	communityId: string,
	albumId: string,
	options: { enable: boolean } = { enable: true }
) => {
	const { api, authentication } = useServices();

	const isLoggedIn = authentication.isAuthenticated();

	const queryClient = useQueryClient();

	const getInitialData = useCallback(() => {
		let album;

		// If it is online, then fetch the announcement from the API
		if (onlineManager.isOnline()) return album;

		const result = queryClient.getQueryData<PaginatedRecords<Album>>(
			albumKeys.list(communityId)
		);

		for (const page of result?.pages ?? []) {
			album = page.data.find(record => record.uuid === albumId);
			if (album) break;
		}

		return album;
	}, [albumId]);

	const albumQuery = useQuery<Album, BaseHttpError>(
		albumKeys.detail(communityId, albumId),
		() =>
			api.getAlbum(communityId, albumId).then(response => response.data),
		{
			enabled: !!albumId && isLoggedIn && options.enable,
			initialData: getInitialData,
		}
	);

	return albumQuery;
};
