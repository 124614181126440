import {
	useLogout,
	useSetCurrentIdentity,
} from 'core/uses-cases/authentication';
import { useSearchParams, useServices } from 'hooks';
import { useEffect, useState } from 'react';
import { getCommunityIdFromLocation } from 'utils/app';

export const useAutoLoginHandler = () => {
	const [params] = useSearchParams();
	const [isChecking, setIsChecking] = useState(true);
	const tokenId = params.get('auth') ?? '';
	const communityId = getCommunityIdFromLocation();
	const { authentication, api } = useServices();
	const setCurrentIdentity = useSetCurrentIdentity();
	const { doLogout } = useLogout();

	useEffect(() => {
		setIsChecking(true);

		if (!tokenId || !communityId) {
			setIsChecking(false);
		} else {
			(async () => {
				try {
					const { access_token, identity } = await api
						.getAutoLoginToken(communityId, tokenId)
						.then(response => response.data);

					if (authentication.isAuthenticated()) {
						doLogout({ keepCurrentPageAfterLogout: true });
					}

					setCurrentIdentity(access_token, identity);
				} finally {
					setIsChecking(false);
				}
			})();
		}
	}, [tokenId, communityId]);

	return { isChecking };
};
