import { SetupMutationFn } from 'core/configure/types';
import { BaseHttpError } from 'core/services/api/errors';
import { Nullable, Community, Group, CommunityMember } from 'core/types';
import { communityKeys, permissionKeys } from 'core/utils/query-key-factory';
import { isCancelledError, Mutation, useMutation } from 'react-query';
import {
	putCommunityGroupMutationKey,
	postCommunityGroupMutationKey,
} from 'core/utils/mutation-key-factory';
import {
	onPatchMutateOptimisticInfinityQueryCache,
	cancelPreviousMutation,
	onSuccessOptimisticInInfinityQueryCache,
	findRecordFromInfinityQueryCache,
	PaginatedRecordsSnapshot,
} from 'core/utils/optimistic-utils';
import { mapVisibilityWithGroups } from 'core/utils/mapper';
import { GroupInput } from '../../services/api/api-client/types';

export interface PutCommunityGroupMutationInput extends GroupInput {
	communityId: string;
	groupId: string;
	isOffline: Nullable<boolean>;
}

export const usePutCommunityGroup = () => {
	const mutation = useMutation<
		Group,
		BaseHttpError,
		PutCommunityGroupMutationInput
	>(putCommunityGroupMutationKey);

	return mutation;
};

type MutationContext = {
	communityId: string;
	groupId: string;
	updatedGroup: Group;
	listSnapshot: PaginatedRecordsSnapshot<Group>;
};

export const setupPutCommunityGroup: SetupMutationFn = (
	services,
	createTrackedParallelMutation,
	mutationTracker
) => {
	const { queryClient, api } = services;

	const mutation = createTrackedParallelMutation<
		Group,
		BaseHttpError,
		PutCommunityGroupMutationInput,
		MutationContext
	>({
		mutationFn: async ({ groupId, communityId, isOffline, ...input }) => {
			// If created dt is given, we need to set the call to be an update
			const actionCall = !isOffline
				? api.putGroup.bind(api, communityId, groupId)
				: api.postGroup.bind(api, communityId);

			return actionCall(input).then(response => response.data);
		},
		onMutate: async input => {
			const {
				groupId,
				communityId,
				name,
				members: memberIds,
				visibility,
				visibilityGroups,
				management,
				managementGroups,
				openEnrollment,
				isOffline,
			} = input;

			const community = queryClient.getQueryData<Community>(
				communityKeys.detail(communityId)
			);

			const communityMembers = queryClient.getQueryData<
				CommunityMember[]
			>(communityKeys.members(communityId));

			const members = memberIds.map(memberId => {
				const member = communityMembers?.find(
					m => m.identity.uuid === memberId
				);

				return {
					uuid: member?.identity.uuid ?? '',
					first_name: member?.identity.first_name ?? '',
					last_name: member?.identity.last_name ?? '',
					email: member?.identity.email ?? '',
				};
			});

			const currentGroup = queryClient.getQueryData<Group>(
				communityKeys.group(communityId, groupId)
			) as Group;

			//  optimistic Group
			const updatedGroup: Group = {
				...currentGroup,
				name,
				description: name,
				members,
				has_open_enrollment: openEnrollment,
				who_can_see: mapVisibilityWithGroups(
					visibility,
					visibilityGroups,
					community?.groups.all ?? []
				),
				managers: mapVisibilityWithGroups(
					management,
					managementGroups,
					community?.groups.all ?? []
				),
				is_offline: !!isOffline,
			};

			// Cancel the previous mutation if it exists
			await cancelPreviousMutation<MutationContext>(
				queryClient,
				mutation => {
					return (
						mutation.state.context?.groupId === groupId &&
						isPostPutCommunityGroupMutation(mutation)
					);
				}
			);

			const { listSnapshot } =
				findRecordFromInfinityQueryCache<Group>(
					queryClient,
					communityKeys.groups(communityId),
					groupId
				) ?? {};

			await onPatchMutateOptimisticInfinityQueryCache<Group>(
				queryClient,
				communityKeys.groups(communityId),
				communityKeys.group(communityId, groupId),
				updatedGroup
			);

			return {
				communityId,
				groupId,
				updatedGroup,
				listSnapshot,
			};
		},
		onSuccess: (result, input, { communityId, groupId }) => {
			onSuccessOptimisticInInfinityQueryCache<Group>(
				queryClient,
				communityKeys.groups(communityId),
				communityKeys.group(communityId, groupId),
				groupId,
				result
			);
		},
		onError: (error, input, context) => {
			if (context?.listSnapshot && !isCancelledError(error)) {
				queryClient.setQueryData(
					communityKeys.groups(context.communityId),
					context?.listSnapshot
				);
			}
		},
		onSettled: (data, error, variables, context) => {
			if (context && !isCancelledError(error)) {
				mutationTracker.queueInvalidations(
					communityKeys.groups(context.communityId),
					permissionKeys.all(context.communityId)
				);
			}
		},
		retry: 1,
	});

	queryClient.setMutationDefaults(putCommunityGroupMutationKey, mutation);
};

export const isPostPutCommunityGroupMutation = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	mutation: Mutation<any, any, any, any>
) =>
	[putCommunityGroupMutationKey, postCommunityGroupMutationKey].includes(
		String(mutation?.options?.mutationKey)
	);
