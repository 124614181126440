import config from 'config';
import { Event, EventSetting } from 'core/types';
import { useCurrentIdentity } from 'core/uses-cases/authentication';
import { useCommunityMembers } from 'core/uses-cases/member';
import { getEventDateTimes } from 'core/utils/calender-events';
import { useCurrentCommunity } from 'hooks';
import { useMemo } from 'react';
import { formatDate } from 'utils/dates';
import { EventFormInitialValues } from '../form';

export const useEventFormData = (record?: Event) => {
	const community = useCurrentCommunity();
	const identity = useCurrentIdentity();

	const groups = useMemo(() => {
		return (
			community?.groups.all.filter(
				group =>
					!(group.name === 'member' || group.name === 'site_admin')
			) ?? []
		);
	}, [community]);

	const members = useCommunityMembers(community?.slug as string);

	const identities = members?.map(({ identity }) => identity) ?? [];

	const getVisibilityAndSelectedGroups = (
		setting: EventSetting
	): [EventSetting['privacy'], string[]] => {
		const visibility = setting.privacy;

		let groups: string[] = [];
		if (visibility === 'custom') {
			groups = setting.groups.map(group => group.id);
		}

		return [visibility, groups];
	};

	const initialValues = useMemo(() => {
		if (!record) {
			return {
				coordinators: [identity?.uuid],
				visibility: 'members',
				volunteer: 'members',
				timezone: identity?.timezone ?? undefined,
			} as EventFormInitialValues;
		}

		const [visibility, visibilityGroups] = getVisibilityAndSelectedGroups(
			record.settings.visibilitySettings
		);

		const [volunteer, volunteerGroups] = getVisibilityAndSelectedGroups(
			record.settings.volunteerSettings
		);

		const { startDate, startDateTime, endDateTime } =
			getEventDateTimes(record);

		const values: EventFormInitialValues = {
			name: record.name,
			type: record.type,
			description: record.description,
			coordinators: (record.coordinators ?? []).map(
				coordinator => coordinator.uuid
			),
			dates: [
				formatDate(
					startDateTime ?? startDate,
					'yyyy-MM-dd',
					startDateTime
						? record.user_timezone ?? config.defaultTimezone
						: undefined
				),
			],
			startTimes: startDateTime
				? [
					formatDate(
						startDateTime,
						'HH:mm:ss',
						record.user_timezone ?? config.defaultTimezone
					),
				  ]
				: undefined, // prettier-ignore
			endTimes: endDateTime ? [
				formatDate(
					endDateTime,
					'HH:mm:ss',
					record.user_timezone ?? config.defaultTimezone
				),
			] : undefined, // prettier-ignore
			attachments: record.asset_collection?.assets ?? [],
			image: record?.image ? record.image : null,
			volunteersNeeded: record.volunteers_needed,
			timezone: record.user_timezone ?? undefined,
			locations: record.locations,
			volunteers: (record.volunteers ?? []).map(
				volunteer => volunteer.uuid
			),
			visibility,
			visibilityGroups,
			volunteer,
			volunteerGroups,
		};

		return values;
	}, [record]);

	return {
		community,
		initialValues: initialValues,
		groups,
		identities,
	};
};
