import { getCurrentTenant } from 'tenants/utils';
import config from 'config';

export const LandingPage = () => {
	const tenant = getCurrentTenant() || '';

	const source =
		config.wp[tenant as keyof typeof config.wp] || config.wp['lotsa'];

	return (
		<iframe
			title='External Content'
			src={source}
			style={{ width: '100%', height: '100vh', border: 'none' }}
			allowFullScreen
		/>
	);
};

export default LandingPage;
