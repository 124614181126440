import React from 'react';
import 'requestidlecallback';

interface DeferProps {
	chunkSize: number;
}

/**
 * based on this article https://itnext.io/improving-slow-mounts-in-react-apps-cff5117696dc
 * window.requestIdleCallback is not supported in all browsers
 * Find alternatives here https://www.npmjs.com/package/requestidlecallback
 */
export const Defer: React.FC<DeferProps> = ({ chunkSize, children }) => {
	const [renderedItemsCount, setRenderedItemsCount] =
		React.useState(chunkSize);

	const childrenArray = React.useMemo(
		() => React.Children.toArray(children),
		[children]
	);

	React.useEffect(() => {
		if (renderedItemsCount < childrenArray.length) {
			requestIdleCallback(() => {
				setRenderedItemsCount(
					Math.min(
						renderedItemsCount + chunkSize,
						childrenArray.length
					)
				);
			});
		}
	}, [
		renderedItemsCount,
		setRenderedItemsCount,
		childrenArray.length,
		chunkSize,
	]);

	return <>{childrenArray.slice(0, renderedItemsCount)}</>;
};
