import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { Button, Box } from 'components/common';
import { MouseEventHandler } from 'react';
import { useTranslation } from 'hooks';
import { uploadButtonStyle, iconStyle } from './style';

type DefaultButtonProps = {
	open: MouseEventHandler<HTMLButtonElement>;
	buttonSize: 'small' | 'medium' | 'large' | undefined;
	fileCount: number;
};

export const UploadButton = ({
	open,
	buttonSize,
	fileCount,
}: DefaultButtonProps) => {
	const { t } = useTranslation();

	return (
		<Button
			sx={uploadButtonStyle}
			type='button'
			variant='outlined'
			onClick={open}
			size={buttonSize}
		>
			<Box display='flex' flexDirection='column' alignItems='center'>
				<AddCircleOutlineOutlinedIcon sx={iconStyle} />
				{fileCount
					? t('photos.add-another-photo')
					: t('photos.add-a-photo')}
			</Box>
		</Button>
	);
};
