import MuiAlert, { AlertProps as MuiAlertProps } from '@mui/material/Alert';
import { useEffect } from 'react';

export interface AlertProps extends MuiAlertProps {
	timeout?: number | null;
}

export function Alert(props: AlertProps) {
	const { timeout = 5000, onClose, ...rest } = props;

	useEffect(() => {
		let timeoutId: any;

		if (timeout) {
			timeoutId = setTimeout(() => {
				onClose?.({} as React.SyntheticEvent<Element, Event>);
			}, timeout);
		}

		return () => clearTimeout(timeoutId);
	}, []);

	return <MuiAlert {...rest} className='alert' onClose={onClose} />;
}
