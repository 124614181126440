import {
	Box,
	Divider,
	Paper,
	Typography,
	Grid,
	RouterLink,
	Link,
} from 'components';
import config from 'config';
import { useRouter, useTranslation } from 'hooks';
import { Trans } from 'react-i18next';

export const ResetPasswordSuccess = () => {
	const { t } = useTranslation();
	const router = useRouter();

	return (
		<Grid container justifyContent='center' textAlign='center'>
			<Grid item xs={12} sm={5}>
				<Box component={Paper} p={3}>
					<Typography variant='h5'>
						{t('reset-password.forgot-password')}
					</Typography>
					<Box mt={3}>
						<Typography>
							{t('reset-password.success-message')}
						</Typography>
					</Box>
					<Divider mt={5} />
					<Box mt={5}>
						<Typography variant='body1'>
							<Trans
								i18nKey='reset-password.no-account-message'
								components={{
									search: (
										<RouterLink
											to={router.getPathFor(
												'community.search'
											)}
										/>
									),
									create: (
										<RouterLink
											to={router.getPathFor(
												'community.create'
											)}
										/>
									),
								}}
							/>
						</Typography>
						<Typography variant='body1' mt={2}>
							<Trans
								i18nKey='reset-password.having-trouble'
								components={{
									mail: (
										<Link
											href={`mailto:${config.supportEmail}`}
										/>
									),
								}}
							/>
						</Typography>
					</Box>
				</Box>
			</Grid>
		</Grid>
	);
};
