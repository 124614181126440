import { DateTime } from 'luxon';
import { Box, FormControl, FormHelperText } from 'components/common';
import { useControlledValue } from 'hooks';
import { TextFieldProps } from '../text-field';
import { DatePickerField, DatePickerFieldProps } from './date-picker';

export type InnerRangeValue = {
	startDate: DateTime | null;
	endDate: DateTime | null;
};

type CleanTextFieldProps = Omit<
	TextFieldProps,
	| 'value'
	| 'onChange'
	| 'multiline'
	| 'type'
	| 'rows'
	| 'maxRows'
	| 'minRows'
	| 'select'
	| 'SelectProps'
	| 'type'
	| 'defaultValue'
	| 'onError'
>;

type DateRangePickerFieldProps = Omit<
	DatePickerFieldProps,
	'value' | 'onChange'
> &
	CleanTextFieldProps & {
		startFieldProps?: CleanTextFieldProps;
		endFieldProps?: CleanTextFieldProps;
		value: InnerRangeValue;
		onChange: (value: InnerRangeValue) => void;
	};

export const DateRangePickerField = (props: DateRangePickerFieldProps) => {
	const {
		error,
		fullWidth,
		disabled,
		margin,
		required,
		size,
		sx,
		helperText,
		onFocus,
		onBlur,
		value,
		onChange,
		startFieldProps,
		endFieldProps,
		meta,
	} = props;

	const [values, setValues] = useControlledValue<InnerRangeValue>(
		value,
		onChange
	);

	const handleChange = (name: string, value: DateTime | null) => {
		setValues({ ...values, [name]: value });
	};

	const { startDate, endDate } = values;

	return (
		<FormControl {...{ fullWidth, error, margin, required, size, sx }}>
			<Box display='flex'>
				<DatePickerField
					value={startDate}
					onChange={value => {
						handleChange('startDate', value);
					}}
					onFocus={onFocus}
					error={!!(meta?.touched && meta.error?.startDate)}
					onBlur={onBlur}
					helperText={meta?.touched && meta.error?.startDate}
					fullWidth={fullWidth}
					size={size}
					disabled={disabled}
					{...startFieldProps}
				/>
				<Box sx={{ mx: 1 }}> - </Box>
				<DatePickerField
					value={endDate}
					onChange={value => handleChange('endDate', value)}
					onFocus={onFocus}
					error={!!(meta?.touched && meta.error?.endDate)}
					onBlur={onBlur}
					helperText={meta?.touched && meta.error?.endDate}
					fullWidth={fullWidth}
					size={size}
					disabled={disabled}
					{...endFieldProps}
				/>
			</Box>
			{helperText && <FormHelperText>{helperText}</FormHelperText>}
		</FormControl>
	);
};
