import { SxProps } from '@mui/system';
import { Theme } from 'theme';

export const inputStyle: SxProps<Theme> = theme => ({
	maxWidth: '100%',
	display: 'flex',
	flex: '1 0 auto',
	flexWrap: 'wrap',
	alignContent: 'flex-start',
	alignItems: 'flex-start',
	position: 'relative',
	padding: theme.spacing(0, 2),
	borderRadius: '4px',
	borderColor: theme.palette.text.primary,
	borderWidth: '1px',
	borderStyle: 'solid',
	fontSize: 16,
	lineHeight: '24px',
	outline: 'none',

	'> span.placeholder': {
		display: 'none',
		position: 'absolute',
		left: theme.spacing(2),
		top: theme.spacing(1.2),
		color: theme.secondaryColors.secondary1,
		opacity: 1,
	},

	'&.empty': {
		'> span.placeholder': {
			display: 'block',
		},
	},

	'&.error': {
		borderColor: theme.palette.error.main,
	},

	'&.focused': {
		borderColor: theme.palette.text.primary,
		borderStyle: 'dashed',

		'&.error': {
			borderColor: theme.palette.error.main,
		},

		'> span.placeholder': {
			display: 'none',
		},
	},

	'&.disabled': {
		backgroundColor: theme.palette.common.white,
	},

	'> .MuiInputBase-root': {
		flex: 1,
		width: 'auto',
		minWidth: 90,
		outline: 'none',
		border: 'none',
		display: 'inline-block',
		verticalAlign: 'baseline',

		input: {
			flex: 1,
			width: '100%',
			outline: 'none',
			border: 'none',
			display: 'inline-block',
			verticalAlign: 'baseline',
			padding: theme.spacing(1.5, 0),
			fontFamily: theme.typography.fontFamily,
			fontSize: theme.typography.body2.fontSize,
			lineHeight: theme.typography.body2.lineHeight,
		},
	},

	'+ .MuiFormHelperText-root': {
		marginTop: theme.spacing(1),
	},
});
