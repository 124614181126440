import {
	PageHeading,
	TextField,
	Typography,
	Field,
	Grid,
	Box,
	Paper,
	Stack,
	Form,
	LoadingButton,
} from 'components';
import * as Yup from 'yup';
import { useCurrentIdentity } from 'core/uses-cases/authentication';
import { useTranslation } from 'react-i18next';
import { useRequestDeletion } from 'core/uses-cases/authentication/use-request-deletion';
import { useRouter } from 'hooks';

type DeleteAccountFormValues = {
	email: string;
};

const validationSchema = Yup.object().shape({
	email: Yup.string()
		.strict(false)
		.trim()
		.email('Please enter a valid email address')
		.required('Please enter an email address'),
});

export const DeleteAccount = () => {
	const identity = useCurrentIdentity();
	const { t } = useTranslation();
	const router = useRouter();
	const { doRequestDeletionAsync } = useRequestDeletion();

	const email = identity?.email;

	const initialValues = {
		email,
	};

	const handleOnSubmit = (values: DeleteAccountFormValues) => {
		return doRequestDeletionAsync(values, {
			onSuccess: () => {
				router.navigate('account.delete-email-sent');
			},
		});
	};

	return (
		<Box>
			<PageHeading title={t('delete-account.title')}>
				<Typography variant='body2' mb={1}>
					{t('delete-account.description')}
				</Typography>
				<Typography variant='body2' mb={1}>
					{t('delete-account.description-two')}
				</Typography>
			</PageHeading>
			<Grid container justifyContent='center'>
				<Grid item md={4} xl={4} sm={8} xs={12}>
					<Box
						component={Paper}
						px={4.5}
						py={3}
						elevation={0}
						display='flex'
						flexDirection='column'
					>
						<Form
							onSubmit={handleOnSubmit}
							validationSchema={validationSchema}
							subscription={{ submitting: true }}
							initialValues={initialValues}
						>
							{({ handleSubmit, submitting }) => (
								<form onSubmit={handleSubmit} noValidate>
									<Stack spacing={3} alignContent='center'>
										<Field
											name='email'
											type='email'
											label={t('delete-account.email')}
											placeholder={t(
												'delete-account.email-placeholder'
											)}
											component={TextField}
											disabled={!!email}
										/>
										<LoadingButton
											loading={submitting}
											loadingIndicator={t(
												'delete-account.deleting-account'
											)}
											type='submit'
											size='small'
										>
											{t('delete-account.delete-account')}
										</LoadingButton>
									</Stack>
								</form>
							)}
						</Form>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
};
