export const DEFAULT_VIEW = 'month';

export const COLORS = {
	helpNeeded: '#EC368D',
	needsMet: '#BBBBBB',
	occasions: '#3278DE',
};

export const FILTER_STATUSES = {
	HELP_NEEDED: 'help_needed',
	NEEDS_MET: 'needs_met',
	OCCASIONS: 'occasions',
};
