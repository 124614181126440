import { styled } from '@mui/material/styles';
import MuiTooltip, {
	TooltipProps,
	tooltipClasses,
} from '@mui/material/Tooltip';

export const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
	<MuiTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => {
	return {
		[`& .${tooltipClasses.tooltip}`]: {
			maxWidth: 232,
			backgroundColor: theme.primaryColors.primary1,
			fontFamily: 'proxima_nova_regular, Arial, sans-serif',
			color: theme.primaryColors.primary8,
			fontSize: '.875rem',
			lineHeight: '1.25rem',
		},
		[`& .${tooltipClasses.arrow}`]: {
			color: theme.primaryColors.primary1,
		},
	};
});

export const Tooltip = MuiTooltip;

export { tooltipClasses };

export type { TooltipProps };
