import { Group, GroupPermissions, Identity } from 'core/types';
import {
	Box,
	CheckboxField,
	Field,
	Form,
	FormOnSubmitReturn,
	FormStack,
	LoadingButton,
	SelectMultipleField,
	SelectMultipleOptions,
	TextField,
	VisibilityField,
	visibilityFieldValidationSchema,
	VisibilityFieldValues,
} from 'components/common';
import { useTranslation } from 'hooks';
import { TFunction } from 'react-i18next';
import * as Yup from 'yup';
import { useCallback, useMemo, useRef } from 'react';
import { sleep } from 'utils/delay';
import { getDefaultGlobalPermissions } from 'core/uses-cases/community';

export type GroupFormSubmittedValues = {
	name: string;
	members: string[];
	openEnrollment: boolean;
	permissions?: GroupPermissions[];
} & VisibilityFieldValues &
	VisibilityFieldValues<{
		management: VisibilityFieldValues['visibility'];
		managementGroups: VisibilityFieldValues['visibilityGroups'];
	}>;

export type GroupFormInitialValues = Partial<GroupFormSubmittedValues>;

export type GroupFormProps = {
	onSubmit?: (values: GroupFormSubmittedValues) => FormOnSubmitReturn;
	submitText?: string;
	submittingText?: string;
	identities: Identity[];
	groups: Group[];
	initialValues?: GroupFormInitialValues;
	showPermissionsField?: boolean;
};

export const GroupForm = (props: GroupFormProps) => {
	const {
		onSubmit,
		submitText,
		submittingText,
		identities,
		groups,
		initialValues,
		showPermissionsField = false,
	} = props;

	const { t } = useTranslation();

	const cachedInitialValues = useRef({
		members: [],
		openEnrollment: false,
		groupName: initialValues?.name || '',
		...initialValues,
	}).current;

	const handleOnSubmit = useCallback(
		async ({
			groupName,
			members,
			visibility,
			visibilityGroups,
			management,
			managementGroups,
			openEnrollment,
			permissions,
		}) => {
			await sleep(1);

			const values = {
				name: groupName,
				members,
				visibility,
				openEnrollment,
				visibilityGroups:
					visibility === 'custom' ? visibilityGroups : [],
				management,
				managementGroups:
					management === 'custom' ? managementGroups : [],
				...(permissions ? { permissions } : {}),
			};

			const result = onSubmit?.(values);

			return Promise.resolve(result).catch(error => error);
		},
		[onSubmit]
	);

	const validationSchema = useMemo(() => createValidationSchema(t), []);

	const identityOptions = useMemo(
		() =>
			identities.map(({ uuid, first_name, last_name }) => ({
				label: `${first_name} ${last_name}`,
				value: uuid,
			})),
		[identities]
	);

	const permissionsOptions = useMemo(() => {
		const defaultPermissions = getDefaultGlobalPermissions();

		const options: SelectMultipleOptions<string>[] = [];
		Object.entries(defaultPermissions).forEach(([key, permissions]) => {
			Object.entries(permissions).forEach(([permission]) => {
				options.push({
					label: t(`advance-permissions.labels.${key}.${permission}`),
					value: `${key}:${permission}`,
				});
			});
		});
		return options;
	}, []);

	return (
		<Form
			onSubmit={handleOnSubmit}
			validationSchema={validationSchema}
			subscription={{ submitting: true }}
			initialValues={cachedInitialValues}
		>
			{({ handleSubmit, submitting }) => (
				<form onSubmit={handleSubmit} noValidate>
					<FormStack>
						<Box>
							<Field
								label={t('group-form.name')}
								name='groupName'
								component={TextField}
								type='text'
								fullWidth
								required
							/>
						</Box>
						<Box>
							<Field
								name='members'
								component={SelectMultipleField}
								options={identityOptions}
								label={t('group-form.members')}
								loading={identityOptions.length === 0}
							/>
						</Box>
						{showPermissionsField && (
							<Box>
								<Field
									name='permissions'
									component={SelectMultipleField}
									options={permissionsOptions}
									label={t('group-form.permission-options')}
								/>
							</Box>
						)}
						<Box>
							<VisibilityField
								name='visibility'
								groups={groups}
								useAdminAndMembersOptions
								label={t('visibility-field.who-can-see-this')}
							/>
						</Box>
						<Box>
							<VisibilityField
								name='management'
								groups={groups}
								enableEveryoneOption={false}
								label={t(
									'visibility-field.who-can-manage-this-group'
								)}
							/>
						</Box>
						<Box>
							<Field
								label={t('group-form.open-membership')}
								name='openEnrollment'
								component={CheckboxField}
							/>
						</Box>
						<Box>
							<LoadingButton
								loading={submitting}
								loadingIndicator={
									submittingText ||
									t('group-form.creating-group')
								}
								size='small'
								type='submit'
							>
								{submitText || t('group-form.create-group')}
							</LoadingButton>
						</Box>
					</FormStack>
				</form>
			)}
		</Form>
	);
};

function createValidationSchema(t: TFunction) {
	return Yup.object()
		.shape({
			groupName: Yup.string()
				.strict(false)
				.trim()
				.required(t('form.generic-required')),
		})
		.concat(visibilityFieldValidationSchema('visibility'))
		.concat(visibilityFieldValidationSchema('management'));
}
