import { isCancelledError, Mutation, useMutation } from 'react-query';
import { Event, CommunityMember } from 'core/types';
import { SetupMutationFn } from 'core/configure/types';
import { calendarKeys, communityKeys } from 'core/utils/query-key-factory';
import { postVolunteerMutationKey } from 'core/utils/mutation-key-factory';
import { Services } from '../../services/types';
import { BaseHttpError } from '../../services/api/errors';
import { updateSingleEventInCache } from './utils';

export interface PostVolunteerMutationInput {
	communityId: string;
	calendarId: string;
	eventId: string;
	identityId: string;
}

export const usePostVolunteer = () => {
	const mutation = useMutation<
		Event,
		BaseHttpError,
		PostVolunteerMutationInput
	>(postVolunteerMutationKey);

	return mutation;
};

type Context = {
	eventSnapshot: Event | undefined;
	communityId: string;
	calendarId: string;
	eventId: string;
};

export const setupPostVolunteer: SetupMutationFn = (
	services: Services,
	createTrackedParallelMutation,
	mutationTracker
) => {
	const { queryClient, api } = services;

	const mutation = createTrackedParallelMutation<
		Event,
		BaseHttpError,
		PostVolunteerMutationInput,
		Context
	>({
		mutationFn: async ({ eventId, identityId }) => {
			return api
				.postIdentityVolunteer(eventId, identityId)
				.then(response => response.data);
		},
		onMutate: async input => {
			const { calendarId, communityId, eventId, identityId } = input;

			const eventSnapshot = queryClient.getQueryData<Event>(
				calendarKeys.detail(calendarId, eventId)
			);

			if (eventSnapshot) {
				const members = queryClient.getQueryData<CommunityMember[]>(
					communityKeys.members(communityId)
				);

				const member = (members ?? []).find(
					member => member.identity.uuid === identityId
				);

				if (member) {
					const updatedEvent: Event = {
						...eventSnapshot,
						volunteers: [
							...(eventSnapshot.volunteers ?? []),
							member.identity,
						],
						volunteers_available:
							eventSnapshot.volunteers_available - 1,
					};

					queryClient.setQueryData<Event>(
						calendarKeys.detail(calendarId, eventId),
						updatedEvent
					);

					updateSingleEventInCache(
						queryClient,
						calendarId,
						updatedEvent
					);
				}
			}

			return {
				eventSnapshot,
				communityId,
				calendarId,
				eventId,
			};
		},
		onSuccess: (result, { calendarId, eventId }) => {
			queryClient.setQueryData<Event>(
				calendarKeys.detail(calendarId, eventId),
				result
			);
		},
		onError: (error, { calendarId, eventId }, context) => {
			if (context && !isCancelledError(error) && context.eventSnapshot) {
				queryClient.setQueryData<Event>(
					calendarKeys.detail(calendarId, eventId),
					context.eventSnapshot
				);
			}
		},
		onSettled: (data, error, variables, context) => {
			if (context && !isCancelledError(error)) {
				mutationTracker.queueInvalidations(
					calendarKeys.detail(context.calendarId, context.eventId),
					calendarKeys.lists(context.calendarId)
				);
			}
		},
		retry: 1,
	});

	queryClient.setMutationDefaults(postVolunteerMutationKey, mutation);
};

export const isPostVolunteerMutation = (
	mutation: Mutation<any, any, any, any>
) =>
	[postVolunteerMutationKey].includes(String(mutation?.options?.mutationKey));
