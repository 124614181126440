import { SetupMutationFn } from 'core/configure/types';
import { BaseHttpError } from 'core/services/api/errors';
import { Community, MembershipRequest } from 'core/types';
import { patchPendingRequestKey } from 'core/utils/mutation-key-factory';
import { removeRecordFromInfinityQueryCache } from 'core/utils/optimistic-utils';
import { communityKeys } from 'core/utils/query-key-factory';
import { isCancelledError, useMutation } from 'react-query';

export interface PatchPendingRequestsInput {
	communityId: string;
	requestIds: string[];
	action: 'approve' | 'decline' | 'delete';
}

export const usePatchPendingRequests = () => {
	const mutation = useMutation<
		MembershipRequest,
		BaseHttpError,
		PatchPendingRequestsInput
	>(patchPendingRequestKey);

	return mutation;
};

export const setupPatchPendingRequests: SetupMutationFn = (
	services,
	createTrackedParallelMutation,
	mutationTracker
) => {
	const { api, queryClient } = services;

	const mutation = createTrackedParallelMutation<
		MembershipRequest,
		BaseHttpError,
		PatchPendingRequestsInput,
		null
	>({
		mutationFn: ({ communityId, requestIds, action }) =>
			api
				.patchCommunityMembershipRequests(
					communityId,
					requestIds,
					action
				)
				.then(response => response.data),
		onMutate: async ({ communityId, requestIds }) => {
			for (const requestId of requestIds) {
				await removeRecordFromInfinityQueryCache<MembershipRequest>(
					queryClient,
					communityKeys.pendingRequests(communityId),
					requestId
				);

				queryClient.setQueryData<Community | undefined>(
					communityKeys.detail(communityId),
					community => {
						if (community) {
							community.team_stats.requests--;
						}

						return community;
					}
				);
			}

			return null;
		},
		onSettled: (_data, error, variables) => {
			if (!isCancelledError(error)) {
				mutationTracker.queueInvalidations(
					communityKeys.pendingRequests(variables.communityId),
					communityKeys.detail(variables.communityId)
				);
			}
		},
		retry: 1,
	});

	queryClient.setMutationDefaults(patchPendingRequestKey, mutation);
};
