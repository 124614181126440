import React from 'react';
import {
	LinkProps as ReactRouterLinkProps,
	useHref,
	useLinkClickHandler,
	useMatch,
	useResolvedPath,
} from 'react-router-dom';
import classNames from 'classnames';
import { Link, LinkProps } from './link';

export type RouterLinkProps = LinkProps &
	ReactRouterLinkProps & {
		match?: 'exact' | 'sensitive';
	};

export const RouterLink = React.forwardRef<HTMLAnchorElement, RouterLinkProps>(
	(props, ref) => {
		const {
			onClick,
			replace = false,
			state,
			target,
			to,
			match = 'exact',
		} = props;

		const href = useHref(to);

		const resolved = useResolvedPath(to);
		const matchLink = useMatch({
			path: resolved.pathname,
			end: match === 'exact',
			caseSensitive: match === 'sensitive',
		});

		const handleClick = useLinkClickHandler<
			HTMLAnchorElement | HTMLSpanElement
		>(to, {
			replace,
			state,
			target,
		});

		return (
			<Link
				className={classNames({ active: matchLink })}
				{...props}
				underline={matchLink ? 'always' : 'hover'}
				href={href}
				onClick={event => {
					onClick?.(event);
					if (!event.defaultPrevented) {
						handleClick(event);
					}
				}}
				ref={ref}
				target={target}
			/>
		);
	}
);
