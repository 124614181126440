import { useMutation } from 'react-query';
import { Nullable, Identity, Image } from 'core/types';
import { postIdentityMutationKey } from 'core/utils/mutation-key-factory';
import { useServices } from 'hooks';
import { BaseHttpError } from '../../services/api/errors';
import { IdentityInput } from '../../services/api/api-client/types';

export interface PostIdentityMutationInput
	extends Omit<IdentityInput, 'photo'> {
	photo?: Nullable<Image>;
}

export const usePostIdentity = () => {
	const { api, uploader } = useServices();

	return useMutation<Identity, BaseHttpError, PostIdentityMutationInput>(
		async ({ photo, ...input }) => {
			const remoteImage = await uploader.uploadImage(photo);

			return api
				.postIdentity({
					...input,
					photo: remoteImage?.uuid ?? null,
				})
				.then(response => response.data);
		},
		{
			mutationKey: postIdentityMutationKey,
		}
	);
};
