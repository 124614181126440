import {
	Box,
	CircularProgress,
	PageHeading,
	Paper,
	Typography,
} from 'components/common';
import { useCurrentIdentity } from 'core/uses-cases/authentication';
import {
	useRouter,
	useServices,
	useTranslation,
	useCurrentCommunity,
} from 'hooks';
import {
	InviteSubmittedValues,
	SetupCommunityMembers,
} from 'components/community/setup';
import { useCallback } from 'react';
import { usePostMembershipInvitation } from 'core/uses-cases/community';
import { headerStyles } from './styles';

export const InviteMembers = () => {
	const { t } = useTranslation();
	const community = useCurrentCommunity();
	const identity = useCurrentIdentity();
	const postInvitation = usePostMembershipInvitation();
	const { notification } = useServices();
	const router = useRouter();

	const handleOnSubmit = useCallback(
		(values: InviteSubmittedValues) => {
			if (!community) return undefined;

			postInvitation.mutate(
				{
					communityId: community.slug,
					...values,
				},
				{
					onSuccess(data) {
						if (data.bad_recipients.length > 0) {
							notification.show(
								'warning',
								t(
									'create-community.message-not-sent-to-these-people',
									{
										name: data.bad_recipients.join(', '),
									}
								)
							);
						} else {
							notification.show(
								'success',
								t(
									'create-community.the-invitations-have-been-sent'
								)
							);

							router.navigate('community.team', {
								communityId: community.slug,
							});
						}
					},
				}
			);
		},
		[community]
	);

	if (!community || !identity) {
		return (
			<Box display='flex' justifyContent='center' mt={4}>
				<CircularProgress message={t('common.loading')} />
			</Box>
		);
	}

	return (
		<Box>
			<PageHeading title={t('invite-members.add-members')}>
				<Typography variant='body2' mb={1} mt={1} sx={headerStyles}>
					{t('invite-members.tell-us')}
				</Typography>
			</PageHeading>
			<Box component={Paper} elevation={0} mt={6} px={5} py={5}>
				<SetupCommunityMembers
					community={community}
					identity={identity}
					onSubmit={handleOnSubmit}
				/>
			</Box>
		</Box>
	);
};
