import { InvitationTokenStatusCode } from 'core/types';
import {
	useGetPublicCommunity,
	useGetInvitationToken,
	useGetInvitationTokenStatus,
} from 'core/uses-cases/community';
import { useRouter, useTranslation } from 'hooks';

export const useInvitationFlow = (
	communityId: string,
	tokenId: string,
	enabled = false
) => {
	const { t } = useTranslation();
	const router = useRouter();
	const communityQuery = useGetPublicCommunity(communityId, { enabled });

	const tokenQuery = useGetInvitationToken(
		communityQuery?.data?.slug ?? '',
		tokenId
	);

	const tokenStatusQuery = useGetInvitationTokenStatus(communityId, tokenId);

	let navigate = null;
	const pathname = window.location.pathname;

	if (communityQuery.isError) {
		navigate = {
			to: router.getPathFor('root'),
			alert: {
				level: 'error' as const,
				message: t('invitation.invalid-community'),
			},
		};
	} else if (tokenStatusQuery.isError) {
		navigate = {
			to: router.getPathFor('community.home', { communityId }),
			alert: {
				level: 'error' as const,
				message: t('invitation.invalid-invitation'),
			},
		};
	} else if (
		tokenQuery.isError ||
		tokenStatusQuery.data?.code !== InvitationTokenStatusCode.PendingToken
	) {
		if (
			tokenStatusQuery?.data?.code ===
			InvitationTokenStatusCode.InvitationNotFound
		) {
			navigate = {
				to: router.getPathFor('community.home', {
					communityId: communityId,
				}),
				alert: {
					level: 'error' as const,
					message: t('invitation.invalid-invitation'),
				},
			};
		} else if (
			tokenStatusQuery?.data?.code ===
			InvitationTokenStatusCode.InvitationUserCommunityMember
		) {
			navigate = {
				to: router.getPathFor('community.home', {
					communityId: communityId,
				}),
				replace: true,
			};
		} else if (!pathname.endsWith('invalid-token')) {
			navigate = {
				to: router.getPathFor('community.invitation.invalid-token', {
					communityId,
					tokenId,
					replace: true,
				}),
			};
		}
	}

	return {
		token: tokenQuery.data,
		community: communityQuery.data,
		navigate,
		isLoading:
			communityQuery.isLoading ||
			tokenQuery.isLoading ||
			tokenStatusQuery.isLoading,
	};
};
