import { BadgeHolder, Box, Paper, Typography, Button } from 'components/common';
import { CommunityMember } from 'core/types';
import { useTranslation } from 'hooks';
import { useState } from 'react';
import { statsButtonStyle } from './styles';

export type MemberStatsCardProps = {
	memberStats: CommunityMember['stats'];
};

export const MemberStatsCard = ({ memberStats }: MemberStatsCardProps) => {
	const { t } = useTranslation();
	const [useAllTime, setUseAllTime] = useState(true);

	const stats = useAllTime ? memberStats.all_time : memberStats.ninety_days;

	return (
		<Box component={Paper} elevation={0} p={3} mt={1} mb={3}>
			<Box mb={1}>
				<Typography variant='button2'>
					{t(
						useAllTime
							? 'member-profile.all-time'
							: 'member-profile.past-90-days'
					)}
				</Typography>
			</Box>
			<StatRow
				label={t('member-profile.tasks-created')}
				value={stats.tasks_created}
			/>
			<StatRow
				label={t('member-profile.tasks-completed')}
				value={stats.tasks_done}
			/>
			<StatRow
				label={t('member-profile.photos-uploaded')}
				value={stats.uploaded_photos}
			/>
			<Button
				sx={statsButtonStyle}
				variant='text'
				onClick={() => setUseAllTime(!useAllTime)}
				size='small'
			>
				{useAllTime
					? t('member-profile.past-90-days')
					: t('member-profile.all-time')}
			</Button>
		</Box>
	);
};

const StatRow = ({ label, value }: { label: string; value: number }) => {
	return (
		<Box display='flex' mb={1}>
			<BadgeHolder>{value}</BadgeHolder>
			<Typography ml={2} className='label'>
				{label}
			</Typography>
		</Box>
	);
};
