import { useDialog, DialogHookOptions } from 'components/common';
import { DeleteNoteMutationInput, useDeleteNote } from 'core/uses-cases/note';
import { useServices, useTranslation } from 'hooks';

export const useDeleteNoteHandler = (
	options?: DialogHookOptions<DeleteNoteMutationInput>
) => {
	const { t } = useTranslation();
	const { notification } = useServices();
	const { mutateAsync } = useDeleteNote();

	const { open } = useDialog<DeleteNoteMutationInput>({
		title: t('notes.delete-confirm-title'),
		content: t('notes.delete-confirm-content'),
		confirmText: t('common.delete'),
		onConfirm: input => {
			if (!input) return;

			mutateAsync(input).then(() => {
				notification.show(
					'success',
					t('notes.note-was-successfully-deleted')
				);
			});

			options?.onConfirm?.(input);
		},
		onCancel: options?.onCancel,
	});

	return open;
};
