import {
	Box,
	Field,
	FormStack,
	Form,
	LoadingButton,
	FormOnSubmitReturn,
	PasswordField,
	Button,
} from 'components/common';
import { useTranslation } from 'hooks';
import { TFunction } from 'i18next';
import { useCallback, useMemo } from 'react';
import { sleep } from 'utils/delay';
import * as Yup from 'yup';

export type UnsubscribeFromCommunityFormSubmittedValues = {
	password: string;
};

export type UnsubscribeFromCommunityFormProps = {
	onSubmit?: (
		values: UnsubscribeFromCommunityFormSubmittedValues
	) => FormOnSubmitReturn;
	submitText?: string;
	submittingText?: string;
	onCancel: () => void;
};

export const UnsubscribeFromCommunityForm = (
	props: UnsubscribeFromCommunityFormProps
) => {
	const { onSubmit, onCancel, submitText, submittingText } = props;

	const { t } = useTranslation();

	const handleOnSubmit = useCallback(
		async values => {
			await sleep(1);
			const result = onSubmit?.(values);
			return Promise.resolve(result).catch(error => error);
		},
		[onSubmit]
	);

	const validationSchema = useMemo(() => createValidationSchema(t), []);

	return (
		<Form
			onSubmit={handleOnSubmit}
			validationSchema={validationSchema}
			subscription={{
				submitting: true,
				submitSucceeded: true,
			}}
		>
			{({ handleSubmit, submitting, submitSucceeded }) => (
				<form onSubmit={handleSubmit} noValidate>
					<FormStack>
						<Box mt={1}>
							<Field
								name='password'
								component={PasswordField}
								placeholder={t(
									'unsubscribe-form.type-the-account-password'
								)}
								type='password'
								fullWidth
							/>
						</Box>
						<Box mt={5}>
							<Box mb={1}>
								<Button
									size='small'
									variant='text'
									onClick={() => onCancel()}
									disabled={submitting || submitSucceeded}
								>
									{t(
										'unsubscribe-form.just-wanted-to-update-my-email-preferences'
									)}
								</Button>
							</Box>
							<LoadingButton
								loading={submitting || submitSucceeded}
								loadingIndicator={
									submittingText ||
									t('unsubscribe-form.leaving-community')
								}
								size='small'
								type='submit'
							>
								{submitText ||
									t(
										'unsubscribe-form.want-to-leave-community'
									)}
							</LoadingButton>
						</Box>
					</FormStack>
				</form>
			)}
		</Form>
	);
};

function createValidationSchema(t: TFunction) {
	return Yup.object().shape({
		password: Yup.string().trim().required(t('form.generic-required')),
	});
}
