import { SxProps } from '@mui/system';
import { Theme } from 'theme';

export const fileContentStyle: SxProps = {
	'& > div': {
		display: 'flex',
		flexGrow: 1,
		alignItems: 'flex-start',
		flexWrap: 'nowrap',
		maxWidth: 300,
		marginTop: 0.5,

		'& > p': {
			flex: 1,
		},
	},
};

export const deleteButtonContent: SxProps<Theme> = theme => ({
	bgcolor: 'white',
	color: theme.palette.error.main,
	borderRadius: '50%',
});
