import {
	CheckboxField,
	FancyTable,
	PaginatedList,
	Typography,
	PaginatedListProps,
	useDialog,
	useMediaQuery,
} from 'components/common';
import {
	useCheckboxMultiSelectionHandler,
	useTranslation,
	useVerifyAccess,
} from 'hooks';
import { Fragment, useMemo } from 'react';
import { Group } from 'core/types';
import { InfiniteData } from 'react-query';
import {
	FooterActionButton,
	FooterActions,
} from 'components/common/footer-action';
import { GroupRow } from './list-row';

export type GroupListProps = {
	communityId: string;
	data: InfiniteData<Group[]> | undefined;
	PaginatedListProps: PaginatedListProps;
	onDelete?: (groupIds: string[]) => void;
	showGlobalPermissions?: boolean;
};

export const GroupList = ({
	data,
	communityId,
	PaginatedListProps,
	onDelete,
	showGlobalPermissions = false,
}: GroupListProps) => {
	const { t } = useTranslation();
	const verify = useVerifyAccess();
	const isLargeScreen = useMediaQuery.up('sm');

	const groupsData = useMemo(() => {
		if (!data?.pages) return [];

		const allGroups = data.pages.reduce((groups, page) => {
			return groups.concat(page);
		}, [] as Group[]);

		return allGroups.map(group => {
			const canBeDeleted = verify('delete', 'group', group);

			const isSelectable = !(
				group.is_system_managed || group.has_locked_permissions
			);

			return {
				group: {
					...group,
					members: [...(group?.members ?? [])].sort((a, b) => {
						const aName = `${a?.first_name} ${a?.last_name}`;
						const bName = `${b?.first_name} ${b?.last_name}`;
						return aName.localeCompare(bName);
					}),
				},
				isSelectable,
				canBeDeleted,
			};
		});
	}, [data]);

	const selectableGroups = groupsData
		.filter(data => data.isSelectable)
		.map(data => data.group);

	const {
		selected,
		hasData,
		select,
		selectedSize,
		selectAll,
		isSelected,
		isAllSelected,
		isIndeterminate,
		clearAll,
	} = useCheckboxMultiSelectionHandler(selectableGroups);

	const { open: handleDelete } = useDialog({
		title: t('groups.actions.delete-title'),
		content: t('groups.actions.delete'),
		confirmText: t('common.delete'),
		onConfirm: () => {
			onDelete?.(selected);
			clearAll();
		},
	});

	const canDeleteGroups = groupsData.some(data => data.canBeDeleted);
	let renderColumns = {};

	if (showGlobalPermissions) {
		renderColumns = {
			2: { xs: false },
			3: { xs: false, sm: false },
			4: { xs: false, sm: false },
		};
	} else {
		renderColumns = {
			2: { xs: false },
			3: { xs: false, sm: false, md: false, lg: false, xl: false },
			4: { xs: false, sm: false },
		};
	}

	const customGridWidths = {
		5: { xs: 3, sm: 2, md: 1.3, xl: 1, lg: 1 },
	};

	return (
		<>
			<FancyTable
				renderColumns={renderColumns}
				customGridWidths={customGridWidths}
			>
				<FancyTable.Header>
					<FancyTable.Header.Cell>
						{canDeleteGroups && (
							<CheckboxField
								CheckBoxProps={{
									indeterminate: isIndeterminate,
								}}
								value={isAllSelected}
								onChange={selectAll}
							/>
						)}
						<Typography variant='button2'>
							{t('groups.name')}
						</Typography>
					</FancyTable.Header.Cell>
					<FancyTable.Header.Cell title={t('groups.members')} />
					<FancyTable.Header.Cell
						title={t('groups.global-permissions')}
					/>
					<FancyTable.Header.Cell title={t('groups.visibility')} />
					<FancyTable.Header.Cell title='' />
				</FancyTable.Header>
				<PaginatedList
					{...PaginatedListProps}
					showButton={PaginatedListProps.showButton && hasData}
				>
					<>
						{groupsData.map(
							({ group, isSelectable, canBeDeleted }) => {
								const isDisabled =
									!isSelectable || !canBeDeleted;

								return (
									<Fragment key={group.uuid}>
										<GroupRow
											group={group}
											isSelectable={!isDisabled}
											onSelect={select}
											isSelected={isSelected(group.uuid)}
											communityId={communityId}
											showGlobalPermissions={showGlobalPermissions} // prettier-ignore
										/>
									</Fragment>
								);
							}
						)}
					</>
				</PaginatedList>
			</FancyTable>
			<FooterActions
				selectedCount={selectedSize}
				isLargeScreen={isLargeScreen}
			>
				<FooterActionButton
					icon='delete_outline'
					onClick={() => handleDelete()}
				>
					{isLargeScreen && t('common.delete')}
				</FooterActionButton>
			</FooterActions>
		</>
	);
};
