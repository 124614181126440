import {
	Box,
	TextField,
	LoadingButton,
	Form,
	Field,
	PasswordField,
	PasswordRulesChecker,
	TelephoneField,
	FormOnSubmitReturn,
	FormStack,
	Button,
	Divider,
	PlainTextField,
} from 'components/common';
import { useTranslation } from 'hooks';
import { useCallback, useMemo } from 'react';
import { sleep } from 'utils/delay';
import { TFunction } from 'react-i18next';
import * as Yup from 'yup';
import { phoneNumber } from 'utils/validation';
import { buttonSectionStyle } from './styles';

export type InvitationCreateAccountFormSubmittedValues = {
	firstName: string;
	lastName: string;
	phone: string;
	password: string;
	email: string;
};

type InvitationCreateAccountFormProps = {
	onBack: () => void;
	initialValues?: Partial<InvitationCreateAccountFormSubmittedValues>;
	onSubmit?: (
		values: InvitationCreateAccountFormSubmittedValues
	) => FormOnSubmitReturn;
	submitText?: string;
	submittingText?: string;
	acceptingText?: string | React.ReactNode;
};

export const InvitationCreateAccountForm = (
	props: InvitationCreateAccountFormProps
) => {
	const {
		onBack,
		initialValues,
		onSubmit,
		submitText,
		submittingText,
		acceptingText,
	} = props;

	const { t } = useTranslation();

	const handleOnSubmit = useCallback(
		async ({ phone, ...values }) => {
			await sleep(1);

			const result = onSubmit?.({
				...values,
				phone: phone ? `+${phone.dialCode}${phone.number}` : undefined,
			});

			return Promise.resolve(result).catch(error => error);
		},
		[onSubmit]
	);

	const validationSchema = useMemo(() => createValidationSchema(t), []);

	return (
		<Form
			onSubmit={handleOnSubmit}
			validationSchema={validationSchema}
			subscription={{ submitting: true }}
			initialValues={initialValues}
		>
			{({ handleSubmit, submitting }) => (
				<form onSubmit={handleSubmit} noValidate>
					<FormStack responsive={false}>
						<Box>
							<Field
								label={t('common-label.first-name')}
								name='firstName'
								component={TextField}
								type='text'
								fullWidth
								required
							/>
						</Box>
						<Box>
							<Field
								label={t('common-label.last-name')}
								name='lastName'
								component={TextField}
								type='text'
								fullWidth
								required
							/>
						</Box>
						<Box>
							<Field
								label={t('common-label.email')}
								name='email'
								component={PlainTextField}
								required
							/>
						</Box>
						<Box>
							<Field
								label={t('common-label.phone')}
								name='phone'
								component={TelephoneField}
								type='text'
								placeholder='(201) 555-0123'
								fullWidth
								required
							/>
						</Box>
						<Box>
							<Field
								label={t('common-label.password')}
								name='password'
								component={PasswordField}
								type='password'
								fullWidth
								required
							/>
							<PasswordRulesChecker
								forField='password'
								usernameField='email'
								initialEmail={initialValues?.email || ''}
							/>
						</Box>
						<Divider />
						{acceptingText && <Box>{acceptingText}</Box>}
						<Box sx={buttonSectionStyle}>
							<Button
								size='small'
								type='button'
								onClick={onBack}
								color='secondary'
							>
								{t('common.back')}
							</Button>
							<LoadingButton
								loading={submitting}
								loadingIndicator={
									submittingText || t('form.submitting')
								}
								size='small'
								type='submit'
								noWrap
							>
								{submitText || t('form.submit')}
							</LoadingButton>
						</Box>
					</FormStack>
				</form>
			)}
		</Form>
	);
};

function createValidationSchema(t: TFunction) {
	return Yup.object().shape({
		firstName: Yup.string().required(t('form.generic-required')),
		lastName: Yup.string().required(t('form.generic-required')),
		email: Yup.string()
			.email(t('form.invalid-email'))
			.required(t('form.generic-required')),
		phone: phoneNumber(t('form.invalid-phone')).required(
			t('form.generic-required')
		),
	});
}
