import { Stack, StackProps } from 'components/common';
import { stackStyle } from './styles';

export interface FormStackProps extends StackProps {
	responsive?: boolean;
	spacing?: number;
}

export const FormStack = ({
	responsive = true,
	spacing = 6,
	sx,
	...rest
}: FormStackProps) => {
	return <Stack spacing={spacing} sx={stackStyle(responsive)} {...rest} />;
};
