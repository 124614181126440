import { Box, CircularProgress, Paper } from 'components/common';
import { GroupForm, GroupFormSubmittedValues } from 'components/groups';
import { TeamHeader } from 'components/team';
import {
	useGetCommunityGroup,
	usePutCommunityGroup,
} from 'core/uses-cases/group';
import {
	useParams,
	useRouter,
	useServices,
	useSubmitHandlerForPausedMutation,
	useTranslation,
} from 'hooks';
import { Navigate } from 'navigation';
import { useGroupFormData } from 'components/groups/use-group-form-data';
import { useGetGroupPermissions } from './use-group-access';

export const EditGroupInfo = () => {
	const { t } = useTranslation();
	const router = useRouter();
	const { communityId, groupId } = useParams() as {
		communityId: string;
		groupId: string;
	};
	const { notification } = useServices();

	const {
		data: group,
		isSuccess,
		isLoading,
	} = useGetCommunityGroup(communityId, groupId);

	const { canEditInfo, showGlobalPermissions } =
		useGetGroupPermissions(group);

	const { mutateAsync, isPaused } = usePutCommunityGroup();

	const handleOnSubmit =
		useSubmitHandlerForPausedMutation<GroupFormSubmittedValues>(
			isPaused,
			({ permissions, ...rest }) =>
				mutateAsync({
					communityId,
					groupId,
					isOffline: !!group?.is_offline,
					...(showGlobalPermissions ? { permissions } : {}),
					...rest,
				}),
			{
				onSuccess: ({ values }) => {
					notification.show(
						'success',
						t('group.group-was-successfully-updated', {
							name: values.name,
						})
					);
					router.navigate('community.group-view', {
						communityId,
						groupId,
					});
				},
			},
			[communityId, groupId]
		);

	const { initialValues, identities, groups } = useGroupFormData(
		communityId,
		group
	);

	if (group && !canEditInfo) {
		return (
			<Navigate
				to={router.getPathFor('community.group-view', {
					communityId,
					groupId,
				})}
			/>
		);
	}

	if (isLoading) {
		return (
			<Box display='flex' justifyContent='center' mt={4}>
				<CircularProgress message={t('common.loading')} />
			</Box>
		);
	}

	return (
		<>
			<TeamHeader title={t('group.edit-group')} />
			<Box component={Paper} elevation={0} mt={6} px={5} py={5}>
				{isSuccess && group && (
					<>
						<GroupForm
							onSubmit={handleOnSubmit}
							groups={groups}
							identities={identities}
							initialValues={initialValues}
							submitText={t('group-form.update-group')}
							submittingText={t('group-form.updating-group')}
							showPermissionsField={showGlobalPermissions}
						/>
					</>
				)}
			</Box>
		</>
	);
};
