import { SxProps } from '@mui/system';
import { Theme } from 'theme';

export const badgeStyle: SxProps<Theme> = theme => ({
	height: 20,
	fontSize: 12,
	lineHeight: '19px',
	padding: '2px 5px ',
	backgroundColor: theme.palette.primary.main,
	borderRadius: 0.5,
	color: 'white',
	verticalAlign: 'middle',
	ml: 1,

	'&.invited': {
		backgroundColor: theme.primaryColors.primary1,
	},

	'&.pending': {
		backgroundColor: theme.primaryColors.primary5,
	},
});

export const titleStyle: SxProps = {
	fontWeight: 'bold',
	mb: 1.5,
	overflow: 'hidden',
	display: 'flex',
	alignItems: 'center',

	'& > span:first-of-type': {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	},
};

export const communityCardStyle: SxProps = {
	'&:hover': {
		textDecoration: 'none',
		'& span.community-name': {
			textDecoration: 'underline',
		},
	},
};

export const avatarStyle: (size: number) => SxProps = size => ({
	width: size,
	height: size,
	marginRight: 3,
});
