import { FeedRecord } from 'core/types';
import { useGetCommunityFeed } from 'core/uses-cases/community/use-get-feed';
import { useFilterFeed, useInfinityScrollPaginationHandler } from 'hooks';
import { Fragment } from 'react';
import { Box, Paper, PaginatedList } from '../../common';
import { AnnouncementFeedCard } from './announcement-card';
import { NoteCard } from './note-card';
import { PhotoCard } from './photo-card';
import { WelcomeCard } from './welcome-card';
import { WellWishCard } from './well-wish-card';

export const FeedContainer = ({ communityId }: { communityId: string }) => {
	const { data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } =
		useGetCommunityFeed(communityId, { enable: !!communityId });

	const { handleOnLoad, showMoreButton, setShowMoreButton } =
		useInfinityScrollPaginationHandler<FeedRecord>({
			data,
			fetchNextPage,
			hasNextPage,
		});

	const filteredData = useFilterFeed(data);

	return (
		<>
			<WelcomeCard />
			<PaginatedList
				onLoadMore={fetchNextPage}
				onReachThreshold={handleOnLoad}
				isFetching={isFetching}
				isFetchingMore={isFetchingNextPage}
				hasMore={!!hasNextPage}
				showButton={showMoreButton}
				setShowButton={setShowMoreButton}
			>
				{filteredData?.pages.map(page => (
					<Fragment key={`page-${page?.[page.length - 1]?.uuid}`}>
						{page.map(item => (
							<Box
								key={item.uuid}
								component={Paper}
								elevation={0}
								p={3}
								mb={2}
								mt={1}
							>
								{item.type === 'announcement' && (
									<AnnouncementFeedCard feed={item} />
								)}
								{item.type === 'note' && (
									<NoteCard feed={item} />
								)}
								{item.type === 'well_wish' && (
									<WellWishCard feed={item} />
								)}
								{item.type === 'album_image' && (
									<PhotoCard feed={item} />
								)}
							</Box>
						))}
					</Fragment>
				))}
			</PaginatedList>
		</>
	);
};
