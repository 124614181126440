import {
	Box,
	Typography,
	Link,
	Form,
	FormControl,
	FormHelperText,
	useDialog,
	SelectField,
	SelectItem,
	FormStack,
	Button,
	Icon,
} from 'components/common';
import MuiDialogActions from '@mui/material/DialogActions';
import MuiDialogContent from '@mui/material/DialogContent';
import InputLabel from '@mui/material/InputLabel';
import { useControlledValue, useTranslation } from 'hooks';
import { getCurrentTimeZone } from 'utils/dates';
import { Field, FieldForSubscription } from 'components';
import { StrictNullable, Timezone, TimezoneCountry } from 'core/types';
import { useField, useForm } from 'react-final-form';
import { useEffect, useMemo } from 'react';
import { useTimezones } from 'components/timezones';
import { TextFieldProps } from '../text-field';

type TimezoneCountryValue = StrictNullable<string>;

type FormSubmittedValue = {
	country: StrictNullable<string>;
	timezone: StrictNullable<string>;
};

type TimeZoneFieldProps = Omit<
	TextFieldProps,
	| 'value'
	| 'onChange'
	| 'multiline'
	| 'type'
	| 'rows'
	| 'maxRows'
	| 'minRows'
	| 'select'
	| 'SelectProps'
	| 'type'
	| 'defaultValue'
> & {
	value?: TimezoneCountryValue;
	onChange?: (value: TimezoneCountryValue) => void;
	defaultValue?: TimezoneCountryValue;
	update: boolean;
};

type TimezoneRecordMap = Timezone & {
	country: Omit<TimezoneCountry, 'timezones'>;
};

const TimezoneSelectorField = ({
	countriesMap,
}: {
	countriesMap: Map<string, TimezoneCountry>;
}) => {
	const form = useForm();

	const {
		input: { value },
	} = useField<FormSubmittedValue['country']>('country', {
		subscription: { value: true },
	});

	const country = countriesMap.get(value || '');

	useEffect(() => {
		if (!country) return;

		const firstTimezone = country.timezones[0].value;

		if (country.timezones.length === 1) {
			form.change('timezone', firstTimezone);
		} else if (!form.getFieldState('timezone')?.value) {
			form.change('timezone', firstTimezone);
		}
	}, [country]);

	if (!country || country.timezones.length === 1) return null;

	const timezoneOptions = country.timezones.map(tz => ({
		label: tz.label,
		value: tz.value,
	}));

	return (
		<Field component={SelectField} name='timezone'>
			{timezoneOptions.map(({ label, value }) => (
				<SelectItem key={value} value={value}>
					{label}
				</SelectItem>
			))}
		</Field>
	);
};

const TimezoneSelector = ({
	initialValues,
	countriesMap,
	onSubmit,
}: {
	countriesMap: Map<string, TimezoneCountry>;
	initialValues: Partial<FormSubmittedValue>;
	onSubmit: (values: FormSubmittedValue) => void;
}) => {
	const { t } = useTranslation();

	const handleOnSubmit = (values: FormSubmittedValue) => {
		if (!values.country) return;
		onSubmit(values);
	};

	const currentTimezone = getCurrentTimeZone();

	const countryOptions = useMemo(() => {
		return new Array(...countriesMap).map(([, countryData]) => ({
			label: countryData.label,
			value: countryData.value,
		}));
	}, []);

	return (
		<Form<FormSubmittedValue>
			onSubmit={handleOnSubmit}
			initialValues={initialValues}
		>
			{({ handleSubmit }) => {
				return (
					<>
						<MuiDialogContent>
							<FormStack responsive={false} spacing={1.5}>
								<FormControl fullWidth>
									<Field
										name='country'
										component={SelectField}
										options={countryOptions}
									>
										{countryOptions.map(
											({ label, value }) => (
												<SelectItem
													key={`country-${value}`}
													value={value}
												>
													{label}
												</SelectItem>
											)
										)}
									</Field>
								</FormControl>
								<FormControl fullWidth>
									<TimezoneSelectorField
										{...{ countriesMap }}
									/>
								</FormControl>
							</FormStack>
						</MuiDialogContent>
						<MuiDialogActions
							sx={{
								paddingX: 2,
								paddingBottom: 1,
								paddingTop: 0,
							}}
						>
							<Button size='small' onClick={handleSubmit}>
								{t('timezone-field.update')}
							</Button>
						</MuiDialogActions>
						<FieldForSubscription<FormSubmittedValue['timezone']>
							name='timezone'
							subscription={{ value: true }}
						>
							{({ input: { value: timezone } }) => {
								if (!timezone || currentTimezone === timezone)
									return null;

								return (
									<Box
										paddingX={3}
										paddingBottom={2}
										marginRight={1}
									>
										<Typography>
											<Icon
												name='info'
												sx={{
													float: 'left',
													fontSize: 16,
													mt: 0.5,
												}}
											/>
											{t(
												'timezone-field.timezone-alert',
												{
													currentTimezone,
													nextTimezone: timezone,
												}
											)}
										</Typography>
									</Box>
								);
							}}
						</FieldForSubscription>
					</>
				);
			}}
		</Form>
	);
};

export const TimeZoneField = (props: TimeZoneFieldProps) => {
	const {
		required,
		label,
		InputLabelProps,
		helperText,
		value: incomingValue = null,
		onChange,
		disabled,
		error,
		fullWidth,
		focused,
		hiddenLabel,
		margin,
		size,
		sx,
		update,
	} = props;

	const { t } = useTranslation();

	const [value, setValue] = useControlledValue<TimezoneCountryValue>(
		incomingValue,
		onChange
	);

	const timezones = useTimezones();

	const [countriesMap, timezonesMap] = useMemo(() => {
		return timezones.reduce(
			([countriesMap, timezonesMap], country) => {
				const { timezones, ...rest } = country;

				timezones.forEach(timezone => {
					timezonesMap.set(timezone.value, {
						...timezone,
						country: rest,
					});

					timezone.alias.forEach(alias => {
						timezonesMap.set(alias, {
							...timezone,
							country: rest,
						});
					});
				});

				countriesMap.set(rest.value, country);

				return [countriesMap, timezonesMap];
			},
			[
				new Map<string, TimezoneCountry>(),
				new Map<string, TimezoneRecordMap>(),
			]
		);
	}, [timezones]);

	const selectedTimezone = timezonesMap.get(value || '') ?? null;

	const selectedCountry = selectedTimezone?.country ?? null;

	const content = (
		confirmHandler: (contextData: FormSubmittedValue) => void
	) => (
		<TimezoneSelector
			initialValues={{
				country: selectedCountry?.value,
				timezone: selectedTimezone?.value,
			}}
			countriesMap={countriesMap}
			onSubmit={confirmHandler}
		/>
	);

	const { open } = useDialog<void, FormSubmittedValue>({
		title: t('timezone-field.update-timezone'),
		content: content,
		confirmText: t('timezone-field.update'),
		fullWidth: true,
		hideActions: true,
		onConfirm: (_contextData, extraData) => {
			setValue(extraData.timezone);
		},
	});

	const handleOnClick = () => open();

	return (
		<FormControl
			{...{
				fullWidth,
				error,
				margin,
				required,
				size,
				sx,
				focused,
				hiddenLabel,
				disabled,
			}}
		>
			{label && <InputLabel {...InputLabelProps}>{label}</InputLabel>}
			<Box display='flex'>
				<Typography mr={1}>
					{selectedTimezone?.label ||
						t('timezone-field.select-timezone')}
				</Typography>
				{update && (
					<Link
						sx={{ cursor: 'pointer' }}
						role='button'
						onClick={handleOnClick}
					>
						{t('timezone-field.change')}
					</Link>
				)}
			</Box>
			{helperText && <FormHelperText>{helperText}</FormHelperText>}
		</FormControl>
	);
};
