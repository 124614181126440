import { useDialog } from 'components/common';
import {
	DeleteCommentMutationInput,
	useDeleteComment,
} from 'core/uses-cases/discussion';
import { useTranslation } from 'hooks';

export const useDeleteCommentHandler = () => {
	const { t } = useTranslation();
	const { mutateAsync } = useDeleteComment();

	const { open } = useDialog<DeleteCommentMutationInput>({
		title: t('comment-widget.delete-prompt-title'),
		content: t('comment-widget.delete-prompt'),
		confirmText: t('common.delete'),
		onConfirm: input => {
			mutateAsync(input);
		},
	});

	return open;
};
