import { useCurrentIdentity } from 'core/uses-cases/authentication';
import { useMemo } from 'react';
import { formatNumber, parsePhoneNumber } from 'utils/phone';
import { ENGLISH_CODE } from 'core/constants';
import { EditAccountFormInitialValues } from '../edit-account-form';

export const useIdentityFormData = () => {
	const identity = useCurrentIdentity();

	const parsedPhone = parsePhoneNumber(identity?.phone ?? '');

	const initialValues = useMemo(
		() =>
			({
				firstName: identity?.first_name,
				lastName: identity?.last_name,
				email: identity?.email,
				phone: {
					number: formatNumber(parsedPhone, 'National'),
					regionCode: parsedPhone?.country?.toLowerCase(),
					dialCode: parsedPhone?.countryCallingCode,
				},
				location: identity?.location,
				address: identity?.address,
				detectTimezone: identity?.autodetect_timezone,
				timezone: identity?.timezone,
				language: identity?.language ?? ENGLISH_CODE,
				image: identity?.image ? identity.image : null,
			} as EditAccountFormInitialValues),
		[identity]
	);

	return {
		initialValues,
		identityId: identity?.uuid ?? '',
	};
};
