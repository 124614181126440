import { Box } from 'components/common';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useCalendar } from '../../context';
import { eventTypesStyle, headerWidgetContainerStyle } from './styles';
import { CalendarWidgetNavigation } from '../navigation';
import { StatusLabel } from '../sidebar/sidebar';

export const VerticalEventTypesInfo = () => {
	const { t } = useTranslation();

	return (
		<Box sx={eventTypesStyle}>
			<StatusLabel className='help-needed-key'>
				{t('calendar-widget.help-needed')}
			</StatusLabel>
			<StatusLabel className='needs-met-key'>
				{t('calendar-widget.needs-met')}
			</StatusLabel>
			<StatusLabel className='occasions-key'>
				{t('calendar-widget.occasions')}
			</StatusLabel>
		</Box>
	);
};

export const CalendarWidgetHeader = () => {
	const { displayed, setDisplayed, formatDate } = useCalendar();

	const handleOnPressNext = useCallback(() => {
		setDisplayed(displayed.plus({ weeks: 1 }));
	}, [displayed]);

	const handleOnPressPrev = useCallback(() => {
		setDisplayed(displayed.minus({ weeks: 1 }));
	}, [displayed]);

	return (
		<Box sx={headerWidgetContainerStyle} component='header'>
			<CalendarWidgetNavigation
				onNext={handleOnPressNext}
				onPrev={handleOnPressPrev}
				display={formatDate(displayed, 'MMMM yyyy')}
			/>
			<VerticalEventTypesInfo />
		</Box>
	);
};
