import { SelectField, SelectItem } from 'components/common';
import { useGetAlbums } from 'core/uses-cases/albums';
import { useTranslation } from 'hooks';
import { Dispatch, SetStateAction, useMemo } from 'react';

type PhotoFilterProps = {
	communityId: string;
	setFilter: Dispatch<SetStateAction<string>>;
	filter: string;
};

export const PhotoFilter = ({
	communityId,
	setFilter,
	filter,
}: PhotoFilterProps) => {
	const { t } = useTranslation();
	const { data } = useGetAlbums(communityId);

	const options = useMemo(() => {
		const options = [{ name: 'all', uuid: 'all' }];
		const systemAlbums =
			data
				?.filter(
					album => album.type === 'system' && album.name !== 'default'
				)
				.map(album => ({ name: album.name, uuid: album.uuid })) ?? [];

		if (systemAlbums.length) {
			options.push({ name: 'photo', uuid: 'photo' });
		}

		return [...options, ...systemAlbums];
	}, [data]);

	const onChange = (event: { target: { value: string } }) => {
		setFilter(event.target.value);
	};

	return (
		<SelectField value={filter} onChange={onChange}>
			{options.map(option => {
				return (
					<SelectItem key={option.name} value={option.uuid}>
						{t(`photo-filter.${option.name}`)}
					</SelectItem>
				);
			})}
		</SelectField>
	);
};
