import { useMutation, useQueryClient } from 'react-query';
import { useServices } from 'hooks';
import { communityKeys } from 'core/utils/query-key-factory';
import { patchTransferOwnershipMutationKey } from 'core/utils/mutation-key-factory';
import { BaseHttpError } from '../../services/api/errors';

export interface RespondTransferRequestMutationInput {
	communityId: string;
	transferRequestId: string;
	response: 'accept' | 'reject';
}

export const useRespondTransferRequest = () => {
	const { api } = useServices();
	const queryClient = useQueryClient();

	const mutation = useMutation<
		null,
		BaseHttpError,
		RespondTransferRequestMutationInput
	>(
		input => {
			const { communityId, transferRequestId, response } = input;
			return api.patchTransferOwnershipRequest(
				communityId,
				transferRequestId,
				response
			);
		},
		{
			mutationKey: patchTransferOwnershipMutationKey,
			onSuccess: () => {
				return queryClient.invalidateQueries(communityKeys.all());
			},
		}
	);

	return mutation;
};
