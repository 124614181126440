import { useGetPhoto } from 'core/uses-cases/photo';
import {
	useErrorHandler,
	useRouter,
	useServices,
	useSubmitHandlerForPausedMutation,
} from 'hooks';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { LargePhotoCard, useDeletePhotoHandler } from 'components/photos';
import { Backdrop, Box, CircularProgress } from 'components/common';
import { Fragment, useCallback, useState } from 'react';
import { usePatchPhoto } from 'core/uses-cases/photo/use-patch-photo';
import { NameEditorSubmittedValues } from 'components/photos/large-card/name-editor';
import { AlbumPhoto } from 'core/types';
import { CommentsWidget } from 'components/discussion';

export const PhotoView = () => {
	const [isDeleting, setIsDeleting] = useState(false);
	const { t } = useTranslation();
	const router = useRouter();
	const { communityId, photoId, albumId } = useParams() as {
		communityId: string;
		photoId: string;
		albumId: string;
	};
	const { notification } = useServices();

	const { data, isLoading, isSuccess, error } = useGetPhoto(
		photoId,
		communityId,
		albumId,
		isDeleting
	);

	const { mutateAsync, isPaused } = usePatchPhoto();

	const { open: deletePhotoHandler, isDeletingPhoto } = useDeletePhotoHandler(
		{
			onConfirm: () => {
				router.navigate('community.albums', {
					communityId: communityId,
				});

				notification.show(
					'success',
					t('photos.photo-was-successfully-deleted')
				);
			},
			onCancel: () => {
				setIsDeleting(false);
			},
		}
	);

	const handleOnDelete = useCallback(() => {
		setIsDeleting(true);
		deletePhotoHandler({
			communityId,
			photoId,
			albumId,
		});
	}, [communityId, photoId, albumId]);

	const handleOnSubmit =
		useSubmitHandlerForPausedMutation<NameEditorSubmittedValues>(
			isPaused,
			values =>
				mutateAsync({
					communityId,
					albumId,
					photo: data as AlbumPhoto,
					name: values.name,
				}),
			{
				onSuccess: () => {
					notification.show('success', t('photos.view.success'));
				},
			},
			[communityId, albumId, photoId, data]
		);

	useErrorHandler(error, {
		redirectTo: router.getPathFor('community.albums', {
			communityId,
		}),
	});

	return (
		<Box>
			{isLoading && (
				<Box display='flex' justifyContent='center'>
					<CircularProgress message={t('common.loading')} />
				</Box>
			)}
			{isSuccess && data && (
				<Fragment>
					<LargePhotoCard
						photo={data}
						onDelete={handleOnDelete}
						onSubmit={handleOnSubmit}
					/>
					<CommentsWidget
						discussionId={data.discussion_uuid}
						enabled
					/>
				</Fragment>
			)}
			<Backdrop open={isDeletingPhoto} />
		</Box>
	);
};
