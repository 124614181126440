import {
	AppWrapper,
	Header,
	Box,
	HeaderLogo,
	Content,
	Footer,
} from 'components';
import { layoutErrorStyle } from './styles';

export const ErrorLayout: React.FC = ({ children }) => {
	return (
		<AppWrapper>
			<Header
				headerLeft={
					<Box display='flex' alignItems='center'>
						<HeaderLogo withName />
					</Box>
				}
			/>
			<Content sx={layoutErrorStyle}>{children}</Content>
			<Footer />
		</AppWrapper>
	);
};
