import { SxProps } from '@mui/system';
import { Theme } from 'theme';

export const contentStyle: SxProps<Theme> = theme => ({
	flexWrap: 'nowrap',
	display: 'flex',
	'& > *': {
		display: {
			md: 'flex',
			xs: 'none',
		},
		flex: {
			md: 1 / 3,
			xs: 1,
		},
		marginRight: {
			md: 2,
			xs: 0,
		},
		marginLeft: {
			md: 2,
			xs: 0,
		},
		'&:last-child': {
			marginRight: 0,
		},
		'&:first-of-type': {
			marginLeft: 0,
		},
	},
});

export const stepStyle: SxProps<Theme> = theme => ({
	alignItems: 'center',
	paddingBottom: 2,
	color: theme.secondaryColors.secondary1,
	borderBottomWidth: 2,
	borderBottomStyle: 'solid',

	'&.active': {
		display: 'flex',
		color: theme.primaryColors.primary4,
		'& .step-number': {
			color: theme.primaryColors.primary8,
			backgroundColor: theme.primaryColors.primary4,
		},
	},
	'& > *': {
		fontWeight: 'bold',
	},
	'& .step-number': {
		marginRight: 2,
		color: theme.primaryColors.primary1,
		backgroundColor: theme.secondaryColors.secondary1,
	},
	'& .label': {
		fontWeight: 'bold',
	},
	'& .summary': {
		display: {
			md: 'none',
		},
		marginLeft: 'auto',
	},
});
