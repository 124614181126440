import { useMemo } from 'react';
import MuiAvatar, { AvatarProps as MuiAvatarProps } from '@mui/material/Avatar';
import { SxProps } from '@mui/system';
import { Theme } from 'theme';

export type AvatarProps = MuiAvatarProps;

export const Avatar = (props: AvatarProps) => {
	const { sx, alt, children, ...rest } = props;

	const styles = useMemo(
		() => [
			(theme: Theme) => ({
				color: theme.primaryColors.primary3,
				backgroundColor: theme.secondaryColors.secondary3,
				fontSize: 18,
			}),
			...(Array.isArray(sx) ? sx : [sx]),
		],
		[sx]
	) as SxProps;

	const initials = useMemo(() => {
		return `${alt?.split(' ')?.[0]?.[0] ?? ''}${
			alt?.split(' ')?.[1]?.[0] ?? ''
		}`;
	}, [alt]);

	return (
		<MuiAvatar {...rest} alt={alt} sx={styles}>
			{children || initials}
		</MuiAvatar>
	);
};
