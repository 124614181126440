import { useServices } from 'core/services';
import { tokenKeys } from 'core/utils/query-key-factory';
import { useQuery } from 'react-query';

export const useGetInvitationToken = (
	communityId: string,
	tokenId: string,
	options: { enabled: boolean } = { enabled: true }
) => {
	const { api } = useServices();

	return useQuery(
		tokenKeys.community(communityId, tokenId),
		() =>
			api
				.getInvitationToken(communityId, tokenId)
				.then(response => response.data),

		{
			enabled: !!communityId && !!tokenId && options.enabled,
			notifyOnChangeProps: 'tracked',
			refetchOnMount: true,
			staleTime: 10000, // 10 seconds
		}
	);
};
