import { RouteObject } from 'react-router-dom';
import {
	createAliasRedirectElement,
	AliasNavigationProps,
} from './alias-redirect';
import { createTitleElement } from './set-title';

type Options = {
	exact?: boolean;
	title?: string;
	alias?: {
		path: string;
		getParams?: AliasNavigationProps['getParams'];
		CustomComponent?: AliasNavigationProps['CustomComponent'];
	};
};

type InnerRouteObject = Omit<RouteObject, 'children'> & {
	name?: string;
	children?: InnerRouteObject[];
};

type ElementBuilderArg = (router: RouterBuilder) => InnerRouteObject;

type TreeRecord = {
	name: string;
	path: string;
	elementBuilder: ElementBuilderArg;
	options?: Options;
};

export class RouterBuilder {
	private routes = new Map<string, string>();
	private tree: TreeRecord[] = [];
	private buildCache: RouteObject[] | null = null;

	/**
	 * Adds a route to the router tree
	 *
	 * @param {string} name
	 * @param {string} path
	 * @param {(router: RouterBuilder) => RouteObject} [elementBuilder]
	 * @memberof RouterBuilder
	 */
	addRoute(
		name: string,
		path: string,
		elementBuilder: ElementBuilderArg,
		options?: Options
	) {
		this.routes.set(name, path);
		this.tree.push({ name, path, elementBuilder, options });
	}

	/**
	 * Builds the router tree based on react-router's useRoutes hook param
	 * check the doc here https://reactrouter.com/docs/en/v6/api#useroutes
	 *
	 * @return {*}
	 * @memberof RouterBuilder
	 */
	build() {
		if (this.buildCache) {
			return this.buildCache;
		}

		const result: RouteObject[] = [];
		const level: any = { result }; // @TODD: Fix the type

		const aliases: RouteObject[] = [];

		this.tree.forEach(entry => {
			const { elementBuilder, options } = entry;

			if (options?.exact === true) {
				const { children = [], ...rest } = elementBuilder(this);
				// Exact routes, needs to start with a slash
				const path = `/${entry.path.replace(/^\/+/, '')}`;
				children.forEach(child => {
					if (child.name) {
						this.routes.set(child.name, `${path}/${child.path}`);
					}
				});
				result.unshift({ ...rest, path, children });
				return;
			}

			if (options?.alias?.path) {
				const { path, getParams, CustomComponent } = options.alias;
				aliases.unshift({
					path: `/${path.replace(/^\/+/, '')}`,
					element: createAliasRedirectElement({
						targetRoute: entry.name,
						getParams,
						CustomComponent,
					}),
					children: [],
				});
			}

			const paths = entry.path === '/' ? ['/'] : entry.path.split('/');

			paths.reduce((record, name, i, a) => {
				let path = name === '' ? '/' : name;
				let isLast = i === a.length - 1;
				const nextPath = a[i + 1];

				// Handle case for descendant-routes
				if (nextPath === '*' && i === a.length - 2) {
					path = `${path.replace(/\/+$/, '')}/*`;
					isLast = true;
				}
				if (isLast && path === '*') return {};

				if (!record[path]) {
					record[path] = { result: [] };

					let routeRecord = {
						path,
						children: record[path].result,
					};

					if (isLast) {
						const { children = [], ...rest } = elementBuilder(this);

						if (options?.title) {
							rest.element = createTitleElement(
								options.title,
								rest.element
							);
						}

						routeRecord = {
							...routeRecord,
							...rest,
						};

						routeRecord.children.unshift(...children);
					}

					record.result.push(routeRecord);
				}

				return record[path];
			}, level);
		});

		this.buildCache = [...aliases, ...result];

		return this.buildCache;
	}

	/**
	 * Gets the full path for a given route name.
	 *
	 * @param {string} name
	 * @param {Record<string, unknown>} [params={}]
	 * @return {*}
	 * @memberof RouterBuilder
	 */
	getPathFor(
		name: string,
		params: Record<string, unknown> = {},
		queryParams: Record<string, unknown> = {}
	) {
		let url = this.routes.get(name) ?? '/';

		// Interpolate params in the url with the given params
		url = url.replace(/:([^(?!/)]*)/g, (a, b) => {
			const r = params[b];
			return typeof r === 'string' || typeof r === 'number' ? `${r}` : a;
		});

		// Remove /* for descendant-routes, and assume there is a redirect for index
		url = url.replace(/\/\*$/, '');

		if (Object.keys(queryParams).length > 0) {
			const queryString = Object.keys(queryParams)
				.map(key => `${key}=${queryParams[key]}`)
				.join('&');

			url += `?${queryString}`;
		}

		return url;
	}

	getRoutes() {
		return this.tree;
	}
}
