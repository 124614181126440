import { useMutation } from 'react-query';
import { useServices } from 'hooks';
import { patchSubscriptionPreferencesMutationKey } from 'core/utils/mutation-key-factory';
import { SubscriptionPreferencesInput } from 'core/services/api/api-client/types';
import { SubscriptionPreferencesResponse } from 'core/services/api/response-types';
import { BaseHttpError } from '../../services/api/errors';

export type PatchSubscriptionPreferencesMutationInput = {
	token: string;
} & SubscriptionPreferencesInput;

export const usePatchSubscriptionPreferences = () => {
	const { api } = useServices();

	const mutation = useMutation<
		SubscriptionPreferencesResponse,
		BaseHttpError,
		PatchSubscriptionPreferencesMutationInput
	>(
		input => {
			const { token, ...rest } = input;
			return api.patchSubscriptionPreferences(token, rest);
		},
		{
			mutationKey: patchSubscriptionPreferencesMutationKey,
		}
	);

	return mutation;
};
