import { Typography, Box, Button, Link } from 'components/common';
import error500Image from 'assets/images/error500.svg?url';
import { useTranslation } from 'hooks';
import config from 'config';
import { Trans } from 'react-i18next';
import { contentStyle } from './styles';

type ErrorProps = {
	onReset?: () => void;
};

export const Error500 = (props: ErrorProps) => {
	const { onReset } = props;
	const { t } = useTranslation();

	return (
		<Box sx={contentStyle}>
			<Box component='img' src={error500Image} />
			<Typography>
				<Trans
					i18nKey='error-page.we-were-unable-to-process'
					components={{
						mail: <Link href={`mailto:${config.supportEmail}`} />,
					}}
					values={{
						email: config.supportEmail,
					}}
				/>
			</Typography>
			{onReset ? (
				<Button onClick={onReset}>
					{t('error-page.take-me-back')}
				</Button>
			) : null}
		</Box>
	);
};
