import { Announcement } from 'core/types';
import { Box, Paper, Typography, Link, BoxProps } from '../../common';
import { containerStyle } from './styles';

export interface MiniAnnouncementListProps extends Omit<BoxProps, 'onClick'> {
	data: Announcement[];
	title: string;
	onClick?: (announcement: Announcement) => void;
}

export const MiniAnnouncementList = ({
	title,
	data,
	onClick,
	...props
}: MiniAnnouncementListProps) => {
	return (
		<Box {...props}>
			<Box mb={1}>
				<Typography variant='button2'>{title}</Typography>
			</Box>
			<Box component={Paper} elevation={0} sx={containerStyle}>
				<ul>
					{data?.map(item => {
						return (
							<li
								key={item.uuid}
								className='announcement-mini-list-item'
							>
								<Link
									color='primary.main'
									onClick={() => onClick?.(item)}
								>
									{item.title}
								</Link>
							</li>
						);
					})}
				</ul>
			</Box>
		</Box>
	);
};
