import {
	CheckboxField,
	FancyTable,
	FormControlLabel,
	Link,
	Typography,
} from 'components/common';
import { MembershipInvite } from 'core/types';
import { useTranslation } from 'hooks';
import { formatDate } from 'utils/dates';
import { cardStyles } from './styles';

export const InviteCard = ({
	invite,
	isSelected,
	onSelect,
}: {
	invite: MembershipInvite;
	isSelected: boolean;
	onSelect: (id: string) => void;
}) => {
	const { t } = useTranslation();

	return (
		<FancyTable.Row key={`pending-request-${invite.uuid}`}>
			<FancyTable.Row.Cell py={0}>
				<FormControlLabel
					control={
						<CheckboxField
							value={isSelected}
							onChange={() => onSelect(invite.uuid)}
						/>
					}
					label={
						<Typography variant='body1'>
							{invite.method === 'sms' ? (
								invite.phone
							) : (
								<Link href={`mailto:${invite.email}`}>
									{invite.email}
								</Link>
							)}
						</Typography>
					}
					sx={cardStyles}
				/>
			</FancyTable.Row.Cell>
			<FancyTable.Row.Cell py={0}>
				<Typography sx={cardStyles}>
					{`${invite.inviter.first_name} ${invite.inviter.last_name}`}
				</Typography>
			</FancyTable.Row.Cell>
			<FancyTable.Row.Cell py={0}>
				<Typography sx={cardStyles}>
					{t(`invites.${invite.status}`)}
				</Typography>
			</FancyTable.Row.Cell>
			<FancyTable.Row.Cell py={0}>
				<Typography sx={cardStyles}>
					{formatDate(invite.created_dt, 'M/d/yy')}
				</Typography>
			</FancyTable.Row.Cell>
		</FancyTable.Row>
	);
};
