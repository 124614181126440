import { SxProps } from '@mui/system';
import { Theme } from 'theme';
import { colord } from 'colord';

export const containerStyle: SxProps<Theme> = theme => ({
	'& .rcc-container': {
		position: 'fixed',
		left: 0,
		alignItems: 'baseline',
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		width: '100%',
		padding: theme.spacing(2, 8),
		background: colord(theme.primaryColors.primary4 as string)
			.lighten(0.5)
			.toHex(),
		color: theme.palette.text.primary,
		zIndex: 999999,

		'& .rcc-link': {
			textDecoration: 'underline',
		},

		'& .MuiButton-root': {
			letterSpacing: -0.4,
			textTransform: 'none',
			borderRadius: '5px',
			fontWeight: '700',
			fontSize: 16,

			'&.MuiButton-textPrimary': {
				color: theme.palette.text.primary,
			},

			'&.MuiButton-containedPrimary': {
				background: theme.primaryColors.primary3,
			},
		},
	},

	'& .rcc-content': {
		fontSize: 16,
		fontWeight: '600',
		flex: '1 0 300px',
		'& > a': {
			color: theme.primaryColors.primary3,
		},
	},
});
