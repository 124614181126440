import { identityWithParams } from 'core/utils/request-with-params';
import { BaseHttpClient } from '../base-http-client';
import {
	CheckEmailResponse,
	IdentityResponse,
	PatchPasswordResponse,
	RequestResetPasswordResponse,
	ResetPasswordResponse,
} from '../response-types';
import { IdentityInput, PatchIdentityInput } from './types';

export const createIdentityApi = (http: BaseHttpClient) => ({
	/**
	 * Creates a new identity
	 * @param input
	 * @returns
	 */
	postIdentity(input: IdentityInput) {
		const { firstName, lastName, ...rest } = input;

		return http
			.post<IdentityResponse>(
				'identity',
				{
					...rest,
					first_name: firstName,
					last_name: lastName,
				},
				{
					params: {
						with: identityWithParams,
					},
				}
			)
			.then(response => response.data);
	},
	/**
	 * Updates an existing identity
	 *
	 * @param {PatchIdentityInput} input
	 * @return {*}
	 */
	patchIdentity(identityId: string, input: PatchIdentityInput) {
		const {
			firstName,
			lastName,
			email,
			detectTimezone,
			passwordNew,
			passwordOld,
			logMeOut,
			...rest
		} = input;

		return http
			.patch<IdentityResponse>(
				`identity/${identityId}`,
				{
					...rest,
					email,
					first_name: firstName,
					last_name: lastName,
					detect_timezone: detectTimezone,
					...(passwordNew && {
						password_new: passwordNew,
						password_new_confirm: passwordNew,
						log_me_out: !!logMeOut,
					}),
					...(passwordOld && { password_old: passwordOld }),
				},
				{
					params: {
						with: identityWithParams,
					},
				}
			)
			.then(response => response.data);
	},

	/**
	 * Gets the identity with the given id.
	 *
	 * @param id
	 */
	getIdentity(id: string) {
		return http
			.get<IdentityResponse>(`identity/${id}`, {
				with: identityWithParams,
			})
			.then(response => response.data);
	},

	/**
	 * Checks if there is an identity with the submitted email
	 *
	 * @param email
	 */
	checkEmail(email: string) {
		return http
			.get<CheckEmailResponse>(`identity-email/${email}`)
			.then(response => response.data);
	},

	postResetPasswordRequest(username: string) {
		return http
			.post<RequestResetPasswordResponse>('password-reset-request', {
				username,
			})
			.then(response => response.data);
	},

	patchPassword(password: string, logout: boolean, token: string) {
		return http
			.patch<PatchPasswordResponse>(
				'identity/me/login',
				{
					password,
					logout,
				},
				{
					params: {
						with: identityWithParams,
					},
					headers: {
						'X-ACCESS-TOKEN': token,
					},
				}
			)
			.then(response => response.data);
	},

	checkResetPasswordToken(tokenId: string) {
		return http
			.get<ResetPasswordResponse>(`oauth-token/${tokenId}`, {
				scope: 'reset_password',
			})
			.then(response => response.data);
	},
});
