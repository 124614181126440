import { Box, Typography } from 'components/common';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { NavigationButton } from './navigation';

export type CalendarWidgetNavigationProps = {
	display: string;
	onNext: () => void;
	onPrev: () => void;
};

export const CalendarWidgetNavigation = ({
	display,
	onNext,
	onPrev,
}: CalendarWidgetNavigationProps) => {
	return (
		<Box display='flex' justifyContent='flex-start' alignItems='center'>
			<NavigationButton size='small' variant='contained' onClick={onPrev}>
				<ArrowBackIosNewIcon />
			</NavigationButton>
			<NavigationButton size='small' variant='contained' onClick={onNext}>
				<ArrowForwardIosIcon />
			</NavigationButton>
			<Box marginX='auto' textAlign='center'>
				<Typography variant='h5' fontSize={24}>
					{display}
				</Typography>
			</Box>
		</Box>
	);
};
