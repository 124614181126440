import { BaseHttpError } from 'core/services/api/errors';
import { useServices } from 'hooks';
import { useMutation } from 'react-query';
import { SearchResult } from 'core/types';
import { SearchInput } from 'core/services/api/api-client/types';
import { communityKeys } from 'core/utils/query-key-factory';

export const useSearchCommunity = () => {
	const { api } = useServices();

	const mutation = useMutation<SearchResult[], BaseHttpError, SearchInput>({
		mutationKey: communityKeys.communitySearch(),
		mutationFn: input =>
			api.searchCommunities(input).then(response => response.data),
	});

	return mutation;
};
