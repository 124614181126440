import { SearchResult } from 'core/types';
import { Avatar, Box, Paper, Typography } from 'components/common';
import { useImageServer, useRouter } from 'hooks';
import defaultImage from 'assets/images/community_profile.svg?url';
import { useCallback } from 'react';
import { resultStyle, infoStyle, imageStyle, textStyle } from './styles';

interface SearchResultsCardProps {
	community: SearchResult;
	loggedId: boolean;
}

export const SearchResultsCard = ({
	community,
	loggedId,
}: SearchResultsCardProps) => {
	const communityImage = useImageServer(community?.image, {
		w: 240,
		h: 240,
		fit: 'crop',
	});

	const router = useRouter();

	const handleOnClick = useCallback(() => {
		if (loggedId) {
			router.navigate('request.join', {
				communityId: community?.slug ?? '',
			});
		} else {
			router.navigate('request.select-email', {
				communityId: community?.slug ?? '',
			});
		}
	}, [community, loggedId]);

	return (
		<Box
			key={community.uuid}
			component={Paper}
			px={4.5}
			py={3}
			elevation={0}
			sx={resultStyle}
			onClick={handleOnClick}
		>
			<Avatar sx={imageStyle} src={communityImage?.url ?? defaultImage} />
			<Box sx={infoStyle}>
				<Typography mb={1} variant='h6' sx={textStyle}>
					{community.name}
				</Typography>
				{community.location.description && (
					<Typography mb={1} variant='body1'>
						{community.location.description}
					</Typography>
				)}
				<Typography sx={textStyle} variant='body1'>
					{community.description}
				</Typography>
			</Box>
		</Box>
	);
};
