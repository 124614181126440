import Typography, { TypographyProps } from '@mui/material/Typography';

export interface HeadingProps extends TypographyProps {
	variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
}

export const Heading = (props: HeadingProps) => {
	const { variant = 'h1', ...rest } = props;

	return <Typography {...rest} variant={variant} />;
};
