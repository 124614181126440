import { useServices } from 'core/services';
import { CommunityMembershipInvitesResponse } from 'core/services/api/response-types';
import { communityKeys } from 'core/utils/query-key-factory';
import { useInfiniteQuery } from 'react-query';
import { BaseHttpError } from 'core/services/api/errors';
import { getPageParams } from 'core/utils/pagination';
import { MembershipInvite } from 'core/types';

export const useGetPendingInvites = (communityId: string) => {
	const { api, authentication } = useServices();
	const isLoggedIn = authentication.isAuthenticated();

	return useInfiniteQuery<
		CommunityMembershipInvitesResponse,
		BaseHttpError,
		MembershipInvite[]
	>(
		communityKeys.pendingInvites(communityId),
		({ pageParam = null, signal }) =>
			api.getCommunityMembershipInvitations(communityId, {
				pagination: pageParam,
				signal,
			}),
		{
			enabled: !!communityId && isLoggedIn,
			select: response => {
				return {
					pages: response.pages.map(page => page.data),
					pageParams: response.pageParams,
				};
			},
			getPreviousPageParam: firstPage =>
				getPageParams(firstPage.pagination).previousParam,
			getNextPageParam: lastPage =>
				getPageParams(lastPage.pagination).nextParam,
			refetchOnMount: true,
			keepPreviousData: true,
			notifyOnChangeProps: 'tracked',
		}
	);
};
