import produce from 'immer';

import { Reducer, useEffect, useReducer } from 'react';
import { Portal } from './portal';
import { Dialog, DialogProps } from './dialog';

export type ConfirmDialog = {
	open: () => void;
	close: () => void;
};

export type DialogPayload<T = void, E = void> = Omit<
	DialogProps<T, E>,
	'id' | 'contextData'
>;
export type DialogPayloadWithContext<T = void, E = void> = Omit<
	DialogProps<T, E>,
	'id'
>;

type DialogActions =
	| {
			type: 'ADD';
			payload: DialogProps;
	  }
	| {
			type: 'REMOVE';
			payload: string;
	  };

type DialogPortalState = Array<DialogProps & { id: string }>;

const dialogReducer: Reducer<DialogPortalState, DialogActions> = (
	state: DialogPortalState = [],
	action: DialogActions
) => {
	return produce<DialogPortalState>(state, draft => {
		if (action.type === 'ADD') {
			draft.push(action.payload);
		} else if (action.type === 'REMOVE') {
			const index = draft.findIndex(
				dialog => dialog.id === action.payload
			);
			if (index !== -1) {
				draft.splice(index, 1);
			}
		}
	});
};

export const ADD_DIALOG_EVENT = 'add-dialog-event' as const;
export const REMOVE_DIALOG_EVENT = 'remove-dialog-event' as const;

export const DialogPortal = () => {
	const [state, dispatch] = useReducer(dialogReducer, []);

	const handleRemove = (id: string) => {
		dispatch({ type: 'REMOVE', payload: id });
	};

	useEffect(() => {
		const handleOnAdd = (event: CustomEvent<DialogProps>) => {
			dispatch({ type: 'ADD', payload: event.detail });
		};

		const handleOnRemove = (event: CustomEvent<string>) => {
			handleRemove(event.detail);
		};

		document.addEventListener(ADD_DIALOG_EVENT, handleOnAdd, false);
		document.addEventListener(REMOVE_DIALOG_EVENT, handleOnRemove, false);

		return () => {
			document.removeEventListener(ADD_DIALOG_EVENT, handleOnAdd);
			document.removeEventListener(REMOVE_DIALOG_EVENT, handleOnRemove);
		};
	}, []);

	return (
		<Portal>
			{state.map(child => (
				<Dialog
					key={child.id}
					{...child}
					closeModal={handleRemove.bind(null, child.id)}
				/>
			))}
		</Portal>
	);
};
