import { SelectProps } from '@mui/material/Select';
import React, { useMemo } from 'react';
import { TextField, TextFieldProps } from '../text-field';
import { SelectItem } from './select-item';
import { Typography } from '../../text';

interface SelectFieldProps
	extends Omit<TextFieldProps, 'multiline' | 'variant'> {
	multiple?: boolean;
	renderValue?: SelectProps['renderValue'];
	displayEmpty?: boolean;
	loading?: boolean;
}

export const SelectField = (props: SelectFieldProps) => {
	const {
		multiple,
		placeholder,
		SelectProps: SelectPropsFromProps,
		renderValue,
		children,
		displayEmpty,
		loading,
		...rest
	} = props;

	const items = useMemo(() => {
		return React.Children.map(children, child => {
			return React.cloneElement(child as React.ReactElement<any>, {
				multiple,
			});
		});
	}, [multiple, children]);

	const MenuProps = useMemo(() => {
		return {
			anchorOrigin: {
				vertical: 'bottom' as const,
				horizontal: 'center' as const,
			},
			MenuListProps: {
				disablePadding: false,
			},
			...SelectPropsFromProps?.MenuProps,
		};
	}, [SelectPropsFromProps]);

	const SelectProps = useMemo(() => {
		return {
			...SelectPropsFromProps,
			renderValue,
			multiple,
			displayEmpty,
			MenuProps,
		};
	}, [SelectPropsFromProps, displayEmpty, renderValue, multiple, MenuProps]);

	return (
		<TextField {...rest} select SelectProps={SelectProps}>
			{loading && (
				<SelectItem disabled value=''>
					Loading...
				</SelectItem>
			)}
			{placeholder && !loading && (
				<SelectItem disabled value=''>
					<Typography
						color={theme => theme.secondaryColors.secondary1}
					>
						{placeholder}
					</Typography>
				</SelectItem>
			)}
			{items}
		</TextField>
	);
};
