import {
	Box,
	CheckboxField,
	FancyTable,
	FormControlLabel,
	Link,
	Typography,
} from 'components/common';
import { MembershipRequest } from 'core/types';
import { formatDate } from 'utils/dates';
import { cardStyles } from './styles';

export const PendingRequestCard = ({
	request,
	isSelected,
	onSelect,
}: {
	request: MembershipRequest;
	isSelected: boolean;
	onSelect: (id: string) => void;
}) => {
	return (
		<FancyTable.Row key={`pending-request-${request.id}`}>
			<FancyTable.Row.Cell py={0}>
				<FormControlLabel
					control={
						<CheckboxField
							value={isSelected}
							onChange={() => onSelect(request.id)}
						/>
					}
					label={
						<Box>
							<Typography variant='h6'>
								{request.requester.first_name}{' '}
								{request.requester.last_name}
							</Typography>
							<Typography variant='body1'>
								{request.requester.phone_readable}
							</Typography>
							<Typography variant='body1'>
								<Link
									href={`mailto:${request.requester.email}`}
								>
									{request.requester.email}
								</Link>
							</Typography>
						</Box>
					}
					sx={cardStyles}
				/>
			</FancyTable.Row.Cell>
			<FancyTable.Row.Cell py={0}>
				<Typography sx={cardStyles}>{request.notes}</Typography>
			</FancyTable.Row.Cell>
			<FancyTable.Row.Cell py={0}>
				<Typography sx={cardStyles}>
					{formatDate(request.created_dt, 'M/d/yy')}
				</Typography>
			</FancyTable.Row.Cell>
		</FancyTable.Row>
	);
};
