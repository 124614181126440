import { BaseHttpClient } from '../base-http-client';
import { AssetResponse, ImageResponse } from '../response-types';

export const createAssetsApi = (http: BaseHttpClient) => ({
	postImage(name: string, file: Blob) {
		return http
			.post<ImageResponse>('image', file, {
				headers: {
					'Content-Type': file.type,
				},
				params: {
					name,
				},
			})
			.then(response => response.data);
	},

	postAttachment(name: string, file: Blob) {
		return http
			.post<AssetResponse>('attachment', file, {
				headers: {
					'Content-Type': file.type,
				},
				params: {
					name,
				},
			})
			.then(response => response.data);
	},
});
