import { Box } from 'components';
import { Divider as MatDivider } from '@mui/material';

type DividerProps = React.ComponentProps<typeof Box>;

const Divider = (props: DividerProps) => (
	<Box {...props}>
		<MatDivider />
	</Box>
);

export { Divider };
