import {
	LocationValue,
	Box,
	FormControl,
	FormHelperText,
	TextFieldProps,
	IconButton,
	Icon,
	renderHelperText,
	LocationField,
} from 'components/common';
import InputLabel from '@mui/material/InputLabel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useControlledValue } from 'hooks';
import { useCallback } from 'react';

export type LocationsFieldProps = Omit<
	TextFieldProps,
	| 'value'
	| 'onChange'
	| 'onBlur'
	| 'onFocus'
	| 'multiline'
	| 'type'
	| 'rows'
	| 'maxRows'
	| 'minRows'
	| 'select'
	| 'SelectProps'
	| 'type'
	| 'defaultValue'
> & {
	name: string;
	value: LocationValue[];
	onChange?: (values: LocationValue[]) => void;
};

export const LocationsField = (props: LocationsFieldProps) => {
	const {
		label,
		InputLabelProps,
		value,
		onChange,
		helperText,
		placeholder,
		meta,
		...rest
	} = props;

	const [innerValues, setValues] = useControlledValue<LocationValue[]>(
		value,
		onChange
	);

	const { error, submitError } = meta ?? {};

	const innerHelperText = renderHelperText(meta ?? {}, helperText);

	const handleOnRemove = useCallback(
		index => {
			const nextLocations = innerValues.filter((_, i) => i !== index);
			setValues(nextLocations);
		},
		[innerValues]
	);

	const handleOnAdd = useCallback(
		(location: LocationValue) => {
			if (!location) return;

			const currentLocations = [...innerValues];

			if (currentLocations.some(l => l?.address === location.address))
				return;

			setValues([...currentLocations, location]);
		},
		[innerValues]
	);

	return (
		<FormControl {...rest} fullWidth error={!!(error || submitError)}>
			{label && <InputLabel {...InputLabelProps}>{label}</InputLabel>}
			{innerValues.length > 0 ? (
				<Box>
					<List dense>
						{innerValues.map((location, index) => (
							<ListItem
								disableGutters
								key={location?.address}
								secondaryAction={
									<IconButton
										edge='end'
										aria-label='delete'
										onClick={() => {
											handleOnRemove(index);
										}}
									>
										<Icon name='delete' color='error' />
									</IconButton>
								}
							>
								<ListItemText
									sx={{ pl: 0 }}
									primary={location?.address}
									primaryTypographyProps={{
										fontWeight: 'bold',
									}}
								/>
							</ListItem>
						))}
					</List>
				</Box>
			) : null}
			<Box>
				<LocationField
					fullWidth
					placeholder={placeholder}
					onChange={handleOnAdd}
				/>
			</Box>
			{innerHelperText && (
				<FormHelperText>{innerHelperText}</FormHelperText>
			)}
		</FormControl>
	);
};
