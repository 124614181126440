import { hasSameDay } from 'components/calendar';
import { Box, Paper, Typography, RouterLink } from 'components/common';
import { ScheduledEvent, Community } from 'core/types';
import { useEventMetadata, useRouter, useTranslation } from 'hooks';
import { DateTime } from 'luxon';
import { formatDate } from 'utils/dates';
import { taskRowStyle } from './styles';

export type MemberScheduledTasksCardProps = {
	tasks: ScheduledEvent[];
	timezone: string;
	community: Community;
};

export const MemberScheduledTasksCard = ({
	tasks,
	timezone,
	community,
}: MemberScheduledTasksCardProps) => {
	const { t } = useTranslation();
	const router = useRouter();

	return (
		<Box>
			<Typography variant='button2'>
				{t('member-profile.scheduled-tasks', { count: tasks.length })}
			</Typography>
			{tasks.length > 0 && (
				<>
					{tasks.map(task => (
						<TaskRow
							key={task.uuid}
							task={task}
							timezone={timezone}
							communityId={community.slug}
						/>
					))}
					<RouterLink
						to={router.getPathFor('community.calendar', {
							communityId: community.slug,
							calendarId: community.calendar?.uuid,
						})}
					>
						<Typography>
							{t('member-profile.see-all-tasks')}
						</Typography>
					</RouterLink>
				</>
			)}
			{tasks.length === 0 && (
				<Box component={Paper} elevation={0} p={3} mt={1} mb={3}>
					<Typography>{t('member-profile.no-tasks')}</Typography>
				</Box>
			)}
		</Box>
	);
};

const TaskRow = ({
	task,
	timezone,
	communityId,
}: {
	task: ScheduledEvent;
	timezone: string;
	communityId: string;
}) => {
	const { t } = useTranslation();
	const router = useRouter();

	const { eventIcon, startDate, startDateTime, endDateTime, isAllDay } =
		useEventMetadata(task);

	const fixedStartDate = startDateTime
		? startDateTime.setZone(timezone)
		: null;

	const fixedEndDate = endDateTime ? endDateTime.setZone(timezone) : null;

	let displayedDate = (
		fixedStartDate ? fixedStartDate : startDate
	).toLocaleString(DateTime.DATE_SHORT);

	if (fixedStartDate && fixedStartDate.isValid) {
		displayedDate += ` ${formatDate(fixedStartDate, 'h:mm a', timezone)}`;

		if (fixedEndDate && fixedEndDate.isValid) {
			if (hasSameDay(fixedStartDate, fixedEndDate)) {
				displayedDate += ` - ${formatDate(
					fixedEndDate,
					'h:mm a',
					timezone
				)}`;
			} else {
				displayedDate += ` ${t(
					'common-label.to'
				)} ${fixedEndDate.toLocaleString(
					DateTime.DATE_SHORT
				)} ${formatDate(fixedEndDate, 'h:mm a', timezone)}`;
			}
		}
	} else if (isAllDay) {
		displayedDate += ` ${t('event.all-day-task')}`;
	}

	return (
		<Box
			component={Paper}
			elevation={0}
			p={3}
			mt={1}
			mb={3}
			display='flex'
			alignItems='flex-start'
		>
			<Box component='img' src={eventIcon} width={48} height={48} />
			<Box sx={taskRowStyle}>
				<RouterLink
					className='link'
					to={router.getPathFor('community.calendar.event.view', {
						communityId,
						eventId: task.uuid,
						calendarId: task.calendar.uuid,
					})}
				>
					<Typography variant='button2'>{task.name}</Typography>
				</RouterLink>
				<Typography className='date' variant='caption'>
					{displayedDate}
				</Typography>
			</Box>
		</Box>
	);
};
