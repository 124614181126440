import { BaseHttpError } from 'core/services/api/errors';
import { RequestToJoin } from 'core/types';
import { postRequestToJoinMutationKey } from 'core/utils/mutation-key-factory';
import { useServices } from 'hooks';
import { useMutation, useQueryClient } from 'react-query';

interface PostRequestToJoinInput {
	communityId: string;
	notes: string;
}

export const usePostRequestToJoin = () => {
	const { api } = useServices();
	const queryClient = useQueryClient();

	return useMutation<RequestToJoin, BaseHttpError, PostRequestToJoinInput>(
		({ communityId, notes }) => {
			return api
				.postRequestToJoin(communityId, notes)
				.then(response => response.data);
		},
		{
			mutationKey: postRequestToJoinMutationKey,
			onSuccess: () => {
				queryClient.invalidateQueries(['communities', 'lists']);
				queryClient.invalidateQueries(['current-identity']);
			},
		}
	);
};
