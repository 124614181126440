import { Box, Paper } from 'components/common';
import { TruncatedContent } from 'components/common/truncated-content';
import { Note } from 'core/types';
import { useTranslation, useVerifyActions } from 'hooks';
import { useCallback } from 'react';
import {
	FeedCardContent,
	FeedCardHeader,
	useFeedCardMenu,
} from '../../community/feed/shared';
import { titleStyle } from './styles';

export type NoteCardProps = {
	note: Note;
	hideTitle?: boolean;
	truncate?: boolean;
	onClick?: (note: Note) => void;
	onDelete?: (note: Note) => void;
	onEdit?: (note: Note) => void;
};

export const NoteCard = (props: NoteCardProps) => {
	const { note, hideTitle, truncate, onClick, onDelete, onEdit } = props;

	const { t } = useTranslation();

	const handleOnClick = useCallback(() => {
		onClick?.(note);
	}, [onClick, note]);

	const handleOnEdit = useCallback(() => {
		onEdit?.(note);
	}, [onEdit, note]);

	const handleOnDelete = useCallback(() => {
		onDelete?.(note);
	}, [onDelete, note]);

	const actionObject = useVerifyActions(
		{
			type: 'note',
			identity_permissions: note.identity_permissions,
		},
		[
			{ action: 'update', handler: handleOnEdit, key: 'onEdit' },
			{ action: 'delete', handler: handleOnDelete, key: 'onDelete' },
		]
	);

	const menuAction = useFeedCardMenu(actionObject);

	return (
		<Box component={Paper} elevation={0} p={3} mb={2} mt={1}>
			<FeedCardHeader
				identity={note.owner}
				createdDt={note.created_dt ?? ''}
				renderAction={menuAction}
			/>
			<FeedCardContent
				title={
					!hideTitle
						? (
							<Box sx={titleStyle} component='span' onClick={handleOnClick}>
								{note.title}
							</Box>
							  ) ?? ''
						: undefined // prettier-ignore
				}
				content={note.content ?? ''}
				contentWrapper={
					<TruncatedContent
						showText={t('notes.view-full-note')}
						hideText={t('notes.hide-note')}
						enabled={truncate}
					/>
				}
			/>
		</Box>
	);
};
