import { BaseHttpError } from 'core/services';
import { Group } from 'core/types';
import { communityKeys } from 'core/utils/query-key-factory';
import { useServices } from 'hooks';
import { useQuery } from 'react-query';

export const useGetCommunityGroup = (communityId: string, groupId: string) => {
	const { api, authentication } = useServices();
	const isLoggedIn = authentication.isAuthenticated();

	return useQuery<Group, BaseHttpError>(
		communityKeys.group(communityId, groupId),
		() =>
			api.getGroup(communityId, groupId).then(response => response.data),
		{
			enabled: !!communityId && !!groupId && isLoggedIn,
			notifyOnChangeProps: 'tracked',
		}
	);
};
