import { proxy, subscribe, useSnapshot } from 'valtio';
import { devtools } from 'valtio/utils';
import { storage } from 'utils/storage';
import { useRef } from 'react';
import { StringKeys } from 'core/types';
import config from 'config';
import { AuthState, authState } from './auth';
import { AlertState, alertState } from './alert';
import { FeedFilterState, feedFilterState } from './feedFilter';

const PERSIST_KEY = 'lotsa@app-storage-2';

let state: {
	auth: AuthState;
	alert: AlertState;
	feedFilter: FeedFilterState;
};

export type Store = typeof state;

export const useStore = () => {
	return useSnapshot(state);
};

export const useTransientStore = () => {
	return useRef(state).current;
};

export const configureStore = async () => {
	let initialState = {
		auth: authState,
		alert: alertState,
		feedFilter: feedFilterState,
	};

	const log = (...args: unknown[]) =>
		config.debug.store && console.log(...args);

	try {
		const persistedValues = await storage.getItem(PERSIST_KEY);

		if (!persistedValues) throw new Error('Storage is not empty');

		const values = JSON.parse(persistedValues) as typeof initialState;

		initialState = (
			Object.keys(values) as StringKeys<typeof initialState>
		).reduce((carry, key) => {
			return {
				...carry,
				[key]: Object.assign(initialState[key], values[key]),
			};
		}, initialState);

		log('[store] loaded from storage');
	} catch (e) {
		// Nothing to do
	}

	state = proxy(initialState);

	subscribe(state, () => {
		log('[store] state changed', state);
		storage.setItem(PERSIST_KEY, JSON.stringify(state));
	});

	if (process.env.NODE_ENV === 'development') {
		devtools(state, { name: 'Lotsa Helping Hands', enabled: true });
	}

	return state;
};
