import { SxProps } from '@mui/system';
import { Theme } from 'theme';

export const containerStyle: SxProps<Theme> = {
	position: 'fixed',
	right: 0,
	left: 0,
	bottom: 0,
	zIndex: 2,
	'& > div': {
		justifyContent: 'center',
	},
};

export const separatorStyle: SxProps<Theme> = theme => ({
	marginTop: theme.spacing(6),
});
