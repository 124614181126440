import { useCallback } from 'react';
import {
	Box,
	Field,
	Form,
	Heading,
	LoadingButton,
	RouterLink,
	SelectField,
	SelectItem,
	Stack,
	TextField,
} from 'components/common';
import { useRouter, useTranslation } from 'hooks';
import { Distances } from 'pages/community/search/distances';

export type SearchFormSubmittedValues = {
	searchTerm: string;
	zipCode: string;
	withinDistance: string;
};

export type SearchFormProps = {
	onSubmit?: (values: SearchFormSubmittedValues) => void;
	isLoading: boolean;
	initialValues?: SearchFormSubmittedValues;
};

export const SearchForm = function SearchForm(props: SearchFormProps) {
	const { onSubmit, initialValues, isLoading } = props;
	const { t } = useTranslation();
	const router = useRouter();

	const handleOnSubmit = useCallback(
		({ searchTerm, zipCode, withinDistance }) => {
			onSubmit?.({
				searchTerm,
				zipCode,
				withinDistance,
			});
		},
		[onSubmit]
	);

	return (
		<Form onSubmit={handleOnSubmit} initialValues={initialValues}>
			{({ handleSubmit }) => (
				<form
					onSubmit={handleSubmit}
					data-test-id='search-community-form'
				>
					<Stack spacing={3} alignItems='center'>
						<Heading variant='h6'>
							{t('search.find-a-community')}
						</Heading>
						<Box alignSelf='stretch'>
							<Field
								name='searchTerm'
								component={TextField}
								type='text'
								placeholder={t('search.community-placeholder')}
								fullWidth
							/>
						</Box>
						<Box alignSelf='stretch'>
							<Field
								name='zipCode'
								component={TextField}
								type='text'
								placeholder={t('search.zip-code')}
								fullWidth
							/>
						</Box>
						<Box alignSelf='stretch'>
							<Field
								name='withinDistance'
								component={SelectField}
								initialValue={Distances.withinFiveMiles.value}
								fullWidth
								displayEmpty
							>
								{Object.values(Distances).map(distance => (
									<SelectItem
										key={distance.key}
										value={distance.value}
									>
										{t(`search.${distance.key}`)}
									</SelectItem>
								))}
							</Field>
						</Box>
						<LoadingButton
							sx={{ alignSelf: 'center' }}
							loading={isLoading}
							loadingIndicator={t('search.searching')}
							size='small'
							type='submit'
						>
							{t('search.search')}
						</LoadingButton>
						<RouterLink
							to={router.getPathFor('community.help-finding')}
						>
							{t("search.can't-find-your-team")}
						</RouterLink>
					</Stack>
				</form>
			)}
		</Form>
	);
};
