import { BoxProps } from '@mui/material';
import { Community } from 'core/types';
import { useImageServer } from 'hooks';
import { getCurrentLanguage } from 'utils/lang';
import { Avatar, Box, Typography, ListItem } from '../../common';
import { avatarStyle } from './styles';

interface PendingCommunityMenuItemProps extends BoxProps {
	community: Community;
}

export const PendingCommunityMenuItem = ({
	community,
	...rest
}: PendingCommunityMenuItemProps) => {
	const communityImage = useImageServer(community?.image, {
		w: 40,
		h: 40,
		fit: 'crop',
	});

	return (
		<ListItem sx={{ mx: 2, p: 2 }}>
			<>
				<Avatar sx={avatarStyle(40)} src={communityImage?.url} />
				<Box
					minWidth={0}
					maxWidth={getCurrentLanguage() === 'fr' ? 500 : 300}
				>
					<Typography
						variant='button2'
						overflow='hidden'
						textOverflow='ellipsis'
						display='block'
					>
						{community.name}
					</Typography>
				</Box>
			</>
		</ListItem>
	);
};
