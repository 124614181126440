import {
	Avatar,
	Box,
	Field,
	FormStack,
	LoadingButton,
	TextField,
} from 'components/common';
import { FormApi } from 'final-form';
import { useAvatarImage, useTranslation } from 'hooks';
import { sleep } from 'utils/delay';
import * as Yup from 'yup';
import { memo, useEffect, useRef } from 'react';
import { Form, FormOnSubmitReturn } from '../common/form/form';
import { useConstantValue } from '../../hooks/use-constant-value';
import { useDiscussion } from './context/context';

export type CommentFormValues = {
	message: string;
};

export type CommentFormProps = {
	submitText?: string;
	submittingText?: string;
	onSubmit?: (
		values: CommentFormValues
	) => FormOnSubmitReturn<CommentFormValues>;
	onCancel?: () => void;
	initialValues?: CommentFormValues;
	hideIdentity?: boolean;
	autoFocus?: boolean;
};

export const CommentForm = memo(function CommentForm({
	onCancel,
	onSubmit,
	submitText,
	submittingText,
	initialValues,
	hideIdentity = false,
	autoFocus = false,
}: CommentFormProps) {
	const { t } = useTranslation();

	const { identity } = useDiscussion();

	const avatarImage = useAvatarImage(identity);

	const handleOnSubmit = async (
		values: CommentFormValues,
		form: FormApi<CommentFormValues>
	) => {
		await sleep(1);

		const result = onSubmit?.(values);

		return Promise.resolve(result)
			.then(() => {
				form.restart({ message: '' });
			})
			.catch(error => error);
	};

	const validationSchema = useConstantValue(
		Yup.object({
			message: Yup.string().required(
				t('form.required', {
					name: t('comment-form.message'),
				})
			),
		})
	);

	const handleOnKeyDown = (form: FormApi<CommentFormValues>) => {
		return (event: React.KeyboardEvent<HTMLInputElement>) => {
			if (event.key === 'Enter' && !event.shiftKey) {
				event.preventDefault();

				if (form.getState().valid) {
					form.submit();
				}
			}

			if (event.key === 'Escape') {
				onCancel?.();
				form.restart({ message: '' });
			}
		};
	};

	const handleOnBlur = (form: FormApi<CommentFormValues>) => {
		return () => {
			const state = form.getFieldState('message');
			if (state?.error) {
				form.resetFieldState('message');
			}
		};
	};

	const cachedInitialValues = useConstantValue(initialValues || {});

	const inputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (autoFocus && inputRef.current) {
			const textLen = inputRef.current.value.length * 2;
			inputRef.current.focus();
			inputRef.current.setSelectionRange(textLen, textLen);
		}
	}, [autoFocus, inputRef]);

	return (
		<Box display='flex' alignItems='flex-start' mb={3}>
			{!hideIdentity && (
				<Box mr={2}>
					<Avatar {...avatarImage} />
				</Box>
			)}
			<Box flex={1}>
				<Form<CommentFormValues>
					initialValues={cachedInitialValues}
					onSubmit={handleOnSubmit}
					validationSchema={validationSchema}
					subscription={{
						pristine: true,
						invalid: true,
						submitting: true,
					}}
				>
					{({
						handleSubmit,
						pristine,
						invalid,
						submitting,
						form,
					}) => (
						<form onSubmit={handleSubmit} noValidate>
							<FormStack spacing={2} responsive={false}>
								<Box>
									<Field
										name='message'
										component={TextField}
										type='text'
										placeholder={t(
											'comment-form.write-comment'
										)}
										onKeyDown={handleOnKeyDown(form)}
										onBlur={handleOnBlur(form)}
										inputRef={inputRef}
										autoComplete='off'
										multiline
										fullWidth
									/>
								</Box>
								<Box display='flex' justifyContent='flex-end'>
									<LoadingButton
										loading={submitting}
										loadingIndicator={
											submittingText ||
											t('comment-form.adding-comment')
										}
										size='small'
										type='submit'
										disabled={
											pristine || invalid || submitting
										}
									>
										{submitText ||
											t('comment-form.add-comment')}
									</LoadingButton>
								</Box>
							</FormStack>
						</form>
					)}
				</Form>
			</Box>
		</Box>
	);
});
