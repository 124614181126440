import { SetupMutationFn } from 'core/configure/types';
import { BaseHttpError } from 'core/services/api/errors';
import { Comment } from 'core/types';
import { discussionKeys } from 'core/utils/query-key-factory';
import { isCancelledError, useMutation, QueryKey } from 'react-query';
import { deleteCommentMutationKey } from 'core/utils/mutation-key-factory';
import {
	cancelPreviousMutation,
	findRecordFromInfinityQueryCache,
	PaginatedRecordsSnapshot,
	removeRecordFromInfinityQueryCache,
	updateRecordFromInfinityQueryCache,
} from 'core/utils/optimistic-utils';
import produce from 'immer';
import { isPostPatchCommentMutation } from './use-patch-comment';

export interface DeleteCommentMutationInput {
	discussionId: string;
	commentId: string;
	parentId?: string;
}

export const useDeleteComment = () => {
	const mutation = useMutation<
		Comment,
		BaseHttpError,
		DeleteCommentMutationInput
	>(deleteCommentMutationKey);

	return mutation;
};

type MutationContext = {
	discussionId: string;
	commentId: string;
	listKey: QueryKey;
	listSnapshot: PaginatedRecordsSnapshot<Comment>;
};

export const setupDeleteComment: SetupMutationFn = (
	services,
	createTrackedParallelMutation,
	mutationTracker
) => {
	const { queryClient, api } = services;

	const mutation = createTrackedParallelMutation<
		null,
		BaseHttpError,
		DeleteCommentMutationInput,
		MutationContext
	>({
		mutationFn: ({ commentId }) => {
			// Means it is real note
			if (commentId.length === 22) {
				return api.deleteComment(commentId);
			}
			return Promise.resolve(null);
		},
		onMutate: async input => {
			const { commentId, discussionId, parentId } = input;

			await cancelPreviousMutation<MutationContext>(
				queryClient,
				mutation => {
					return (
						isPostPatchCommentMutation(mutation) &&
						mutation.state.context?.commentId === commentId
					);
				}
			);

			const listKey = parentId
				? discussionKeys.replies(discussionId, parentId)
				: discussionKeys.comments(discussionId);

			const { listSnapshot } =
				findRecordFromInfinityQueryCache<Comment>(
					queryClient,
					listKey,
					commentId
				) ?? {};

			// Remove from the actual list
			removeRecordFromInfinityQueryCache<Comment>(
				queryClient,
				listKey,
				commentId
			);

			// Update comment count
			queryClient.setQueryData<PaginatedRecordsSnapshot<Comment>>(
				discussionKeys.comments(discussionId),
				currentData => {
					if (!currentData) return currentData;
					return produce(currentData, draft => {
						if (draft.pages?.[0].data?.[0]?.discussion_stats) {
							const { comments } =
								draft.pages[0].data[0].discussion_stats;

							draft.pages[0].data[0].discussion_stats.comments =
								comments - 1;
						}
					});
				}
			);

			if (parentId) {
				// Remove reply in case it was included in the initial comment object
				const { record } =
					findRecordFromInfinityQueryCache<Comment>(
						queryClient,
						discussionKeys.comments(discussionId),
						parentId
					) ?? {};

				if (record) {
					updateRecordFromInfinityQueryCache(
						queryClient,
						discussionKeys.comments(discussionId),
						{
							...record,
							replies: (record?.replies ?? []).filter(
								reply => reply.uuid !== commentId
							),
						}
					);
				}
			}

			return {
				listKey,
				commentId,
				discussionId,
				listSnapshot,
			};
		},
		onError: (error, input, context) => {
			if (context?.listSnapshot && !isCancelledError(error)) {
				queryClient.setQueryData(
					context.listKey,
					context?.listSnapshot
				);
			}
		},
		onSettled: (data, error, { commentId }, context) => {
			if (context && !isCancelledError(error)) {
				if (commentId.length === 22) {
					// Only trigger the refetch when it is a real resource
					mutationTracker.queueInvalidations(context.listKey);
				}
			}
		},
		retry: 1,
	});

	queryClient.setMutationDefaults(deleteCommentMutationKey, mutation);
};
