import { SxProps } from '@mui/system';
import { Theme } from 'theme';

export const containerStyle: SxProps<Theme> = theme => ({
	py: theme.spacing(2),
	px: theme.spacing(3),
	mb: theme.spacing(2),
	mt: theme.spacing(1),
	'> ul': {
		margin: 0,
		pl: 2,

		'> li a': {
			cursor: 'pointer',
		},
	},
});
