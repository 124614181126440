import { SxProps } from '@mui/system';

export const buttonContainer: SxProps = {
	'button:first-child': {
		marginRight: 2,
	},
};

export const editBox: SxProps = {
	cursor: 'pointer',
};
