import { Box, Typography } from 'components';
import { FILTER_STATUSES } from 'components/calendar/constants';
import { useCalendar } from 'components/calendar/context';
import {
	useEventsForDay,
	VerticalEventTypesInfo,
} from 'components/calendar/shared';
import { DateTime } from 'luxon';
import { Fragment, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { CalendarEvent } from 'components/calendar/types';
import { EventsTooltipWrapper } from './events-tooltip';
import {
	dayStyle,
	dotIndicatorStyle,
	eventListItemStyle,
	infoContainerStyle,
} from './styles';
import { isToday } from '../../utils';

const DotIndicator = ({
	type,
}: {
	type: 'help-needed' | 'needs-met' | 'occasions';
}) => {
	return (
		<Box
			className={classNames({
				[type]: true,
				small: true,
			})}
			sx={dotIndicatorStyle}
		/>
	);
};

const EventListItem = ({ event }: { event: CalendarEvent }) => {
	const { formatDate, onEventClick } = useCalendar();

	return (
		<Box sx={eventListItemStyle}>
			<Box mr={2}>
				<Box
					className={classNames({
						occasions: event.category === 'occasion',
						'help-needed': event.volunteersAvailable > 0,
						'needs-met': true,
						large: true,
					})}
					sx={dotIndicatorStyle}
				/>
			</Box>
			<Box className='event-info' onClick={() => onEventClick(event)}>
				<Typography variant='body2' onClick={() => onEventClick(event)}>
					{event.name}
				</Typography>
				{event.startDateTime && event.startDateTime.isValid && (
					<Typography variant='body1'>
						{formatDate(event.startDateTime || '', 'h:mm a')}
						{event.endDateTime && event.endDateTime.isValid ? (
							<>
								{' - '}
								{formatDate(event.endDateTime, 'h:mm a')}
							</>
						) : null}
						{'  '}
						{formatDate(event.startDateTime, 'ZZZZ')}
					</Typography>
				)}
				{event.locations.length > 0 && (
					<Box pt={2}>
						{event.locations.map(location => (
							<Typography
								key={`location-${location.uuid}`}
								variant='body1'
							>
								{location.name}
							</Typography>
						))}
					</Box>
				)}
			</Box>
		</Box>
	);
};

export const Day = memo(function Day({ day }: { day: DateTime }) {
	const { formatDate, timezone } = useCalendar();
	const { t } = useTranslation();

	const events = useEventsForDay(day);

	const { hasOccasions, hasNeedsHelp, hasNeedsMet } = useMemo(() => {
		let hasOccasions = false;
		let hasNeedsHelp = false;
		let hasNeedsMet = false;

		for (const event of events) {
			hasOccasions =
				hasOccasions || event.status === FILTER_STATUSES.OCCASIONS;
			hasNeedsHelp =
				hasNeedsHelp || event.status === FILTER_STATUSES.HELP_NEEDED;
			hasNeedsMet =
				hasNeedsMet || event.status === FILTER_STATUSES.NEEDS_MET;

			if (hasOccasions && hasNeedsHelp && hasNeedsMet) break;
		}

		return { hasOccasions, hasNeedsHelp, hasNeedsMet };
	}, [events]);

	return (
		<EventsTooltipWrapper
			title={
				<Fragment>
					{isToday(day, timezone) && (
						<Typography variant='h4'>
							{t('calendar-widget.todays-activities')}
						</Typography>
					)}
					<Typography variant='body1'>
						{formatDate(day, 'DDDD')}
					</Typography>
					<Box sx={infoContainerStyle}>
						<VerticalEventTypesInfo />
					</Box>
					<Box>
						{events.map(event => (
							<Fragment key={`event-widget-${event.uuid}`}>
								<EventListItem event={event} />
							</Fragment>
						))}
					</Box>
				</Fragment>
			}
		>
			<Box sx={dayStyle}>
				<Typography variant='body1'>
					{formatDate(day, 'EEE')}
				</Typography>
				<Typography variant='h4'>{formatDate(day, 'd')}</Typography>
				<Box className='indicators'>
					{hasNeedsHelp && <DotIndicator type='help-needed' />}
					{hasNeedsMet && <DotIndicator type='needs-met' />}
					{hasOccasions && <DotIndicator type='occasions' />}
				</Box>
			</Box>
		</EventsTooltipWrapper>
	);
});
