import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import config from 'config';
import { FC } from 'react';

export const MicrosoftProvider: FC = ({ children }) => {
	const client = new PublicClientApplication(config.microsoft);

	return <MsalProvider instance={client}>{children}</MsalProvider>;
};
