import { Box, BoxProps } from 'components/common';

type FeedCardFooterProps = {
	children?: React.ReactNode;
} & BoxProps;

export const FeedCardFooter = ({ children, ...rest }: FeedCardFooterProps) => {
	return (
		<Box mt={2} {...rest}>
			{children}
		</Box>
	);
};
