import {
	Cookie,
	createApiClient,
	createAnalytics,
	createErrorLogger,
	createUploader,
	Services,
} from 'core/services';
import config from 'config';
import { prepareMutations } from 'core/configure';
import { configureStore } from 'store';
import { configureI18n } from 'i18n';
import {
	configureAuthentication,
	configureErrorReport,
	configureNotification,
	configureQueryClient,
} from 'configure';

export async function configureAppServices() {
	const store = await configureStore();
	const i18n = await configureI18n();
	const cookie = Cookie();
	const queryClient = configureQueryClient();
	const authentication = configureAuthentication(store);
	const notification = configureNotification(store);
	const errorReport = configureErrorReport(notification, i18n);
	const errorLogger = createErrorLogger(config, queryClient);
	const analytics = createAnalytics(config, cookie);
	const api = await createApiClient(config, authentication, errorReport);
	const uploader = createUploader(api);

	const services: Services = {
		config,
		cookie,
		i18n,
		authentication,
		notification,
		queryClient,
		errorReport,
		analytics,
		api,
		uploader,
		errorLogger,
	};

	prepareMutations(services);

	return services;
}
