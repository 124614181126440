import { SxProps } from '@mui/system';
import { Theme } from 'theme';

export const tabsStyle: SxProps<Theme> = theme => ({
	minHeight: 'unset',
	'& .MuiTabs-flexContainer': {
		display: 'inline-flex',
		borderWidth: 1,
		borderStyle: 'solid',
		borderColor: theme.palette.common.black,
		borderRadius: '3px',
		overflow: 'hidden',
	},
	'& .MuiTabs-indicator': {
		display: 'flex',
		justifyContent: 'center',
		backgroundColor: 'transparent',
	},
	'& .MuiTabs-indicatorSpan': {
		display: 'none',
	},
});
