import { useCallback, useEffect, useState } from 'react';
import { Box, Typography } from 'components/common';
import { calendarUtils } from 'components/calendar/utils';
import { useCalendar } from 'components/calendar/context';
import { FilterForm, SubmittedValues } from './filter-form';
import { CalendarNavigation } from '../../shared/navigation';
import { AgendaGrid } from './grid';

export const Agenda = () => {
	const { displayed, setDisplayed, formatDate, loadEvents, timezone } =
		useCalendar();

	const [filter, setFilter] = useState<SubmittedValues>({
		filterOption: 'monthly',
		dates: {
			startDate: displayed.startOf('month'),
			endDate: displayed.endOf('month'),
		},
	});

	const { filterOption, dates } = filter;

	const handleOnSubmit = useCallback(
		({ filterOption, dates }: SubmittedValues) => {
			setFilter({ filterOption, dates });
		},
		[]
	);

	const handleOnPressNext = useCallback(() => {
		if (filterOption === 'monthly') {
			setDisplayed(displayed.plus({ month: 1 }));
		} else if (filterOption === 'weekly') {
			setDisplayed(displayed.plus({ week: 1 }));
		}
	}, [displayed, filterOption]);

	const handleOnPressPrev = useCallback(() => {
		if (filterOption === 'monthly') {
			setDisplayed(displayed.minus({ month: 1 }));
		} else {
			setDisplayed(displayed.minus({ week: 1 }));
		}
	}, [displayed, filterOption]);

	const renderNavigationDisplay = useCallback(() => {
		if (filterOption === 'monthly') {
			return formatDate(displayed, 'MMMM yyyy');
		}

		const startDate = calendarUtils.getFirstDayOfWeek(displayed);
		const endDate = calendarUtils.getLastDayOfWeek(displayed);

		return `${formatDate(startDate, 'MMMM d')} - ${formatDate(
			endDate,
			'MMMM d'
		)}`;
	}, [filterOption, displayed]);

	useEffect(() => {
		if (filterOption === 'monthly' || filterOption === 'weekly') {
			setFilter(filter => ({
				...filter,
				dates: {
					startDate:
						filterOption === 'monthly'
							? displayed.startOf('month')
							: calendarUtils.getFirstDayOfWeek(displayed),
					endDate:
						filterOption === 'monthly'
							? displayed.endOf('month')
							: calendarUtils.getLastDayOfWeek(displayed),
				},
			}));
		}
	}, [displayed, filterOption]);

	useEffect(() => {
		loadEvents(dates);
	}, [dates]);

	return (
		<>
			<Box paddingTop={3}>
				<Box paddingX={2}>
					<Typography variant='button2' fontSize={20}>
						Agenda View
					</Typography>
					<Box display='flex' marginTop={2}>
						<FilterForm
							initialValues={filter}
							onSubmit={handleOnSubmit}
							timezone={timezone}
						/>
					</Box>
				</Box>
				{['monthly', 'weekly'].includes(filterOption) ? (
					<CalendarNavigation
						display={renderNavigationDisplay()}
						onNext={handleOnPressNext}
						onPrev={handleOnPressPrev}
					/>
				) : null}
			</Box>
			<Box>
				<AgendaGrid {...dates} />
			</Box>
		</>
	);
};
