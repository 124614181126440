import { SxProps } from '@mui/system';
import { Theme } from 'theme';

export const containerStyle: SxProps<Theme> = {
	'> div': {
		mb: 6,
	},
};

export const itemsStyle: SxProps<Theme> = theme => ({
	h3: {
		fontSize: 16,
		lineHeight: 1.5,
		fontFamily: theme.typography.fontFamily,
	},
	h5: {
		fontSize: 20,
		lineHeight: 1.5,
		fontWeight: '500',
		fontFamily: theme.typography.fontFamily,

		'&.dates-times': {
			fontWeight: 'bold',
		},
	},
	h6: {
		fontSize: 14,
		lineHeight: 1.5,
		fontFamily: theme.typography.fontFamily,
	},

	'& .description p': {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},

	'& .location-item a': {
		color: theme.palette.text.primary,
	},
});

export const permissionSectionStyleForCopy: SxProps<Theme> = theme => ({
	h3: {
		fontSize: 22,
		lineHeight: 1.5,
		fontWeight: '600',
		fontFamily: theme.typography.fontFamily,
	},
	h5: {
		fontSize: 16,
		lineHeight: 1.5,
		fontWeight: '500',
		fontFamily: theme.typography.fontFamily,
	},
});

export const permissionSectionStyleForDetails: SxProps<Theme> = theme => ({
	h3: {
		fontSize: 16,
		lineHeight: 1.5,
		fontFamily: theme.typography.fontFamily,
	},
	h5: {
		fontSize: 20,
		lineHeight: 1.5,
		fontWeight: '500',
		fontFamily: theme.typography.fontFamily,
	},
});

export const identityListCardStyle: SxProps<Theme> = {
	display: 'flex',
	position: 'relative',
	marginBottom: 2,
	padding: 3,
};

export const identityNameStyle: SxProps<Theme> = {
	lineHeight: '16px',
	fontWeight: 'bold',
};
