import { Fragment } from 'react';
import { Footer, Content } from '../shared';
import { AppHeader } from './header';

export const AppLayout: React.FC = ({ children }) => {
	return (
		<Fragment>
			<AppHeader />
			<Content>{children}</Content>
			<Footer />
		</Fragment>
	);
};
