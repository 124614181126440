import { Community } from 'core/types';
import { useImageServer, useRouter, useTranslation } from 'hooks';
import { SxProps } from '@mui/system';
import { Fragment } from 'react';
import { Trans } from 'react-i18next';
import classNames from 'classnames';
import {
	avatarStyle,
	titleStyle,
	badgeStyle,
	communityCardStyle,
} from './styles';
import {
	Avatar,
	Paper,
	PaperProps,
	Box,
	Typography,
	Link,
	RouterLink,
} from '../../common';

interface CommunityCardProps extends PaperProps {
	community: Community;
	currentRole: string;
}

const CommunityRouterLink: React.FC<{
	community: Community;
	sx: SxProps;
	type: 'invited' | 'pending' | null;
}> = ({ community, children, sx, type }) => {
	const router = useRouter();

	if (type) {
		if (type === 'invited') {
			return (
				<RouterLink
					to={router.getPathFor(
						'community.invitation.email-target',
						{
							communityId: community.slug,
						},
						{
							t: community.invite_token,
						}
					)}
					sx={sx}
				>
					{children}
				</RouterLink>
			);
		} else {
			return <Fragment>{children}</Fragment>;
		}
	}

	if (community.is_legacy) {
		return (
			<Link href={community.switch_to_url} sx={sx}>
				{children}
			</Link>
		);
	}

	return (
		<RouterLink
			to={router.getPathFor('community.home', {
				communityId: community.slug,
			})}
			sx={sx}
		>
			{children}
		</RouterLink>
	);
};

export const CommunityCard = ({
	community,
	currentRole,
	...rest
}: CommunityCardProps) => {
	const communityImage = useImageServer(community?.image, {
		w: 120,
		h: 120,
		fit: 'crop',
	});
	const { t } = useTranslation();

	let type: 'invited' | 'pending' | null = null;

	if (currentRole === t('switch.invited')) {
		type = 'invited';
	} else if (currentRole === t('switch.pending')) {
		type = 'pending';
	}

	return (
		<CommunityRouterLink
			community={community}
			sx={communityCardStyle}
			type={type}
		>
			<Box
				component={Paper}
				elevation={0}
				display='flex'
				flexWrap='nowrap'
				alignItems='center'
				p={3}
				{...rest}
			>
				<Avatar sx={avatarStyle(120)} src={communityImage?.url} />
				<Box display='flex' flexDirection='column' minWidth={0}>
					<Typography
						className='community-name'
						sx={titleStyle}
						variant='button2'
					>
						<span>{community.name}</span>
						{currentRole !== '' && (
							<Box
								className={classNames({
									'community-badge': true,
									[type as string]: true,
								})}
								component='span'
								sx={badgeStyle}
							>
								{currentRole}
							</Box>
						)}
					</Typography>
					{community.member_stats && (
						<Typography
							variant='caption'
							color={theme => theme.blacks[0.54]}
						>
							{t('switch.member', {
								count: community.member_stats.members,
							})}
						</Typography>
					)}
					{community?.contact_info?.contact_list && (
						<Typography variant='caption' fontWeight='bold'>
							<Trans
								i18nKey='switch.contact'
								components={{
									values: (
										<Typography
											variant='caption'
											fontWeight='400'
										/>
									),
								}}
								values={{
									count: community.contact_info.contact_list
										.length,
									name: community.contact_info.contact_list
										.map(
											({ first_name, last_name }) =>
												`${first_name} ${last_name}`
										)
										.join(', '),
								}}
							/>
						</Typography>
					)}
				</Box>
			</Box>
		</CommunityRouterLink>
	);
};
