import { FeedRecord } from 'core/types';
import noteIcon from 'assets/images/feed-note.svg?url';
import { useParams, useRouter, useTranslation, useVerifyActions } from 'hooks';
import { useDeleteNoteHandler } from 'components/notes';
import { useCallback } from 'react';
import { TruncatedContent } from 'components/common/truncated-content';
import { FeedCardContent, useFeedCardMenu } from './shared';
import { FeedCardHeader } from './shared/feed-card-header/feed-card-header';

type NoteCardProps = {
	feed: FeedRecord;
};

export const NoteCard = ({ feed }: NoteCardProps) => {
	const { t } = useTranslation();
	const router = useRouter();
	const { communityId } = useParams() as {
		communityId: string;
	};
	const noteId = feed.uuid;

	const deleteNoteHandler = useDeleteNoteHandler();

	const handleOnClick = useCallback(() => {
		router.navigate('note.view', {
			communityId,
			noteId,
		});
	}, [communityId, noteId]);

	const handleOnEdit = useCallback(() => {
		router.navigate('note.edit', {
			communityId,
			noteId,
		});
	}, [feed]);

	const handleOnDelete = useCallback(() => {
		deleteNoteHandler({
			noteId,
			communityId,
		});
	}, [communityId]);

	const actionObject = useVerifyActions(
		{
			type: 'note',
			identity_permissions: feed.identity_permissions,
		},
		[
			{ action: 'update', handler: handleOnEdit, key: 'onEdit' },
			{ action: 'delete', handler: handleOnDelete, key: 'onDelete' },
		]
	);

	const menuAction = useFeedCardMenu(actionObject);

	return (
		<>
			<FeedCardHeader
				identity={feed?.owner}
				action={t('community-home.uploaded-a-note')}
				createdDt={feed?.updated_dt ?? ''}
				onClick={handleOnClick}
				icon={noteIcon}
				renderAction={menuAction}
			/>
			<FeedCardContent
				title={feed?.title ?? ''}
				content={feed?.content ?? ''}
				contentWrapper={
					<TruncatedContent
						showText={t('notes.view-full-note')}
						hideText={t('notes.hide-note')}
						maxHeight={200}
					/>
				}
			/>
		</>
	);
};
