import { Box, CircularProgress, LoadingButton } from 'components/common';
import { Fragment, useEffect } from 'react';
import { useTranslation } from 'hooks';
import { useGetComments } from 'core/uses-cases/discussion';
import config from 'config';
import { CommentRow } from './comment-row';
import { useDiscussion } from './context/context';

export const CommentList = () => {
	const { t } = useTranslation();
	const { discussionId, enabled, setEnabledToggler, isTogglerEnabled } =
		useDiscussion();

	const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } =
		useGetComments(discussionId, {
			enabled,
			refreshInterval: config.commentWidget.pollerTimeout,
		});

	useEffect(() => {
		const firstPage = data?.pages?.[0] ?? [];
		const totalCommentsInFirstPage = firstPage.length;

		if (isTogglerEnabled === false && totalCommentsInFirstPage > 0) {
			setEnabledToggler(true);
		} else if (
			isTogglerEnabled === true &&
			totalCommentsInFirstPage === 0
		) {
			setEnabledToggler(false);
		}
	}, [isTogglerEnabled, data]);

	return (
		<Box>
			{isLoading && (
				<Box display='flex' justifyContent='center' mt={4}>
					<CircularProgress
						message={t('comment-widget.loading-comments')}
					/>
				</Box>
			)}
			{data?.pages?.map(page => (
				<Fragment key={`comment-page-${page?.[page.length - 1]?.uuid}`}>
					{page.map(comment => (
						<CommentRow
							key={comment.uuid}
							discussionId={discussionId}
							comment={comment}
						/>
					))}
				</Fragment>
			))}
			{hasNextPage ? (
				<Box textAlign='center' mb={1}>
					<LoadingButton
						onClick={() => fetchNextPage()}
						disabled={isFetchingNextPage}
						size='small'
						variant='text'
						loading={isFetchingNextPage}
						loadingIndicator={t('comment-widget.loading-comments')}
					>
						{t('comment-widget.load-older-comments')}
					</LoadingButton>
				</Box>
			) : null}
		</Box>
	);
};
