import { SxProps } from '@mui/system';

export const rootStyle: SxProps = {
	display: 'flex',
	padding: 2,
	marginBottom: 2,
	cursor: 'pointer',
	border: '1px solid #ffffff',

	'&.draggable': {
		border: '1px dashed black',
	},
};
export const avatarStyle: SxProps = { width: 76, height: 76, mr: 2 };
export const nameStyle: SxProps = { flex: 1 };
