import { SxProps } from '@mui/system';
import { Theme } from 'theme';

export const uploadButtonStyle: SxProps<Theme> = {
	width: '100%',
	height: '100%',
};

export const iconStyle: SxProps<Theme> = {
	fontSize: 64,
	marginBottom: 4,
};
