import { CircularProgress, useDialog } from 'components/common';
import { useServices, useTranslation } from 'hooks';
import { useEffect, useState } from 'react';
import {
	ImportEmailsModal,
	ImportEmailsContextValue,
} from '../setup/setup-community-members-form/import-emails-modal';

export const useImportMicrosoftEmails = (
	onSubmit: (contacts: string[]) => void
) => {
	const { t } = useTranslation();

	const Content = (
		handleOnConfirm: (extraData: string[]) => void,
		_handleOnCancel: () => void,
		accessToken: string
	) => {
		const [contacts, setContacts] = useState<ImportEmailsContextValue>([]);
		const [loading, setLoading] = useState(true);
		const { notification } = useServices();

		useEffect(() => {
			const headers = new Headers();
			headers.append('Authorization', `Bearer ${accessToken}`);

			fetch('https://graph.microsoft.com/v1.0/me/contacts', {
				method: 'GET',
				headers,
			})
				.then(response => {
					if (!response.ok) {
						throw new Error('contacts request failed');
					}

					return response.json();
				})
				.then(contactResponse => {
					const contacts = contactResponse.value.map(
						(contactData: {
							displayName: string;
							emailAddresses: { address: string }[];
						}) => ({
							uuid: contactData.emailAddresses[0].address,
							name: contactData.displayName,
							email: contactData.emailAddresses[0].address,
						})
					);

					setContacts(contacts);
				})
				.catch(() => {
					notification.show(
						'error',
						t('community-form.could-not-import')
					);
				})
				.finally(() => setLoading(false));
		}, [accessToken]);

		if (loading) {
			return (
				<CircularProgress size='small' message={t('common.loading')} />
			);
		}

		return (
			<ImportEmailsModal
				contacts={contacts}
				handleOnConfirm={handleOnConfirm}
			/>
		);
	};

	const { open } = useDialog<string, string[]>({
		title: t('community-form.choose-contacts'),
		content: Content,
		maxWidth: 'md',
		hideActions: true,
		onConfirm: (_contextData, extraData) => onSubmit(extraData),
	});

	const handleImportEmails = (accessToken: string) => {
		open(accessToken);
	};

	return handleImportEmails;
};
