import { Box, ButtonBase } from 'components/common';
import { CalendarEvent } from 'components/calendar/types';
import Popover from '@mui/material/Popover';
import { useMemo } from 'react';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { SxProps } from '@mui/system';
import {
	eventListStyles,
	morePopupContainerStyle,
	seeMoreButtonStyle,
} from './styles';
import { EventRow } from './event-row';

type EventListProps = {
	maxRenderCount?: number;
	events: CalendarEvent[];
	onEventClick: (event: CalendarEvent) => void;
};

export const EventList = ({
	events,
	maxRenderCount = 5,
	onEventClick,
}: EventListProps) => {
	const [chunkedEvents, moreEvents] = useMemo(() => {
		if (maxRenderCount > 0 && events.length > maxRenderCount) {
			return [
				events.slice(0, maxRenderCount - 1),
				events.slice(maxRenderCount - 1),
			];
		}

		return [events, []];
	}, [events]);

	return (
		<Box sx={eventListStyles}>
			{chunkedEvents.map(event => (
				<EventRow
					key={event.uuid}
					event={event}
					onClick={onEventClick}
				/>
			))}
			{moreEvents.length > 0 && (
				<PopupState variant='popover'>
					{popupState => (
						<>
							<ButtonBase
								disableRipple
								sx={seeMoreButtonStyle}
								{...bindTrigger(popupState)}
							>
								{moreEvents.length} more
							</ButtonBase>
							<Popover
								{...bindPopover(popupState)}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'center',
								}}
								transformOrigin={{
									vertical: 'top',
									horizontal: 'center',
								}}
							>
								<Box
									sx={
										[
											eventListStyles,
											morePopupContainerStyle,
										] as SxProps
									}
								>
									{moreEvents.map(event => (
										<EventRow
											key={event.uuid}
											event={event}
											onClick={onEventClick}
										/>
									))}
								</Box>
							</Popover>
						</>
					)}
				</PopupState>
			)}
		</Box>
	);
};
