import {
	Box,
	CheckboxField,
	FancyTable,
	FormControlLabel,
	PaginatedList,
	Paper,
	Typography,
	ButtonRouterLink,
	Icon,
	TeamHeader,
	useMediaQuery,
} from 'components';
import {
	useCheckboxMultiSelectionHandler,
	useInfinityScrollPaginationHandler,
	useParams,
	useRouter,
	useServices,
	useSubmitHandlerForPausedMutation,
	useTranslation,
} from 'hooks';
import { InviteCard } from 'components/community/team';
import { Fragment, useMemo } from 'react';
import {
	useDeleteFailedInvites,
	useGetFailedInvites,
} from 'core/uses-cases/community';
import { MembershipInvite } from 'core/types';
import {
	FooterActions,
	FooterActionButton,
} from 'components/common/footer-action';

export const FailedInvites = () => {
	const { t } = useTranslation();
	const router = useRouter();
	const { communityId } = useParams() as { communityId: string };
	const { mutateAsync, isPaused } = useDeleteFailedInvites();
	const { notification } = useServices();
	const isLargeScreen = useMediaQuery.up('sm');

	const {
		data,
		isSuccess,
		isFetching,
		isFetchingNextPage,
		hasNextPage,
		fetchNextPage,
	} = useGetFailedInvites(communityId);

	const invites = useMemo(() => {
		if (!data?.pages) return [];

		return data.pages.reduce(
			(invites, page) => invites.concat(page),
			[] as MembershipInvite[]
		);
	}, [data]);

	const {
		selected,
		select,
		selectedSize,
		selectAll,
		isSelected,
		isAllSelected,
		isIndeterminate,
		clearAll,
	} = useCheckboxMultiSelectionHandler(invites);

	const handleOnDelete = useSubmitHandlerForPausedMutation<string[]>(
		isPaused,
		invitationIds =>
			mutateAsync({
				communityId,
				invitationIds,
			}),
		{
			onSuccess: () => {
				clearAll();

				notification.show('success', t('invites.invitation-processed'));
			},
		}
	);

	const { handleOnLoad, showMoreButton, setShowMoreButton } =
		useInfinityScrollPaginationHandler<MembershipInvite>({
			data,
			fetchNextPage,
			hasNextPage,
		});

	return (
		<>
			<TeamHeader
				title={t('team.community-members')}
				button={
					<ButtonRouterLink
						to={router.getPathFor('community.invite', {
							communityId,
						})}
						startIcon={
							<Icon name='add_circle_outline' fontSize='large' />
						}
						size='small'
					>
						{t('team.add-members')}
					</ButtonRouterLink>
				}
			/>
			{isSuccess && !isFetching && data.pages[0].length === 0 ? (
				<Box component={Paper} mt={2} px={4} py={4}>
					{t('invites.no-failed-invitations')}
				</Box>
			) : (
				<>
					<FancyTable
						renderColumns={{
							2: { xs: false },
							4: { xs: false, sm: false },
						}}
					>
						<FancyTable.Header>
							<FancyTable.Header.Cell>
								<FormControlLabel
									control={
										<CheckboxField
											CheckBoxProps={{
												indeterminate: isIndeterminate,
											}}
											value={isAllSelected}
											onChange={selectAll}
										/>
									}
									label={
										<Typography variant='button2'>
											{t('invites.recipient')}
										</Typography>
									}
								/>
							</FancyTable.Header.Cell>
							<FancyTable.Header.Cell
								title={t('invites.invited-by')}
							/>
							<FancyTable.Header.Cell
								title={t('invites.status')}
							/>
							<FancyTable.Header.Cell
								title={t('invites.date-sent')}
							/>
						</FancyTable.Header>
						<PaginatedList
							onReachThreshold={handleOnLoad}
							onLoadMore={fetchNextPage}
							isFetching={isFetching}
							isFetchingMore={isFetchingNextPage}
							hasMore={!!hasNextPage}
							showButton={showMoreButton}
							setShowButton={setShowMoreButton}
						>
							{invites.map(invite => {
								return (
									<Fragment key={invite.uuid}>
										<InviteCard
											invite={invite}
											isSelected={isSelected(invite.uuid)}
											onSelect={select}
										/>
									</Fragment>
								);
							})}
						</PaginatedList>
					</FancyTable>
					<FooterActions
						selectedCount={selectedSize}
						isLargeScreen={isLargeScreen}
					>
						<FooterActionButton
							icon='delete_outline'
							onClick={() => handleOnDelete(selected)}
						>
							{isLargeScreen && t('common.delete')}
						</FooterActionButton>
					</FooterActions>
				</>
			)}
		</>
	);
};
