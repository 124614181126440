import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { CalendarFilter, useCalendar } from '../context';
import { CalendarEvent } from '../types';

const applyViewFilter = (
	filter: CalendarFilter,
	events: CalendarEvent[]
): CalendarEvent[] => {
	if (filter.statuses.length === 0) return [];

	return events.filter(event => {
		let filteredByType = true;
		let filteredByMember = true;

		if (filter.eventTypes.length > 0) {
			filteredByType = filter.eventTypes.includes(event.type);
		}

		if (filter.memberIds.length > 0) {
			filteredByMember =
				filter.memberIds.some(id =>
					event.volunteers
						.map(volunteer => volunteer.uuid)
						.includes(id)
				) ||
				filter.memberIds.some(id =>
					event.coordinators
						.map(coodinator => coodinator.uuid)
						.includes(id)
				);
		}

		return (
			filteredByType &&
			filteredByMember &&
			filter.statuses.includes(event.status)
		);
	});
};

export const useEventsForDay = (dateTime: DateTime) => {
	const { eventsMap, filter } = useCalendar();

	const dateKey = dateTime.toFormat('yyyy-MM-dd');

	const filteredEvents = eventsMap.get(dateKey) ?? [];

	return applyViewFilter(filter, filteredEvents);
};

export const useEventsForDateTime = (dateTime: DateTime, allDay = false) => {
	const { eventsMap, filter } = useCalendar();

	const dateKey = dateTime.toFormat('yyyy-MM-dd');

	const events = eventsMap.get(dateKey) ?? [];

	const filteredByDate = events.filter(event => {
		if (allDay) return !event.startDateTime;

		if (event.startDateTime) {
			return (
				event.startDateTime.toFormat('HH') === dateTime.toFormat('HH')
			);
		}

		return false;
	});

	return applyViewFilter(filter, filteredByDate);
};

export const useEventsBetween = (startDate: DateTime, endDate: DateTime) => {
	const { events, filter } = useCalendar();

	const eventsBetween = useMemo(
		() =>
			events.filter(event => {
				const startDateRef = event.startDateTime
					? event.startDateTime
					: event.startDate;

				return startDateRef >= startDate && startDateRef <= endDate;
			}),
		[startDate, endDate, events]
	);

	return applyViewFilter(filter, eventsBetween);
};

type GroupEvents = Record<
	string,
	{
		month: DateTime;
		dates: Record<string, { date: DateTime; events: CalendarEvent[] }>;
	}
>;

export const useEventsForAgenda = (startDate: DateTime, endDate: DateTime) => {
	const events = useEventsBetween(startDate, endDate);

	return useMemo(() => {
		const grouped = events.reduce((carry, event) => {
			const startDateRef = event.startDateTime
				? event.startDateTime
				: event.startDate;

			const key = startDateRef.toFormat('yyyy-MM');

			if (!carry[key]) {
				carry[key] = { month: startDateRef, dates: {} };
			}

			const dateKey = startDateRef.toFormat('yyyy-MM-dd');

			if (!carry[key].dates[dateKey]) {
				carry[key].dates[dateKey] = {
					date: startDateRef,
					events: [],
				};
			}

			carry[key].dates[dateKey].events.push(event);

			return carry;
		}, {} as GroupEvents);

		return Object.values(grouped)
			.sort(
				(month1, month2) =>
					month1.month.toMillis() - month2.month.toMillis()
			)
			.map(({ month, dates }) => ({
				month,
				dates: Object.values(dates)
					.sort((a, b) => a.date.toMillis() - b.date.toMillis())
					.filter(({ events }) => events.length > 0),
			}));
	}, [events, startDate, endDate]);
};
