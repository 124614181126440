import { SxProps } from '@mui/system';
import { COLORS } from 'components/calendar/constants';
import { Theme } from 'theme';

export const eventListStyles: SxProps<Theme> = {
	display: 'flex',
	flexDirection: 'column',
};

export const eventItemStyle: SxProps<Theme> = theme => ({
	display: 'inline-flex',
	alignItems: 'center',
	cursor: 'pointer',
	marginBottom: theme.spacing(0.05),

	'&:before': {
		display: 'block',
		content: "''",
		width: 8,
		height: 8,
		minWidth: 8,
		borderRadius: '100%',
		marginRight: theme.spacing(1),
	},

	'&.needs-met:before': {
		backgroundColor: COLORS.needsMet,
	},
	'&.needs-help:before': {
		backgroundColor: COLORS.helpNeeded,
	},
	'&.is-occasion:before': {
		backgroundColor: COLORS.occasions,
	},

	'> span': {
		fontWeight: 500,
	},
});

export const seeMoreButtonStyle: SxProps<Theme> = theme => ({
	fontSize: theme.typography.caption.fontSize,
	fontWeight: 'bold',
});

export const morePopupContainerStyle: SxProps<Theme> = theme => ({
	padding: theme.spacing(1, 2.5, 0.5),
	backgroundColor: theme.palette.background.paper,
});

export const eventBoxContainerStyle: SxProps<Theme> = theme => ({
	position: 'relative',

	'&.needs-met': {
		backgroundColor: COLORS.needsMet,
	},
	'&.needs-help': {
		backgroundColor: COLORS.helpNeeded,
	},
	'&.is-occasion': {
		backgroundColor: COLORS.occasions,
	},
});

export const mobileMonthDayButtonStyle: SxProps<Theme> = {
	position: 'absolute',
	top: 0,
	left: 0,
	right: 0,
	bottom: 0,
	alignItems: 'flex-end',
	justifyContent: 'flex-start',
	paddingLeft: 1,
	paddingBottom: 1,
};

export const mobileEventPopupContainerStyle: SxProps<Theme> = {
	paddingX: 2,
	paddingY: 2,
	'& .event-row': {
		marginBottom: 1,
		'&:before': {
			width: 20,
			height: 20,
		},
		span: {
			fontSize: 20,
			lineHeight: '24px',
		},
	},
};
