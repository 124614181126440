import { useVerifyAccess } from 'hooks';
import {
	Item,
	Actions,
	ActionObject,
	StringKeys,
	ActionLabelAndIcon,
} from 'core/types';
import { CardMenuItem } from 'components/common/menu';

export const useVerifyActions = (item: Item, actions: Actions) => {
	const actionObject: ActionObject = {};
	const verify = useVerifyAccess();

	for (const action of actions) {
		const canAdd = verify(action.action, item.type, {
			identity_permissions: item.identity_permissions ?? [],
		});

		if (canAdd) {
			actionObject[action.key as keyof ActionObject] = action.handler;
		}
	}

	return actionObject;
};

export const useVerifyActionsWithLabels = (
	item: Item,
	actions: Actions,
	labelsAndIcons: ActionLabelAndIcon
) => {
	const actionObject = useVerifyActions(item, actions);

	const actionsWithLabels: CardMenuItem[] = (
		Object.keys(actionObject) as StringKeys<typeof labelsAndIcons>
	).map(actionKey => {
		return {
			...labelsAndIcons[actionKey],
			onClick: actionObject[actionKey as keyof ActionObject],
		};
	});

	return actionsWithLabels;
};
