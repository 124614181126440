import { Button, Box, LoadingButton } from 'components';
import { useTranslation } from 'hooks';

export type ButtonSectionProps = {
	submitting: boolean;
	submitText?: string;
	submittingText?: string;
	onDelete?: () => void;
	deleteText?: string;
};

export const ButtonSection = ({
	submitting,
	submitText,
	submittingText,
	onDelete,
	deleteText,
}: ButtonSectionProps) => {
	const { t } = useTranslation();

	return (
		<Box
			display='flex'
			flexDirection={{ xs: 'column', sm: 'row' }}
			alignItems={{ xs: 'center', sm: 'flex-start' }}
		>
			<Box sx={{ mr: { xs: 0, sm: 7 } }}>
				<LoadingButton
					loading={submitting}
					loadingIndicator={submittingText || t('form.submitting')}
					size='small'
					type='submit'
				>
					{submitText || t('form.submit')}
				</LoadingButton>
			</Box>
			{onDelete && (
				<Box mt={{ xs: 4, sm: 0 }}>
					<Button
						size='small'
						onClick={onDelete}
						variant='text'
						color='error'
						data-test-id='delete-button'
					>
						{deleteText || t('common.delete')}
					</Button>
				</Box>
			)}
		</Box>
	);
};
