import { Box, CircularProgress } from 'components/common';
import { AppHeader } from '../app/header';
import { Footer, Content } from '../shared';
import { CommunityProvider, useCurrentCommunity } from './community-context';
import { CommunityNavigationMenu } from './header';
import { useCurrentCommunityPermissions } from './permission-context';

const LayoutContent: React.FC = ({ children }) => {
	const community = useCurrentCommunity();
	const permissions = useCurrentCommunityPermissions();

	if (!community || !permissions) {
		return (
			<Content>
				<Box
					display='flex'
					justifyContent='center'
					alignItems='center'
					height='calc(100vh - 303px)'
				>
					<CircularProgress message='Loading community...' />
				</Box>
			</Content>
		);
	}

	return (
		<>
			<AppHeader />
			<CommunityNavigationMenu />
			<Content>{children}</Content>
			<Footer />
		</>
	);
};

export const CommunityContainerLayout: React.FC = ({ children }) => {
	return (
		<CommunityProvider>
			<LayoutContent>{children}</LayoutContent>
		</CommunityProvider>
	);
};
