import { Announcement, Nullable } from 'core/types';
import { findRecordFromInfinityQueryCache } from 'core/utils/optimistic-utils';
import { announcementKeys } from 'core/utils/query-key-factory';
import { QueryClient } from 'react-query';

export function getAnnouncementListQueryKey(params: {
	communityId: string;
	whenToPost: string;
	publishedDt: Nullable<string>;
}) {
	const { communityId, whenToPost } = params;

	let announcementList: Parameters<(typeof announcementKeys)['list']>[1] =
		'published';

	const publishedDt = params.publishedDt
		? new Date(params.publishedDt)
		: null;

	if (whenToPost === 'draft' || publishedDt == null) {
		announcementList = 'draft';
	} else if (
		whenToPost === 'date-time' &&
		publishedDt &&
		publishedDt?.getTime() > Date.now()
	) {
		announcementList = 'scheduled';
	}

	return announcementKeys.list(communityId, announcementList);
}

export function findAnnouncementInfo(
	queryClient: QueryClient,
	communityId: string,
	announcementId: string
) {
	// Otherswise, fetch the announcement from the cache
	for (const listKey of getAnnouncementsListKeys(communityId)) {
		const found = findRecordFromInfinityQueryCache<Announcement>(
			queryClient,
			listKey,
			announcementId
		);
		if (found) {
			return {
				listKey,
				...found,
			};
		}
	}

	return null;
}

export const getAnnouncementsListKeys = (communityId: string) => [
	announcementKeys.list(communityId, 'draft'),
	announcementKeys.list(communityId, 'scheduled'),
	announcementKeys.list(communityId, 'published'),
];
