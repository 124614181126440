import {
	AppLayout,
	CommunityContainerLayout,
	OuterLayout,
} from 'components/layout';
import {
	Calendar,
	CommunityHome,
	CommunitySwitch,
	CommunitySearch,
	CreateCommunity,
	EditCommunity,
	ShutdownCommunity,
	CreateNote,
	CreateTask,
	EditNote,
	NoteList,
	SetupAnnouncement,
	SetupMembersCommunity,
	SetupProfileCommunity,
	ViewNote,
	EventDetail,
	CopyEvent,
	PhotoList,
	PhotoView,
	Team,
	EditEvent,
	PendingRequests,
	PendingInvites,
	FailedInvites,
	DeclinedInvites,
	MemberProfile,
	ExportCalendar,
} from 'pages/community';
import { Preview } from 'pages/preview';
import { Navigate } from 'react-router';
import {
	Login,
	ResetPasswordSuccess,
	ResetPassword,
	UpdatePassword,
	TransferSession,
} from 'pages/auth';
import {
	AnnouncementList,
	ViewAnnouncement,
	CreateAnnouncement,
	EditAnnouncement,
} from 'pages/community/announcements';
import { WellWishHome } from 'pages/community/well-wish';
import { EditAccount } from 'pages/account/edit';
import { ContactSupport, CommunitySupport, SetPhpCookie } from 'pages/support';
import { CreateAlbum } from 'pages/community/albums/create';
import { ViewAlbum } from 'pages/community/albums/view';
import { EditAlbum } from 'pages/community/albums/edit';
import { InviteMembers } from 'pages/community/invite';
import {
	InvalidToken,
	InvitationCreateAccount,
	InvitationDeclineSuccess,
	InvitationLogin,
	InvitationSelectEmail,
	JoinByInvitation,
	JoinEmailTarget,
	InvitationLayoutGuard,
} from 'pages/community/invitation';
import {
	ConfirmTransferRequest,
	InitiateTransferRequest,
} from 'pages/community/transfer-ownership';
import { SelectEmail } from 'pages/community/request-to-join/select-email';
import { RequestSuccess } from 'pages/community/request-to-join/success';
import { RequestToJoin } from 'pages/community/request-to-join/request-to-join';
import { ManagePermissions } from 'pages/community/permissions';
import {
	CreateGroup,
	EditGroupInfo,
	EditGroupMembers,
	EditGroupPermissions,
	ManageGroups,
	ViewGroup,
} from 'pages/community/groups';
import { EditMemberProfile } from 'pages/community/member/edit';
import { UnsubscribePage } from 'pages/subscription';
import { LogoutAccount } from 'pages/account';
import {
	LegacyTaskAliasBuilder,
	LegacyTaskCalendarAliasBuilder,
} from 'pages/legacy/tasks';
import { LegacyCommunityUuidExchangeAliasBuilder } from 'pages/legacy/community';
import { MicrosoftProvider } from 'core/services/microsoft/microsoft-provider';
import LandingPage from 'pages/landing/landing';
import { DeleteAccount } from 'pages/auth/delete';
import { DeleteEmailSent } from 'pages/auth/delete-email-sent';
import { ConfirmDeleteAccount } from 'pages/auth/confim-delete-account';
import { RouterBuilder } from './router-builder';
import {
	AuthenticatedGuard,
	PublicGuard,
	RequestToJoinGuard,
	UnAuthenticatedGuard,
	AvailableSectionGuard,
	AccessGuard,
} from './guards';

export const router = new RouterBuilder();

router.addRoute(
	'root',
	'/',
	() => ({
		element: <LandingPage />,
	}),
	{
		title: 'care-calendar-website',
		exact: true,
	}
);

router.addRoute('community', '/community', r => ({
	children: [
		{
			index: true,
			element: <Navigate to={r.getPathFor('community.switch')} replace />,
		},
	],
}));

router.addRoute(
	'community.search',
	'/community/search',
	() => ({
		element: (
			<PublicGuard layout={OuterLayout} element={<CommunitySearch />} />
		),
	}),
	{
		title: 'find-a-community',
	}
);

router.addRoute(
	'community.help-finding',
	'/community/help-finding',
	() => ({
		element: <PublicGuard element={<ContactSupport />} />,
	}),
	{
		title: 'care-calendar-website',
	}
);

router.addRoute(
	'community.create',
	'/community/create',
	() => ({
		element: <PublicGuard element={<CreateCommunity />} />,
	}),
	{
		title: 'create-a-community',
	}
);

router.addRoute(
	'community.switch',
	'/community/switch',
	() => ({
		element: (
			<AuthenticatedGuard
				layout={AppLayout}
				element={<CommunitySwitch />}
			/>
		),
	}),
	{
		title: 'switch-community',
	}
);

router.addRoute(
	'community.support',
	'/community/support',
	() => ({
		element: (
			<PublicGuard layout={AppLayout} element={<CommunitySupport />} />
		),
	}),
	{
		title: 'we-are-here-to-help',
	}
);

router.addRoute('community.root', '/community/:communityId', () => ({
	element: <AuthenticatedGuard layout={CommunityContainerLayout} />,
	children: [
		{
			index: true,
			element: <Navigate to='home' replace />,
		},
	],
}));

router.addRoute(
	'community.home',
	'/community/:communityId/home',
	() => ({
		element: <CommunityHome />,
	}),
	{
		title: 'home',
	}
);

router.addRoute(
	'community.edit',
	'/community/:communityId/edit',
	() => ({
		element: (
			<AccessGuard
				element={<EditCommunity />}
				action='update'
				entity='community'
			/>
		),
	}),
	{
		title: 'edit-community',
	}
);

router.addRoute(
	'community.shutdown',
	'/community/:communityId/shutdown',
	() => ({
		element: <ShutdownCommunity />,
	}),
	{
		title: 'shut-down-community',
	}
);

router.addRoute(
	'community.setup-profile',
	'/community/:communityId/setup-profile',
	() => ({
		element: (
			<AccessGuard
				element={<SetupProfileCommunity />}
				action='update'
				entity='community'
			/>
		),
	}),
	{ title: 'set-up-profile' }
);

router.addRoute(
	'community.setup-members',
	'/community/:communityId/setup-members',
	() => ({
		element: (
			<AccessGuard
				element={<SetupMembersCommunity />}
				action='update'
				entity='community'
			/>
		),
	}),
	{ title: 'add-members' }
);

router.addRoute(
	'community.setup-announcement',
	'/community/:communityId/setup-announcement',
	() => ({
		element: (
			<AccessGuard
				element={<SetupAnnouncement />}
				action='update'
				entity='community'
			/>
		),
	}),
	{ title: 'create-announcement' }
);

router.addRoute(
	'announcement.root',
	'/community/:communityId/announcement',
	() => ({
		element: <AvailableSectionGuard sectionKey='has_announcements' />,
		children: [
			{
				index: true,
				element: <Navigate to='list' replace />,
			},
		],
	}),
	{
		alias: {
			path: '/community/:communityId/announcements',
		},
	}
);

router.addRoute(
	'community.announcements',
	'/community/:communityId/announcement/list',
	() => ({
		element: <AnnouncementList />,
	}),
	{ title: 'announcements' }
);

router.addRoute(
	'announcement.create',
	'/community/:communityId/announcement/create',
	() => ({
		element: (
			<AccessGuard
				element={<CreateAnnouncement />}
				action='create'
				entity='announcement'
			/>
		),
	}),
	{
		title: 'create-announcement',
		alias: {
			path: '/announcement/create',
			getParams: (_, searchParams) => ({
				communityId: searchParams.get('c'),
				auth: searchParams.get('auth'),
			}),
			CustomComponent: LegacyCommunityUuidExchangeAliasBuilder,
		},
	}
);

router.addRoute(
	'announcement.view',
	'/community/:communityId/announcement/:announcementId/view',
	() => ({
		element: <ViewAnnouncement />,
	}),
	{
		title: 'announcement',
		alias: {
			path: '/announcement/:announcementId/view',
			getParams: (_, searchParams) => ({
				communityId: searchParams.get('c'),
				auth: searchParams.get('auth'),
			}),
			CustomComponent: LegacyCommunityUuidExchangeAliasBuilder,
		},
	}
);

router.addRoute(
	'announcement.edit',
	'/community/:communityId/announcement/:announcementId/edit',
	() => ({
		element: (
			<AccessGuard
				element={<EditAnnouncement />}
				action='update'
				entity='announcement'
				useItem
			/>
		),
	}),
	{
		title: 'edit-announcement',
		alias: {
			path: '/announcement/:announcementId/edit',
			getParams: (_, searchParams) => ({
				communityId: searchParams.get('c'),
				auth: searchParams.get('auth'),
			}),
			CustomComponent: LegacyCommunityUuidExchangeAliasBuilder,
		},
	}
);

router.addRoute(
	'note.root',
	'/community/:communityId/note',
	() => ({
		element: <AvailableSectionGuard sectionKey='has_documents' />,
		children: [
			{
				index: true,
				element: <Navigate to='list' replace />,
			},
		],
	}),
	{
		alias: {
			path: '/community/:communityId/notes',
		},
	}
);

router.addRoute(
	'community.notes',
	'/community/:communityId/note/list',
	() => ({
		element: <NoteList />,
	}),
	{
		title: 'notes',
	}
);

router.addRoute(
	'note.create',
	'/community/:communityId/note/create',
	() => ({
		element: (
			<AccessGuard
				element={<CreateNote />}
				action='create'
				entity='note'
			/>
		),
	}),
	{
		title: 'create-note',
	}
);

router.addRoute(
	'note.view',
	'/community/:communityId/note/:noteId/view',
	() => ({
		element: <ViewNote />,
	}),
	{
		title: 'note',
		alias: {
			path: '/community/:communityId/note/view/:noteId',
		},
	}
);

router.addRoute(
	'note.edit',
	'/community/:communityId/note/:noteId/edit',
	() => ({
		element: (
			<AccessGuard
				element={<EditNote />}
				action='update'
				entity='note'
				useItem
			/>
		),
	}),
	{
		title: 'edit-note',
		alias: {
			path: '/community/:communityId/note/edit/:noteId',
		},
	}
);

router.addRoute(
	'community.wishes',
	'/community/:communityId/wishes',
	() => ({
		element: <AvailableSectionGuard sectionKey='has_well_wishes' />,
		children: [
			{
				index: true,
				element: <WellWishHome />,
			},
		],
	}),
	{
		title: 'well-wishes',
	}
);

router.addRoute(
	'album.root',
	'/community/:communityId/album',
	() => ({
		element: <AvailableSectionGuard sectionKey='has_photos' />,
		children: [
			{
				index: true,
				element: <Navigate to='list' replace />,
			},
		],
	}),
	{
		alias: {
			path: '/community/:communityId/albums',
		},
	}
);

router.addRoute(
	'community.albums',
	'/community/:communityId/album/list',
	() => ({
		element: <PhotoList />,
	}),
	{
		title: 'photos',
	}
);

router.addRoute(
	'photo.view',
	'/community/:communityId/album/:albumId/photo/:photoId/view',
	() => ({
		element: <PhotoView />,
	}),
	{
		title: 'photo',
		alias: {
			path: '/album/:albumId/view-photo',
			getParams: (_, searchParams) => ({
				communityId: searchParams.get('c'),
				photoId: searchParams.get('p'),
				auth: searchParams.get('auth'),
			}),
			CustomComponent: LegacyCommunityUuidExchangeAliasBuilder,
		},
	}
);

router.addRoute(
	'album.create',
	'/community/:communityId/album/create',
	() => ({
		element: (
			<AccessGuard
				element={<CreateAlbum />}
				action='create'
				entity='album'
			/>
		),
	}),
	{
		title: 'create-album',
		alias: {
			path: '/album/create',
			getParams: (_, searchParams) => ({
				communityId: searchParams.get('c'),
				auth: searchParams.get('auth'),
			}),
			CustomComponent: LegacyCommunityUuidExchangeAliasBuilder,
		},
	}
);

router.addRoute(
	'album.view',
	'/community/:communityId/album/:albumId/view',
	() => ({
		element: <ViewAlbum />,
	}),
	{
		title: 'album',
		alias: {
			path: '/album/:albumId/view',
			getParams: (_, searchParams) => ({
				communityId: searchParams.get('c'),
				auth: searchParams.get('auth'),
			}),
			CustomComponent: LegacyCommunityUuidExchangeAliasBuilder,
		},
	}
);

router.addRoute(
	'album.edit',
	'/community/:communityId/album/:albumId/edit',
	() => ({
		element: (
			<AccessGuard
				element={<EditAlbum />}
				action='create'
				entity='album'
				useItem
			/>
		),
	}),
	{
		title: 'edit-album',
		alias: {
			path: '/album/:albumId/edit',
			getParams: (_, searchParams) => ({
				communityId: searchParams.get('c'),
				auth: searchParams.get('auth'),
			}),
			CustomComponent: LegacyCommunityUuidExchangeAliasBuilder,
		},
	}
);

router.addRoute(
	'calendar.create',
	'/community/:communityId/calendar/:calendarId/task/create',
	() => ({
		element: (
			<AccessGuard
				element={<CreateTask />}
				action='create'
				entity='task'
			/>
		),
	}),
	{
		title: 'create-task',
		alias: {
			path: '/calendar/:calendarId/create-task',
			getParams: (_, searchParams) => ({
				auth: searchParams.get('auth'),
			}),
			CustomComponent: LegacyTaskAliasBuilder,
		},
	}
);

router.addRoute(
	'community.calendar.event.view',
	'/community/:communityId/calendar/:calendarId/task/:eventId/view',
	() => ({
		element: <EventDetail />,
	}),
	{
		title: 'task',
		alias: {
			path: '/calendar/:calendarId/view-task',
			getParams: (_, searchParams) => ({
				eventId: searchParams.get('task'),
				auth: searchParams.get('auth'),
			}),
			CustomComponent: LegacyTaskAliasBuilder,
		},
	}
);

router.addRoute(
	'community.calendar.event.copy',
	'/community/:communityId/calendar/:calendarId/task/:eventId/copy',
	() => ({
		element: (
			<AccessGuard
				element={<CopyEvent />}
				action='create'
				entity='task'
			/>
		),
	}),
	{
		title: 'duplicate-task',
		alias: {
			path: '/calendar/:calendarId/copy-task',
			getParams: (_, searchParams) => ({
				eventId: searchParams.get('task'),
				auth: searchParams.get('auth'),
			}),
			CustomComponent: LegacyTaskAliasBuilder,
		},
	}
);

router.addRoute(
	'community.calendar.event.edit',
	'/community/:communityId/calendar/:calendarId/task/:eventId/edit',
	() => ({
		element: (
			<AccessGuard
				element={<EditEvent />}
				action='update'
				entity='task'
				useItem
			/>
		),
	}),
	{
		title: 'edit-task',
		alias: {
			path: '/calendar/:calendarId/edit-task-details',
			getParams: (_, searchParams) => ({
				eventId: searchParams.get('task'),
				auth: searchParams.get('auth'),
			}),
			CustomComponent: LegacyTaskAliasBuilder,
		},
	}
);

router.addRoute(
	'community.calendar',
	'/community/:communityId/calendar/:calendarId/*',
	() => ({
		element: <Calendar />,
		children: [
			{
				index: true,
				element: <Navigate to='month' />,
			},
		],
	}),
	{
		title: 'calendar',
		alias: {
			path: '/community/:communityId/calendar',
			getParams: (params, searchParams) => ({
				communityId: params.communityId,
				auth: searchParams.get('auth'),
			}),
			CustomComponent: LegacyTaskCalendarAliasBuilder,
		},
	}
);

router.addRoute(
	'community.team',
	'/community/:communityId/team',
	() => ({
		element: <Team />,
	}),
	{
		title: 'manage-community',
	}
);

router.addRoute(
	'community.invite',
	'/community/:communityId/invite',
	() => ({
		element: (
			<AccessGuard
				element={
					<MicrosoftProvider>
						<InviteMembers />
					</MicrosoftProvider>
				}
				action='create'
				entity='member'
			/>
		),
	}),
	{
		title: 'invite-members',
	}
);

router.addRoute(
	'community.pending-requests',
	'/community/:communityId/team-requested',
	() => ({
		element: <PendingRequests />,
	}),
	{
		title: 'manage-community',
	}
);

router.addRoute(
	'community.pending-invites',
	'/community/:communityId/team-invited',
	() => ({
		element: <PendingInvites />,
	}),
	{
		title: 'manage-community',
	}
);

router.addRoute(
	'community.failed-invites',
	'/community/:communityId/team-failed',
	() => ({
		element: <FailedInvites />,
	}),
	{
		title: 'manage-community',
	}
);

router.addRoute(
	'community.declined-invites',
	'/community/:communityId/team-declined',
	() => ({
		element: <DeclinedInvites />,
	}),
	{
		title: 'manage-community',
	}
);

router.addRoute(
	'community.groups',
	'/community/:communityId/manage-member-groups',
	() => ({
		element: <ManageGroups />,
	}),
	{
		title: 'groups',
	}
);

router.addRoute(
	'community.group-view',
	'/community/:communityId/group/:groupId/view',
	() => ({
		element: <ViewGroup />,
	}),
	{
		title: 'group',
		alias: {
			path: '/community/:communityId/view-group',
			getParams: (params, searchParams) => ({
				communityId: params.communityId,
				groupId: searchParams.get('group'),
			}),
		},
	}
);

router.addRoute(
	'community.group-create',
	'/community/:communityId/group/create',
	() => ({
		element: (
			<AccessGuard
				element={<CreateGroup />}
				action='create'
				entity='group'
			/>
		),
	}),
	{
		title: 'create-group',
		alias: {
			path: '/community/:communityId/create-group',
			getParams: (params, searchParams) => ({
				communityId: params.communityId,
				groupId: searchParams.get('group'),
			}),
		},
	}
);

router.addRoute(
	'community.group-edit-info',
	'/community/:communityId/group/:groupId/edit-info',
	() => ({
		element: (
			<AccessGuard
				element={<EditGroupInfo />}
				action='update'
				entity='group'
				useItem
			/>
		),
	}),
	{
		title: 'edit-group',
		alias: {
			path: '/community/:communityId/edit-group-info',
			getParams: (params, searchParams) => ({
				communityId: params.communityId,
				groupId: searchParams.get('group'),
			}),
		},
	}
);

router.addRoute(
	'community.group-edit-members',
	'/community/:communityId/group/:groupId/edit-members',
	() => ({
		element: (
			<AccessGuard
				element={<EditGroupMembers />}
				action='update'
				entity='group'
				useItem
			/>
		),
	}),
	{
		title: 'edit-members',
		alias: {
			path: '/community/:communityId/edit-group-members',
			getParams: (params, searchParams) => ({
				communityId: params.communityId,
				groupId: searchParams.get('group'),
			}),
		},
	}
);

router.addRoute(
	'community.group-permissions',
	'/community/:communityId/group/:groupId/edit-permissions',
	() => ({
		element: <EditGroupPermissions />,
	}),
	{
		title: 'edit-permissions',
		alias: {
			path: '/community/:communityId/edit-group-permissions',
			getParams: (params, searchParams) => ({
				communityId: params.communityId,
				groupId: searchParams.get('group'),
			}),
		},
	}
);

router.addRoute(
	'community.edit-members',
	'/community/:communityId/edit-profile/:identityId',
	() => ({
		element: (
			<AccessGuard
				element={<EditMemberProfile />}
				action='update'
				entity='member'
				useItem
			/>
		),
	}),
	{
		title: 'edit-profile',
	}
);

router.addRoute(
	'community.initiate-transfer-request',
	'/community/:communityId/initiate-transfer-request',
	() => ({
		element: <InitiateTransferRequest />,
	})
);

router.addRoute(
	'community.member-profile',
	'/community/:communityId/member/:memberId',
	() => ({
		element: <MemberProfile />,
	}),
	{
		title: 'member',
		alias: {
			path: '/community/:communityId/manage-member/:memberId',
		},
	}
);

router.addRoute(
	'community.permissions',
	'/community/:communityId/manage-member-permissions',
	() => ({
		element: <ManagePermissions />,
	}),
	{
		title: 'edit-permissions',
	}
);

router.addRoute(
	'community.export-calendar',
	'/community/:communityId/export-calendar',
	() => ({
		element: <ExportCalendar />,
	}),
	{
		title: 'export-calendar',
	}
);

router.addRoute(
	'account.login',
	'/account/login',
	() => ({
		element: (
			<UnAuthenticatedGuard layout={OuterLayout} element={<Login />} />
		),
	}),
	{
		title: 'log-in',
	}
);

router.addRoute('account.delete', '/account/delete', () => ({
	element: (
		<OuterLayout>
			<DeleteAccount />
		</OuterLayout>
	),
}));

router.addRoute(
	'account.delete-email-sent',
	'/account/delete-email-sent',
	() => ({
		element: (
			<OuterLayout>
				<DeleteEmailSent />
			</OuterLayout>
		),
	})
);

router.addRoute('confirm-delete', '/account/confirm-delete', () => ({
	element: (
		<OuterLayout>
			<ConfirmDeleteAccount />
		</OuterLayout>
	),
}));

router.addRoute(
	'account.transfer-session',
	'/account/transfer-session',
	() => ({
		element: <TransferSession />,
	})
);

router.addRoute(
	'account.edit',
	'/account/edit',
	() => ({
		element: (
			<AuthenticatedGuard layout={AppLayout} element={<EditAccount />} />
		),
	}),
	{
		title: 'edit-account',
	}
);

router.addRoute(
	'account.reset',
	'/account/reset-password',
	() => ({
		element: (
			<OuterLayout>
				<ResetPassword />
			</OuterLayout>
		),
	}),
	{
		title: 'reset-password',
	}
);

router.addRoute(
	'account.reset-success',
	'/account/reset-password-success',
	() => ({
		element: (
			<OuterLayout>
				<ResetPasswordSuccess />
			</OuterLayout>
		),
	}),
	{
		title: 'reset-password',
	}
);

router.addRoute(
	'account.update-password',
	'/account/update-password',
	() => ({
		element: (
			<OuterLayout>
				<UpdatePassword />
			</OuterLayout>
		),
	}),
	{
		title: 'update-password',
	}
);

router.addRoute('account.logout', '/account/logout', () => ({
	element: <LogoutAccount />,
}));

router.addRoute(
	'request.root',
	'/request/:communityId',
	() => ({
		element: <RequestToJoinGuard />,
		children: [
			{
				index: true,
				element: <Navigate to='select-email' replace />,
			},
		],
	}),
	{
		alias: {
			path: '/community/:communityId/request-to-join',
		},
	}
);

router.addRoute(
	'request.select-email',
	'/request/:communityId/select-email',
	() => ({
		element: <SelectEmail />,
	}),
	{
		title: 'select-email',
	}
);

router.addRoute(
	'request.join',
	'/request/:communityId/join',
	() => ({
		element: <RequestToJoin />,
	}),
	{
		title: 'request-to-join',
	}
);

router.addRoute(
	'request.success',
	'/request/:communityId/success',
	() => ({
		element: <RequestSuccess />,
	}),
	{
		title: 'request-to-join',
	}
);

router.addRoute(
	'community.invitation.email-target',
	'/community/:communityId/join-by-invitation',
	() => ({
		element: <PublicGuard element={<JoinEmailTarget />} />,
	}),
	{ exact: true }
);

router.addRoute(
	'community.invitation.decline-success',
	'/community/:communityId/decline-success',
	() => ({
		element: <InvitationDeclineSuccess />,
	}),
	{ exact: true, title: 'join-community' }
);

router.addRoute(
	'community.invitation',
	'/community/:communityId/invitation/:tokenId',
	() => ({
		element: <InvitationLayoutGuard />,
		children: [
			{
				name: 'community.invitation.select-email',
				path: 'select-email',
				element: <InvitationSelectEmail />,
			},
			{
				name: 'community.invitation.join',
				path: 'join',
				element: <JoinByInvitation />,
			},
			{
				name: 'community.invitation.create-account',
				path: 'create-account',
				element: <InvitationCreateAccount />,
			},
			{
				name: 'community.invitation.login',
				path: 'login',
				element: <InvitationLogin />,
			},
			{
				name: 'community.invitation.invalid-token',
				path: 'invalid-token',
				element: <InvalidToken />,
			},
		],
	}),
	{ exact: true, title: 'join-community' }
);

router.addRoute('email.unsubscribe', '/email/unsubscribe', () => ({
	element: <PublicGuard layout={OuterLayout} element={<UnsubscribePage />} />,
}));

router.addRoute(
	'community.confirm-transfer-request',
	'/community/:communityId/confirm-transfer',
	() => ({
		element: <PublicGuard element={<ConfirmTransferRequest />} />,
	}),
	{ exact: true, title: 'transfer-request' }
);

router.addRoute('preview', '/preview', () => ({
	element: <Preview />,
}));

router.addRoute('community.fallback', '/community/:communityId/*', r => {
	return {
		element: <Navigate to='home' replace />,
	};
});

router.addRoute('root.fallback', '/*', r => ({
	element: <Navigate to={r.getPathFor('root')} replace />,
}));
