import {
	FormControlProps,
	InputLabelProps,
	FormGroupProps,
	FormControl,
	InputLabel,
	FormHelperText,
} from '@mui/material';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useCallback, useMemo } from 'react';
import { useDropzone, FileWithPath, FileRejection } from 'react-dropzone';
import { Asset } from 'core/types';
import {
	Box,
	Typography,
	Button,
	ButtonProps,
	IconButton,
} from 'components/common';
import { useServices, useTranslation } from 'hooks';
import config from 'config';
import { deleteButtonContent, fileContentStyle } from './styles';

export interface FilePickerFile extends Asset {
	file: FileWithPath | null;
}

interface FilePickerProps extends Omit<FormControlProps, 'onChange'> {
	value: FilePickerFile[];
	label?: string;
	disabled?: boolean;
	LabelProps?: InputLabelProps;
	FormGroupProps?: FormGroupProps;
	helperText?: string;
	maxFiles?: number;
	isMultiple?: boolean;
	onChange: (value: FilePickerFile[]) => void;
	buttonSize?: ButtonProps['size'];
	addButtonText?: string;
}

export const FilePicker = (props: FilePickerProps) => {
	const {
		isMultiple = false,
		maxFiles = 0,
		disabled = false,
		label,
		LabelProps,
		helperText,
		value = [],
		buttonSize,
		onChange: onChangeProp,
		addButtonText = 'Add a File',
		...rest
	} = props;

	const { notification } = useServices();
	const { t } = useTranslation();

	const files = useMemo(() => value || [], [value]);

	const handleOnDropFile = useCallback(
		(acceptedFiles: FileWithPath[], errors: FileRejection[]) => {
			if (errors.length > 0) {
				const errorCodes = errors.map(error => error.errors[0].code);

				if (errorCodes.includes('too-many-files')) {
					notification.show(
						'error',
						t('file-picker.too-many-files', {
							count: maxFiles,
						})
					);

					return;
				}

				if (errorCodes.includes('file-too-large')) {
					notification.show(
						'error',
						t('file-picker.file-is-too-large', {
							maxFileSize: config.maxFileSize,
						})
					);
				}
			}

			if (acceptedFiles.length === 0) {
				return;
			}

			const currentFiles = [...files];

			const newFiles = acceptedFiles.map(file => ({
				uuid: null,
				name: file.name,
				url: '',
				size: file.size,
				metadata: '',
				created_dt: new Date(file.lastModified).toISOString(),
				file,
			}));

			onChangeProp?.([...currentFiles, ...newFiles]);
		},
		[files]
	);

	const { getRootProps, getInputProps, open } = useDropzone({
		disabled,
		// Disable click and keydown behavior
		noClick: true,
		noKeyboard: true,
		multiple: isMultiple,
		maxFiles: isMultiple ? maxFiles : 1,
		maxSize: config.maxFileSize * 1000000,
		onDrop: handleOnDropFile,
	});

	const handleOnRemoveImage = useCallback(
		index => {
			const newImages = files.filter((_, i) => i !== index);

			onChangeProp?.(newImages);
		},
		[files]
	);

	return (
		<FormControl {...rest}>
			{label && <InputLabel {...LabelProps}>{label}</InputLabel>}
			<Box {...getRootProps({ className: 'dropzone' })}>
				<input {...getInputProps()} />
				{isMultiple || (!isMultiple && files.length === 0) ? (
					<Button
						type='button'
						variant='outlined'
						onClick={open}
						size={buttonSize}
						startIcon={
							<AddCircleOutlineOutlinedIcon fontSize='large' />
						}
					>
						{addButtonText}
					</Button>
				) : null}
			</Box>
			{files.length > 0 && (
				<Box sx={fileContentStyle}>
					{files.map((file, index) => (
						<Box key={file.name}>
							<Typography noWrap>{file.name}</Typography>
							<Box sx={deleteButtonContent}>
								<IconButton
									aria-label='delete'
									size='small'
									onClick={handleOnRemoveImage.bind(
										null,
										index
									)}
									color='inherit'
								>
									<DeleteOutlineIcon fontSize='inherit' />
								</IconButton>
							</Box>
						</Box>
					))}
				</Box>
			)}
			{helperText && <FormHelperText>{helperText}</FormHelperText>}
		</FormControl>
	);
};
