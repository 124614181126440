import { Community } from 'core/types';
import { proxy } from 'valtio';

export interface FeedFilterState {
	filter: string;
	communitySections: string[];
	setFilter: (filter: string) => void;
	setCommunitySections: (community: Community | null) => void;
}

export interface communitySectionInterface {
	everything: boolean;
	well_wish: boolean;
	announcement: boolean;
	note: boolean;
	album_image: boolean;
}

export const FEED_FILTER_VALUES = {
	everything: 'everything',
	well_wish: 'well_wish',
	announcement: 'announcement',
	note: 'note',
	album_image: 'album_image',
};

export type FilterKeys = keyof typeof FEED_FILTER_VALUES;
export type FilterValues = (typeof FEED_FILTER_VALUES)[FilterKeys];

export const feedFilterState = proxy<FeedFilterState>({
	filter: 'everything',
	communitySections: [
		'everything',
		'well_wishes',
		'announcement',
		'note',
		'album_image',
	],
	setFilter(filter: string): void {
		feedFilterState.filter = filter;
	},
	setCommunitySections(community: Community | null): void {
		const communitySections: communitySectionInterface = {
			everything: true,
			well_wish: community?.has_well_wishes ?? false,
			announcement: community?.has_announcements ?? false,
			note: community?.has_documents ?? false,
			album_image: community?.has_photos ?? false,
		};

		feedFilterState.communitySections = Object.keys(
			communitySections
		).filter(key => communitySections[key as FilterKeys]);
	},
});
