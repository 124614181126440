import {
	Box,
	CircularProgress,
	Typography,
	RouterLink,
	ButtonRouterLink,
} from 'components/common';
import { InvitationLayout } from 'components/community/invitation';
import { useGetPublicCommunity } from 'core/uses-cases/community';
import { useLocation, useParams, useRouter, useTranslation } from 'hooks';
import { Navigate } from 'navigation';
import { Trans } from 'react-i18next';

export const InvitationDeclineSuccess = () => {
	const { t } = useTranslation();
	const router = useRouter();
	const { communityId } = useParams() as {
		communityId: string;
	};
	const location = useLocation() as {
		state?: { fromInvitation: boolean };
	};
	const { data, isError, isLoading } = useGetPublicCommunity(communityId);

	if (!location?.state?.fromInvitation) {
		return (
			<Navigate
				to={router.getPathFor('community.home', { communityId })}
				replace
			/>
		);
	}

	if (isError) {
		return (
			<Navigate
				to={router.getPathFor('root')}
				alert={{
					level: 'error' as const,
					message: t('invitation.invalid-community'),
				}}
				replace
			/>
		);
	}

	if (!data || isLoading) {
		return (
			<Box display='flex' justifyContent='center' mt={4}>
				<CircularProgress message={t('common.loading')} />
			</Box>
		);
	}

	return (
		<InvitationLayout community={data}>
			<Box mb={5}>
				<Typography variant='body2'>
					<Trans
						i18nKey='invitation.decline-success-message'
						components={{
							support: (
								<RouterLink
									to={router.getPathFor('community.support')}
								/>
							),
						}}
					/>
				</Typography>
			</Box>
			<ButtonRouterLink
				size='small'
				to={router.getPathFor('community.search')}
			>
				{t('invitation.return-search')}
			</ButtonRouterLink>
		</InvitationLayout>
	);
};
