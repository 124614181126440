import { useServices } from 'hooks';
import { useMutation } from 'react-query';
import { ContactSupportInput } from 'core/services/api/api-client/types';
import { BaseHttpError } from 'core/services/api/errors';
import { postContactSupportMutationKey } from 'core/utils/mutation-key-factory';

export const useContactSupport = () => {
	const { api } = useServices();

	const mutation = useMutation<null, BaseHttpError, ContactSupportInput>(
		input => {
			return api.contactSupport(input).then(() => null);
		},
		{
			mutationKey: postContactSupportMutationKey,
		}
	);

	return mutation;
};
