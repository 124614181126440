import { useMutation, useQueryClient } from 'react-query';
import { Nullable, Community, Image } from 'core/types';
import { patchCommunityMutationKey } from 'core/utils/mutation-key-factory';
import { useServices } from 'hooks';
import { communityKeys } from 'core/utils/query-key-factory';
import { isUndefined } from 'utils/is';
import { BaseHttpError } from '../../services/api/errors';
import { PatchCommunityInput } from '../../services/api/api-client/types';

export interface PatchCommunityMutationInput
	extends Partial<Omit<PatchCommunityInput, 'image' | 'name'>> {
	communityId: string;
	name: string;
	image?: Nullable<Image>;
	setup_status?: number;
}

export const usePatchCommunity = () => {
	const { api, uploader } = useServices();

	const queryClient = useQueryClient();

	const mutation = useMutation<
		Community,
		BaseHttpError,
		PatchCommunityMutationInput
	>(
		async ({ communityId, image, ...input }) => {
			const remoteImageUuid =
				(await uploader.uploadImage(image))?.uuid ?? null;

			return api
				.patchCommunity(communityId, {
					...input,
					...(!isUndefined(image) ? { image: remoteImageUuid } : {}),
				})
				.then(response => response.data);
		},
		{
			mutationKey: patchCommunityMutationKey,
			onSuccess: () => {
				return queryClient.invalidateQueries(communityKeys.all());
			},
		}
	);

	return mutation;
};
