import { BaseHttpError } from 'core/services/api/errors';
import { CalendarSummary } from 'core/types';
import { calendarKeys } from 'core/utils/query-key-factory';
import { useServices } from 'hooks';
import { useQuery } from 'react-query';

export const useGetCalendar = (
	calendarId: string,
	options: { enabled: boolean } = { enabled: true }
) => {
	const { api } = useServices();

	return useQuery<CalendarSummary, BaseHttpError>(
		calendarKeys.view(calendarId),
		() => api.getCalendar(calendarId).then(response => response.data),
		{
			enabled: !!calendarId && options.enabled,
		}
	);
};
