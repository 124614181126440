import {
	Box,
	Typography,
	Paper,
	PageHeading,
	RouterLink,
} from 'components/common';
import { Trans } from 'react-i18next';
import {
	useParams,
	useRouter,
	useServices,
	useSubmitHandlerForPausedMutation,
	useTranslation,
} from 'hooks';
import {
	NoteForm,
	NoteFormInitialValues,
	NoteFormSubmittedValues,
} from 'components/notes';
import { useVisibilityFormData } from 'components/community/hooks';
import { usePostNote } from 'core/uses-cases/note';

export const CreateNote = () => {
	const { t } = useTranslation();
	const { communityId } = useParams() as { communityId: string };
	const router = useRouter();

	const { notification } = useServices();

	const { mutateAsync, isPaused } = usePostNote();

	const handleOnSubmit =
		useSubmitHandlerForPausedMutation<NoteFormSubmittedValues>(
			isPaused,
			values => mutateAsync({ communityId, ...values }),
			{
				onSuccess: ({ values }) => {
					notification.show(
						'success',
						t('notes.note-was-successfully-created', {
							title: values.title,
						})
					);

					router.navigate('community.notes', {
						communityId,
					});
				},
			},
			[communityId]
		);

	const { visibilityData, groups } =
		useVisibilityFormData<NoteFormInitialValues>();

	return (
		<Box>
			<PageHeading title={t('notes.create-note')}>
				<Typography variant='body2' mb={1} mt={1}>
					{t('notes.notes-help-you')}
				</Typography>
				<Typography variant='body2' mb={1}>
					<Trans i18nKey='notes.share-a-note'>
						<RouterLink
							to={router.getPathFor('community.groups', {
								communityId,
							})}
						/>
					</Trans>
				</Typography>
			</PageHeading>
			<Box component={Paper} elevation={0} mt={6} px={5} py={5}>
				<NoteForm
					onSubmit={handleOnSubmit}
					submitText={t('note-form.post-note')}
					groups={groups}
					initialValues={{
						...visibilityData,
					}}
				/>
			</Box>
		</Box>
	);
};
