import { isCancelledError, useMutation } from 'react-query';
import { v4 as uuid } from 'uuid';
import {
	Nullable,
	Announcement,
	Community,
	Identity,
	Image,
	CommunityMember,
} from 'core/types';
import { SetupMutationFn } from 'core/configure/types';
import { announcementKeys, communityKeys } from 'core/utils/query-key-factory';
import { postAnnouncementMutationKey } from 'core/utils/mutation-key-factory';
import {
	onErrorOptimisticInInfinityQueryCache,
	onPostMutateOptimisticInfinityQueryCache,
	onSuccessOptimisticInInfinityQueryCache,
} from 'core/utils/optimistic-utils';
import { mapVisibilityWithGroups } from 'core/utils/mapper';
import { Services } from '../../services/types';
import { BaseHttpError } from '../../services/api/errors';
import { AnnouncementInput } from '../../services/api/api-client/types';
import { getAnnouncementListQueryKey } from './utils';

export interface PostAnnouncementMutationInput
	extends Omit<AnnouncementInput, 'image'> {
	whenToPost: string;
	communityId: string;
	image: Nullable<Image>;
}

export const usePostAnnouncement = () => {
	const mutation = useMutation<
		Announcement,
		BaseHttpError,
		PostAnnouncementMutationInput
	>(postAnnouncementMutationKey);

	return mutation;
};

type Context = {
	communityId: string;
	announcementId: string;
	newAnnouncement: Announcement;
};

export const setupPostAnnouncement: SetupMutationFn = (
	services: Services,
	createTrackedParallelMutation,
	mutationTracker
) => {
	const { queryClient, api, uploader } = services;

	const mutationOptions = createTrackedParallelMutation<
		Announcement,
		BaseHttpError,
		PostAnnouncementMutationInput,
		Context
	>({
		mutationFn: async ({ communityId, image, ...input }) => {
			const remoteImage = await uploader.uploadImage(image);

			return api
				.postAnnouncement(communityId, {
					...input,
					image: remoteImage?.uuid ?? null,
				})
				.then(response => response.data);
		},
		onMutate: async input => {
			const {
				communityId,
				whenToPost,
				publishedDt,
				allowDiscussion,
				image,
				message,
				title,
				visibility,
				visibilityGroups,
				author,
			} = input;

			const announcementId = uuid();

			const community = queryClient.getQueryData<Community>(
				communityKeys.detail(communityId)
			);
			const communityMembers = queryClient.getQueryData<
				CommunityMember[]
			>(communityKeys.members(communityId));

			const announcementAuthor = communityMembers?.find(
				communityMember => communityMember.identity.uuid === author
			);

			// Create optimistic announcement
			const newAnnouncement: Announcement = {
				title,
				message,
				image,
				uuid: announcementId,
				author: announcementAuthor?.identity as Identity,
				created_dt: null,
				discussion_state: allowDiscussion ? 'enabled' : 'disabled',
				discussion_stats: { comments: 0 },
				discussion_uuid: uuid(),
				is_featured: false,
				published_dt: publishedDt ?? null,
				video: null,
				identity_permissions: [],
				who_can_see: mapVisibilityWithGroups(
					visibility,
					visibilityGroups,
					community?.groups.all ?? []
				),
			};

			const listQueryKey = getAnnouncementListQueryKey({
				communityId,
				whenToPost,
				publishedDt,
			});

			await onPostMutateOptimisticInfinityQueryCache<Announcement>(
				queryClient,
				listQueryKey,
				announcementKeys.detail(communityId, announcementId),
				newAnnouncement,
				[announcementKeys.lists(communityId)]
			);

			return {
				communityId,
				announcementId,
				newAnnouncement,
			};
		},
		onSuccess: (
			result,
			{ communityId, whenToPost, publishedDt },
			{ announcementId }
		) => {
			const queryKey = getAnnouncementListQueryKey({
				communityId,
				whenToPost,
				publishedDt,
			});

			onSuccessOptimisticInInfinityQueryCache<Announcement>(
				queryClient,
				queryKey,
				announcementKeys.detail(communityId, announcementId),
				announcementId,
				result
			);
		},
		onError: (error, { communityId, whenToPost, publishedDt }, context) => {
			if (context && !isCancelledError(error)) {
				const queryKey = getAnnouncementListQueryKey({
					communityId,
					whenToPost,
					publishedDt,
				});

				onErrorOptimisticInInfinityQueryCache(
					queryClient,
					queryKey,
					context?.announcementId
				);
			}
		},
		onSettled: (data, error, variables, context) => {
			if (context && !isCancelledError(error) && error) {
				mutationTracker.queueInvalidations(
					announcementKeys.lists(context.communityId),
					announcementKeys.detail(
						variables.communityId,
						context.announcementId
					),
					communityKeys.feed(context.communityId)
				);
			}
		},
		retry: 1,
	});

	queryClient.setMutationDefaults(
		postAnnouncementMutationKey,
		mutationOptions
	);
};
