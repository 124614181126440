import { SxProps } from '@mui/system';
import { Container, Box, BoxProps } from '../../common';
import { contentBackgroundStyle, contentStyle } from './styles';

export type ContentProps = BoxProps & {
	withBackground?: boolean;
};

export const Content: React.FC<ContentProps> = ({
	children,
	withBackground = false,
	sx,
}) => {
	return (
		<Box
			sx={
				[
					contentStyle,
					...(withBackground ? [contentBackgroundStyle] : []),
					sx,
				] as SxProps
			}
		>
			<Container>{children}</Container>
		</Box>
	);
};
