import { DialogHookOptions, useDialog } from 'components/common';
import {
	DeletePhotoMutationInput,
	useDeletePhoto,
} from 'core/uses-cases/photo';
import { useServices, useTranslation } from 'hooks';

export const useDeletePhotoHandler = (
	options?: DialogHookOptions<DeletePhotoMutationInput>
) => {
	const { t } = useTranslation();
	const { notification } = useServices();
	const { mutate, isLoading } = useDeletePhoto();

	const { open } = useDialog<DeletePhotoMutationInput>({
		title: t('photos.delete-confirm-title'),
		content: t('photos.delete-confirm-content'),
		confirmText: t('common.delete'),
		onConfirm: input => {
			if (!input) return;

			mutate(input, {
				onSuccess: () => {
					options?.onConfirm?.(input);

					notification.show(
						'success',
						t('photos.photo-was-successfully-deleted')
					);
				},
			});
		},
		onCancel: options?.onCancel,
	});

	return { open, isDeletingPhoto: isLoading };
};
