import { SetupMutationFn } from 'core/configure/types';
import { BaseHttpError } from 'core/services/api/errors';
import { Community, Group, CommunityMember } from 'core/types';
import { communityKeys, permissionKeys } from 'core/utils/query-key-factory';
import { isCancelledError, Mutation, useMutation } from 'react-query';
import { patchCommunityGroupMutationKey } from 'core/utils/mutation-key-factory';
import {
	onPatchMutateOptimisticInfinityQueryCache,
	cancelPreviousMutation,
	onSuccessOptimisticInInfinityQueryCache,
	findRecordFromInfinityQueryCache,
	PaginatedRecordsSnapshot,
} from 'core/utils/optimistic-utils';
import { mapVisibilityWithGroups } from 'core/utils/mapper';
import { PatchGroupInput } from '../../services/api/api-client/types';

export type PatchCommunityGroupMutationInput = {
	communityId: string;
	groupId: string;
} & PatchGroupInput;

export const usePatchCommunityGroup = () => {
	const mutation = useMutation<
		Group,
		BaseHttpError,
		PatchCommunityGroupMutationInput
	>(patchCommunityGroupMutationKey);

	return mutation;
};

type MutationContext = {
	communityId: string;
	groupId: string;
	previousGroup: Group;
	updatedGroup: Group;
	listSnapshot: PaginatedRecordsSnapshot<Group>;
};

export const setupPatchCommunityGroup: SetupMutationFn = (
	services,
	createTrackedParallelMutation,
	mutationTracker
) => {
	const { queryClient, api } = services;

	const mutation = createTrackedParallelMutation<
		Group,
		BaseHttpError,
		PatchCommunityGroupMutationInput,
		MutationContext
	>({
		mutationFn: async ({ groupId, communityId, ...input }) => {
			return api
				.patchGroup(communityId, groupId, input)
				.then(response => response.data);
		},
		onMutate: async input => {
			const { groupId, communityId, type } = input;

			const community = queryClient.getQueryData<Community>(
				communityKeys.detail(communityId)
			);

			const communityMembers = queryClient.getQueryData<
				CommunityMember[]
			>(communityKeys.members(communityId));

			const previousGroup = queryClient.getQueryData<Group>(
				communityKeys.group(communityId, groupId)
			) as Group;

			//  optimistic Group
			const updatedGroup: Group = {
				...previousGroup,
				...(type === 'members' && {
					members: input.members.map(memberId => {
						const member = communityMembers?.find(
							m => m.identity.uuid === memberId
						);

						return {
							uuid: member?.identity.uuid ?? '',
							first_name: member?.identity.first_name ?? '',
							last_name: member?.identity.last_name ?? '',
							email: member?.identity.email ?? '',
						};
					}),
				}),
				...(type === 'visibility' && {
					who_can_see: mapVisibilityWithGroups(
						input.visibility,
						input.visibilityGroups ?? [],
						community?.groups.all ?? []
					),
				}),
				...(type === 'name' && {
					name: input.name,
					description: input.description,
				}),
			};

			// Cancel the previous mutation if it exists
			await cancelPreviousMutation<MutationContext>(
				queryClient,
				mutation => {
					return (
						mutation.state.context?.groupId === groupId &&
						isPatchCommunityGroupMutation(mutation)
					);
				}
			);

			const { listSnapshot } =
				findRecordFromInfinityQueryCache<Group>(
					queryClient,
					communityKeys.groups(communityId),
					groupId
				) ?? {};

			await onPatchMutateOptimisticInfinityQueryCache<Group>(
				queryClient,
				communityKeys.groups(communityId),
				communityKeys.group(communityId, groupId),
				updatedGroup
			);

			return {
				communityId,
				groupId,
				previousGroup,
				updatedGroup,
				listSnapshot,
			};
		},
		onSuccess: (result, { communityId, groupId }) => {
			onSuccessOptimisticInInfinityQueryCache<Group>(
				queryClient,
				communityKeys.groups(communityId),
				communityKeys.group(communityId, groupId),
				groupId,
				result
			);

			queryClient.invalidateQueries(communityKeys.all());
		},
		onError: (error, input, context) => {
			if (!isCancelledError(error)) {
				if (context?.listSnapshot) {
					queryClient.setQueryData(
						communityKeys.groups(context.communityId),
						context?.listSnapshot
					);
				}

				if (context?.previousGroup) {
					queryClient.setQueryData(
						communityKeys.group(
							context.communityId,
							context.groupId
						),
						context?.previousGroup
					);
				}
			}
		},
		onSettled: (data, error, variables, context) => {
			if (context && !isCancelledError(error)) {
				mutationTracker.queueInvalidations(
					permissionKeys.all(context.communityId)
				);
			}
		},
		retry: 1,
	});

	queryClient.setMutationDefaults(patchCommunityGroupMutationKey, mutation);
};

export const isPatchCommunityGroupMutation = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	mutation: Mutation<any, any, any, any>
) =>
	[patchCommunityGroupMutationKey].includes(
		String(mutation?.options?.mutationKey)
	);
