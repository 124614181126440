import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { Box } from '@mui/system';
import { Tab, Tabs } from '../../common/tabs';
import { useCalendar } from '../context';
import { CalendarViewTypes } from '../types';

export const CalendarViews = () => {
	const calendar = useCalendar();
	const location = useLocation();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
		navigate(newValue);
	};

	const handleSelectChange = (event: SelectChangeEvent) => {
		navigate(event.target.value);
	};

	const isMatching = (key: string) => location.pathname.endsWith(key);

	const views: { value: CalendarViewTypes; label: string }[] = [
		{
			value: 'day',
			label: t('calendar-widget.day'),
		},
		{
			value: 'week',
			label: t('calendar-widget.week'),
		},
		{
			value: 'month',
			label: t('calendar-widget.month'),
		},
		{
			value: 'year',
			label: t('calendar-widget.year'),
		},
		{
			value: 'agenda',
			label: t('calendar-widget.agenda'),
		},
	];

	const value = views.find(({ value: v }) => isMatching(v))?.value ?? 'month';

	useEffect(() => {
		calendar.setCurrentView(value);
	}, [location]);

	return (
		<>
			<Box display={{ xs: 'none', sm: 'block' }}>
				<Tabs value={value} onChange={handleChange}>
					{views.map(({ value, label }) => (
						<Tab
							key={value}
							value={value}
							label={label}
							variant='unset'
							sx={theme => ({
								bgcolor: theme.palette.common.white,
							})}
						/>
					))}
				</Tabs>
			</Box>
			<Box display={{ xs: 'block', sm: 'none' }}>
				<Select value={value} onChange={handleSelectChange}>
					{views.map(view => (
						<MenuItem key={view.value} value={view.value}>
							{view.label}
						</MenuItem>
					))}
				</Select>
			</Box>
		</>
	);
};
