import { AxiosError } from 'axios';
import { ALREADY_CREATED_WELL_WISH } from './error-codes';
import { HttpClientError } from './errors';

export class NotAllowedToPostWellWish extends HttpClientError {
	constructor() {
		super({
			message: 'Client Error',
			originalError: new Error(
				'Not allowed to create a well wish'
			) as AxiosError,
			type: 'forbidden',
			errorCode: ALREADY_CREATED_WELL_WISH,
			displayError: false,
		});
	}
}

export const isNotAllowToPostWellWish = (error: any): boolean => {
	return error instanceof NotAllowedToPostWellWish;
};
