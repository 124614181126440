import { Checkbox } from '@mui/material';
import { Typography } from 'components';
import { Group } from 'core/types';
import { GridCell, GridCellHeader, GridRow } from './grid';
import { useHandleSelection } from './use-handle-selection';

type PermissionGridRowFieldProps = {
	groups: Group[];
	label: string;
	name: string;
	value: string[];
	onChange?: (value: string[]) => void;
};

export const PermissionGridRowField = ({
	groups,
	label,
	name,
	value = [],
	onChange,
}: PermissionGridRowFieldProps) => {
	const { memberUuid, options, selected, handleOnSelect } =
		useHandleSelection(groups, {
			value,
			onChange,
		});

	return (
		<GridRow>
			<GridCellHeader>
				<Typography>{label}</Typography>
			</GridCellHeader>
			{options.map(({ value, disabled }) => (
				<GridCell key={`permission-${name}-group-${value}`}>
					<Checkbox
						checked={selected.has(value)}
						onChange={handleOnSelect.bind(null, value)}
						disabled={disabled}
					/>
				</GridCell>
			))}
			<GridCell>
				<Checkbox checked={!selected.has(memberUuid)} disabled />
			</GridCell>
		</GridRow>
	);
};
