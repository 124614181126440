import { SxProps } from '@mui/system';
import { Theme } from 'theme';

export const bodyStyle: SxProps<Theme> = {
	'> p': {
		fontSize: '16px',
		lineHeight: '24px',
		letterSpacing: '0px',
		margin: 0,
		padding: 0,
	},
};

export const imageStyle: SxProps<Theme> = {
	mt: 3,
	'> img': {
		width: { xs: 'auto', md: 300 },
		height: { xs: 'auto', md: 200 },
		maxWidth: '100%',
	},
};

export const videoStyle: SxProps<Theme> = {
	mt: 3,
	'> video': {
		width: { xs: 'auto', md: 412 },
		height: { xs: 'auto', md: 233 },
		maxWidth: '100%',
	},
};
