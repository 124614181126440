import {
	Box,
	Divider,
	Paper,
	Typography,
	Grid,
	RouterLink,
	Link,
} from 'components';
import {
	ResetPasswordForm,
	ResetPasswordFormSubmittedValues,
} from 'components/account';
import config from 'config';
import { usePostResetPassword } from 'core/uses-cases/password/use-post-reset-password';
import {
	useRouter,
	useSearchParams,
	useSubmitHandlerForPausedMutation,
	useTranslation,
} from 'hooks';
import { useMemo } from 'react';
import { Trans } from 'react-i18next';

export const ResetPassword = () => {
	const { t } = useTranslation();
	const router = useRouter();
	const [searchParams] = useSearchParams();

	const { isPaused, mutateAsync } = usePostResetPassword();
	const email = searchParams.get('email') ?? '';

	const handleOnSubmit =
		useSubmitHandlerForPausedMutation<ResetPasswordFormSubmittedValues>(
			isPaused,
			values => mutateAsync(values),
			{
				onSuccess: () => {
					router.navigate(
						'account.reset-success',
						{},
						{},
						{
							replace: true,
						}
					);
				},
			}
		);
	const initialValues = useMemo(
		() => ({
			email,
		}),
		[email]
	);

	return (
		<Grid container justifyContent='center' textAlign='center'>
			<Grid item xs={12} sm={5}>
				<Box component={Paper} p={3}>
					<Typography variant='h5'>
						{t('reset-password.forgot-password')}
					</Typography>
					<Box mt={3}>
						<ResetPasswordForm
							initialValues={initialValues}
							onSubmit={handleOnSubmit}
						/>
					</Box>
					<Divider mt={5} />
					<Box mt={5}>
						<Typography variant='body1'>
							<Trans
								i18nKey='reset-password.no-account-message'
								components={{
									search: (
										<RouterLink
											to={router.getPathFor(
												'community.search'
											)}
										/>
									),
									create: (
										<RouterLink
											to={router.getPathFor(
												'community.create'
											)}
										/>
									),
								}}
							/>
						</Typography>
						<Typography variant='body1' mt={2}>
							<Trans
								i18nKey='reset-password.having-trouble'
								components={{
									mail: (
										<Link
											href={`mailto:${config.supportEmail}`}
										/>
									),
								}}
							/>
						</Typography>
					</Box>
				</Box>
			</Grid>
		</Grid>
	);
};
