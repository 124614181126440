import { useMutation } from 'react-query';
import { postMembershipInvitationMutationKey } from 'core/utils/mutation-key-factory';
import { useServices } from 'hooks';
import { MembershipInvitationResponse } from 'core/services/api/response-types';
import { communityKeys } from 'core/utils/query-key-factory';
import { BaseHttpError } from '../../services/api/errors';
import { MembershipInvitationInput } from '../../services/api/api-client/types';

export type PostMembershipInvitationMutationInput =
	MembershipInvitationInput & {
		communityId: string;
	};

export const usePostMembershipInvitation = () => {
	const { api, queryClient } = useServices();

	const mutation = useMutation<
		MembershipInvitationResponse['data'],
		BaseHttpError,
		PostMembershipInvitationMutationInput
	>(
		({ communityId, ...input }) => {
			return api
				.postMembershipInvitation(communityId, input)
				.then(response => response.data);
		},
		{
			mutationKey: postMembershipInvitationMutationKey,
			onSettled: (_data, _error, variables) => {
				queryClient.invalidateQueries(
					communityKeys.pendingInvites(variables.communityId)
				);
				queryClient.invalidateQueries(
					communityKeys.failedInvites(variables.communityId)
				);
				queryClient.invalidateQueries(
					communityKeys.detail(variables.communityId)
				);
			},
		}
	);

	return mutation;
};
