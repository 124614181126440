import { FC, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

const modalRoot = document.getElementById('modal-root');

export const Portal: FC = props => {
	const element = useRef(document.createElement('div')).current;

	useEffect(() => {
		modalRoot?.appendChild(element);

		return () => {
			modalRoot?.removeChild(element);
		};
	}, []);

	return ReactDOM.createPortal(props.children, element);
};
