import { isCancelledError, useMutation } from 'react-query';
import { v4 as uuid } from 'uuid';
import { WellWish, Identity, Nullable, Image as ImageType } from 'core/types';
import { SetupMutationFn } from 'core/configure/types';
import {
	authenticationKeys,
	communityKeys,
	wellWishKeys,
} from 'core/utils/query-key-factory';
import { postWellWishMutationKey } from 'core/utils/mutation-key-factory';
import {
	onErrorOptimisticInInfinityQueryCache,
	onPostMutateOptimisticInfinityQueryCache,
	onSuccessOptimisticInInfinityQueryCache,
	PaginatedRecords,
} from 'core/utils/optimistic-utils';
import { DateTime } from 'luxon';
import {
	isNotAllowToPostWellWish,
	NotAllowedToPostWellWish,
} from 'core/services/api/errors/app-errors';
import { cacheImage } from 'utils/url';
import { feedImageConfigs } from 'components/community/feed/shared';
import { Services } from '../../services/types';
import { BaseHttpError } from '../../services/api/errors';
import { WellWishInput } from '../../services/api/api-client/types';

export interface PostWellWishMutationInput
	extends Omit<WellWishInput, 'image'> {
	communityId: string;
	image: Nullable<ImageType>;
}

export const usePostWellWish = () => {
	const mutation = useMutation<
		WellWish,
		BaseHttpError,
		PostWellWishMutationInput
	>(postWellWishMutationKey);

	return mutation;
};

type Context = {
	communityId: string;
	wellWishId: string;
	newWellWish: WellWish;
};

export const setupPostWellWish: SetupMutationFn = (
	services: Services,
	createTrackedParallelMutation,
	mutationTracker
) => {
	const { queryClient, api, uploader } = services;

	const mutation = createTrackedParallelMutation<
		WellWish,
		BaseHttpError,
		PostWellWishMutationInput,
		Context
	>({
		mutationFn: async ({ communityId, image, ...input }) => {
			const remoteImage = await uploader.uploadImage(image);

			return api
				.postWellWish(communityId, {
					...input,
					image: remoteImage?.uuid ?? null,
				})
				.then(response => response.data);
		},
		onMutate: async input => {
			const { communityId, message, image } = input;

			const wishes = queryClient.getQueryData<PaginatedRecords<WellWish>>(
				wellWishKeys.list(communityId)
			);
			const author = queryClient.getQueryData<Identity>(
				authenticationKeys.identity
			) as Identity;

			const isNotAllowedToPost = wishes?.pages.some(page =>
				page.data.some(
					wish =>
						wish.author.uuid === author.uuid &&
						wish.created_dt &&
						DateTime.now() <=
							DateTime.fromISO(wish.created_dt).plus({
								minutes: 15,
							})
				)
			);

			if (isNotAllowedToPost) {
				throw new NotAllowedToPostWellWish();
			}

			const wellWishId = uuid();

			if (image?.scaledUrl) {
				image.url = image.scaledUrl;
			}

			const newWellWish: WellWish = {
				uuid: wellWishId,
				message,
				image,
				author,
				created_dt: null,
			};

			await onPostMutateOptimisticInfinityQueryCache<WellWish>(
				queryClient,
				wellWishKeys.list(communityId),
				wellWishKeys.detail(communityId, wellWishId),
				newWellWish
			);

			return { communityId, wellWishId, newWellWish };
		},
		onSuccess: async (result, { communityId }, context) => {
			if (context?.wellWishId) {
				const { wellWishId } = context;

				if (result.image?.url) {
					await cacheImage(result.image.url, feedImageConfigs);
				}

				onSuccessOptimisticInInfinityQueryCache(
					queryClient,
					wellWishKeys.list(communityId),
					wellWishKeys.detail(communityId, wellWishId),
					wellWishId,
					result
				);
			}
		},
		onError: (error, { communityId }, context) => {
			if (context && !isCancelledError(error)) {
				onErrorOptimisticInInfinityQueryCache(
					queryClient,
					wellWishKeys.list(communityId),
					context.wellWishId
				);
			}
		},
		onSettled: (data, error, variables, context) => {
			if (
				context &&
				!isCancelledError(error) &&
				!isNotAllowToPostWellWish(error)
			) {
				mutationTracker.queueInvalidations(
					wellWishKeys.list(context.communityId),
					wellWishKeys.detail(
						variables.communityId,
						context.wellWishId
					),
					communityKeys.feed(context.communityId)
				);
			}
		},
		retry: 1,
	});

	queryClient.setMutationDefaults(postWellWishMutationKey, mutation);
};
