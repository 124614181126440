import { Group, Identity } from 'core/types';
import {
	Box,
	Field,
	Form,
	FormOnSubmitReturn,
	FormStack,
	LoadingButton,
	SelectMultipleField,
	VisibilityField,
	VisibilityFieldValues,
} from 'components/common';
import { useTranslation } from 'hooks';
import { useCallback, useMemo, useRef } from 'react';
import { sleep } from 'utils/delay';

export type GroupMembersFormSubmittedValues = {
	members: string[];
} & VisibilityFieldValues;

export type GroupMembersFormInitialValues =
	Partial<GroupMembersFormSubmittedValues>;

export type GroupMembersFormProps = {
	onSubmit?: (
		values: GroupMembersFormSubmittedValues
	) => FormOnSubmitReturn<Group>;
	submitText?: string;
	submittingText?: string;
	identities: Identity[];
	groups: Group[];
	initialValues?: GroupMembersFormInitialValues;
};

export const GroupMembersForm = (props: GroupMembersFormProps) => {
	const {
		onSubmit,
		submitText,
		submittingText,
		identities,
		groups,
		initialValues,
	} = props;

	const { t } = useTranslation();

	const cachedInitialValues = useRef({
		members: [],
		...initialValues,
	}).current;

	const handleOnSubmit = useCallback(
		async ({ members, visibility, visibilityGroups }) => {
			await sleep(1);

			const values = {
				members,
				visibility,
				visibilityGroups:
					visibility === 'custom' ? visibilityGroups : [],
			};

			const result = onSubmit?.(values);

			return Promise.resolve(result).catch(error => error);
		},
		[onSubmit]
	);

	const identityOptions = useMemo(
		() =>
			identities.map(({ uuid, first_name, last_name }) => ({
				label: `${first_name} ${last_name}`,
				value: uuid,
			})),
		[identities]
	);

	return (
		<Form
			onSubmit={handleOnSubmit}
			subscription={{ submitting: true }}
			initialValues={cachedInitialValues}
		>
			{({ handleSubmit, submitting }) => (
				<form onSubmit={handleSubmit} noValidate>
					<FormStack>
						<Box>
							<Field
								name='members'
								component={SelectMultipleField}
								options={identityOptions}
								label={t('group-form.members')}
							/>
						</Box>
						<Box>
							<VisibilityField
								name='visibility'
								groups={groups}
								label={t('visibility-field.who-can-see-this')}
							/>
						</Box>
						<Box>
							<LoadingButton
								loading={submitting}
								loadingIndicator={
									submittingText ||
									t('group-form.updating-group')
								}
								size='small'
								type='submit'
							>
								{submitText || t('group-form.update-group')}
							</LoadingButton>
						</Box>
					</FormStack>
				</form>
			)}
		</Form>
	);
};
