import { forwardRef } from 'react';
import { styled } from '@mui/material/styles';
import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import { SxProps } from '@mui/system';
import { styles } from './styles';

export type ButtonProps = MuiButtonProps & {
	noWrap?: boolean;
};

const InnerButton = styled(MuiButton)<ButtonProps>(styles);

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
	function Button({ noWrap, sx, ...rest }, ref) {
		return (
			<InnerButton
				variant='contained'
				size='medium'
				color='primary'
				{...rest}
				disableElevation
				sx={
					[
						sx,
						{
							whiteSpace: noWrap ? 'nowrap' : 'normal',
						},
					] as SxProps
				}
				ref={ref}
			/>
		);
	}
);
