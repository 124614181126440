import { Box, CircularProgress } from 'components';
import { useGetInvitationToken } from 'core/uses-cases/community';
import { useParams, useRouter, useSearchParams } from 'hooks';
import { Navigate } from 'navigation';
import { useTranslation } from 'react-i18next';
import { useTransientStore } from 'store';

export const JoinEmailTarget = () => {
	const { communityId } = useParams() as { communityId: string };
	const [queryParams] = useSearchParams();
	const store = useTransientStore();
	const { t } = useTranslation();
	const router = useRouter();
	const tokenId = queryParams.get('t') || '';
	const { isError, isSuccess } = useGetInvitationToken(communityId, tokenId);

	if (!tokenId) {
		return (
			<Navigate
				to={router.getPathFor('community.home', { communityId })}
				alert={{
					level: 'error',
					message: t('invitation.not-giving-token'),
				}}
			/>
		);
	}

	if (isError) {
		return (
			<Navigate
				to={router.getPathFor('community.invitation.invalid-token', {
					communityId,
					tokenId,
				})}
				replace
			/>
		);
	}

	if (isSuccess) {
		const redirectKey = store.auth.isLoggedIn
			? 'community.invitation.join'
			: 'community.invitation.select-email';

		return (
			<Navigate
				to={router.getPathFor(redirectKey, {
					communityId,
					tokenId,
				})}
				replace
			/>
		);
	}

	return (
		<Box display='flex' justifyContent='center' mt={4}>
			<CircularProgress message={t('common.loading')} />
		</Box>
	);
};
