import * as ERROR_CODES from './error-codes';
import { MessageMatcherEntry } from './types';

export const knownMessages: { [key: string | number]: string } = {
	// key: lang-key
	generic: 'generic-error',
	connection: 'connection-error',
	timeout: 'timeout-error',
	unknown: 'generic-error',
	server: 'generic-error',
	forbidden: 'authorized-request-error',
	unauthorized: 'unauthorized-request-error',
	[ERROR_CODES.UNKNOWN_ERROR]: 'generic-error',
	[ERROR_CODES.COLLECTION_NOT_FOUND]: 'collection-not-found',
	[ERROR_CODES.RESOURCE_NOT_FOUND]: 'resource-not-found',
	[ERROR_CODES.AUTH_INVALID]: 'invalid-credentials',
	[ERROR_CODES.MISSING_PARAMETER]: 'some-parameters-are-required',
	[ERROR_CODES.AUTHORIZATION_INVALID]: 'invalid-authorization',
	[ERROR_CODES.TOKEN_INVALID]: 'invalid-token',
	[ERROR_CODES.TOKEN_EXPIRED]: 'token-expired',
	[ERROR_CODES.INVALID_PARAMETER]: 'invalid-parameter',
	[ERROR_CODES.INVALID_USERNAME]: 'invalid-username',
	[ERROR_CODES.ALREADY_MEMBER]: 'already-member',
	[ERROR_CODES.PENDING_MEMBER_REQUEST]: 'pending-member-request',
	[ERROR_CODES.DUPLICATED_LOGIN]: 'duplicated-login',
	[ERROR_CODES.EMAIL_NOT_VERIFIED]: 'email-not-verified',
	[ERROR_CODES.DUPLICATED_IDENTITY]: 'duplicated-identity',
	[ERROR_CODES.ALREADY_CREATED_WELL_WISH]: 'already-created-well-wish',
	[ERROR_CODES.ALBUM_NOT_EMPTY]: 'album-not-empty',
	[ERROR_CODES.NOT_OWNER]: 'not-owner',
	[ERROR_CODES.AUTHORIZATION_REQUIRED]: 'authorization-required',
	[ERROR_CODES.ALREADY_VOLUNTEER]: 'already-volunteer',
	[ERROR_CODES.NO_VOLUNTEER_NEEDED]: 'no-volunteer-needed',
	[ERROR_CODES.ALREADY_EXISTING_SLUG]: 'already-existing-slug',
	[ERROR_CODES.CANT_REMOVE_COMMUNITY_OWNER]: 'cant-remove-community-owner',
	[ERROR_CODES.DUPLICATED_GROUP]: 'duplicated-group',
	[ERROR_CODES.WRONG_VERSION]: 'wrong-version',
	[ERROR_CODES.MAINTENANCE]: 'maintenance',
	[ERROR_CODES.RECAPTCHA]: 're-captcha',
	[ERROR_CODES.MUST_WAIT]: 'must-wait',
	[ERROR_CODES.INVALID_FILE_TYPE]: 'invalid-file-type',
	[ERROR_CODES.INVALID_URI]: 'invalid-uri',
};

export const messagesWithRegExp: MessageMatcherEntry[] = [
	{
		path: /\/?community\/[^/]+\/token\/(.+)\?type=auto_login/,
		method: 'GET',
		status: 404,
		message: 'invalid-token',
	},
	{
		path: 'subscription',
		method: 'PATCH',
		status: 404,
		message: 'invalid-token',
	},
	{
		path: /\/?oauth-token\/(.+)/,
		method: 'GET',
		status: 404,
		message: 'invalid-reset-password-link',
	},
	{
		path: 'identity/me/login',
		method: 'PATCH',
		status: 400,
		message: 'weak-password',
	},
];

export const getErrorMessage = (
	path: string,
	method: string,
	status: number
): MessageMatcherEntry['message'] => {
	const found = messagesWithRegExp.find(entry => {
		let pathMatch;
		if (typeof entry.path === 'string') {
			pathMatch = entry.path === path;
		} else if (entry.path instanceof RegExp) {
			pathMatch = entry.path.test(path);
		}

		if (!pathMatch) return false;
		if (entry.method && entry.method.toLowerCase() !== method.toLowerCase())
			return false;
		if (entry.status && entry.status !== status) return false;

		return true;
	});

	return found?.message ?? null;
};
