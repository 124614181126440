import { Box, Typography } from 'components/common';
import lotsaLogo from 'assets/images/lotsa-logo-white.svg?url';
import { useTranslation } from 'hooks';

export const FooterLogo = () => {
	const { t } = useTranslation();
	return (
		<Box
			display='flex'
			flexDirection='row'
			alignItems='center'
			justifyContent={{ xs: 'center' }}
			py={0}
			height={48}
		>
			<Box
				component='img'
				src={lotsaLogo}
				alt={t('footer.lotsa-helping-hands')}
				maxWidth={45}
				height={48}
			/>
			<Box
				component={Typography}
				ml={2}
				fontFamily='Foco'
				fontSize={20}
				color='white'
				position='relative'
			>
				{t('footer.lotsa-helping-hands')}
				<Box
					component='span'
					position='absolute'
					fontSize={8}
					fontWeight={600}
					lineHeight='8px'
					bottom={-8}
					right={0}
				>
					{t('footer.create-community')}
				</Box>
			</Box>
		</Box>
	);
};
