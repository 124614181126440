import { useCallback, useState } from 'react';
import { ButtonRouterLink, Box, Grid, PageHeading } from 'components/common';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { useRouter, useParams, useTranslation, useVerifyAccess } from 'hooks';
import {
	AnnouncementListByType,
	PublishedAnnouncementsList,
} from 'components/announcements';
import { useDeleteAnnouncementHandler } from 'components/announcements/hooks';

export const AnnouncementList = () => {
	const { t } = useTranslation();
	const router = useRouter();
	const { communityId } = useParams() as { communityId: string };
	const verify = useVerifyAccess();

	const onDeleteHandler = useDeleteAnnouncementHandler();

	const handleOnClick = useCallback(announcement => {
		router.navigate('announcement.view', {
			communityId,
			announcementId: announcement.uuid,
		});
	}, []);

	const handleOnEdit = useCallback(announcement => {
		router.navigate('announcement.edit', {
			communityId,
			announcementId: announcement.uuid,
		});
	}, []);

	const handleOnDelete = useCallback(announcement => {
		onDeleteHandler({
			communityId,
			announcementId: announcement.uuid,
		});
	}, []);

	const [columnStatus, setColumnStatus] = useState(() => ({
		draft: false,
		scheduled: false,
	}));

	const handleOnSettled = useCallback((type, data) => {
		setColumnStatus(prev => {
			return {
				...prev,
				[type]: data.length > 0,
			};
		});
	}, []);

	const showSideColumn = columnStatus.draft || columnStatus.scheduled;

	const canCreateAnnouncement = verify('create', 'announcement');

	return (
		<Box>
			<PageHeading
				title={t('announcements.announcements')}
				rightSection={
					canCreateAnnouncement && (
						<ButtonRouterLink
							to={router.getPathFor('announcement.create', {
								communityId,
							})}
							startIcon={
								<AddCircleOutlineOutlinedIcon fontSize='large' />
							}
							size='small'
							data-test-id='create-announcement-button'
						>
							{t('announcements.create-announcement')}
						</ButtonRouterLink>
						// eslint-disable-next-line react/jsx-indent
					)
				}
			/>
			<Grid container spacing={3}>
				<Grid
					item
					xs={showSideColumn ? 12 : 0}
					md={showSideColumn ? 3 : 0}
					display={showSideColumn ? 'block' : 'none'}
					sx={{ order: { xs: 1, md: 2 } }}
				>
					<AnnouncementListByType
						communityId={communityId}
						type='draft'
						title={data => {
							return t('announcements.drafts', {
								total: data.length,
							});
						}}
						onEdit={handleOnEdit}
						onSettled={handleOnSettled}
						data-test-id='draft-announcements-list'
					/>
					<AnnouncementListByType
						communityId={communityId}
						type='scheduled'
						title={data => {
							return t('announcements.scheduled', {
								total: data.length,
							});
						}}
						onEdit={handleOnEdit}
						onSettled={handleOnSettled}
						data-test-id='scheduled-announcements-list'
					/>
				</Grid>
				<Grid
					item
					xs={12}
					md={showSideColumn ? 9 : 12}
					sx={{ order: { xs: 2, md: 1 } }}
					data-test-id='published-announcements-list'
				>
					<PublishedAnnouncementsList
						communityId={communityId}
						onEdit={handleOnEdit}
						onDelete={handleOnDelete}
						onClick={handleOnClick}
						canCreate={canCreateAnnouncement}
					/>
				</Grid>
			</Grid>
		</Box>
	);
};
