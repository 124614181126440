import { Box, Paper } from 'components/common';
import { GroupForm, GroupFormSubmittedValues } from 'components/groups';
import { TeamHeader } from 'components/team';
import { usePostCommunityGroup } from 'core/uses-cases/group';
import {
	useParams,
	useRouter,
	useServices,
	useSubmitHandlerForPausedMutation,
	useTranslation,
} from 'hooks';
import { Navigate } from 'navigation';
import { useGroupFormData } from 'components/groups/use-group-form-data';
import { useGetGroupPermissions } from './use-group-access';

export const CreateGroup = () => {
	const { t } = useTranslation();
	const router = useRouter();
	const { communityId } = useParams() as { communityId: string };
	const { notification } = useServices();
	const { mutateAsync, isPaused } = usePostCommunityGroup();

	const handleOnSubmit =
		useSubmitHandlerForPausedMutation<GroupFormSubmittedValues>(
			isPaused,
			values => mutateAsync({ communityId, ...values }),
			{
				onSuccess: ({ values }) => {
					notification.show(
						'success',
						t('group.group-was-successfully-created', {
							name: values.name,
						})
					);
					router.navigate('community.groups', {
						communityId,
					});
				},
			},
			[communityId]
		);

	const { identities, initialValues, groups } = useGroupFormData(communityId);

	const { canCreateGroup, showGlobalPermissions } = useGetGroupPermissions();

	if (!canCreateGroup) {
		return (
			<Navigate
				to={router.getPathFor('community.groups', { communityId })}
			/>
		);
	}

	return (
		<>
			<TeamHeader title={t('groups.create-group')} />
			<Box component={Paper} elevation={0} mt={6} px={5} py={5}>
				<GroupForm
					onSubmit={handleOnSubmit}
					groups={groups}
					identities={identities}
					initialValues={initialValues}
					showPermissionsField={showGlobalPermissions}
				/>
			</Box>
		</>
	);
};
