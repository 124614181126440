import { useMemo, Fragment } from 'react';
import { useTranslation } from 'hooks';
import { useField } from 'react-final-form';
import { Box, Typography } from 'components/common';
import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { Icon } from 'components/common/icon';
import { checkIconStyle } from './styles';

type ValidationResultType = {
	label: (t: TFunction) => string;
	status: 'valid' | 'invalid' | 'ignored';
};

type PasswordRulesCheckerProps = {
	forField: string;
	usernameField: string;
	initialEmail: string;
	required?: boolean;
	disabled?: boolean;
};

export const PasswordRulesChecker = ({
	forField,
	usernameField,
	initialEmail,
	required = true,
	disabled = false,
}: PasswordRulesCheckerProps) => {
	const { t } = useTranslation();

	const password = useField(forField, {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		validate: (value, allValues: Record<string, any>, meta) => {
			const email = allValues?.[usernameField || ''] ?? '';

			let username = '';
			const match = /^([\w.+\-_]+)(@.*)$/.exec(email);

			if (match) {
				username = match[1];
			}

			if ((!value && required === false) || disabled) {
				if (meta?.data) {
					// Reset state
					meta.data.validationResult = validatePassword('', {
						username,
					});
				}
				return;
			}

			const result = validatePassword(value, { username });

			const error = result.every(e => e.status === 'valid')
				? undefined
				: t('form.generic-required');

			if (meta?.data) {
				meta.data.validationResult = result;
			}

			return error;
		},
		subscription: {
			data: true,
			value: true,
		},
	});

	let username = '';
	const match = /^([\w.+\-_]+)(@.*)$/.exec(initialEmail);

	if (match) {
		username = match[1];
	}

	const initialValidationResult = useMemo(
		() => validatePassword('', { username }),
		[]
	);

	const validationResult = (password?.meta?.data?.validationResult ??
		initialValidationResult) as ValidationResultType[];

	if (disabled) return null;

	return (
		<Box
			display='flex'
			flexDirection='column'
			data-test-id='password-rules'
		>
			{validationResult.map(entry => {
				const label = entry.label(t);
				return (
					<Fragment key={label}>
						<Box display='flex' alignItems='center'>
							<Box sx={checkIconStyle}>
								{entry.status !== 'valid' ? (
									<Icon name='check_box_outline_blank' />
								) : (
									<Icon name='check_box' />
								)}
							</Box>
							<Typography variant='caption' textAlign='left'>
								{label}
							</Typography>
						</Box>
					</Fragment>
				);
			})}
		</Box>
	);
};

const validatePassword = (value: string, context: Record<string, string>) => {
	const rules = [
		{
			label: (t: TFunction) => {
				const needed = 8 - String(value).length;

				let lengthRuleLabel = t('password-rules-checker.length-rule', {
					minLength: 8,
				});

				if (String(value).length > 0 && needed > 0) {
					lengthRuleLabel = `${lengthRuleLabel}.. ${t(
						'password-rules-checker.length-rule-with-needed',
						{ needed }
					)}`;
				}

				return lengthRuleLabel;
			},
			validator: Yup.string().min(8),
		},
		{
			label: (t: TFunction) =>
				t('password-rules-checker.contains-at-least'),
			validator: Yup.string().test('lower-upper-numbers', value => {
				return (
					/[a-z]/.test(String(value)) &&
					/[A-Z]/.test(String(value)) &&
					/[0-9]/.test(String(value))
				);
			}),
		},
		{
			label: (t: TFunction) =>
				t('password-rules-checker.must-not-repeat-the-same-letter'),
			validator: Yup.string().test('no-repeated-letters', value => {
				return !/(.)\1{2}/.test(String(value));
			}),
		},
		{
			label: (t: TFunction) =>
				t('password-rules-checker.must-not-contain-your-username', {
					username: context.username,
				}),
			validator: Yup.string().test('no-username', value => {
				return (
					context.username.length === 0 ||
					String(value).indexOf(context.username) < 0
				);
			}),
		},
	];

	const validationResult = rules.map<ValidationResultType>(entry => {
		const { label, validator } = entry;

		const status = !value
			? 'ignored'
			: validator.isValidSync(value)
				? 'valid'
				: 'invalid'; // prettier-ignore

		return { label, status };
	});

	return validationResult;
};
