import FormControl, { FormControlProps } from '@mui/material/FormControl';
import InputLabel, { InputLabelProps } from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText/FormHelperText';
import { FieldRenderProps } from 'react-final-form';
import { MultiChipsInput } from 'components/common/multi-chips-input';
import { AsYouType } from 'libphonenumber-js';
import { forwardRef } from 'react';
import { MaskElement } from 'imask';
import { IMaskInput } from 'react-imask';
import { OnErrorScrollPoint } from 'components/common/on-error-scroll-point';
import { contentStyle } from './styles';

export interface PhonesChipsProps extends Omit<FormControlProps, 'onChange'> {
	value: string[];
	label?: string;
	placeholder?: string;
	LabelProps?: InputLabelProps;
	name: string;
	helperText?: string;
	onChange: (value: string[]) => void;
	meta: FieldRenderProps<string>['meta'];
}

const isValidPhone = (rawPhone: string) => {
	const asYouType = new AsYouType('US');
	asYouType.input(rawPhone);
	return asYouType.isValid();
};

interface InnerInputProps {
	onChange: (event: {
		target: { name?: string; value: string | null };
		currentTarget: { name?: string; value: string | null };
	}) => void;
	onBlur?: (event: {
		target: { name?: string; value: string | null };
		currentTarget: { name?: string; value: string | null };
	}) => void;
	name?: string;
}

const createEvent = (name: string | undefined, rawPhone: string) => {
	return {
		target: { name, value: rawPhone },
		currentTarget: {
			name,
			value: rawPhone,
		},
	};
};

const InnerInput = forwardRef<HTMLElement, InnerInputProps>(
	function TextMaskCustom(props, ref) {
		const { onChange, onBlur, ...other } = props;
		return (
			<IMaskInput
				{...other}
				eager
				overwrite
				lazy
				mask='+{1} (000) 000-0000[  ]'
				inputRef={ref as unknown as React.RefCallback<MaskElement>}
				onAccept={(value: unknown) => {
					onChange(createEvent(props.name, value as string));
				}}
				onBlur={event => {
					onBlur?.(
						createEvent(props.name, event.target.value as string)
					);
				}}
			/>
		);
	}
);

const handleParsePhone = (rawPhone: string) => {
	const asYouType = new AsYouType();
	asYouType.input(rawPhone);
	return String(
		asYouType?.getNumber()?.formatNational() ?? asYouType.getChars()
	);
};

export const PhonesChips = (props: PhonesChipsProps) => {
	const {
		value = [],
		placeholder,
		label,
		LabelProps,
		helperText,
		onChange,
		meta,
		error,
		disabled,
		...rest
	} = props;

	return (
		<FormControl
			sx={contentStyle}
			disabled={disabled}
			error={error}
			{...rest}
		>
			<OnErrorScrollPoint name={rest.name} />
			{label && <InputLabel {...LabelProps}>{label}</InputLabel>}
			<MultiChipsInput
				value={value}
				onChange={onChange}
				dataValidation={isValidPhone}
				placeholder={placeholder}
				fullWidth={props.fullWidth}
				parseInput={handleParsePhone}
				inputComponent={InnerInput as never}
				delimiter='[\s]{2}'
				maskPlaceholder='(000) 000-0000'
				{...{ active: meta.active, error, disabled }}
			/>
			{helperText && <FormHelperText>{helperText}</FormHelperText>}
		</FormControl>
	);
};
