import { useServices } from 'hooks';
import { useMutation } from 'react-query';

export const useDeleteAccount = (uuid: string) => {
	const { api } = useServices();

	const { mutateAsync: doDeletionAccountAsync } = useMutation(() => {
		return api.deleteAccount(uuid);
	});

	return { doDeletionAccountAsync };
};
