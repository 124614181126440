import { Dispatch, ReactNode, SetStateAction } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';

export type DraggableContainerProps<T> = {
	list: T[];
	setList: Dispatch<SetStateAction<T[]>>;
	children: ReactNode;
};

export function DraggableContainer<T>({
	list,
	setList,
	children,
}: DraggableContainerProps<T>) {
	const reorder = (list: T[], startIndex: number, endIndex: number) => {
		const result = [...list];
		const [removed] = result.splice(startIndex, 1);

		result.splice(endIndex, 0, removed);

		return result;
	};

	const ondragEnd = (result: DropResult) => {
		if (!result.destination) {
			return;
		}

		setList(reorder(list, result.source.index, result.destination.index));
	};

	return <DragDropContext onDragEnd={ondragEnd}>{children}</DragDropContext>;
}
