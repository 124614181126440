import { SyntheticEvent, useState } from 'react';
import { v4 as uuid } from 'uuid';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import { useControlledValue } from 'hooks';
import { TextField, TextFieldProps } from '../text-field';

export type SelectMultipleOptions<T> = { value: T; label: string };

interface SelectMultipleFieldProps<T>
	extends Omit<
		TextFieldProps,
		'multiline' | 'variant' | 'onChange' | 'autoComplete'
	> {
	value: T[];
	options: SelectMultipleOptions<T>[];
	onChange: (value: T[]) => void;
	defaultValue?: T[];
	max?: number;
}

export function SelectMultipleField<T = any>(
	props: SelectMultipleFieldProps<T>
) {
	const {
		options,
		onChange,
		value,
		size,
		required,
		label,
		placeholder,
		InputLabelProps,
		helperText,
		defaultValue: initialValues,
		onFocus,
		onBlur,
		error,
		max,
		...rest
	} = props;

	const id = useState(() => `select-multiple-field-${uuid()}`)[0];
	const [values, setValues] = useControlledValue<T[]>(value, onChange);

	const handleOnChange = (
		event: SyntheticEvent,
		value: SelectMultipleOptions<T>[]
	) => {
		if (max && value.length > max) {
			return;
		}

		setValues(value.map(({ value }) => value));
	};

	const selectedValues = values
		.map(value => options.find(option => option.value === value))
		.filter(Boolean) as SelectMultipleOptions<T>[];

	const defaultValue = (initialValues
		?.map(value => options.find(option => option.value === value))
		.filter(Boolean) ?? []) as SelectMultipleOptions<T>[];

	return (
		<Autocomplete
			{...rest}
			multiple
			options={options}
			getOptionLabel={option => option.label}
			isOptionEqualToValue={(option, { value }) => option.value === value}
			filterSelectedOptions
			defaultValue={defaultValue}
			disableClearable
			renderInput={params => (
				<TextField
					{...params}
					error={error}
					InputLabelProps={InputLabelProps}
					onFocus={onFocus}
					onBlur={onBlur}
					helperText={helperText}
					label={label}
					placeholder={placeholder}
					size={size}
					required={required}
				/>
			)}
			renderTags={(value, getTagProps) => {
				return value.map((option, index) => {
					const { onDelete, ...tagProps } = getTagProps({ index });

					return (
						<Chip
							color='primary'
							label={option.label}
							size='small'
							{...tagProps}
							onDelete={e => {
								onFocus?.(e);
								onDelete(e);
							}}
							// eslint-disable-next-line react/no-array-index-key
							key={`${id}-${index}`}
						/>
					);
				});
			}}
			value={selectedValues}
			onChange={handleOnChange}
		/>
	);
}
