import { useMemo } from 'react';
import { useTranslation } from 'hooks';
import { useCurrentCommunity } from 'components';

export const useCommunityTeamData = () => {
	const { t } = useTranslation();
	const community = useCurrentCommunity();

	let contactGroupUuid = community?.contact_group_uuid ?? '';
	if (contactGroupUuid === 'admin' && community) {
		const contactGroup = community.groups.all.find(
			group => group.name === 'site_admin'
		);

		contactGroupUuid = contactGroup?.uuid ?? '';
	}

	const showOptions = useMemo(() => {
		const options = community?.groups.all.map(({ uuid, description }) => {
			const option = {
				value: uuid,
				label: description,
			};

			if (contactGroupUuid === option.value) {
				option.label = t('team.contact-group', {
					group: option.label,
				});
			}

			return option;
		});

		if (contactGroupUuid === 'owner') {
			options?.push({
				value: contactGroupUuid,
				label: t('team.contact-group', { group: t('team.owner') }),
			});
		}

		return options;
	}, [community, contactGroupUuid]);

	const sortByOptions = useMemo(
		() => [
			{
				value: 'first_name',
				label: t('team.sort-by-options.first-name'),
			},
			{
				value: 'last_name',
				label: t('team.sort-by-options.last-name'),
			},
			{
				value: 'oldest_member',
				label: t('team.sort-by-options.oldest-member'),
			},
			{
				value: 'recently_joined',
				label: t('team.sort-by-options.recently-joined'),
			},
			{
				value: 'most_tasks',
				label: t('team.sort-by-options.most-tasks-completed'),
			},
		],
		[]
	);

	const memberGroup = showOptions?.find(group => group.label === 'Members');

	const initialValues = useMemo(
		() => ({
			show: memberGroup?.value ?? '',
			sortBy: sortByOptions[0].value,
		}),
		[memberGroup]
	);

	return { initialValues, showOptions, sortByOptions };
};
