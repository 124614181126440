import { Box, Paper, Typography } from 'components/common';
import { MemberRecentActivity } from 'core/types';
import { useTranslation } from 'hooks';
import { DateTime } from 'luxon';
import { toRelative } from 'utils/dates';
import { recentActivityRowStyle } from './styles';

export type MemberRecentActivityCardProps = {
	activities: MemberRecentActivity[];
	timezone: string;
};

export const MemberRecentActivityCard = ({
	activities,
	timezone,
}: MemberRecentActivityCardProps) => {
	const { t } = useTranslation();

	return (
		<Box>
			<Typography variant='button2'>
				{t('member-profile.recent-activity')}
			</Typography>
			<Box component={Paper} elevation={0} p={3} mt={1} mb={3}>
				{activities.map(activity => {
					const fixedDate = DateTime.fromISO(activity.date).setZone(
						timezone
					);

					return (
						<Box
							key={`activity-${activity.action}-${activity.name}-${activity.community?.uuid}-${activity.date}`}
						>
							<Typography sx={recentActivityRowStyle}>
								{activity.action}
								&nbsp;&nbsp;&quot;
								<Typography component='span'>
									{activity.name}
								</Typography>
								&quot;
								<Typography component='span'>
									&nbsp;&nbsp;
									{toRelative(fixedDate) ||
										t('comment-widget.a-few-seconds-ago')}
								</Typography>
							</Typography>
						</Box>
					);
				})}
				{activities.length === 0 && (
					<Typography>{t('member-profile.no-activity')}</Typography>
				)}
			</Box>
		</Box>
	);
};
