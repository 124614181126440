import React from 'react';
import MuiLink, { LinkProps as MuiLinkProps } from '@mui/material/Link';
import { SxProps } from '@mui/system';

export type LinkProps = MuiLinkProps & {
	component?: React.ElementType;
};

// eslint-disable-next-line react/display-name
export const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(
	(props, ref) => {
		const { variant, role, sx, ...rest } = props;
		return (
			<MuiLink
				underline='hover'
				role={variant === 'button' ? 'button' : role}
				className={variant === 'button' ? 'link-button' : undefined}
				variant={variant}
				sx={
					[
						sx,
						{
							cursor: 'pointer',
							'> img': {
								mr: 2,
							},
						},
					] as SxProps
				}
				{...rest}
				ref={ref}
			/>
		);
	}
);
