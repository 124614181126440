import { Button, ButtonProps, Icon } from 'components/common';
import { defaultButtonStyle } from './styles';

export type FooterActionButtonProps = ButtonProps & {
	icon?: string | React.ReactNode;
};

export const FooterActionButton = (props: FooterActionButtonProps) => {
	const { icon, color, ...rest } = props;

	const iconComponent =
		typeof icon === 'string' ? (
			<Icon name={icon} fontSize='large' color={color} />
		) : (
			icon
		);

	return (
		<Button
			{...rest}
			color={color}
			variant='text'
			startIcon={iconComponent}
			size='small'
			sx={defaultButtonStyle}
		/>
	);
};
