import { Box, Typography, Paper, PageHeading } from 'components/common';
import {
	AnnouncementForm,
	AnnouncementFormValues,
} from 'components/announcements';
import {
	useParams,
	useRouter,
	useServices,
	useSubmitHandlerForPausedMutation,
	useTranslation,
} from 'hooks';
import { usePostAnnouncement } from 'core/uses-cases/announcement';
import { useAnnouncementFormData } from 'components/announcements/hooks';

export const CreateAnnouncement = () => {
	const { t } = useTranslation();
	const { communityId } = useParams() as {
		communityId: string;
	};

	const router = useRouter();

	const { notification } = useServices();

	const { mutateAsync, isPaused } = usePostAnnouncement();

	const handleOnSubmit =
		useSubmitHandlerForPausedMutation<AnnouncementFormValues>(
			isPaused,
			values => mutateAsync({ communityId, ...values }),
			{
				onSuccess: ({ values }) => {
					notification.show(
						'success',
						t(
							'announcements.announcement-was-successfully-created',
							{
								title: values.title,
							}
						)
					);

					router.navigate('community.announcements', {
						communityId,
					});
				},
			},
			[communityId]
		);

	const formProps = useAnnouncementFormData(communityId);

	return (
		<Box>
			<PageHeading title={t('announcement-form.create-announcement')}>
				<Typography variant='body2' mb={1}>
					{t('announcement-form.announcements-are-great')}
				</Typography>
				<Typography variant='body2' mb={1}>
					{t('announcement-form.announcement-description')}
				</Typography>
			</PageHeading>
			<Box component={Paper} elevation={0} mt={6} px={5} py={5}>
				<AnnouncementForm
					onSubmit={handleOnSubmit}
					submitText={t('announcement-form.create-announcement')}
					submittingText={t(
						'announcement-form.creating-announcement'
					)}
					{...formProps}
				/>
			</Box>
		</Box>
	);
};
