import { Box, Icon, useImagePicker } from 'components/common';
import { Community } from 'core/types';
import { useUpdateCommunityImage } from 'core/uses-cases/community';
import { useImageServer } from 'hooks';
import { useState } from 'react';
import { avatarStyle, communityAvatarContainerStyle } from './styles';

export type CommunityAvatarProps = {
	community: Community;
	allowEditing?: boolean;
};

export const CommunityAvatar = (props: CommunityAvatarProps) => {
	const { community, allowEditing = true } = props;
	const imageServerConfig = {
		w: 858,
		h: 644,
		fit: 'max',
		bg: 'fff',
	};
	const communityImage = useImageServer(community?.image, imageServerConfig);

	const [communityImageUrl, setCommunityImageUrl] = useState<string>(
		communityImage?.url || ''
	);
	const [isLoading, setIsLoading] = useState(false);

	const mutation = useUpdateCommunityImage();
	const { getRootProps, getInputProps, open } = useImagePicker({
		isMultiple: false,
		onChange: value => {
			setCommunityImageUrl(value[0].scaledUrl ?? '');
			setIsLoading(false);
			mutation.mutate({
				image: value[0],
				name: community.name,
				communityId: community.slug,
			});
		},
		value: communityImage ? [communityImage] : [],
		imageServerConfig,
		onOpen: () => setIsLoading(true),
		onCancel: () => setIsLoading(false),
	});

	if (!allowEditing) {
		return (
			<Box sx={communityAvatarContainerStyle}>
				<Box
					component='img'
					sx={avatarStyle}
					src={communityImage?.url}
				/>
			</Box>
		);
	}

	return (
		<Box
			sx={communityAvatarContainerStyle}
			{...getRootProps({ className: 'dropzone' })}
		>
			<input {...getInputProps()} />
			<Box>
				<Box
					data-test-id='community-home-image'
					component='img'
					sx={avatarStyle}
					src={communityImageUrl}
				/>
			</Box>
			{!isLoading && (
				<Box className='edit-button' role='button' onClick={open}>
					<Box>
						<Icon name='edit' />
					</Box>
				</Box>
			)}
			{isLoading && <Box className='overlay' />}
		</Box>
	);
};
