import { Box, Typography, Button, ButtonProps } from 'components/common';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { styled } from '@mui/system';
import { Theme } from 'theme';
import { navigationStyled } from './styles';

export type CalendarNavigationProps = {
	display: string;
	onNext: () => void;
	onPrev: () => void;
};

export const NavigationButton = styled(Button)<ButtonProps>(({ theme }) =>
	navigationStyled(theme as Theme)
);

export const CalendarNavigation = ({
	display,
	onNext,
	onPrev,
}: CalendarNavigationProps) => {
	return (
		<Box display='flex' alignItems='center' flex={1} py={2} px={2}>
			<NavigationButton size='small' variant='contained' onClick={onPrev}>
				<ArrowBackIosNewIcon />
			</NavigationButton>
			<Box marginX='auto' textAlign='center'>
				<Typography variant='button2' fontSize={20}>
					{display}
				</Typography>
			</Box>
			<NavigationButton size='small' variant='contained' onClick={onNext}>
				<ArrowForwardIosIcon />
			</NavigationButton>
		</Box>
	);
};
