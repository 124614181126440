import { Box, Typography } from 'components';
import { FC, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Trans } from 'react-i18next';
import { useFooterActionsRoot } from './footer-action-provider';

type FooterActionsProps = {
	selectedCount?: number;
	isLargeScreen: boolean;
};

export const FooterActions: FC<FooterActionsProps> = ({
	selectedCount = 0,
	children,
	isLargeScreen,
}) => {
	const rootElement = useFooterActionsRoot();

	useEffect(() => {
		if (selectedCount > 0) {
			rootElement?.classList.add('enabled');
		} else {
			rootElement?.classList.remove('enabled');
		}

		return () => {
			rootElement?.classList.remove('enabled');
		};
	}, [rootElement, selectedCount]);

	if (!rootElement) return null;

	if (selectedCount <= 0) {
		return ReactDOM.createPortal(
			<Box className='footer-actions-wrapper' />,
			rootElement
		);
	}

	return ReactDOM.createPortal(
		<Box
			className='footer-actions-wrapper'
			display='flex'
			alignItems='center'
			justifyContent='center'
			px={3}
		>
			{isLargeScreen && (
				<Box position='absolute' left={24}>
					<Typography variant='body1' color='text.secondary'>
						<Trans
							i18nKey='footer-actions.selected'
							count={selectedCount}
							components={{ bold: <strong /> }}
						/>
					</Typography>
				</Box>
			)}
			{children}
		</Box>,
		rootElement
	);
};
