import { SxProps } from '@mui/system';
import { Theme } from 'theme';

export const menuPaperStyle: SxProps<Theme> = theme => ({
	overflow: 'visible',
	filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
	mt: 2,
	ml: -3.5,
	[theme.breakpoints.down('sm')]: {
		position: 'absolute',
		top: '0 !important',
		bottom: '0 !important',
		left: '0 !important',
		right: '0 !important',
		ml: 0,
		mt: 0,
		maxHeight: 'unset',
		maxWidth: 'unset',
		overflow: 'auto',
	},
});

export const footerLinkStyle: SxProps<Theme> = (theme: Theme) => ({
	...theme.typography.button,
	padding: theme.spacing(1, 4),
	color: 'buttonPrimary.main',
	'> img': {
		marginRight: theme.spacing(1),
		maxWidth: 24,
		height: 24,
	},
	':hover': {
		backgroundColor: 'transparent',
		textDecoration: 'underline',
	},
});

export const itemStyle: SxProps<Theme> = (theme: Theme) => ({
	...theme.typography.button,
	borderWidth: 1,
	borderStyle: 'solid',
	borderColor: 'transparent',
	textTransform: 'capitalize',
	padding: theme.spacing(2),
	margin: theme.spacing(0, 2),
	':hover': {
		backgroundColor: 'transparent',
		borderColor: theme.primaryColors.primary1,
		borderStyle: 'dashed',
	},
});

export const pendingItemStyle: SxProps<Theme> = (theme: Theme) => ({
	...theme.typography.button,
	borderWidth: 1,
	borderStyle: 'solid',
	borderColor: 'transparent',
	textTransform: 'capitalize',
	padding: theme.spacing(2),
	margin: theme.spacing(0, 2),
});

export const dropDownTitleStyle: SxProps<Theme> = {
	px: 2,
	fontSize: 12,
	textTransform: 'capitalize',
};

export const brandTitleStyle: SxProps<Theme> = (theme: Theme) => ({
	padding: { xs: theme.spacing(1, 0), md: theme.spacing(1, 2) },
	borderRadius: theme.spacing(0.4),
	marginLeft: theme.spacing(1),
	minWidth: 0,

	'& .MuiTypography-root': {
		marginLeft: theme.spacing(0.6),
		marginRight: theme.spacing(2),
		fontSize: 20,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
});

export const menuLinkStyle: SxProps<Theme> = {
	py: 2,
	px: 1.5,
	display: 'block',
	color: 'common.white',
	fontSize: 14,
};

export const menuContainerStyle: SxProps<Theme> = {
	flexGrow: 1,
	display: { xs: 'flex', sm: 'none' },
	'& > button > span': {
		color: 'common.white',
	},
};

export const communityMenuWrapperStyle: SxProps<Theme> = theme => ({
	display: 'flex',
	backgroundColor: theme.secondaryColors.secondary3,

	'&.sticky': {
		position: 'sticky',
		top: 0,
		zIndex: 2,
		boxShadow: `0 2px 6px 0 ${theme.borderColors[0.1]}`,
	},

	'& .sticky-community-dropdown': {
		'& > button > span': {
			color: theme.palette.common.white,
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			display: 'block',
			maxWidth: { xs: 200, sm: 300, md: 150, lg: 400 },
		},
		'& > button > svg': {
			color: theme.palette.common.white,
		},
	},
});

export const arrowMenuIconStyle: SxProps<Theme> = {
	fontSize: 18,
	transform: 'rotate(-90deg)',
	'&.open': {
		transform: 'rotate(90deg)',
	},
};

export const currentCommunityContainer: SxProps = {
	backgroundColor: '#e6eee2',
};
