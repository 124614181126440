import { Draggable } from 'react-beautiful-dnd';

export type DraggableItemProps = {
	key: string;
	id: string;
	index: number;
	children: React.ReactNode;
};

export const DraggableItem = ({ id, index, children }: DraggableItemProps) => {
	return (
		<Draggable draggableId={id} index={index}>
			{provided => (
				<div
					ref={provided.innerRef}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
					style={{
						...provided.draggableProps.style,
					}}
				>
					{children}
				</div>
			)}
		</Draggable>
	);
};
