import { useCardMenu, CardMenuItem } from 'components/common';
import { useMemo } from 'react';
import { useTranslation } from 'hooks';
import { ActionObject } from 'core/types';

export const useFeedCardMenu = ({
	onEdit,
	onDelete,
}: ActionObject): React.ReactNode => {
	const { t } = useTranslation();

	const menuActions = useMemo(() => {
		if (!onDelete && !onEdit) {
			return [];
		}

		const menuActions: CardMenuItem[] = [];

		if (onEdit) {
			menuActions.push({
				label: t('feed-card-menu.edit'),
				icon: 'edit',
				onClick: onEdit,
			});
		}

		if (onDelete) {
			menuActions.push({
				label: t('feed-card-menu.delete'),
				icon: 'delete',
				onClick: onDelete,
				variant: 'error',
			});
		}

		return menuActions;
	}, [onEdit, onDelete]);

	return useCardMenu(menuActions);
};
