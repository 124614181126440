import { Box, CircularProgress, PageHeading, Paper } from 'components/common';
import {
	GroupPermissionsForm,
	GroupPermissionsFormSubmittedValues,
} from 'components/groups';
import { useGroupFormData } from 'components/groups/use-group-form-data';
import { useGroupNameTranslation } from 'components/groups/use-group-name-translation';
import { TeamHeader } from 'components/team';
import {
	useGetCommunityGroup,
	usePatchCommunityGroup,
} from 'core/uses-cases/group';
import {
	useParams,
	useRouter,
	useServices,
	useSubmitHandlerForPausedMutation,
	useTranslation,
} from 'hooks';
import { Navigate } from 'navigation';
import { useGetGroupPermissions } from './use-group-access';

export const EditGroupPermissions = () => {
	const { t } = useTranslation();
	const router = useRouter();
	const { communityId, groupId } = useParams() as {
		communityId: string;
		groupId: string;
	};
	const { notification } = useServices();
	const {
		data: group,
		isLoading,
		isSuccess,
	} = useGetCommunityGroup(communityId, groupId);
	const { initialValues } = useGroupFormData(communityId, group);
	const { canEditPermissions } = useGetGroupPermissions(group);
	const { mutateAsync, isPaused } = usePatchCommunityGroup();

	const handleOnSubmit =
		useSubmitHandlerForPausedMutation<GroupPermissionsFormSubmittedValues>(
			isPaused,
			({ permissions }) =>
				mutateAsync({
					communityId,
					groupId,
					type: 'permissions',
					permissions,
				}),
			{
				onSuccess: () => {
					notification.show(
						'success',
						t('group.group-was-successfully-updated', {
							name: group?.name,
						})
					);
					router.navigate('community.group-view', {
						communityId,
						groupId,
					});
				},
			},
			[communityId, groupId]
		);

	const title = useGroupNameTranslation(group);

	if (group && !canEditPermissions) {
		return (
			<Navigate
				to={router.getPathFor('community.group-view', {
					communityId,
					groupId,
				})}
			/>
		);
	}

	if (isLoading) {
		return (
			<Box display='flex' justifyContent='center' mt={4}>
				<CircularProgress message={t('common.loading')} />
			</Box>
		);
	}

	return (
		<>
			<TeamHeader title={t('group.edit-group')} />
			<Box component={Paper} elevation={0} mt={6} px={5} py={5}>
				{isSuccess && group && (
					<>
						<PageHeading title={title} />
						<GroupPermissionsForm
							onSubmit={handleOnSubmit}
							initialValues={initialValues}
							submitText={t('group-form.update-group')}
							submittingText={t('group-form.updating-group')}
						/>
					</>
				)}
			</Box>
		</>
	);
};
