import MuiBackdrop, { BackdropProps } from '@mui/material/Backdrop';
import { Box, CircularProgress } from 'components';
import { FC } from 'react';
import ReactDOM from 'react-dom';

export const Backdrop = ({ open, ...rest }: BackdropProps) => {
	return (
		<BackdropPortal>
			<MuiBackdrop open={open} sx={{ zIndex: 3 }} {...rest}>
				<Box bgcolor='white' p={1} borderRadius='50%'>
					<CircularProgress color='inherit' />
				</Box>
			</MuiBackdrop>
		</BackdropPortal>
	);
};

const backdropRoot = document.getElementById('backdrop-root') as Element;

export const BackdropPortal: FC = props => {
	return ReactDOM.createPortal(props.children, backdropRoot);
};
