import { ThemeOptions } from '@mui/material/styles';

export const typography: ThemeOptions['typography'] = {
	fontFamily: 'Proxima Nova',
	fontWeightBold: 700,
	fontWeightLight: 300,
	fontWeightMedium: 400,
	fontWeightRegular: 500,
	htmlFontSize: 16,
	allVariants: {
		fontFamily: 'Proxima Nova',
		fontSize: 16,
	},
	subtitle1: undefined,
	subtitle2: undefined,
	overline: undefined,
	button: {
		fontSize: '16px',
		lineHeight: '16px',
		fontStyle: 'normal',
		fontWeight: '600',
		letterSpacing: '0px',
	},
	h1: {
		fontFamily: 'Playfair Display',
		fontStyle: 'normal',
		fontWeight: '900',
		fontSize: '90px',
		lineHeight: '98px',
		letterSpacing: '0px',
	},
	h2: {
		fontFamily: 'Playfair Display',
		fontWeight: 'bold',
		fontSize: '64px',
		lineHeight: '74px',
		letterSpacing: '0px',
	},
	h3: {
		fontFamily: 'Playfair Display',
		fontWeight: 'bold',
		fontSize: '48px',
		lineHeight: '56px',
		letterSpacing: '0px',
	},
	h4: {
		fontFamily: 'Playfair Display',
		fontWeight: 'bold',
		fontSize: '32px',
		lineHeight: '40px',
		letterSpacing: '0px',
	},
	h5: {
		fontFamily: 'Playfair Display',
		fontWeight: 'bold',
		fontSize: '20px',
		lineHeight: '28px',
		letterSpacing: '0px',
	},
	h6: {
		fontFamily: 'Playfair Display',
		fontWeight: 'bold',
		fontSize: '20px',
		lineHeight: '28px',
		letterSpacing: '0px',
	},
	body3: {
		fontSize: '32px',
		lineHeight: '42px',
		letterSpacing: '0px',
	},
	body2: {
		fontSize: '16px',
		lineHeight: '24px',
		letterSpacing: '0px',
	},
	body1: {
		fontSize: '14px',
		lineHeight: '20px',
		letterSpacing: '0px',
	},
	quote1: {
		fontSize: '32px',
		lineHeight: '40px',
		letterSpacing: '0px',
		fontStyle: 'italic',
	},
	quote2: {
		fontSize: '18px',
		lineHeight: '32px',
		letterSpacing: '0px',
		fontStyle: 'italic',
	},
	button1: {
		fontSize: '18px',
		lineHeight: '32px',
		fontWeight: 600,
	},
	button2: {
		fontSize: '16px',
		lineHeight: '28px',
		fontWeight: 600,
	},
	button3: {
		fontSize: '14px',
		lineHeight: '22px',
		fontWeight: 600,
	},
	navitem: {
		fontSize: '14px',
		lineHeight: '32px',
		fontWeight: 600,
	},
	caption: {
		fontSize: '12px',
		lineHeight: '18px',
	},
};
