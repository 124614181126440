import { useState } from 'react';

type ItemKeys = {
	uuid: string;
	method?: string;
};

export const useCheckboxMultiSelectionHandler = <T extends ItemKeys>(
	data: T[]
) => {
	const [selected, setSelected] = useState(() => new Set<string>());

	const totalData = data.length;
	const hasData = totalData > 0;

	const isIndeterminate =
		hasData && selected.size > 0 && selected.size !== totalData;

	const isAllSelected = hasData && selected.size === totalData;

	const select = (id: string) => {
		setSelected(currentSelected => {
			const nextSelected = new Set(currentSelected);

			if (nextSelected.has(id)) {
				nextSelected.delete(id);
			} else {
				nextSelected.add(id);
			}

			return nextSelected;
		});
	};

	const selectAll = () => {
		if (isAllSelected) {
			setSelected(() => new Set([]));
		} else {
			setSelected(() => new Set([...data.map(({ uuid }) => uuid)]));
		}
	};

	const clearAll = () => {
		setSelected(() => new Set([]));
	};

	const isSelected = (id: string) => selected.has(id);

	return {
		select,
		selectAll,
		isIndeterminate,
		isAllSelected,
		clearAll,
		isSelected,
		hasData,
		selected: Array.from(selected),
		selectedSize: selected.size,
		showResend:
			Array.from(selected)
				.map(id => data.find(item => item.uuid === id))
				.filter(item => item?.method === 'email').length > 0,
	};
};
