import { ENGLISH_CODE } from 'core/constants';
import { useServices } from 'core/services';
import { postAccessToken } from 'core/utils/mutation-key-factory';
import { useMutation } from 'react-query';

type LoginMutationInput = {
	username: string;
	password: string;
};

export const useAccessToken = () => {
	const { api } = useServices();

	return useMutation(
		({ username, password }: LoginMutationInput) => {
			return api
				.postAccessToken(username, password, ENGLISH_CODE)
				.then(response => response.data);
		},
		{
			mutationKey: postAccessToken,
		}
	);
};
