import { SxProps } from '@mui/system';

export const imageAreaStyle: SxProps = {
	marginTop: 5,
};

export const imageContainerStyle: SxProps = {
	position: 'relative',
	'> img': { maxWidth: '100%' },
	'> div': {
		display: 'none',
		position: 'absolute',
		top: 10,
		right: 10,
	},
	'&:hover': {
		'> div': { display: 'block' },
	},
};

export const deleteButtonContent: SxProps = {
	bgcolor: 'white',
	color: 'common.black',
	borderRadius: '50%',
};

export const dropzoneAreaStyle: SxProps = {
	height: '100%',
};
