import { Box, Paper, PageHeading } from 'components/common';
import {
	EventForm,
	EventFormSubmittedValues,
	useEventFormData,
} from 'components/events';
import { usePostEvents } from 'core/uses-cases/calendar';
import {
	useParams,
	useRouter,
	useServices,
	useSubmitHandlerForPausedMutation,
	useTranslation,
} from 'hooks';

export const CreateTask = () => {
	const { t } = useTranslation();
	const { communityId, calendarId } = useParams() as {
		communityId: string;
		calendarId: string;
	};
	const router = useRouter();
	const { notification } = useServices();

	const { mutateAsync, isPaused } = usePostEvents();

	const handleOnSubmit =
		useSubmitHandlerForPausedMutation<EventFormSubmittedValues>(
			isPaused,
			values => mutateAsync({ communityId, calendarId, ...values }),
			{
				onSuccess: () => {
					notification.show(
						'success',
						t('events.events-were-successfully-created')
					);

					router.navigate('community.calendar', {
						communityId,
						calendarId,
					});
				},
			},
			[communityId]
		);

	const formProps = useEventFormData();

	return (
		<Box>
			<PageHeading title={t('calendar.create-task')} />
			<Box component={Paper} elevation={0} mt={6} px={5} py={5}>
				<EventForm
					onSubmit={handleOnSubmit}
					submitText={t('event-form.post-event')}
					{...formProps}
				/>
			</Box>
		</Box>
	);
};
