import { Box, CircularProgress } from 'components/common';
import { useGetCalendar } from 'core/uses-cases/calendar';
import { useGetPublicCommunity } from 'core/uses-cases/community';
import { useRouter } from 'hooks';
import { Navigate } from 'navigation';
import { CustomAliasNavigationProps } from 'navigation/alias-redirect';

const useGetCalendarInformation = (
	calendarId: string,
	onSuccess: (communityId: string) => React.ReactElement
) => {
	const router = useRouter();
	const { data, isLoading, isError } = useGetCalendar(calendarId);

	if (isLoading) {
		return (
			<Box display='flex' justifyContent='center' mt={4}>
				<CircularProgress />
			</Box>
		);
	}

	if (isError) {
		return <Navigate to={router.getPathFor('root')} replace />;
	}

	return onSuccess(data?.community?.slug as string);
};

export const LegacyTaskAliasBuilder = ({
	targetRoute,
	params,
}: CustomAliasNavigationProps) => {
	const router = useRouter();
	const { calendarId, eventId, auth } = params;

	return useGetCalendarInformation(calendarId, communityId => (
		<Navigate
			to={router.getPathFor(
				targetRoute,
				{
					calendarId,
					eventId,
					communityId,
				},
				{
					auth,
					communityId,
				}
			)}
			replace
		/>
	));
};

export const LegacyTaskCalendarAliasBuilder = ({
	targetRoute,
	params,
}: CustomAliasNavigationProps) => {
	const router = useRouter();
	const { communityId, auth } = params;
	const { data, isError, isLoading } = useGetPublicCommunity(communityId);

	if (isLoading) {
		return (
			<Box display='flex' justifyContent='center' mt={4}>
				<CircularProgress />
			</Box>
		);
	}

	if (isError) {
		return <Navigate to={router.getPathFor('root')} replace />;
	}

	return (
		<Navigate
			to={router.getPathFor(
				targetRoute,
				{
					...params,
					calendarId: data?.calendar?.uuid,
				},
				{
					auth,
					communityId: data?.slug,
				}
			)}
			replace
		/>
	);
};
