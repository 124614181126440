import { useMutation, useQueryClient } from 'react-query';
import { Nullable, Community, Image } from 'core/types';
import { postCommunityMutationKey } from 'core/utils/mutation-key-factory';
import { useServices } from 'hooks';
import { communityKeys } from 'core/utils/query-key-factory';
import { BaseHttpError } from '../../services/api/errors';
import { CommunityInput } from '../../services/api/api-client/types';

export interface PostCommunityMutationInput
	extends Omit<CommunityInput, 'image'> {
	image?: Nullable<Image>;
}

export const usePostCommunity = () => {
	const { api, uploader } = useServices();

	const queryClient = useQueryClient();

	const mutation = useMutation<
		Community,
		BaseHttpError,
		PostCommunityMutationInput
	>(
		async ({ image, ...input }) => {
			const remoteImage = await uploader.uploadImage(image);

			return api
				.postCommunity({
					...input,
					image: remoteImage?.uuid ?? null,
				})
				.then(response => response.data);
		},
		{
			mutationKey: postCommunityMutationKey,
			onSuccess: () => {
				return queryClient.invalidateQueries(communityKeys.all());
			},
		}
	);

	return {
		post: mutation.mutate,
		postAsync: mutation.mutateAsync,
		...mutation,
	};
};
