import { Box, ButtonRouterLink, PageHeading } from 'components/common';
import {
	Calendar as FullCalendar,
	CalendarLoadEventsFilter,
	transformEventToCalendarEvent,
} from 'components/calendar';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { useParams, useRouter, useTranslation, useVerifyAccess } from 'hooks';
import { useGetEvents } from 'core/uses-cases/calendar';
import { useCommunityMembers } from 'core/uses-cases/member';
import { useCallback, useMemo, useState } from 'react';
import { CalendarEvent } from 'components/calendar/types';
import config from 'config';
import { transformServerToClientLocal } from 'utils/dates';
import { useCurrentIdentity } from 'core/uses-cases/authentication';

type CalendarFilterState = Partial<CalendarLoadEventsFilter>;

export const Calendar = () => {
	const [filter, setFilter] = useState<CalendarFilterState>(() => {
		return { startDate: undefined, endDate: undefined };
	});

	const { t } = useTranslation();
	const router = useRouter();
	const currentIdentity = useCurrentIdentity();
	const verify = useVerifyAccess();
	const { communityId, calendarId } = useParams() as {
		communityId: string;
		calendarId: string;
	};

	const members = useCommunityMembers(communityId);

	const { data, status } = useGetEvents(calendarId, {
		startDate: filter.startDate?.toJSDate() ?? null,
		endDate: filter.endDate?.toJSDate() ?? null,
	});

	const handleOnLoadEvents = useCallback(
		(options: CalendarLoadEventsFilter) => {
			setFilter({ ...options });
		},
		[]
	);

	const onEventClick = (event: CalendarEvent) => {
		router.navigate('community.calendar.event.view', {
			communityId,
			calendarId,
			eventId: event.uuid,
		});
	};

	const transformedEvents = useMemo(
		() => (data || []).map(transformEventToCalendarEvent),
		[data]
	);

	const canCreateTask = verify('create', 'task');

	if (!communityId || !currentIdentity) return null;

	const locale = transformServerToClientLocal(currentIdentity.language);

	return (
		<Box>
			<PageHeading
				rightSection={
					canCreateTask && (
						<ButtonRouterLink
							to={router.getPathFor('calendar.create', {
								communityId,
								calendarId,
							})}
							startIcon={
								<AddCircleOutlineOutlinedIcon fontSize='large' />
							}
							size='small'
						>
							{t('calendar.create-task')}
						</ButtonRouterLink>
						// eslint-disable-next-line react/jsx-indent
					)
				}
			/>
			<FullCalendar
				timezone={currentIdentity?.timezone || config.defaultTimezone}
				locale={locale}
				members={members.map(member => member.identity)}
				events={transformedEvents}
				loadEventsStatus={status}
				loadEvents={handleOnLoadEvents}
				onEventClick={onEventClick}
				exportLink={router.getPathFor('community.export-calendar', {
					communityId,
					calendarId,
				})}
			/>
		</Box>
	);
};
