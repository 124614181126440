import { Event } from 'core/types';
import { useEventMetadata, useTranslation } from 'hooks';
import { TFunction } from 'i18next';
import { DateTime } from 'luxon';
import { useCallback, useMemo, useRef } from 'react';
import { sleep } from 'utils/delay';
import { luxonDateTime } from 'utils/validation';
import * as Yup from 'yup';
import arrayMutators from 'final-form-arrays';
import config from 'config';
import {
	Box,
	LoadingButton,
	Form,
	Field,
	FormOnSubmitReturn,
	FormStack,
	TimeZoneField,
} from '../../common';
import { DateSlotsField } from './date-slots-field';

export type DuplicateEventFormSubmittedValues = {
	dates: string[];
	timezone: string;
};

export type DuplicateEventFormInitialValues =
	Partial<DuplicateEventFormSubmittedValues>;

export type DuplicateEventFormProps = {
	onSubmit?: (
		values: DuplicateEventFormSubmittedValues
	) => FormOnSubmitReturn<Event>;
	event: Event;
	submitText?: string;
	submittingText?: string;
	initialValues?: DuplicateEventFormInitialValues;
};

export const DuplicateEventForm = (props: DuplicateEventFormProps) => {
	const { onSubmit, event, submitText, submittingText, initialValues } =
		props;

	const { t } = useTranslation();

	const { isAllDay } = useEventMetadata(event);

	const cachedInitialValues = useRef({
		dates: [
			DateTime.local({
				zone: initialValues?.timezone ?? config.defaultTimezone,
			}),
		],
		timezone: config.defaultTimezone,
		...initialValues,
	}).current;

	const handleOnSubmit = useCallback(
		async ({ dates, ...restValues }) => {
			await sleep(1);

			const values: DuplicateEventFormSubmittedValues = {
				...restValues,
				dates: dates.map((date: DateTime) =>
					isAllDay ? date.toISODate() : date.toISO()
				),
			};

			const result = onSubmit?.(values);

			return Promise.resolve(result).catch(error => error);
		},
		[isAllDay, onSubmit]
	);

	const validationSchema = useMemo(() => createValidationSchema(t), []);

	return (
		<Form
			onSubmit={handleOnSubmit}
			validationSchema={validationSchema}
			subscription={{ submitting: true }}
			initialValues={cachedInitialValues}
			mutators={{
				...arrayMutators,
			}}
		>
			{({ handleSubmit, submitting }) => (
				<form onSubmit={handleSubmit} noValidate>
					<FormStack responsive={false} spacing={3}>
						<Box>
							<Field
								name='timezone'
								component={TimeZoneField}
								label={t('duplicate-event-form.timezone')}
								required
								update
							/>
						</Box>
						<Box>
							<DateSlotsField
								label={t('duplicate-event-form.dates')}
								name='dates'
								useTimes={!isAllDay}
								timezone={
									initialValues?.timezone ??
									config.defaultTimezone
								}
							/>
						</Box>
						<Box>
							<LoadingButton
								loading={submitting}
								loadingIndicator={
									submittingText || t('form.submitting')
								}
								size='small'
								type='submit'
							>
								{submitText || t('form.submit')}
							</LoadingButton>
						</Box>
					</FormStack>
				</form>
			)}
		</Form>
	);
};

function createValidationSchema(t: TFunction) {
	const schema = Yup.object().shape({
		dates: Yup.array()
			.of(luxonDateTime(t('duplicate-event-form.invalid-date')))
			.required()
			.min(1),
	});

	return schema;
}
