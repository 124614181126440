import { Box, CircularProgress } from 'components/common';
import { InvitationLayout } from 'components/community/invitation';
import { useParams, useTranslation } from 'hooks';
import { Navigate } from 'navigation';
import { Outlet } from 'react-router';
import { useInvitationFlow } from './use-invitation-flow';

export const InvitationLayoutGuard = () => {
	const { t } = useTranslation();
	const { communityId, tokenId } = useParams() as {
		communityId: string;
		tokenId: string;
	};
	const { community, navigate, isLoading } = useInvitationFlow(
		communityId,
		tokenId,
		true
	);

	if (!community || isLoading) {
		return (
			<Box display='flex' justifyContent='center' mt={4}>
				<CircularProgress message={t('common.loading')} />
			</Box>
		);
	}

	if (navigate) {
		return <Navigate {...navigate} replace />;
	}

	return (
		<InvitationLayout community={community}>
			<Outlet />
		</InvitationLayout>
	);
};
