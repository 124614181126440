import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { ResetAuthOptions, useServices } from 'core/services';
import { authenticationKeys } from 'core/utils/query-key-factory';

export const useLogout = () => {
	const { authentication, errorLogger } = useServices();

	const queryClient = useQueryClient();

	const doLogout = useCallback((options?: ResetAuthOptions) => {
		authentication.reset(options);

		queryClient.removeQueries({
			predicate: query => query.queryKey !== authenticationKeys.identity,
		});

		queryClient.setQueryData(authenticationKeys.identity, null);

		errorLogger.setUser(null);
	}, []);

	return { doLogout };
};
