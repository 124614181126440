const promisify = <T>(fn: () => T): Promise<T> => {
	return new Promise((resolve, reject) => {
		try {
			const value = fn.call(null);
			resolve(value);
		} catch (e) {
			reject(e);
		}
	});
};

export async function getItem(key: string): Promise<string | null> {
	return promisify(() => window.localStorage.getItem(key));
}

export async function setItem(key: string, value: string): Promise<void> {
	try {
		await promisify(() => window.localStorage.setItem(key, value));
	} catch {
		/* empty */
	}
}

export async function removeItem(key: string): Promise<void> {
	try {
		await promisify(() => window.localStorage.removeItem(key));
	} catch {
		/* empty */
	}
}

export async function clear(): Promise<void> {
	try {
		await promisify(() => window.localStorage.clear());
	} catch {
		/* empty */
	}
}

export async function load(key: string): Promise<string | null> {
	try {
		const value = await promisify(() => window.localStorage.getItem(key));
		return value ? JSON.parse(value) : null;
	} catch {
		return null;
	}
}

export async function save(key: string, value: unknown): Promise<boolean> {
	try {
		await promisify(() =>
			window.localStorage.setItem(key, JSON.stringify(value))
		);
		return true;
	} catch {
		return false;
	}
}

export async function getAllKeys() {
	try {
		const value = await promisify(() => Object.keys(window.localStorage));
		return value;
	} catch {
		return [];
	}
}

const storage = {
	getItem,
	setItem,
	removeItem,
	getAllKeys,
	clear,
	load,
	save,
};

export { storage };
