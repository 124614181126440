import MuiIcon, { IconProps as MuiIconProps } from '@mui/material/Icon';

interface IconProps extends MuiIconProps {
	name: string;
}

export const Icon = (props: IconProps) => {
	const { name, ...rest } = props;

	return <MuiIcon {...rest}>{name}</MuiIcon>;
};
