import { styled } from '@mui/system';
import { DateTime } from 'luxon';
import { Button, Box, BoxProps } from 'components/common';
import { Theme } from 'theme';
import { useTranslation } from 'react-i18next';
import { LinearProgress } from '@mui/material';
import { useCalendar } from '../../context';
import { CalendarViews } from '../views-tabs';
import { headerContainerStyled, spinnerStyle } from './styles';

const Container = styled(Box)<BoxProps>(({ theme }) =>
	headerContainerStyled(theme as Theme)
);

export const CalendarHeader = () => {
	const { t } = useTranslation();
	const { setDisplayed, loadEventsStatus, timezone } = useCalendar();

	const handleOnPressToday = () => {
		setDisplayed(DateTime.local({ zone: timezone }));
	};

	return (
		<Box position='relative'>
			<Container component='header'>
				<Button
					size='small'
					variant='outlined'
					onClick={handleOnPressToday}
				>
					{t('calendar-widget.today')}
				</Button>
				<Box flexGrow={1} />
				<CalendarViews />
			</Container>
			{loadEventsStatus === 'loading' && (
				<Box sx={spinnerStyle}>
					<LinearProgress />
				</Box>
			)}
		</Box>
	);
};
