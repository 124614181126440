import { Box, Link, Typography } from 'components/common';
import {
	InvitationCreateAccountForm,
	InvitationCreateAccountFormSubmittedValues,
} from 'components/community/invitation';
import config from 'config';
import { DUPLICATED_IDENTITY, HttpClientError } from 'core/services';
import { useLogin } from 'core/uses-cases/authentication';
import { usePostIdentity } from 'core/uses-cases/identity';
import { useRespondInvitation } from 'core/uses-cases/invitation';
import {
	useParams,
	useTranslation,
	useRouter,
	useLocation,
	useServices,
} from 'hooks';
import { Navigate } from 'navigation';
import { Trans } from 'react-i18next';
import { useTransientStore } from 'store';
import { useInvitationFlow } from './use-invitation-flow';
import { useConstantValue } from '../../../hooks/use-constant-value';

export const InvitationCreateAccount = () => {
	const { t } = useTranslation();
	const router = useRouter();
	const { notification } = useServices();
	const { communityId, tokenId } = useParams() as {
		communityId: string;
		tokenId: string;
	};
	const store = useTransientStore();
	const enabledFlow = useConstantValue(!store.auth.isLoggedIn); // Only allow if not logged in

	const { token } = useInvitationFlow(communityId, tokenId, enabledFlow);
	const location = useLocation() as {
		state?: { email: string; hasAccount: boolean };
	};
	const { email, hasAccount } = location.state ?? {};
	const identityMutation = usePostIdentity();
	const loginMutation = useLogin();
	const acceptMutation = useRespondInvitation();

	const onSubmit = async (
		values: InvitationCreateAccountFormSubmittedValues
	) => {
		try {
			if (!token) return;

			await identityMutation.mutateAsync(values);

			const { password, email } = values;

			await loginMutation.doLoginAsync({
				username: email,
				password,
			});

			await acceptMutation.mutateAsync({
				communityId,
				invitationId: token?.invitation_id,
				action: 'accept',
			});

			notification.show(
				'success',
				t('invitation.you-have-accepted-the-invitation-request')
			);

			router.navigate(
				'community.home',
				{ communityId },
				{},
				{ replace: true }
			);
		} catch (error) {
			if (
				error instanceof HttpClientError &&
				error.errorCode === DUPLICATED_IDENTITY
			) {
				throw { email: t('form.email-already-exists') };
			}
		}
	};

	const onBack = () => {
		router.navigate('community.invitation.select-email', {
			communityId,
			tokenId,
		});
	};

	if (!enabledFlow) {
		return (
			<Navigate
				to={router.getPathFor('community.invitation.join', {
					communityId,
					tokenId,
				})}
			/>
		);
	}

	if (!email) {
		return (
			<Navigate
				to={router.getPathFor('community.home', { communityId })}
				alert={{
					level: 'error',
					message: t('invitation.missing-email-create-account'),
				}}
			/>
		);
	} else if (hasAccount) {
		return (
			<Navigate
				to={router.getPathFor('community.invitation.select-email', {
					communityId,
					tokenId,
				})}
				alert={{
					level: 'error',
					message: t('invitation.invalid-account'),
				}}
			/>
		);
	}

	const initialValues = { email };

	return (
		<Box>
			<Box mb={4}>
				<Typography variant='caption'>
					{t('invitation.create-account-first')}
				</Typography>
			</Box>
			<InvitationCreateAccountForm
				initialValues={initialValues}
				onSubmit={onSubmit}
				onBack={onBack}
				submitText={t('invitation.accept-invitation')}
				submittingText={t('invitation.accepting-invitation')}
				acceptingText={
					<Trans
						i18nKey='invitation.accept-message'
						components={{
							term: (
								<Link
									href={`${config?.urls?.wordPress}/terms`}
									target='_blank'
								/>
							),
						}}
					/>
				}
			/>
		</Box>
	);
};
