import { ListSubheader } from '@mui/material';
import MenuItem, { MenuItemProps } from '@mui/material/MenuItem';
import React from 'react';

export interface SelectItemProps extends MenuItemProps {
	multiple?: boolean;
}

export type OptionType = {
	value: string;
	label: string;
};

export const SelectItem = React.forwardRef<HTMLLIElement, SelectItemProps>(
	function SelectItem(props, ref) {
		const { multiple, ...rest } = props;

		return <MenuItem {...rest} ref={ref} />;
	}
);

export const SelectItemGroup = ListSubheader;
