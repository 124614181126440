export const UNKNOWN_ERROR = 1;
export const COLLECTION_NOT_FOUND = 2;
export const RESOURCE_NOT_FOUND = 3;
export const AUTH_INVALID = 4;
export const MISSING_PARAMETER = 5;
export const AUTHORIZATION_INVALID = 6;
export const TOKEN_INVALID = 7;
export const TOKEN_EXPIRED = 8;
export const INVALID_PARAMETER = 9;
export const INVALID_USERNAME = 10;
export const ALREADY_MEMBER = 11;
export const PENDING_MEMBER_REQUEST = 12;
export const DUPLICATED_LOGIN = 13;
export const EMAIL_NOT_VERIFIED = 14;
export const DUPLICATED_IDENTITY = 15;
export const ALREADY_CREATED_WELL_WISH = 17;
export const ALBUM_NOT_EMPTY = 18;
export const NOT_OWNER = 19;
export const AUTHORIZATION_REQUIRED = 20;
export const ALREADY_VOLUNTEER = 21;
export const NO_VOLUNTEER_NEEDED = 22;
export const ALREADY_EXISTING_SLUG = 23;
export const CANT_REMOVE_COMMUNITY_OWNER = 25;
export const DUPLICATED_GROUP = 26;
export const WRONG_VERSION = 27;
export const MAINTENANCE = 28;
export const RECAPTCHA = 29;
export const MUST_WAIT = 30;
export const DECRYPTION_ERROR = 31;
export const REINVITE_FAILED = 32;
export const SES_ERROR = 33;
export const INVALID_FILE_TYPE = 34;
export const INVALID_URI = 35;
