import {
	FormControlProps,
	InputLabelProps,
	FormGroupProps,
	FormControl,
	InputLabel,
	FormHelperText,
} from '@mui/material';
import { Box, Typography } from 'components/common';

interface PlainTextFieldProps extends Omit<FormControlProps, 'onChange'> {
	value: string;
	label?: string;
	disabled?: boolean;
	LabelProps?: InputLabelProps;
	FormGroupProps?: FormGroupProps;
	helperText?: string;
}

export const PlainTextField = (props: PlainTextFieldProps) => {
	const { label, LabelProps, helperText, value = '', ...rest } = props;

	return (
		<FormControl {...rest}>
			{label && <InputLabel {...LabelProps}>{label}</InputLabel>}
			<Box>
				<Typography variant='body2'>{value}</Typography>
			</Box>
			{helperText && <FormHelperText>{helperText}</FormHelperText>}
		</FormControl>
	);
};
