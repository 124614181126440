import {
	Grid,
	Typography,
	RouterLink,
	Box,
	PageHeading,
} from 'components/common';
import { useShowGlobalPermission } from 'components/permissions/use-show-global-permission';
import {
	useCurrentCommunity,
	useRouter,
	useTranslation,
	useVerifyAccess,
} from 'hooks';
import { linksStyles, descriptionStyles } from './styles';

type TeamHeaderProps = {
	title?: string;
	description?: string | React.ReactElement;
	button?: React.ReactElement | null;
};

export const TeamHeader = ({ title, description, button }: TeamHeaderProps) => {
	const { t } = useTranslation();
	const router = useRouter();
	const community = useCurrentCommunity();
	const communityId = community?.slug ?? '';
	const verify = useVerifyAccess();
	const canViewPendingRequests = verify('approve', 'member');
	const canViewInvites = verify('create', 'member');

	const renderPermissionLink = useShowGlobalPermission();

	return (
		<Box mb={2}>
			{title || button ? (
				<PageHeading title={title} rightSection={button} />
			) : null}
			<Typography sx={descriptionStyles}>{description}</Typography>
			<Grid container mt={1} sx={linksStyles}>
				<RouterLink
					to={router.getPathFor('community.team', { communityId })}
				>
					<Typography variant='navitem' mr={2}>
						{t('team.total-members', {
							count: community?.team_stats.total ?? 0,
						})}
					</Typography>
				</RouterLink>
				{canViewPendingRequests && (
					<RouterLink
						to={router.getPathFor('community.pending-requests', {
							communityId,
						})}
					>
						<Typography variant='navitem' mr={2}>
							{t('team.pending-requests', {
								count: community?.team_stats.requests ?? 0,
							})}
						</Typography>
					</RouterLink>
				)}
				{canViewInvites && (
					<>
						<RouterLink
							to={router.getPathFor('community.pending-invites', {
								communityId,
							})}
						>
							<Typography variant='navitem' mr={2}>
								{t('team.pending-invites', {
									count: community?.team_stats.invites ?? 0,
								})}
							</Typography>
						</RouterLink>
						<RouterLink
							to={router.getPathFor('community.failed-invites', {
								communityId,
							})}
						>
							<Typography variant='navitem' mr={2}>
								{t('team.failed-invites', {
									count: community?.team_stats.failed ?? 0,
								})}
							</Typography>
						</RouterLink>
						<RouterLink
							to={router.getPathFor(
								'community.declined-invites',
								{
									communityId,
								}
							)}
						>
							<Typography variant='navitem' mr={2}>
								{t('team.declined-invites', {
									count: community?.team_stats.declined ?? 0,
								})}
							</Typography>
						</RouterLink>
					</>
				)}
				<RouterLink
					to={router.getPathFor('community.groups', { communityId })}
				>
					<Typography variant='navitem' mr={2}>
						{t('team.groups')}
					</Typography>
				</RouterLink>
				{renderPermissionLink && (
					<RouterLink
						to={router.getPathFor('community.permissions', {
							communityId,
						})}
					>
						<Typography variant='navitem'>
							{t('team.permissions')}
						</Typography>
					</RouterLink>
				)}
			</Grid>
		</Box>
	);
};
