import { Box, Typography, ButtonRouterLink, Button } from 'components/common';
import { AlbumList } from 'components/albums/list';
import { useRouter } from 'hooks';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { useTranslation } from 'react-i18next';
import { useState, useMemo, useEffect } from 'react';
import { DraggableAlbumListContainer } from 'components/albums';
import { usePatchAlbumOrder } from 'core/uses-cases/albums/use-patch-album-order';
import { Album } from 'core/types';

export const AlbumListContainer = ({
	communityId,
	albums,
	canCreateAlbum,
	canArrange,
}: {
	communityId: string;
	albums: Album[];
	canCreateAlbum: boolean;
	canArrange: boolean;
}) => {
	const { t } = useTranslation();
	const router = useRouter();
	const saveMutation = usePatchAlbumOrder();
	const [isDragging, setIsDragging] = useState<boolean>(false);
	const [localAlbums, setLocalAlbums] = useState(albums);

	useEffect(() => {
		if (albums.length > 0) {
			setLocalAlbums(albums);
		}
	}, [albums]);

	const handleArrangeClick = () => {
		if (isDragging) {
			setIsDragging(false);

			saveMutation.mutateAsync({
				communityId,
				newOrder: localAlbums,
			});
		} else {
			setIsDragging(true);
		}
	};

	const visibleAlbums = useMemo(
		() => localAlbums.filter(album => album.type === 'user'),
		[localAlbums]
	);

	const showOrderButton = useMemo(
		() => visibleAlbums.length > 1 && canArrange,
		[localAlbums, canArrange]
	);

	return (
		<>
			<Typography mb={2}>{t('photos.albums')}</Typography>
			{!isDragging && (
				<AlbumList communityId={communityId} albums={visibleAlbums} />
			)}
			{isDragging && (
				<DraggableAlbumListContainer
					albums={localAlbums}
					setAlbums={setLocalAlbums}
				/>
			)}
			<Box
				display='flex'
				flexDirection='column'
				flex={1}
				justifyContent='center'
			>
				{canCreateAlbum && (
					<ButtonRouterLink
						to={router.getPathFor('album.create', {
							communityId,
						})}
						startIcon={
							<AddCircleOutlineOutlinedIcon fontSize='large' />
						}
						size='small'
					>
						{t('photos.create-album')}
					</ButtonRouterLink>
				)}
				{showOrderButton && (
					<Button
						sx={{ marginTop: 1 }}
						size='small'
						variant='outlined'
						onClick={handleArrangeClick}
					>
						{isDragging
							? t('common.done')
							: t('photos.order-albums')}
					</Button>
				)}
			</Box>
		</>
	);
};
