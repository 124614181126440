import { useCallback } from 'react';
import { Box, Heading, CircularProgress } from 'components/common';
import { useParams, useTranslation, useRouter, useErrorHandler } from 'hooks';
import { NoteCard, useDeleteNoteHandler } from 'components/notes';
import { useGetNote } from 'core/uses-cases/note';

export const ViewNote = () => {
	const { t } = useTranslation();

	const { communityId, noteId } = useParams() as {
		communityId: string;
		noteId: string;
	};

	const router = useRouter();

	const { data, isLoading, isSuccess, error } = useGetNote(
		communityId as string,
		noteId as string
	);

	const deleteNoteHandler = useDeleteNoteHandler({
		onConfirm: () =>
			router.navigate('community.notes', {
				communityId,
			}),
	});

	const handleOnDelete = useCallback(
		note => {
			deleteNoteHandler({
				noteId: note.uuid,
				communityId,
			});
		},
		[communityId]
	);

	const handleOnEdit = useCallback(note => {
		router.navigate('note.edit', {
			communityId,
			noteId: note.uuid,
		});
	}, []);

	useErrorHandler(error, {
		redirectTo: router.getPathFor('community.notes', {
			communityId,
		}),
	});

	return (
		<Box>
			{isLoading && (
				<Box display='flex' justifyContent='center' mt={4}>
					<CircularProgress message={t('common.loading')} />
				</Box>
			)}
			{isSuccess && data && (
				<>
					<Box display='flex' mb={4}>
						<Heading variant='h4'>{data.title}</Heading>
					</Box>
					<NoteCard
						hideTitle
						note={data}
						onEdit={handleOnEdit}
						onDelete={handleOnDelete}
					/>
				</>
			)}
		</Box>
	);
};
