import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography } from 'components/common';
import { Fragment, useCallback, useEffect, useMemo } from 'react';
import { useCalendar } from 'components/calendar/context';
import {
	ShowMoreLessHoursButton,
	useCalendarHeaderDays,
	useGetHoursRange,
} from 'components/calendar/shared';
import { calendarUtils, getRangeOfDates } from '../../utils';
import { CalendarNavigation } from '../../shared/navigation';
import { weekHeaderContainerStyle } from './styles';
import { TimeRow } from './row';

export const Week = () => {
	const { t } = useTranslation();
	const { displayed, setDisplayed, formatDate, loadEvents, timezone } =
		useCalendar();

	const { startDate, endDate, daysRange } = useMemo(() => {
		const startDate = calendarUtils.getFirstDayOfWeek(displayed);
		const endDate = calendarUtils.getLastDayOfWeek(displayed);
		const daysRange = getRangeOfDates(startDate, endDate);

		return {
			startDate,
			endDate,
			daysRange,
		};
	}, [displayed]);

	const headerDays = useCalendarHeaderDays(timezone);

	const { hours, showLessTime, toggle } = useGetHoursRange(timezone);

	const renderDisplay = `${formatDate(startDate, 'MMMM d')} - ${formatDate(
		endDate,
		'MMMM d'
	)}`;

	const handleOnPressNext = useCallback(() => {
		setDisplayed(displayed.plus({ weeks: 1 }));
	}, [displayed]);

	const handleOnPressPrev = useCallback(() => {
		setDisplayed(displayed.minus({ weeks: 1 }));
	}, [displayed]);

	useEffect(() => {
		const startDate = calendarUtils.getFirstDayOfWeek(
			displayed.minus({ weeks: 1 })
		);
		const endDate = calendarUtils.getLastDayOfWeek(
			displayed.plus({ weeks: 1 })
		);

		loadEvents({ startDate, endDate });
	}, [displayed]);

	return (
		<>
			<CalendarNavigation
				display={renderDisplay}
				onNext={handleOnPressNext}
				onPrev={handleOnPressPrev}
			/>
			<Box>
				<Grid container item spacing={0} sx={weekHeaderContainerStyle}>
					<Grid item xs={1}>
						<Typography variant='body1'>
							{t('calendar-widget.time')}
						</Typography>
					</Grid>
					{headerDays.map(day => (
						<Fragment key={`week-header-${day.toISO()}`}>
							<Grid item xs={11 / 7}>
								<Typography variant='body1'>
									{formatDate(day, 'EEE')}
								</Typography>
							</Grid>
						</Fragment>
					))}
				</Grid>
				<Grid container spacing={0}>
					<Grid item xs={12} flexDirection='column'>
						<ShowMoreLessHoursButton
							onChange={toggle}
							value={showLessTime}
						/>
					</Grid>
					<TimeRow
						label={t('calendar-widget.all-day')}
						days={daysRange}
					/>
					{hours.map(row => (
						<TimeRow
							key={`time-row-week-${row.startTime.toISO()}`}
							label={formatDate(
								row.startTime,
								'h a'
							).toLowerCase()}
							time={row.startTime}
							days={daysRange}
						/>
					))}
				</Grid>
			</Box>
		</>
	);
};
