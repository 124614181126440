import { Box, Paper, TruncatedContent, BoxProps } from 'components/common';
import { FeedCardFooter } from 'components/community/feed/shared/feed-card-footer';
import { Announcement } from 'core/types';
import { useCallback } from 'react';
import { useTranslation, useVerifyActions } from 'hooks';
import {
	FeedCardContent,
	FeedCardHeader,
	FeedImage,
	FeedVideo,
	useFeedCardMenu,
} from '../../community/feed/shared';
import { videoStyle } from './styles';

type AnnouncementCardProps = {
	announcement: Announcement;
	hideTitle?: boolean;
	onDelete?: (announcement: Announcement) => void;
	onEdit?: (announcement: Announcement) => void;
	renderFooter?: React.ReactNode;
	truncate?: boolean;
} & BoxProps;

export const AnnouncementCard = ({
	announcement,
	hideTitle,
	onDelete,
	onEdit,
	renderFooter,
	truncate = false,
	...props
}: AnnouncementCardProps) => {
	const { t } = useTranslation();

	const handleOnEdit = useCallback(() => {
		onEdit?.(announcement);
	}, [onEdit, announcement]);

	const handleOnDelete = useCallback(() => {
		onDelete?.(announcement);
	}, [onDelete, announcement]);

	const actionObject = useVerifyActions(
		{
			type: 'announcement',
			identity_permissions: announcement.identity_permissions,
		},
		[
			{ action: 'update', handler: handleOnEdit, key: 'onEdit' },
			{ action: 'delete', handler: handleOnDelete, key: 'onDelete' },
		]
	);

	const menuAction = useFeedCardMenu(actionObject);

	return (
		<Box component={Paper} elevation={0} p={3} mb={2} mt={1} {...props}>
			<FeedCardHeader
				identity={announcement.author}
				createdDt={announcement.published_dt ?? ''}
				renderAction={menuAction}
				className='announcement-card-content'
			/>
			<FeedCardContent
				title={!hideTitle ? announcement.title ?? '' : undefined}
				content={announcement.message ?? ''}
				contentWrapper={
					<TruncatedContent
						showText={t('announcements.view-full-announcement')}
						hideText={t('announcements.hide-announcement')}
						enabled={truncate}
					/>
				}
				className='announcement-card-content'
			>
				{announcement?.video && (
					<FeedVideo
						video={announcement?.video}
						boxProps={{
							sx: videoStyle,
						}}
					/>
				)}
				{announcement?.image && (
					<FeedImage image={announcement?.image} />
				)}
			</FeedCardContent>
			{renderFooter && (
				<FeedCardFooter className='announcement-card-footer'>
					{renderFooter}
				</FeedCardFooter>
			)}
		</Box>
	);
};
