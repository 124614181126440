import { useCurrentCommunity } from 'components';
import { Album, SingleGroup } from 'core/types';
import { useMemo } from 'react';
import { AlbumFormInitialValues } from '../form';

export const useAlbumFormData = (record?: Album) => {
	const community = useCurrentCommunity();

	const groups = useMemo(() => {
		return (
			community?.groups.all.filter(
				(group: SingleGroup) =>
					!(group.name === 'member' || group.name === 'site_admin')
			) ?? []
		);
	}, [community]);

	const data = useMemo(() => {
		if (!record) return {} as AlbumFormInitialValues;

		return {
			name: record.name,
			visibility: record.settings.visibilitySettings.privacy,
			visibilityGroups: record.settings.visibilitySettings.groups.map(
				group => group.id
			),
			upload: record.settings.uploadSettings.upload,
			uploadGroups: record.settings.uploadSettings.groups.map(
				group => group.id
			),
		} as AlbumFormInitialValues;
	}, [record]);

	return {
		initialValues: {
			...data,
		},
		groups,
	};
};
