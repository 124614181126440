import { useEffect, useRef } from 'react';
import {
	Navigate as RRNavigate,
	NavigateProps as RRNavigateProps,
} from 'react-router-dom';
import { AlertLevel, useServices } from 'core/services';

export interface NavigateProps extends RRNavigateProps {
	alert?: {
		message: string;
		level: AlertLevel;
		timeout?: number;
	};
}

export const Navigate = (props: NavigateProps) => {
	const alertShown = useRef(false);

	const { alert, ...rest } = props;
	const { notification } = useServices();

	useEffect(() => {
		if (alert && !alertShown.current) {
			notification.show(alert.level, alert.message, {
				timeout: alert.timeout,
			});

			alertShown.current = true;
		}
	}, [alert]);

	return <RRNavigate {...rest} />;
};
