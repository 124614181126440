import { Field } from 'react-final-form';

type WhenProps<T> = {
	field: string;
	is: ((value: T) => boolean) | string | object | number | boolean;
	children: React.ReactNode;
};

export function When<T = unknown>({ field, is, children }: WhenProps<T>) {
	return (
		<Field<T> name={field} subscription={{ value: true }}>
			{({ input: { value } }) => {
				if (typeof is === 'function') {
					return is(value) ? children : null;
				}

				return (value || false) === is ? children : null;
			}}
		</Field>
	);
}

export function WhenNot<T = unknown>({ field, is, children }: WhenProps<T>) {
	return (
		<Field<T> name={field} subscription={{ value: true }}>
			{({ input: { value } }) => {
				if (typeof is === 'function') {
					return is(value) ? children : null;
				}

				return (value || true) !== is ? children : null;
			}}
		</Field>
	);
}
