import { SxProps } from '@mui/system';
import { Theme } from 'theme';

export const shadowStyle: SxProps<Theme> = theme => ({
	position: 'relative',

	'&:before': {
		content: '""',
		width: '100%',
		height: 100,
		position: 'absolute',
		left: 0,
		bottom: 0,
		background: `linear-gradient(transparent 10px, ${theme.palette.common.white})`,
	},
});

export const titleStyle: SxProps<Theme> = {
	cursor: 'pointer',
};
