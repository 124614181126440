import MuiTab, { TabProps as MuiTabsProps } from '@mui/material/Tab';
import MuiTabs, { TabsProps } from '@mui/material/Tabs';
import { styled } from '@mui/material/styles';
import { Box } from 'components/common';
import { tabsStyle } from './styles';

interface TabPanelProps {
	children?: React.ReactNode;
	isActive: boolean;
}

export const TabPanel = (props: TabPanelProps) => {
	const { children, isActive, ...rest } = props;

	return (
		<Box role='tabpanel' {...rest}>
			{isActive ? <Box sx={{ mt: 6 }}>{children}</Box> : null}
		</Box>
	);
};

export const Tabs = (props: TabsProps) => <MuiTabs sx={tabsStyle} {...props} />;

interface TabProps extends MuiTabsProps {
	variant?: 'small' | 'medium' | 'large' | 'unset';
}

const InnerTab = styled(MuiTab)<TabProps>(({ theme, variant }) => ({
	...(variant === 'large' && {
		minWidth: 160,
		padding: theme.spacing(1.3),
	}),
	...(variant === 'medium' && {
		minWidth: 120,
		padding: theme.spacing(1.1),
	}),
	...(variant === 'small' && {
		padding: theme.spacing(1, 2),
	}),
	...(variant === 'unset' && {
		padding: theme.spacing(1, 2),
		minWidth: 'unset',
		maxWidth: 'unset',
		maxHeight: 'unset',
		minHeight: 'unset',
	}),
	lineHeight: '24px',
	fontSize: 14,
	color: theme.primaryColors.primary6,
	textTransform: 'none',
	borderRightWidth: 1,
	borderRightStyle: 'solid',
	borderColor: theme.palette.common.black,

	'&:last-of-type': {
		borderRightWidth: 0,
	},

	'&.Mui-selected': {
		backgroundColor: theme.primaryColors.primary4,
		color: theme.palette.common.white,
	},

	'&.Mui-focusVisible': {
		backgroundColor: 'transparent',
	},
}));

export const Tab = (props: TabProps) => {
	const { variant = 'medium', ...rest } = props;

	return <InnerTab disableRipple variant={variant} {...rest} />;
};
