import { useCallback } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import {
	Box,
	Grid,
	Typography,
	Heading,
	Paper,
	LoadingButton,
} from 'components/common';
import { SetupCommunityStepper } from 'components/community/setup';
import {
	useCurrentCommunity,
	useRouter,
	useServices,
	useTranslation,
} from 'hooks';
import { usePostAnnouncement } from 'core/uses-cases/announcement';
import {
	AnnouncementForm,
	AnnouncementFormValues,
} from 'components/announcements';
import { useAnnouncementFormData } from 'components/announcements/hooks';
import { usePatchCommunity } from 'core/uses-cases/community';
import { COMMUNITY_SETUP_COMPLETED } from 'core/constants';
import { useCurrentIdentity } from 'core/uses-cases/authentication';

export const SetupAnnouncement = () => {
	const { t } = useTranslation();
	const { communityId } = useParams() as { communityId: string };
	const community = useCurrentCommunity();
	const identity = useCurrentIdentity();
	const router = useRouter();
	const { notification } = useServices();

	const postAnnouncement = usePostAnnouncement();
	const patchCommunity = usePatchCommunity();

	const handleOnSubmit = useCallback(
		async (values: AnnouncementFormValues) => {
			await postAnnouncement.mutateAsync(
				{
					communityId,
					...values,
				},
				{
					onSuccess: result => {
						notification.show(
							'success',
							t(
								'announcements.announcement-was-successfully-created',
								{
									title: result.title,
								}
							)
						);
					},
				}
			);

			await handleOnClickSkip();
		},
		[communityId]
	);

	const handleOnClickSkip = useCallback(async () => {
		if (!community) return undefined;

		await patchCommunity.mutateAsync(
			{
				name: community.name,
				communityId,
				setup_status: COMMUNITY_SETUP_COMPLETED,
			},
			{
				onSuccess: () => {
					router.navigate('community.home', {
						communityId,
					});
				},
			}
		);
	}, [communityId]);

	const formProps = useAnnouncementFormData(communityId);

	if (!identity) {
		return <Navigate to='/?error=identity-not-found' replace />;
	}

	return (
		<Box>
			<Grid mb={4} container spacing={3}>
				<Grid item xs={12} md={8}>
					<Heading variant='h4' mb={1}>
						{t('create-community.share-your-first-update')}
					</Heading>
					<Typography variant='body2' mb={1}>
						{t('create-community.get-your-community-up-to-speed')}
					</Typography>
					<Typography variant='body2' mb={1}>
						{t('create-community.announcements-are-great')}
					</Typography>
					<Typography variant='body2' mb={1}>
						{t('create-community.announcement-description')}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<SetupCommunityStepper />
				</Grid>
			</Grid>
			<Box component={Paper} elevation={0} mt={6} px={5} py={5}>
				<AnnouncementForm
					onSubmit={handleOnSubmit}
					submitText={t('announcement-form.create-announcement')}
					{...formProps}
				/>
			</Box>
			<Box display='flex' mt={2} justifyContent='flex-end'>
				<LoadingButton
					onClick={handleOnClickSkip}
					size='small'
					loading={patchCommunity.isLoading}
					loadingIndicator={t('community-form.going-home')}
					data-test-id='setup-community-skip-step-3'
				>
					{t('community-form.go-to-my-homepage')} &raquo;
				</LoadingButton>
			</Box>
		</Box>
	);
};
