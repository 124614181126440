import {
	Box,
	Field,
	Form,
	FormOnSubmitReturn,
	FormStack,
	LoadingButton,
	PasswordField,
} from 'components/common';
import { useCallback } from 'react';
import { useConstantValue, useTranslation } from 'hooks';
import { sleep } from 'utils/delay';
import { PlainTextField } from '../../common/form/plain-text-field/plain-text-field';

export type InvitationLoginFormSubmittedValues = {
	email: string;
	password: string;
};

type InvitationLoginFormProps = {
	identity: { email: string; name?: string };
	onSubmit?: (
		values: InvitationLoginFormSubmittedValues
	) => FormOnSubmitReturn;
	submitText?: string;
	submittingText?: string;
	acceptingText?: string | React.ReactNode;
};

export const InvitationLoginForm = (props: InvitationLoginFormProps) => {
	const {
		identity: { email, name },
		onSubmit,
		submitText,
		submittingText,
		acceptingText,
	} = props;
	const { t } = useTranslation();

	const handleOnSubmit = useCallback(
		async values => {
			await sleep(1);

			const result = onSubmit?.(values);

			return Promise.resolve(result).catch(error => error);
		},
		[onSubmit]
	);

	const cacheInitialValues = useConstantValue(() => ({
		email,
	}));

	return (
		<Form
			initialValues={cacheInitialValues}
			onSubmit={handleOnSubmit}
			subscription={{ submitting: true }}
		>
			{({ handleSubmit, submitting }) => (
				<form onSubmit={handleSubmit} noValidate>
					<FormStack spacing={6} responsive={false}>
						{name && (
							<Box>
								<PlainTextField
									label={t('common-label.name')}
									value={name}
									fullWidth
								/>
							</Box>
						)}
						<Box>
							<PlainTextField
								label={t('common-label.email')}
								value={email}
								fullWidth
							/>
						</Box>
						<Box>
							<Box mr={{ md: '35%' }}>
								<Field
									label={t('common-label.password')}
									name='password'
									component={PasswordField}
									type='password'
									fullWidth
									required
								/>
							</Box>
						</Box>
						{acceptingText && <Box>{acceptingText}</Box>}
						<Box>
							<LoadingButton
								loading={submitting}
								loadingIndicator={
									submittingText || t('common.loading')
								}
								size='small'
								type='submit'
							>
								{submitText || t('common.continue')}
							</LoadingButton>
						</Box>
					</FormStack>
				</form>
			)}
		</Form>
	);
};
