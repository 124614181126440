import {
	Box,
	BoxProps,
	CheckboxGroupField,
	Typography,
	FormControl,
	TextProps,
	TextField,
	Paper,
} from 'components/common';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@mui/material/Autocomplete';
import { SyntheticEvent, useMemo, useState } from 'react';
import Chip from '@mui/material/Chip';
import { useCalendar } from 'components/calendar/context';
import { FILTER_STATUSES } from 'components/calendar/constants';
import { StringKeys } from 'core/types';
import { statusLabelStyle, sectionStyle } from './styles';

const Section = ({ isLast, ...props }: BoxProps & { isLast?: boolean }) => (
	<Box {...props} sx={sectionStyle(isLast)} />
);

export const StatusLabel = (props: TextProps) => (
	<Typography sx={statusLabelStyle} {...props} />
);

const InnerSidebar = () => {
	const { t } = useTranslation();

	const { members, filter, setFilter } = useCalendar();

	const handleChangeStatus = (value: string[]) => {
		setFilter({
			...filter,
			statuses: value,
		});
	};

	const handleChangeEventType = (value: string[]) => {
		setFilter({
			...filter,
			eventTypes: value,
		});
	};

	const handleChangeMembers = (
		event: SyntheticEvent,
		value: { value: string; label: string }[]
	) => {
		setFilter({
			...filter,
			memberIds: value.map(({ value }) => value),
		});
	};

	const memberOptions = useMemo(
		() =>
			members.map(member => ({
				value: member.uuid,
				label: `${member.first_name} ${member.last_name}`,
			})),
		[members]
	);

	const eventTypes = {
		meal: t('calendar-widget.preparing-meals'),
		ride: t('calendar-widget.giving-rides'),
		shopping: t('calendar-widget.shopping'),
		childcare: t('calendar-widget.childcare'),
		visit: t('calendar-widget.visits'),
		coverage: t('calendar-widget.coverage'),
		medical: t('calendar-widget.medical'),
		misc: t('calendar-widget.miscellaneous'),
	};

	const eventTypeOptions = (
		Object.keys(eventTypes) as StringKeys<typeof eventTypes>
	).map(key => ({
		value: key,
		label: eventTypes[key],
	}));

	return (
		<Box>
			<Section>
				<Typography variant='h4'>
					{t('calendar-widget.status')}
				</Typography>
				<CheckboxGroupField
					name='statuses'
					options={[
						{
							value: FILTER_STATUSES.HELP_NEEDED,
							label: (
								<StatusLabel className='help-needed-key'>
									{t('calendar-widget.help-needed')}
								</StatusLabel>
							),
						},
						{
							value: FILTER_STATUSES.NEEDS_MET,
							label: (
								<StatusLabel className='needs-met-key'>
									{t('calendar-widget.needs-met')}
								</StatusLabel>
							),
						},
						{
							value: FILTER_STATUSES.OCCASIONS,
							label: (
								<StatusLabel className='occasions-key'>
									{t('calendar-widget.occasions')}
								</StatusLabel>
							),
						},
					]}
					onChange={handleChangeStatus}
					value={filter.statuses}
				/>
			</Section>
			<Section>
				<Typography variant='h4'>
					{t('calendar-widget.members')}
				</Typography>
				<FormControl fullWidth>
					<Autocomplete
						multiple
						options={memberOptions}
						loading={memberOptions.length === 0}
						renderOption={(props, option) => (
							<li {...props} key={option.value}>
								{option.label}
							</li>
						)}
						getOptionLabel={option => option.label}
						isOptionEqualToValue={(option, { value }) => {
							return option.value === value;
						}}
						filterSelectedOptions
						defaultValue={[]}
						renderInput={params => (
							<TextField
								{...params}
								placeholder={t(
									'calendar-widget.select-members'
								)}
								size='small'
							/>
						)}
						renderTags={(value, getTagProps) => {
							return value.map((option, index) => (
								<Chip
									color='primary'
									label={option.label}
									size='small'
									{...getTagProps({ index })}
									key={option.value}
								/>
							));
						}}
						onChange={handleChangeMembers}
					/>
				</FormControl>
			</Section>
			<Section isLast>
				<Typography variant='h4'>
					{t('calendar-widget.event-type')}
				</Typography>
				<CheckboxGroupField
					name='eventType'
					options={eventTypeOptions}
					onChange={handleChangeEventType}
					value={filter.eventTypes}
				/>
			</Section>
		</Box>
	);
};

export const Sidebar = () => {
	const [open, setOpen] = useState(false);
	const handleClick = () => setOpen(!open);
	const { t } = useTranslation();

	return (
		<>
			<Box component={Paper} elevation={0}>
				<Box
					display={{ xs: 'none', lg: 'flex' }}
					flexDirection='column'
				>
					<InnerSidebar />
				</Box>
				<Box
					display={{ xs: 'flex', lg: 'none' }}
					flexDirection='column'
				>
					<ListItemButton onClick={handleClick}>
						<ListItemText
							primaryTypographyProps={{
								fontSize: 24,
							}}
							primary={t('calendar-widget.filters')}
						/>
						{open ? <ExpandLess /> : <ExpandMore />}
					</ListItemButton>
					<Collapse in={open} timeout='auto' unmountOnExit>
						<InnerSidebar />
					</Collapse>
				</Box>
			</Box>
		</>
	);
};
