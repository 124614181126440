import { SxProps } from '@mui/system';
import { Theme } from 'theme';

export const dayHeaderContainerStyle: SxProps<Theme> = theme => ({
	borderBottom: '1px solid',
	borderBottomColor: theme.palette.grey[300],

	'& p': {
		display: 'block',
		textAlign: 'center',
		padding: theme.spacing(1),
		fontWeight: 400,
		fontSize: '24px',
	},
});

export const timeRowStyle: SxProps<Theme> = theme => ({
	borderBottom: '1px dotted ',
	borderBottomColor: theme.palette.grey[300],

	'&:last-child': {
		borderBottomColor: 'transparent',
	},

	'> *': {
		minHeight: 64,
		borderRight: '1px dotted',
		borderRightColor: theme.palette.grey[300],

		'&:first-of-type': {
			paddingX: theme.spacing(0.5),
			paddingY: theme.spacing(1),
			textAlign: 'right',
			backgroundColor: theme.secondaryColors.secondary5,
		},

		'&:last-child': {
			borderRightColor: 'transparent',
		},

		'> div': {
			height: '100%',
			paddingY: theme.spacing(1),
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(1),
		},
	},
});
