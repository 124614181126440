import i18n, { TFunction } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import resourcesToBackend from 'i18next-resources-to-backend';

export function configureI18n(): Promise<TFunction> {
	return i18n
		.use(LanguageDetector)
		.use(initReactI18next) // passes i18n down to react-i18next
		.use(
			resourcesToBackend((language, namespace, callback) => {
				import(`./lang/${language}.json`)
					.then(m => {
						callback(null, m.default);
					})
					.catch(error => {
						callback(error, null);
					});
			})
		)
		.init({
			fallbackLng: 'en',
			interpolation: {
				escapeValue: false,
				format: function (value, format) {
					if (format === 'uppercase' && typeof value === 'string')
						return value.toUpperCase();

					return value;
				},
			},
		});
}
