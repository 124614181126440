import config from 'config';
import {
	Box,
	Link,
	List,
	ListItem,
	PageHeading,
	Paper,
	Typography,
} from 'components/common';
import { useTranslation } from 'hooks';
import { Trans } from 'react-i18next';
import { listStyles, listItemStyles } from './styles';

export const CommunitySupport = () => {
	const { t } = useTranslation();

	return (
		<Box>
			<PageHeading title={t('support.we-are-here-to-help')}>
				<Typography>{t('support.general-questions')}</Typography>
			</PageHeading>
			<Box component={Paper} elevation={0} mt={5} mb={3} px={5} py={5}>
				<List component='ol' sx={listStyles}>
					<ListItem sx={listItemStyles}>
						<Trans i18nKey='support.step-1'>
							<Link href={config.urls.faq} target='_blank' />
						</Trans>
					</ListItem>
					<ListItem sx={listItemStyles}>
						<Trans i18nKey='support.step-2'>
							<Link href='mailto:support@lotsahelpinghands.com' />
						</Trans>
					</ListItem>
					<ListItem sx={listItemStyles}>
						{t('support.step-3')}
					</ListItem>
				</List>
				<Typography mt={6}>
					{t('support.specific-questions')}
				</Typography>
			</Box>
		</Box>
	);
};
