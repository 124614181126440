import { DateTime } from 'luxon';
import { Nullable } from './utils';

export type ServerImage = {
	uuid: string | null;
	name: string;
	url: string;
	scaledUrl?: string;
	width: number;
	height: number;
	size: number;
	created_dt: string;
};

export type ServerAsset = {
	uuid: string | null;
	name: string;
	metadata: string;
	size: number;
	url: string;
	created_dt: string;
};

export interface BlobFile extends Blob {
	readonly lastModified: number;
	readonly name: string;
}

export interface Image extends ServerImage {
	file: BlobFile | null;
}

export interface Asset extends ServerAsset {
	file: BlobFile | null;
}

export type Identity = {
	uuid: string;
	first_name: string;
	last_name: string;
	email: string;
	phone: string;
	phone_readable?: string;
	address: string | null;
	location: string | null;
	autodetect_timezone: boolean;
	timezone: string;
	language: Nullable<string>;
	created_dt: string;
	image: Nullable<Image>;
	my_communities: string[];
	my_pending_communities: string[];
	my_invited_communities: string[];
	not_before_dt: null | string;
};

export type Community = {
	uuid: string;
	slug: string;
	name: string;
	description: string;
	created_dt: Date;
	purpose_id: number;
	public_listing: boolean;
	zip_code: string;
	has_well_wishes: boolean;
	has_announcements: boolean;
	has_photos: boolean;
	has_documents: boolean;
	has_advanced_permissions: boolean;
	is_active: boolean;
	is_demo: boolean;
	timezone: string;
	setup_status: number;
	contact_group_uuid: string;
	members_can_invite: boolean;
	members_can_upload: boolean;
	last_login_dt: DateTime;
	last_active_dt: DateTime | null;
	city: string;
	state: string;
	is_legacy: boolean;
	calendar: Nullable<Calendar>;
	purpose: Purpose;
	image: Nullable<Image>;
	partner: Partner;
	owner: Nullable<Owner>;
	groups: CommunityGroups;
	member_stats: MemberStats;
	contact_info: ContactInfo;
	team_stats: TeamStats;
	switch_to_url: string;
	invite_token?: string;
};

export type MemberStats = {
	coordinators: number;
	members: number;
};

export type TeamStats = {
	total: number;
	requests: number;
	invites: number;
	declined: number;
	failed: number;
};

export type ContactInfo = {
	is_site_admin: boolean;
	contact_list: Identity[];
};

export interface Calendar {
	uuid: string;
	community: Community;
}

export interface CalendarSummary {
	community: {
		uuid: string;
		slug: string;
	};
}

export interface Purpose {
	id: string;
	name: string;
	description: string;
}

export interface Partner {
	id: string;
	code: string;
	name: string;
	description: string;
}

export interface Owner {
	uuid: string;
	first_name: string;
	last_name: string;
	email: string;
	phone: string;
	address: string;
	location: string;
	autodetect_timezone: boolean;
	timezone: string;
	language?: string | null;
	created_dt: Date;
}

export interface CommunityGroups {
	all: Group[];
	memberUuid: string;
}

export interface GroupPermission {
	description: string;
	name: string;
	entity_uuid: string;
}

export interface Group {
	uuid: string;
	community_id: string;
	name: string;
	description: string;
	is_hidden: boolean;
	is_system_managed: boolean;
	has_locked_permissions: boolean;
	has_open_enrollment: boolean;
	identity_permissions: PermissionsKey[];
	permissions: GroupPermission[];
	members?: Pick<Identity, 'uuid' | 'first_name' | 'last_name' | 'email'>[];
	is_public?: boolean;
	who_can_see: SingleGroup[];
	managers?: SingleGroup[];
	is_offline?: Nullable<boolean>;
}

export type SingleGroup = Pick<
	Group,
	'name' | 'description' | 'uuid' | 'members'
>;

export interface AlbumPhoto {
	uuid: string;
	album_uuid: string;
	community_uuid: string;
	created_dt: string;
	discussion_uuid: string;
	discussion_stats: {
		comments: number;
	};
	owner: Identity | null;
	image: Image;
	sort_order: number;
	can_delete: boolean;
	source_entity: string | null;
	source_uuid: string | null;
	identity_permissions: PermissionsKey[];
}

export interface Album {
	uuid: string;
	community_uuid: string;
	name: string;
	sort_order: number;
	created_dt: string;
	identity_uuid: Nullable<string>;
	cover_photo: Nullable<AlbumPhoto>;
	photos: AlbumPhoto[];
	identity_permissions: PermissionsKey[];
	type: 'user' | 'system';
	settings: {
		visibilitySettings: {
			privacy: string;
			groups: { id: string; name: string; description: string }[];
		};
		uploadSettings: {
			upload: string;
			groups: { id: string; name: string; description: string }[];
		};
	};
}

export interface Video {
	created_dt: string;
	hd_url: string;
	metadata: {
		cover: {
			uuid: string;
		};
		identityUuid: string;
		mimetype: string;
		encoding_status: string;
		streams: {
			sd: { duration: number; width: number; height: number };
			hd: { duration: number; width: number; height: number };
		};
	};
	name: string;
	sd_url: string;
	sha1: string;
	size: number;
	type: string;
	url: string;
	uuid: string;
}

export interface FeedRecord {
	uuid: string;
	title?: string;
	name?: string;
	message?: string;
	content?: string;
	discussion_state: string;
	discussion_stats: { comments: number };
	created_dt: string;
	published_dt: string;
	updated_dt?: string;
	discussion_uuid: string;
	is_featured: boolean;
	sort_date: string;
	type: string;
	image?: Nullable<Image>;
	author?: Identity;
	identity?: Identity;
	owner?: Identity;
	photos?: AlbumPhoto[];
	album?: Album;
	video?: Nullable<Video>;
	identity_permissions?: PermissionsKey[];
}

export interface Announcement {
	author: Identity;
	created_dt: string | null;
	discussion_state: string;
	discussion_stats: { comments: number };
	discussion_uuid: string;
	image: Nullable<Image>;
	is_featured: boolean;
	message: string;
	published_dt: Nullable<string>;
	title: string;
	uuid: string;
	video: Nullable<Video>;
	who_can_see: SingleGroup[];
	identity_permissions: PermissionsKey[];
}

export interface Note {
	uuid: string;
	owner: Identity;
	created_dt: string | null;
	updated_dt: string | null;
	title: string;
	content: string;
	is_public?: boolean;
	who_can_see: SingleGroup[];
	managers: SingleGroup[];
	identity_permissions: PermissionsKey[];
}

export interface CommunityMemberStats {
	tasks_created: number;
	tasks_done: number;
	uploaded_photos: number;
}

export type ScheduledEvent = Pick<
	Event,
	| 'uuid'
	| 'name'
	| 'type'
	| 'start_date'
	| 'end_date'
	| 'start_time'
	| 'end_time'
> & {
	calendar: { uuid: string };
};

export type MemberRecentActivity = {
	action: string;
	name: string;
	date: string;
	identity: Identity | null;
	community: Community | null;
};

export interface CommunityMember {
	identity: Identity;
	groups: SingleGroup[];
	notes: string;
	administrative_notes?: string;
	help_provided: string;
	is_new: number;
	stats: {
		ninety_days: CommunityMemberStats;
		all_time: CommunityMemberStats;
	};
	member_schedule: ScheduledEvent[];
	recent_activity: MemberRecentActivity[];
	joined_dt: string;
	identity_permissions: PermissionsKey[];
}

export type CommunityMemberRaw = Omit<
	CommunityMember,
	'member_schedule' | 'recent_activity'
> & {
	member_schedule: (Omit<ScheduledEvent, 'start_date' | 'end_date'> & {
		start_date: { date: string; timezone: string; timezone_type: number };
		end_date: {
			date: string;
			timezone: string;
			timezone_type: number;
		};
	})[];
	recent_activity: (Omit<MemberRecentActivity, 'date'> & {
		date: { date: string; timezone: string; timezone_type: number };
	})[];
};

export interface WellWish {
	uuid: string;
	message: string;
	author: Identity;
	created_dt: string | null;
	image: Nullable<Image>;
	identity_permissions?: PermissionsKey[];
}

export interface Item {
	type: EntityKey;
	identity_permissions?: PermissionsKey[];
}

export type SettingValues =
	| 'only_admins'
	| 'admins_members'
	| 'members'
	| 'custom';

export type DefaultSettingKey = {
	privacy: SettingValues;
};

export type VolunteerSettingKey = {
	volunteer: SettingValues;
};

export type EventSetting<K = DefaultSettingKey> = {
	groups: (Pick<Group, 'name' | 'description'> & { id: string })[];
} & K;

export type AssetCollection = {
	uuid: string;
	name: string | null;
	assets: Asset[];
};

export interface SearchResult {
	uuid: string;
	slug: string;
	name: string;
	description: string;
	image: Nullable<Image>;
	location: {
		description: string | null;
		zip_code: string | null;
	};
}

export type Location = {
	name: string;
	country: string;
	state: string;
	city: string;
	address: string;
	latitude: number;
	longitude: number;
	zip: string;
};

export type EventLocation = Location & {
	uuid: string;
	created_dt: string;
};

export interface Event {
	uuid: string;
	name: string;
	description: string;
	type:
		| 'meal'
		| 'ride'
		| 'shopping'
		| 'childcare'
		| 'visit'
		| 'coverage'
		| 'medical'
		| 'occasion'
		| 'misc';
	start_date: string;
	end_date: string;
	start_time: string | null;
	end_time: string | null;
	volunteers_available: number;
	volunteers_needed: number;
	event_series_uuid: string;
	event_series_count?: number;
	user_timezone: Nullable<string>;
	discussion_uuid: Nullable<string>;
	created_dt: string | null;
	deleted_dt: string | null;
	image: Nullable<Image>;
	asset_collection: AssetCollection;
	locations: EventLocation[];
	volunteers?: Identity[];
	coordinators?: Identity[];
	settings: {
		visibilitySettings: EventSetting;
		volunteerSettings: EventSetting;
	};
	identity_permissions: PermissionsKey[];
}

export type RawEvent = Omit<Event, 'settings'> & {
	settings: {
		visibilitySettings: Event['settings']['visibilitySettings'];
		volunteerSettings: EventSetting<VolunteerSettingKey>;
	};
};

export interface SearchResult {
	name: string;
	description: string;
	image: Nullable<Image>;
	city: string;
	state: string;
	uuid: string;
}

export interface Timezone {
	value: string;
	label: string;
	abbr: string;
	alias: string[];
}

export interface TimezoneCountry {
	value: string;
	label: string;
	timezones: Timezone[];
}

export type PermissionResources =
	| 'community'
	| 'announcement'
	| 'album'
	| 'album_image'
	| 'member'
	| 'task'
	| 'well_wish'
	| 'group'
	| 'note';

export type CommunityPermissions = {
	// eslint-disable-next-line no-unused-vars
	[key in PermissionResources]: { permissions: Permissions };
};

export type EntityKey = keyof CommunityPermissions;

export type Permissions = {
	approve?: boolean;
	assign?: boolean;
	create?: boolean;
	delete?: boolean;
	delete_comments?: boolean;
	read?: boolean;
	unvolunteer?: boolean;
	update?: boolean;
	upload_photo?: boolean;
	view_private?: boolean;
	volunteer?: boolean;
};

export type PermissionsKey = keyof Permissions;

export type PermissibleItem = {
	identity_permissions: PermissionsKey[];
};

export type AdvanceCommunityPermissions = {
	community: {
		update: string[];
		delete_comments: string[];
	};
	announcement: {
		create: string[];
		read: string[];
		update: string[];
		delete: string[];
	};
	album: {
		create: string[];
		read: string[];
		update: string[];
		delete: string[];
		upload_photo: string[];
	};
	album_image: {
		update: string[];
		delete: string[];
	};
	member: {
		create: string[];
		update: string[];
		delete: string[];
		approve: string[];
		view_private: string[];
	};
	task: {
		create: string[];
		read: string[];
		update: string[];
		delete: string[];
		assign: string[];
	};
	well_wish: {
		create: string[];
		delete: string[];
	};
	group: {
		create: string[];
		read: string[];
	};
	note: {
		create: string[];
		read: string[];
		update: string[];
		delete: string[];
	};
};

export type Actions = {
	action: PermissionsKey;
	handler: () => void;
	key: string;
}[];

export type ActionLabelAndIcon = Record<
	string,
	{
		label: string;
		icon?: string;
	}
>;

export type Comment = {
	uuid: string;
	commenter: Omit<
		Identity,
		'my_communities' | 'my_pending_communities' | 'my_invited_communities'
	>;
	message: string;
	created_dt: string | null;
	replies?: CommentReply[];
	more_replies?: boolean;
	discussion_stats?: { comments: number };
};

export type CommentReply = Omit<
	Comment,
	'discussion_stats' | 'replies' | 'more_replies'
>;

export type ActionObject = {
	onEdit?: (args: unknown) => void;
	onDelete?: (args: unknown) => void;
	onDuplicate?: (args: unknown) => void;
	onTransfer?: (args: unknown) => void;
	onLeaveCommunity?: (args: unknown) => void;
	onRemoveMember?: (args: unknown) => void;
};

export interface MembershipRequest {
	created_dt: string;
	id: string;
	uuid: string;
	is_accepted: boolean;
	notes: string;
	requester: Identity;
	responder_id: string;
}

export interface MembershipInvite {
	created_dt: string;
	email: string;
	failed: string;
	first_name: string;
	id: string;
	uuid: string;
	invitee_id: string;
	inviter: Identity;
	is_rejected: string;
	last_name: string;
	message: string;
	method: string;
	phone: string;
	response_dt: string;
	status: string;
}

export interface BatchMembershipInvite {
	invitations: MembershipInvite[];
	errors: string[];
}

export type Token = {
	id: string;
	type: string;
	identity_id: string | null;
	details: Record<string, any>;
	created_dt: string;
	expires_dt: string;
};

export type InvitationToken = {
	invitation_id: number;
	identity_id: string | null;
	email: string | null;
	phone: string | null;
	possible_emails: string[];
};

export enum InvitationTokenStatusCode {
	InvitationNotFound = 0,
	PendingToken = 1,
	ExpiredToken = 2,
	InvitationUserCommunityMember = 3,
	AcceptedInvitationUserNotCommunityMember = 4,
	RejectedInvitationUser = 5,
	AcceptedAnotherUserUserCommunityMember = 6,
	AcceptedAnotherUserUserNotCommunityMember = 7,
	RejectedAnotherUser = 8,
	AcceptedUnknownUserCommunityMember = 9,
	AcceptedUnknownUserNotCommunityMember = 10,
	RejectedUnknownUser = 11,
}

export type InvitationTokenStatus = {
	code: InvitationTokenStatusCode;
};

export type InvitationTokenStatusResponse = {
	data: InvitationTokenStatus;
};

export type Invitation = {
	id: string;
	message: string;
	inviter_id: string;
	invitee_id: string | null;
	community_id: string;
	method: 'email' | 'sms';
	first_name: string;
	last_name: string;
	phone: string | null;
	email: string | null;
	failure: string | null;
	failed: string; // should be number
	is_rejected: string; // should be boolean
	response_dt: string | null;
	sent_dt: string | null;
	opened_dt: string | null;
	clicked_dt: string | null;
	approved_dt: string | null;
	created_dt: string;
	deleted_dt: string | null;
};

export type TransferOwnershipRequest = {
	recipient: {
		id: string;
		first_name: string;
		last_name: string;
	};
	sent_dt: string | null;
};

export type TransferRequestToken = {
	id: string;
	identity_id: string;
	details: {
		community_uuid: string;
		current_owner_uuid: string;
		new_owner_uuid: string;
		community_id: number;
	};
	access_token: string;
	identity: Identity;
	created_dt: string;
	expiration_dt: string;
};

export type RequestToJoin = {
	id: string;
	requester_id: string;
	responder_id: string | null;
	notes: string | null;
	is_accepted: null;
	created_dt: string;
};

export type Preferences = {
	event_reminder_time: string;
	digest_subscription: boolean;
	announcement_subscription: boolean;
	digest_frequency: string;
	dismissed_welcome_message: boolean;
};

export type GroupPermissions =
	| 'well_wish:create'
	| 'well_wish:delete'
	| 'community:update'
	| 'community:delete_comments'
	| 'announcement:create'
	| 'member:create'
	| 'album_image:delete'
	| 'member:update'
	| 'member:delete'
	| 'album_image:update'
	| 'member:approve'
	| 'member:view_private'
	| 'task:read'
	| 'task:update'
	| 'task:create'
	| 'task:delete'
	| 'task:assign'
	| 'group:read'
	| 'group:create'
	| 'note:update'
	| 'note:create'
	| 'note:read'
	| 'note:delete'
	| 'album:upload_photo'
	| 'album:delete'
	| 'album:read'
	| 'announcement:read'
	| 'announcement:update'
	| 'announcement:delete'
	| 'album:create'
	| 'album:update';
