import { ResetAuthOptions } from 'core/services';
import { proxy } from 'valtio';

export interface AuthState {
	isLoggedIn: boolean;
	accessToken: string | null;
	setAccessToken: (accessToken: string | null) => void;
	reset: (options?: ResetAuthOptions) => void;
	keepCurrentPageAfterReset: boolean;
	setKeepCurrentPageAfterReset: (keepCurrentPageAfterReset: boolean) => void;
}

export const authState = proxy<AuthState>({
	accessToken: null,
	setAccessToken(accessToken: string | null): void {
		authState.accessToken = accessToken;
	},
	reset(options?: ResetAuthOptions) {
		authState.setKeepCurrentPageAfterReset(
			options?.keepCurrentPageAfterLogout ?? false
		);

		authState.accessToken = null;
	},
	setKeepCurrentPageAfterReset(value: boolean): void {
		authState.keepCurrentPageAfterReset = value;
	},
	keepCurrentPageAfterReset: false,
	get isLoggedIn(): boolean {
		return !!authState.accessToken;
	},
});
