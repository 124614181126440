import { Location } from 'core/types';
import { BaseHttpClient } from '../base-http-client';
import { LocationResponse, TimezonesResponse } from '../response-types';
import { ContactSupportInput } from './types';

export const createCommonApi = (http: BaseHttpClient) => ({
	postReCaptchaToken(token: string) {
		return http
			.post<{ data: { score: string } }>('recaptcha', {
				recaptcha_response: token,
			})
			.then(response => response.data);
	},

	/**
	 * It takes a location object, sends it to the server, and returns the response data
	 *
	 * @param {Location} location - The location object to be posted.
	 * @returns A promise that resolves to a LocationResponse object.
	 */
	postLocation(location: Location) {
		return http
			.post<LocationResponse>('location', location, {})
			.then(response => response.data);
	},

	/**
	 * Retrieves all timezones
	 *
	 * @return {*}
	 */
	getTimezones() {
		return http
			.get<TimezonesResponse>('timezone')
			.then(response => response.data);
	},

	/**
	 * Send an email to support to help find a community
	 */
	contactSupport(input: ContactSupportInput) {
		const {
			fullName,
			email,
			communityName,
			communityLocation,
			beingHelped,
			otherMembers,
		} = input;

		return http
			.post('support', {
				full_name: fullName,
				email,
				community_name: communityName,
				community_location: communityLocation,
				being_helped: beingHelped,
				other_members: otherMembers,
			})
			.then(() => null);
	},
});
