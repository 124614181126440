import { SetupMutationFn } from 'core/configure/types';
import { BaseHttpError } from 'core/services/api/errors';
import { Announcement } from 'core/types';
import { announcementKeys, communityKeys } from 'core/utils/query-key-factory';
import { isCancelledError, useMutation } from 'react-query';
import { deleteAnnouncementMutationKey } from 'core/utils/mutation-key-factory';
import {
	cancelPreviousMutation,
	onDeleteMutateOptimisticInfinityQueryCache,
	PaginatedRecordsSnapshot,
	removeRecordFromInfinityQueryCache,
} from 'core/utils/optimistic-utils';
import { findAnnouncementInfo, getAnnouncementsListKeys } from './utils';
import { isPostPatchAnnouncementMutation } from './use-patch-announcement';

export interface DeleteAnnouncementMutationInput {
	communityId: string;
	announcementId: string;
}

export const useDeleteAnnouncement = () => {
	const deleteMutation = useMutation<
		Announcement,
		BaseHttpError,
		DeleteAnnouncementMutationInput
	>(deleteAnnouncementMutationKey);

	return {
		delete: deleteMutation.mutate,
		deleteAsync: deleteMutation.mutateAsync,
		...deleteMutation,
	};
};

type MutationContext = {
	communityId: string;
	announcementId: string;
	currentAnnouncementInfo: {
		listKey: ReturnType<typeof getAnnouncementsListKeys>[number];
		listSnapshot: PaginatedRecordsSnapshot<Announcement>;
	} | null;
};

export const setupDeleteAnnouncement: SetupMutationFn = (
	services,
	createTrackedParallelMutation,
	mutationTracker
) => {
	const { queryClient, api } = services;

	const mutationOptions = createTrackedParallelMutation<
		null,
		BaseHttpError,
		DeleteAnnouncementMutationInput,
		MutationContext
	>({
		mutationFn: ({ announcementId }) => {
			// Means it is real announcement
			if (announcementId.length === 22) {
				return api.deleteAnnouncement(announcementId);
			}

			// Otherwise, it is a local announcement created in offline moe.
			return Promise.resolve(null);
		},
		onMutate: async input => {
			const { announcementId, communityId } = input;

			await cancelPreviousMutation<MutationContext>(
				queryClient,
				mutation =>
					isPostPatchAnnouncementMutation(mutation) &&
					mutation.state.context?.announcementId === announcementId
			);

			const currentAnnouncementInfo = findAnnouncementInfo(
				queryClient,
				communityId,
				announcementId
			);

			if (currentAnnouncementInfo) {
				const { listKey } = currentAnnouncementInfo;

				await onDeleteMutateOptimisticInfinityQueryCache<Announcement>(
					queryClient,
					listKey,
					announcementKeys.detail(communityId, announcementId),
					announcementId
				);
			}

			removeRecordFromInfinityQueryCache(
				queryClient,
				communityKeys.feed(communityId),
				announcementId
			);

			return {
				communityId,
				announcementId,
				currentAnnouncementInfo,
			};
		},
		onError: (error, input, context) => {
			if (context?.currentAnnouncementInfo && !isCancelledError(error)) {
				queryClient.setQueryData(
					context.currentAnnouncementInfo.listKey,
					context?.currentAnnouncementInfo.listSnapshot
				);
			}
		},
		onSettled: (data, error, { announcementId }, context) => {
			if (context && !isCancelledError(error)) {
				if (announcementId.length === 22) {
					// Only trigger the refetch when it is a real announcement
					mutationTracker.queueInvalidations(
						announcementKeys.lists(context.communityId),
						communityKeys.feed(context.communityId)
					);
				}
			}
		},
		retry: 1,
	});

	queryClient.setMutationDefaults(
		deleteAnnouncementMutationKey,
		mutationOptions
	);
};
