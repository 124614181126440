export const isEmpty = (value: unknown): boolean =>
	value === null || value === undefined;

export const isEmptyObject = (value: object): boolean =>
	!Object.keys(value).length;

export const isString = (value: unknown): boolean => typeof value === 'string';

export const isUndefined = (value: unknown): boolean =>
	typeof value === 'undefined';
