import { ButtonSection } from 'components/common/form/button-section';
import {
	Group,
	Identity,
	Nullable,
	StatusEnum,
	Announcement,
} from 'core/types';
import { useTranslation, useVerifyAccess } from 'hooks';
import { TFunction } from 'i18next';
import { DateTime } from 'luxon';
import { useCallback, useMemo, useRef } from 'react';
import { sleep } from 'utils/delay';
import { dateFromLuxon } from 'utils/validation';
import * as Yup from 'yup';
import {
	CircularProgress,
	Box,
	TextField,
	Form,
	Field,
	Grid,
	RadioGroupField,
	CheckboxField,
	When,
	DateTimePickerField,
	SelectField,
	SelectItem,
	ImagePickerField,
	PickerFile,
	WysiwygField,
	FormOnSubmitReturn,
	VisibilityField,
	visibilityFieldValidationSchema,
	FormStack,
	VisibilityFieldValues,
} from '../common';

export type AnnouncementFormValues = {
	title: string;
	message: string;
	author: string;
	whenToPost: string;
	image: Nullable<PickerFile>;
	publishedDt: Nullable<string>;
	allowDiscussion: boolean;
} & VisibilityFieldValues;

export type AnnouncementFormProps = {
	onSubmit?: (
		values: AnnouncementFormValues
	) => FormOnSubmitReturn<Announcement>;
	submitText?: string;
	submittingText?: string;
	authors: Identity[];
	groups: Group[];
	initialValues?: AnnouncementFormValues;
	loadStatus?: StatusEnum;
	onDelete?: () => void;
	deleteText?: string;
};

export const AnnouncementForm = (props: AnnouncementFormProps) => {
	const {
		onSubmit,
		submitText,
		submittingText,
		authors,
		groups,
		initialValues,
		loadStatus,
		onDelete,
		deleteText,
	} = props;

	const { t } = useTranslation();
	const verify = useVerifyAccess();
	const canEditAuthor = verify('update', 'announcement');

	const cachedInitialValues = useRef({
		...initialValues,
		image: initialValues?.image ? [initialValues?.image] : null,
		publishedDt: initialValues?.publishedDt
			? DateTime.fromISO(initialValues.publishedDt)
			: null,
	}).current;

	const handleOnSubmit = useCallback(
		async ({
			whenToPost,
			publishedDt,
			visibility,
			visibilityGroups,
			image,
			...restValues
		}) => {
			await sleep(1);

			const values = {
				...restValues,
				whenToPost,
				publishedDt: DateTime.now().toISO(),
				image: image?.[0] ?? null,
				visibility,
				visibilityGroups:
					visibility === 'custom' ? visibilityGroups : [],
			};

			if (whenToPost === 'date-time') {
				values.publishedDt = publishedDt?.isValid
					? publishedDt.toISO()
					: null;
			} else if (whenToPost == 'draft') {
				values.publishedDt = null;
			}

			const result = onSubmit?.(values);

			return Promise.resolve(result).catch(error => error);
		},
		[onSubmit]
	);

	const members = useMemo(
		() =>
			authors.map(({ uuid, first_name, last_name }) => ({
				label: `${first_name} ${last_name}`,
				value: uuid,
			})),
		[authors]
	);

	const whenToPostOptions = useMemo(
		() => [
			{
				value: 'draft',
				label: t('announcement-form.save-as-draft'),
			},
			{
				value: 'now',
				label: t('announcement-form.now'),
			},
			{
				value: 'date-time',
				label: t('announcement-form.schedule-for-date-time'),
			},
		],
		[]
	);

	const validationSchema = useMemo(() => createValidationSchema(t), []);

	if (loadStatus === 'loading') {
		return (
			<Box display='flex' justifyContent='center' mt={4}>
				<CircularProgress message={t('common.loading')} />
			</Box>
		);
	}

	return (
		<Form
			onSubmit={handleOnSubmit}
			validationSchema={validationSchema}
			subscription={{ submitting: true }}
			initialValues={cachedInitialValues}
		>
			{({ handleSubmit, submitting }) => (
				<form onSubmit={handleSubmit} noValidate>
					<FormStack responsive={false}>
						<Box>
							<Grid container spacing={3}>
								<Grid item xs={12} md={6}>
									<Field
										label={t('announcement-form.title')}
										name='title'
										component={TextField}
										type='text'
										placeholder={t(
											'announcement-form.title'
										)}
										fullWidth
										required
									/>
								</Grid>
								<Grid item xs={12} md={6}>
									<Field
										label={t('announcement-form.author')}
										name='author'
										component={SelectField}
										placeholder={t(
											'announcement-form.author'
										)}
										helperText={t(
											'announcement-form.enter-author'
										)}
										fullWidth
										required
										displayEmpty
										disabled={!canEditAuthor}
										loading={authors.length === 0}
									>
										{members.map(member => (
											<SelectItem
												key={member.value}
												value={member.value}
											>
												{member.label}
											</SelectItem>
										))}
									</Field>
								</Grid>
							</Grid>
						</Box>
						<Box>
							<Field
								label={t('announcement-form.description')}
								name='message'
								component={WysiwygField}
								placeholder=''
								fullWidth
								required
							/>
						</Box>
						<Box maxWidth={300}>
							<Field name='image' component={ImagePickerField} />
						</Box>
						<Box
							display='flex'
							flexDirection='column'
							alignItems='flex-start'
						>
							<Field
								label={t('announcement-form.when-to-post')}
								name='whenToPost'
								component={RadioGroupField}
								options={whenToPostOptions}
							/>
							<When field='whenToPost' is='date-time'>
								<Field
									name='publishedDt'
									component={DateTimePickerField}
								/>
							</When>
						</Box>
						<Box>
							<Field
								label={t(
									'announcement-form.allow-members-to-comment'
								)}
								name='allowDiscussion'
								component={CheckboxField}
							/>
						</Box>
						<Box display='flex'>
							<Box flex={{ md: 1 / 2, xs: 1 }}>
								<VisibilityField groups={groups} />
							</Box>
						</Box>
						<ButtonSection
							submitting={submitting}
							submitText={submitText}
							submittingText={submittingText}
							onDelete={onDelete}
							deleteText={deleteText}
						/>
					</FormStack>
				</form>
			)}
		</Form>
	);
};

function createValidationSchema(t: TFunction) {
	const schema = Yup.object()
		.shape({
			title: Yup.string()
				.strict(false)
				.trim()
				.required(
					t('form.required', {
						name: t('announcement-form.title'),
					})
				),
			message: Yup.string().required(
				t('form.required', {
					name: t('announcement-form.description'),
				})
			),
			author: Yup.string().required(t('form.generic-required')),
			publishedDt: Yup.mixed().when('whenToPost', {
				is: 'date-time',
				then: () => {
					return dateFromLuxon().typeError(t('form.invalid-date'));
				},
			}),
		})
		.concat(visibilityFieldValidationSchema('visibility'));

	return schema;
}
