import { styled } from '@mui/material/styles';
import { Tooltip, tooltipClasses, TooltipProps } from 'components/common';

export const EventsTooltipWrapper = styled(
	({ className, ...props }: TooltipProps) => (
		<Tooltip {...props} classes={{ popper: className }} arrow />
	)
)(({ theme }) => ({
	[`& .${tooltipClasses.arrow}`]: {
		color: theme.palette.text.primary,
	},
	[`& .${tooltipClasses.tooltip}`]: {
		padding: theme.spacing(3),
		backgroundColor: theme.palette.text.primary,
		maxWidth: 500,
		minWidth: 400,
	},
}));
