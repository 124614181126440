import { Group, Note } from 'core/types';
import {
	Box,
	Field,
	Form,
	FormOnSubmitReturn,
	FormStack,
	TextField,
	VisibilityField,
	visibilityFieldValidationSchema,
	VisibilityFieldValues,
	WysiwygField,
} from 'components/common';
import { useTranslation } from 'hooks';
import { TFunction } from 'react-i18next';
import * as Yup from 'yup';
import { useCallback, useMemo, useRef } from 'react';
import { sleep } from 'utils/delay';
import { ButtonSection } from 'components/common/form/button-section';

export type NoteFormSubmittedValues = {
	title: string;
	content: string;
} & VisibilityFieldValues &
	VisibilityFieldValues<{
		edit: VisibilityFieldValues['visibility'];
		editGroups: VisibilityFieldValues['visibilityGroups'];
	}>;

export type NoteFormInitialValues = Partial<NoteFormSubmittedValues>;

export type NoteFormProps = {
	onSubmit?: (values: NoteFormSubmittedValues) => FormOnSubmitReturn<Note>;
	submitText?: string;
	submittingText?: string;
	groups: Group[];
	initialValues?: NoteFormInitialValues;
	onDelete?: () => void;
	deleteText?: string;
};

export const NoteForm = (props: NoteFormProps) => {
	const {
		onSubmit,
		submitText,
		submittingText,
		groups,
		initialValues,
		onDelete,
		deleteText,
	} = props;

	const { t } = useTranslation();

	const cachedInitialValues = useRef({
		...initialValues,
	}).current;

	const handleOnSubmit = useCallback(
		async ({
			visibility,
			visibilityGroups,
			edit,
			editGroups,
			...restValues
		}) => {
			await sleep(1);

			const values = {
				...restValues,
				visibility,
				visibilityGroups:
					visibility === 'custom' ? visibilityGroups : [],
				edit,
				editGroups: edit === 'custom' ? editGroups : [],
			};

			const result = onSubmit?.(values);

			return Promise.resolve(result).catch(error => error);
		},
		[onSubmit]
	);

	const validationSchema = useMemo(() => createValidationSchema(t), []);

	return (
		<Form
			onSubmit={handleOnSubmit}
			validationSchema={validationSchema}
			subscription={{ submitting: true }}
			initialValues={cachedInitialValues}
		>
			{({ handleSubmit, submitting }) => (
				<form onSubmit={handleSubmit} noValidate>
					<FormStack>
						<Box>
							<Field
								label={t('note-form.title')}
								name='title'
								component={TextField}
								type='text'
								placeholder={t('note-form.title')}
								fullWidth
								required
							/>
						</Box>
						<Box>
							<Field
								label={t('note-form.content')}
								name='content'
								component={WysiwygField}
								placeholder=''
								fullWidth
								required
								rows={2}
							/>
						</Box>
						<Box>
							<VisibilityField
								name='visibility'
								groups={groups}
								label={t('visibility-field.who-can-see-this')}
							/>
						</Box>
						<Box>
							<VisibilityField
								name='edit'
								groups={groups}
								label={t('visibility-field.who-can-edit-this')}
							/>
						</Box>
						<ButtonSection
							submitting={submitting}
							submitText={submitText}
							submittingText={submittingText}
							onDelete={onDelete}
							deleteText={deleteText}
						/>
					</FormStack>
				</form>
			)}
		</Form>
	);
};

function createValidationSchema(t: TFunction) {
	return Yup.object()
		.shape({
			title: Yup.string()
				.strict(false)
				.trim()
				.required(
					t('form.required', {
						name: t('note-form.title'),
					})
				),
			content: Yup.string().required(
				t('form.required', {
					name: t('note-form.content'),
				})
			),
		})
		.concat(visibilityFieldValidationSchema('visibility'))
		.concat(visibilityFieldValidationSchema('edit'));
}
