import { Identity } from 'core/types';
import { ImageServerConfig, useImageServer } from 'hooks';

export const useAvatarImage = (
	identity: Pick<Identity, 'first_name' | 'last_name' | 'image'>,
	options: ImageServerConfig = {
		w: 32,
		h: 32,
		fit: 'crop',
	}
) => {
	const avatarImage = useImageServer(identity.image, options);

	return {
		src: avatarImage?.url,
		alt: `${identity?.first_name?.[0]} ${identity?.last_name?.[0]}`,
		sx: {
			width: options?.w,
			height: options?.h,
		},
	};
};
