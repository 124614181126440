import { SxProps } from '@mui/system';
import { Theme } from 'theme';

export const borderStyle: SxProps<Theme> = theme => ({
	borderLeft: `1px solid ${theme.secondaryColors.secondary16}`,
	padding: '0px 32px',
});

export const overflowStyle: SxProps = {
	textOverflow: 'ellipsis',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
};

export const volunteerPreferencesBoxStyle: SxProps<Theme> = theme => ({
	color: theme.primaryColors.primary9,
	opacity: '50%',
});

export const avatarPositionStyle: SxProps = {
	width: '56px',
	height: '56px',
	cursor: 'pointer',
};

export const nameStyle: SxProps<Theme> = {
	cursor: 'pointer',
	'& > h6:hover': {
		textDecoration: 'underline',
	},
};

export const groupStyle: SxProps = {
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	overflow: 'hidden',
};

export const headerStyles: SxProps = {
	textAlign: 'center',
};

export const activityStyles: SxProps = {
	display: { xs: 'none', sm: 'block' },
};

export const volunteerPreferenceStyles: SxProps = {
	display: { xs: 'none', md: 'block' },
};

export const cardStyles: SxProps = {
	display: 'flex',
	alignItems: 'center',
	height: '100px',
};
