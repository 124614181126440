import { Community } from 'core/types';
import { useRouter, useTranslation, useVerifyAccess } from 'hooks';
import { RouterLink, Typography, Link } from 'components/common';
import { Trans } from 'react-i18next';
import { FeedCardContent } from '../shared';

export const WelcomeAdminContent = ({
	community,
}: {
	community: Community;
}) => {
	const { t } = useTranslation();
	const router = useRouter();

	const verify = useVerifyAccess();
	const hasAnnouncement =
		community?.has_announcements && verify('create', 'announcement');
	const canCreateNotes = community?.has_documents && verify('create', 'note');
	const canCreateTasks = verify('create', 'task');
	const canInviteMembers = verify('create', 'member');

	return (
		<FeedCardContent
			title={t('community-home.welcome-to-lotsa')}
			content={t(
				'community-home.congratulations-on-creating-your-new-community'
			)}
		>
			<ol>
				{hasAnnouncement ? (
					<li>
						<Trans
							i18nKey='community-home.post-an-announcement'
							components={{
								action: (
									<RouterLink
										to={router.getPathFor(
											'announcement.create',
											{
												communityId: community.slug,
											}
										)}
									/>
								),
							}}
						/>
					</li>
				) : null}
				{canCreateTasks ? (
					<li>
						<Trans
							i18nKey='community-home.add-tasks-need-help-completing'
							components={{
								action: (
									<RouterLink
										to={router.getPathFor(
											'calendar.create',
											{
												communityId: community.slug,
												calendarId:
													community.calendar?.uuid,
											}
										)}
									/>
								),
							}}
						/>
					</li>
				) : null}
				<li>
					{canInviteMembers ? (
						<Trans
							i18nKey='community-home.add-members-using-emails'
							components={{
								action: (
									<RouterLink
										to={router.getPathFor(
											'community.invite',
											{
												communityId: community.slug,
											}
										)}
									/>
								),
							}}
						/>
					) : (
						<Trans
							i18nKey='community-home.add-members-using-emails'
							components={{
								action: (
									<Link
										href={t('community-home.mail-to-link', {
											joinUrl: router.getPathFor(
												'request.root',
												{
													communityId: community.slug,
												}
											),
											communityName: community.name,
										})}
										target='_blank'
									/>
								),
							}}
						/>
					)}
				</li>
				{canCreateNotes ? (
					<li>
						<Trans
							i18nKey='community-home.add-notes-to-document-information'
							components={{
								action: (
									<RouterLink
										to={router.getPathFor('note.create', {
											communityId: community?.slug,
										})}
									/>
								),
							}}
						/>
					</li>
				) : null}
			</ol>
			<Typography variant='body2'>
				{t('community-home.to-delete-this-message')}
			</Typography>
		</FeedCardContent>
	);
};
