import { useServices } from 'core/services';
import { tokenKeys } from 'core/utils/query-key-factory';
import { useQuery } from 'react-query';

export const useGetInvitationTokenStatus = (
	communityId: string,
	tokenId: string
) => {
	const { api } = useServices();

	return useQuery(
		tokenKeys.invitationStatus(communityId, tokenId),
		() =>
			api
				.getInvitationTokenStatus(communityId, tokenId)
				.then(response => response.data),

		{
			enabled: !!communityId && !!tokenId,
			notifyOnChangeProps: 'tracked',
			refetchOnMount: true,
			staleTime: 10000, // 10 seconds
		}
	);
};
