/* eslint-disable react/display-name */
import { forwardRef, useCallback } from 'react';
import { useNavigate, NavigateProps } from 'react-router-dom';
import { Button, ButtonProps } from './button';

export type RouterButtonLinkProps = ButtonProps & {
	to: string;
	state?: NavigateProps['state'];
	replace?: boolean;
};

export const ButtonRouterLink = forwardRef<
	typeof Button,
	RouterButtonLinkProps
>((props, ref) => {
	const { onClick, replace = false, state, to } = props;

	const navigate = useNavigate();

	const handleClick = useCallback(
		e => {
			onClick?.(e);
			navigate(to, { replace, state });
		},
		[navigate, to, replace, state]
	);

	return <Button {...props} onClick={handleClick} ref={ref} />;
});
