import { createContext, useContext } from 'react';
import { Identity } from 'core/types';

export type DiscussionContextValue = {
	discussionId: string;
	enabled: boolean;
	identity: Identity;
	setEnabledToggler: (enabled: boolean) => void;
	isTogglerEnabled: boolean;
};

export const DiscussionContext = createContext<DiscussionContextValue>(
	{} as DiscussionContextValue
);

export const useDiscussion = (): DiscussionContextValue => {
	const discussion = useContext(DiscussionContext);

	if (!discussion) {
		throw new Error(
			'useDiscussion must be used within a DiscussionProvider'
		);
	}

	return discussion;
};
