import { useCallback, useEffect } from 'react';
import { QueryablePromise } from 'utils/async';
import { useConstantValue } from './use-constant-value';

export interface Controller {
	readonly promise: QueryablePromise<any>;
	readonly resolve: (value: any) => void;
	readonly reject: (error: any) => void;
	reset: () => void;
}

function createPromiseController() {
	let resolve: (value: any) => void = () => void 0;
	let reject: (value: any) => void = () => void 0;

	const createPromise = () =>
		new QueryablePromise((resolver, rejecter) => {
			resolve = resolver;
			reject = rejecter;
		});
	let promise: QueryablePromise<any> = createPromise();
	const reset = () => (promise = createPromise());

	return {
		get promise() {
			return promise;
		},
		get resolve() {
			return resolve;
		},
		get reject() {
			return reject;
		},
		reset,
	};
}

type CallbackOptions<T> = {
	onSuccess?: (data: { result: any; values: T }) => void;
	onError?: (error: any, values: T) => void;
	onPaused?: (values: T) => void;
	onSettled?: (values: T) => void;
};

export const useSubmitHandlerForPausedMutation = <T>(
	isPaused: boolean,
	onSubmit: (value: T) => Promise<any>,
	options: CallbackOptions<T> = {},
	deps: any[] = []
) => {
	const pausedController = useConstantValue(() => {
		return createPromiseController();
	});

	useEffect(() => {
		if (isPaused) {
			pausedController.resolve({ status: 'paused', result: null });
			pausedController.reset();
		}
	}, [isPaused, pausedController]);

	const handleOnSubmit = useCallback(
		(values: T) => {
			const submitPromise = onSubmit(values).then(result => {
				return { status: 'success', result };
			});
			return Promise.race([submitPromise, pausedController.promise])
				.then(response => {
					if (response?.status === 'paused') {
						options?.onPaused?.(values);
					}

					options?.onSuccess?.({
						result: response.result,
						values,
					});
				})
				.catch(error => {
					options?.onError?.(error, values);

					if (error instanceof Error) return; // No need to render error.name or error.message in forms with these fields

					throw error;
				})
				.finally(() => {
					options?.onSettled?.(values);
				});
		},
		[pausedController, isPaused, ...deps]
	);

	return handleOnSubmit;
};
