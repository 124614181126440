import { useRef } from 'react';

declare type AnyFunction = (...args: unknown[]) => unknown;

type Value<T> = T extends AnyFunction ? never : T | (() => T);

export const useConstantValue = <T>(value: Value<T>) => {
	const ref = useRef<{ value: T }>();

	if (!ref.current) {
		ref.current = {
			value: typeof value === 'function' ? value() : value,
		};
	}

	return ref.current.value;
};
