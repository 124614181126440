import { Theme } from 'theme';
import { SxProps } from '@mui/system';

export const sectionHeader: SxProps<Theme> = (theme: Theme) => ({
	fontWeight: 'bold',
	color: theme.primaryColors.primary4,
	mb: -5,
});

export const sectionHeaderContainer: SxProps<Theme> = (theme: Theme) => ({
	'> p': {
		fontWeight: 'bold',
		color: theme.primaryColors.primary4,
		mb: -5,
	},
});
