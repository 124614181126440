import {
	Box,
	ButtonRouterLink,
	CircularProgress,
	Icon,
} from 'components/common';
import {
	useCurrentCommunity,
	useParams,
	useRouter,
	useSearchParams,
} from 'hooks';
import { useState } from 'react';
import {
	CommunityMembers,
	CommunityMembersSubmittedValues,
} from 'components/community/team';
import { Identity } from 'core/types';
import { useGetTeamMembers } from 'core/uses-cases/community';
import {
	useCommunityTeamData,
	useDeleteCommunityMemberHandler,
} from 'components/community/hooks';
import { useCurrentIdentity } from 'core/uses-cases/authentication';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import {
	useDeleteCommunityTransfer,
	useGetCommunityTransferRequests,
} from 'core/uses-cases/transfer-request';
import { TeamHeader } from 'components/team';

export const Team = () => {
	const community = useCurrentCommunity();
	const currentIdentity = useCurrentIdentity();
	const { communityId } = useParams() as { communityId: string };
	const [queryParams] = useSearchParams();
	const router = useRouter();

	const { data: transferRequests = [] } =
		useGetCommunityTransferRequests(communityId);

	const hasInitiatedATransfer = transferRequests.length > 0;

	const checkIsOwner = (identity: Identity) => {
		return identity.uuid === community?.owner?.uuid;
	};

	const checkIsCurrentMember = (identity: Identity) => {
		return identity.uuid === currentIdentity?.uuid;
	};

	const deleteMemberHandler = useDeleteCommunityMemberHandler({
		onConfirm: contextData => {
			const { isSelf } = contextData;

			if (isSelf) {
				router.navigate('community.switch');
			}
		},
	});

	const handleOnRemoveMember = (identity: Identity) => {
		if (!identity) return null;

		deleteMemberHandler({
			communityId,
			memberId: identity.uuid,
			isSelf: identity.uuid === currentIdentity?.uuid,
		});
	};

	const handleOnTransfer = () => {
		router.navigate('community.initiate-transfer-request', {
			communityId,
		});
	};

	const cancelTransferRequestMutation = useDeleteCommunityTransfer();

	const handleOnCancelTransfer = () => {
		const createdTransfer = transferRequests.find(
			transfer => !!transfer.sent_dt
		);

		cancelTransferRequestMutation.mutate({
			communityId,
			hasTransferRequests: !!createdTransfer,
		});
	};

	const {
		initialValues,
		showOptions = [],
		sortByOptions,
	} = useCommunityTeamData();

	const role = queryParams.get('role');
	const sortBy = queryParams.get('sort_by');

	const [filter, setFilter] = useState({
		searchTerm: '',
		show: role ? role : initialValues.show || '',
		sortBy: sortBy ? sortBy : initialValues.sortBy || '',
	});

	const { data: members = [], isLoading } = useGetTeamMembers(
		communityId,
		filter
	);

	const handleOnApplyFilter = (filter: CommunityMembersSubmittedValues) => {
		setFilter({
			...filter,
			searchTerm: filter.searchTerm || '',
		});
	};

	const handleOnClickMember = (identity: Identity) => {
		router.navigate('community.member-profile', {
			communityId,
			memberId: identity.uuid,
		});
	};

	return (
		<Box>
			<TeamHeader
				title={t('team.community-members')}
				description={
					<Trans
						i18nKey='team.description'
						components={{ italic: <i /> }}
					/>
				}
				button={
					<ButtonRouterLink
						to={router.getPathFor('community.invite', {
							communityId,
						})}
						startIcon={
							<Icon name='add_circle_outline' fontSize='large' />
						}
						size='small'
					>
						{t('team.add-members')}
					</ButtonRouterLink>
				}
			/>
			{isLoading ? (
				<Box display='flex' justifyContent='center' mt={4}>
					<CircularProgress />
				</Box>
			) : (
				<CommunityMembers
					members={members}
					initialFilter={filter}
					onClickMember={handleOnClickMember}
					onRemoveMember={handleOnRemoveMember}
					onTransferMember={handleOnTransfer}
					onCancelTransfer={handleOnCancelTransfer}
					onApplyFilter={handleOnApplyFilter}
					hasInitiatedATransfer={hasInitiatedATransfer}
					sortByOptions={sortByOptions}
					showOptions={showOptions}
					checkIsOwner={checkIsOwner}
					checkIsCurrentMember={checkIsCurrentMember}
				/>
			)}
		</Box>
	);
};
