import { SxProps } from '@mui/system';
import { Theme } from 'theme';

export const spinnerStyle: SxProps<Theme> = (theme: Theme) => ({
	display: 'inline-flex',
	alignItems: 'center',
	'> img': {
		animation: 'rotation 2s infinite linear',
		marginRight: theme.spacing(1.5),
		marginTop: theme.spacing(-1),
		marginBottom: theme.spacing(-1),
		width: 24,
		height: 24,
	},
});
