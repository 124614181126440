import { useParams } from 'react-router-dom';
import { EntityKey, Nullable, PermissibleItem } from 'core/types';
import { useGetAnnouncement } from 'core/uses-cases/announcement';
import { useGetEvent } from 'core/uses-cases/calendar';
import { useGetNote } from 'core/uses-cases/note';
import { useGetAlbum } from 'core/uses-cases/albums';
import { useGetCommunityGroup } from 'core/uses-cases/group';
import { useGetCommunityMember } from 'core/uses-cases/member';

export const useGetAccessGuardItem = (
	entity: Omit<EntityKey, 'community' | 'album_image' | 'well_wish'>,
	useItem: boolean | undefined
) => {
	const params = useParams();
	const communityId = params.communityId ?? '';

	const { data: announcement, isLoading: announcementIsLoading } =
		useGetAnnouncement(communityId, params.announcementId ?? '');

	const { data: task, isLoading: taskIsLoading } = useGetEvent(
		params.calendarId ?? '',
		params.eventId ?? ''
	);

	const { data: note, isLoading: noteIsLoading } = useGetNote(
		communityId,
		params.noteId ?? ''
	);

	const { data: album, isLoading: albumIsLoading } = useGetAlbum(
		communityId,
		params.albumId ?? ''
	);

	const { data: group, isLoading: groupIsLoading } = useGetCommunityGroup(
		communityId,
		params.groupId ?? ''
	);

	const { data: member, isLoading: memberIsLoading } = useGetCommunityMember(
		communityId,
		params.identityId ?? ''
	);

	const dataMap = {
		announcement,
		task,
		note,
		album,
		group,
		member,
	};

	const item: Nullable<PermissibleItem> = useItem
		? dataMap?.[entity as keyof typeof dataMap] ?? null
		: null;

	return {
		item,
		isLoading:
			announcementIsLoading ||
			taskIsLoading ||
			noteIsLoading ||
			albumIsLoading ||
			groupIsLoading ||
			memberIsLoading,
	};
};
