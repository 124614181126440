import { Identity, Community } from 'core/types';
import { useTranslation } from 'hooks';
import { SyntheticEvent, useCallback, useState } from 'react';
import MuiTab, { TabProps } from '@mui/material/Tab';
import MuiTabs, { TabsProps } from '@mui/material/Tabs';
import {
	Box,
	FormOnSubmitReturn,
	SelectField,
	SelectItem,
} from 'components/common';
import { InviteViaEmailForm } from './invite-via-email-form';
import { InviteViaSmsForm } from './invite-via-sms-form';
import { tabsStyle, tabStyle } from './styles';
import { ShareLinkSection } from './share-link-section';

export type InviteSubmittedValues =
	| {
			method: 'email';
			emails: string[];
			text?: string;
	  }
	| {
			method: 'sms';
			phones: string[];
			text?: string;
	  };

type SetupCommunityMembersProps = {
	identity: Identity;
	community: Community;
	onSubmit?: (values: InviteSubmittedValues) => FormOnSubmitReturn;
};

export const SetupCommunityMembers = (props: SetupCommunityMembersProps) => {
	const [tab, setTab] = useState<number>(0);

	const handleChange = (event: SyntheticEvent, newValue: number) => {
		setTab(newValue);
	};

	const { community, identity, onSubmit } = props;

	const { t } = useTranslation();

	const handleOnSubmit = useCallback((values: InviteSubmittedValues) => {
		const result = onSubmit?.(values);
		return Promise.resolve(result).catch(error => error);
	}, []);

	return (
		<>
			<Box display={{ xs: 'none', sm: 'flex' }}>
				<Tabs value={tab} onChange={handleChange}>
					<Tab
						value={0}
						label={t('community-form.invite-via-email')}
					/>
					{/* <Tab
						value={1}
						label={t('community-form.invite-via-text')}
					/> */}
					<Tab value={2} label={t('community-form.share-link')} />
				</Tabs>
			</Box>
			<Box display={{ xs: 'block', sm: 'none' }}>
				<SelectField
					fullWidth
					value={tab}
					onChange={event => handleChange(event, +event.target.value)}
				>
					<SelectItem value={0}>
						{t('community-form.invite-via-email')}
					</SelectItem>
					{/* <SelectItem value={1}>
						{t('community-form.invite-via-text')}
					</SelectItem> */}
					<SelectItem value={2}>
						{t('community-form.share-link')}
					</SelectItem>
				</SelectField>
			</Box>
			<TabPanel isActive={tab === 0}>
				<InviteViaEmailForm
					key='invite-via-email-form'
					community={community}
					identity={identity}
					onSubmit={handleOnSubmit}
				/>
			</TabPanel>
			<TabPanel isActive={tab === 1}>
				<InviteViaSmsForm
					key='invite-via-sms-form'
					community={community}
					identity={identity}
					onSubmit={handleOnSubmit}
				/>
			</TabPanel>
			<TabPanel isActive={tab === 2}>
				<ShareLinkSection community={community} />
			</TabPanel>
		</>
	);
};

interface TabPanelProps {
	children?: React.ReactNode;
	isActive: boolean;
}

const TabPanel = (props: TabPanelProps) => {
	const { children, isActive, ...rest } = props;

	return (
		<Box role='tabpanel' {...rest}>
			{isActive ? <Box sx={{ mt: 6 }}>{children}</Box> : null}
		</Box>
	);
};

const Tabs = (props: TabsProps) => <MuiTabs sx={tabsStyle} {...props} />;

const Tab = (props: TabProps) => (
	<MuiTab sx={tabStyle} disableRipple {...props} />
);
