import { isCancelledError, useMutation } from 'react-query';
import { v4 as uuid } from 'uuid';
import { Album, AlbumPhoto, Community, Identity } from 'core/types';
import { SetupMutationFn } from 'core/configure/types';
import {
	albumKeys,
	albumPhotoKeys,
	authenticationKeys,
	communityKeys,
} from 'core/utils/query-key-factory';
import { postAlbumMutationKey } from 'core/utils/mutation-key-factory';
import { AlbumFormSubmittedValues } from 'components/albums';
import {
	onPostMutateMultiOptimisticInfinityQueryCache,
	onPostMutateOptimistic,
	PaginatedRecords,
} from 'core/utils/optimistic-utils';
import { Services } from '../../services/types';
import { BaseHttpError } from '../../services/api/errors';
import { formatAlbumGroups } from './use-patch-album';

export interface PostAlbumMutationInput extends AlbumFormSubmittedValues {
	communityId: string;
}

export const usePostAlbum = () => {
	const mutation = useMutation<Album, BaseHttpError, PostAlbumMutationInput>(
		postAlbumMutationKey
	);

	return mutation;
};

type Context = {
	communityId: string;
	previousAlbums: Album[];
	previousAlbumPhotos: PaginatedRecords<AlbumPhoto>;
};

export const setupPostAlbum: SetupMutationFn = (
	services: Services,
	createTrackedParallelMutation,
	mutationTracker
) => {
	const { queryClient, api, uploader } = services;

	const mutation = createTrackedParallelMutation<
		Album,
		BaseHttpError,
		PostAlbumMutationInput,
		Context
	>({
		mutationFn: async ({ communityId, photos, ...input }) => {
			const result = await api
				.postAlbum(communityId, input)
				.then(response => response.data);

			if (result.uuid) {
				for (let i = 0; i < photos.length; i++) {
					const image = await uploader
						.uploadImage(photos[i])
						.catch(() => null);

					if (image) {
						await api
							.postAlbumPhoto(result.uuid, image?.uuid as string)
							.catch(() => null);
					}
				}
			}

			return result;
		},
		onMutate: async input => {
			const {
				communityId,
				name,
				visibility,
				visibilityGroups = [],
				upload,
				uploadGroups = [],
				photos,
			} = input;

			const community = queryClient.getQueryData<Community>(
				communityKeys.detail(communityId)
			);
			const identity = queryClient.getQueryData<Identity>(
				authenticationKeys.identity
			);

			const groupOptions = formatAlbumGroups(community?.groups.all ?? []);

			const now = new Date().toISOString();
			const albumId = uuid();
			const albumPhotos: AlbumPhoto[] = photos.map((photo, index) => ({
				uuid: uuid(),
				album_uuid: albumId,
				community_uuid: communityId,
				created_dt: now,
				discussion_uuid: '',
				discussion_stats: {
					comments: 0,
				},
				owner: null,
				image: photo,
				sort_order: index,
				identity_permissions: [],
				source_entity: null,
				source_uuid: null,
				can_delete: false,
			}));

			const newAlbum: Album = {
				community_uuid: communityId,
				uuid: albumId,
				name,
				identity_uuid: identity?.uuid,
				created_dt: now,
				sort_order: 0,
				type: 'user',
				cover_photo: null,
				settings: {
					visibilitySettings: {
						privacy: visibility,
						groups: visibilityGroups.map(
							groupId => groupOptions[groupId]
						),
					},
					uploadSettings: {
						upload,
						groups: uploadGroups.map(
							groupId => groupOptions[groupId]
						),
					},
				},
				photos: albumPhotos,
				identity_permissions: [],
			};

			const previousAlbums = await onPostMutateOptimistic<Album>(
				queryClient,
				albumKeys.list(communityId),
				newAlbum
			);

			const previousAlbumPhotos =
				(await onPostMutateMultiOptimisticInfinityQueryCache<AlbumPhoto>(
					queryClient,
					albumPhotoKeys.list(communityId),
					albumPhotos.reverse()
				)) as PaginatedRecords<AlbumPhoto>;

			return {
				communityId,
				previousAlbums,
				previousAlbumPhotos,
			};
		},
		onError: (_error, { communityId }, context) => {
			queryClient.setQueryData(
				albumKeys.list(communityId),
				context?.previousAlbums
			);

			queryClient.setQueryData(
				albumPhotoKeys.list(communityId),
				context?.previousAlbumPhotos
			);
		},
		onSettled: (_data, error, _input, context) => {
			if (context && !isCancelledError(error)) {
				mutationTracker.queueInvalidations(
					albumPhotoKeys.list(context.communityId),
					communityKeys.feed(context.communityId)
				);
			}
		},
		retry: 1,
	});

	queryClient.setMutationDefaults(postAlbumMutationKey, mutation);
};
