import classnames from 'classnames';
import { Box } from 'components/common';
import { holderStyle } from './styles';

type BadgeHolderProps = React.ComponentProps<typeof Box>;

export const BadgeHolder = ({ className, ...rest }: BadgeHolderProps) => {
	return (
		<Box
			className={classnames('badge-holder', className)}
			sx={holderStyle}
			{...rest}
		/>
	);
};
