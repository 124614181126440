import { SxProps } from '@mui/system';
import { Theme } from 'theme';
import people from 'assets/images/people.svg?url';

export const contentStyle: SxProps<Theme> = theme => ({
	paddingTop: theme.spacing(8),
	paddingBottom: theme.spacing(8),
	flex: 1,
});

export const contentBackgroundStyle: SxProps<Theme> = theme => ({
	backgroundImage: `url(${people})`,
	backgroundRepeat: 'no-repeat',
	backgroundPosition: 'center 101%',
	backgroundSize: '66% auto',
	[theme.breakpoints.down('sm')]: {
		paddingBottom: '26%',
		backgroundSize: '92% auto',
	},
	minHeight: {
		xs: 625,
		sm: 700,
		md: 580,
		lg: 750,
		xl: 800,
	},
});
