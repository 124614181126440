import { PageHeading, Typography, Grid, Box, Paper } from 'components';
import { useTranslation } from 'react-i18next';

export const DeleteEmailSent = () => {
	const { t } = useTranslation();

	return (
		<Box>
			<PageHeading title={t('delete-email-sent.title')} />
			<Grid container justifyContent='center'>
				<Grid item xl={6} sm={8} xs={12}>
					<Box
						component={Paper}
						px={4.5}
						py={3}
						elevation={0}
						display='flex'
						flexDirection='column'
					>
						<Typography variant='body2'>
							{t('delete-email-sent.description')}
						</Typography>
						<Typography variant='body2' mt={1}>
							{t('delete-email-sent.description-two')}
						</Typography>
						<Typography variant='body2' mt={3} textAlign='center'>
							{t('delete-email-sent.description-four')}
						</Typography>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
};
