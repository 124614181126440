import { useTranslation } from 'react-i18next';
import { useCalendar } from 'components/calendar/context';
import { EventRow, useEventsForAgenda } from 'components/calendar/shared';
import { CalendarEvent } from 'components/calendar/types';
import { Box, CircularProgress, Grid, Typography } from 'components/common';
import { DateTime } from 'luxon';
import { Fragment } from 'react';
import { hasSameDay } from 'components';
import { timeRowStyle, monthTitleSectionStyles } from './styles';

type AgendaGridProps = {
	startDate: DateTime;
	endDate: DateTime;
};

type AgendaRowProps = {
	date: DateTime;
	events: CalendarEvent[];
};

export const AgendaGrid = ({ startDate, endDate }: AgendaGridProps) => {
	const { formatDate, loadEventsStatus } = useCalendar();
	const { t } = useTranslation();

	const groupedEvents = useEventsForAgenda(startDate, endDate);

	if (loadEventsStatus === 'loading') {
		return (
			<Box textAlign='center' paddingY={4}>
				<CircularProgress />
			</Box>
		);
	}

	if (loadEventsStatus === 'success' && groupedEvents.length === 0) {
		return (
			<Box textAlign='center' paddingY={4}>
				<Typography component='p' variant='caption' mb={2}>
					{t('calendar-widget.no-events-range')}
				</Typography>
				<Typography component='p' variant='caption'>
					{t('calendar-widget.select-different-or-adjust')}
				</Typography>
			</Box>
		);
	}

	return (
		<Fragment>
			{groupedEvents.map(row => {
				const { month, dates } = row;

				const hasEvents = dates.some(date => date.events.length > 0);

				if (!hasEvents) return null;

				return (
					<Fragment
						key={`agenda-grid-${formatDate(month, 'MMMM yyyy')}`}
					>
						<Box sx={monthTitleSectionStyles}>
							<Typography variant='button1'>
								{formatDate(month, 'MMMM yyyy')}
							</Typography>
						</Box>
						{dates.map(date => (
							<AgendaRow
								key={`agenda-row-week-${formatDate(
									date.date,
									'yyyy-MM-dd HH:mm:ss'
								)}`}
								date={date.date}
								events={date.events}
							/>
						))}
					</Fragment>
				);
			})}
		</Fragment>
	);
};

export const AgendaRow = ({ date, events }: AgendaRowProps) => {
	const { onEventClick, formatDate } = useCalendar();
	const { t } = useTranslation();

	return (
		<Fragment>
			<Grid container item spacing={0} sx={timeRowStyle}>
				<Grid item xs={1.5}>
					<Typography variant='caption'>
						{formatDate(date, 'EEE, MMM d')}
					</Typography>
				</Grid>
				<Grid container item xs={10.5}>
					{events.map((event: CalendarEvent) => (
						<Grid container key={event.uuid}>
							<Grid item xs={2}>
								<Typography variant='caption'>
									{!event.startDateTime ? (
										t('calendar-widget.all-day')
									) : (
										<>
											{formatDate(
												event.startDateTime,
												'h:mm a'
											)}
											{
												event.endDateTime &&
												hasSameDay(
													event.startDateTime,
													event.endDateTime
												) ? (
														` - ${formatDate(
															event.endDateTime,
															'h:mm a'
														)}`
													) : null // prettier-ignore
											}
										</>
									)}
								</Typography>
							</Grid>
							<Grid item xs={7}>
								<EventRow
									event={event}
									onClick={onEventClick}
								/>
							</Grid>
							<Grid item xs={3}>
								{event.volunteersAvailable > 0 && (
									<Box>
										<Typography
											fontWeight={600}
											variant='caption'
										>
											{t(
												'calendar-widget.volunteer-needed',
												{
													count: event.volunteersAvailable,
												}
											)}
										</Typography>
									</Box>
								)}
								<Typography variant='caption'>
									{event.volunteers
										.map(
											volunteer =>
												`${volunteer.first_name} ${volunteer.last_name}`
										)
										.join(', ')}
								</Typography>
							</Grid>
						</Grid>
					))}
				</Grid>
			</Grid>
		</Fragment>
	);
};
