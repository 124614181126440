import { MutationObserverOptions, QueryClient, QueryKey } from 'react-query';

type CallbackInvalidation = () => void;

export const createMutationTracker = (queryClient: QueryClient) => {
	let mutationNumber = 0;
	const invalidationStack = new Map<string, CallbackInvalidation>();

	return {
		startOne: () => {
			mutationNumber += 1;
		},
		endOne: () => {
			if (mutationNumber > 0) {
				mutationNumber -= 1;
			}
		},
		allEnded: () => mutationNumber === 0,

		queueInvalidations: (...queryKeys: QueryKey[]) => {
			queryKeys.forEach(queryKey => {
				const hash = JSON.stringify(queryKey);

				if (invalidationStack.has(hash)) return;

				invalidationStack.set(hash, () => {
					queryClient.invalidateQueries(queryKey);
				});
			});
		},

		flushInvalidationQueue: () => {
			invalidationStack.forEach(fn => fn());
			invalidationStack.clear();
		},
	};
};

export type MutationTracker = ReturnType<typeof createMutationTracker>;

export const createTrackedParallelMutationCreator = (mutationTracker: MutationTracker) => <
	D = unknown,
	E = unknown,
	V = void,
	C = unknown
>(config: MutationObserverOptions<D, E, V, C>) => {
	const setup: MutationObserverOptions<D, E, V, C> = {
		...config,
		onMutate: (input) => {
			mutationTracker.startOne();
			return config.onMutate?.(input);
		},
		onSettled: (data, error, variables, context) => {
			const result = config.onSettled?.(data, error, variables, context);
			mutationTracker.endOne();
			if (mutationTracker.allEnded()) {
				mutationTracker.flushInvalidationQueue();
			}
			return result;
		},
	};

	return setup;
}; // prettier-ignore
