import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { ApiConfig } from '../types';
import { HttpClientInterface } from './types';

/**
 * Manages all requests to the API.
 */
export class BaseHttpClient implements HttpClientInterface {
	/**
	 * Axios instance
	 */
	client: AxiosInstance;
	/**
	 * Configurable options.
	 */
	config: ApiConfig;

	/**
	 * Creates the api.
	 *
	 * @param config The configuration to use.
	 */
	constructor(config: ApiConfig) {
		this.config = config;

		// construct the axios instance
		this.client = axios.create({
			baseURL: this.config.url.replace(/\/$/, '') + '/',
			timeout: Number(this.config.timeout ?? 10000),
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
		});
	}

	/**
	 * Passthrought any request here
	 *
	 * @type {AxiosInstance['request']}
	 * @memberof BaseHttpClient
	 */
	request: AxiosInstance['request'] = params => this.client.request(params);

	/**
	 * Wrapper for post requests
	 */
	post: HttpClientInterface['post'] = (
		url: string,
		data?: unknown,
		config?: AxiosRequestConfig
	) => {
		return this.client.request({
			method: 'post',
			url,
			data,
			...prepareConfig({ data, config }),
		});
	};

	/**
	 * Wrapper for get requests
	 */
	get: HttpClientInterface['get'] = (
		url: string,
		params?: unknown,
		config?: AxiosRequestConfig
	) => this.client.request({ method: 'get', url, params, ...config });

	/**
	 * Wrapper for put requests
	 */
	put: HttpClientInterface['put'] = (
		url: string,
		data?: unknown,
		config?: AxiosRequestConfig
	) =>
		this.client.request({
			method: 'put',
			url,
			data,
			...prepareConfig({ data, config }),
		});

	/**
	 * Wrapper for patch requests
	 */
	patch: HttpClientInterface['patch'] = (
		url: string,
		data?: unknown,
		config?: AxiosRequestConfig
	) =>
		this.client.request({
			method: 'patch',
			url,
			data,
			...prepareConfig({ data, config }),
		});

	/**
	 * Wrapper for delete requests
	 */
	delete: HttpClientInterface['delete'] = (
		url: string,
		config?: AxiosRequestConfig
	) => this.client.request({ method: 'delete', url, ...config });
}

function prepareConfig({
	data,
	config = {},
}: {
	data: unknown;
	config?: AxiosRequestConfig;
}): AxiosRequestConfig {
	const isFormData = data instanceof FormData;

	if (!isFormData) {
		return config;
	}

	return {
		...config,
		headers: {
			...config?.headers,
			'Content-Type': 'multipart/form-data' as const,
		},
	};
}
