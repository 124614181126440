import { DateCalendar, DateCalendarProps } from '@mui/x-date-pickers';
import InputLabel from '@mui/material/InputLabel';
import { DateTime } from 'luxon';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { useControlledValue } from 'hooks';
import {
	Paper,
	PaperProps,
	FormControl,
	FormHelperText,
} from 'components/common';
import { OnErrorScrollPoint } from 'components/common/on-error-scroll-point';
import { useState } from 'react';
import { PickerSelectionState } from '@mui/x-date-pickers/internals';
import { TextFieldProps } from '../../text-field';

export type DatePickerCalendarMultipleProps = Omit<
	DateCalendarProps<DateTime>,
	'value' | 'onChange' | 'renderInput'
> &
	Omit<
		TextFieldProps,
		| 'value'
		| 'onChange'
		| 'multiline'
		| 'type'
		| 'rows'
		| 'maxRows'
		| 'minRows'
		| 'select'
		| 'SelectProps'
		| 'type'
		| 'defaultValue'
		| 'placeholder'
	> & {
		value: DateTime[];
		onChange?: (values: DateTime[]) => void;
		PaperProps?: Partial<PaperProps>;
	};

const WeekPickerDay = (
	props: PickersDayProps<DateTime> & { selectedDays?: DateTime[] }
) => {
	const {
		selectedDays = [],
		day,
		outsideCurrentMonth,
		...pickersDayProps
	} = props;

	const isSelected = selectedDays.some(
		value => value.toISODate() === day.toISODate()
	);

	return (
		<PickersDay
			{...pickersDayProps}
			outsideCurrentMonth={outsideCurrentMonth}
			day={day}
			selected={isSelected}
		/>
	);
};

export const DatePickerCalendarMultipleField = (
	props: DatePickerCalendarMultipleProps
) => {
	const {
		required,
		label,
		InputLabelProps,
		helperText,
		value,
		onChange,
		disabled,
		error,
		fullWidth,
		focused,
		hiddenLabel,
		PaperProps,
		size,
		...rest
	} = props;

	const [innerValue, setInnerValue] = useState<DateTime | null>(null);
	const [values, setValues] = useControlledValue<DateTime[]>(value, onChange);

	const handleChange = (
		value: DateTime | null,
		selectionState: PickerSelectionState | undefined
	) => {
		setInnerValue(value);

		if (selectionState === 'finish') {
			if (!value) {
				setValues([]);
				return;
			}

			const dates = [...values];
			const index = dates.findIndex(
				date => date.toISODate() === value.toISODate()
			);
			if (index >= 0) {
				dates.splice(index, 1);
			} else {
				dates.push(value);
			}
			setValues(dates);
		}
	};

	const controlProps = {
		fullWidth,
		focused,
		hiddenLabel,
		error,
		required,
		size,
	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { onBlur, onFocus, ...pickerProps } = rest;

	return (
		<FormControl
			{...controlProps}
			sx={{ display: { xs: 'block', sm: 'flex' } }}
		>
			<OnErrorScrollPoint name={rest.name || ''} />
			{label && <InputLabel {...InputLabelProps}>{label}</InputLabel>}
			<Paper
				{...PaperProps}
				sx={{
					alignSelf: 'flex-start',
					marginLeft: { xs: -4, sm: 0 },
					minWidth: 320,
				}}
			>
				<DateCalendar
					{...pickerProps}
					value={innerValue}
					onChange={handleChange}
					openTo='day'
					disabled={disabled}
					slots={{
						day: WeekPickerDay,
					}}
					slotProps={{
						day: {
							selectedDays: values,
						} as any,
					}}
					views={['month', 'day']}
				/>
			</Paper>
			{helperText && (
				<FormHelperText sx={{ mt: 1 }}>{helperText}</FormHelperText>
			)}
		</FormControl>
	);
};
