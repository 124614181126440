import { useCallback, useEffect, useRef, useState } from 'react';
import Chip from '@mui/material/Chip';
import { usePrevious } from 'hooks/use-previous';
import classnames from 'classnames';
import InputBase, { InputBaseProps } from '@mui/material/InputBase';
import { Box } from '../layout';
import { inputStyle } from './styles';

export interface MultiChipsInputProps
	extends Omit<InputBaseProps, 'onChange' | 'onError'> {
	value: string[];
	placeholder?: string;
	onChange: (value: string[]) => void;
	dataValidation: (value: string) => boolean;
	parseInput?: (value: string) => string;
	autoFocus?: boolean;
	spinner?: () => React.ReactNode;
	delimiter?: string;
	error: boolean | undefined;
	active: boolean | undefined;
	maskPlaceholder?: string;
}

/**
 * This component is mostly based on this library implementation:
 * https://github.com/axisj/react-multi-email
 */
export const MultiChipsInput = (props: MultiChipsInputProps) => {
	const {
		value = [],
		parseInput,
		onChange,
		dataValidation,
		disabled,
		onBlur,
		onFocus,
		delimiter = '[ ,;]',
		onKeyUp,
		onKeyDown,
		autoFocus,
		placeholder,
		error,
		active,
		maskPlaceholder = '',
		...rest
	} = props;
	const emailInputRef = useRef<HTMLInputElement>(null);

	const [values, setValues] = useState(() =>
		(value || []).filter(dataValidation)
	);

	const prevValues = usePrevious(values);
	const [inputValue, setInputValue] = useState('');
	const [internalError, setInternalError] = useState<string | null>(null);

	useEffect(() => {
		if (prevValues !== value) {
			setValues((value || []).filter(dataValidation));
		}
	}, [prevValues, value]);

	useEffect(() => onChange?.(values), [onChange, values]);

	const handleAddValue = useCallback(
		(value: string, isEnter: boolean) => {
			if (internalError) setInternalError(null);

			if (!value) return;

			const re = new RegExp(delimiter, 'g');

			if (re.test(value)) {
				const splitData = value.split(re).filter(n => {
					return n !== '' && n !== undefined && n !== null;
				});

				const setArr = [...new Set(splitData)];

				const validEmails = setArr.reduce((carry, email) => {
					if (dataValidation(email)) carry.push(email);
					return carry;
				}, [] as string[]);

				const hasErrors = validEmails.length !== setArr.length;

				setValues([...new Set([...values, ...validEmails])]);

				if (hasErrors) {
					setInternalError('Invalid email');
				} else {
					setInputValue('');
				}
			} else if (isEnter) {
				const cleanValue = value.trim();

				const isValid = dataValidation(cleanValue);

				if (isValid) {
					const parsedValue = parseInput?.(cleanValue) ?? cleanValue;

					setValues([...new Set([...values, parsedValue])]);
					setInputValue('');
				} else {
					setInternalError('Invalid email');
				}
			}
		},
		[internalError, values, dataValidation]
	);

	const handleDelete = useCallback(itemIndex => {
		setValues(prevValues =>
			prevValues.filter((_, index) => index !== itemIndex)
		);
	}, []);

	const handleOnKeydown = useCallback(
		(e: React.KeyboardEvent<HTMLInputElement>) => {
			onKeyDown?.(e);

			if (e.key === 'Enter' || e.key === 'Tab') {
				e.preventDefault();
			} else if (e.key === 'Backspace' && !e.currentTarget.value) {
				handleDelete(values.length - 1);
			}
		},
		[values, handleDelete, onKeyDown]
	);

	const handleOnKeyup = useCallback(
		(e: React.KeyboardEvent<HTMLInputElement>) => {
			onKeyUp?.(e);
			if (e.key === 'Enter' || e.key === 'Tab') {
				handleAddValue(e.currentTarget.value, true);
			}
		},
		[handleAddValue, onKeyUp]
	);

	const handleOnChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const { value } = event.target;
			setInputValue(value);
			handleAddValue(value, false);
		},
		[handleAddValue, setInputValue]
	);

	const handleOnBlur = useCallback(
		(
			e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
		) => {
			handleAddValue(e.currentTarget.value, true);
			onBlur?.(e);
		},
		[handleAddValue, onBlur]
	);

	const handleOnFocus = useCallback(
		(
			e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
		) => {
			onFocus?.(e);
		},
		[onFocus]
	);

	const classes = classnames({
		error: error,
		disabled: disabled,
		focused: active,
	});

	const hasValues = values.length > 0 || inputValue.length > 0;

	return (
		<Box
			mt={1}
			className={classes}
			sx={inputStyle}
			onClick={() => emailInputRef.current?.focus()}
		>
			<div
				className='data-labels'
				style={{ display: 'contents', flexWrap: 'inherit' }}
			>
				{values.map((value: string, index: number) => {
					return (
						<Chip
							key={value}
							label={value}
							variant='outlined'
							onDelete={() => handleDelete(index)}
							sx={{ mr: 1, mt: 1, overflow: 'hidden' }}
						/>
					);
				})}
			</div>
			<InputBase
				{...rest}
				inputRef={emailInputRef}
				value={inputValue}
				onFocus={handleOnFocus}
				onBlur={handleOnBlur}
				onChange={handleOnChange}
				onKeyDown={handleOnKeydown}
				onKeyUp={handleOnKeyup}
				autoFocus={autoFocus}
				placeholder={!hasValues ? placeholder : maskPlaceholder}
				sx={internalError ? { color: 'error.main' } : {}}
			/>
		</Box>
	);
};
