import { BaseHttpError } from 'core/services/api/errors';
import { ResetPassword } from 'core/services/api/response-types';
import { oauthTokenKeys } from 'core/utils/query-key-factory';
import { useServices } from 'hooks';
import { useQuery } from 'react-query';

export const useCheckResetPasswordToken = (token: string) => {
	const { api } = useServices();

	const query = useQuery<ResetPassword, BaseHttpError>(
		oauthTokenKeys.resetPassword(token),
		() =>
			api.checkResetPasswordToken(token).then(response => response.data),
		{
			enabled: !!token,
			retry: false,
			refetchOnMount: 'always',
		}
	);

	return query;
};
