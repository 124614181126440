import { useQuery } from 'react-query';
import { BaseHttpError } from 'core/services/api/errors';
import { permissionKeys } from 'core/utils/query-key-factory';
import { useServices } from 'core/services';
import { CommunityPermissions, PermissionsKey, EntityKey } from 'core/types';

export const useGetCommunityPermissions = (
	communityId: string,
	identityId: string,
	options: { enabled: boolean } = { enabled: false }
) => {
	const { api } = useServices();

	const initialPermissions = {
		album: { permissions: {} },
		album_image: { permissions: {} },
		announcement: { permissions: {} },
		community: { permissions: {} },
		group: { permissions: {} },
		member: { permissions: {} },
		note: { permissions: {} },
		task: { permissions: {} },
		well_wish: { permissions: {} },
	};

	return useQuery<CommunityPermissions, BaseHttpError>(
		permissionKeys.list(communityId),
		() =>
			api
				.getCommunityPermissions(communityId, identityId)
				.then(response => {
					const data = response.data;
					const communityPermissions: CommunityPermissions = {
						...initialPermissions,
					};

					data?.forEach(item => {
						item.permissions.forEach(permission => {
							communityPermissions[
								item.resourceType as EntityKey
							].permissions[permission as PermissionsKey] = true;
						});
					});

					return communityPermissions as CommunityPermissions;
				}),
		{
			enabled: !!communityId && !!identityId && options.enabled,
			staleTime: 60000,
			keepPreviousData: true,
		}
	);
};
