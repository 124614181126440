import { useDimensions } from 'hooks';
import { useEffect, useState } from 'react';
import { Theme } from 'theme';
import { SxProps } from '@mui/system';
import { BoxProps } from 'components';

const TRUNCATE_MAX_HEIGHT = 355;

type TruncateContentOptions = {
	enabled?: boolean;
	maxHeight?: number;
};

export const useTruncateContent = ({
	enabled = true,
	maxHeight = TRUNCATE_MAX_HEIGHT,
}: TruncateContentOptions) => {
	const [contentRef, dimensions] = useDimensions<HTMLDivElement>();
	const [visible, setVisible] = useState(true);

	const renderLink = (dimensions?.height ?? 0) >= maxHeight;

	useEffect(() => {
		if (enabled && renderLink) {
			setVisible(false);
		}
	}, [dimensions]);

	const toggleVisible = () => {
		setVisible(prev => !prev);
	};

	const wrapperProps: BoxProps = {
		ref: contentRef,
		sx: !visible ? shadowStyle : {},
		maxHeight: !visible ? maxHeight : 'unset',
		overflow: !visible ? 'hidden' : 'visible',
	};

	return {
		renderLink: enabled && renderLink,
		visible,
		toggleVisible,
		contentRef,
		wrapperProps,
	};
};

const shadowStyle: SxProps<Theme> = theme => ({
	position: 'relative',

	'&:before': {
		content: '""',
		width: '100%',
		height: 100,
		position: 'absolute',
		left: 0,
		bottom: 0,
		background: `linear-gradient(transparent 10px, ${theme.palette.common.white})`,
	},
});
