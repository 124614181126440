import { Heading, Grid } from 'components/common';
import {
	EditAccountForm,
	EditAccountFormSubmittedValues,
} from 'components/account';
import { usePatchIdentity } from 'core/uses-cases/identity';
import {
	useServices,
	useSubmitHandlerForPausedMutation,
	useTranslation,
} from 'hooks';
import { useIdentityFormData } from 'components/account/hooks/use-identity-form-data';

export const EditAccount = () => {
	const { t } = useTranslation();
	const { notification } = useServices();
	const { mutateAsync, isPaused } = usePatchIdentity();

	const { initialValues, identityId } = useIdentityFormData();

	const handleOnSubmit =
		useSubmitHandlerForPausedMutation<EditAccountFormSubmittedValues>(
			isPaused,
			values => {
				const phone = `+${values.phone.dialCode}${values.phone.number}`;

				return mutateAsync({
					identityId,
					...values,
					phone,
				});
			},
			{
				onSuccess: () => {
					notification.show('success', t('account.profile-updated'));
				},
			},
			[identityId]
		);

	return (
		<Grid container>
			<Grid item xs={12}>
				<Heading variant='h4' mb={6}>
					{t('account.my-Account-information')}
				</Heading>
			</Grid>
			<Grid item xs={12}>
				<EditAccountForm
					submitText={t('account.update-profile')}
					submittingText={t('account.updating')}
					submitPasswordText={t('account.update-password')}
					submittingPasswordText={t('account.updating')}
					initialValues={initialValues}
					onSubmit={handleOnSubmit}
				/>
			</Grid>
		</Grid>
	);
};
