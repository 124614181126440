import { useTranslation } from 'hooks';
import { FooterActionProvider } from 'components/common/footer-action';
import { Container, Box, Typography } from '../../../common';
import { FooterLogo } from './logo';
import { FooterMenu } from './menu';

export const Footer = () => {
	const { t } = useTranslation();

	return (
		<FooterActionProvider>
			<Box className='footer'>
				<Box bgcolor={theme => theme.secondaryColors.secondary3}>
					<Container maxWidth='lg'>
						<Box
							display='flex'
							flexDirection={{ xs: 'column', md: 'row' }}
							justifyContent={{ xs: 'center' }}
							alignContent='center'
							px={2}
							py={4.5}
						>
							<FooterLogo />
							<FooterMenu />
						</Box>
					</Container>
				</Box>
				<Box
					bgcolor={theme => theme.secondaryColors.secondary4}
					paddingY={1.5}
				>
					<Container maxWidth='lg'>
						<Box display='flex' justifyContent='center'>
							<Typography
								variant='body1'
								color={theme => theme.blacks[0.6]}
							>
								{`© ${new Date().getFullYear()} ${t(
									'footer.lotsa-helping-hands'
								)}`}
							</Typography>
						</Box>
					</Container>
				</Box>
			</Box>
		</FooterActionProvider>
	);
};
