import { BaseHttpError } from 'core/services/api/errors';
import { useNavigate } from 'hooks';
import { useEffect, useRef } from 'react';

export type ErrorHandlerType = (
	error?: BaseHttpError | Error | null,
	options?: {
		redirectTo?: string;
	}
) => void;

export const useErrorHandler: ErrorHandlerType = (
	error,
	{ redirectTo } = {}
) => {
	const errorCaught = useRef(false);
	const navigateTo = useNavigate();

	useEffect(() => {
		if (error && !errorCaught.current) {
			errorCaught.current = true;

			if (redirectTo) {
				navigateTo(redirectTo, { replace: true });
			}
		}
	}, [error, errorCaught.current, redirectTo]);

	return null;
};
