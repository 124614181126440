import { Box, CircularProgress, PageHeading, Paper } from 'components/common';
import {
	useParams,
	useTranslation,
	useRouter,
	useErrorHandler,
	useSubmitHandlerForPausedMutation,
} from 'hooks';
import {
	DuplicateEventForm,
	EventPermissionSection,
	BasicEventInfo,
	DuplicateEventFormSubmittedValues,
} from 'components/events';
import { Identity } from 'core/types';
import { useDuplicateEvent, useGetEvent } from 'core/uses-cases/calendar';
import { useCurrentIdentity } from 'core/uses-cases/authentication';

export const CopyEvent = () => {
	const { t } = useTranslation();
	const currentIdentity = useCurrentIdentity();
	const router = useRouter();

	const { communityId, calendarId, eventId } = useParams() as {
		communityId: string;
		calendarId: string;
		eventId: string;
	};

	const {
		data: event,
		isLoading,
		isSuccess,
		error,
	} = useGetEvent(calendarId, eventId);

	const mutation = useDuplicateEvent();

	const handleOnSubmit =
		useSubmitHandlerForPausedMutation<DuplicateEventFormSubmittedValues>(
			mutation.isPaused,
			values =>
				mutation.mutateAsync({
					baseEventId: eventId,
					calendarId,
					dates: values.dates,
				}),
			{
				onSuccess: () =>
					router.navigate('community.calendar', {
						communityId,
						calendarId,
					}),
			},
			[communityId]
		);

	useErrorHandler(error, {
		redirectTo: router.getPathFor('community.calendar', {
			communityId,
			calendarId,
		}),
	});

	if (!event) return null;

	return (
		<Box>
			{isLoading && (
				<Box display='flex' justifyContent='center' mt={4}>
					<CircularProgress message={t('common.loading')} />
				</Box>
			)}
			{isSuccess && event && (
				<>
					<PageHeading title={t('event-copy.duplicate-task')} />
					<Box>
						<Box component={Paper} elevation={0} p={3}>
							<BasicEventInfo
								event={event}
								identity={currentIdentity as Identity}
							/>
							<Box ml={9} mt={2}>
								<EventPermissionSection
									event={event}
									variant='event-copy'
								/>
							</Box>
						</Box>
					</Box>
					<Box mt={5}>
						<PageHeading title={t('event-copy.add-dates')} />
						<Box>
							<Box component={Paper} elevation={0} p={3}>
								<DuplicateEventForm
									submitText={t('event-copy.submit-text')}
									submittingText={t(
										'event-copy.submitting-text'
									)}
									event={event}
									onSubmit={handleOnSubmit}
								/>
							</Box>
						</Box>
					</Box>
				</>
			)}
		</Box>
	);
};
