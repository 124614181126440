import { isCancelledError, useMutation } from 'react-query';
import { v4 as uuid } from 'uuid';
import { Group, Community, CommunityMember } from 'core/types';
import { SetupMutationFn } from 'core/configure/types';
import { communityKeys } from 'core/utils/query-key-factory';
import { postCommunityGroupMutationKey } from 'core/utils/mutation-key-factory';
import {
	onErrorOptimisticInInfinityQueryCache,
	onPostMutateOptimisticInfinityQueryCache,
	onSuccessOptimisticInInfinityQueryCache,
} from 'core/utils/optimistic-utils';
import { mapVisibilityWithGroups } from 'core/utils/mapper';
import { Services } from '../../services/types';
import { BaseHttpError } from '../../services/api/errors';
import { GroupInput } from '../../services/api/api-client/types';

export interface PostCommunityGroupMutationInput extends GroupInput {
	communityId: string;
}

export const usePostCommunityGroup = () => {
	const mutation = useMutation<
		Group,
		BaseHttpError,
		PostCommunityGroupMutationInput
	>(postCommunityGroupMutationKey);

	return mutation;
};

type Context = {
	communityId: string;
	groupId: string;
	group: Group;
};

export const setupPostCommunityGroup: SetupMutationFn = (
	services: Services,
	createTrackedParallelMutation,
	mutationTracker
) => {
	const { queryClient, api } = services;

	const mutation = createTrackedParallelMutation<
		Group,
		BaseHttpError,
		PostCommunityGroupMutationInput,
		Context
	>({
		mutationFn: ({ communityId, ...input }) => {
			return api
				.postGroup(communityId, input)
				.then(response => response.data);
		},
		onMutate: async input => {
			const {
				communityId,
				name,
				members: memberIds,
				visibility,
				visibilityGroups,
				management,
				managementGroups,
				openEnrollment,
			} = input;

			const groupId = uuid();

			const community = queryClient.getQueryData<Community>(
				communityKeys.detail(communityId)
			);

			const communityMembers = queryClient.getQueryData<
				CommunityMember[]
			>(communityKeys.members(communityId));

			const members = memberIds.map(memberId => {
				const member = communityMembers?.find(
					m => m.identity.uuid === memberId
				);

				return {
					uuid: member?.identity.uuid ?? '',
					first_name: member?.identity.first_name ?? '',
					last_name: member?.identity.last_name ?? '',
					email: member?.identity.email ?? '',
				};
			});

			const group: Group = {
				uuid: groupId,
				community_id: communityId,
				name,
				description: name,
				members,
				is_hidden: false,
				is_public: false,
				is_system_managed: false,
				has_locked_permissions: false,
				has_open_enrollment: openEnrollment,
				identity_permissions: [],
				permissions: [],
				who_can_see: mapVisibilityWithGroups(
					visibility,
					visibilityGroups,
					community?.groups.all ?? []
				),
				managers: mapVisibilityWithGroups(
					management,
					managementGroups,
					community?.groups.all ?? []
				),
				is_offline: true,
			};

			await onPostMutateOptimisticInfinityQueryCache<Group>(
				queryClient,
				communityKeys.groups(communityId),
				communityKeys.group(communityId, groupId),
				group,
				[],
				false
			);

			return {
				communityId,
				groupId,
				group,
			};
		},
		onSuccess: (result, { communityId }, { groupId }) => {
			onSuccessOptimisticInInfinityQueryCache(
				queryClient,
				communityKeys.groups(communityId),
				communityKeys.group(communityId, groupId),
				groupId,
				result
			);
		},
		onError: (error, { communityId }, context) => {
			if (context && !isCancelledError(error)) {
				onErrorOptimisticInInfinityQueryCache(
					queryClient,
					communityKeys.groups(communityId),
					context.groupId
				);
			}
		},
		onSettled: (data, error, variables, context) => {
			if (context && !isCancelledError(error)) {
				mutationTracker.queueInvalidations(
					communityKeys.detail(context.communityId),
					communityKeys.groups(context.communityId),
					communityKeys.group(variables.communityId, context.groupId)
				);
			}
		},
		retry: 1,
	});

	queryClient.setMutationDefaults(postCommunityGroupMutationKey, mutation);
};
