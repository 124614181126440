import {
	CalendarFilterInput,
	FilterCommunityMembersInput,
} from 'core/services/api/api-client/types';

export const authenticationKeys = {
	identity: 'current-identity',
	checkEmail: (email: string) => ['check-email', email] as const,
	validateJwt: (token: string) => ['validate-jwt', token] as const,
};

export const subscriptionKeys = {
	all: () => ['subscription-preferences'] as const,
	preference: (token: string | { communityId: string; memberId: string }) =>
		[...subscriptionKeys.all(), token] as const,
};

export const communityKeys = {
	all: () => ['communities'] as const,
	lists: () => [...communityKeys.all(), 'lists'] as const,
	list: (type: 'invited' | 'pending' | 'my_communities' = 'my_communities') =>
		[...communityKeys.lists(), type] as const,
	details: () => [...communityKeys.all(), 'detail'] as const,
	detail: (id: string) => [...communityKeys.details(), id] as const,
	publicDetail: (id: string) => ['public-communities', id] as const,
	feed: (id: string) => [...communityKeys.detail(id), 'feed'] as const,
	members: (id: string) => [...communityKeys.detail(id), 'members'] as const,
	member: (id: string, memberId: string) =>
		[...communityKeys.members(id), memberId] as const,
	communitySearch: () => ['community-search'] as const,
	pendingRequests: (communityId: string) =>
		[...communityKeys.detail(communityId), 'pending-requests'] as const,
	pendingInvites: (communityId: string) =>
		[...communityKeys.detail(communityId), 'pending-invites'] as const,
	failedInvites: (communityId: string) =>
		[...communityKeys.detail(communityId), 'failed-invites'] as const,
	declinedInvites: (communityId: string) =>
		[...communityKeys.detail(communityId), 'declined-invites'] as const,
	team: (id: string, input: FilterCommunityMembersInput) =>
		[...communityKeys.members(id), 'team', input] as const,
	groups: (id: string) => [...communityKeys.detail(id), 'groups'] as const,
	group: (id: string, groupId: string) =>
		[...communityKeys.groups(id), 'group', groupId] as const,
	invitations: (communityId: string) =>
		[...communityKeys.detail(communityId), 'invitations'] as const,
	invitation: (communityId: string, invitationId: number) =>
		[...communityKeys.invitations(communityId), invitationId] as const,
	transferRequests: (id: string) =>
		[...communityKeys.detail(id), 'transfer-requests'] as const,
	globalPermissions: (id: string) =>
		[...communityKeys.detail(id), 'global-permissions'] as const,
};

export const permissionKeys = {
	all: (communityId: string) =>
		['communities', communityId, 'globalPermissions'] as const,
	list: (communityId: string) =>
		[...permissionKeys.all(communityId), 'list'] as const,
};

export const announcementKeys = {
	all: (communityId: string) =>
		['communities', communityId, 'announcements'] as const,
	lists: (communityId: string) =>
		[...announcementKeys.all(communityId), 'list'] as const,
	list: (communityId: string, type: 'published' | 'draft' | 'scheduled') =>
		[...announcementKeys.lists(communityId), type] as const,
	details: (communityId: string) =>
		[...announcementKeys.all(communityId), 'detail'] as const,
	detail: (communityId: string, id: string) =>
		[...announcementKeys.details(communityId), id] as const,
};

export const noteKeys = {
	all: (communityId: string) =>
		['communities', communityId, 'notes'] as const,
	list: (communityId: string) =>
		[...noteKeys.all(communityId), 'list'] as const,
	details: (communityId: string) =>
		[...noteKeys.all(communityId), 'detail'] as const,
	detail: (communityId: string, id: string) =>
		[...noteKeys.details(communityId), id] as const,
};

export const wellWishKeys = {
	all: (communityId: string) =>
		['communities', communityId, 'well-wish'] as const,
	list: (communityId: string) =>
		[...wellWishKeys.all(communityId), 'list'] as const,
	details: (communityId: string) =>
		[...wellWishKeys.all(communityId), 'detail'] as const,
	detail: (communityId: string, id: string) =>
		[...wellWishKeys.details(communityId), id] as const,
};

export const albumKeys = {
	all: (communityId: string) =>
		['communities', communityId, 'album'] as const,
	list: (communityId: string) =>
		[...albumKeys.all(communityId), 'list'] as const,
	details: (communityId: string) =>
		[...albumKeys.all(communityId), 'detail'] as const,
	detail: (communityId: string, id: string) =>
		[...albumKeys.details(communityId), id] as const,
};

export const albumPhotoKeys = {
	all: (albumId: string) => ['album', albumId, 'albumPhotos'] as const,
	list: (albumId: string) =>
		[...albumPhotoKeys.all(albumId), 'list'] as const,
	details: (albumId: string) =>
		[...albumPhotoKeys.all(albumId), 'detail'] as const,
	detail: (albumId: string, id: string) =>
		[...albumPhotoKeys.details(albumId), id] as const,
};

export const calendarKeys = {
	exportToken: (calendarId: string) =>
		['calendar-export', calendarId, 'token'] as const,
	view: (calendarId: string) => ['calendar', calendarId, 'view'] as const,
	all: (calendarId: string) => ['calendar', calendarId, 'events'] as const,
	lists: (calendarId: string) =>
		[...calendarKeys.all(calendarId), 'list'] as const,
	list: (calendarId: string, filter: CalendarFilterInput) =>
		[...calendarKeys.lists(calendarId), filter] as const,
	details: (calendarId: string) =>
		[...calendarKeys.all(calendarId), 'detail'] as const,
	detail: (calendarId: string, id: string) =>
		[...calendarKeys.details(calendarId), id] as const,
	upcoming: (calendarId: string) =>
		[...calendarKeys.all(calendarId), 'upcoming'] as const,
};

export const timezoneKeys = {
	list: () => ['timezones', 'list'] as const,
};

export const tokenKeys = {
	all: () => ['tokens'] as const,
	community: (communityId: string, tokenId: string) =>
		[
			...tokenKeys.all(),
			'community',
			communityId,
			'token',
			tokenId,
		] as const,
	invitationStatus: (communityId: string, tokenId: string) =>
		[
			...tokenKeys.all(),
			'community',
			communityId,
			'token',
			tokenId,
			'invitation-status',
		] as const,
	transferRequest: (communityId: string, tokenId: string) =>
		[
			...tokenKeys.all(),
			'community',
			communityId,
			'transfer-request',
			tokenId,
		] as const,
};

export const oauthTokenKeys = {
	all: () => ['oauth-tokens'] as const,
	resetPassword: (token: string) =>
		[...oauthTokenKeys.all(), 'reset-password', token] as const,
};

export const discussionKeys = {
	all: (discussionId: string) => ['discussion', discussionId] as const,
	comments: (discussionId: string) =>
		[...discussionKeys.all(discussionId), 'comments'] as const,
	replies: (discussionId: string, commentId: string) =>
		[
			...discussionKeys.comments(discussionId),
			commentId,
			'replies',
		] as const,
};
