import { BaseHttpError, useServices } from 'core/services';
import { transformCommunityMember } from 'core/services/api/transformers';
import { CommunityMember } from 'core/types';
import { communityKeys } from 'core/utils/query-key-factory';
import { useQuery } from 'react-query';

export const useGetCommunityMember = (
	communityId: string,
	memberId: string
) => {
	const { api, authentication } = useServices();

	const isLoggedIn = authentication.isAuthenticated();

	return useQuery<CommunityMember, BaseHttpError>(
		communityKeys.member(communityId, memberId),
		() =>
			api
				.getCommunityMember(communityId, memberId)
				.then(response => transformCommunityMember(response.data)),
		{
			enabled: !!communityId && !!memberId && isLoggedIn,
			staleTime: 20000, // keep the data for 20 seconds
		}
	);
};
