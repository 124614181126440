import ReactGA from 'react-ga4';
import { COOKIE_CONSENT_KEY } from 'core/constants';
import { Config } from 'config/types';
import { UaEventOptions } from 'react-ga4/types/ga4';
import { CookieService } from '.';

export const createAnalytics = (config: Config, cookie: CookieService) => {
	const isAllowedToCollect = cookie.get(COOKIE_CONSENT_KEY) === 'true';

	if (config.analytics.measurementId && isAllowedToCollect) {
		ReactGA.initialize(config.analytics.measurementId);
	}

	const logEvent = (options: UaEventOptions) => {
		ReactGA.event(options);
	};

	return { logEvent };
};
