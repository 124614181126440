import { BaseHttpError } from 'core/services/api/errors';
import { Announcement } from 'core/types';
import { useGetAnnouncements } from 'core/uses-cases/announcement';
import { useEffect, useMemo, memo } from 'react';
import { MiniAnnouncementList } from './mini-list';

export type AnnouncementListByType = {
	communityId: string;
	type: 'draft' | 'scheduled';
	title: string | ((data: Announcement[]) => string);
	onEdit?: (announcement: Announcement) => void;
	onDelete?: (announcement: Announcement) => void;
	onSettled?: (
		type: AnnouncementListByType['type'],
		data: Announcement[],
		error: BaseHttpError | null
	) => void;
};

export const AnnouncementListByType = memo(
	({
		communityId,
		type,
		title,
		onEdit,
		onSettled,
		...props
	}: AnnouncementListByType) => {
		const {
			isError,
			isSuccess,
			data: queryData,
			error,
		} = useGetAnnouncements(communityId as string, type, {
			enable: !!communityId,
			loadAllPages: true,
		});

		const hasData = useMemo(
			() => isSuccess && (queryData?.pages?.[0]?.length ?? 0) > 0,
			[queryData, isSuccess, isError]
		);

		const data = useMemo(() => {
			const data =
				queryData?.pages.reduce((prev, page) => {
					return [...prev, ...page];
				}, []) ?? [];

			return data.reverse();
		}, [queryData]);

		const renderedTitle = useMemo(() => {
			if (typeof title === 'string') {
				return title;
			}

			return title(data);
		}, [data]);

		useEffect(() => {
			if (isSuccess || isError) {
				onSettled?.(type, data, error);
			}
		}, [isSuccess, isError, error]);

		if (!hasData) return null;

		return (
			<MiniAnnouncementList
				data={data}
				title={renderedTitle}
				onClick={onEdit}
				{...props}
			/>
		);
	}
);
