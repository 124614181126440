import * as Sentry from '@sentry/react';
import { ErrorBoundaryProps } from '@sentry/react/types/errorboundary';
import { BrowserTracing } from '@sentry/tracing';
import { Config } from 'config/types';
import { Identity } from 'core/types';
import { authenticationKeys } from 'core/utils/query-key-factory';
import { QueryClient } from 'react-query';

export const createErrorLogger = (config: Config, queryClient: QueryClient) => {
	const setUser = (user: Identity | null) => {
		if (config.errorLogger.dsn) {
			Sentry.setUser(user);
		}
	};

	if (config.errorLogger.dsn) {
		Sentry.init({
			dsn: config.errorLogger.dsn,
			environment: config.errorLogger.environment,
			integrations: [new BrowserTracing(), new Sentry.Replay()],
			tracesSampleRate: 0.0,
			replaysSessionSampleRate: 0.0,
			replaysOnErrorSampleRate: 1.0,
		});

		const identity =
			queryClient.getQueryData<Identity>(authenticationKeys.identity) ??
			null;

		setUser(identity);
	}

	return {
		setUser,
	};
};

export const ErrorBoundary = Sentry.ErrorBoundary;

export type { ErrorBoundaryProps };
