import { useCallback } from 'react';
import { PickerFile, Backdrop } from 'components/common';
import { useRouter, useSubmitHandlerForPausedMutation } from 'hooks';
import { AlbumPhoto } from 'core/types';
import { useDeletePhotoHandler } from 'components/photos';
import { useGetAlbumPhotos, usePostAlbumPhoto } from 'core/uses-cases/photo';
import { EmptyPhotoList } from './empty-list';
import { PhotoList } from './list';

export type AlbumPhotoListProps = {
	albumId: string;
	communityId: string;
	canUploadPhoto: boolean;
};

export const AlbumPhotoListContainer = ({
	canUploadPhoto,
	communityId,
	albumId,
}: AlbumPhotoListProps) => {
	const queryResult = useGetAlbumPhotos(albumId);

	const router = useRouter();

	const handleOnClick = useCallback(
		(photo: AlbumPhoto) => {
			router.navigate('photo.view', {
				albumId: photo.album_uuid,
				communityId,
				photoId: photo.uuid,
			});
		},
		[communityId]
	);

	const { open: deletePhotoHandler, isDeletingPhoto } = useDeletePhotoHandler(
		{
			onConfirm: input => {
				if (input?.lastPhoto) {
					router.navigate('community.albums', {
						communityId,
					});
				}
			},
		}
	);

	const handleOnDelete = useCallback(
		(photo: AlbumPhoto) => {
			deletePhotoHandler({
				communityId,
				photoId: photo.uuid,
				albumId: photo.album_uuid,
				lastPhoto: queryResult.data?.pages[0].length === 1,
			});
		},
		[communityId, queryResult.data]
	);

	const { mutateAsync, isPaused } = usePostAlbumPhoto();

	const handleOnChange = useSubmitHandlerForPausedMutation<PickerFile[]>(
		isPaused,
		values => {
			return mutateAsync({
				albumId,
				image: values[0],
			});
		},
		{},
		[albumId]
	);

	return (
		<>
			<PhotoList
				{...queryResult}
				onClick={handleOnClick}
				onChange={handleOnChange}
				onDelete={handleOnDelete}
				canUploadPhoto={canUploadPhoto}
				emptyState={<EmptyPhotoList />}
			/>
			<Backdrop open={isDeletingPhoto} />
		</>
	);
};
