import { Box, Typography } from 'components/common';
import { Event, EventSetting } from 'core/types';
import { useTranslation } from 'hooks';
import {
	permissionSectionStyleForCopy,
	permissionSectionStyleForDetails,
} from './styles';

export type EventPermissionSectionProps = {
	event: Event;
	variant?: 'event-detail' | 'event-copy';
};

export const EventPermissionSection = (props: EventPermissionSectionProps) => {
	const { event, variant = 'event-detail' } = props;
	const { t } = useTranslation();

	const renderPermission = (setting: EventSetting) => {
		const visibility = setting.privacy;

		if (visibility === 'members') {
			return t('permissions.members');
		}

		if (visibility === 'only_admins') {
			return t('permissions.only_admins');
		}

		// Custom
		return setting.groups.map(group => group.name).join(', ');
	};

	return (
		<Box
			sx={
				variant === 'event-detail'
					? permissionSectionStyleForDetails
					: permissionSectionStyleForCopy
			}
		>
			<Typography variant='h3'>{t('event.permissions')}</Typography>
			<Box>
				<Typography variant='h5'>
					{t('event.who-can-view')}:{' '}
					{renderPermission(event.settings.visibilitySettings)}
				</Typography>
				<Typography variant='h5'>
					{t('event.who-can-volunteer')}:{' '}
					{renderPermission(event.settings.volunteerSettings)}
				</Typography>
			</Box>
		</Box>
	);
};
