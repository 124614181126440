import config from 'config';
import { PARTNERS } from 'tenants/registry';
import { getCurrentTenant } from 'tenants/utils';

export const useCurrentPartnerInfo = (fallback = 'lotsa') => {
	const partner = getCurrentTenant() ?? fallback;

	return {
		...PARTNERS[partner],
		contactUrl:
			PARTNERS[partner]?.contactUrl ?? `${config.urls.wordPress}/contact`,
	};
};
