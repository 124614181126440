import { SxProps } from '@mui/system';
import { Theme } from 'theme';

export const languageButtonStyles: SxProps<Theme> = theme => ({
	'&[aria-haspopup="true"]': {
		'&[aria-expanded="true"]': {
			backgroundColor: theme.palette.buttonPrimary.main,
		},
	},
});

export const downCaretStyles: SxProps = () => ({
	marginLeft: '12px',
});
