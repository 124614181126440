import { useServices } from 'core/services';
import { communityKeys } from 'core/utils/query-key-factory';
import { useQuery } from 'react-query';

export const useGetCommunityTransferRequests = (communityId: string) => {
	const { api } = useServices();

	return useQuery(
		communityKeys.transferRequests(communityId),
		() =>
			api
				.getTransferOwnershipRequests(communityId)
				.then(response => response.data),
		{
			enabled: !!communityId,
			notifyOnChangeProps: 'tracked',
			refetchOnMount: true,
		}
	);
};
