import {
	Box,
	CircularProgress,
	Grid,
	PageHeading,
	Typography,
} from 'components';
import {
	JoinCommunityCard,
	SelectEmailForm,
	SelectEmailFormSubmittedValues,
} from 'components/community/request-to-join';
import { useGetPublicCommunity } from 'core/uses-cases/community';
import { useRouter, useServices } from 'hooks';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export const SelectEmail = () => {
	const { communityId } = useParams() as { communityId: string };
	const { data, isLoading } = useGetPublicCommunity(communityId);
	const { t } = useTranslation();
	const { api } = useServices();
	const router = useRouter();

	const handleOnSubmit = useCallback(
		async (values: SelectEmailFormSubmittedValues) => {
			const { email } = values;
			const response = await api.checkEmail(email);

			if (response.data.has_account) {
				router.navigate(
					'request.join',
					{ communityId },
					{
						hasAccount: true,
						email: encodeURIComponent(email),
					}
				);
			} else {
				router.navigate(
					'request.join',
					{ communityId },
					{
						email: encodeURIComponent(email),
					}
				);
			}
		},
		[]
	);

	if (isLoading || !data) {
		return (
			<Box display='flex' justifyContent='center' mt={4}>
				<CircularProgress message={t('common.loading')} />
			</Box>
		);
	}

	return (
		<>
			<PageHeading title={data?.name}>
				<Grid container>
					<Grid item xs={12} md={6}>
						<Typography variant='body2'>
							{t('select-email.private-message')}
						</Typography>
					</Grid>
				</Grid>
			</PageHeading>
			<Grid container spacing={6} mt={0}>
				<Grid item xs={12} md={4}>
					<JoinCommunityCard community={data} />
				</Grid>
				<Grid item xs={12} md={8}>
					<Typography variant='h4'>
						{t('select-email.join-community')}
					</Typography>
					<Typography variant='body2'>
						{t('select-email.enter-email')}
					</Typography>
					<SelectEmailForm onSubmit={handleOnSubmit} />
				</Grid>
			</Grid>
		</>
	);
};
