import {
	Box,
	Divider,
	Paper,
	Typography,
	Grid,
	RouterLink,
	Link,
	CircularProgress,
} from 'components';
import { UpdatePasswordForm } from 'components/account';
import config from 'config';
import { useCheckResetPasswordToken } from 'core/uses-cases/identity';
import { usePatchPassword } from 'core/uses-cases/password/use-patch-password';
import {
	useErrorHandler,
	useRouter,
	useSearchParams,
	useTranslation,
} from 'hooks';
import { useCallback } from 'react';
import { Trans } from 'react-i18next';

export const UpdatePassword = () => {
	const { t } = useTranslation();
	const router = useRouter();
	const [searchParams] = useSearchParams();
	const token = searchParams.get('t') ?? '';

	const { data, isFetching, error } = useCheckResetPasswordToken(token);
	const passwordMutation = usePatchPassword();

	const handleOnSubmit = useCallback(
		async values => {
			await passwordMutation.postAsync({ ...values, token });

			router.navigate('account.login', {}, {}, { replace: true });
		},
		[token]
	);

	useErrorHandler(error, {
		redirectTo: router.getPathFor('account.reset'),
	});

	if (isFetching) {
		return (
			<Box display='flex' justifyContent='center' mt={4}>
				<CircularProgress message={t('common.loading')} />
			</Box>
		);
	}

	return (
		<Grid container justifyContent='center' textAlign='center'>
			<Grid item xs={12} sm={10} md={7} lg={5}>
				<Box component={Paper} p={3}>
					<Typography variant='h5'>
						{t('update-password.update-password')}
					</Typography>
					<Box mt={3}>
						<UpdatePasswordForm
							onSubmit={handleOnSubmit}
							submitText={t('update-password.set-password')}
							submittingText={t(
								'update-password.updating-password'
							)}
							email={data?.email ?? ''}
						/>
					</Box>
					<Divider mt={5} />
					<Box mt={5}>
						<Typography variant='body1'>
							<Trans
								i18nKey='update-password.no-account-message'
								components={{
									search: (
										<RouterLink
											to={router.getPathFor(
												'community.search'
											)}
										/>
									),
									create: (
										<RouterLink
											to={router.getPathFor(
												'community.create'
											)}
										/>
									),
								}}
							/>
						</Typography>
						<Typography variant='body1' mt={2}>
							<Trans
								i18nKey='reset-password.having-trouble'
								components={{
									mail: (
										<Link
											href={`mailto:${config.supportEmail}`}
										/>
									),
								}}
							/>
						</Typography>
					</Box>
				</Box>
			</Grid>
		</Grid>
	);
};
