import {
	PageHeading,
	Box,
	CircularProgress,
	Grid,
	LoadingButton,
	Icon,
	ButtonRouterLink,
} from 'components/common';
import { useDeleteCommunityMemberHandler } from 'components/community/hooks';
import {
	MemberProfileCard,
	MemberRecentActivityCard,
	MemberScheduledTasksCard,
	MemberStatsCard,
} from 'components/community/member-profile';
import { Community } from 'core/types';
import { useCurrentIdentity } from 'core/uses-cases/authentication';
import { useGetCommunityMember } from 'core/uses-cases/member';
import {
	useCurrentCommunity,
	useErrorHandler,
	useParams,
	useRouter,
	useTranslation,
	useVerifyAccess,
} from 'hooks';

export const MemberProfile = () => {
	const { t } = useTranslation();
	const router = useRouter();
	const { communityId, memberId } = useParams() as {
		communityId: string;
		memberId: string;
	};

	const community = useCurrentCommunity() as Community;
	const currentIdentity = useCurrentIdentity();

	const { data, error, isSuccess, isLoading } = useGetCommunityMember(
		communityId,
		memberId
	);

	const deleteMemberHandler = useDeleteCommunityMemberHandler({
		onConfirm: contextData => {
			const { isSelf } = contextData;

			if (isSelf) {
				router.navigate('community.switch');
			} else {
				router.navigate('community.team', {
					communityId,
				});
			}
		},
	});

	const handleOnRemoveMember = () => {
		if (!data) return null;

		deleteMemberHandler({
			communityId,
			memberId: data.identity.uuid,
			isSelf: data.identity.uuid === currentIdentity?.uuid,
		});
	};

	useErrorHandler(error, {
		redirectTo: router.getPathFor('community.team', {
			communityId,
		}),
	});

	const verify = useVerifyAccess();

	const canRemoveMember =
		verify('delete', 'member', data) && memberId !== community.owner?.uuid;

	const itIsMe = memberId === currentIdentity?.uuid;

	const canUpdateMember = verify('update', 'member', data);

	const canSeePrivateInfo = verify('view_private', 'member', data);

	const actions = canUpdateMember ? (
		<ButtonRouterLink
			to={router.getPathFor('community.edit-members', {
				communityId,
				identityId: memberId,
			})}
			size='small'
			color='primary'
			variant='text'
		>
			{t('member-profile.edit-community-profile')}
		</ButtonRouterLink>
	) : null;

	return (
		<>
			<PageHeading
				title={t('member-profile.profile')}
				rightSection={canRemoveMember && (
					<LoadingButton
						onClick={handleOnRemoveMember}
						color='error'
						startIcon={<Icon name='cancel' />}
						size='small'
						loadingIndicator={t(
							itIsMe ? 'member-profile.leaving-community' : 'member-profile.removing-member'
						)}
					>
						{t(itIsMe ? 'member-profile.leave-community' : 'member-profile.remove-member')}
					</LoadingButton>
				)} // prettier-ignore
			/>
			<Grid container spacing={3}>
				<Grid item md={6} lg={8}>
					{isLoading && (
						<Box display='flex' justifyContent='center' mt={4}>
							<CircularProgress message={t('common.loading')} />
						</Box>
					)}
					{isSuccess && data && (
						<MemberProfileCard
							member={data}
							showAdministrativeNotes={canUpdateMember}
							showPrivateInfo={canSeePrivateInfo}
							renderActions={actions}
						/>
					)}
				</Grid>
				<Grid item md={6} lg={4}>
					{isSuccess && data && currentIdentity && (
						<>
							<MemberStatsCard memberStats={data.stats} />
							<MemberRecentActivityCard
								activities={data.recent_activity}
								timezone={currentIdentity.timezone}
							/>
							<MemberScheduledTasksCard
								tasks={data.member_schedule}
								timezone={currentIdentity.timezone}
								community={community}
							/>
						</>
					)}
				</Grid>
			</Grid>
		</>
	);
};
