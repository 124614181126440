import {
	Box,
	Typography,
	RouterLink,
	Link,
	CircularProgress,
} from 'components/common';
import {
	InvitationLoginForm,
	InvitationLoginFormSubmittedValues,
} from 'components/community/invitation';
import config from 'config';
import {
	useParams,
	useTranslation,
	useRouter,
	useLocation,
	useServices,
	useConstantValue,
} from 'hooks';
import { Trans } from 'react-i18next';
import { useTransientStore } from 'store';
import { Navigate } from 'navigation';
import { useLogin } from 'core/uses-cases/authentication';
import { useRespondInvitation } from 'core/uses-cases/invitation';
import { AUTH_INVALID, HttpClientError } from 'core/services/api/errors';
import { useInvitationFlow } from './use-invitation-flow';

export const InvitationLogin = () => {
	const { t } = useTranslation();
	const router = useRouter();
	const { notification } = useServices();
	const { communityId, tokenId } = useParams() as {
		communityId: string;
		tokenId: string;
	};
	const location = useLocation() as {
		state?: { email: string };
	};
	const currentEmail = location.state?.email;
	const store = useTransientStore();
	const enabledFlow = useConstantValue(!store.auth.isLoggedIn); // Only allow if not logged in
	const { token } = useInvitationFlow(communityId, tokenId, enabledFlow);
	const loginMutation = useLogin();
	const acceptMutation = useRespondInvitation();

	const onSubmit = async ({
		email: username,
		password,
	}: InvitationLoginFormSubmittedValues) => {
		try {
			if (!token) return;

			await loginMutation.doLoginAsync({ username, password });

			await acceptMutation.mutateAsync({
				communityId,
				invitationId: token?.invitation_id,
				action: 'accept',
			});

			notification.show(
				'success',
				t('invitation.you-have-accepted-the-invitation-request')
			);

			router.navigate(
				'community.home',
				{ communityId },
				{},
				{ replace: true }
			);
		} catch (error) {
			if (
				error instanceof HttpClientError &&
				error.errorCode === AUTH_INVALID
			) {
				throw { password: t('login.invalid-password') };
			}
		}
	};

	if (!currentEmail) {
		// Invalid navigation state, it needs to come from the select email page
		return (
			<Navigate
				to={router.getPathFor('invitation.join', {
					communityId,
					tokenId,
				})}
				replace
			/>
		);
	}

	if (!token) {
		return (
			<Box display='flex' justifyContent='center' mt={4}>
				<CircularProgress message={t('common.loading')} />
			</Box>
		);
	}

	return (
		<Box>
			<Box mb={4}>
				<Typography>
					<Trans
						i18nKey='invitation.already-have-account'
						components={{
							reset: (
								<RouterLink
									to={router.getPathFor(
										'account.reset',
										{},
										{
											email: currentEmail,
										}
									)}
								/>
							),
						}}
					/>
				</Typography>
			</Box>
			<InvitationLoginForm
				identity={{
					email: currentEmail,
				}}
				onSubmit={onSubmit}
				submitText={t('invitation.accept-invitation')}
				submittingText={t('invitation.accepting-invitation')}
				acceptingText={
					<Trans
						i18nKey='invitation.accept-message'
						components={{
							term: (
								<Link
									href={`${config?.urls?.wordPress}/terms`}
									target='_blank'
								/>
							),
						}}
					/>
				}
			/>
		</Box>
	);
};
