import { useIntersectionObserver, useTranslation } from 'hooks';
import { forwardRef, useRef } from 'react';
import { Button } from '..';
import { Box } from '../layout/box';
import { CircularProgress } from '../progress/progress';
import { containerStyle, endListStyle, listStyle, buttonStyle } from './styles';

export type PaginatedListProps = {
	threshold?: number;
	onReachThreshold: () => void;
	onLoadMore: () => void;
	isFetchingMore: boolean;
	isFetching: boolean;
	hasMore: boolean;
	showButton?: boolean;
	setShowButton?: (show: boolean) => void;
	loadingMoreText?: string;
	loadNewerText?: string;
	children?: React.ReactNode;
};

export const PaginatedList = forwardRef<typeof Box, PaginatedListProps>(
	function ContentList(
		{
			threshold = 0.95,
			onReachThreshold,
			onLoadMore,
			isFetching,
			isFetchingMore,
			hasMore,
			showButton = true,
			setShowButton,
			loadNewerText,
			children,
		},
		ref
	) {
		const { t } = useTranslation();

		const endListRef = useRef(null);

		useIntersectionObserver({
			target: endListRef,
			onIntersect: onReachThreshold,
			enabled: !!hasMore,
			threshold: 0.01,
		});

		const handleOnLoad = () => {
			setShowButton?.(false);
			onLoadMore();
		};

		return (
			<Box ref={ref} sx={containerStyle}>
				<Box sx={listStyle}>{children}</Box>
				{!isFetching && hasMore && showButton ? (
					<Box sx={buttonStyle}>
						<Button onClick={handleOnLoad} size='small'>
							{loadNewerText || t('common.load-more')}
						</Button>
					</Box>
				) : null}
				{isFetching || isFetchingMore ? (
					<Box display='flex' justifyContent='center' mt={4}>
						<CircularProgress
							size='small'
							message={t('common.loading')}
						/>
					</Box>
				) : null}
				<Box
					ref={endListRef}
					sx={endListStyle}
					height={1 - threshold}
				/>
			</Box>
		);
	}
);
