import {
	Box,
	CircularProgress,
	Grid,
	Typography,
	PageHeading,
	RouterLink,
	Button,
	Paper,
} from 'components';
import { JoinCommunityCard } from 'components/community/request-to-join';
import { useGetPublicCommunity } from 'core/uses-cases/community';
import { useRouter } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export const RequestSuccess = () => {
	const { communityId } = useParams() as { communityId: string };
	const { data, isLoading } = useGetPublicCommunity(communityId);
	const { t } = useTranslation();
	const router = useRouter();

	if (isLoading || !data) {
		return (
			<Box display='flex' justifyContent='center' mt={4}>
				<CircularProgress message={t('common.loading')} />
			</Box>
		);
	}

	return (
		<>
			<PageHeading title={data?.name}>
				<Grid container>
					<Grid item xs={12} md={6}>
						<Typography variant='body2'>
							{t('select-email.private-message')}
						</Typography>
					</Grid>
				</Grid>
			</PageHeading>
			<Grid container spacing={6}>
				<Grid item xs={12} md={4}>
					<JoinCommunityCard community={data} />
				</Grid>
				<Grid item xs={12} md={8}>
					<Box component={Paper} elevation={0} p={5}>
						<Typography>
							{t('request-to-join.success-text-1')}
						</Typography>
						<Typography mt={3}>
							{t('request-to-join.success-text-2')}
						</Typography>
						<Box mt={6}>
							<RouterLink
								to={router.getPathFor('community.search', {
									communityId,
								})}
							>
								<Button size='small'>
									{t('request-to-join.return-search')}
								</Button>
							</RouterLink>
						</Box>
					</Box>
				</Grid>
			</Grid>
		</>
	);
};
