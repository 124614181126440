import React, { useEffect, useMemo, useRef } from 'react';
import Grow from '@mui/material/Grow';
import { Nullable, Community } from 'core/types';
import { useRouter, useTranslation } from 'hooks';
import { RouterBuilder } from 'navigation/router-builder';
import { TFunction } from 'react-i18next';
import classNames from 'classnames';
import { AccountMenu } from 'components/layout/app/header';
import {
	IconButton,
	Box,
	RouterLink,
	Typography,
	MenuItem,
	Container,
	Icon,
	Drawer,
} from '../../../common';
import {
	menuLinkStyle,
	menuContainerStyle,
	communityMenuWrapperStyle,
} from './style';
import { useCurrentCommunity } from '../community-context';
import { CommunityDropDownMenu } from './community-dropdown-menu';

export const CommunityNavigationMenu = () => {
	const boxRef = useRef<HTMLDivElement>(null);
	const router = useRouter();

	const community = useCurrentCommunity();
	const { t } = useTranslation();

	const [anchorElNav, setAnchorElNav] = React.useState<Element>();
	const [isSticky, setIsSticky] = React.useState(false);

	const handleOpenNavMenu = () => {
		setAnchorElNav(boxRef.current ?? undefined);
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(undefined);
	};

	const pages = useMemo(
		() => getCommunityPages(router, community, t),
		[community]
	);

	useEffect(() => {
		const handleOnScroll = () => {
			if (!boxRef?.current) return;

			if (Math.ceil(window.pageYOffset) >= boxRef?.current.offsetTop) {
				if (!isSticky) {
					setIsSticky(true);
				}
			} else {
				if (isSticky) {
					setIsSticky(false);
				}
			}
		};

		window.addEventListener('scroll', handleOnScroll, false);

		return () => {
			window.removeEventListener('scroll', handleOnScroll, false);
		};
	}, [isSticky]);

	return (
		<Box sx={communityMenuWrapperStyle} className='sticky' ref={boxRef}>
			<Container maxWidth='lg'>
				<Box display='flex' alignItems='center'>
					<Box alignItems='center' sx={menuContainerStyle}>
						<IconButton
							size='large'
							aria-label='community menu'
							aria-controls='menu-community-bar'
							aria-haspopup='true'
							onClick={handleOpenNavMenu}
							color='inherit'
						>
							<Icon name='menu' />
						</IconButton>
						<Drawer
							anchor='left'
							open={Boolean(anchorElNav)}
							onClose={handleCloseNavMenu}
						>
							{pages.map(page => (
								<MenuItem
									key={page.title}
									onClick={handleCloseNavMenu}
									component={RouterLink}
									to={page.path}
								>
									<Typography textAlign='center'>
										{page.title}
									</Typography>
								</MenuItem>
							))}
						</Drawer>
						<Grow in={isSticky}>
							<Box className='sticky-community-dropdown'>
								<CommunityDropDownMenu fixed={isSticky} />
							</Box>
						</Grow>
					</Box>
					<Box
						justifyContent='flex-start'
						alignItems='center'
						flexGrow={1}
						display={{ xs: 'none', sm: 'flex' }}
					>
						{pages.map(page => (
							<RouterLink
								key={page.title}
								data-test-id={page.dataTestId}
								to={page.path}
								sx={menuLinkStyle}
								className={classNames({
									disabled: !community,
								})}
								match={page.match}
							>
								{page.title}
							</RouterLink>
						))}
					</Box>
					<Grow in={isSticky}>
						<Box className='sticky-community-dropdown'>
							<AccountMenu />
						</Box>
					</Grow>
				</Box>
			</Container>
		</Box>
	);
};

function getCommunityPages(
	router: RouterBuilder,
	community: Nullable<Community>,
	t: TFunction
) {
	const pages: {
		path: string;
		title: string;
		match?: 'sensitive' | 'exact';
		dataTestId: string;
	}[] = [
		{
			title: t('community-header.home'),
			path: router.getPathFor('community.home', {
				communityId: community?.slug,
			}),
			dataTestId: 'community-header_home-link',
		},
		{
			title: t('community-header.calendar'),
			path: router.getPathFor('community.calendar', {
				communityId: community?.slug,
				calendarId: community?.calendar?.uuid,
			}),
			match: 'sensitive',
			dataTestId: 'community-header_calendar-link',
		},
	];

	if (community?.has_announcements) {
		pages.push({
			title: t('community-header.announcements'),
			path: router.getPathFor('community.announcements', {
				communityId: community?.slug,
			}),
			dataTestId: 'community-header_announcements-link',
		});
	}

	if (community?.has_documents) {
		pages.push({
			title: t('community-header.notes'),
			path: router.getPathFor('community.notes', {
				communityId: community?.slug,
			}),
			dataTestId: 'community-header_notes-link',
		});
	}

	if (community?.has_well_wishes) {
		pages.push({
			title: t('community-header.well-wishes'),
			path: router.getPathFor('community.wishes', {
				communityId: community?.slug,
			}),
			dataTestId: 'community-header_well-wishes-link',
		});
	}

	if (community?.has_photos) {
		pages.push({
			title: t('community-header.photos'),
			path: router.getPathFor('community.albums', {
				communityId: community?.slug,
			}),
			dataTestId: 'community-header_photos-link',
		});
	}

	pages.push({
		title: t('community-header.team'),
		path: router.getPathFor('community.team', {
			communityId: community?.slug,
		}),
		dataTestId: 'community-header_team-link',
	});

	return pages;
}
