import { Error500 } from 'components/common/errors';
import { useLogout } from 'core/uses-cases/authentication';
import { useNavigate } from 'react-router-dom';
import { ErrorLayout } from './layout';

type ErrorFallbackOptions = {
	componentStack: React.ErrorInfo['componentStack'] | null;
	error: Error | null;
	resetError: () => void;
	eventId: string | null;
};

const Error500Page = ({ resetError }: ErrorFallbackOptions) => {
	const { doLogout } = useLogout();
	const navigate = useNavigate();

	const handleOnReset = () => {
		doLogout();
		resetError();
		navigate('/');
	};

	return (
		<ErrorLayout>
			<Error500 onReset={handleOnReset} />
		</ErrorLayout>
	);
};

export const createErrorFallback = (options: ErrorFallbackOptions) => {
	return <Error500Page {...options} />;
};
