import { Box, BoxProps } from 'components/common';
import { Group } from 'core/types';
import { t } from 'i18next';
import * as Yup from 'yup';
import { useMemo } from 'react';
import {
	SelectField,
	SelectItem,
	When,
	Field,
	CheckboxGroupField,
} from 'components/common/form';
import { Visibility } from 'core/services/api/api-client/types';

export interface VisibilityFieldProps extends BoxProps {
	name?: string;
	label?: string;
	required?: boolean;
	groups: Group[];
	useAdminAndMembersOptions?: boolean;
	enableEveryoneOption?: boolean;
}

export type VisibilityFieldValues<
	T = { visibility: Visibility; visibilityGroups: string[] }
> = {
	readonly [K in keyof T]: T[K];
};

export const VisibilityField = ({
	name = 'visibility',
	required,
	label,
	groups,
	useAdminAndMembersOptions,
	enableEveryoneOption = true,
	...rest
}: VisibilityFieldProps) => {
	const groupOptions = useMemo(
		() =>
			groups.map(({ uuid, description }) => ({
				label: description,
				value: uuid,
			})),
		[groups]
	);

	const visibilityOptions = useMemo(
		() => [
			...(enableEveryoneOption
				? [
					{
						label: t('visibility-field.everyone'),
						value: 'members',
					},
				  ]
				: []), // prettier-ignore
			...(useAdminAndMembersOptions
				? [
					{
						label: t(
							'visibility-field.admin-and-group-members'
						),
						value: 'admins_members',
					},
				  ]
				: []), // prettier-ignore
			{
				label: t('visibility-field.only-admins'),
				value: 'only_admins',
			},
			...(groupOptions.length > 0
				? [
					{
						label: t('visibility-field.specific-groups'),
						value: 'custom',
					},
				  ]
				: []), // prettier-ignore
		],
		[groupOptions]
	);

	return (
		<>
			<Box {...rest}>
				<Field
					label={label || t('visibility-field.who-can-see-this')}
					name={name}
					component={SelectField}
					required={required}
					type='text'
					fullWidth
					displayEmpty
				>
					{visibilityOptions.map(member => (
						<SelectItem key={member.value} value={member.value}>
							{member.label}
						</SelectItem>
					))}
				</Field>
			</Box>
			<When field={name} is='custom'>
				<Box mt={3}>
					<Field
						label={t('visibility-field.groups')}
						name={`${name}Groups`}
						component={CheckboxGroupField}
						options={groupOptions}
						inlineLabel
					/>
				</Box>
			</When>
		</>
	);
};

export const visibilityFieldValidationSchema = (name: string) =>
	Yup.object().shape({
		[`${name}Groups`]: Yup.array()
			.of(Yup.string())
			.when(name, {
				is: 'custom',
				then: schema =>
					schema.min(1, t('form.generic-required')).required(),
			}),
	});
