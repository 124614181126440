import { Box, Typography } from 'components/common';
import { CalendarEvent } from 'components/calendar/types';
import classNames from 'classnames';
import { useCallback } from 'react';
import { eventItemStyle } from './styles';

type EventRowProps = {
	event: CalendarEvent;
	onClick: (event: CalendarEvent) => void;
};

export const getEventTags = (event: CalendarEvent) => {
	return {
		'is-occasion': event.category === 'occasion',
		'needs-help': event.volunteersAvailable > 0,
		'needs-met': event.volunteersAvailable === 0,
	};
};

export const EventRow = ({ event, onClick }: EventRowProps) => {
	const handleOnClick = useCallback(() => onClick(event), [event, onClick]);

	return (
		<Box
			sx={eventItemStyle}
			className={classNames({
				'event-row': true,
				...getEventTags(event),
			})}
			onClick={handleOnClick}
		>
			<Typography variant='caption' noWrap>
				{event.name}
			</Typography>
		</Box>
	);
};
