import {
	Box,
	Field,
	Form,
	FormOnSubmitReturn,
	FormStack,
	LoadingButton,
	TextField,
	Divider,
	Button,
	SelectField,
	SelectItem,
	SelectItemGroup,
	When,
	WhenNot,
} from 'components/common';
import { useCallback, useMemo } from 'react';
import { useConstantValue, useTranslation } from 'hooks';
import { TFunction } from 'react-i18next';
import * as Yup from 'yup';
import { sleep } from 'utils/delay';
import { dividerStyle } from './styles';

export type SelectEmailFormSubmittedValues = {
	email: string;
};

type SelectEmailFormProps = {
	initialValues?: Partial<SelectEmailFormSubmittedValues>;
	availableEmails?: string[];
	onCancel?: () => void;
	cancelText?: string;
	onSubmit?: (
		values: SelectEmailFormSubmittedValues
	) => FormOnSubmitReturn<boolean>;
	submitText?: string;
	submittingText?: string;
};

export const SelectEmailForm = (props: SelectEmailFormProps) => {
	const {
		onSubmit,
		submitText,
		submittingText,
		onCancel,
		cancelText,
		initialValues,
		availableEmails = [],
	} = props;
	const { t } = useTranslation();

	const handleOnSubmit = useCallback(
		async ({ email: selectedEmail, otherEmail }) => {
			await sleep(1);

			let email = selectedEmail;

			if (selectedEmail === 'other') {
				email = otherEmail;
			}

			const result = onSubmit?.({ email });

			return Promise.resolve(result).catch(error => error);
		},
		[onSubmit]
	);

	const validationSchema = useMemo(() => createValidationSchema(t), []);

	const cacheInitialValues = useConstantValue(() => ({ ...initialValues }));

	return (
		<Form
			initialValues={cacheInitialValues}
			onSubmit={handleOnSubmit}
			validationSchema={validationSchema}
			subscription={{ submitting: true }}
		>
			{({ handleSubmit, submitting, form }) => (
				<form onSubmit={handleSubmit} noValidate>
					<FormStack spacing={3}>
						<Box>
							{availableEmails.length > 1 ? (
								<>
									<WhenNot field='email' is='other'>
										<Field
											label={t('common-label.email')}
											name='email'
											component={SelectField}
											fullWidth
											required
										>
											{availableEmails.map(
												(option: string) => (
													<SelectItem
														key={option}
														value={option}
													>
														{option}
													</SelectItem>
												)
											)}
											<SelectItemGroup>
												---
											</SelectItemGroup>
											<SelectItem value='other'>
												{t(
													'common-label.use-a-different-address'
												)}
											</SelectItem>
										</Field>
									</WhenNot>
									<When field='email' is='other'>
										<Box display='flex'>
											<Field
												label={t('common-label.email')}
												name='otherEmail'
												component={TextField}
												fullWidth
												required
											/>
											<Box ml={1} mt={4}>
												<Button
													color='secondary'
													size='small'
													onClick={() => {
														form.change(
															'email',
															availableEmails[0]
														);
													}}
												>
													{t('common.cancel')}
												</Button>
											</Box>
										</Box>
									</When>
								</>
							) : (
								<Field
									label={t('common-label.email')}
									name='email'
									component={TextField}
									fullWidth
									required
								/>
							)}
						</Box>
						<Divider sx={dividerStyle} />
						<Box
							display='flex'
							flexDirection={{ xs: 'column', sm: 'row' }}
							alignItems={{ xs: 'center', sm: 'flex-start' }}
							flexWrap={{ xs: 'nowrap', sm: 'wrap' }}
							gap={2}
						>
							{onCancel && (
								<Button
									color='secondary'
									size='small'
									onClick={onCancel}
								>
									{cancelText || t('common-label.cancel')}
								</Button>
							)}
							<LoadingButton
								loading={submitting}
								loadingIndicator={
									submittingText || t('common.loading')
								}
								size='small'
								type='submit'
							>
								{submitText || t('common.continue')}
							</LoadingButton>
						</Box>
					</FormStack>
				</form>
			)}
		</Form>
	);
};

function createValidationSchema(t: TFunction) {
	return Yup.object().shape(
		{
			email: Yup.string().when('otherEmail', {
				is: (otherEmail: string) => !otherEmail,
				then: schema =>
					schema.email(t('form.invalid-email')).required(
						t('form.required', {
							name: t('common-label.email'),
						})
					),
			}),
			otherEmail: Yup.string().when('email', {
				is: 'other',
				then: schema =>
					schema
						.email(t('form.invalid-email'))
						.required(t('form.generic-required')),
			}),
		},
		[['otherEmail', 'email']]
	);
}
