import { Box, Typography, Grid, PageHeading } from 'components/common';
import { CommunityPhotoListContainer } from 'components/photos/community-list';
import { useParams, useVerifyAccess } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useState, useMemo } from 'react';
import { PhotoFilter } from 'components/photos/filter/photo-filter';
import { useGetAlbums } from 'core/uses-cases/albums';
import { AlbumListContainer } from 'components/albums';

export const PhotoList = () => {
	const { t } = useTranslation();
	const { communityId } = useParams() as { communityId: string };
	const [filter, setFilter] = useState('all');
	const verify = useVerifyAccess();
	const { data, isSuccess, isFetching } = useGetAlbums(communityId);

	const defaultAlbum = useMemo(
		() => data?.find(album => album.name === 'default'),
		[data]
	);

	const canUploadPhoto = verify('upload_photo', 'album', {
		identity_permissions: defaultAlbum?.identity_permissions ?? [],
	});

	const canCreateAlbum = verify('create', 'album');
	const canArrange = verify('update', 'album');

	const hasAlbums =
		(data?.filter(album => album.name !== 'default').length ?? 0) > 0;

	const showAlbumColumn =
		(isSuccess && !isFetching && hasAlbums) || canCreateAlbum || canArrange;

	return (
		<Box>
			<PageHeading
				title={t('photos.photos')}
				rightSection={
					<PhotoFilter
						communityId={communityId}
						filter={filter}
						setFilter={setFilter}
					/>
				}
			>
				{canUploadPhoto && (
					<Typography variant='body2'>
						{t('photos.header-text')}
					</Typography>
				)}
			</PageHeading>
			<Grid container spacing={3}>
				<Grid item xs={12} md={showAlbumColumn ? 9 : 12}>
					<CommunityPhotoListContainer
						communityId={communityId}
						filter={filter}
						canUploadPhoto={canUploadPhoto}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					md={showAlbumColumn ? 3 : 0}
					display={showAlbumColumn ? 'block' : 'none'}
				>
					<AlbumListContainer
						communityId={communityId}
						albums={data || []}
						canCreateAlbum={canCreateAlbum}
						canArrange={canArrange}
					/>
				</Grid>
			</Grid>
		</Box>
	);
};
