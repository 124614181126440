import {
	Box,
	Field,
	FieldArray,
	Button,
	IconButton,
	Icon,
	DateTimePickerField,
	DatePickerField,
} from 'components/common';
import { useTranslation } from 'hooks';
import { DateTime } from 'luxon';
import { ReactNode } from 'react';

export type DateSlotProps = {
	name: string;
	onDelete: () => void;
	useTimes: boolean;
	hideDelete: boolean;
};

export const DateSlot = (props: DateSlotProps) => {
	const { name, onDelete, useTimes, hideDelete } = props;

	return (
		<Box display='flex' alignItems='center'>
			<Box>
				<Field
					component={useTimes ? DateTimePickerField : DatePickerField}
					name={name}
					validateImmediately
				/>
			</Box>
			{hideDelete ? null : (
				<Box alignSelf='flex-start'>
					<IconButton onClick={onDelete}>
						<Icon name='delete' />
					</IconButton>
				</Box>
			)}
		</Box>
	);
};

export type DateSlotsFieldProps = {
	label?: ReactNode;
	name: string;
	useTimes: boolean;
	timezone: string;
};

export const DateSlotsField = (props: DateSlotsFieldProps) => {
	const { label, name, useTimes = true, timezone } = props;
	const { t } = useTranslation();

	return (
		<FieldArray<DateTime> name={name} label={label} defaultValue={[]}>
			{({ fields, hasError }) => {
				return (
					<>
						{fields.map((name, index) => (
							<Box key={name} mb={1}>
								<DateSlot
									name={name}
									onDelete={() => fields.remove(index)}
									useTimes={useTimes}
									hideDelete={index === 0}
								/>
							</Box>
						))}
						<Button
							type='button'
							variant='outlined'
							size='small'
							disabled={hasError}
							onClick={() => {
								if (hasError) return;

								const lastDate = fields.value.slice(-1)?.[0];

								fields.push(
									lastDate
										? lastDate.plus({ day: 1 })
										: DateTime.local({ zone: timezone })
								);
							}}
							startIcon={<Icon name='add_circle_outline' />}
						>
							{t('duplicate-event-form.add-another-date')}
						</Button>
					</>
				);
			}}
		</FieldArray>
	);
};
