import { SxProps } from '@mui/system';
import { Theme } from 'theme';

export const avatarStyle: SxProps = {
	width: '56px',
	height: '56px',
	cursor: 'pointer',
};

export const nameStyle: SxProps<Theme> = {
	cursor: 'pointer',
	'& > h6:hover': {
		textDecoration: 'underline',
	},
};

export const statsButtonStyle: SxProps<Theme> = {
	padding: 0,
	textTransform: 'none',

	'&:focus': {
		boxShadow: 'none',
	},
};

export const taskRowStyle: SxProps<Theme> = theme => ({
	display: 'flex',
	flexDirection: 'column',
	marginLeft: 1.5,

	'& .link': {
		color: theme.textColors.text,
	},
});

export const recentActivityRowStyle: SxProps<Theme> = theme => ({
	display: 'flex',
	flexWrap: 'nowrap',
	marginBottom: 1,

	'& > span:first-of-type': {
		overflow: 'hidden',
		fontWeight: 'bold',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
	'& > span + span': {
		whiteSpace: 'nowrap',
		fontWeight: 'normal',
	},
});
