import React, { ComponentType } from 'react';
import { useLocation, Outlet, Navigate } from 'react-router-dom';
import { useTransientStore } from 'store';
import { useRouter } from 'hooks';

type GuardProps = {
	element?: React.ReactNode;
	layout?: ComponentType;
};

export const AuthenticatedGuard = (props: GuardProps) => {
	const router = useRouter();

	const { layout: Layout = React.Fragment, element } = props;

	const store = useTransientStore();

	const location = useLocation();

	if (!store.auth.isLoggedIn) {
		return (
			<Navigate
				to={router.getPathFor('account.login')}
				state={{ from: location }}
				replace
			/>
		);
	}

	return <Layout>{element ? element : <Outlet />}</Layout>;
};
