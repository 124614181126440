import { Box, BoxProps } from 'components';
import { Video } from 'core/types';
import { useRef } from 'react';
import ReactHlsPlayer, { HlsPlayerProps } from 'react-hls-player';
import { videoStyle } from './style';

type FeedVideoProps = {
	video: Video;
	boxProps?: BoxProps;
	playerProps?: HlsPlayerProps;
};

export const FeedVideo = ({ video, boxProps }: FeedVideoProps) => {
	const videoRef = useRef<HTMLVideoElement>(null);

	return (
		<Box sx={videoStyle} {...boxProps}>
			<ReactHlsPlayer
				playerRef={videoRef}
				src={video.url}
				autoPlay={false}
				controls
				width='100%'
				height='auto'
			/>
		</Box>
	);
};
