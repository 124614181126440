import { Box, Paper } from 'components/common';
import {
	FeedCardHeader,
	useFeedCardMenu,
} from 'components/community/feed/shared';
import { AlbumPhoto, Identity } from 'core/types';
import { useImageServer, ImageServerConfig, useVerifyActions } from 'hooks';
import { useCallback } from 'react';
import { NameEditor, NameEditorSubmittedValues } from './name-editor';
import { imageContainer } from './styles';

type PhotoImageProps = {
	onDelete?: (photo: AlbumPhoto) => void;
	onSubmit: (values: NameEditorSubmittedValues) => Promise<void>;
	photo: AlbumPhoto;
	config?: ImageServerConfig;
};

export const LargePhotoCard = ({
	photo,
	config,
	onDelete,
	onSubmit,
}: PhotoImageProps) => {
	const photoImage = useImageServer(photo.image, {
		w: 2000,
		h: 2000,
		fit: 'max',
		...config,
	});

	const handleOnDelete = useCallback(() => {
		onDelete?.(photo);
	}, [onDelete, photo]);

	const canEditName = !!photo.identity_permissions.includes('update');

	const actionObject = useVerifyActions(
		{
			type: 'album_image',
			identity_permissions: photo.identity_permissions,
		},
		[{ action: 'delete', handler: handleOnDelete, key: 'onDelete' }]
	);

	const menuAction = useFeedCardMenu(actionObject);

	return (
		<Box component={Paper} elevation={0} p={3} mb={2} mt={1}>
			<FeedCardHeader
				createdDt={photo.created_dt}
				identity={photo.owner as Identity}
				renderAction={menuAction}
			/>
			<Box alignContent='center' mt={2} sx={imageContainer}>
				<img src={photoImage?.url} alt={photoImage?.name} />
			</Box>
			<NameEditor
				name={photo.image.name}
				canEditName={canEditName}
				onSubmit={onSubmit}
			/>
		</Box>
	);
};
