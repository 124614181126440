import { useMemo, useState, useRef, useEffect } from 'react';
import { eqSet } from 'utils/collections';
import { Group } from 'core/types';
import { useTranslation } from 'hooks';

export const useHandleSelection = (
	groups: Group[],
	{
		value,
		onChange,
	}: {
		value: string[];
		onChange?: (value: string[]) => void;
	}
) => {
	const { t } = useTranslation();

	const [memberUuid, adminUuid] = useMemo(() => {
		const memberUuid = groups.find(group => group.name === 'member')
			?.uuid as string;
		const adminUuid = groups.find(group => group.name === 'site_admin')
			?.uuid as string;
		return [memberUuid, adminUuid];
	}, [groups]);

	const [selected, setSelected] = useState(() => new Set<string>());
	const prevSelectedBeforeEveryone = useRef<Set<string>>();

	useEffect(() => {
		const inComingSet = new Set<string>(value);
		if (!eqSet(inComingSet, selected)) {
			setSelected(inComingSet);
		}
	}, [value]);

	useEffect(() => {
		onChange?.(Array.from(selected));
	}, [selected]);

	const handleOnSelect = (value: string) => {
		const nextSelected = new Set(selected);

		if (value === memberUuid && !nextSelected.has(memberUuid)) {
			prevSelectedBeforeEveryone.current = selected;
			nextSelected.clear();
			nextSelected.add(memberUuid);
		} else if (nextSelected.has(value)) {
			nextSelected.delete(value);
		} else {
			nextSelected.add(value);
		}

		if (
			value === memberUuid &&
			prevSelectedBeforeEveryone.current &&
			nextSelected.size === 0
		) {
			setSelected(prevSelectedBeforeEveryone.current);
			prevSelectedBeforeEveryone.current = undefined;
		} else {
			setSelected(nextSelected);
		}
	};

	const options = groups
		.filter(({ name }) => name !== 'site_admin')
		.map(group => ({
			label:
				group.name === 'member'
					? t('visibility-field.everyone')
					: group.description,
			value: group.uuid,
			disabled:
				group.name !== 'member' &&
				selected.size === 1 &&
				selected.has(memberUuid),
		}));

	return {
		memberUuid,
		adminUuid,
		options,
		selected,
		handleOnSelect,
	};
};
