import { DateTime, ToRelativeOptions } from 'luxon';

export const getCurrentTimeZone = () => {
	return DateTime.now().zoneName;
};

export const isValidDate = (date: string): boolean => {
	const dateTime = DateTime.fromISO(date);
	return dateTime.isValid;
};

export const fromISO = (iso: string): Date => {
	return DateTime.fromISO(iso).toJSDate();
};

export const toRelative = (
	isoDate: Date | string | DateTime,
	unit: ToRelativeOptions['unit'] = ['days', 'hours', 'minutes']
) => {
	if (typeof isoDate === 'string') {
		isoDate = fromISO(isoDate);
	}

	if (isoDate instanceof Date) {
		isoDate = DateTime.fromJSDate(isoDate);
	}

	if (isoDate.diffNow().milliseconds > -60000) {
		return false;
	}

	return isoDate.toRelative({
		unit,
	});
};

export const formatDate = (
	date: Date | string | DateTime,
	format: string,
	timeZone?: string
): string => {
	if (typeof date === 'string') {
		date = fromISO(date);
	}

	if (date instanceof DateTime) {
		date = date.toJSDate();
	}

	if (timeZone) {
		return DateTime.fromJSDate(date, { zone: timeZone }).toFormat(format);
	}

	return DateTime.fromJSDate(date).toFormat(format);
};

export const setStartOfDay = (date: DateTime) => {
	return date.set({
		hour: 0,
		minute: 0,
		second: 0,
		millisecond: 0,
	});
};

export const setEndOfDay = (date: DateTime) => {
	return date.set({
		hour: 23,
		minute: 59,
		second: 59,
		millisecond: 999,
	});
};

/**
 * It takes a locale string in the format of `en_US.UTF-8` and returns `en-US`
 * @param {string} serverLocale - The locale string that the server sends.
 * @param [defaultLocale=en-US] - The default locale to use if the server locale is not found.
 * @returns The locale string with the country code removed.
 */
export const transformServerToClientLocal = (
	serverLocale: string | undefined | null,
	defaultLocale = 'en-US'
) => {
	return (
		serverLocale?.replace(/\..+$/, '').replace(/_/, '-') || defaultLocale
	);
};
