import {
	AutoSaveField,
	Field,
	Form,
	Grid,
	Paper,
	SelectField,
	SelectItem,
	TextField,
	Typography,
	OptionType,
} from 'components/common';
import { useConstantValue, useTranslation } from 'hooks';
import {
	CommunityMemberCard,
	CommunityMemberCardProps,
} from 'components/community/team';
import searchContacts from 'assets/images/search-contacts.svg?url';
import { sleep } from 'utils/delay';
import { CommunityMember } from 'core/types';
import {
	activityStyles,
	headerStyles,
	volunteerPreferenceStyles,
} from './styles';

export type CommunityMembersSubmittedValues = {
	searchTerm?: string;
	show: string;
	sortBy: string;
};

export type CommunityMembersProps = {
	members: CommunityMember[];
	initialFilter?: CommunityMembersSubmittedValues;
	showOptions: OptionType[];
	sortByOptions: OptionType[];
	onApplyFilter: (values: CommunityMembersSubmittedValues) => void;
} & Omit<CommunityMemberCardProps, 'member'>;

export const CommunityMembers = ({
	members,
	initialFilter,
	onApplyFilter,
	showOptions,
	sortByOptions,
	onClickMember,
	...rest
}: CommunityMembersProps) => {
	const { t } = useTranslation();

	const cachedInitialValues = useConstantValue({
		...initialFilter,
	});

	const save = async (values: CommunityMembersSubmittedValues) => {
		onApplyFilter({
			searchTerm: values.searchTerm ?? '',
			show: values.show,
			sortBy: values.sortBy,
		});

		await sleep(0);
	};

	return (
		<>
			<Form onSubmit={save} initialValues={cachedInitialValues}>
				{({ handleSubmit }) => (
					<form onSubmit={handleSubmit} noValidate>
						<AutoSaveField<CommunityMembersSubmittedValues>
							debounce={1000}
							onSave={save}
						/>
						<Grid container mt={3}>
							<Grid item md={4} xs={12} px={2}>
								<Field
									name='searchTerm'
									label={t('team.search-term')}
									component={TextField}
									placeholder={t('team.name-meals-rides')}
									fullWidth
									startIcon={searchContacts}
								/>
							</Grid>
							<Grid item md={4} xs={12} px={2}>
								<Field
									name='show'
									label={t('team.show')}
									component={SelectField}
									fullWidth
								>
									{showOptions?.map(option => (
										<SelectItem
											key={option.value}
											value={option.value}
										>
											{option.label}
										</SelectItem>
									))}
								</Field>
							</Grid>
							<Grid item md={4} xs={12} px={2}>
								<Field
									name='sortBy'
									label={t('team.sort-by')}
									component={SelectField}
									fullWidth
								>
									{sortByOptions.map(option => (
										<SelectItem
											key={option.value}
											value={option.value}
										>
											{option.label}
										</SelectItem>
									))}
								</Field>
							</Grid>
						</Grid>
					</form>
				)}
			</Form>
			<Grid
				container
				component={Paper}
				mt={6}
				px={5}
				py={2}
				sx={headerStyles}
			>
				<Grid item md={3} sm={4} xs={6}>
					<Typography variant='navitem'>{t('team.name')}</Typography>
				</Grid>
				<Grid item md={3} sm={4} xs={6}>
					<Typography variant='navitem'>
						{t('team.contact-info')}
					</Typography>
				</Grid>
				<Grid item md={3} sm={4} sx={activityStyles}>
					<Typography variant='navitem'>
						{t('team.activity.activity')}
					</Typography>
				</Grid>
				<Grid item md={3} sx={volunteerPreferenceStyles}>
					<Typography variant='navitem'>
						{t('team.volunteer-preference')}
					</Typography>
				</Grid>
			</Grid>
			{members?.map(member => {
				return (
					<CommunityMemberCard
						member={member}
						key={member.identity.uuid}
						onClickMember={onClickMember?.bind(
							null,
							member.identity
						)}
						{...rest}
					/>
				);
			})}
		</>
	);
};
