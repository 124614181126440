import {
	Stack,
	Box,
	Typography,
	TextField,
	Button,
	Tooltip,
} from 'components/common';
import {
	useCurrentPartner,
	useTranslation,
	useCopyLink,
	useRouter,
} from 'hooks';
import { Community } from 'core/types';
import { shareLinkInput, stackStyle } from './styles';

type ShareLinkSectionProps = {
	community: Community;
};

export const ShareLinkSection = (props: ShareLinkSectionProps) => {
	const { community } = props;

	const { t } = useTranslation();
	const partner = useCurrentPartner();
	const router = useRouter();

	const { url, isCopied, handleOnCopy } = useCopyLink(
		router.getPathFor('request.root', {
			communityId: community?.slug ?? '',
		})
	);

	return (
		<>
			<Stack spacing={6} sx={stackStyle}>
				<Box display='flex' alignItems='flex-end'>
					<TextField
						label={t('community-form.your-community-url')}
						type='text'
						value={url}
						sx={shareLinkInput}
						inputProps={{
							readOnly: true,
						}}
						fullWidth
					/>
					<Tooltip
						open={isCopied}
						title={<>{t('community-form.community-url-copied')}</>}
						placement='top'
					>
						<Button onClick={handleOnCopy} size='small'>
							{t('community-form.copy')}
						</Button>
					</Tooltip>
				</Box>
				<Box component='dl'>
					<Typography component='dt'>
						{t('community-form.privacy-tip')}:
					</Typography>
					<Typography variant='caption'>
						{t('community-form.if-you-created-public-private', {
							partner: partner.name,
						})}
						.
					</Typography>
				</Box>
			</Stack>
		</>
	);
};
