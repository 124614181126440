import { BaseHttpClient } from '../base-http-client';
import {
	CommunityMemberResponse,
	CommunityMembersResponse,
	IdentityResponse,
} from '../response-types';
import {
	FilterCommunityMembersInput,
	PaginationArgs,
	PatchMemberInput,
} from './types';

export const createMemberApi = (http: BaseHttpClient) => ({
	getCommunityMembers(
		communityId: string,
		input?: FilterCommunityMembersInput,
		pagination?: PaginationArgs
	) {
		return http
			.get<CommunityMembersResponse>(`community/${communityId}/member`, {
				with: 'image',
				search_term: input?.searchTerm,
				filter_by: input?.sortBy,
				group: input?.show,
				...pagination,
			})
			.then(response => response.data);
	},

	getCommunityMember(communityId: string, memberId: string) {
		return http
			.get<CommunityMemberResponse>(
				`community/${communityId}/member/${memberId}`,
				{
					with: 'image',
				}
			)
			.then(response => response.data);
	},

	patchCommunityMember(
		communityId: string,
		identityId: string,
		payload: PatchMemberInput
	) {
		return http
			.patch<IdentityResponse>(
				`community/${communityId}/member/${identityId}`,
				payload,
				{
					params: {
						with: 'image',
					},
				}
			)
			.then(response => response.data);
	},

	deleteCommunityMember(
		communityId: string,
		memberId: string,
		sendNotification?: boolean,
		accessToken?: string
	) {
		return http
			.delete<null>(`community/${communityId}/member/${memberId}`, {
				data: {
					send_notification: sendNotification || true,
				},
				params: {
					...(accessToken ? { access_token: accessToken } : {}),
				},
			})
			.then(() => null);
	},
});
