import { Service } from 'axios-middleware';
import { Config } from 'config/types';
import { AuthenticationInterface, ErrorReportInterface } from '../types';
import { ApiClient, createClient } from './api-client/api-client';
import {
	AuthMiddleware,
	LogMiddleware,
	ErrorHandlerMiddleware,
} from './middlewares';

export async function createApiClient(
	config: Config,
	authentication: AuthenticationInterface,
	errorReport: ErrorReportInterface
): Promise<ApiClient> {
	const api = createClient(config.api);

	// Configure middlewares
	new Service(api.client).register([
		...(config.api.logEnabled ? [new LogMiddleware()] : []),
		new AuthMiddleware(authentication),
		new ErrorHandlerMiddleware(errorReport),
	]);

	if (config.api.useMock) {
		const { setupBrowserWorker } = await import('./mock/browser');

		(await setupBrowserWorker(config.api)).start({
			quiet: true,
			onUnhandledRequest: 'bypass',
		});
	}

	return api;
}
