import config from 'config';

export type PartnerRecord = {
	name: string;
	contactUrl?: string;
};

export const PARTNERS: Record<string, PartnerRecord> = {
	lotsa: {
		name: 'Lotsa Helping Hands',
	},
	mcc: {
		name: 'My Cancer Circle (Aflac)',
	},
	mcc2: {
		name: 'My Cancer Circle',
		contactUrl: 'https://mycancercircle.net/contact',
	},
	alsa: {
		name: 'The ALS Association',
		contactUrl: 'https://alsa.lotsahelpinghands.com/contact',
	},
};

export const HOST_MAP: Record<string, string> = {
	[config.hosts.lotsa]: 'lotsa',
	[config.hosts.mcc]: 'mcc',
	[config.hosts.mcc2]: 'mcc2',
	[config.hosts.alsa]: 'alsa',
};
