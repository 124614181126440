import {
	Box,
	Paper,
	PageHeading,
	CircularProgress,
	useDialog,
	DialogContent,
	DialogContentText,
	Form,
	FormStack,
	FormControl,
	SelectItem,
	SelectField,
	Field,
	DialogActions,
	Button,
} from 'components/common';
import {
	EventForm,
	EventFormSubmittedValues,
	useDeleteEventHandler,
	useEventFormData,
} from 'components/events';
import { EVENT_SCOPES, EventScopeValues } from 'core/constants';
import { useGetEvent, usePathEvent } from 'core/uses-cases/calendar';
import {
	useErrorHandler,
	useParams,
	useRouter,
	useServices,
	useSubmitHandlerForPausedMutation,
	useTranslation,
} from 'hooks';

export const EditEvent = () => {
	const { t } = useTranslation();

	const { communityId, calendarId, eventId } = useParams() as {
		communityId: string;
		calendarId: string;
		eventId: string;
	};
	const router = useRouter();
	const { notification } = useServices();

	const eventQuery = useGetEvent(calendarId, eventId);

	const { mutateAsync, isLoading, isPaused } = usePathEvent();

	const handleOnSubmit = useSubmitHandlerForPausedMutation<
		EventFormSubmittedValues & { scope: EventScopeValues }
	>(
		isPaused,
		values => {
			const {
				dates,
				startTimes,
				endTimes,
				coordinators,
				attachments,
				locations,
				volunteers,
				scope,
				...input
			} = values;

			return mutateAsync({
				...input,
				eventId,
				communityId,
				calendarId,
				scope,
				startTime: startTimes?.[0] || null,
				endTime: endTimes?.[0] || null,
				date: dates?.[0],
				coordinators,
				attachments,
				locations,
				volunteers: volunteers ?? [],
			});
		},
		{
			onSuccess: () => {
				notification.show(
					'success',
					t('event-edit.events-were-successfully-updated')
				);

				router.navigate('community.calendar.event.view', {
					communityId,
					calendarId,
					eventId,
				});
			},
		},
		[communityId]
	);

	const { open } = useDialog<EventFormSubmittedValues, number>({
		title: t('event-edit.change-recurring-task'),
		content: (
			confirmHandler: (extra: number) => void,
			cancelHandler: () => void
		) => {
			return (
				<RecurringDialogContent
					confirmHandler={confirmHandler}
					cancelHandler={cancelHandler}
				/>
			);
		},
		hideActions: true,
		confirmText: t('event-edit.confirm'),
		maxWidth: 'sm',
		fullWidth: true,
		onConfirm: (values, scope) => {
			if (values) {
				handleOnSubmit({ ...values, scope });
			}
		},
	});

	const handlePreSubmit = (values: EventFormSubmittedValues) => {
		if (!eventQuery.data) return null;

		const eventSeriesCount = eventQuery.data.event_series_count ?? 1;

		// Open dialog if event series count is greater than 1
		if (eventSeriesCount > 1) {
			open(values);
			return Promise.resolve(void 0);
		}

		return handleOnSubmit({ ...values, scope: EVENT_SCOPES.INSTANCE });
	};

	const formProps = useEventFormData(eventQuery?.data);

	const onDeleteEvent = useDeleteEventHandler({
		onConfirm: () =>
			router.navigate('community.calendar', {
				communityId,
				calendarId,
			}),
	});

	const handleOnDelete = () => {
		if (!eventQuery.data) return;
		onDeleteEvent({ event: eventQuery.data, communityId, calendarId });
	};

	useErrorHandler(eventQuery.error, {
		redirectTo: router.getPathFor('community.calendar', {
			communityId,
			calendarId,
		}),
	});

	if (eventQuery.isLoading) {
		return (
			<Box display='flex' justifyContent='center' mt={4}>
				<CircularProgress message={t('common.loading')} />
			</Box>
		);
	}

	return (
		<Box>
			<PageHeading title={t('event-edit.edit-task')} />
			<Box component={Paper} elevation={0} mt={6} px={5} py={5}>
				{eventQuery.isSuccess && eventQuery.data && (
					<>
						<EventForm
							onSubmit={handlePreSubmit}
							submitText={t('common.save-changes')}
							submittingText={t('common.saving-changes')}
							isSubmitting={isLoading && !isPaused}
							editMode
							onDelete={handleOnDelete}
							deleteText={t('event-edit.delete-task')}
							{...formProps}
						/>
					</>
				)}
			</Box>
		</Box>
	);
};

const RecurringDialogContent = ({
	confirmHandler,
	cancelHandler,
}: {
	confirmHandler: (extra: number) => void;
	cancelHandler: () => void;
}) => {
	const { t } = useTranslation();

	const scopeOptions = [
		{ value: EVENT_SCOPES.INSTANCE, label: t('event-edit.this-task') },
		{
			value: EVENT_SCOPES.FUTURE,
			label: t('event-edit.this-and-future-tasks'),
		},
		{ value: EVENT_SCOPES.ALL, label: t('event-edit.all-tasks') },
	];

	const handleOnScopeSubmit = (values: { scope: number }) => {
		confirmHandler(values.scope);
	};

	return (
		<Form<{ scope: number }> onSubmit={handleOnScopeSubmit}>
			{({ handleSubmit }) => {
				return (
					<>
						<DialogContent>
							<DialogContentText>
								{t('event-edit.edit-task-prompt')}
							</DialogContentText>
							<FormStack responsive={false} spacing={1.5}>
								<FormControl fullWidth>
									<Field
										name='scope'
										component={SelectField}
										defaultValue={EVENT_SCOPES.INSTANCE}
									>
										{scopeOptions.map(
											({ label, value }) => (
												<SelectItem
													key={`scope-${value}`}
													value={value}
												>
													{label}
												</SelectItem>
											)
										)}
									</Field>
								</FormControl>
							</FormStack>
						</DialogContent>
						<DialogActions
							sx={{
								paddingX: 2,
								paddingBottom: 2,
							}}
						>
							<Button
								variant='text'
								size='small'
								onClick={cancelHandler}
							>
								{t('common.cancel')}
							</Button>
							<Button size='small' onClick={handleSubmit}>
								{t('event-edit.confirm')}
							</Button>
						</DialogActions>
					</>
				);
			}}
		</Form>
	);
};
