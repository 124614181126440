import React, { useEffect } from 'react';

export function useIntersectionObserver({
	root,
	target,
	onIntersect,
	threshold = 1.0,
	rootMargin = '0px',
	enabled = true,
}: {
	root?: React.RefObject<HTMLElement>;
	target: React.RefObject<HTMLElement>;
	onIntersect: () => void;
	threshold?: number;
	rootMargin?: string;
	enabled?: boolean;
}) {
	const observerCallback = (entries: IntersectionObserverEntry[]) => {
		entries.forEach(entry => entry.isIntersecting && onIntersect());
	};

	useEffect(() => {
		if (!enabled) return;

		const observer = new IntersectionObserver(observerCallback, {
			root: root && root.current,
			rootMargin,
			threshold,
		});

		const el = target && target.current;

		if (!el) {
			return;
		}

		observer.observe(el);

		// eslint-disable-next-line consistent-return
		return () => {
			observer.unobserve(el);
		};
	}, [target.current, enabled]);
}
