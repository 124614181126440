import { InputLabel } from '@mui/material';
import { Box, CheckboxGroupItem, Typography } from 'components';
import { Group } from 'core/types';
import { useTranslation, usePrevious } from 'hooks';
import { useState, useMemo, useEffect } from 'react';
import { convertCollectionToMap } from 'utils/collections';
import { checkboxSummaryLabelStyle, labelStyle } from './styles';
import { useHandleSelection } from './use-handle-selection';

type PermissionTabsFieldProps = {
	groups: Group[];
	label: string;
	name: string;
	value: string[];
	onChange?: (value: string[]) => void;
	isVisible?: boolean;
};

export const PermissionTabsField = ({
	groups,
	label,
	value = [],
	onChange,
	isVisible = true,
}: PermissionTabsFieldProps) => {
	const { t } = useTranslation();
	const [isEditing, setIsEditing] = useState(false);
	const prevVisible = usePrevious(isVisible);

	useEffect(() => {
		if (prevVisible && !isVisible) {
			setIsEditing(false);
		}
	}, [isVisible]);

	const groupMap = useMemo(
		() => convertCollectionToMap(groups, 'uuid'),
		[groups]
	);

	const { memberUuid, adminUuid, options, selected, handleOnSelect } =
		useHandleSelection(groups, {
			value,
			onChange,
		});

	const getGroupLabel = (groupUuid: string) => {
		const group = groupMap.get(groupUuid);
		if (group?.name === 'member') {
			return t('visibility-field.everyone');
		} else if (group?.name === 'site_admin') {
			return t('visibility-field.only-admins');
		}

		return group?.description;
	};

	const groupLabels = (
		selected.size === 0 ? [adminUuid] : Array.from(selected)
	).map(getGroupLabel);

	return (
		<Box display='flex' flexDirection='row'>
			<InputLabel sx={labelStyle}>{label}</InputLabel>
			{isEditing ? (
				<Box display='flex' flexDirection='column'>
					{options.map(({ label, value, disabled }) => (
						<CheckboxGroupItem
							key={`checkbox-${label}-${value}`}
							checked={selected.has(value)}
							onChange={handleOnSelect.bind(null, value)}
							label={label}
							disabled={disabled}
						/>
					))}
					<CheckboxGroupItem
						checked={!selected.has(memberUuid)}
						label={groupMap.get(adminUuid)?.description || ''}
						disabled
					/>
				</Box>
			) : (
				<Box
					onClick={() => setIsEditing(true)}
					sx={checkboxSummaryLabelStyle}
				>
					<Typography>{groupLabels.join(', ')}</Typography>
				</Box>
			)}
		</Box>
	);
};
