import { isCancelledError, Mutation, useMutation } from 'react-query';
import { communityKeys } from 'core/utils/query-key-factory';
import { initiateTransferOwnershipMutationKey } from 'core/utils/mutation-key-factory';
import { SetupMutationFn } from 'core/configure/types';
import { Services } from 'core/services';
import { CommunityMember, TransferOwnershipRequest } from 'core/types';
import produce from 'immer';
import { cancelPreviousMutation } from 'core/utils/optimistic-utils';
import { BaseHttpError } from '../../services/api/errors';

export interface InitiateTransferCommunityMutationInput {
	communityId: string;
	identityId: string;
}

type MutationContext = {
	communityId: string;
	identityId: string;
	transferRequest: TransferOwnershipRequest;
};

export const useInitiateTransferCommunity = () => {
	const mutation = useMutation<
		null,
		BaseHttpError,
		InitiateTransferCommunityMutationInput,
		MutationContext
	>(initiateTransferOwnershipMutationKey);

	return mutation;
};

export const setupInitiateTransferCommunity: SetupMutationFn = (
	services: Services,
	createTrackedParallelMutation,
	mutationTracker
) => {
	const { queryClient, api } = services;

	const mutation = createTrackedParallelMutation<
		null,
		BaseHttpError,
		InitiateTransferCommunityMutationInput,
		MutationContext
	>({
		mutationFn: input => {
			const { communityId, identityId } = input;
			return api.initiateTransferOwnership(communityId, identityId);
		},
		onMutate: async input => {
			const { communityId, identityId } = input;

			const communityMembers = queryClient.getQueryData<
				CommunityMember[]
			>(communityKeys.members(communityId));

			const newOwner = communityMembers?.find(
				communityMember => communityMember.identity.uuid === identityId
			);

			const transferRequest: TransferOwnershipRequest = {
				recipient: {
					id: identityId,
					first_name: newOwner?.identity.first_name ?? '',
					last_name: newOwner?.identity.last_name ?? '',
				},
				sent_dt: null,
			};

			// Cancel the previous mutation if it exists
			await cancelPreviousMutation<MutationContext>(
				queryClient,
				mutation => {
					return (
						mutation.state.context?.communityId === communityId &&
						isInitiateTransferMutation(mutation)
					);
				}
			);

			await queryClient.cancelQueries(
				communityKeys.transferRequests(communityId)
			);

			queryClient.setQueryData<TransferOwnershipRequest[]>(
				communityKeys.transferRequests(communityId),
				data =>
					produce(data || [], draft => {
						draft.push(transferRequest);
					})
			);

			return {
				communityId,
				identityId,
				transferRequest,
			};
		},
		onError: (error, { communityId, identityId }, context) => {
			if (context && !isCancelledError(error)) {
				queryClient.setQueryData<TransferOwnershipRequest[]>(
					communityKeys.transferRequests(communityId),
					data =>
						produce(data || [], draft => {
							const index = draft.findIndex(
								item => item.recipient.id === identityId
							);
							if (index !== -1) draft.splice(index, 1);
						})
				);
			}
		},
		onSettled: (data, error, { communityId }, context) => {
			if (context && !isCancelledError(error)) {
				mutationTracker.queueInvalidations(
					communityKeys.transferRequests(communityId)
				);
			}
		},
		retry: 1,
	});

	queryClient.setMutationDefaults(
		initiateTransferOwnershipMutationKey,
		mutation
	);
};

export const isInitiateTransferMutation = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	mutation: Mutation<any, any, any, any>
) =>
	[initiateTransferOwnershipMutationKey].includes(
		String(mutation?.options?.mutationKey)
	);
