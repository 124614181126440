import {
	FormControlProps,
	InputLabelProps,
	FormGroupProps,
	FormControl,
	InputLabel,
	FormHelperText,
} from '@mui/material';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { ImageServerConfig, useTranslation } from 'hooks';
import { UploadButton } from 'components/photos/button';
import { createImageServerUrl } from 'utils/url';
import { Button, ButtonProps, IconButton } from '../../button';
import { Box, Grid } from '../../layout';
import {
	deleteButtonContent,
	imageAreaStyle,
	dropzoneAreaStyle,
	imageContainerStyle,
} from './styles';
import { useImagePicker, ImagePickerOptions } from './use-image-picker';

export type ImagePickerFieldProps = Omit<FormControlProps, 'onChange'> & {
	label?: string;
	LabelProps?: InputLabelProps;
	FormGroupProps?: FormGroupProps;
	helperText?: string;
	buttonSize?: ButtonProps['size'];
	CustomButton?: typeof UploadButton;
	imageServerConfig?: ImageServerConfig;
} & ImagePickerOptions;

export const ImagePickerField = (props: ImagePickerFieldProps) => {
	const {
		isMultiple = false,
		maxFiles = 0,
		disabled = false,
		label,
		LabelProps,
		helperText,
		value = [],
		buttonSize,
		onChange,
		CustomButton,
		imageServerConfig = {
			w: 600,
			h: 400,
			fit: 'fill',
			bg: 'fafafa',
		},
		...rest
	} = props;

	const { t } = useTranslation();

	const { images, removeImage, getRootProps, getInputProps, open } =
		useImagePicker({
			isMultiple,
			maxFiles,
			disabled,
			value,
			onChange,
			imageServerConfig,
		});

	return (
		<FormControl {...rest}>
			{label && <InputLabel {...LabelProps}>{label}</InputLabel>}
			<Box
				{...getRootProps({ className: 'dropzone' })}
				sx={dropzoneAreaStyle}
			>
				<input {...getInputProps()} />
				{isMultiple || (!isMultiple && images.length === 0) ? (
					CustomButton ? (
						<CustomButton
							open={open}
							buttonSize={buttonSize}
							fileCount={images.length}
						/>
					) : (
						<Button
							type='button'
							variant='outlined'
							onClick={open}
							size={buttonSize}
							startIcon={
								<AddCircleOutlineOutlinedIcon fontSize='large' />
							}
						>
							{images.length > 0
								? t('common.add-another-photo')
								: t('common.add-a-photo')}
						</Button>
					)
				) : null}
			</Box>
			{images.length > 0 && (
				<Grid
					container
					spacing={isMultiple ? 2 : 0}
					sx={isMultiple ? imageAreaStyle : undefined}
				>
					{images.map((file, index) => {
						const src =
							file.scaledUrl ||
							createImageServerUrl(file.url, imageServerConfig);
						return (
							<Grid
								item
								xs={12}
								md={isMultiple ? 4 : 12}
								sx={imageContainerStyle}
								key={file.url}
							>
								<img src={src} />
								<Box sx={deleteButtonContent}>
									<IconButton
										aria-label='delete'
										size='small'
										onClick={removeImage.bind(null, index)}
										color='inherit'
									>
										<DeleteOutlineIcon fontSize='inherit' />
									</IconButton>
								</Box>
							</Grid>
						);
					})}
				</Grid>
			)}
			{helperText && <FormHelperText>{helperText}</FormHelperText>}
		</FormControl>
	);
};
