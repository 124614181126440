import {
	Box,
	CardMenuItem,
	CheckboxField,
	FancyTable,
	RouterLink,
	TruncatedList,
	Typography,
	useCardMenu,
} from 'components/common';
import { Group } from 'core/types';
import { useCurrentIdentity } from 'core/uses-cases/authentication';
import { useManageCommunityGroup } from 'core/uses-cases/group';
import { useRouter, useTranslation } from 'hooks';
import { Theme } from 'theme';
import { groupNameBoxStyle } from './styles';
import { useGroupNameTranslation } from './use-group-name-translation';

export type GroupRowProps = {
	group: Group;
	isSelected: boolean;
	onSelect: (uuid: string) => void;
	communityId: string;
	isSelectable: boolean;
	showGlobalPermissions?: boolean;
};

export const GroupRow = ({
	group,
	isSelected,
	onSelect,
	isSelectable,
	communityId,
	showGlobalPermissions = false,
}: GroupRowProps) => {
	const { t } = useTranslation();
	const router = useRouter();
	const currentIdentity = useCurrentIdentity();

	const handleOnLeave = () => {
		if (!currentIdentity || !group) return;

		manageGroupMutation.mutate({
			communityId,
			groupId: group.uuid,
			identityId: currentIdentity.uuid,
			add: false,
		});
	};

	const manageGroupMutation = useManageCommunityGroup();

	const handleOnJoin = () => {
		if (!currentIdentity || !group) return;

		manageGroupMutation.mutate({
			communityId,
			groupId: group.uuid,
			identityId: currentIdentity.uuid,
			add: true,
		});
	};

	const menuActions: CardMenuItem[] = [];

	if (group.has_open_enrollment) {
		const isPartOfGroup = (group?.members ?? []).some(
			member => member.uuid === currentIdentity?.uuid
		);

		if (isPartOfGroup) {
			menuActions.push({
				label: t('groups.menu.leave-group'),
				icon: 'person_remove',
				variant: 'error',
				onClick: () => handleOnLeave(),
				disabled: manageGroupMutation.isLoading,
			});
		} else {
			menuActions.push({
				label: t('groups.menu.join-group'),
				icon: 'person_add',
				onClick: () => handleOnJoin(),
				disabled: manageGroupMutation.isLoading,
			});
		}
	}

	const menuAction = useCardMenu(menuActions, { sx: { padding: 0 } });

	const groupPermissions = group?.permissions ?? [];

	const title = useGroupNameTranslation(group);

	return (
		<FancyTable.Row>
			<FancyTable.Row.Cell py={1}>
				<Box display='flex' alignItems='center' sx={groupNameBoxStyle}>
					{isSelectable ? (
						<CheckboxField
							value={isSelected}
							onChange={() => {
								onSelect(group.uuid);
							}}
						/>
					) : (
						<Box className='checkbox-placeholder' />
					)}
					<RouterLink
						to={router.getPathFor('community.group-view', {
							communityId,
							groupId: group.uuid,
						})}
						variant='button2'
					>
						{title}
					</RouterLink>
				</Box>
			</FancyTable.Row.Cell>
			<FancyTable.Row.Cell>
				<TruncatedList
					records={group?.members ?? []}
					maxRenderCount={4}
					renderItem={(member, inPopup) => (
						<Typography
							key={`member-group-${member.uuid}`}
							color={inPopup ? 'common.white' : 'common.black'}
						>
							{`${member.first_name} ${member.last_name}`}
						</Typography>
					)}
					PopoverProps={{
						anchorOrigin: {
							vertical: 'center',
							horizontal: 'left',
						},
						transformOrigin: {
							vertical: 'center',
							horizontal: 'right',
						},
					}}
					popoverContainerStyle={(theme: Theme) => ({
						backgroundColor: theme.palette.grey[900],
					})}
				/>
			</FancyTable.Row.Cell>
			{showGlobalPermissions ? (
				<FancyTable.Row.Cell>
					{
						(group.name === 'site_admin' ||
						groupPermissions.length === 31) &&
							<Typography>{t('group.all')}</Typography> // prettier-ignore
					}
					{
						group.name !== 'site_admin' &&
						groupPermissions.length === 0 && (
							<Typography>{t('group.none')}</Typography>
						) // prettier-ignore
					}
					{groupPermissions.length !== 31 && (
						<TruncatedList
							records={groupPermissions}
							maxRenderCount={4}
							renderItem={(
								{ entity_uuid, name, description },
								inPopup
							) => (
								<Typography
									key={`permission-${entity_uuid}-${name}`}
									color={
										inPopup
											? 'common.white'
											: 'common.black'
									}
								>
									{t(
										`advance-permissions.labels.${entity_uuid}.${name}`,
										{
											defaultValue: description,
										}
									)}
								</Typography>
							)}
							PopoverProps={{
								anchorOrigin: {
									vertical: 'center',
									horizontal: 'left',
								},
								transformOrigin: {
									vertical: 'center',
									horizontal: 'right',
								},
							}}
							popoverContainerStyle={(theme: Theme) => ({
								backgroundColor: theme.palette.grey[900],
							})}
						/>
					)}
				</FancyTable.Row.Cell>
			) : (
				<FancyTable.Row.Cell />
			)}
			<FancyTable.Row.Cell>
				<Typography>
					{group.is_public ? t('groups.public') : t('groups.private')}
				</Typography>
			</FancyTable.Row.Cell>
			<FancyTable.Row.Cell>
				<Box>{menuAction}</Box>
			</FancyTable.Row.Cell>
		</FancyTable.Row>
	);
};
