import { useRecaptcha, useTranslation } from 'hooks';
import { TFunction } from 'i18next';
import { useCallback, useMemo, useRef, FocusEvent, useEffect } from 'react';
import { sleep } from 'utils/delay';
import { phoneNumber } from 'utils/validation';
import * as Yup from 'yup';
import config from 'config';
import {
	Box,
	TextField,
	LoadingButton,
	Form,
	Field,
	CheckboxField,
	SelectField,
	SelectItem,
	Typography,
	Link,
	PasswordField,
	PasswordRulesChecker,
	TelephoneField,
	FormOnSubmitReturn,
	FormStack,
	Alert,
	CircularProgress,
} from '../../common';

export type CreateCommunityFormSubmittedValues = {
	name: string;
	purpose: string;
	description: string;
	zipCode: string;
	contact: string;
	searchEnabled: boolean;
	recaptchaToken: string | null;
	firstName?: string;
	lastName?: string;
	email?: string;
	password?: string;
	phone?: string;
};

type CreateCommunityFormProps = {
	onSubmit?: (
		values: CreateCommunityFormSubmittedValues
	) => FormOnSubmitReturn;
	submitText?: string;
	submittingText?: string;
	initialValues?: Partial<CreateCommunityFormSubmittedValues>;
	isLoggedIn: boolean;
	onCheckEmail?: (email: string) => void;
	emailCheckStatus?: 'loading' | 'success' | 'idle' | 'error';
	emailExists?: boolean;
};

export const CreateCommunityForm = (props: CreateCommunityFormProps) => {
	const {
		onSubmit,
		submitText,
		submittingText,
		initialValues,
		isLoggedIn,
		onCheckEmail,
		emailCheckStatus = 'idle',
		emailExists = false,
	} = props;
	const emailRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		const timeout = setTimeout(() => {
			if (emailRef.current?.value) {
				onCheckEmail?.(emailRef.current.value);
			}
		}, 1000);

		return () => clearTimeout(timeout);
	}, []);

	const { t } = useTranslation();

	const cachedInitialValues = useRef(initialValues).current;

	const recaptcha = useRecaptcha();

	const handleOnSubmit = useCallback(
		async ({
			name,
			description,
			purpose,
			zipCode,
			contact,
			searchEnabled,
			...restValues
		}) => {
			const token = await recaptcha.execute('create_community');

			await sleep(1);

			let values: CreateCommunityFormSubmittedValues = {
				name,
				description,
				purpose,
				zipCode,
				contact,
				searchEnabled,
				recaptchaToken: token,
			};

			if (!isLoggedIn) {
				const { email, password } = restValues;

				if (emailExists) {
					values = { ...values, email, password };
				} else {
					const { phone } = restValues;

					values = {
						...values,
						...restValues,
						phone: phone
							? `+${phone.dialCode}${phone.number}`
							: undefined,
					};
				}
			}

			const result = onSubmit?.({ ...values });

			return Promise.resolve(result).catch(error => error);
		},
		[onSubmit, isLoggedIn, emailExists]
	);

	const handleOnEmailChange = (e: FocusEvent<HTMLInputElement>) => {
		const value = (e.target as HTMLInputElement).value;
		onCheckEmail?.(value);
	};

	const purposeOptions = useMemo(
		() => [
			{
				value: 'caregiving',
				label: t('community-form.caregiving'),
			},
			{
				value: 'eldercare',
				label: t('community-form.elderly-care-and-long-term-care'),
			},
			{
				value: 'military',
				label: t('community-form.military-and-veterans-families'),
			},
			{
				value: 'volunteering',
				label: t('community-form.volunteering'),
			},
			{
				value: 'parenting',
				label: t('community-form.parenting'),
			},
			{
				value: 'schools',
				label: t('community-form.schools'),
			},
			{
				value: 'religious',
				label: t('community-form.religious-groups'),
			},
		],
		[]
	);

	const contactOptions = useMemo(
		() => [
			{
				label: t('community-form.owner'),
				value: 'owner',
			},
			{
				label: t('community-form.site-admins'),
				value: 'admin',
			},
		],
		[]
	);

	const showAccountForm =
		!isLoggedIn &&
		(emailCheckStatus === 'success' || emailCheckStatus === 'error') &&
		!emailExists;

	const validationSchema = useMemo(
		() => createValidationSchema(t, isLoggedIn, emailExists),
		[isLoggedIn, emailCheckStatus, emailExists]
	);

	return (
		<Form
			onSubmit={handleOnSubmit}
			validationSchema={validationSchema}
			subscription={{ submitting: true, submitError: true }}
			initialValues={cachedInitialValues}
			keepDirtyOnReinitialize
		>
			{({ handleSubmit, submitting, submitError }) => (
				<form onSubmit={handleSubmit} noValidate>
					<FormStack>
						<Box>
							<Field
								label={t('community-form.community-name')}
								name='name'
								component={TextField}
								type='text'
								fullWidth
								required
							/>
						</Box>
						<Box>
							<Field
								label={t('community-form.purpose')}
								name='purpose'
								component={SelectField}
								placeholder={t('community-form.purpose')}
								initialValue={purposeOptions[0].value}
								fullWidth
								required
								displayEmpty
							>
								{purposeOptions.map(purpose => (
									<SelectItem
										key={purpose.value}
										value={purpose.value}
									>
										{purpose.label}
									</SelectItem>
								))}
							</Field>
						</Box>
						<Box>
							<Field
								label={t('community-form.description')}
								name='description'
								component={TextField}
								type='text'
								placeholder=''
								multiline
								rows={4}
								fullWidth
								required
							/>
						</Box>
						<Box>
							<Field
								label={t('community-form.zip-code')}
								name='zipCode'
								component={TextField}
								type='text'
								placeholder=''
								helperText={t(
									'community-form.location-of-community'
								)}
								fullWidth
								required
							/>
						</Box>
						<Box>
							<Field
								label={t('community-form.community-contact')}
								name='contact'
								component={SelectField}
								placeholder={t(
									'community-form.community-contact'
								)}
								helperText={
									<ul>
										<li>
											{t(
												'community-form.owner-of-community'
											)}
										</li>
										<li>
											{t(
												'community-form.owner-site-admin'
											)}
										</li>
										<li>
											{t(
												'community-form.please-select-here'
											)}
										</li>
									</ul>
								}
								FormHelperTextProps={{
									component: 'div',
								}}
								fullWidth
								required
								displayEmpty
								initialValue={contactOptions[0].value}
							>
								{contactOptions.map(group => (
									<SelectItem
										key={group.value}
										value={group.value}
									>
										{group.label}
									</SelectItem>
								))}
							</Field>
						</Box>
						{/* Identity Section */}
						{!isLoggedIn && (
							<>
								<Box>
									<Typography component='p' variant='button1'>
										Your info
									</Typography>
									<Typography variant='caption'>
										Your profile will be created as a Site
										Admin. You can change this later.
									</Typography>
								</Box>
								<Box>
									<Field
										label={t('common-label.email')}
										inputRef={emailRef}
										placeholder={t(
											'community-form.type-your-email'
										)}
										name='email'
										component={TextField}
										type='text'
										validateFields={['password']}
										fullWidth
										required
										onBlur={handleOnEmailChange}
									/>
								</Box>
								<Box>
									<Field
										label={t('common-label.password')}
										name='password'
										component={PasswordField}
										type='password'
										fullWidth
										required
									/>
									<PasswordRulesChecker
										forField='password'
										usernameField='email'
										initialEmail={
											cachedInitialValues?.email || ''
										}
										disabled={!showAccountForm}
									/>
								</Box>
							</>
						)}
						{emailCheckStatus === 'loading' && (
							<CircularProgress size='small' />
						)}
						{showAccountForm && (
							<>
								<Box>
									<Field
										label={t('common-label.first-name')}
										name='firstName'
										component={TextField}
										type='text'
										fullWidth
										required
									/>
								</Box>
								<Box>
									<Field
										label={t('common-label.last-name')}
										name='lastName'
										component={TextField}
										type='text'
										fullWidth
										required
									/>
								</Box>
								<Box>
									<Field
										label={t('common-label.phone')}
										name='phone'
										component={TelephoneField}
										placeholder='(201) 555-0123'
										fullWidth
										required
									/>
								</Box>
							</>
						)}
						{/* End Identity Section */}
						<Box>
							<Field
								label={t('community-form.allow-friends-family')}
								name='searchEnabled'
								component={CheckboxField}
								initialValue
							/>
						</Box>
						<Box>
							<Field
								label={
									<Typography>
										{t('community-form.read-and-agree')}{' '}
										<Link
											href={`${config.urls.wordPress}/terms`}
											target='_blank'
										>
											{t('community-form.terms-of-use')}
										</Link>
									</Typography>
								}
								name='tou'
								component={CheckboxField}
							/>
						</Box>
						<Box>
							{submitError && (
								<Alert
									variant='filled'
									severity='error'
									color='error'
								>
									{submitError}
								</Alert>
							)}
						</Box>
						<Box>
							<LoadingButton
								loading={submitting}
								disabled={emailCheckStatus === 'loading'}
								loadingIndicator={
									submittingText || t('form.submitting')
								}
								size='small'
								type='submit'
							>
								{submitText || t('form.submit')}
							</LoadingButton>
						</Box>
					</FormStack>
				</form>
			)}
		</Form>
	);
};

function createValidationSchema(
	t: TFunction,
	isLoggedIn: boolean,
	emailExists: boolean
) {
	const communitySchema = Yup.object().shape({
		name: Yup.string().trim().required(t('form.generic-required')),
		purpose: Yup.string().required(t('form.generic-required')),
		description: Yup.string().trim().required(t('form.generic-required')),
		zipCode: Yup.string().required(t('form.generic-required')),
		contact: Yup.string().required(t('form.generic-required')),
		tou: Yup.boolean()
			.oneOf([true], t('form.generic-required'))
			.required(t('form.generic-required')),
	});

	if (!isLoggedIn) {
		const identitySchema = !emailExists
			? Yup.object().shape({
				firstName: Yup.string().required(
					t('form.generic-required')
				),
				lastName: Yup.string().required(t('form.generic-required')),
				email: Yup.string()
					.email(t('form.invalid-email'))
					.required(t('form.generic-required')),
				phone: phoneNumber(t('form.invalid-phone')).required(
					t('form.generic-required')
				),
			  })
			: Yup.object().shape({
				email: Yup.string()
					.email(t('form.invalid-email'))
					.required(t('form.generic-required')),
				password: Yup.string().required(t('form.generic-required')),
			  }); // prettier-ignore

		return communitySchema.concat(identitySchema);
	}

	return communitySchema;
}
