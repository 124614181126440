import { Box, Paper, Typography, Divider } from 'components/common';
import { Group, SingleGroup } from 'core/types';
import { useTranslation } from 'hooks';
import { useGroupNameTranslation } from './use-group-name-translation';

export type GroupCardProps = {
	group: Group;
	renderEnrollmentActions?: React.ReactNode;
	renderActions: React.ReactNode;
	showGlobalPermissions?: boolean;
};

export const GroupCard = ({
	group,
	renderEnrollmentActions,
	renderActions,
	showGlobalPermissions = false,
}: GroupCardProps) => {
	const { t } = useTranslation();

	const renderPermission = (data: SingleGroup[]) => {
		const groups = data.map(({ description }) => description);

		if (
			data.length === 0 ||
			(data.length === 1 && data[0].name === group.name)
		) {
			groups.push(t('group.admin'));
		}

		return groups.join(', ');
	};

	const title = useGroupNameTranslation(group);

	return (
		<Box component={Paper} elevation={0} p={3} mb={2} mt={1}>
			<Typography variant='h4' textAlign='center'>
				{title}
			</Typography>
			<Divider my={3} />
			<Box>
				<Typography component='span' fontWeight='bold'>
					{t('group.group-members')}:{' '}
				</Typography>
				<Typography component='span'>
					{group.name === 'member' ? (
						t('group.all')
					) : group.members?.length === 0 ? (
						t('group.none')
					) : (
						<>
							{(group?.members ?? [])
								.map(
									member =>
										`${member.first_name} ${member.last_name}`
								)
								.join(', ')}
						</>
					)}
				</Typography>
				{showGlobalPermissions && (
					<Box mt={2}>
						<Typography component='span' fontWeight='bold'>
							{t('group.group-permissions')}:{' '}
						</Typography>
						<Typography component='span'>
							{
								group.name === 'site_admin'
									? t('group.all-locked')
									: group?.permissions.length === 0
										? t('group.none')
										: group.permissions
											.map(
												({
													entity_uuid,
													name,
													description,
												}) => {
													return t(
														`advance-permissions.labels.${entity_uuid}.${name}`,
														{
															defaultValue:
															description,
														}
													);
												}
											)
											.join(', ') // prettier-ignore
							}
						</Typography>
					</Box>
				)}
				{renderEnrollmentActions && (
					<Box mt={2}>{renderEnrollmentActions}</Box>
				)}
			</Box>
			<Divider my={3} />
			<Box>
				<Typography>
					<Typography component='span' fontWeight='bold'>
						{t('group.group-visibility')}:{' '}
					</Typography>
					{renderPermission(group?.who_can_see ?? [])}
				</Typography>
				<Typography>
					<Typography component='span' fontWeight='bold'>
						{t('group.group-managers')}:{' '}
					</Typography>
					{renderPermission(group?.managers ?? [])}
				</Typography>
			</Box>
			{renderActions && (
				<Box mt={4} display='flex' justifyContent='center'>
					{renderActions}
				</Box>
			)}
		</Box>
	);
};
