import { Note } from 'core/types';
import {
	PaginatedList,
	Box,
	Paper,
	Typography,
	RouterLink,
} from 'components/common';
import { useGetNotes } from 'core/uses-cases/note';
import { useInfinityScrollPaginationHandler, useRouter } from 'hooks';
import { t } from 'i18next';
import { Fragment, useMemo } from 'react';
import { Trans } from 'react-i18next';
import { NoteCard } from './card';

export type NoteListContainerProps = {
	communityId: string;
	onDelete?: (note: Note) => void;
	onEdit?: (note: Note) => void;
	onClick?: (note: Note) => void;
	canCreate?: boolean;
	adminRoleId?: string;
};

export const NoteListContainer = (props: NoteListContainerProps) => {
	const { communityId, onDelete, onEdit, onClick, canCreate, adminRoleId } =
		props;
	const router = useRouter();
	const {
		data,
		isFetching,
		isSuccess,
		isFetchingNextPage,
		fetchNextPage,
		hasNextPage,
	} = useGetNotes(communityId, {
		enable: !!communityId,
	});

	const hasNotes = useMemo(() => {
		if (!data?.pages) return false;
		return data.pages.some(page => page.length > 0);
	}, [data?.pages]);

	const { handleOnLoad, showMoreButton, setShowMoreButton } =
		useInfinityScrollPaginationHandler<Note>({
			data,
			fetchNextPage,
			hasNextPage,
		});

	return (
		<PaginatedList
			onReachThreshold={handleOnLoad}
			onLoadMore={fetchNextPage}
			isFetching={isFetching}
			isFetchingMore={isFetchingNextPage}
			hasMore={!!hasNextPage}
			showButton={showMoreButton}
			setShowButton={setShowMoreButton}
		>
			{isSuccess && !isFetching && !hasNotes && (
				<Box
					component={Paper}
					elevation={0}
					px='20%'
					py={4}
					mb={2}
					mt={1}
					display='flex'
					justifyContent='center'
					alignItems='center'
					flexDirection='column'
					textAlign='center'
				>
					<Typography variant='body2' mb={0}>
						{canCreate ? (
							<>
								{t('notes.notes-help-you')}{' '}
								<Trans i18nKey='notes.share-a-note'>
									<RouterLink
										to={router.getPathFor(
											'community.groups',
											{
												communityId,
											}
										)}
									/>
								</Trans>
							</>
						) : (
							<>
								<Trans i18nKey='notes.there-are-currently-no-notes'>
									<RouterLink
										to={router.getPathFor(
											'community.team',
											{
												communityId,
											},
											{
												role: adminRoleId ?? '',
												sort_by: 'first_name',
											}
										)}
									/>
								</Trans>
							</>
						)}
					</Typography>
				</Box>
			)}
			{hasNotes && (
				<>
					{data?.pages.map(page => (
						<Fragment key={`page-${page?.[page.length - 1]?.uuid}`}>
							{page.map(item => {
								return (
									<Fragment key={item.uuid}>
										<NoteCard
											note={item}
											onDelete={onDelete}
											onEdit={onEdit}
											onClick={onClick}
											truncate
										/>
									</Fragment>
								);
							})}
						</Fragment>
					))}
				</>
			)}
		</PaginatedList>
	);
};
