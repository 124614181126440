import { useCallback } from 'react';
import * as Yup from 'yup';
import { useLogin } from 'core/uses-cases/authentication';
import { useRouter, useTranslation } from 'hooks';
import {
	LoadingButton,
	Box,
	Heading,
	Text,
	RouterLink,
	Stack,
	Paper,
	Grid,
	TextField,
	Form,
	Field,
	FieldForSubscription,
	Link,
} from 'components/common';
import config from 'config';
import { Trans } from 'react-i18next';
// import maintenance from 'assets/images/maintenance.png?url';

const validationSchema = Yup.object().shape({
	username: Yup.string()
		.strict(false)
		.trim()
		.email('Please enter a valid email address')
		.required('Please enter an email address'),
	password: Yup.string().required('Please enter a password'),
});

export const Login = () => {
	const { doLoginAsync } = useLogin();

	const router = useRouter();
	const { t } = useTranslation();

	const handleOnSubmit = useCallback(
		values => doLoginAsync(values).catch(() => null),
		[]
	);

	return (
		<Grid container>
			<Grid item md={4} xl={4} sm={2} xs={0} />
			{/* <img
				src={maintenance}
				alt='maintenance'
				style={{ width: '100%' }}
			/> */}
			<Grid item md={4} xl={4} sm={8} xs={12}>
				<Box
					component={Paper}
					px={4.5}
					py={3}
					elevation={0}
					display='flex'
					flexDirection='column'
				>
					<Form
						onSubmit={handleOnSubmit}
						validationSchema={validationSchema}
						subscription={{ submitting: true }}
					>
						{({ handleSubmit, submitting }) => (
							<form onSubmit={handleSubmit} noValidate>
								<Stack spacing={3} alignItems='center'>
									<Heading variant='h6'>
										{t('login.log-in')}
									</Heading>
									<Box alignSelf='stretch'>
										<Field
											name='username'
											component={TextField}
											type='text'
											placeholder={t(
												'common-label.email-address'
											)}
											fullWidth
										/>
									</Box>
									<Box alignSelf='stretch'>
										<Field
											name='password'
											component={TextField}
											type='password'
											placeholder={t(
												'common-label.password'
											)}
											fullWidth
										/>
									</Box>
									<LoadingButton
										sx={{ alignSelf: 'center' }}
										loading={submitting}
										loadingIndicator={t('login.logging-in')}
										size='small'
										type='submit'
									>
										{t('login.log-in')}
									</LoadingButton>
									<FieldForSubscription
										name='username'
										subscription={{
											value: true,
											valid: true,
										}}
									>
										{props => (
											<RouterLink
												to={router.getPathFor(
													'account.reset',
													{},
													{
														email: props.meta.valid
															? props.input.value
															: '',
													}
												)}
											>
												{t(
													'login.forgot-your-password'
												)}
											</RouterLink>
										)}
									</FieldForSubscription>
								</Stack>
							</form>
						)}
					</Form>
					<Box alignSelf='stretch'>
						<Box
							component='hr'
							border='none'
							borderTop='1px solid'
							borderColor={theme => theme.borderColors[0.1]}
							my={4}
							mx={2}
						/>
					</Box>
					<Box textAlign='center' color={theme => theme.blacks[0.54]}>
						<Text variant='body1'>
							<Trans
								i18nKey='login.no-account-message'
								components={{
									search: (
										<RouterLink
											to={router.getPathFor(
												'community.search'
											)}
										/>
									),
									create: (
										<RouterLink
											to={router.getPathFor(
												'community.create'
											)}
										/>
									),
								}}
							/>
						</Text>
						<Text variant='body1' mt={1}>
							<Trans
								i18nKey='login.having-trouble'
								components={{
									mail: (
										<Link
											href={`mailto:${config.supportEmail}`}
										/>
									),
								}}
							/>
						</Text>
					</Box>
				</Box>
			</Grid>
		</Grid>
	);
};
