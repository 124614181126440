import {
	Box,
	Button,
	CircularProgress,
	Divider,
	Link,
	PageHeading,
	Paper,
	Stack,
	Tooltip,
	Typography,
} from 'components/common';
import { useCurrentIdentity } from 'core/uses-cases/authentication';
import { useGetExportCalendarToken } from 'core/uses-cases/calendar';
import {
	useCopyLink,
	useTranslation,
	useCurrentCommunity,
	useRouter,
} from 'hooks';
import { Navigate } from 'navigation';

export const ExportCalendar = () => {
	const { t } = useTranslation();
	const community = useCurrentCommunity();
	const identity = useCurrentIdentity();
	const router = useRouter();

	const { data, isLoading, isError } = useGetExportCalendarToken(
		community?.calendar?.uuid ?? ''
	);

	const myTasksCopyLink = useCopyLink(
		`${data?.downloadUrl}?members=${identity?.uuid}`,
		1500,
		false
	);
	const allTasksCopyLink = useCopyLink(data?.downloadUrl, 1500, false);

	if (isLoading) {
		return (
			<Box display='flex' justifyContent='center' mt={4}>
				<CircularProgress message={t('common.loading')} />
			</Box>
		);
	}

	if (isError) {
		return (
			<Navigate
				to={router.getPathFor('community.home', {
					communityId: community?.slug,
				})}
				alert={{
					level: 'error' as const,
					message: t('export-calendar.invalid-token'),
				}}
				replace
			/>
		);
	}

	return (
		<Box>
			<PageHeading title={t('export-calendar.export-calendar')} mb={0}>
				<Typography variant='body2' mb={1} mr={{ md: '50%', sm: '0' }}>
					{t('export-calendar.use-your-care-calendar')}
				</Typography>
			</PageHeading>
			<Box component={Paper} elevation={0} mt={6} px={5} py={5}>
				<Stack spacing={6}>
					<Box>
						<Typography variant='h4'>
							{t('export-calendar.my-tasks')}
						</Typography>
						<Typography variant='body2'>
							{t('export-calendar.includes-all-events')}
						</Typography>
					</Box>
					<Box>
						<Tooltip
							open={myTasksCopyLink.isCopied}
							title={
								<>
									{t(
										'export-calendar.subscription-url-copied'
									)}
								</>
							}
							placement='top'
						>
							<Button
								onClick={myTasksCopyLink.handleOnCopy}
								size='small'
								variant='outlined'
								data-test-id='export-calendar_my-tasks-copy-subscription'
							>
								{t('export-calendar.copy-subscription-link')}
							</Button>
						</Tooltip>
					</Box>
					<Box>
						<Link
							data-test-id='export-calendar_my-tasks-download-calendar'
							component={Button}
							href={myTasksCopyLink.url}
							underline='none'
							color='common.white'
						>
							{t('export-calendar.download-calendar-file')}
						</Link>
					</Box>
				</Stack>
				<Divider my={6} />
				<Stack spacing={6}>
					<Box>
						<Typography variant='h4'>
							{t('export-calendar.all-tasks')}
						</Typography>
						<Typography variant='body2'>
							{t('export-calendar.includes-all-events-community')}
						</Typography>
					</Box>
					<Box>
						<Tooltip
							open={allTasksCopyLink.isCopied}
							title={
								<>
									{t(
										'export-calendar.subscription-url-copied'
									)}
								</>
							}
							placement='top'
						>
							<Button
								onClick={allTasksCopyLink.handleOnCopy}
								size='small'
								variant='outlined'
								data-test-id='export-calendar_all-tasks-copy-subscription'
							>
								{t('export-calendar.copy-subscription-link')}
							</Button>
						</Tooltip>
					</Box>
					<Box>
						<Link
							data-test-id='export-calendar_all-tasks-download-calendar'
							component={Button}
							href={allTasksCopyLink.url}
							underline='none'
							color='common.white'
						>
							{t('export-calendar.download-calendar-file')}
						</Link>
					</Box>
				</Stack>
			</Box>
		</Box>
	);
};
