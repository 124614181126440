import { isCancelledError, useMutation } from 'react-query';
import { Nullable, Community, Image } from 'core/types';
import { patchCommunityMutationKey } from 'core/utils/mutation-key-factory';
import { communityKeys } from 'core/utils/query-key-factory';
import { SetupMutationFn } from 'core/configure/types';
import {
	cancelPreviousMutation,
	updatePartiallyRecordFromInfinityQueryCache,
} from 'core/utils/optimistic-utils';
import produce from 'immer';
import { BaseHttpError } from '../../services/api/errors';

export interface UpdateCommunityImageMutationInput {
	communityId: string;
	name: string;
	image: Nullable<Image>;
}

export const useUpdateCommunityImage = () => {
	const mutation = useMutation<
		Community,
		BaseHttpError,
		UpdateCommunityImageMutationInput
	>(patchCommunityMutationKey);

	return mutation;
};

type MutationContext = {
	communitySnapshot: Community;
};

export const setupUpdateCommunityImage: SetupMutationFn = (
	services,
	createTrackedParallelMutation
) => {
	const { queryClient, api, uploader } = services;

	const mutation = createTrackedParallelMutation<
		Community,
		BaseHttpError,
		UpdateCommunityImageMutationInput,
		MutationContext
	>({
		mutationFn: async ({ communityId, image, name }) => {
			const remoteImage = await uploader.uploadImage(image);

			return api
				.patchCommunity(communityId, {
					name,
					image: remoteImage?.uuid,
				})
				.then(response => response.data);
		},
		onMutate: async input => {
			const { communityId, image } = input;

			const communitySnapshot = queryClient.getQueryData<Community>(
				communityKeys.detail(communityId)
			) as Community;

			// Cancel the previous mutation if it exists
			await cancelPreviousMutation<MutationContext>(
				queryClient,
				mutation => {
					return (
						mutation.state.variables?.communityId === communityId &&
						mutation?.options?.mutationKey ===
							patchCommunityMutationKey
					);
				}
			);

			const nextCommunity = queryClient.setQueryData<Community>(
				communityKeys.detail(communityId),
				data =>
					produce(data || communitySnapshot, draft => {
						draft.image = image;
					})
			);

			updatePartiallyRecordFromInfinityQueryCache(
				queryClient,
				communityKeys.list(),
				nextCommunity,
				['image']
			);

			return {
				communitySnapshot,
			};
		},
		onSuccess: (result, variables) => {
			updatePartiallyRecordFromInfinityQueryCache(
				queryClient,
				communityKeys.list(),
				result,
				['image']
			);

			queryClient.setQueryData<Community>(
				communityKeys.detail(variables.communityId),
				data =>
					produce(data || result, draft => {
						draft.image = result.image;
					})
			);
		},
		onError: (error, input, context) => {
			if (context?.communitySnapshot && !isCancelledError(error)) {
				queryClient.setQueryData(
					communityKeys.detail(input.communityId),
					context?.communitySnapshot
				);
			}
		},
		retry: 1,
	});

	queryClient.setMutationDefaults(patchCommunityMutationKey, mutation);
};
