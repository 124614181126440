import { SetupMutationFn } from 'core/configure/types';
import { AlbumInput } from 'core/services/api/api-client/types';
import { BaseHttpError } from 'core/services/api/errors';
import { Album, Community, Group } from 'core/types';
import {
	patchAlbumMutationKey,
	postAlbumMutationKey,
} from 'core/utils/mutation-key-factory';
import {
	cancelPreviousMutation,
	onPatchMutateOptimistic,
} from 'core/utils/optimistic-utils';
import { albumKeys, communityKeys } from 'core/utils/query-key-factory';
import { isCancelledError, Mutation, useMutation } from 'react-query';

export interface PatchAlbumMutationInput extends AlbumInput {
	albumId: string;
	communityId: string;
}

export const usePatchAlbum = () => {
	const mutation = useMutation<Album, BaseHttpError, PatchAlbumMutationInput>(
		patchAlbumMutationKey
	);

	return mutation;
};

type MutationContext = {
	albumId: string;
	communityId: string;
	updatedAlbum: Album;
	currentAlbumInfo: Album;
};

export const setupPatchAlbum: SetupMutationFn = (
	services,
	createTrackedParallelMutation,
	mutationTracker
) => {
	const { queryClient, api } = services;

	const mutation = createTrackedParallelMutation<
		Album,
		BaseHttpError,
		PatchAlbumMutationInput,
		MutationContext
	>({
		mutationFn: ({ albumId, ...data }) => {
			return api
				.patchAlbum(albumId, data)
				.then(response => response.data);
		},
		onMutate: async input => {
			const {
				albumId,
				communityId,
				name,
				visibility,
				visibilityGroups,
				upload,
				uploadGroups,
			} = input;

			const community = queryClient.getQueryData<Community>(
				communityKeys.detail(communityId)
			);

			const currentAlbumInfo = queryClient.getQueryData<Album>(
				albumKeys.detail(communityId, albumId)
			) as Album;

			const groupOptions = formatAlbumGroups(community?.groups.all ?? []);

			const updatedAlbum: Album = {
				...currentAlbumInfo,
				name,
				settings: {
					visibilitySettings: {
						privacy: visibility,
						groups: visibilityGroups.map(
							groupId => groupOptions[groupId]
						),
					},
					uploadSettings: {
						upload,
						groups: uploadGroups.map(
							groupId => groupOptions[groupId]
						),
					},
				},
			};

			await cancelPreviousMutation<MutationContext>(
				queryClient,
				mutation => {
					return (
						mutation.state.context?.albumId === albumId &&
						isPostPatchAlbumMutation(mutation)
					);
				}
			);

			onPatchMutateOptimistic<Album>(
				queryClient,
				albumKeys.list(communityId),
				albumKeys.detail(communityId, albumId),
				updatedAlbum
			);

			return {
				albumId,
				communityId,
				updatedAlbum,
				currentAlbumInfo,
			};
		},
		onSettled: (_data, error, _variables, context) => {
			if (context && !isCancelledError(error)) {
				mutationTracker.queueInvalidations(
					albumKeys.list(context.communityId),
					albumKeys.detail(context.communityId, context.albumId)
				);
			}
		},
		retry: 1,
	});

	queryClient.setMutationDefaults(patchAlbumMutationKey, mutation);
};

export const isPostPatchAlbumMutation = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	mutation: Mutation<any, any, any, any>
) =>
	[patchAlbumMutationKey, postAlbumMutationKey].includes(
		String(mutation.options.mutationKey)
	);

export const formatAlbumGroups = (communityGroups: Group[]) => {
	return communityGroups.reduce(
		(
			groups: {
				[key: string]: {
					id: string;
					name: string;
					description: string;
				};
			},
			currentGroup
		) => {
			groups[currentGroup.uuid] = {
				id: currentGroup.uuid,
				name: currentGroup.name,
				description: currentGroup.description,
			};

			return groups;
		},
		{}
	);
};
