import { useDialog } from 'components/common';
import {
	useDeleteWellWish,
	DeleteWellWishMutationInput,
} from 'core/uses-cases/well-wish';
import { useServices, useTranslation } from 'hooks';
import { useCallback } from 'react';

export const useDeleteWellWishHandler = (
	input: DeleteWellWishMutationInput
) => {
	const { t } = useTranslation();
	const { notification } = useServices();
	const { mutateAsync } = useDeleteWellWish();

	const { open } = useDialog({
		title: t('well-wishes.delete-confirm-title'),
		content: t('well-wishes.delete-confirm-content'),
		confirmText: t('common.delete'),
		onConfirm: useCallback(() => {
			mutateAsync(input).then(() => {
				notification.show(
					'success',
					t('well-wishes.well-wish-was-successfully-deleted')
				);
			});
		}, [input]),
	});

	return open;
};
