import Grid from '@mui/material/Grid';
import { DateTime } from 'luxon';
import { Fragment } from 'react';
import { Typography } from 'components/common/text';
import classNames from 'classnames';
import { useCalendar } from 'components/calendar/context';
import { useEventsForDay } from 'components/calendar/shared/use-get-events';
import { EventsBox } from 'components/calendar/shared/events';
import { hasSameMonth, isToday, hasSameDay } from '../../utils';
import { monthDayStyle, weekContainerStyle } from './styles';

type MonthRowProps = {
	weekDays: DateTime[];
};

type MonthDayProps = {
	day: DateTime;
};

const MonthDay = ({ day }: MonthDayProps) => {
	const {
		displayed,
		selected,
		setSelected,
		formatDate,
		onEventClick,
		timezone,
	} = useCalendar();

	const events = useEventsForDay(day);

	return (
		<Grid item xs={12 / 7}>
			<EventsBox
				onClick={() => setSelected(day)}
				sx={monthDayStyle}
				className={classNames({
					'from-other-month': !hasSameMonth(day, displayed),
					'is-today': isToday(day, timezone),
					'is-selected': selected ? hasSameDay(day, selected) : false,
				})}
				events={events}
				onEventClick={onEventClick}
			>
				<Typography variant='body1' fontWeight={400}>
					{formatDate(day, 'd')}
				</Typography>
			</EventsBox>
		</Grid>
	);
};

export const MonthWeekRow = ({ weekDays }: MonthRowProps) => {
	return (
		<Grid container item spacing={0} sx={weekContainerStyle}>
			{weekDays.map(day => (
				<Fragment key={`day-${day.toISO()}`}>
					<MonthDay day={day} />
				</Fragment>
			))}
		</Grid>
	);
};
