import { useMemo } from 'react';
import { formatNumber, parsePhoneNumber } from 'utils/phone';
import { CommunityMember, Preferences } from 'core/types';
import { parsePreferences } from 'utils/subscription';
import { useCurrentCommunity, useTranslation } from 'hooks';
import { DateTime } from 'luxon';
import { DEFAULT_DELIVERY_HOUR } from 'components/email-preferences/constants';
import { OptionType } from 'components';

export const useMemberFormData = (
	record: CommunityMember,
	preferences: Preferences
) => {
	const community = useCurrentCommunity();
	const { t } = useTranslation();
	const parsedPhone = parsePhoneNumber(record?.identity.phone ?? '');
	const parsedPreferences = parsePreferences(preferences);

	const initialValues = useMemo(
		() => ({
			firstName: record?.identity.first_name,
			lastName: record?.identity.last_name,
			phone: {
				number: formatNumber(parsedPhone, 'NATIONAL'),
				regionCode: parsedPhone?.country?.toLowerCase(),
				dialCode: parsedPhone?.countryCallingCode,
			},
			email: record?.identity.email,
			helpWith: record?.help_provided,
			groups: record?.groups.map(group => group.name),
			administrativeNotes: record?.administrative_notes,
			eventReminderTime: parsedPreferences?.eventReminderTime,
			emailPreferences: parsedPreferences?.emailPreferences,
			digestFrequency: parsedPreferences?.digestFrequency,
			deliveryDay: parsedPreferences?.deliveryDay ?? 'Sunday',
			deliveryDays: parsedPreferences?.deliveryDays ?? [],
			deliveryTime:
				parsedPreferences?.deliveryTime ??
				DateTime.fromISO(`${DEFAULT_DELIVERY_HOUR}:00:00`),
		}),
		[record, parsedPreferences]
	);

	const groupsOptions: OptionType[] = useMemo(
		() =>
			community?.groups.all
				.filter(group => group.name !== 'member')
				.map(group => ({
					label: group.description,
					value: group.name,
				})) ?? [],
		[]
	);

	const reminderOptions: OptionType[] = useMemo(
		() => [
			{
				label: t('edit-member-profile.5-days'),
				value: '5 days',
			},
			{
				label: t('edit-member-profile.1-day'),
				value: '1 day',
			},
			{
				label: t('edit-member-profile.1-hour'),
				value: '1 hour',
			},
			{
				label: t('edit-member-profile.30-minutes'),
				value: '30 minutes',
			},
			{
				label: t('edit-member-profile.10-minutes'),
				value: '10 minutes',
			},
		],
		[]
	);

	return {
		initialValues,
		groupsOptions,
		reminderOptions,
	};
};
