import { Box, Field, Form, LoadingButton, TextField } from 'components/common';
import { useTranslation } from 'hooks';
import { useCallback, useMemo } from 'react';
import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { sleep } from 'utils/delay';
import { ContactSupportInput } from 'core/services/api/api-client/types';
import { FormApi } from 'final-form';

export type ContactSupportFormProps = {
	onSubmit?: (values: ContactSupportInput) => void;
};

export const ContactSupportForm = (props: ContactSupportFormProps) => {
	const { onSubmit } = props;
	const { t } = useTranslation();

	const handleOnSubmit = useCallback(
		async (values, form: FormApi) => {
			await sleep(1);
			const result = onSubmit?.(values);
			return Promise.resolve(result)
				.then(() => form.restart())
				.catch(error => error);
		},
		[onSubmit]
	);

	const validationSchema = useMemo(() => createValidationSchema(t), []);

	return (
		<Form
			onSubmit={handleOnSubmit}
			validationSchema={validationSchema}
			subscription={{ submitting: true, submitError: true }}
		>
			{({ handleSubmit, submitting }) => (
				<form onSubmit={handleSubmit} noValidate>
					<Box mb={6}>
						<Field
							name='fullName'
							label={t('help-finding.your-full-name')}
							component={TextField}
							type='text'
							fullWidth
							required
						/>
					</Box>
					<Box mb={6}>
						<Field
							name='email'
							label={t('help-finding.your-email')}
							component={TextField}
							type='text'
							fullWidth
							required
						/>
					</Box>
					<Box mb={6}>
						<Field
							name='communityName'
							label={t('help-finding.community-name')}
							component={TextField}
							type='text'
							fullWidth
							required
						/>
					</Box>
					<Box mb={6}>
						<Field
							name='communityLocation'
							label={t('help-finding.community-location')}
							component={TextField}
							type='text'
							fullWidth
							required
						/>
					</Box>
					<Box mb={6}>
						<Field
							name='beingHelped'
							label={t('help-finding.who-is-being-helped')}
							component={TextField}
							type='text'
							fullWidth
							required
						/>
					</Box>
					<Box mb={6}>
						<Field
							name='otherMembers'
							label={t('help-finding.other-members')}
							component={TextField}
							type='text'
							fullWidth
						/>
					</Box>
					<Box>
						<LoadingButton
							loading={submitting}
							loadingIndicator={t(
								'help-finding.submitting-request'
							)}
							size='small'
							type='submit'
						>
							{t('help-finding.submit-request')}
						</LoadingButton>
					</Box>
				</form>
			)}
		</Form>
	);
};

function createValidationSchema(t: TFunction) {
	return Yup.object().shape({
		fullName: Yup.string()
			.trim()
			.required(
				t('form.required', {
					name: t('help-finding.your-full-name'),
				})
			),
		email: Yup.string()
			.trim()
			.email(t('form.invalid-email'))
			.required(
				t('form.required', {
					name: t('help-finding.your-email'),
				})
			),
		communityName: Yup.string()
			.trim()
			.required(
				t('form.required', {
					name: t('help-finding.community-name'),
				})
			),
		communityLocation: Yup.string()
			.trim()
			.required(
				t('form.required', {
					name: t('help-finding.community-location'),
				})
			),
		beingHelped: Yup.string()
			.trim()
			.required(
				t('form.required', {
					name: t('help-finding.who-is-being-helped'),
				})
			),
		otherMembers: Yup.string().trim(),
	});
}
