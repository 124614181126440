import { useServices } from 'core/services';
import { BaseHttpError } from 'core/services/api/errors';
import { GroupsResponse } from 'core/services/api/response-types';
import { Group } from 'core/types';
import { getPageParams } from 'core/utils/pagination';
import { communityKeys } from 'core/utils/query-key-factory';
import { useInfiniteQuery } from 'react-query';

export const useGetCommunityGroups = (communityId: string) => {
	const { api, authentication } = useServices();
	const isLoggedIn = authentication.isAuthenticated();

	return useInfiniteQuery<GroupsResponse, BaseHttpError, Group[]>(
		communityKeys.groups(communityId),
		({ pageParam = null, signal }) => {
			return api.getGroups(communityId, {
				pagination: pageParam,
				signal,
			});
		},
		{
			enabled: !!communityId && isLoggedIn,
			select: response => {
				return {
					pages: response.pages.map(page => page.data),
					pageParams: response.pageParams,
				};
			},
			getPreviousPageParam: firstPage =>
				getPageParams(firstPage.pagination).previousParam,
			getNextPageParam: lastPage =>
				getPageParams(lastPage.pagination).nextParam,
			notifyOnChangeProps: 'tracked',
			refetchOnMount: true,
			keepPreviousData: true,
		}
	);
};
