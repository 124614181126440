import { isCancelledError, Mutation, useMutation } from 'react-query';
import { communityKeys, permissionKeys } from 'core/utils/query-key-factory';
import { deleteCommunityMemberMutationKey } from 'core/utils/mutation-key-factory';
import { SetupMutationFn } from 'core/configure/types';
import { Services } from 'core/services';
import { Community, CommunityMember } from 'core/types';
import {
	cancelPreviousMutation,
	onDeleteMutateOptimistic,
	onDeleteMutateOptimisticInfinityQueryCache,
} from 'core/utils/optimistic-utils';
import { BaseHttpError } from '../../services/api/errors';

export interface DeleteCommunityMemberMutationInput {
	communityId: string;
	memberId: string;
	sendNotification?: boolean;
	leaveCommunity?: boolean;
	accessToken?: string;
}

export const useDeleteCommunityMember = () => {
	const mutation = useMutation<
		null,
		BaseHttpError,
		DeleteCommunityMemberMutationInput
	>(deleteCommunityMemberMutationKey);

	return mutation;
};

type MutationContext = {
	listSnapshot: CommunityMember[];
};

export const setupDeleteCommunityMember: SetupMutationFn = (
	services: Services,
	createTrackedParallelMutation,
	mutationTracker
) => {
	const { queryClient, api } = services;

	const mutation = createTrackedParallelMutation<
		null,
		BaseHttpError,
		DeleteCommunityMemberMutationInput,
		MutationContext
	>({
		mutationFn: ({
			communityId,
			memberId,
			sendNotification,
			accessToken,
		}) => {
			return api.deleteCommunityMember(
				communityId,
				memberId,
				sendNotification,
				accessToken
			);
		},
		onMutate: async input => {
			const { communityId, memberId, leaveCommunity } = input;

			await queryClient.cancelQueries(communityKeys.list());

			// Cancel the previous mutation if it exists
			await cancelPreviousMutation<MutationContext>(
				queryClient,
				mutation => {
					return (
						mutation.state.variables?.communityId === communityId &&
						mutation.state.variables?.memberId === memberId &&
						isDeleteCommunityMember(mutation)
					);
				}
			);

			const listSnapshot =
				await onDeleteMutateOptimistic<CommunityMember>(
					queryClient,
					memberId,
					communityKeys.members(communityId),
					communityKeys.member(communityId, memberId)
				);

			const community = queryClient.getQueryData<Community>(
				communityKeys.detail(communityId)
			) as Community;

			if (community && leaveCommunity) {
				await onDeleteMutateOptimisticInfinityQueryCache<Community>(
					queryClient,
					communityKeys.list(),
					communityKeys.detail(communityId),
					community.uuid
				);
			}

			return { listSnapshot };
		},
		onError: (error, { communityId }, context) => {
			if (context?.listSnapshot && !isCancelledError(error)) {
				queryClient.setQueryData(
					communityKeys.members(communityId),
					context?.listSnapshot
				);
			}
		},
		onSettled: (data, error, { communityId }, context) => {
			if (context && !isCancelledError(error)) {
				mutationTracker.queueInvalidations(
					communityKeys.all(),
					permissionKeys.all(communityId)
				);
			}
		},
		retry: 1,
	});

	queryClient.setMutationDefaults(deleteCommunityMemberMutationKey, mutation);
};

export const isDeleteCommunityMember = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	mutation: Mutation<any, any, any, any>
) =>
	[deleteCommunityMemberMutationKey].includes(
		String(mutation?.options?.mutationKey)
	);
