import { useTranslation } from 'hooks';
import { ReactNode, useEffect } from 'react';

export const SetTitleElement = (props: {
	children: ReactNode;
	title: string;
}) => {
	const { t } = useTranslation();

	useEffect(() => {
		document.title = t(`title.${props.title}`);
	}, [t, props.title]);

	return <>{props.children}</>;
};

export const createTitleElement = (title: string, element: ReactNode) => {
	return <SetTitleElement title={title}>{element}</SetTitleElement>;
};
