import {
	Box,
	Field,
	Form,
	FormOnSubmitReturn,
	FormStack,
	LoadingButton,
	SelectMultipleField,
	SelectMultipleOptions,
} from 'components/common';
import { GroupPermissions } from 'core/types';
import { getDefaultGlobalPermissions } from 'core/uses-cases/community';
import { useTranslation } from 'hooks';
import { useCallback, useMemo, useRef } from 'react';
import { sleep } from 'utils/delay';

export type GroupPermissionsFormSubmittedValues = {
	permissions: GroupPermissions[];
};

export type GroupPermissionsFormInitialValues =
	Partial<GroupPermissionsFormSubmittedValues>;

export type GroupPermissionsFormProps = {
	onSubmit?: (
		values: GroupPermissionsFormSubmittedValues
	) => FormOnSubmitReturn;
	submitText?: string;
	submittingText?: string;
	initialValues?: GroupPermissionsFormInitialValues;
};

const defaultPermissions = getDefaultGlobalPermissions();

export const GroupPermissionsForm = (props: GroupPermissionsFormProps) => {
	const { onSubmit, submitText, submittingText, initialValues } = props;

	const { t } = useTranslation();

	const cachedInitialValues = useRef({
		permissions: [],
		...initialValues,
	}).current;

	const handleOnSubmit = useCallback(
		async ({ permissions }) => {
			await sleep(1);
			const result = onSubmit?.({ permissions });
			return Promise.resolve(result).catch(error => error);
		},
		[onSubmit]
	);

	const permissionsOptions = useMemo(() => {
		const options: SelectMultipleOptions<string>[] = [];
		Object.entries(defaultPermissions).forEach(([key, permissions]) => {
			Object.entries(permissions).forEach(([permission]) => {
				options.push({
					label: t(`advance-permissions.labels.${key}.${permission}`),
					value: `${key}:${permission}`,
				});
			});
		});
		return options;
	}, []);

	return (
		<Form
			onSubmit={handleOnSubmit}
			subscription={{ submitting: true }}
			initialValues={cachedInitialValues}
		>
			{({ handleSubmit, submitting }) => (
				<form onSubmit={handleSubmit} noValidate>
					<FormStack>
						<Box>
							<Field
								name='permissions'
								component={SelectMultipleField}
								options={permissionsOptions}
								label={t('group-form.permission-options')}
							/>
						</Box>
						<Box>
							<LoadingButton
								loading={submitting}
								loadingIndicator={
									submittingText ||
									t('group-form.updating-group')
								}
								size='small'
								type='submit'
							>
								{submitText || t('group-form.update-group')}
							</LoadingButton>
						</Box>
					</FormStack>
				</form>
			)}
		</Form>
	);
};
