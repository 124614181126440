import { SxProps } from '@mui/system';
import { Box, BoxProps, Typography, Heading } from 'components';
import React from 'react';
import { bodyStyle } from './style';

export interface FeedCardContentProps extends Omit<BoxProps, 'title'> {
	title?: string | React.ReactNode;
	content: string;
	contentWrapper?: React.ReactElement;
}

export const FeedCardContent = ({
	title,
	content,
	children,
	contentWrapper,
	sx,
	...rest
}: FeedCardContentProps) => {
	// Check if content contains html tags
	const isHtml = /<[^>]*>/.test(content);

	let contentText: React.ReactNode;

	// If content contains html tags, render it as html
	if (!isHtml) {
		contentText = (
			<Typography
				variant='body2'
				sx={[bodyStyle, ...(sx ? [sx] : [])] as SxProps}
			>
				{content}
			</Typography>
		);
	} else {
		contentText = (
			<Box
				sx={[bodyStyle, ...(sx ? [sx] : [])] as SxProps}
				dangerouslySetInnerHTML={{
					__html: content,
				}}
			/>
		);
	}

	const contentRendered = contentWrapper
		? React.Children.map(contentWrapper, (child) => {
			if (React.isValidElement(child)) {
				return React.cloneElement(child, {}, contentText);
			}

			return child;
		  })
		: contentText; // prettier-ignore

	return (
		<Box mt={3} ml={8} {...rest}>
			{title && (
				<Heading variant='h4' mb={2}>
					{title}
				</Heading>
			)}
			{contentRendered}
			{children}
		</Box>
	);
};
