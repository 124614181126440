import { Routes, Route, Navigate } from 'react-router-dom';
import { RouterLink } from 'components/common';
import { useTranslation } from 'hooks';
import { Box, Grid } from '../common/layout';
import { CalendarProvider, CalendarProviderProps } from './context';
import { Sidebar } from './shared';
import { CalendarHeader } from './shared/header';
import { Month, Week, Day, Year, Agenda } from './views';

export function Calendar(props: CalendarProviderProps) {
	const { t } = useTranslation();

	return (
		<CalendarProvider {...props}>
			<Grid container spacing={2}>
				<Grid item xs={12} lg={3}>
					<Sidebar />
				</Grid>
				<Grid item xs={12} lg={9}>
					<CalendarHeader />
					<Box bgcolor={theme => theme.palette.common.white}>
						<Routes>
							<Route index element={<Month />} />
							<Route path='day' element={<Day />} />
							<Route path='week' element={<Week />} />
							<Route path='month' element={<Month />} />
							<Route path='year' element={<Year />} />
							<Route path='agenda' element={<Agenda />} />
							<Route
								path='*'
								element={<Navigate to='month' replace />}
							/>
						</Routes>
					</Box>
				</Grid>
				<Grid item xs={12}>
					<RouterLink to={props.exportLink}>
						{t('calendar-widget.export-calendar')}
					</RouterLink>
				</Grid>
			</Grid>
		</CalendarProvider>
	);
}
