import { SxProps } from '@mui/system';
import { Theme } from 'theme';

export const imageCard: SxProps<Theme> = {
	position: 'relative',
	cursor: 'pointer',

	'> img': { maxWidth: '100%' },
	'&:hover': {
		'> div': { display: 'block' },
	},
};

export const overlayContainer: SxProps = {
	display: 'none',
	position: 'absolute',
	top: 0,
	bottom: 0,
	left: 0,
	right: 0,
};

export const deleteButton: SxProps = {
	position: 'absolute',
	top: 10,
	right: 10,
	fontSize: 25,
	backgroundColor: 'common.white',
	color: 'common.black',
	borderRadius: '50%',
};

export const photoInfo: SxProps<Theme> = theme => ({
	position: 'absolute',
	bottom: 10,
	right: 10,
	left: 10,
	borderRadius: 24,
	bgcolor: theme.secondaryColors.secondary6,
	padding: '12px 22px',
	display: 'flex',

	'> p': {
		width: '70%',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	},
});

export const commentInfo: SxProps<Theme> = theme => ({
	display: 'flex',
	marginLeft: 'auto',
	alignItems: 'center',

	'> p': {
		display: 'flex',
		bgcolor: theme.secondaryColors.secondary3,
		color: theme.primaryColors.primary3,
		width: 22,
		height: 22,
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: '100%',
		marginRight: 1,
	},
});
