import { SetupMutationFn } from 'core/configure/types';
import { BaseHttpError } from 'core/services/api/errors';
import { Album, AlbumPhoto } from 'core/types';
import {
	communityKeys,
	albumPhotoKeys,
	albumKeys,
} from 'core/utils/query-key-factory';
import { isCancelledError, useMutation } from 'react-query';
import { deleteAlbumPhotoMutationKey } from 'core/utils/mutation-key-factory';
import {
	cancelPreviousMutation,
	findRecordFromInfinityQueryCache,
	onDeleteMutateOptimistic,
	onDeleteMutateOptimisticInfinityQueryCache,
	PaginatedRecordsSnapshot,
	removeRecordFromInfinityQueryCache,
} from 'core/utils/optimistic-utils';
import { isPostPatchPhotoMutation } from './use-post-community-photo';

export interface DeletePhotoMutationInput {
	communityId: string;
	albumId: string;
	photoId: string;
	lastPhoto?: boolean;
}

export const useDeletePhoto = () => {
	const mutation = useMutation<
		AlbumPhoto,
		BaseHttpError,
		DeletePhotoMutationInput
	>(deleteAlbumPhotoMutationKey);

	return mutation;
};

type MutationContext = {
	communityId: string;
	albumId: string;
	photoId: string;
	currentCommunityPhotoInfo: {
		listSnapshot: PaginatedRecordsSnapshot<AlbumPhoto>;
	} | null;
	currentAlbumPhotoInfo: {
		listSnapshot: PaginatedRecordsSnapshot<AlbumPhoto>;
	} | null;
};

export const setupDeleteAlbumPhoto: SetupMutationFn = (
	services,
	createTrackedParallelMutation,
	mutationTracker
) => {
	const { queryClient, api } = services;

	const mutation = createTrackedParallelMutation<
		null,
		BaseHttpError,
		DeletePhotoMutationInput,
		MutationContext
	>({
		mutationFn: ({ photoId, albumId }) => {
			if (photoId.length === 22) {
				return api.deleteAlbumPhoto(albumId, photoId);
			}
			return Promise.resolve(null);
		},
		onMutate: async input => {
			const { photoId, albumId, communityId, lastPhoto } = input;

			await cancelPreviousMutation<MutationContext>(
				queryClient,
				mutation => {
					return (
						isPostPatchPhotoMutation(mutation) &&
						mutation.state.context?.photoId === photoId
					);
				}
			);

			const currentCommunityPhotoInfo =
				findRecordFromInfinityQueryCache<AlbumPhoto>(
					queryClient,
					albumPhotoKeys.list(communityId),
					photoId
				);

			if (currentCommunityPhotoInfo) {
				await onDeleteMutateOptimisticInfinityQueryCache<AlbumPhoto>(
					queryClient,
					albumPhotoKeys.list(communityId),
					albumPhotoKeys.detail(communityId, photoId),
					photoId
				);
			}

			if (lastPhoto) {
				await onDeleteMutateOptimistic<Album>(
					queryClient,
					albumId,
					albumKeys.list(communityId)
				);
			}

			removeRecordFromInfinityQueryCache(
				queryClient,
				communityKeys.feed(communityId),
				photoId
			);

			const currentAlbumPhotoInfo =
				findRecordFromInfinityQueryCache<AlbumPhoto>(
					queryClient,
					albumPhotoKeys.list(albumId),
					photoId
				);

			if (currentAlbumPhotoInfo) {
				await onDeleteMutateOptimisticInfinityQueryCache<AlbumPhoto>(
					queryClient,
					albumPhotoKeys.list(albumId),
					albumPhotoKeys.detail(albumId, photoId),
					photoId
				);
			}

			return {
				communityId,
				albumId,
				photoId,
				currentCommunityPhotoInfo,
				currentAlbumPhotoInfo,
			};
		},
		onError: (error, input, context) => {
			if (
				context?.currentCommunityPhotoInfo &&
				!isCancelledError(error)
			) {
				queryClient.setQueryData(
					albumPhotoKeys.list(context.communityId),
					context?.currentCommunityPhotoInfo.listSnapshot
				);
			}

			if (context?.currentAlbumPhotoInfo && !isCancelledError(error)) {
				queryClient.setQueryData(
					albumPhotoKeys.list(context.albumId),
					context?.currentAlbumPhotoInfo.listSnapshot
				);
			}
		},
		onSettled: (data, error, { photoId }, context) => {
			if (context && !isCancelledError(error)) {
				if (photoId.length === 22) {
					// Only trigger the refetch when it is a real resource
					mutationTracker.queueInvalidations(
						albumPhotoKeys.list(context.communityId),
						albumKeys.list(context.communityId),
						communityKeys.feed(context.communityId),
						albumPhotoKeys.list(context.albumId)
					);
				}
			}
		},
		retry: 1,
	});

	queryClient.setMutationDefaults(deleteAlbumPhotoMutationKey, mutation);
};
