import { Box, Defer, Grid } from 'components/common';
import { useCallback, useEffect, useMemo } from 'react';
import { DateTime } from 'luxon';
import { useCalendar } from 'components/calendar/context';
import { CalendarNavigation } from '../../shared/navigation';
import { containerStyle } from './styles';
import { YearMonth } from './month';

export const Year = () => {
	const { displayed, setDisplayed, formatDate, loadEvents } = useCalendar();

	const months = useMemo(() => {
		return [...Array(12).keys()].map((key: number) => {
			const monthIndex = key + 1;

			const month = DateTime.fromFormat(
				`${displayed.year}-${monthIndex}-01`,
				'yyyy-M-dd'
			);

			return { month };
		});
	}, [displayed.year]);

	useEffect(() => {
		const startDate = displayed.startOf('year');
		const endDate = displayed.endOf('year');

		loadEvents({ startDate, endDate });
	}, [displayed]);

	const handleOnPressNext = useCallback(() => {
		setDisplayed(displayed.plus({ year: 1 }));
	}, [displayed]);

	const handleOnPressPrev = useCallback(() => {
		setDisplayed(displayed.minus({ year: 1 }));
	}, [displayed]);

	return (
		<Box>
			<CalendarNavigation
				display={formatDate(displayed, 'yyyy')}
				onNext={handleOnPressNext}
				onPrev={handleOnPressPrev}
			/>
			<Box sx={containerStyle}>
				<Grid container spacing={2}>
					<Defer chunkSize={9}>
						{months.map(row => (
							<Grid
								key={`year-month-${row.month.toISO()}`}
								item
								xs={6}
								sm={6}
								md={4}
								mb={2}
							>
								<YearMonth month={row.month} />
							</Grid>
						))}
					</Defer>
				</Grid>
			</Box>
		</Box>
	);
};
