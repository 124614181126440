import {
	Box,
	Form,
	FormOnSubmitReturn,
	LoadingButton,
} from 'components/common';
import { AdvanceCommunityPermissions, Group } from 'core/types';
import { getDefaultGlobalPermissions } from 'core/uses-cases/community';
import { useTranslation, useConstantValue } from 'hooks';
import { useCallback } from 'react';
import { sleep } from 'utils/delay';
import { ManagePermissionsGridFields } from './grid-fields';
import { ManagePermissionsTabsFields } from './tabs-fields';

export type ManagePermissionsFormSubmittedValues = AdvanceCommunityPermissions;

export type ManagePermissionsFormProps = {
	onSubmit?: (
		values: ManagePermissionsFormSubmittedValues
	) => FormOnSubmitReturn;
	initialValues?: ManagePermissionsFormSubmittedValues;
	groups: Group[];
};

const FormWrapper: React.FC<Omit<ManagePermissionsFormProps, 'groups'>> = ({
	onSubmit,
	initialValues = getDefaultGlobalPermissions(),
	children,
}) => {
	const { t } = useTranslation();

	const cachedInitialValues = useConstantValue(() => ({
		...initialValues,
	}));

	const handleOnSubmit = useCallback(
		async values => {
			await sleep(1);
			const result = onSubmit?.(values);
			return Promise.resolve(result).catch(error => error);
		},
		[onSubmit]
	);

	return (
		<Form
			onSubmit={handleOnSubmit}
			initialValues={cachedInitialValues}
			subscription={{ submitting: true }}
		>
			{({ handleSubmit, submitting }) => (
				<form onSubmit={handleSubmit} noValidate>
					{children}
					<Box mt={2}>
						<LoadingButton
							loading={submitting}
							loadingIndicator={t(
								'advance-permissions.saving-global-permissions'
							)}
							size='small'
							type='submit'
						>
							{t('advance-permissions.save-global-permissions')}
						</LoadingButton>
					</Box>
				</form>
			)}
		</Form>
	);
};

export const ManagePermissionsForm = ({
	groups,
	...rest
}: ManagePermissionsFormProps) => {
	return (
		<>
			<Box display={{ xs: 'none', sm: 'block' }}>
				<FormWrapper {...rest}>
					<ManagePermissionsGridFields groups={groups} />
				</FormWrapper>
			</Box>
			<Box display={{ xs: 'block', sm: 'none' }}>
				<FormWrapper {...rest}>
					<ManagePermissionsTabsFields groups={groups} />
				</FormWrapper>
			</Box>
		</>
	);
};
