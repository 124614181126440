import {
	useCurrentCommunity,
	useRouter,
	useServices,
	useTranslation,
} from 'hooks';
import { useShutdownCommunity } from 'core/uses-cases/community';
import { Box, Link, Typography } from 'components/common';
import { useCallback } from 'react';
import { Trans } from 'react-i18next';
import {
	ShutdownCommunitySubmittedValues,
	ShutdownForm,
} from 'components/community/shutdown';
import { useCurrentIdentity } from 'core/uses-cases/authentication';
import { Navigate } from 'react-router-dom';

export const ShutdownCommunity = () => {
	const community = useCurrentCommunity();
	const identity = useCurrentIdentity();
	const { t } = useTranslation();
	const { notification } = useServices();
	const router = useRouter();
	const communityMutation = useShutdownCommunity();

	const handleOnSubmit = useCallback(
		(values: ShutdownCommunitySubmittedValues) => {
			communityMutation.mutate({
				communityId: community?.slug ?? '',
				...values,
			});

			notification.show(
				'success',
				t('shutdown-community.community-disabled')
			);

			router.navigate('community.switch', {});
		},
		[community]
	);

	if (community?.owner?.uuid !== identity?.uuid) {
		return (
			<Navigate
				to={router.getPathFor('community.home', {
					communityId: community?.slug,
				})}
				replace
			/>
		);
	}

	return (
		<Box>
			<Typography mt={1} variant='h4'>
				{t('shutdown-community.shutdown-community-name', {
					community: community?.name,
				})}
			</Typography>
			<Typography mt={1}>
				{t("shutdown-community.we're-sorry-to-see-you-go")}
			</Typography>
			<Typography mt={1}>
				{t('shutdown-community.community-shutdown-immediately')}
			</Typography>
			<Typography mt={1}>
				<Trans i18nKey='shutdown-community.have-questions-before-shutdown'>
					<Link href='mailto:support@lotsahelpinghands.com' />
				</Trans>
			</Typography>
			<ShutdownForm onSubmit={handleOnSubmit} />
		</Box>
	);
};
