import {
	Group,
	Identity,
	Nullable,
	Event,
	StringKeys,
	Location,
} from 'core/types';
import { useConstantValue, useGetEventTypesMap, useTranslation } from 'hooks';
import { TFunction } from 'i18next';
import { DateTime } from 'luxon';
import { useCallback, useMemo, useRef } from 'react';
import { sleep } from 'utils/delay';
import { luxonDateTime } from 'utils/validation';
import * as Yup from 'yup';
import arrayMutators from 'final-form-arrays';
import config from 'config';
import { ButtonSection } from 'components/common/form/button-section';
import {
	Box,
	TextField,
	Form,
	Field,
	Grid,
	SelectMultipleField,
	SelectField,
	SelectItem,
	ImagePickerField,
	PickerFile,
	WysiwygField,
	FormOnSubmitReturn,
	VisibilityField,
	visibilityFieldValidationSchema,
	FormStack,
	VisibilityFieldValues,
	DatePickerCalendarMultipleField,
	Typography,
	TimeZoneField,
	FilePicker,
	LocationsField,
	FilePickerFile,
	DatePickerField,
	TimePickerField,
	CheckboxField,
	When,
} from 'components/common';
import { FormSpy } from 'react-final-form';
import { TimeSlotsFields } from './time-slot-field';

type VolunteerVisibilityValues = VisibilityFieldValues<{
	volunteer: VisibilityFieldValues['visibility'];
	volunteerGroups: VisibilityFieldValues['visibilityGroups'];
}>;

export type EventFormSubmittedValues = {
	type: Event['type'];
	name: string;
	description: string;
	image: Nullable<PickerFile>;
	attachments: FilePickerFile[];
	coordinators: string[];
	volunteersNeeded: number;
	timezone: string;
	locations: Location[];
	dates: string[];
	startTimes: string[];
	endTimes: string[];
	volunteers?: string[];
} & VisibilityFieldValues &
	VolunteerVisibilityValues;

export type EventFormInitialValues = Partial<EventFormSubmittedValues>;

export type EventFormProps = {
	onSubmit?: (values: EventFormSubmittedValues) => FormOnSubmitReturn<Event>;
	submitText?: string;
	submittingText?: string;
	identities: Identity[];
	groups: Group[];
	initialValues?: EventFormInitialValues;
	editMode?: boolean;
	isSubmitting?: boolean;
	onDelete?: () => void;
	deleteText?: string;
};

export function EventForm(props: EventFormProps) {
	const {
		onSubmit,
		submitText,
		submittingText,
		identities,
		groups,
		initialValues,
		editMode = false,
		isSubmitting = false,
		onDelete,
		deleteText,
	} = props;

	const originalNeeded = useRef(initialValues?.volunteersNeeded ?? 1);
	const volunteerFocus = useRef('');
	const { t } = useTranslation();

	const cachedInitialValues = useConstantValue(() => {
		const {
			dates = [],
			startTimes = [],
			endTimes = [],
			attachments = [],
			image = null,
			timezone,
			...rest
		} = initialValues ?? {};

		return {
			locations: [],
			volunteers: [],
			timezone: timezone || config.defaultTimezone,
			...rest,
			dates: dates.map(time => DateTime.fromISO(time)),
			times: startTimes.map((time, index) => {
				return {
					start: DateTime.fromISO(time),
					end: endTimes?.[index]
						? DateTime.fromISO(endTimes?.[index])
						: null,
				};
			}),
			image: image ? [image] : null,
			attachments: attachments,
			allDay:
				editMode && startTimes.length === 0 && endTimes.length === 0,
		};
	});

	const handleOnSubmit = useCallback(
		async ({
			visibility,
			visibilityGroups,
			volunteer,
			volunteerGroups,
			image,
			times,
			dates,
			allDay,
			...restValues
		}) => {
			await sleep(1);

			let startTimes = [];
			let endTimes = [];

			if (allDay === false) {
				[startTimes, endTimes] = times.reduce(
					(
						carry: string[][],
						{
							start,
							end,
						}: { start: DateTime | null; end: DateTime | null }
					) => {
						carry[0].push(start?.toFormat('HH:mm:ss') ?? '');
						carry[1].push(end?.toFormat('HH:mm:ss') ?? '');

						return carry;
					},
					[[], []]
				);
			}

			const values: EventFormSubmittedValues = {
				...restValues,
				dates: dates.map((date: DateTime) =>
					date.toFormat('yyyy-MM-dd')
				),
				image: image?.[0] ?? null,
				startTimes,
				endTimes,
				visibility,
				visibilityGroups:
					visibility === 'custom' ? visibilityGroups : [],
				volunteer,
				volunteerGroups: volunteer === 'custom' ? volunteerGroups : [],
			};

			const result = onSubmit?.(values);

			return Promise.resolve(result).catch(error => error);
		},
		[onSubmit]
	);

	const eventTypes = useGetEventTypesMap();

	const eventTypeOptions = (
		Object.keys(eventTypes) as StringKeys<typeof eventTypes>
	).map(key => ({
		value: key,
		label: eventTypes[key],
	}));

	const identityOptions = useMemo(
		() =>
			identities.map(({ uuid, first_name, last_name }) => ({
				label: `${first_name} ${last_name}`,
				value: uuid,
			})),
		[identities]
	);

	const validationSchema = useMemo(() => createValidationSchema(t), []);

	return (
		<Form
			onSubmit={handleOnSubmit}
			validationSchema={validationSchema}
			subscription={{
				submitting: true,
			}}
			initialValues={cachedInitialValues}
			mutators={{
				...arrayMutators,
			}}
		>
			{({ handleSubmit, submitting, form }) => (
				<form onSubmit={handleSubmit} noValidate>
					<FormSpy
						onChange={({ values }) => {
							let volunteersNeeded = +values.volunteersNeeded;

							if (isNaN(volunteersNeeded)) {
								volunteersNeeded = 0;
							}

							const volunteers = values.volunteers?.length ?? 0;
							const active = volunteerFocus.current;

							if (active === 'volunteersNeeded') {
								if (volunteersNeeded <= volunteers) {
									form.change('volunteersNeeded', volunteers);
								} else {
									originalNeeded.current = volunteersNeeded;
								}

								if (volunteersNeeded < 0) {
									form.change('volunteersNeeded', 0);
								}

								if (volunteersNeeded > 10) {
									form.change('volunteersNeeded', 10);
								}
							}

							if (active === 'volunteers') {
								if (volunteersNeeded < volunteers) {
									form.change('volunteersNeeded', volunteers);
								}

								if (volunteersNeeded > volunteers) {
									if (volunteers >= originalNeeded.current) {
										form.change(
											'volunteersNeeded',
											volunteers
										);
									}
								}
							}
						}}
					/>
					<FormStack responsive={false}>
						<Box>
							<Grid container spacing={3}>
								<Grid item xs={12} md={6}>
									<Field
										label={t('event-form.name')}
										name='name'
										component={TextField}
										type='text'
										fullWidth
										required
									/>
								</Grid>
								<Grid item xs={12} md={6}>
									<Field
										label={t('event-form.type')}
										name='type'
										component={SelectField}
										defaultValue='meal'
										fullWidth
										required
										displayEmpty
									>
										{eventTypeOptions.map(type => (
											<SelectItem
												key={type.value}
												value={type.value}
											>
												{type.label}
											</SelectItem>
										))}
									</Field>
								</Grid>
							</Grid>
						</Box>
						<Box>
							<Field
								label={t('event-form.description')}
								name='description'
								component={WysiwygField}
								placeholder=''
								fullWidth
								required
							/>
						</Box>
						<Box>
							<Grid container spacing={3}>
								<Grid item xs={12} md={5}>
									<Field
										label={t('event-form.photo')}
										name='image'
										component={ImagePickerField}
									/>
								</Grid>
								<Grid item xs={12} md={4}>
									<Field
										name='attachments'
										component={FilePicker}
										label={t('event-form.attachments')}
										addButtonText={t(
											'event-form.add-an-attachment'
										)}
										isMultiple
									/>
								</Grid>
							</Grid>
						</Box>
						<FormStack>
							<Box>
								<Field
									name='coordinators'
									component={SelectMultipleField}
									options={identityOptions}
									loading={identityOptions.length === 0}
									label={t('event-form.coordinators')}
									max={10}
									required
								/>
							</Box>
							<Box>
								<Field
									name='volunteers'
									onFocus={() => {
										volunteerFocus.current = 'volunteers';
									}}
									component={SelectMultipleField}
									options={identityOptions}
									loading={identityOptions.length === 0}
									label={t('event-form.volunteers')}
									max={10}
								/>
							</Box>
							<Box>
								<Field
									name='volunteersNeeded'
									onFocus={() => {
										volunteerFocus.current =
											'volunteersNeeded';
									}}
									component={TextField}
									label={t('event-form.volunteers-needed')}
									helperText={t('event-form.per-time-slot')}
									defaultValue={originalNeeded.current}
									type='number'
								/>
							</Box>
							{editMode ? (
								<>
									<Box>
										<Field
											component={DatePickerField}
											name='dates.0'
											label={t('event-form.date')}
											fullWidth
										/>
									</Box>
									<Box>
										<Box>
											<Field
												inputLabel={t(
													'event-form.time'
												)}
												label={t(
													'event-form.all-day-event'
												)}
												name='allDay'
												component={CheckboxField}
											/>
										</Box>
										<When field='allDay' is={false}>
											<Box
												mt={1}
												display='flex'
												alignItems='flex-start'
											>
												<Box mr={2}>
													<Field
														label={t(
															'event-form.start-time'
														)}
														component={TimePickerField} // prettier-ignore
														name='times.0.start'
													/>
												</Box>
												<Box>
													<Field
														label={t(
															'event-form.end-time'
														)}
														component={TimePickerField} // prettier-ignore
														name='times.0.end'
													/>
												</Box>
											</Box>
											<Box mt={3}>
												<Field
													name='timezone'
													component={TimeZoneField}
													label={t(
														'event-form.timezone'
													)}
													update
												/>
											</Box>
										</When>
									</Box>
								</>
							) : (
								<>
									<Box alignSelf='flex-start'>
										<Typography
											variant='body2'
											color='primary'
											fontWeight='bold'
										>
											{t('event-form.task-date-and-time')}
										</Typography>
										<Field
											name='dates'
											component={
												// eslint-disable-next-line react/jsx-indent
												DatePickerCalendarMultipleField
											}
											label={t('event-form.dates')}
											hideErrorMessage
											required
										/>
									</Box>
									<Box>
										<TimeSlotsFields
											label={t('event-form.times')}
											name='times'
										/>
									</Box>
									<Box>
										<Field
											name='timezone'
											component={TimeZoneField}
											label={t('event-form.timezone')}
											required
											update
										/>
									</Box>
								</>
							)}
							<Box>
								<Field
									component={LocationsField}
									name='locations'
									label={t('event-form.locations')}
									placeholder={t(
										'event-form.add-new-location'
									)}
								/>
							</Box>
							<Box>
								<VisibilityField
									name='visibility'
									groups={groups}
									label={t(
										'visibility-field.who-can-see-this'
									)}
									required
								/>
							</Box>
							<Box>
								<VisibilityField
									name='volunteer'
									groups={groups}
									label={t('event-form.who-can-volunteer')}
									required
								/>
							</Box>
						</FormStack>
						<ButtonSection
							submitting={submitting || isSubmitting}
							submitText={submitText}
							submittingText={submittingText}
							onDelete={onDelete}
							deleteText={deleteText}
						/>
					</FormStack>
				</form>
			)}
		</Form>
	);
}

function createValidationSchema(t: TFunction) {
	const schema = Yup.object()
		.shape({
			name: Yup.string()
				.strict(false)
				.trim()
				.required(
					t('form.required', {
						name: t('event-form.name'),
					})
				),
			description: Yup.string().required(
				t('form.required', {
					name: t('event-form.description'),
				})
			),
			coordinators: Yup.array()
				.of(Yup.string())
				.required()
				.min(1, t('event-form.at-least-one-coordinator')),
			dates: Yup.array().of(luxonDateTime()).min(1),
			times: Yup.array().of(
				Yup.object({
					start: luxonDateTime('Invalid Time')
						.nullable(true)
						.when('end', {
							is: (end: DateTime) => end !== null,
							then: schema => schema.required('Required'),
						}),
					end: luxonDateTime('Invalid Time').nullable(true),
				})
			),
			volunteersNeeded: Yup.number().min(0).max(10),
		})
		.concat(visibilityFieldValidationSchema('visibility'))
		.concat(visibilityFieldValidationSchema('volunteer'));

	return schema;
}
