import { Box, ButtonRouterLink, Icon } from 'components/common';
import { GroupList } from 'components/groups';
import { useShowGlobalPermission } from 'components/permissions/use-show-global-permission';
import { TeamHeader } from 'components/team';
import { Group } from 'core/types';
import {
	useDeleteCommunityGroup,
	useGetCommunityGroups,
} from 'core/uses-cases/group';
import {
	useInfinityScrollPaginationHandler,
	useParams,
	useRouter,
	useTranslation,
	useVerifyAccess,
} from 'hooks';
import { Trans } from 'react-i18next';

export const ManageGroups = () => {
	const { t } = useTranslation();
	const router = useRouter();
	const { communityId } = useParams() as { communityId: string };
	const { data, fetchNextPage, isFetching, isFetchingNextPage, hasNextPage } =
		useGetCommunityGroups(communityId);

	const { handleOnLoad, showMoreButton, setShowMoreButton } =
		useInfinityScrollPaginationHandler<Group>({
			data,
			fetchNextPage,
			hasNextPage,
		});

	const deleteGroupMutation = useDeleteCommunityGroup();

	const handleOnDelete = (groupIds: string[]) => {
		for (const groupId of groupIds) {
			deleteGroupMutation.mutate({ communityId, groupId });
		}
	};

	const showGlobalPermissions = useShowGlobalPermission();

	const verify = useVerifyAccess();

	const canCreateGroup = verify('create', 'group');

	return (
		<>
			<TeamHeader
				title={t('groups.manage-groups')}
				button={
					canCreateGroup ? (
						<ButtonRouterLink
							to={router.getPathFor('community.group-create', {
								communityId,
							})}
							startIcon={
								<Icon
									name='add_circle_outline'
									fontSize='large'
								/>
							}
							size='small'
						>
							{t('groups.create-group')}
						</ButtonRouterLink>
					) : null
				}
				description={
					<Trans
						i18nKey='groups.manage-groups-description'
						components={{ italic: <i /> }}
					/>
				}
			/>
			<Box mt={3}>
				<GroupList
					data={data}
					communityId={communityId}
					onDelete={handleOnDelete}
					PaginatedListProps={{
						onReachThreshold: handleOnLoad,
						onLoadMore: fetchNextPage,
						isFetching: isFetching,
						isFetchingMore: isFetchingNextPage,
						hasMore: !!hasNextPage,
						showButton: showMoreButton,
						setShowButton: setShowMoreButton,
					}}
					showGlobalPermissions={showGlobalPermissions}
				/>
			</Box>
		</>
	);
};
