import { commentWitParams } from 'core/utils/request-with-params';
import { BaseHttpClient } from '../base-http-client';
import {
	CommentRepliesResponse,
	CommentReplyResponse,
	CommentResponse,
	CommentsResponse,
} from '../response-types';
import { PaginationArgs, CommentInput } from './types';

export const createDiscussionApi = (http: BaseHttpClient) => ({
	/**
	 * A function that is getting comments from the API.
	 *
	 * @param {string} discussionId
	 * @param {{
	 * 			pagination?: PaginationArgs;
	 * 			signal?: AbortSignal;
	 * 		}} [options={}]
	 * @return {*}
	 */
	getComments(
		discussionId: string,
		options: {
			pagination?: PaginationArgs;
			signal?: AbortSignal;
		} = {}
	) {
		const { pagination, signal } = options;

		return http
			.get<CommentsResponse>(
				`discussion/${discussionId}/comment`,
				{
					...pagination,
					order: 'DESC',
					with: commentWitParams,
				},
				{
					signal,
				}
			)
			.then(response => response.data);
	},

	/**
	 * A function that is getting comment replies from the API.
	 *
	 * @param {string} commentId
	 * @param {{
	 * 			pagination?: PaginationArgs;
	 * 			signal?: AbortSignal;
	 * 		}} [options={}]
	 * @return {*}
	 */
	getCommentReplies(
		commentId: string,
		options: {
			pagination?: PaginationArgs;
			signal?: AbortSignal;
		} = {}
	) {
		const { pagination, signal } = options;

		return http
			.get<CommentRepliesResponse>(
				`comment/${commentId}/reply`,
				{
					...pagination,
					order: 'DESC',
					with: commentWitParams,
				},
				{
					signal,
				}
			)
			.then(response => response.data);
	},

	/**
	 * Creates a comment on a discussion.
	 *
	 * @param {string} discussionId
	 * @param {CommentInput} input
	 * @return {*}
	 */
	postComment(discussionId: string, input: CommentInput) {
		return http
			.post<CommentResponse>(
				`discussion/${discussionId}/comment`,
				{ ...input },
				{
					params: {
						with: commentWitParams,
					},
				}
			)
			.then(response => response.data);
	},

	/**
	 * Deletes a comment.
	 *
	 * @param {string} commentId
	 * @return {*}
	 */
	deleteComment(commentId: string) {
		return http.delete(`comment/${commentId}`).then(() => null);
	},

	/**
	 * Updates a comment on a discussion.
	 *
	 * @param {string} commentId
	 * @param {CommentInput} input
	 * @return {*}
	 */
	patchComment(commentId: string, input: CommentInput) {
		return http
			.patch<CommentReplyResponse>(
				`comment/${commentId}`,
				{
					...input,
				},
				{
					params: {
						with: commentWitParams,
					},
				}
			)
			.then(response => response.data);
	},

	/**
	 * Creates a comment reply on a comment.
	 *
	 * @param {string} commentId
	 * @param {CommentInput} input
	 * @return {*}
	 */
	postCommentReply(commentId: string, input: CommentInput) {
		return http
			.post<CommentReplyResponse>(
				`comment/${commentId}/reply`,
				{ ...input },
				{
					params: {
						with: commentWitParams,
					},
				}
			)
			.then(response => response.data);
	},
});
