import { Fragment, useCallback } from 'react';
import {
	Grid,
	ButtonRouterLink,
	Box,
	Heading,
	CircularProgress,
	PaginatedList,
} from 'components/common';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import {
	useGetInvitedCommunities,
	useGetMyCommunities,
	useGetPendingCommunities,
} from 'core/uses-cases/identity';
import { CommunityCard } from 'components/community';
import { Community } from 'core/types';
import { useRouter, useTranslation } from 'hooks';
import { useCurrentIdentity } from 'core/uses-cases/authentication';

export const CommunitySwitch = () => {
	const router = useRouter();

	const { t } = useTranslation();

	const identity = useCurrentIdentity();

	const {
		data,
		isLoading,
		isFetching,
		isFetchingNextPage,
		fetchNextPage,
		hasNextPage,
	} = useGetMyCommunities();

	const { data: pendingCommunities, isLoading: isLoadingPending } =
		useGetPendingCommunities();

	const { data: invitedCommunities, isLoading: isLoadingInvited } =
		useGetInvitedCommunities();

	const extractCommunityRole = useCallback((community: Community) => {
		if (community.uuid.length !== 22) {
			return '';
		}

		if (community.owner?.uuid === identity?.uuid) {
			return t('switch.owner');
		} else if (community.contact_info.is_site_admin) {
			return t('switch.site-admin');
		} else {
			return t('switch.member');
		}
	}, []);

	if (isLoading || isLoadingInvited || isLoadingPending) {
		return (
			<Box display='flex' justifyContent='center' mt={4}>
				<CircularProgress
					message={t('switch.loading-your-communities')}
				/>
			</Box>
		);
	}

	return (
		<Box>
			<Grid container justifyContent='space-between' mb={4}>
				<Grid item xs={12} sm={6}>
					<Heading variant='h4' data-test-id='my-communities'>
						{t('switch.my-communities')}
					</Heading>
				</Grid>
				<Grid item xs={12} sm={6} display='flex' justifyContent='end'>
					<ButtonRouterLink
						to={router.getPathFor('community.create')}
						startIcon={
							<AddCircleOutlineOutlinedIcon fontSize='large' />
						}
						size='small'
					>
						{t('switch.create-a-community')}
					</ButtonRouterLink>
				</Grid>
			</Grid>
			<PaginatedList
				onLoadMore={fetchNextPage}
				onReachThreshold={fetchNextPage}
				isFetching={isFetching}
				isFetchingMore={isFetchingNextPage}
				hasMore={!!hasNextPage}
				showButton={false}
			>
				<Grid container spacing={3} mb={2}>
					{invitedCommunities?.map(community => (
						<Grid key={community.uuid} item xs={12} md={6}>
							<CommunityCard
								community={community}
								currentRole={t('switch.invited')}
								sx={{
									':hover': {
										boxShadow: 1,
									},
								}}
							/>
						</Grid>
					))}
					{pendingCommunities?.map(community => (
						<Grid key={community.uuid} item xs={12} md={6}>
							<CommunityCard
								community={community}
								currentRole={t('switch.pending')}
								sx={{
									':hover': {
										boxShadow: 1,
									},
								}}
							/>
						</Grid>
					))}
					{data?.pages.map(page => (
						<Fragment key={`page-${page?.[page.length - 1]?.uuid}`}>
							{page.map(community => {
								return (
									<Grid
										key={community.uuid}
										item
										xs={12}
										md={6}
									>
										<CommunityCard
											community={community}
											currentRole={extractCommunityRole(
												community
											)}
											sx={{
												':hover': {
													boxShadow: 1,
												},
											}}
										/>
									</Grid>
								);
							})}
						</Fragment>
					))}
				</Grid>
			</PaginatedList>
		</Box>
	);
};
