import { BaseHttpError } from 'core/services/api/errors';
import { Event } from 'core/types';
import { calendarKeys } from 'core/utils/query-key-factory';
import { useServices } from 'hooks';
import { useCallback } from 'react';
import { useQuery, useQueryClient, onlineManager } from 'react-query';
import { EventsCache } from './use-get-events';

export const useGetEvent = (
	calendarId: string,
	eventId: string,
	options: { enable: boolean } = { enable: true }
) => {
	const { api, authentication } = useServices();

	const isLoggedIn = authentication.isAuthenticated();

	const queryClient = useQueryClient();

	const getInitialData = useCallback(() => {
		// If it is online, then fetch the event from the API
		if (onlineManager.isOnline()) return undefined;

		const result = queryClient.getQueryData<EventsCache>(
			calendarKeys.all(calendarId)
		);

		return result?.byId[eventId] ?? undefined;
	}, [calendarId, eventId]);

	const eventQuery = useQuery<Event, BaseHttpError>(
		calendarKeys.detail(calendarId, eventId),
		() => api.getEvent(calendarId, eventId).then(response => response.data),
		{
			enabled: !!eventId && isLoggedIn && options.enable,
			initialData: getInitialData,
		}
	);

	return eventQuery;
};
