import { SetupMutationFn } from 'core/configure/types';
import { BaseHttpError } from 'core/services/api/errors';
import { Album } from 'core/types';
import { patchAlbumOrderMutationKey } from 'core/utils/mutation-key-factory';
import { cancelPreviousMutation } from 'core/utils/optimistic-utils';
import { albumKeys } from 'core/utils/query-key-factory';
import { isCancelledError, useMutation } from 'react-query';

export type AlbumOrderInfo = {
	uuid: string;
	sort_order: number;
};

export interface PatchAlbumOrderMutationInput {
	communityId: string;
	newOrder: Album[];
}

export const usePatchAlbumOrder = () => {
	const mutation = useMutation<
		Album[],
		BaseHttpError,
		PatchAlbumOrderMutationInput
	>(patchAlbumOrderMutationKey);

	return mutation;
};

type MutationContext = {
	communityId: string;
	originalOrder: Album[];
	newOrder: Album[];
};

export const setupPatchAlbumOrder: SetupMutationFn = (
	services,
	createTrackedParallelMutation,
	mutationTracker
) => {
	const { queryClient, api } = services;

	const mutation = createTrackedParallelMutation<
		Album[],
		BaseHttpError,
		PatchAlbumOrderMutationInput,
		MutationContext
	>({
		mutationFn: ({ communityId, newOrder }) => {
			const data = newOrder.map((album, index) => ({
				uuid: album.uuid,
				sort_order: index,
			}));

			return api
				.saveAlbumOrder(communityId, data)
				.then(response => response.albums);
		},
		onMutate: async input => {
			const { communityId, newOrder } = input;

			const originalOrder =
				queryClient.getQueryData<Album[]>(
					albumKeys.list(communityId)
				) ?? [];

			queryClient.setQueryData<Album[]>(
				albumKeys.list(communityId),
				newOrder
			);

			await cancelPreviousMutation<MutationContext>(
				queryClient,
				mutation =>
					mutation.options.mutationKey === patchAlbumOrderMutationKey
			);

			return {
				communityId,
				originalOrder,
				newOrder,
			};
		},
		onError: (error, _variables, context) => {
			if (context?.originalOrder && !isCancelledError(error)) {
				queryClient.setQueryData(
					albumKeys.list(context.communityId),
					context?.originalOrder
				);
			}
		},
		onSettled: (_data, error, _variables, context) => {
			if (context && !isCancelledError(error)) {
				mutationTracker.queueInvalidations(
					albumKeys.list(context.communityId)
				);
			}
		},
		retry: 1,
	});

	queryClient.setMutationDefaults(patchAlbumOrderMutationKey, mutation);
};
