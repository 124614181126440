import { useCallback } from 'react';
import {
	Form as FinalForm,
	FormProps as FinalFormProps,
} from 'react-final-form';
import * as Yup from 'yup';
import { Decorator, FORM_ERROR, setIn } from 'final-form';
import { scrollToFirstErrorDecorator } from './decorators';

export { FORM_ERROR };

type validatorType = (value: unknown) => undefined & string;

const composeValidators = (...validators: validatorType[]) => {
	return (value: unknown) => {
		return validators.reduce(
			(error, validator: validatorType) => error || validator(value),
			undefined
		);
	};
};

export interface FormProps<T = Record<string, any>, I = Partial<T>>
	extends FinalFormProps<T, I> {
	validation?: {
		[key: string]: validatorType | validatorType[];
	};
	validationSchema?: Yup.ObjectSchema<Record<string, any>>;
}

export type FormOnSubmitReturn<T = Record<string, unknown>> =
	| void
	| T
	| Promise<T | null | void>
	| null;

export function Form<T, I = Partial<T>>(props: FormProps<T, I>) {
	const { initialValues, validationSchema, validation, ...rest } = props;

	const validateViaSchema = useCallback(
		values => {
			let errors = {};

			try {
				validationSchema?.validateSync(values, {
					abortEarly: false,
				});
			} catch (e: unknown) {
				if (e instanceof Yup.ValidationError) {
					errors = e.inner.reduce(
						(
							formError: Record<string, string>,
							innerError: Yup.ValidationError
						) => {
							return setIn(
								formError,
								innerError?.path ?? 'global',
								innerError.message
							);
						},
						{}
					);
				} else if (e instanceof Error) {
					errors = { [FORM_ERROR]: e.message };
				} else {
					errors = { [FORM_ERROR]: 'Unknown error' };
				}
			}

			return errors;
		},
		[validationSchema]
	);

	const validateViaCustom = useCallback(
		values => {
			if (!validation) return {};

			return Object.keys(validation).reduce((c, k) => {
				if (!validation[k]) return c;

				const validator = validation[k];

				let result;

				if (typeof validator === 'function') {
					result = validator(values[k]);
				} else if (Array.isArray(validator)) {
					result = composeValidators(...validator)(values[k]);
				}

				if (result !== undefined) {
					return { ...c, [k]: result };
				}

				return c;
			}, {});
		},
		[validation]
	);

	const validate = useCallback(
		values => {
			return validationSchema
				? validateViaSchema(values)
				: validateViaCustom(values);
		},
		[validationSchema, validation]
	);

	return (
		<FinalForm<T, I>
			initialValues={initialValues}
			validate={validate}
			decorators={
				[scrollToFirstErrorDecorator] as unknown as Decorator<T, I>[]
			}
			{...rest}
		/>
	);
}
