import { useMemo } from 'react';
import { Nullable, Image } from 'core/types';
import appConfig from 'config';
import { createImageServerUrl } from 'utils/url';

export type ImageServerConfig = {
	fit?: string;
	bg?: string;
	w?: number;
	h?: number;
};

export const useImageServer = (
	image?: Nullable<Image>,
	config: ImageServerConfig = {}
) => {
	const processedImage = useMemo(() => {
		if (!appConfig.imageServer) return image;
		if (!image) return image;
		if (image.url.endsWith('.svg')) return image;
		if (image.file) return image;

		return {
			...image,
			url: createImageServerUrl(image.url, config),
		};
	}, [image, config]);

	return processedImage;
};
