import { FormApi } from 'final-form';

export const scrollToFirstErrorDecorator = (form: FormApi) => {
	const scrollToFirstError = (
		errors: Record<string, unknown>,
		touched?: Record<string, boolean>
	) => {
		const inputs: HTMLInputElement[] = Array.from(
			document.querySelectorAll('[name]')
		);

		const firstInput = inputs.find(input => {
			if (input.tagName === 'META' || !input.name) {
				return false;
			}

			const [name] = input.name.split('.');

			const isTouched = touched?.[name] ?? false;
			const hasError = name in errors;

			return isTouched && hasError;
		});

		if (firstInput) {
			let top = firstInput.getBoundingClientRect().top;

			if (top === 0) {
				top =
					firstInput?.parentElement?.getBoundingClientRect().top ?? 0;
			}

			const scrollTo = top + window.pageYOffset - 125;

			window.scrollTo({ top: scrollTo, behavior: 'smooth' });
		}
	};

	let state: {
		errors?: Record<string, unknown>;
		touched?: Record<string, boolean>;
		submitErrors?: Record<string, unknown>;
	} = {};

	const unsubscribe = form.subscribe(
		nextState => {
			state = nextState;
		},
		{ touched: true, errors: true, submitErrors: true }
	);

	const originalSubmit = form.submit;

	const afterSubmit = () => {
		const { errors, touched, submitErrors } = state;
		if (errors && Object.keys(errors).length) {
			scrollToFirstError(errors, touched);
		} else if (submitErrors && Object.keys(submitErrors).length) {
			scrollToFirstError(submitErrors, touched);
		}
	};

	form.submit = () => {
		const result = originalSubmit.call(form);
		if (result && typeof result.then === 'function') {
			result.then(afterSubmit);
		} else {
			afterSubmit();
		}

		return result;
	};

	return () => {
		unsubscribe();
		form.submit = originalSubmit;
	};
};
