import { Fragment, useMemo } from 'react';
import {
	PaginatedList,
	Grid,
	ImagePickerField,
	Typography,
	PickerFile,
	Paper,
	Box,
} from 'components/common';
import { useInfinityScrollPaginationHandler } from 'hooks';
import { AlbumPhoto } from 'core/types';
import { PhotoCard } from 'components/photos';
import { formatDate } from 'utils/dates';
import { UseInfiniteQueryResult } from 'react-query';
import { imagePickerStyle } from './style';
import { UploadButton } from './button';

export type PhotoListProps = {
	filter?: string;
	canUploadPhoto: boolean;
	onChange: (values: PickerFile[]) => Promise<void>;
	onDelete: (photo: AlbumPhoto) => void;
	onClick: (photo: AlbumPhoto) => void;
	emptyState?: JSX.Element;
} & UseInfiniteQueryResult<AlbumPhoto[]>;

export const PhotoList = ({
	filter,
	canUploadPhoto,
	data,
	isFetching,
	isFetchingNextPage,
	fetchNextPage,
	hasNextPage,
	isSuccess,
	onChange,
	onDelete,
	onClick,
	emptyState,
}: PhotoListProps) => {
	let currentDate = formatDate(
		data?.pages[0][0]?.created_dt ?? new Date(),
		'MMMM yyyy'
	);

	const { handleOnLoad, showMoreButton, setShowMoreButton } =
		useInfinityScrollPaginationHandler<AlbumPhoto>({
			data,
			fetchNextPage,
			hasNextPage,
		});

	const hasPhotos = useMemo(() => {
		if (!data?.pages) return false;
		return data.pages.some(page => page.length > 0);
	}, [data?.pages]);

	const showEmptyState =
		emptyState && isSuccess && !isFetching && !hasPhotos && !canUploadPhoto;

	const filteredImages = useMemo(() => {
		return data?.pages.reduce<AlbumPhoto[]>((allPhotos, page) => {
			const thisPage = page.reduce<AlbumPhoto[]>(
				(photosForPAge, photo) => {
					if (filter && filter !== 'all') {
						if (
							filter === 'photo' &&
							photo.source_entity !== null
						) {
							return photosForPAge;
						}

						if (filter !== 'photo' && filter !== photo.album_uuid) {
							return photosForPAge;
						}
					}

					return [...photosForPAge, photo];
				},
				[]
			);

			return [...allPhotos, ...thisPage];
		}, []);
	}, [data, filter]);

	return (
		<PaginatedList
			onReachThreshold={handleOnLoad}
			onLoadMore={fetchNextPage}
			isFetching={isFetching}
			isFetchingMore={isFetchingNextPage}
			hasMore={!!hasNextPage}
			showButton={showMoreButton}
			setShowButton={setShowMoreButton}
		>
			{showEmptyState ? (
				<Box
					component={Paper}
					elevation={0}
					px={2}
					py={4}
					mb={2}
					mt={1}
					textAlign='center'
				>
					{emptyState}
				</Box>
			) : null}
			<Grid container spacing={2}>
				{hasPhotos && (
					<Grid item xs={12}>
						<Typography>{currentDate}</Typography>
					</Grid>
				)}
				{canUploadPhoto && (
					<Grid item xs={12} sm={6} lg={4}>
						<ImagePickerField
							sx={imagePickerStyle}
							CustomButton={UploadButton}
							value={[]}
							onChange={onChange}
						/>
					</Grid>
				)}
				{filteredImages?.map((photo: AlbumPhoto) => {
					const photoDate = formatDate(photo.created_dt, 'MMMM yyyy');
					let showDate = false;

					if (photoDate !== currentDate) {
						currentDate = photoDate;
						showDate = true;
					}

					return (
						<Fragment key={photo.uuid}>
							{showDate && (
								<Grid item xs={12}>
									<Typography>{currentDate}</Typography>
								</Grid>
							)}
							<Grid item xs={12} sm={6} lg={4}>
								<PhotoCard
									photo={photo}
									onDelete={onDelete}
									onClick={onClick}
								/>
							</Grid>
						</Fragment>
					);
				})}
			</Grid>
		</PaginatedList>
	);
};
