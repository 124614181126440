import {
	Form,
	Field,
	FormOnSubmitReturn,
	Box,
	Typography,
	TextField,
	EmailsChips,
	LoadingButton,
	FormStack,
} from 'components/common';
import { useCallback, useMemo } from 'react';
import * as Yup from 'yup';
import { useCurrentPartner, useTranslation } from 'hooks';
import { Identity, Community } from 'core/types';
import { sleep } from 'utils/delay';
import { getCommunityUrl } from 'utils/app';
import { TFunction } from 'react-i18next';
import { FormApi } from 'final-form';
import config from 'config';
import { InviteSubmittedValues } from './setup-community-members';
import { ImportMicrosoftEmailsButton } from './import-microsoft-emails-button';
import { ImportGmailEmailsButton } from './import-gmail-emails-button';

type InviteViaEmailFormProps = {
	identity: Identity;
	community: Community;
	onSubmit?: (values: InviteSubmittedValues) => FormOnSubmitReturn;
};

export const InviteViaEmailForm = (props: InviteViaEmailFormProps) => {
	const { onSubmit, community, identity } = props;

	const { t } = useTranslation();
	const partner = useCurrentPartner();

	const handleOnSubmit = useCallback(
		async (values, form: FormApi) => {
			await sleep(1);

			const result = onSubmit?.({
				method: 'email',
				emails: values.emails,
				text: values.text || '',
			});

			return Promise.resolve(result)
				.then(() => form.restart({ emails: [] }))
				.catch(error => error);
		},
		[onSubmit]
	);

	const validationSchema = useMemo(() => createValidationSchema(t), []);

	const showImport = useMemo(
		() => !!config.gmail.clientId || !!config.microsoft.auth.clientId,
		[config]
	);

	return (
		<Form
			onSubmit={handleOnSubmit}
			subscription={{ submitting: true, pristine: true }}
			validationSchema={validationSchema}
			mutators={{
				setEmails: (args, state, utils) => {
					utils.changeValue(state, 'emails', current => [
						...current,
						...args[0].filter(
							(email: string) => !current.includes(email)
						),
					]);
				},
			}}
		>
			{({ handleSubmit, submitting, pristine, form }) => (
				<>
					{showImport && (
						<>
							<Typography>
								{t('community-form.import-addresses')}
							</Typography>
							<ImportMicrosoftEmailsButton
								onSubmit={form.mutators.setEmails}
							/>
							<ImportGmailEmailsButton
								onSubmit={form.mutators.setEmails}
								sx={{
									marginLeft: 1,
									marginTop: { xs: 1, sm: 0 },
								}}
							/>
						</>
					)}
					<form onSubmit={handleSubmit} noValidate>
						<FormStack>
							<Box component='dl' mt={4}>
								<Typography component='dt'>
									{t('community-form.send-from')}
								</Typography>
								<Typography component='dd'>
									{identity.first_name} {identity.last_name}{' '}
									{`<${identity.email}>`}
								</Typography>
							</Box>
							<Box>
								<Field
									label={t('community-form.send-to')}
									name='emails'
									component={EmailsChips}
									type='text'
									placeholder={t(
										'community-form.enter-email-addresses'
									)}
									fullWidth
								/>
							</Box>
							<Box component='dl'>
								<Typography component='dt'>
									{t('community-form.subject')}
								</Typography>
								<Typography component='dd'>
									{t('community-form.join-community-on', {
										community: community.name,
										partner: partner.name,
									})}
								</Typography>
							</Box>
							<Box component='dl'>
								<Typography component='dt'>
									{t('community-form.message')}
								</Typography>
								<Typography component='dd'>
									{t('community-form.invite-message', {
										name: `${identity.first_name} ${identity.last_name}`,
										community: community.name,
									})}
									.{' '}
									{t(
										'community-form.click-link-accept-invitation'
									)}
									:
									<Typography mb={1} color='primary'>
										{getCommunityUrl(community)}
									</Typography>
								</Typography>
							</Box>
							<Box>
								<Field
									label={t(
										'community-form.add-personal-message'
									)}
									name='text'
									component={TextField}
									type='text'
									placeholder={t(
										'community-form.enter-your-personal-message-here'
									)}
									multiline
									rows={2}
									fullWidth
								/>
							</Box>
							<Box>
								<LoadingButton
									loading={submitting}
									loadingIndicator={t('common.sending')}
									size='small'
									type='submit'
									disabled={pristine}
								>
									{t('community-form.send-invites')}
								</LoadingButton>
							</Box>
						</FormStack>
					</form>
				</>
			)}
		</Form>
	);
};

function createValidationSchema(t: TFunction) {
	return Yup.object().shape({
		emails: Yup.array()
			.min(1, t('form.generic-required'))
			.required(t('form.generic-required')),
	});
}
