import { Box, Paper, Heading, Avatar, Typography } from 'components/common';
import { Album } from 'core/types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'utils/dates';
import { useImageServer } from 'hooks';
import classNames from 'classnames';
import { rootStyle, avatarStyle, nameStyle } from './styles';

type AlbumCardProps = {
	album: Album;
	draggable?: boolean;
	onClick?: (album: Album) => void;
};

export const AlbumCard = ({ album, draggable, onClick }: AlbumCardProps) => {
	const handleOnClick = useCallback(() => {
		onClick?.(album);
	}, [onClick, album]);

	const { t } = useTranslation();

	const avatarImage = useImageServer(album.cover_photo?.image, {
		w: 152,
		h: 152,
		fit: 'max',
	});

	return (
		<Box
			component={Paper}
			elevation={0}
			sx={rootStyle}
			onClick={handleOnClick}
			alignItems='center'
			className={classNames({ draggable: draggable })}
		>
			<Avatar sx={avatarStyle} src={avatarImage?.url} alt={album.name} />
			<Box>
				<Heading variant='h5' sx={nameStyle}>
					{album.name}
				</Heading>
				<Typography variant='body1' color={theme => theme.blacks[0.54]}>
					{t('photos.created-on', {
						date: formatDate(album.created_dt, 'M/d/yy'),
					})}
				</Typography>
			</Box>
		</Box>
	);
};
