import { Box } from 'components';
import { createRef, FC } from 'react';
import { rootStyle } from './styles';

const elementRef = createRef<HTMLDivElement>();

export const FooterActionProvider: FC = ({ children }) => {
	return (
		<>
			<Box ref={elementRef} sx={rootStyle} />
			{children}
		</>
	);
};

export const useFooterActionsRoot = () => elementRef.current;
