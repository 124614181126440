import {
	Box,
	CircularProgress,
	Typography,
	Button,
	LoadingButton,
} from 'components/common';
import {
	useParams,
	useTranslation,
	useRouter,
	useServices,
	useConstantValue,
} from 'hooks';
import { Trans } from 'react-i18next';
import { useTransientStore } from 'store';
import { useCurrentIdentity, useLogout } from 'core/uses-cases/authentication';
import { useRespondInvitation } from 'core/uses-cases/invitation';
import { Navigate } from 'navigation';
import { useInvitationFlow } from './use-invitation-flow';

export const JoinByInvitation = () => {
	const { t } = useTranslation();
	const router = useRouter();
	const { notification } = useServices();
	const { communityId, tokenId } = useParams() as {
		communityId: string;
		tokenId: string;
	};
	const currentIdentity = useCurrentIdentity();
	const store = useTransientStore();
	const enabledFlow = useConstantValue(store.auth.isLoggedIn); // Only allow if logged in
	const { token } = useInvitationFlow(communityId, tokenId, enabledFlow);
	const acceptMutation = useRespondInvitation();
	const declineMutation = useRespondInvitation();
	const { doLogout } = useLogout();

	const handleOnAccept = () => {
		if (!token) return;

		acceptMutation.mutate(
			{
				communityId,
				invitationId: token.invitation_id,
				action: 'accept',
			},
			{
				onSuccess: () => {
					notification.show(
						'success',
						t('invitation.you-have-accepted-the-invitation-request')
					);

					router.navigate('community.home', { communityId });
				},
			}
		);
	};

	const handleOnReject = () => {
		if (!token) return;

		declineMutation.mutate(
			{
				communityId,
				invitationId: token.invitation_id,
				action: 'decline',
			},
			{
				onSuccess: () => {
					router.navigate(
						'community.invitation.decline-success',
						{ communityId },
						{},
						{ state: { fromInvitation: true } }
					);
				},
			}
		);
	};

	const handleOnChangeAccount = () => {
		doLogout({
			keepCurrentPageAfterLogout: true,
		});

		router.navigate(
			'community.invitation.join',
			{ communityId, tokenId },
			{},
			{ replace: true }
		);
	};

	// If there is no already a user logged in, redirect to the select email workflow
	if (!enabledFlow) {
		return (
			<Navigate
				to={router.getPathFor('community.invitation.select-email', {
					communityId,
					tokenId,
				})}
			/>
		);
	}

	if (!currentIdentity || !token) {
		return (
			<Box display='flex' justifyContent='center' mt={4}>
				<CircularProgress message={t('common.loading')} />
			</Box>
		);
	}

	const isExactMatch =
		token.possible_emails.length === 1 &&
		token.possible_emails[0] === currentIdentity.email;

	return (
		<Box pt={1}>
			<Box mb={4}>
				<Typography variant='caption'>
					<Trans
						i18nKey={
							isExactMatch
								? 'invitation.join-logged-with-same-account'
								: 'invitation.join-logged-different-account'
						}
						values={{
							email: currentIdentity.email,
							name: `${currentIdentity.first_name} ${currentIdentity.last_name}`,
						}}
						components={{
							bold: <strong />,
						}}
					/>
				</Typography>
			</Box>
			<Box
				display='flex'
				flexDirection={{ xs: 'column', sm: 'row' }}
				alignItems={{ xs: 'center', sm: 'flex-start' }}
				flexWrap={{ xs: 'nowrap', sm: 'wrap' }}
				gap={2}
			>
				<LoadingButton
					size='small'
					variant='text'
					onClick={handleOnReject}
					loading={declineMutation.isLoading}
					loadingIndicator={t('invitation.declining-invitation')}
					noWrap
				>
					{t('invitation.decline-invitation')}
				</LoadingButton>
				{!isExactMatch && (
					<Button
						size='small'
						variant='outlined'
						onClick={handleOnChangeAccount}
						noWrap
					>
						{t('invitation.use-different-email')}
					</Button>
				)}
				<LoadingButton
					size='small'
					variant='contained'
					onClick={handleOnAccept}
					loading={acceptMutation.isLoading}
					loadingIndicator={t('invitation.accepting-invitation')}
					noWrap
				>
					{t('invitation.accept-invitation')}
				</LoadingButton>
			</Box>
		</Box>
	);
};
