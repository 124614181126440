import { Splash } from 'components';
import { USE_PHP_SITE } from 'core/constants';
import { useValidateSession } from 'core/uses-cases/authentication/use-validate-session';
import { useRouter, useServices } from 'hooks';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const TransferSession = () => {
	const { cookie } = useServices();
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const router = useRouter();
	const mutate = useValidateSession();
	const data = searchParams.get('d') ?? '';
	const iv = searchParams.get('iv') ?? '';
	const usePhpSite = searchParams.get(USE_PHP_SITE) ?? '';

	useEffect(() => {
		if (!usePhpSite) {
			mutate.mutateAsync(
				{ data, iv },
				{
					onSuccess: data => {
						navigate(data.redirect_url ?? '', {
							replace: true,
						});
					},
					onError: () => {
						navigate(router.getPathFor('account.login'), {
							replace: true,
						});
					},
				}
			);
		} else {
			setTimeout(async () => {
				const now = new Date();
				cookie.set(USE_PHP_SITE, new Date().toISOString(), {
					expires: new Date(
						now.getFullYear() + 10, // as of 2022 this is limited to 400 days
						now.getMonth(),
						now.getDate()
					),
					domain: `.${window.location.hostname}`,
				});

				if (navigator.serviceWorker) {
					const registrations =
						await navigator.serviceWorker.getRegistrations();
					for (const registration of registrations) {
						await registration.unregister();
					}
				}

				window.location.reload();
			}, 500);
		}
	}, []);

	return <Splash />;
};
