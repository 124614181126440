import { Group } from 'core/types';
import { useTranslation } from 'hooks';

export const useGroupNameTranslation = (group?: Group): string => {
	const { t } = useTranslation();

	return t(`groups.${group?.name}`, {
		defaultValue: group?.description,
	});
};
