import {
	Box,
	Avatar,
	Paper,
	Typography,
	Link,
	IconButton,
} from 'components/common';
import { Identity } from 'core/types';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { identityListCardStyle, identityNameStyle } from './styles';

export type IdentityListCardProps = {
	identities: Identity[];
	emptyMessage?: string;
	showPhone?: boolean;
	onDelete?: (identity: Identity) => void;
};

export const IdentityListCard = (props: IdentityListCardProps) => {
	const { identities, emptyMessage, onDelete } = props;

	return (
		<Box>
			{emptyMessage && identities.length === 0 && (
				<Box component={Paper} elevation={0} p={3}>
					<Typography>{emptyMessage}</Typography>
				</Box>
			)}
			{identities.map(identity => (
				<Box
					key={identity.uuid}
					component={Paper}
					elevation={0}
					sx={identityListCardStyle}
				>
					<Avatar
						src={identity?.image?.url}
						alt={`${identity?.first_name?.[0]} ${identity?.last_name?.[0]}`}
					/>
					<Box ml={2} flex={1}>
						<Typography variant='body2' sx={identityNameStyle}>
							{identity?.first_name} {identity?.last_name}
						</Typography>
						<Typography variant='body1'>
							{identity?.location}
						</Typography>
						{props.showPhone && (
							<Typography variant='body1'>
								<Link href={`tel:${identity?.phone}`}>
									{identity?.phone}
								</Link>
							</Typography>
						)}
						<Typography variant='body1'>
							<Link href={`mailto:${identity?.email}`}>
								{identity?.email}
							</Link>
						</Typography>
					</Box>
					{onDelete && (
						<Box position='absolute' top={0} right={0}>
							<IconButton onClick={() => onDelete?.(identity)}>
								<HighlightOffIcon fontSize='small' />
							</IconButton>
						</Box>
					)}
				</Box>
			))}
		</Box>
	);
};
