import { SetupMutationFn } from 'core/configure/types';
import { BaseHttpError } from 'core/services/api/errors';
import { Comment, Nullable } from 'core/types';
import { discussionKeys } from 'core/utils/query-key-factory';
import { isCancelledError, Mutation, QueryKey, useMutation } from 'react-query';
import {
	patchCommentMutationKey,
	postCommentMutationKey,
} from 'core/utils/mutation-key-factory';
import {
	findRecordFromInfinityQueryCache,
	PaginatedRecordsSnapshot,
	updateRecordFromInfinityQueryCache,
	onSuccessSingleRecordInInfinityQueryCache,
	onErrorOptimisticInInfinityQueryCache,
	cancelPreviousMutation,
} from 'core/utils/optimistic-utils';
import { CommentInput } from '../../services/api/api-client/types';

export interface PatchCommentMutationInput extends CommentInput {
	discussionId: string;
	commentId: string;
	parentId?: string;
	createdDt: Nullable<string>;
}

export const usePatchComment = () => {
	const mutation = useMutation<
		Comment,
		BaseHttpError,
		PatchCommentMutationInput
	>(patchCommentMutationKey);

	return mutation;
};

type MutationContext = {
	commentId: string;
	snapshot: PaginatedRecordsSnapshot<Comment>;
	listKey: QueryKey;
};

export const setupPatchComment: SetupMutationFn = (
	services,
	createTrackedParallelMutation,
	mutationTracker
) => {
	const { queryClient, api } = services;

	const mutation = createTrackedParallelMutation<
		Comment,
		BaseHttpError,
		PatchCommentMutationInput,
		MutationContext
	>({
		mutationFn: async ({ discussionId, commentId, createdDt, message }) => {
			// If created dt is given, we need to set the call to be an update
			const actionCall = createdDt
				? api.patchComment.bind(api, commentId)
				: api.postComment.bind(api, discussionId);

			return actionCall({ message }).then(response => response.data);
		},
		onMutate: async input => {
			const { commentId, discussionId, parentId, message, createdDt } =
				input;

			// Cancel the previous mutation if it exists
			await cancelPreviousMutation<MutationContext>(
				queryClient,
				mutation => {
					return (
						mutation.state.context?.commentId === commentId &&
						isPostPatchCommentMutation(mutation)
					);
				}
			);

			const listKey = parentId
				? discussionKeys.replies(discussionId, parentId)
				: discussionKeys.comments(discussionId);

			const { listSnapshot, record } =
				findRecordFromInfinityQueryCache<Comment>(
					queryClient,
					listKey,
					commentId
				) ?? {};

			const updatedComment = {
				...record,
				uuid: commentId,
				message,
				created_dt: createdDt ?? null,
			} as Comment;

			updateRecordFromInfinityQueryCache<Comment>(
				queryClient,
				listKey,
				updatedComment
			);

			return {
				listKey,
				commentId,
				snapshot: listSnapshot,
			};
		},
		onSuccess: (result, input, { listKey, commentId }) => {
			onSuccessSingleRecordInInfinityQueryCache(
				queryClient,
				listKey,
				commentId,
				result
			);
		},
		onError: (error, input, context) => {
			if (context && !isCancelledError(error)) {
				onErrorOptimisticInInfinityQueryCache(
					queryClient,
					context.listKey,
					context.commentId
				);
			}
		},
		onSettled: (data, error, variables, context) => {
			if (context && !isCancelledError(error)) {
				mutationTracker.queueInvalidations(context.listKey);
			}
		},
		retry: 1,
	});

	queryClient.setMutationDefaults(patchCommentMutationKey, mutation);
};

export const isPostPatchCommentMutation = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	mutation: Mutation<any, any, any, any>
) =>
	[patchCommentMutationKey, postCommentMutationKey].includes(
		String(mutation?.options?.mutationKey)
	);
