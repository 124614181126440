import { isCancelledError, onlineManager, useMutation } from 'react-query';
import { Event } from 'core/types';
import { SetupMutationFn } from 'core/configure/types';
import { calendarKeys } from 'core/utils/query-key-factory';
import { deleteEventMutationKey } from 'core/utils/mutation-key-factory';
import { cancelPreviousMutation } from 'core/utils/optimistic-utils';
import { EventScopeValues } from 'core/constants';
import { getEventDateTimes } from 'core/utils/calender-events';
import { Services } from '../../services/types';
import { BaseHttpError } from '../../services/api/errors';
import { isPostVolunteerMutation } from './use-post-volunteer';
import { isRemoveVolunteerMutation } from './use-remove-volunteer';
import { EVENT_SCOPES } from '../../constants';
import { EventsCache } from './use-get-events';
import { removeEventsFromCache } from './utils';

export interface DeleteEventMutationInput {
	communityId: string;
	calendarId: string;
	eventId: string;
	scope: EventScopeValues;
}

export const useDeleteEvent = () => {
	const mutation = useMutation<null, BaseHttpError, DeleteEventMutationInput>(
		deleteEventMutationKey
	);

	return mutation;
};

type Context = {
	eventsSnapshot: EventsCache | undefined;
	communityId: string;
	calendarId: string;
	eventId: string;
	eventIdsToRemove: string[];
};

export const setupDeleteEvent: SetupMutationFn = (
	services: Services,
	createTrackedParallelMutation,
	mutationTracker
) => {
	const { queryClient, api } = services;

	const mutation = createTrackedParallelMutation<
		null,
		BaseHttpError,
		DeleteEventMutationInput,
		Context
	>({
		mutationFn: ({ eventId, calendarId, scope }) => {
			if (eventId.length === 22) {
				return api.deleteEvent(calendarId, eventId, scope);
			}

			if (!onlineManager.isOnline()) throw new Error('Offline');

			return Promise.resolve(null);
		},
		onMutate: async input => {
			const { calendarId, communityId, eventId, scope } = input;

			// Cancel any previous volunteer actions for this event
			await cancelPreviousMutation<Context>(queryClient, mutation => {
				return (
					(isPostVolunteerMutation(mutation) ||
						isRemoveVolunteerMutation(mutation)) &&
					mutation.state.context?.eventId === eventId
				);
			});

			const eventsSnapshot = queryClient.getQueryData<EventsCache>(
				calendarKeys.all(calendarId)
			);

			// Save the current info as snapshot
			const currentEvent = eventsSnapshot?.byId[eventId];

			const eventIdsToRemove = [eventId];

			// Grab all local future events for the same series
			if (currentEvent && scope === EVENT_SCOPES.FUTURE) {
				const { startDate, startDateTime } =
					getEventDateTimes(currentEvent);

				const eventStartDate = startDateTime
					? startDateTime
					: startDate;

				const futureEventIds = eventsSnapshot.allIds.filter(uuid => {
					const event = eventsSnapshot.byId[uuid];

					const { startDateTime, startDate } =
						getEventDateTimes(event);

					const innerStartDate = startDateTime
						? startDateTime
						: startDate;

					return (
						currentEvent.event_series_uuid ===
							event.event_series_uuid &&
						event.uuid !== eventId &&
						innerStartDate >= eventStartDate
					);
				});

				eventIdsToRemove.push(...futureEventIds);
			}

			// Cancel all ongoing queries
			// await queryClient.cancelQueries(calendarKeys.lists(calendarId));
			// await queryClient.cancelQueries(calendarKeys.details(calendarId));

			// Remove the event from the list
			removeEventsFromCache(queryClient, calendarId, eventIdsToRemove);

			return {
				eventsSnapshot,
				communityId,
				calendarId,
				eventId,
				eventIdsToRemove,
			};
		},
		onSuccess: (
			_result,
			{ calendarId, eventId, communityId, scope },
			{ eventIdsToRemove }
		) => {
			const eventSnapshot = queryClient.getQueryData<Event>(
				calendarKeys.detail(calendarId, eventId)
			);

			if (eventSnapshot && eventSnapshot.uuid !== eventId) {
				// Means was created but needs to be deleted

				// Run mutation again to delete the real one
				queryClient.executeMutation({
					mutationKey: deleteEventMutationKey,
					variables: {
						calendarId,
						eventId: eventSnapshot.uuid,
						communityId,
						scope,
					},
				});

				queryClient.cancelQueries(calendarKeys.details(calendarId));
			}

			removeEventsFromCache(queryClient, calendarId, eventIdsToRemove);
		},
		onError: (error, { calendarId }, context) => {
			if (!isCancelledError(error) && context?.eventsSnapshot) {
				queryClient.setQueryData(
					calendarKeys.all(calendarId),
					context?.eventsSnapshot
				);
			}
		},
		onSettled: (data, error, { eventId }, context) => {
			if (context && !isCancelledError(error)) {
				if (eventId.length === 22) {
					mutationTracker.queueInvalidations(
						calendarKeys.lists(context.calendarId)
					);
				}
			}
		},
		retry: 1,
	});

	queryClient.setMutationDefaults(deleteEventMutationKey, mutation);
};
