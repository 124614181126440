import { useEffect, useState } from 'react';
import { usePrevious } from './use-previous';

const shallowEqual = (a: any, b: any) => {
	return a === b;
};

export const useControlledValue = <T>(
	valueProp: T,
	onChange?: (value: T) => void,
	isEqual = shallowEqual
): [T, (value: T) => void] => {
	const [value, setValue] = useState<T>(() => valueProp);
	const prevValues = usePrevious(value);

	const isControlled = typeof onChange === 'function';

	useEffect(() => {
		if (
			!isControlled &&
			(!isEqual(prevValues, value) || !isEqual(valueProp, value))
		) {
			setValue(value);
		}
	}, [prevValues, value, valueProp]);

	useEffect(() => onChange?.(value), [value]);

	if (isControlled) {
		return [valueProp, onChange];
	}

	return [value, setValue];
};
