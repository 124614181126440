import classnames from 'classnames';
import { BadgeHolder, Box, Typography } from 'components/common';
import { useRouter, useTranslation } from 'hooks';
import { useCallback } from 'react';
import { matchPath } from 'react-router-dom';
import { contentStyle, stepStyle } from './styles';

type StepProps = {
	step: number;
	totalSteps: number;
	label: string;
	isActive?: boolean;
};

const Step = (props: StepProps) => {
	const { step, label, isActive, totalSteps } = props;

	return (
		<Box
			sx={stepStyle}
			className={classnames({
				active: isActive,
			})}
		>
			<BadgeHolder className='step-number'>{step}</BadgeHolder>
			<Typography className='label'>{label}</Typography>
			<Typography className='summary'>
				{step}/{totalSteps}
			</Typography>
		</Box>
	);
};

export const SetupCommunityStepper = () => {
	const { t } = useTranslation();

	const router = useRouter();

	const isMatching = useCallback(
		(key: string) =>
			!!matchPath(
				{
					path: router.getPathFor(`community.${key}`),
					end: true,
				},
				window.location.pathname
			),
		[]
	);

	return (
		<Box sx={contentStyle}>
			<Step
				step={1}
				totalSteps={3}
				label={t('community-form.set-up-community-profile')}
				isActive={isMatching('setup-profile')}
			/>
			<Step
				step={2}
				totalSteps={3}
				label={t('community-form.add-members')}
				isActive={isMatching('setup-members')}
			/>
			<Step
				step={3}
				totalSteps={3}
				label={t('community-form.write-your-first-announcement')}
				isActive={isMatching('setup-announcement')}
			/>
		</Box>
	);
};
