import {
	Form,
	Field,
	FormOnSubmitReturn,
	Stack,
	Box,
	Typography,
	PhonesChips,
	LoadingButton,
} from 'components/common';
import * as Yup from 'yup';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'hooks';
import { Identity, Community } from 'core/types';
import { sleep } from 'utils/delay';
import { TFunction } from 'react-i18next';
import { stackStyle } from './styles';
import { InviteSubmittedValues } from './setup-community-members';

type InviteViaSmsFormProps = {
	identity: Identity;
	community: Community;
	onSubmit?: (values: InviteSubmittedValues) => FormOnSubmitReturn;
};

export const InviteViaSmsForm = (props: InviteViaSmsFormProps) => {
	const { onSubmit, community, identity } = props;

	const { t } = useTranslation();

	const handleOnSubmit = useCallback(
		async (values, form) => {
			await sleep(1);

			if (values.phones.length === 0) {
				return Promise.resolve(null);
			}

			const result = onSubmit?.({
				method: 'sms',
				phones: values.phones,
				text: values.text || '',
			});

			return Promise.resolve(result)
				.then(() => form.restart({ phones: [] }))
				.catch(error => error);
		},
		[onSubmit]
	);

	const validationSchema = useMemo(() => createValidationSchema(t), []);

	return (
		<Form
			onSubmit={handleOnSubmit}
			subscription={{ submitting: true, pristine: true }}
			validationSchema={validationSchema}
		>
			{({ handleSubmit, submitting, pristine }) => (
				<form onSubmit={handleSubmit} noValidate>
					<Stack spacing={6} sx={stackStyle}>
						<Box component='dl'>
							<Typography component='dt'>
								{t('community-form.send-from')}
							</Typography>
							<Typography component='dd'>
								{`${identity.first_name} ${identity.last_name}`}
							</Typography>
						</Box>
						<Box>
							<Field
								label={t('community-form.numbers')}
								name='phones'
								component={PhonesChips}
								type='text'
								placeholder={t(
									'community-form.enter-phone-numbers'
								)}
								fullWidth
							/>
						</Box>
						<Box component='dl'>
							<Typography component='dt'>
								{t('community-form.message')}
							</Typography>
							<Typography component='dd'>
								{t('community-form.invite-message', {
									name: `${identity.first_name} ${identity.last_name}`,
									community: community.name,
								})}
								.
							</Typography>
						</Box>
						<Box>
							<LoadingButton
								loading={submitting}
								loadingIndicator={t('common.sending')}
								size='small'
								type='submit'
								disabled={pristine}
							>
								{t('community-form.send-text-invites')}
							</LoadingButton>
						</Box>
					</Stack>
				</form>
			)}
		</Form>
	);
};

function createValidationSchema(t: TFunction) {
	return Yup.object().shape({
		phones: Yup.array()
			.min(1, t('form.generic-required'))
			.required(t('form.generic-required')),
	});
}
