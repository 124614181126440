import { Box, Button, Field, SelectField, SelectItem } from 'components/common';
import { Group, PermissionResources } from 'core/types';
import { useState, Fragment } from 'react';
import { useTranslation } from 'hooks';
import { getDefaultGlobalPermissions } from 'core/uses-cases/community';
import { PermissionTabsField } from './tabs-field';
import { navButtonStyle } from './styles';

export type PermissionsTabOption = {
	label: string;
	value: PermissionResources;
};

export type ManagePermissionsTabsFieldsProps = {
	groups: Group[];
};

const defaultPermissions = getDefaultGlobalPermissions();

export const ManagePermissionsTabsFields = ({
	groups,
}: ManagePermissionsTabsFieldsProps) => {
	const { t } = useTranslation();
	const [selected, setSelected] = useState<PermissionResources>('community');

	const handleChange = (newValue: PermissionResources) => {
		setSelected(newValue);
	};

	return (
		<>
			<Box mt={4}>
				<Box display={{ xs: 'none', sm: 'flex' }}>
					{Object.entries(defaultPermissions).map(([resourceKey]) => (
						<Button
							key={`permission-group-${resourceKey}`}
							onClick={() => {
								handleChange(
									resourceKey as PermissionResources
								);
							}}
							variant={
								selected === resourceKey ? 'contained' : 'text'
							}
							size='small'
							sx={navButtonStyle}
						>
							{t(`advance-permissions.resource.${resourceKey}`)}
						</Button>
					))}
				</Box>
				<Box display={{ xs: 'block', sm: 'none' }}>
					<SelectField
						fullWidth
						value={selected}
						onChange={event => {
							handleChange(
								event.target.value as PermissionResources
							);
						}}
					>
						{Object.entries(defaultPermissions).map(
							([resourceKey]) => (
								<SelectItem
									key={`permission-group-${resourceKey}`}
									value={resourceKey}
								>
									{t(
										`advance-permissions.resource.${resourceKey}`
									)}
								</SelectItem>
							)
						)}
					</SelectField>
				</Box>
			</Box>
			<Box mt={5}>
				{Object.entries(defaultPermissions).map(
					([key, permissions]) => (
						<Fragment key={`permission-label-${key}`}>
							<Box display={selected === key ? 'block' : 'none'}>
								{Object.entries(permissions).map(
									([permissionKey]) => (
										<Box
											key={`permission-${key}-${permissionKey}`}
											mb={6}
										>
											<Field
												name={`${key}.${permissionKey}`}
												component={
													// eslint-disable-next-line react/jsx-indent
													PermissionTabsField
												}
												label={t(
													`advance-permissions.labels.${key}.${permissionKey}`
												)}
												groups={groups}
												isVisible={selected === key}
											/>
										</Box>
									)
								)}
							</Box>
						</Fragment>
					)
				)}
			</Box>
		</>
	);
};
