import {
	Box,
	PageHeading,
	Typography,
	Paper,
	CircularProgress,
} from 'components/common';
import {
	EmailPreferencesForm,
	EmailPreferencesFormSubmittedValues,
	UnsubscribeForm,
	UnsubscribeFormSubmittedValues,
	UnsubscribeFromCommunityForm,
	UnsubscribeFromCommunityFormSubmittedValues,
} from 'components/email-preferences';
import {
	DIGEST_FREQUENCY,
	PREFERENCES,
} from 'components/email-preferences/constants';
import config from 'config';
import {
	AUTH_INVALID,
	HttpClientError,
	RESOURCE_NOT_FOUND,
} from 'core/services';
import { SubscriptionPreferencesInput } from 'core/services/api/api-client/types';
import { useAccessToken } from 'core/uses-cases/authentication';
import { useDeleteCommunityMember } from 'core/uses-cases/community';
import {
	useGetSubscriptionPreferences,
	usePatchSubscriptionPreferences,
} from 'core/uses-cases/subscription';
import { subscriptionKeys } from 'core/utils/query-key-factory';
import { useRouter, useSearchParams, useServices, useTranslation } from 'hooks';
import { Navigate } from 'navigation';
import { useState, useRef } from 'react';
import { Trans } from 'react-i18next';
import { parsePreferences } from 'utils/subscription';

type InternalPages =
	| 'email-preferences'
	| 'unsubscribe'
	| 'unsubscribe-from-community';

export const UnsubscribePage = () => {
	const { t } = useTranslation();
	const router = useRouter();
	const { queryClient } = useServices();

	const [queryParams] = useSearchParams();
	const submittedValues = useRef<SubscriptionPreferencesInput>();

	const tokenId = queryParams.get('t') || '';
	const [page, setPage] = useState<InternalPages>('email-preferences');

	const { isError, data, isLoading, isRefetching, isFetching } =
		useGetSubscriptionPreferences(tokenId);

	const initialPreferencesValues = parsePreferences(data?.subscription);

	const handleOnSuccess = () => {
		queryClient.removeQueries(subscriptionKeys.preference(tokenId));

		if (config.urls.wordPress) {
			window.location.replace(config.urls.wordPress);
		} else {
			router.navigate('root', {}, {}, { replace: true });
		}
	};

	const handleOnError = (error: Error) => {
		if (
			error instanceof HttpClientError &&
			error.errorCode === RESOURCE_NOT_FOUND
		) {
			router.navigate('root', {}, {}, { replace: true });
		}
	};

	const mutation = usePatchSubscriptionPreferences();

	const handleOnSubmitEmailPreferences = ({
		digestFrequency,
		deliveryTime,
		...values
	}: EmailPreferencesFormSubmittedValues) => {
		if (!initialPreferencesValues) return;

		const preferencesValues: SubscriptionPreferencesInput = {
			announcementSubscriptionStatus: values.emailPreferences.includes(
				PREFERENCES.ANNOUNCEMENT_SUBSCRIPTION
			),
			emailDigestSubscriptionStatus: values.emailPreferences.includes(
				PREFERENCES.DIGEST_SUBSCRIPTION
			),
			deliveryTime: deliveryTime.hour,
			...(digestFrequency === DIGEST_FREQUENCY.WEEKLY
				? {
					digestFrequency: DIGEST_FREQUENCY.WEEKLY,
					customDay: values.deliveryDay || '',
				  }
				: digestFrequency === DIGEST_FREQUENCY.CUSTOM
					? {
						digestFrequency: DIGEST_FREQUENCY.CUSTOM,
						customDays: values.deliveryDays || [],
				  }
					: {
						digestFrequency: DIGEST_FREQUENCY.DAILY,
				  }), // prettier-ignore
		};

		const thereIsNewUnchecked =
			(initialPreferencesValues.emailPreferences.includes(
				PREFERENCES.ANNOUNCEMENT_SUBSCRIPTION
			) === true &&
				preferencesValues.announcementSubscriptionStatus === false) ||
			(initialPreferencesValues.emailPreferences.includes(
				PREFERENCES.DIGEST_SUBSCRIPTION
			) === true &&
				preferencesValues.emailDigestSubscriptionStatus === false);

		// If either of the preferences is unchecked, then go to the reason page
		if (thereIsNewUnchecked) {
			submittedValues.current = preferencesValues;
			setPage('unsubscribe');
		} else {
			return mutation.mutateAsync(
				{
					token: tokenId,
					...preferencesValues,
				},
				{
					onSuccess: handleOnSuccess,
					onError: handleOnError,
				}
			);
		}
	};

	const handleOnUnsubscribe = async ({
		reason,
		otherReason,
	}: UnsubscribeFormSubmittedValues) => {
		if (!submittedValues.current) return;

		return mutation.mutateAsync(
			{
				token: tokenId,
				...submittedValues.current,
				reason,
				otherReason,
			},
			{
				onSuccess: () => {
					if (reason === 'non-active') {
						setPage('unsubscribe-from-community');
					} else {
						handleOnSuccess();
					}
				},
				onError: handleOnError,
			}
		);
	};

	const loginMutation = useAccessToken();
	const deleteMemberMutation = useDeleteCommunityMember();

	const handleOnUnsubscribeFromCommunity = async ({
		password,
	}: UnsubscribeFromCommunityFormSubmittedValues) => {
		if (!data) return;

		try {
			const response = await loginMutation.mutateAsync({
				username: data.token_data.email,
				password,
			});

			await deleteMemberMutation.mutateAsync({
				memberId: response.identity.uuid,
				communityId: data.token_data.community_slug,
				sendNotification: true,
				leaveCommunity: true,
				accessToken: response.data.uuid,
			});

			handleOnSuccess();
		} catch (error) {
			if (
				error instanceof HttpClientError &&
				(error.errorCode === 0 || error.errorCode === AUTH_INVALID)
			) {
				throw { password: t('login.invalid-password') };
			}
		}
	};

	if (!tokenId || isError) {
		return <Navigate to={router.getPathFor('root')} />;
	}

	if (isLoading || isRefetching || isFetching) {
		return (
			<Box display='flex' justifyContent='center' mt={4}>
				<CircularProgress message={t('common.loading')} />
			</Box>
		);
	}

	return (
		<Box>
			<PageHeading
				title={
					page === 'email-preferences'
						? t('email-preferences.email-preferences')
						: t('email-preferences.unsubscribe')
				}
			/>
			<Box component={Paper} elevation={0} mt={6} px={5} py={5}>
				{page === 'email-preferences' && (
					<>
						<Typography variant='body2'>
							<Trans
								i18nKey='email-preferences.you-are-updating-your-email-preferences'
								values={{
									email: data?.token_data.email,
									community: data?.token_data.community_name,
								}}
								components={{
									italic: <i />,
									bold: <strong />,
									break: <br />,
								}}
							/>
						</Typography>
						<Box mt={2}>
							<EmailPreferencesForm
								onSubmit={handleOnSubmitEmailPreferences}
								initialValues={initialPreferencesValues}
							/>
						</Box>
					</>
				)}
				{page === 'unsubscribe' && (
					<UnsubscribeForm
						onSubmit={handleOnUnsubscribe}
						hideNonActiveOptions={
							data?.token_data.sub ===
							data?.token_data.community_owner
						}
					/>
				)}
				{page === 'unsubscribe-from-community' && (
					<>
						<Typography variant='body2'>
							<Trans
								i18nKey='email-preferences.would-you-like-to-leave'
								values={{
									email: data?.token_data.email,
									community: data?.token_data.community_name,
								}}
								components={{
									italic: <i />,
									bold: <strong />,
									break: <br />,
								}}
							/>
						</Typography>
						<UnsubscribeFromCommunityForm
							onCancel={handleOnSuccess}
							onSubmit={handleOnUnsubscribeFromCommunity}
						/>
					</>
				)}
			</Box>
		</Box>
	);
};
