import { Box, BoxProps, Typography, TextProps } from 'components';

export const DescriptionListItem: React.FC<
	{
		title: string;
		description?: string;
		titleProps?: TextProps;
		descriptionProps?: TextProps;
	} & BoxProps
> = ({
	title,
	description,
	titleProps,
	descriptionProps,
	children,
	...rest
}) => {
	return (
		<Box mb={2} {...rest}>
			<Typography component='span' fontWeight='bold' {...titleProps}>
				{title}:{' '}
			</Typography>
			<Typography component='span' {...descriptionProps}>
				{description || children}
			</Typography>
		</Box>
	);
};
