import { useMutation, useQueryClient } from 'react-query';
import { respondInvitationMutationKey } from 'core/utils/mutation-key-factory';
import { useServices } from 'hooks';
import { Invitation } from 'core/types';
import { communityKeys } from 'core/utils/query-key-factory';
import { BaseHttpError } from '../../services/api/errors';

export type RespondInvitationMutationInput = {
	communityId: string;
	invitationId: number;
	action: 'reinvite' | 'accept' | 'decline';
};

export const useRespondInvitation = () => {
	const { api } = useServices();
	const queryClient = useQueryClient();

	return useMutation<
		Invitation[],
		BaseHttpError,
		RespondInvitationMutationInput
	>(
		({ communityId, invitationId, action }) => {
			return api
				.respondInvitation(communityId, invitationId, action)
				.then(response => response.data);
		},
		{
			mutationKey: respondInvitationMutationKey,
			onSuccess: () => {
				return queryClient.invalidateQueries(communityKeys.lists());
			},
		}
	);
};
