import { useEffect, useMemo } from 'react';
import { useNavigate, useRoutes } from 'react-router-dom';
import { usePrevious, useConstantValue } from 'hooks';
import { useStore } from 'store';
import config from 'config';
import { AppWrapper } from 'components/layout';
import { DebugRouter } from './debug';
import { router } from './router';
import { RouterProvider } from './context';
import { ScrollToTop } from './reset-scroll';

function AuthCheckGuard() {
	const {
		auth: {
			accessToken,
			keepCurrentPageAfterReset,
			setKeepCurrentPageAfterReset,
		},
	} = useStore();

	const prevAccessToken = usePrevious(accessToken);

	const navigate = useNavigate();

	useEffect(() => {
		if (!accessToken && prevAccessToken) {
			if (!keepCurrentPageAfterReset) {
				// Logout -> Redirect to login page
				navigate(router.getPathFor('account.login'), { replace: true });
			}

			setKeepCurrentPageAfterReset(false);
		}
	}, [accessToken]);

	return null;
}

export const Navigator = () => {
	const routes = useConstantValue(() => {
		return router.build();
	});

	const rootElement = useRoutes(routes);

	const memoizedRootElement = useMemo(() => rootElement, [rootElement]);

	return (
		<RouterProvider router={router}>
			<ScrollToTop />
			<AppWrapper>{memoizedRootElement}</AppWrapper>
			<AuthCheckGuard />
			{config.debug.routes && <DebugRouter />}
		</RouterProvider>
	);
};
