import { Box } from 'components';
import { AlbumPhoto } from 'core/types';
import { useImageServer, ImageServerConfig, useVerifyAccess } from 'hooks';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import dashCommentIcon from 'assets/images/dash-comment.svg?url';
import { Typography, IconButton } from 'components/common';
import { MouseEvent } from 'react';
import {
	imageCard,
	overlayContainer,
	deleteButton,
	photoInfo,
	commentInfo,
} from './style';

type PhotoImageProps = {
	onClick: (photo: AlbumPhoto) => void;
	onDelete: (photo: AlbumPhoto) => void;
	photo: AlbumPhoto;
};

export const photoCardConfigs: ImageServerConfig = {
	w: 600,
	h: 400,
	fit: 'fill',
	bg: 'fafafa',
};

export const PhotoCard = ({ photo, onClick, onDelete }: PhotoImageProps) => {
	const photoImage = useImageServer(photo.image, photoCardConfigs);

	const verify = useVerifyAccess();

	const canDelete = verify('delete', 'album_image', photo);

	const navigateToImage = () => {
		onClick(photo);
	};

	const deleteImage = (event: MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		onDelete(photo);
	};

	return (
		<Box sx={imageCard} onClick={navigateToImage}>
			<img
				src={photoImage?.scaledUrl || photoImage?.url}
				alt={photoImage?.name}
			/>
			<Box sx={overlayContainer}>
				{canDelete && (
					<Box sx={deleteButton}>
						<IconButton
							aria-label='delete'
							size='small'
							color='inherit'
							onClick={deleteImage}
						>
							<DeleteOutlineIcon fontSize='inherit' />
						</IconButton>
					</Box>
				)}
				<Box sx={photoInfo}>
					<Typography>{photoImage?.name}</Typography>
					<Box sx={commentInfo}>
						<Typography>
							{photo.discussion_stats.comments}
						</Typography>
						<img src={dashCommentIcon} />
					</Box>
				</Box>
			</Box>
		</Box>
	);
};
