import { Event, ScheduledEvent } from 'core/types';
import { useTranslation } from 'hooks';
import meal from 'assets/images/meal.svg?url';
import ride from 'assets/images/ride.svg?url';
import shopping from 'assets/images/shopping.svg?url';
import childcare from 'assets/images/childcare.svg?url';
import visit from 'assets/images/visit.svg?url';
import coverage from 'assets/images/coverage.svg?url';
import medical from 'assets/images/medical.svg?url';
import misc from 'assets/images/misc.svg?url';
import occasion from 'assets/images/occasion.svg?url';
import { getEventDateTimes } from 'core/utils/calender-events';

export const useEventMetadata = (event: Event | ScheduledEvent) => {
	const { startDate, endDate, startDateTime, endDateTime, duration } =
		getEventDateTimes(event);

	const eventTypes = useGetEventTypesMap();

	const eventIcons = {
		meal,
		ride,
		shopping,
		childcare,
		visit,
		coverage,
		medical,
		misc,
		occasion,
	};

	const eventType = eventTypes[event.type] ?? event.type;
	const eventIcon = eventIcons[event.type] ?? eventIcons.misc;

	return {
		eventType,
		eventIcon,
		startDate,
		endDate,
		startDateTime,
		endDateTime,
		duration,
		isAllDay: !startDateTime && !endDateTime,
	};
};

export const useGetEventTypesMap = () => {
	const { t } = useTranslation();

	return {
		meal: t('calendar-widget.preparing-meals'),
		ride: t('calendar-widget.giving-rides'),
		shopping: t('calendar-widget.shopping'),
		childcare: t('calendar-widget.childcare'),
		visit: t('calendar-widget.visits'),
		coverage: t('calendar-widget.coverage'),
		medical: t('calendar-widget.medical'),
		misc: t('calendar-widget.miscellaneous'),
		occasion: t('calendar-widget.occasions'),
	};
};
