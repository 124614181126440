import { useState, useCallback } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { IconButton } from '../../button/icon-button';
import { TextField, TextFieldProps } from '../text-field';

type PasswordFieldProps = TextFieldProps;

export const PasswordField = (props: PasswordFieldProps) => {
	const { InputProps, ...rest } = props;
	const [showPassword, setShowPassword] = useState(false);

	const handleClickShowPassword = useCallback(() => {
		setShowPassword(!showPassword);
	}, [showPassword]);

	return (
		<TextField
			{...rest}
			type={showPassword ? 'text' : 'password'}
			InputProps={{
				...InputProps,
				endAdornment: (
					<InputAdornment position='end'>
						<IconButton
							aria-label='toggle password visibility'
							onClick={handleClickShowPassword}
							sx={{
								fontSize: '16px',
							}}
						>
							{showPassword ? (
								<VisibilityOff fontSize='inherit' />
							) : (
								<Visibility fontSize='inherit' />
							)}
						</IconButton>
					</InputAdornment>
				),
			}}
		/>
	);
};
