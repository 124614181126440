import { useDimensions, useSearchParams, useTranslation } from 'hooks';
import {
	Box,
	CircularProgress,
	Grid,
	PageHeading,
	Paper,
} from 'components/common';
import { useSearchCommunity } from 'core/uses-cases/community';
import { useEffect, useRef, useState } from 'react';
import { SearchResultsCard } from 'components/community/search/search-results-card';
import {
	SearchForm,
	SearchFormSubmittedValues,
} from 'components/community/search/form';
import { useCurrentIdentity } from 'core/uses-cases/authentication';
import { resultsGridStyle } from './styles';

const BREAKPOINT = 852;

export const CommunitySearch = () => {
	const resultRef = useRef<HTMLDivElement>(null);
	const [searchParams, setSearchParams] = useSearchParams();
	const [triggerScroll, setTriggerScroll] = useState(false);
	const [searched, setSearched] = useState(false);

	const searchTerm = searchParams.get('searchTerm') ?? '';
	const zipCode = searchParams.get('zipCode') ?? '';
	const withinDistance = searchParams.get('withinDistance') ?? '';

	const { t } = useTranslation();
	const { data, isSuccess, isLoading, mutate } = useSearchCommunity();
	const identity = useCurrentIdentity();
	const [contentRef, dimensions] = useDimensions<HTMLDivElement>();

	const width = dimensions?.width ?? 0;

	useEffect(() => {
		if (searchTerm || zipCode || withinDistance) {
			setSearched(true);
			mutate({
				searchTerm: searchTerm.replace(/\W/g, " "),
				zipCode,
				withinDistance,
			});
		}
	}, [searchTerm, zipCode, withinDistance, mutate]);

	useEffect(() => {
		if (isSuccess && width < BREAKPOINT && triggerScroll) {
			resultRef.current?.scrollIntoView({ behavior: 'smooth' });
			setTriggerScroll(false);
		}
	}, [isSuccess, triggerScroll, width]);

	const handleOnSubmit = (values: SearchFormSubmittedValues) => {
		setSearchParams({
			searchTerm: values.searchTerm || '',
			zipCode: values.zipCode || '',
			withinDistance: values.withinDistance || '',
		});
		setTriggerScroll(true);
	};

	return (
		<Box ref={contentRef}>
			<Box
				sx={{
					visibility: searched ? 'visible' : 'hidden',
				}}
			>
				<PageHeading
					title={t('search.communities-found', {
						count: data?.length || 0,
					})}
				/>
			</Box>
			<Grid container spacing={3}>
				{!searched && <Grid item md={4} sm={2} xs={0} />}
				<Grid item md={searched ? 6 : 4} sm={searched ? 12 : 8} xs={12}>
					<Box component={Paper} px={4.5} py={3} elevation={0}>
						<SearchForm
							onSubmit={handleOnSubmit}
							isLoading={isLoading}
							initialValues={{
								searchTerm,
								zipCode,
								withinDistance,
							}}
						/>
					</Box>
				</Grid>
				{searched && (
					<Grid
						ref={resultRef}
						item
						md={6}
						sm={12}
						sx={resultsGridStyle}
					>
						{isLoading && (
							<Box display='flex' justifyContent='center' mt={4}>
								<CircularProgress />
							</Box>
						)}
						{data?.length !== 0 ? (
							<>
								{data?.map(community => (
									<SearchResultsCard
										key={community.uuid}
										community={community}
										loggedId={!!identity}
									/>
								))}
							</>
						) : (
							<Box
								component={Paper}
								px={4.5}
								py={3}
								elevation={0}
							>
								{t('search.no-results')}
							</Box>
						)}
					</Grid>
				)}
			</Grid>
		</Box>
	);
};
