import { useDialog } from 'components/common';
import { useTranslation } from 'hooks';
import {
	ImportEmailsModal,
	ImportEmailsContextValue,
} from '../setup/setup-community-members-form/import-emails-modal';

export const useImportGmailEmails = (
	onSubmit: (contacts: string[]) => void
) => {
	const { t } = useTranslation();

	const Content = (
		handleOnConfirm: (extraData: string[]) => void,
		_handleOnCancel: () => void,
		contacts: ImportEmailsContextValue
	) => {
		return (
			<ImportEmailsModal
				contacts={contacts}
				handleOnConfirm={handleOnConfirm}
			/>
		);
	};

	const { open } = useDialog<ImportEmailsContextValue, string[]>({
		title: t('community-form.choose-contacts'),
		content: Content,
		maxWidth: 'md',
		hideActions: true,
		onConfirm: (_contextData, extraData) => onSubmit(extraData),
	});

	const handleImportEmails = (contacts: ImportEmailsContextValue) => {
		open(contacts);
	};

	return handleImportEmails;
};
