import {
	DateTimePicker,
	DateTimePickerProps,
} from '@mui/x-date-pickers/DateTimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers';
import EventNoteIcon from '@mui/icons-material/EventNote';
import { DateTime } from 'luxon';
import { TextFieldProps } from '../text-field';

type DateTimePickerFieldProps = DateTimePickerProps<DateTime> &
	Omit<
		TextFieldProps,
		| 'value'
		| 'onChange'
		| 'multiline'
		| 'type'
		| 'rows'
		| 'maxRows'
		| 'minRows'
		| 'select'
		| 'SelectProps'
		| 'type'
		| 'defaultValue'
	> & {
		'data-test-id'?: string;
	};

export const DateTimePickerField = (props: DateTimePickerFieldProps) => {
	const { helperText, error, onFocus, size, onBlur, ...rest } = props;

	return (
		<DateTimePicker
			{...rest}
			slots={{
				openPickerIcon: EventNoteIcon,
			}}
			viewRenderers={{
				hours: renderTimeViewClock,
				minutes: renderTimeViewClock,
				seconds: renderTimeViewClock,
			}}
			format='M/d/yyyy   h:mm a'
			slotProps={{
				textField: {
					...(rest['data-test-id'] && {
						'data-test-id': rest['data-test-id'],
					}),
					error,
					helperText,
					onFocus,
					onBlur,
					size,
				},
			}}
			views={['month', 'day']}
		/>
	);
};
