import {
	PageHeading,
	Button,
	Box,
	CircularProgress,
	ButtonRouterLink,
} from 'components/common';
import {
	useDeleteCommunityGroup,
	useGetCommunityGroup,
	useManageCommunityGroup,
} from 'core/uses-cases/group';
import {
	useErrorHandler,
	useParams,
	useRouter,
	useServices,
	useTranslation,
} from 'hooks';
import { GroupCard } from 'components/groups';
import { Icon, LoadingButton, TeamHeader, useDialog } from 'components';
import { useCurrentIdentity } from 'core/uses-cases/authentication';
import { useGetGroupPermissions } from './use-group-access';

export const ViewGroup = () => {
	const { t } = useTranslation();
	const router = useRouter();
	const { notification } = useServices();
	const { communityId, groupId } = useParams() as {
		communityId: string;
		groupId: string;
	};
	const identity = useCurrentIdentity();
	const {
		data: group,
		error,
		isSuccess,
		isLoading,
	} = useGetCommunityGroup(communityId, groupId);

	useErrorHandler(error, {
		redirectTo: router.getPathFor('community.groups', {
			communityId,
		}),
	});

	const deleteGroupMutation = useDeleteCommunityGroup();

	const handleOnDelete = (groupId: string) => {
		deleteGroupMutation.mutateAsync({ communityId, groupId }).then(() => {
			notification.show(
				'success',
				t('group.group-was-successfully-deleted', {
					name: group?.description,
				})
			);
		});

		router.navigate('community.groups', {
			communityId,
		});
	};

	const handleOnLeave = () => {
		if (!identity) return;

		manageGroupMutation.mutate({
			communityId,
			groupId,
			identityId: identity.uuid,
			add: false,
		});
	};

	const manageGroupMutation = useManageCommunityGroup();

	const handleOnJoin = () => {
		if (!identity) return;

		manageGroupMutation.mutate({
			communityId,
			groupId,
			identityId: identity.uuid,
			add: true,
		});
	};

	const { open: handleDelete } = useDialog({
		title: t('groups.actions.delete-title'),
		content: t('groups.actions.delete'),
		confirmText: t('common.delete'),
		onConfirm: () => {
			handleOnDelete?.(groupId);
		},
	});

	const {
		canDelete,
		canEditMembers,
		canEditInfo,
		canEditPermissions,
		showGlobalPermissions,
	} = useGetGroupPermissions(group);

	if (!group || isLoading) {
		return (
			<Box display='flex' justifyContent='center' mt={4}>
				<CircularProgress message={t('common.loading')} />
			</Box>
		);
	}

	let groupActions = null;

	if (canEditPermissions) {
		groupActions = (
			<ButtonRouterLink
				to={router.getPathFor('community.group-permissions', {
					communityId,
					groupId,
				})}
				size='small'
				color='primary'
				variant='text'
			>
				{t('group.edit-group-permissions')}
			</ButtonRouterLink>
		);
	} else if (canEditMembers) {
		groupActions = (
			<ButtonRouterLink
				to={router.getPathFor('community.group-edit-members', {
					communityId,
					groupId,
				})}
				size='small'
				color='primary'
				variant='text'
			>
				{t('group.edit-group-members')}
			</ButtonRouterLink>
		);
	} else if (canEditInfo) {
		groupActions = (
			<ButtonRouterLink
				to={router.getPathFor('community.group-edit-info', {
					communityId,
					groupId,
				})}
				size='small'
				color='primary'
				variant='text'
			>
				{t('group.edit-group-info')}
			</ButtonRouterLink>
		);
	}

	let enrollmentActions = null;

	if (identity && group.has_open_enrollment) {
		const isMember = (group?.members ?? []).some(
			member => member.uuid === identity.uuid
		);

		if (isMember) {
			enrollmentActions = (
				<LoadingButton
					size='small'
					color='error'
					startIcon={<Icon name='cancel' />}
					onClick={handleOnLeave}
					loading={manageGroupMutation.isLoading}
					loadingIndicator={t('group.leaving-group')}
				>
					{t('group.leave-group')}
				</LoadingButton>
			);
		} else {
			enrollmentActions = (
				<LoadingButton
					size='small'
					color='primary'
					startIcon={<Icon name='add_circle' />}
					onClick={handleOnJoin}
					loading={manageGroupMutation.isLoading}
					loadingIndicator={t('group.joining-group')}
				>
					{t('group.join-group')}
				</LoadingButton>
			);
		}
	}

	return (
		<>
			<TeamHeader />
			<PageHeading
				title={t('group.group')}
				rightSection={
					canDelete ? (
						<Button
							size='small'
							color='error'
							onClick={() => handleDelete()}
						>
							{t('group.delete-group')}
						</Button>
					) : null
				}
			/>
			{isSuccess && (
				<GroupCard
					group={group}
					renderActions={groupActions}
					renderEnrollmentActions={enrollmentActions}
					showGlobalPermissions={showGlobalPermissions}
				/>
			)}
		</>
	);
};
