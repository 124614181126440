import { Box, RouterLink, Typography } from 'components';
import { Event } from 'core/types';
import { useRouter, useTranslation } from 'hooks';
import { getEventDateTimes } from 'core/utils/calender-events';
import { DateTime } from 'luxon';
import { openTaskTitle } from './styles';

const formatOption = {
	year: 'numeric',
	month: 'numeric',
	day: 'numeric',
	hour: 'numeric',
	minute: '2-digit',
	timeZoneName: 'short',
} as {
	year: 'numeric' | '2-digit' | undefined;
	month: 'numeric' | '2-digit' | undefined;
	day: 'numeric' | '2-digit' | undefined;
	hour: 'numeric' | '2-digit' | undefined;
	minute: 'numeric' | '2-digit' | undefined;
	timeZoneName: 'short' | 'long' | 'shortOffset';
};

type UpcomingEventCardProps = {
	event: Event;
	communityId: string;
	calendarId: string;
	timezone?: string;
};

export const UpcomingEventCard = (props: UpcomingEventCardProps) => {
	const {
		event,
		communityId,
		calendarId,
		timezone = 'America/Chicago',
	} = props;
	const { t } = useTranslation();
	const router = useRouter();
	const { startDate, startDateTime, endDateTime } = getEventDateTimes(event);

	let eventTime = null;

	if (!startDateTime) {
		const formattedDay = startDate.toLocaleString();
		eventTime = `${formattedDay} ${t('community-home.all-day-task')}`;
	} else if (startDateTime && !endDateTime) {
		eventTime = startDateTime
			.setZone(timezone)
			.toLocaleString(formatOption);
	} else if (startDateTime && endDateTime) {
		if (startDateTime.toLocaleString() === endDateTime.toLocaleString()) {
			eventTime = `${startDateTime
				.setZone(timezone)
				.toLocaleString(formatOption)} - ${endDateTime
				.setZone(timezone)
				.toLocaleString(DateTime.TIME_WITH_SHORT_OFFSET)}`;
		} else {
			eventTime = `${startDateTime
				.setZone(timezone)
				.toLocaleString(formatOption)} - ${endDateTime
				.setZone(timezone)
				.toLocaleString(formatOption)}`;
		}
	}

	return (
		<Box mb={2}>
			<RouterLink
				to={router.getPathFor('community.calendar.event.view', {
					communityId,
					calendarId,
					eventId: event.uuid,
				})}
			>
				<Typography>{eventTime}</Typography>
			</RouterLink>
			<Typography sx={openTaskTitle}>{event.name}</Typography>
		</Box>
	);
};
