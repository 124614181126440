import { SxProps } from '@mui/system';
import { COLORS } from 'components/calendar/constants';
import { Theme } from 'theme';

export const statusLabelStyle: SxProps<Theme> = theme => ({
	display: 'inline-flex',
	alignItems: 'center',

	'&:before': {
		display: 'inline-block',
		content: "''",
		width: 14,
		height: 14,
		borderRadius: '100%',
		marginRight: theme.spacing(1),
	},

	'&.help-needed-key:before': {
		backgroundColor: COLORS.helpNeeded,
	},
	'&.needs-met-key:before': {
		backgroundColor: COLORS.needsMet,
	},
	'&.occasions-key:before': {
		backgroundColor: COLORS.occasions,
	},
});

export const sectionStyle =
	(isLast?: boolean): SxProps<Theme> =>
	theme => ({
		padding: theme.spacing(2),
		borderBottom: isLast ? 'none' : `1px solid ${theme.palette.grey[300]}`,
		'& h4': {
			fontFamily: (theme as Theme).typography.fontFamily,
			fontWeight: 500,
			fontSize: 24,
			marginBottom: theme.spacing(2),
		},
		'& .MuiCheckbox-root': {
			padding: theme.spacing(0.5, 1),
		},
	});
