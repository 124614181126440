import { MenuItemProps } from '@mui/material';
import Badge from '@mui/material/Badge';
import { Community } from 'core/types';
import { useImageServer, useTranslation } from 'hooks';
import { forwardRef } from 'react';
import { formatDate } from 'utils/dates';
import { getCurrentLanguage } from 'utils/lang';
import {
	Avatar,
	Box,
	Typography,
	MenuItem,
	RouterLink,
	RouterLinkProps,
	Link,
} from '../../common';
import { avatarStyle } from './styles';

interface CommunityMenuItemProps extends MenuItemProps<typeof RouterLink> {
	community: Community;
}

export const CommunityMenuLink = forwardRef<HTMLAnchorElement, RouterLinkProps>(
	function CommunityMenuLink(props, ref) {
		return <RouterLink ref={ref} {...props} component='li' />;
	}
);

export const CommunityMenuItem = ({
	community,
	...rest
}: CommunityMenuItemProps) => {
	const communityImage = useImageServer(community?.image, {
		w: 40,
		h: 40,
		fit: 'crop',
	});
	const { t } = useTranslation();

	const component = community.is_legacy ? Link : CommunityMenuLink;

	return (
		<MenuItem {...rest} component={component} disableRipple>
			<>
				<Badge
					overlap='circular'
					variant='dot'
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					color='primary'
					invisible={
						community.last_active_dt === null ||
						community.last_login_dt > community.last_active_dt
					}
				>
					<Avatar sx={avatarStyle(40)} src={communityImage?.url} />
				</Badge>
				<Box
					minWidth={0}
					maxWidth={getCurrentLanguage() === 'fr' ? 500 : 300}
				>
					<Typography
						variant='button2'
						overflow='hidden'
						textOverflow='ellipsis'
						display='block'
					>
						{community.name}
					</Typography>
					<Typography
						variant='body1'
						overflow='hidden'
						textOverflow='ellipsis'
						display='block'
						color={theme => theme.blacks[0.54]}
					>
						{community.last_login_dt &&
							t('community-header.last-visit', {
								date: formatDate(
									community.last_login_dt,
									'M/d/yyyy'
								),
							})}
						{community.last_active_dt &&
							t('community-header.last-active', {
								date: formatDate(
									community.last_active_dt,
									'M/d/yyyy'
								),
							})}
					</Typography>
				</Box>
			</>
		</MenuItem>
	);
};
