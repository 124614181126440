import { Event } from 'core/types';
import { getEventDateTimes } from 'core/utils/calender-events';
import { FILTER_STATUSES } from './constants';
import { CalendarEvent } from './types';

export const transformEventToCalendarEvent = (event: Event): CalendarEvent => {
	const { uuid, name, description, type } = event;
	const category = type === 'occasion' ? event.type : 'event';

	const { startDate, endDate, startDateTime, endDateTime } =
		getEventDateTimes(event);

	let status = null;

	if (category === 'occasion') {
		status = FILTER_STATUSES.OCCASIONS;
	} else {
		if (event.volunteers_available > 0) {
			status = FILTER_STATUSES.HELP_NEEDED;
		} else {
			status = FILTER_STATUSES.NEEDS_MET;
		}
	}

	return {
		uuid,
		name,
		category,
		description,
		type,
		startDate,
		endDate,
		startDateTime,
		endDateTime,
		volunteers: event.volunteers || [],
		coordinators: event.coordinators || [],
		volunteersAvailable: event.volunteers_available,
		volunteersNeeded: event.volunteers_needed,
		locations: event.locations,
		status,
	};
};
