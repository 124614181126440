type PromiseStatus = 'pending' | 'fulfilled' | 'rejected';

export class QueryablePromise<T = any> extends Promise<T> {
	_status: PromiseStatus = 'pending';

	constructor(
		executor: (
			resolve: (value: T | PromiseLike<T>) => void,
			reject: (reason?: any) => void
		) => void
	) {
		super((resolve, reject) =>
			executor(
				data => {
					resolve(data);
					this._status = 'fulfilled';
				},
				err => {
					reject(err);
					this._status = 'rejected';
				}
			)
		);

		this._status = 'pending';
	}

	get status(): PromiseStatus {
		return this.status;
	}
}
