import { useCurrentCommunity } from 'components';
import { Box, Grid, Typography, Heading, Paper } from 'components/common';
import {
	SetupCommunityProfileForm,
	SetupCommunityProfileSubmittedValues,
	SetupCommunityStepper,
} from 'components/community/setup';
import { COMMUNITY_SETUP_MEMBERS } from 'core/constants';
import { useCurrentIdentity } from 'core/uses-cases/authentication';
import { usePatchCommunity } from 'core/uses-cases/community';
import { usePatchIdentity } from 'core/uses-cases/identity';
import { useRouter, useServices, useTranslation } from 'hooks';
import { useCallback } from 'react';
import { Navigate } from 'react-router-dom';

export const SetupProfileCommunity = () => {
	const { t } = useTranslation();
	const community = useCurrentCommunity();
	const identity = useCurrentIdentity();
	const router = useRouter();
	const { notification } = useServices();

	const communityMutation = usePatchCommunity();
	const identityMutation = usePatchIdentity();

	const handleOnSubmit = useCallback(
		async (values: SetupCommunityProfileSubmittedValues) => {
			if (!community || !identity) return undefined;

			await communityMutation.mutateAsync({
				communityId: community.slug,
				name: community.name,
				slug: values.community.slug || community.slug,
				image: values.community.image,
				setup_status: COMMUNITY_SETUP_MEMBERS,
			});

			await identityMutation.mutateAsync({
				identityId: identity.uuid,
				image: values.identity.image,
				detectTimezone: identity.autodetect_timezone,
			});

			notification.show(
				'success',
				t('edit-community.community-update-success')
			);

			router.navigate('community.setup-members', {
				communityId: values.community.slug || community.slug,
			});
		},
		[community, identity]
	);

	if (!community) {
		return <Navigate to='/?error=community-not-found' replace />;
	}

	if (!identity) {
		return <Navigate to='/?error=identity-not-found' replace />;
	}

	return (
		<Box>
			<Grid mb={4} container spacing={3}>
				<Grid item xs={12} md={8}>
					<Heading variant='h4' mb={1}>
						{t('create-community.create-community')}
					</Heading>
					<Typography variant='body2' mb={1}>
						{t("create-community.you're-almost-there")}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<SetupCommunityStepper />
				</Grid>
			</Grid>
			<Box component={Paper} elevation={0} mt={6} px={5} py={5}>
				<SetupCommunityProfileForm
					onSubmit={handleOnSubmit}
					community={community}
					identity={identity}
					initialValues={{
						identity,
					}}
				/>
			</Box>
		</Box>
	);
};
