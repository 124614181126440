import { useServices } from 'core/services';
import { BaseHttpError } from 'core/services/api/errors';
import { NotesResponse } from 'core/services/api/response-types';
import { Note } from 'core/types';
import { getPageParams } from 'core/utils/pagination';
import { noteKeys } from 'core/utils/query-key-factory';
import { useInfiniteQuery } from 'react-query';

export const useGetNotes = (
	communityId: string,
	options: {
		enable?: boolean;
	} = {
		enable: true,
	}
) => {
	const { api, authentication } = useServices();

	const isLoggedIn = authentication.isAuthenticated();

	const notesQuery = useInfiniteQuery<NotesResponse, BaseHttpError, Note[]>(
		noteKeys.list(communityId),
		({ pageParam = null, signal }) =>
			api.getNotes(communityId, {
				pagination: pageParam,
				signal,
			}),
		{
			enabled: !!communityId && isLoggedIn && options.enable,
			select: response => {
				return {
					pages: response.pages.map(page => page.data),
					pageParams: response.pageParams,
				};
			},
			getPreviousPageParam: firstPage =>
				getPageParams(firstPage.pagination).previousParam,
			getNextPageParam: lastPage =>
				getPageParams(lastPage.pagination).nextParam,
			notifyOnChangeProps: 'tracked',
			refetchOnMount: true,
		}
	);

	return notesQuery;
};
