import { SxProps } from '@mui/system';
import { colord } from 'colord';
import { Theme } from 'theme';

export const avatarStyle: SxProps = {
	maxWidth: '100%',
	verticalAlign: 'middle',

	'&[src$="svg"]': {
		width: 200,
	},
};

export const communityAvatarContainerStyle: SxProps<Theme> = theme => ({
	position: 'relative',
	display: 'flex',
	flexDirection: 'column',
	flexWrap: 'nowrap',
	alignItems: 'center',
	overflow: 'hidden',
	marginBottom: 3,

	'& .edit-button': {
		display: 'none',
		position: 'absolute',
		top: 0,
		right: 0,
		bottom: 0,
		left: 0,
		background: colord(theme.secondaryColors.secondary3 as string)
			.alpha(0.35)
			.toHex(),
		cursor: 'pointer',
		textAlign: 'center',
		overflow: 'hidden',

		'& > div': {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			height: '100%',
			'& .MuiIcon-root': {
				verticalAlign: 'middle',
				fontSize: 100,
				color: theme.palette.common.white,
			},
		},
	},

	'& .overlay': {
		position: 'absolute',
		top: 0,
		right: 0,
		bottom: 0,
		left: 0,
		background: 'rgba(0,0,0,.5)',
		zIndex: 1,
	},

	'&:hover .edit-button': {
		display: 'block',
	},
});

export const boxStyle: SxProps = {
	display: 'flex',
	flexDirection: 'column',
	flexWrap: 'nowrap',
	p: 3,
	'& > .MuiAvatar-root': {
		marginBottom: 4,
	},
	'& > a': {
		paddingRight: 0,
		paddingLeft: 0,
		paddingTop: 0,
		letterSpacing: 1,
	},
};

export const sectionHeader: SxProps<Theme> = theme => ({
	color: theme.primaryColors.primary4,
	fontWeight: 700,
});

export const openTaskTitle: SxProps<Theme> = theme => ({
	color: theme.primaryColors.primary6,
	fontSize: 14,
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});
