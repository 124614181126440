import { Theme } from 'theme';

export const navigationStyled = (theme: Theme) => ({
	fontSize: 10,
	minWidth: 'unset',
	padding: theme.spacing(1),

	'& svg': {
		fontSize: 14,
	},
});
