import { useCurrentIdentity } from 'core/uses-cases/authentication';
import { useMemo, useState } from 'react';
import { DiscussionContext, DiscussionContextValue } from './context';

export type DiscussionProviderProps = Pick<
	DiscussionContextValue,
	'discussionId' | 'enabled'
>;

export const DiscussionProvider: React.FC<DiscussionProviderProps> = ({
	discussionId,
	enabled,
	children,
}) => {
	const identity = useCurrentIdentity();
	const [enabledToggler, setEnabledToggler] = useState(false);

	const renderedContent = useMemo(() => children, []);

	if (!identity || !enabled) return null;

	const value: DiscussionContextValue = {
		discussionId,
		enabled,
		identity,
		setEnabledToggler,
		isTogglerEnabled: enabledToggler,
	};

	return (
		<DiscussionContext.Provider value={value}>
			{renderedContent}
		</DiscussionContext.Provider>
	);
};

export const DiscussionConsumer = DiscussionContext.Consumer;
