import { BaseHttpClient } from '../base-http-client';
import { RequestToJoinResponse } from '../response-types';

export const createRequestToJoinApi = (http: BaseHttpClient) => ({
	postRequestToJoin(communityId: string, notes: string) {
		return http
			.post<RequestToJoinResponse>(
				`community/${communityId}/membership-request`,
				{ notes }
			)
			.then(response => response.data);
	},
});
