import { isCancelledError, Mutation, useMutation } from 'react-query';
import { communityKeys } from 'core/utils/query-key-factory';
import { deleteTransferOwnershipMutationKey } from 'core/utils/mutation-key-factory';
import { SetupMutationFn } from 'core/configure/types';
import { Services } from 'core/services';
import { TransferOwnershipRequest } from 'core/types';
import { cancelPreviousMutation } from 'core/utils/optimistic-utils';
import { BaseHttpError } from '../../services/api/errors';
import { isInitiateTransferMutation } from './use-initiate-transfer-ownership';

export interface DeleteCommunityTransferMutationInput {
	communityId: string;
	hasTransferRequests: boolean;
}

export const useDeleteCommunityTransfer = () => {
	const mutation = useMutation<
		null,
		BaseHttpError,
		DeleteCommunityTransferMutationInput
	>(deleteTransferOwnershipMutationKey);

	return mutation;
};

type MutationContext = {
	communityId: string;
	snapshot: TransferOwnershipRequest[];
};

export const setupDeleteCommunityTransfer: SetupMutationFn = (
	services: Services,
	createTrackedParallelMutation,
	mutationTracker
) => {
	const { queryClient, api } = services;

	const mutation = createTrackedParallelMutation<
		null,
		BaseHttpError,
		DeleteCommunityTransferMutationInput,
		MutationContext
	>({
		mutationFn: ({ communityId, hasTransferRequests }) => {
			if (hasTransferRequests) {
				return api.deleteTransferOwnershipRequest(communityId);
			}

			return Promise.resolve(null);
		},
		onMutate: async input => {
			const { communityId } = input;

			// Cancel the previous mutation if it exists
			await cancelPreviousMutation<MutationContext>(
				queryClient,
				mutation => {
					return (
						mutation.state.context?.communityId === communityId &&
						(isInitiateTransferMutation(mutation) ||
							isDeleteTransferMutation(mutation))
					);
				}
			);

			await queryClient.cancelQueries(
				communityKeys.transferRequests(communityId)
			);

			const snapshot =
				queryClient.getQueryData<TransferOwnershipRequest[]>(
					communityKeys.transferRequests(communityId)
				) ?? [];

			// Clean up all transfer requests
			queryClient.setQueryData<TransferOwnershipRequest[]>(
				communityKeys.transferRequests(communityId),
				[]
			);

			return { communityId, snapshot };
		},
		onError: (error, { communityId }, context) => {
			if (context && !isCancelledError(error)) {
				queryClient.setQueryData<TransferOwnershipRequest[]>(
					communityKeys.transferRequests(communityId),
					context.snapshot
				);
			}
		},
		onSettled: (data, error, { communityId }, context) => {
			if (context && !isCancelledError(error)) {
				mutationTracker.queueInvalidations(
					communityKeys.transferRequests(communityId)
				);
			}
		},
		retry: 1,
	});

	queryClient.setMutationDefaults(
		deleteTransferOwnershipMutationKey,
		mutation
	);
};

export const isDeleteTransferMutation = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	mutation: Mutation<any, any, any, any>
) =>
	[deleteTransferOwnershipMutationKey].includes(
		String(mutation?.options?.mutationKey)
	);
