import { SxProps } from '@mui/system';
import { Theme } from 'theme';

export const videoStyle: SxProps<Theme> = {
	mt: 3,
	'> video': {
		width: { xs: 'auto', md: 432, lg: 720 },
		height: { xs: 'auto', md: 243, lg: 405 },
	},
};
