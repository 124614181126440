import { isCancelledError, Mutation, useMutation } from 'react-query';
import { Event } from 'core/types';
import { SetupMutationFn } from 'core/configure/types';
import { calendarKeys } from 'core/utils/query-key-factory';
import { removeVolunteerMutationKey } from 'core/utils/mutation-key-factory';
import { Services } from '../../services/types';
import { BaseHttpError } from '../../services/api/errors';
import { updateSingleEventInCache } from './utils';

export interface RemoveVolunteerMutationInput {
	communityId: string;
	calendarId: string;
	eventId: string;
	identityId: string;
}

export const useRemoveVolunteer = () => {
	const mutation = useMutation<
		null,
		BaseHttpError,
		RemoveVolunteerMutationInput
	>(removeVolunteerMutationKey);

	return mutation;
};

type Context = {
	eventSnapshot: Event | undefined;
	communityId: string;
	calendarId: string;
	eventId: string;
};

export const setupRemoveVolunteer: SetupMutationFn = (
	services: Services,
	createTrackedParallelMutation,
	mutationTracker
) => {
	const { queryClient, api } = services;

	const mutation = createTrackedParallelMutation<
		null,
		BaseHttpError,
		RemoveVolunteerMutationInput,
		Context
	>({
		mutationFn: async ({ eventId, identityId }) => {
			return api.deleteIdentityVolunteer(eventId, identityId);
		},
		onMutate: async input => {
			const { calendarId, communityId, eventId, identityId } = input;

			const eventSnapshot = queryClient.getQueryData<Event>(
				calendarKeys.detail(calendarId, eventId)
			);

			if (eventSnapshot) {
				const updatedEvent: Event = {
					...eventSnapshot,
					volunteers: (eventSnapshot.volunteers ?? []).filter(
						volunteer => volunteer.uuid !== identityId
					),
					volunteers_available:
						eventSnapshot.volunteers_available + 1,
				};

				queryClient.setQueryData<Event>(
					calendarKeys.detail(calendarId, eventId),
					updatedEvent
				);

				updateSingleEventInCache(queryClient, calendarId, updatedEvent);
			}

			return {
				eventSnapshot,
				communityId,
				calendarId,
				eventId,
			};
		},
		onError: (error, { calendarId, eventId }, context) => {
			if (!isCancelledError(error) && context?.eventSnapshot) {
				queryClient.setQueryData<Event>(
					calendarKeys.detail(calendarId, eventId),
					context.eventSnapshot
				);
			}
		},
		onSettled: (data, error, variables, context) => {
			if (context && !isCancelledError(error)) {
				mutationTracker.queueInvalidations(
					calendarKeys.detail(context.calendarId, context.eventId),
					calendarKeys.lists(context.calendarId)
				);
			}
		},
		retry: 1,
	});

	queryClient.setMutationDefaults(removeVolunteerMutationKey, mutation);
};

export const isRemoveVolunteerMutation = (
	mutation: Mutation<any, any, any, any>
) =>
	[removeVolunteerMutationKey].includes(
		String(mutation?.options?.mutationKey)
	);
