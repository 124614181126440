import React, { ComponentType } from 'react';
import { useRouter, useVerifyAccess } from 'hooks';
import { Navigate, useParams } from 'react-router-dom';
import { EntityKey, PermissionsKey } from 'core/types';
import { Box, CircularProgress } from 'components';
import { useGetAccessGuardItem } from './use-get-access-guard-item';

type GuardProps = {
	action: PermissionsKey;
	entity: EntityKey;
	element: React.ReactNode;
	useItem?: boolean;
	layout?: ComponentType;
};

export const AccessGuard = (props: GuardProps) => {
	const {
		layout: Layout = React.Fragment,
		element,
		action,
		entity,
		useItem,
	} = props;
	const verify = useVerifyAccess();
	const router = useRouter();
	const params = useParams();
	const { item, isLoading } = useGetAccessGuardItem(entity, useItem);

	if (isLoading) {
		return (
			<Box display='flex' justifyContent='center' mt={4}>
				<CircularProgress />
			</Box>
		);
	}

	if (!verify(action, entity, item)) {
		return (
			<Navigate
				to={router.getPathFor('community.home', {
					communityId: params.communityId,
				})}
				replace
			/>
		);
	}

	return <Layout>{element}</Layout>;
};
