import { AnnouncementFormValues } from 'components/announcements/form';
import { useVisibilityFormData } from 'components/community/hooks';
import { Announcement, Identity } from 'core/types';
import { useCurrentIdentity } from 'core/uses-cases/authentication';
import { useCommunityMembers } from 'core/uses-cases/member';
import { useMemo } from 'react';

export const useAnnouncementFormData = (
	communityId: string,
	announcement?: Announcement
) => {
	const members = useCommunityMembers(communityId);
	const identity = useCurrentIdentity();

	const { visibilityData, groups } =
		useVisibilityFormData<AnnouncementFormValues>(announcement);

	const authors = useMemo(
		() => members?.map(member => member.identity) ?? [identity as Identity], // Use current identity as fallback
		[members, identity]
	);

	const data = useMemo(() => {
		// If the announcement is not provided, we are creating a new announcement.
		if (!announcement) {
			return {
				author: identity?.uuid,
				whenToPost: 'now',
			} as AnnouncementFormValues;
		}

		return {
			title: announcement?.title,
			message: announcement?.message,
			author: announcement?.author.uuid,
			allowDiscussion: announcement?.discussion_state === 'enabled',
			image: announcement?.image
				? announcement.image
				: null, // prettier-ignore
			publishedDt: announcement?.published_dt,
			whenToPost: announcement?.published_dt ? 'date-time' : 'draft',
		} as AnnouncementFormValues;
	}, [announcement, identity]);

	return {
		authors,
		initialValues: {
			...data,
			...visibilityData,
		},
		groups,
	};
};
