import { SxProps } from '@mui/system';
import { AnyColor, colord } from 'colord';
import { Theme } from 'theme';

export const headerContainerStyled = (theme: Theme) => ({
	display: 'flex',
	alignItems: 'center',
	padding: theme.spacing(2),
	backgroundColor: colord(theme.secondaryColors.secondary5 as AnyColor)
		.alpha(0.1)
		.toRgbString(),
	borderBottom: `2px solid ${theme.palette.grey[400]}`,

	'&> button': {
		backgroundColor: 'white',
	},
});

export const headerWidgetContainerStyle: SxProps<Theme> = theme => ({
	display: 'flex',
	alignItems: {
		xs: 'flex-start',
		md: 'center',
	},
	flexDirection: {
		xs: 'column',
		md: 'row',
	},
	'& button': {
		marginRight: theme.spacing(1),

		'&+ div': {
			marginLeft: theme.spacing(1),
		},
	},
});

export const eventTypesStyle: SxProps<Theme> = theme => ({
	flex: 1,
	display: 'flex',
	justifyContent: 'flex-end',
	alignItems: 'center',
	marginTop: { xs: 2, md: 0 },
	'> p': {
		marginLeft: theme.spacing(3),
		fontSize: 14,
		lineHeight: 1,

		'&:first-of-type': {
			marginLeft: 0,
		},
	},
});

export const spinnerStyle: SxProps<Theme> = {
	position: 'absolute',
	bottom: 0,
	width: '100%',
};
