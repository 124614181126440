import { useRegisterSW } from 'virtual:pwa-register/react';
import Alert from '@mui/material/Alert';
import { Button } from '../button';
import { Box } from '../layout';
import { containerStyle } from './styles';

export const ReloadPrompt = () => {
	const {
		needRefresh: [needRefresh],
		updateServiceWorker,
	} = useRegisterSW();

	return (
		<Box sx={containerStyle}>
			{needRefresh && (
				<Alert
					variant='filled'
					severity='info'
					color='success'
					action={
						<>
							<Button
								color='inherit'
								size='small'
								onClick={() => updateServiceWorker(true)}
								variant='text'
							>
								Reload
							</Button>
						</>
					}
				>
					<span>
						New content available, click on reload button to update.
					</span>
				</Alert>
			)}
		</Box>
	);
};

ReloadPrompt.whyDidYouRender = {
	logOnDifferentValues: true,
	customName: 'ReloadPrompt',
};
