import { SxProps } from '@mui/system';
import { Theme } from 'theme';

export const replyButtonStyle: SxProps<Theme> = {
	paddingY: 0.5,
	paddingX: 0,
};

export const commentTopInfoStyle: SxProps<Theme> = theme => ({
	display: 'flex',
	alignItems: 'flex-start',
	flex: 1,
	mt: 0.5,

	'& > p': {
		lineHeight: 1,
		fontWeight: 'bold',

		'& + p': {
			fontWeight: 'normal',
			marginLeft: theme.spacing(1),
			lineHeight: 1,
			color: theme.primaryColors.primary6,
		},
	},
});

export const commentWrapperStyle: SxProps<Theme> = theme => ({
	flex: 1,
	padding: 3,
	justifyContent: 'center',
	alignItems: 'center',
	textAlign: 'center',
	borderTop: '1px solid',
	borderColor: theme.secondaryColors.secondary6,
	color: theme.primaryColors.primary6,
	cursor: 'pointer',
});

export const titleStyle: SxProps<Theme> = theme => ({
	color: theme.primaryColors.primary6,
});

export const discussionFooterWrapperStyle: SxProps<Theme> = theme => ({
	display: 'flex',
	alignItems: 'center',
	cursor: 'pointer',
	'&.comment-widget-open p': {
		color: theme.primaryColors.primary3,
	},
});
