import { Note } from 'core/types';
import { ButtonRouterLink, PageHeading } from 'components/common';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import {
	useRouter,
	useParams,
	useTranslation,
	useVerifyAccess,
	useCurrentCommunity,
} from 'hooks';
import { NoteListContainer, useDeleteNoteHandler } from 'components/notes';
import { useCallback } from 'react';

export const NoteList = () => {
	const { t } = useTranslation();
	const router = useRouter();
	const verify = useVerifyAccess();
	const { communityId } = useParams() as { communityId: string };

	const currentCommunity = useCurrentCommunity();

	const deleteNoteHandler = useDeleteNoteHandler();

	const handleOnClick = useCallback(note => {
		router.navigate('note.view', {
			communityId,
			noteId: note.uuid,
		});
	}, []);

	const handleOnEdit = useCallback(note => {
		router.navigate('note.edit', {
			communityId,
			noteId: note.uuid,
		});
	}, []);

	const handleOnDelete = useCallback(
		(note: Note) => {
			deleteNoteHandler({
				noteId: note.uuid,
				communityId,
			});
		},
		[communityId]
	);

	const canCreateNote = verify('create', 'note');

	const adminRoleId = currentCommunity?.groups.all.find(
		g => g.name === 'site_admin'
	)?.uuid;

	return (
		<>
			<PageHeading
				title={t('notes.notes')}
				rightSection={
					canCreateNote ? (
						<ButtonRouterLink
							to={router.getPathFor('note.create', {
								communityId,
							})}
							startIcon={
								<AddCircleOutlineOutlinedIcon fontSize='large' />
							}
							size='small'
						>
							{t('notes.create-note')}
						</ButtonRouterLink>
					) : null
				}
			/>
			<NoteListContainer
				communityId={communityId}
				onClick={handleOnClick}
				onEdit={handleOnEdit}
				onDelete={handleOnDelete}
				canCreate={canCreateNote}
				adminRoleId={adminRoleId}
			/>
		</>
	);
};
