import { SxProps } from '@mui/system';

export const stackStyle: (responsive: boolean) => SxProps = responsive => ({
	...(responsive
		? {
			width: {
				md: 8 / 12,
				sm: 1,
			},
		  }
		: {}), // prettier-ignore
	'> div, > dl': {
		alignSelf: 'stretch',

		'& dt': {
			marginBottom: 1,
		},
	},
});
