import { useShowGlobalPermission } from 'components/permissions/use-show-global-permission';
import { Group } from 'core/types';
import { useVerifyAccess } from 'hooks';

export const useGetGroupPermissions = (group?: Group) => {
	const showGlobalPermissions = useShowGlobalPermission();
	const verify = useVerifyAccess();

	const canCreateGroup = verify('create', 'group');
	let canDelete = false;
	let canEditMembers = false;
	let canEditInfo = false;
	let canEditPermissions = false;

	if (group) {
		canDelete =
			!group.is_system_managed &&
			!group.has_locked_permissions &&
			!group.is_hidden &&
			verify('delete', 'group', group);

		canEditPermissions = group.is_system_managed && showGlobalPermissions;

		canEditMembers =
			!group.is_system_managed &&
			group.has_locked_permissions &&
			verify('update', 'group', group);

		canEditInfo =
			!canEditMembers &&
			!group.is_system_managed &&
			verify('update', 'group', group);
	}

	return {
		canDelete,
		canEditMembers,
		canEditInfo,
		canCreateGroup,
		canEditPermissions,
		showGlobalPermissions,
	};
};
