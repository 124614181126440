import config from 'config';
import { useCallback, useEffect, useRef } from 'react';
import { v4 as uuid } from 'uuid';
import { useForceUpdate } from './use-force-update';

const recaptchaSiteKey = config.recaptcha.siteKey;

type RecaptchaInstance = {
	execute: (
		key: string,
		options: { action: string }
	) => Promise<null | string>;
	ready: (callback: () => void) => void;
};

export const useRecaptcha = () => {
	const recaptchaRef = useRef<RecaptchaInstance>();
	const scriptId = useRef(uuid()).current;
	const forceUpdate = useForceUpdate();

	useEffect(() => {
		if (!config.recaptcha.siteKey) {
			console.warn('Recaptcha site key is not set');
			return () => ({});
		}

		loadRecaptcha(scriptId)
			.then(recaptcha => {
				recaptchaRef.current = recaptcha;
			})
			.catch(error => {
				console.error('Failed to load Recaptcha', error);
			})
			.finally(() => forceUpdate());

		return () => cleanRecaptcha(scriptId);
	}, []);

	const execute = useCallback(
		(action: string) => {
			if (!recaptchaRef.current) {
				return Promise.resolve(null);
			}

			return recaptchaRef.current
				.execute(recaptchaSiteKey, { action })
				.catch(() => null);
		},
		[recaptchaRef]
	);

	return { execute };
};

function loadRecaptcha(id: string): Promise<RecaptchaInstance> {
	return new Promise((resolve, reject) => {
		const alreadyInjected = !!document.getElementById(id);

		if (alreadyInjected) {
			return onLoad();
		}

		const src = `https://www.google.com/recaptcha/api.js?render=${recaptchaSiteKey}`;
		const script = document.createElement('script');
		script.id = id;
		script.src = src;
		script.async = true;
		script.defer = true;
		document.body.appendChild(script);

		script.onload = onLoad;
		script.onerror = reject;

		function onLoad() {
			const grecaptcha = (window as any)
				.grecaptcha as unknown as RecaptchaInstance;
			grecaptcha.ready(() => resolve(grecaptcha));
		}
	});
}

function cleanRecaptcha(id: string) {
	document.getElementById(id)?.remove();

	const badges = document.querySelector('.grecaptcha-badge');

	if (badges && badges.parentNode) {
		document.body.removeChild(badges.parentNode);
	}
}
