import { useCallback, useMemo, useRef } from 'react';
import { Group, Album } from 'core/types';
import { useTranslation } from 'hooks';
import { sleep } from 'utils/delay';
import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { AlbumInput } from 'core/services/api/api-client/types';
import { ButtonSection } from 'components/common/form/button-section';
import {
	TextField,
	Form,
	Field,
	Grid,
	ImagePickerField,
	VisibilityField,
	visibilityFieldValidationSchema,
	FormStack,
	PickerFile,
	FormOnSubmitReturn,
	FieldForSubscription,
	Typography,
} from '../common';

export interface AlbumFormSubmittedValues extends AlbumInput {
	photos: PickerFile[];
}

export type AlbumFormInitialValues = Partial<AlbumFormSubmittedValues>;

export type AlbumFormProps = {
	initialValues: AlbumFormInitialValues;
	groups: Group[];
	onSubmit?: (values: AlbumFormSubmittedValues) => FormOnSubmitReturn<Album>;
	submitText?: string;
	submittingText?: string;
	edit: boolean;
	onDelete?: () => void;
	deleteText?: string;
};

export const AlbumForm = ({
	onSubmit,
	initialValues,
	submitText,
	submittingText,
	groups,
	edit,
	onDelete,
	deleteText,
}: AlbumFormProps) => {
	const { t } = useTranslation();

	const cachedInitialValues = useRef({
		...initialValues,
	}).current;

	const handleOnSubmit = useCallback(
		async values => {
			await sleep(1);

			const result = onSubmit?.(values);

			return Promise.resolve(result).catch(error => error);
		},
		[onSubmit]
	);

	const validationSchema = useMemo(
		() => createValidationSchema(t, edit),
		[edit]
	);

	return (
		<Form
			onSubmit={handleOnSubmit}
			validationSchema={validationSchema}
			subscription={{ submitting: true }}
			initialValues={cachedInitialValues}
		>
			{({ handleSubmit, submitting }) => (
				<form onSubmit={handleSubmit} noValidate>
					<FormStack responsive={false}>
						<Grid container spacing={4}>
							<Grid item xs={12} md={8}>
								<Field
									label={t('album-form.name')}
									name='name'
									component={TextField}
									type='text'
									fullWidth
									required
								/>
							</Grid>
							{!edit && (
								<>
									<Grid item xs={12} md={8}>
										<Field
											label={t('album-form.photos')}
											name='photos'
											component={ImagePickerField}
											isMultiple
											required
										/>
									</Grid>
									<FieldForSubscription
										name='photos'
										subscription={{ value: true }}
									>
										{({ input: { value } }) => {
											if (!value.length) {
												return (
													<Grid item xs={12} md={8}>
														<Typography>
															{t(
																'album-form.no-photos'
															)}
														</Typography>
													</Grid>
												);
											}
											return <></>;
										}}
									</FieldForSubscription>
								</>
							)}
							<Grid item xs={12} md={8}>
								<VisibilityField
									groups={groups}
									required
									label={t('album-form.view-permission')}
								/>
							</Grid>
							<Grid item xs={12} md={8}>
								<VisibilityField
									name='upload'
									label={t('album-form.upload-permission')}
									groups={groups}
									required
								/>
							</Grid>
						</Grid>
						<ButtonSection
							submitting={submitting}
							submitText={submitText}
							submittingText={submittingText}
							onDelete={onDelete}
							deleteText={deleteText}
						/>
					</FormStack>
				</form>
			)}
		</Form>
	);
};

function createValidationSchema(t: TFunction, edit: boolean) {
	let mainShape = Yup.object().shape({
		name: Yup.string()
			.trim()
			.required(
				t('form.required', {
					name: t('album-form.name'),
				})
			),
	});

	if (!edit) {
		mainShape = mainShape.concat(
			Yup.object().shape({
				photos: Yup.array()
					.min(
						1,
						t('form.required', {
							name: t('album-form.photos'),
						})
					)
					.required(
						t('form.required', {
							name: t('album-form.photos'),
						})
					),
			})
		);
	}

	const schema = mainShape
		.concat(visibilityFieldValidationSchema('visibility'))
		.concat(visibilityFieldValidationSchema('upload'));

	return schema;
}
