import defaultImage from 'assets/images/community_profile.svg?url';
import { Community } from 'core/types';
import { selectImage } from './assets';

export const selectCommunity = (data: Community): Community => {
	return {
		...data,
		image: selectImage(data.image, defaultImage),
	};
};

export const selectMyCommunities = (data: Community[]): Community[] => {
	return data.map(selectCommunity);
};
