import { BaseHttpError } from 'core/services/api/errors';
import { albumKeys } from 'core/utils/query-key-factory';
import { useServices } from 'core/services';
import { useQuery } from 'react-query';
import { Album } from 'core/types';

export const useGetAlbums = (communityId: string) => {
	const { api, authentication } = useServices();

	const isLoggedIn = authentication.isAuthenticated();

	const albumsQuery = useQuery<Album[], BaseHttpError>(
		albumKeys.list(communityId),
		() => {
			return api.getAlbums(communityId).then(response =>
				response.data.albums.map(album => ({
					...album,
					photos: [],
				}))
			);
		},
		{
			enabled: !!communityId && isLoggedIn,
			keepPreviousData: true,
		}
	);

	return albumsQuery;
};
