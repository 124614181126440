import { BaseHttpError } from 'core/services/api/errors';
import { Announcement } from 'core/types';
import { PaginatedRecords } from 'core/utils/optimistic-utils';
import { announcementKeys } from 'core/utils/query-key-factory';
import { useServices } from 'hooks';
import { useCallback } from 'react';
import { useQuery, useQueryClient, onlineManager } from 'react-query';
import { getAnnouncementsListKeys } from './utils';

export const useGetAnnouncement = (
	communityId: string,
	announcementId: string,
	options: { enable: boolean } = { enable: true }
) => {
	const { api, authentication } = useServices();

	const isLoggedIn = authentication.isAuthenticated();

	const queryClient = useQueryClient();

	const getInitialData = useCallback(() => {
		let announcement;

		// If it is online, then fetch the announcement from the API
		if (onlineManager.isOnline()) return announcement;

		// Otherswise, fetch the announcement from the cache
		for (const key of getAnnouncementsListKeys(communityId)) {
			const result =
				queryClient.getQueryData<PaginatedRecords<Announcement>>(key);

			for (const page of result?.pages ?? []) {
				announcement = page.data.find(
					record => record.uuid === announcementId
				);

				if (announcement) break;
			}

			if (announcement) break;
		}

		return announcement;
	}, [announcementId]);

	const { data, isSuccess, isLoading, isError, error } = useQuery<
		Announcement,
		BaseHttpError
	>(
		announcementKeys.detail(communityId, announcementId),
		() => {
			return api
				.getAnnouncement(announcementId)
				.then(response => response.data);
		},
		{
			enabled: !!announcementId && isLoggedIn && options.enable,
			initialData: getInitialData,
		}
	);

	return { data, isSuccess, isLoading, isError, error };
};
