import classnames from 'classnames';
import FormControl, { FormControlProps } from '@mui/material/FormControl';
import InputLabel, { InputLabelProps } from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText/FormHelperText';
import {
	forwardRef,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { FieldRenderProps } from 'react-final-form';
import ReactQuill from 'react-quill';
import { useMergeRefs } from 'hooks';
import { Box } from '../../layout';
import { editorContentStyle, containerStyle } from './styles';
import { OnErrorScrollPoint } from 'components/common/on-error-scroll-point';
import 'react-quill/dist/quill.snow.css';

interface WysiwygFieldProps
	extends Omit<FormControlProps, 'onChange' | 'onBlur' | 'onFocus'> {
	value: string;
	label?: string;
	placeholder?: string;
	LabelProps?: InputLabelProps;
	name: string;
	helperText?: string;
	onChange: (value: string) => void;
	onBlur: () => void;
	onFocus: () => void;
	meta: FieldRenderProps<string>['meta'];
	rows?: number;
}

export const WysiwygField = forwardRef<ReactQuill, WysiwygFieldProps>(
	function WysiwygField(props, ref) {
		const {
			value,
			placeholder,
			label,
			LabelProps,
			helperText,
			onFocus = () => ({}),
			onBlur = () => ({}),
			onChange,
			meta,
			rows = 2,
			...rest
		} = props;

		const quillEleRef = useRef<ReactQuill>(null);

		const innerRef = useMergeRefs([quillEleRef, ref]);

		const modules = useMemo(
			() => ({
				toolbar: {
					container: '#toolbar',
				},
			}),
			[]
		);

		const handleOnChange = useCallback((value, delta, source, editor) => {
			const rawContent = editor.getText().trim() == '' ? '' : value;
			onChange?.(rawContent);
		}, []);

		return (
			<FormControl sx={containerStyle} {...rest}>
				<OnErrorScrollPoint name={rest.name} />
				{label && <InputLabel {...LabelProps}>{label}</InputLabel>}

				<Box
					sx={editorContentStyle}
					className={classnames({
						'Mui-error': rest.error,
						'Mui-disabled': rest.disabled,
						'Mui-focused': meta.active,
					})}
					height={`${rows * 40}px`}
				>
					<ReactQuill
						ref={innerRef}
						theme='snow'
						onChange={handleOnChange}
						onFocus={onFocus}
						onBlur={onBlur}
						value={value}
						placeholder={placeholder}
						modules={modules}
					/>
				</Box>
				<Box id='toolbar'>
					<button className='ql-bold'></button>
					<button className='ql-italic'></button>
					<button className='ql-strike'></button>
					<button className='ql-underline'></button>
					<button className='ql-link'></button>
					<button className='ql-list' value='bullet'></button>
					<button className='ql-list' value='ordered'></button>
				</Box>
				{helperText && <FormHelperText>{helperText}</FormHelperText>}
			</FormControl>
		);
	}
);

export type WysiwygField = ReactQuill;
