import { Visibility } from 'core/services/api/api-client/types';
import { EventSetting, Group } from 'core/types';
import { ENGLISH_CODE, FRENCH_CODE } from 'core/constants';

export const mapCommunityGroups = (
	groups: Group[],
	visibilityGroups: string[]
) => {
	return visibilityGroups.map(groupUuid => {
		const group = groups.find(group => group.uuid === groupUuid);

		return {
			uuid: groupUuid,
			name: group?.name || '',
			description: group?.description || '',
		};
	});
};

export const mapCommunityGroupsForSetting = (
	groups: Group[],
	visibilityGroups: string[]
) => {
	return visibilityGroups.map(groupUuid => {
		const group = groups.find(group => group.uuid === groupUuid);

		return {
			id: groupUuid,
			name: group?.name || '',
			description: group?.description || '',
		};
	});
};

export const mapVisibilityWithGroups = (
	visibility: Visibility,
	visibilityGroups: string[],
	groups: Group[]
) => {
	if (visibility === 'only_admins') {
		return [];
	}

	if (visibility === 'members') {
		return [{ uuid: '', name: 'member', description: 'Members' }];
	}

	if (visibility === 'admins_members') {
		return [];
	}

	return mapCommunityGroups(groups, visibilityGroups);
};

export const mapVisibilityWithGroupsForSetting = (
	visibility: Visibility,
	visibilityGroups: string[],
	groups: Group[]
): EventSetting => {
	return {
		privacy: visibility,
		groups: mapCommunityGroupsForSetting(groups, visibilityGroups),
	};
};

export const mapLanguage = (language: string): string => {
	const languageMap = {
		english: ENGLISH_CODE,
		french: FRENCH_CODE,
	};

	type languageMapKeys = keyof typeof languageMap;

	return languageMap[language as languageMapKeys];
};

export const mapLanguageCode = (code: string): string => {
	const languageCodeMap = {
		ENGLISH_CODE: 'english',
		FRENCH_CODE: 'french',
	};

	type languageCodeMapKeys = keyof typeof languageCodeMap;

	return languageCodeMap[code as languageCodeMapKeys];
};
