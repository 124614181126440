import { Theme, ThemeOptions } from '@mui/material/styles';
import { colord } from 'colord';

export const greens = {
	1: '#BFD7B4', // Secondary 5
	2: '#9FC48F', // Secondary 4
	3: '#7FB069', // Secondary 3
	4: '#5F834E', // Primary 4
	5: '#3F5734', // Primary 3
};

export const browns = {
	1: '#d3d1d4',
	2: '#a9a3a4',
	3: '#7e7574',
	4: '#544744',
	5: '#2b1914',
};

export const grays = {
	1: '#ffffff',
	2: '#fafafa',
	3: '#f2f7f0',
	4: '#e0e0e0',
	5: '#000000',
	6: '#6c757d',
};

export const purples = {
	1: '#f2f3f8',
	2: '#c1c3de',
	3: '#a2a5cd',
	4: '#8387bd',
	5: '#41435e',
};

export const reds = {
	1: '#cf5050',
	2: '#bf3434',
	3: '#ea6157',
};

export const blues = {
	1: '#5fb0d3',
};

export const yellows = {
	1: '#c2d35f',
};

export const primaryColors = {
	primary1: browns[5],
	primary2: browns[4],
	primary3: greens[5],
	primary4: greens[4],
	primary5: purples[5],
	primary6: browns[3],
	primary7: grays[2],
	primary8: grays[1],
	primary9: grays[5],
};

export const secondaryColors = {
	secondary1: browns[2],
	secondary2: browns[1],
	secondary3: greens[3],
	secondary4: greens[2],
	secondary5: greens[1],
	secondary6: grays[3],
	secondary7: purples[4],
	secondary8: purples[3],
	secondary9: purples[2],
	secondary10: purples[1],
	secondary11: reds[1],
	secondary12: reds[2],
	secondary13: reds[3],
	secondary14: yellows[1],
	secondary15: blues[1],
	secondary16: grays[4],
};

export const textColors = {
	text: primaryColors.primary1,
	textInverted: primaryColors.primary8,
	textActive: secondaryColors.secondary3,
};

export const blacks = {
	0: browns[5],
	0.54: colord(browns[5]).alpha(0.54).toRgbString(),
	0.6: colord(browns[5]).alpha(0.6).toRgbString(),
};

export const borderColors = {
	default: grays[3],
	0.1: colord('#000000').alpha(0.1).toRgbString(),
	0.26: colord('#000000').alpha(0.26).toRgbString(),
};

export const palette = (
	theme: Theme
): Pick<
	ThemeOptions,
	| 'palette'
	| 'primaryColors'
	| 'secondaryColors'
	| 'textColors'
	| 'blacks'
	| 'borderColors'
	| 'shadows'
> => ({
	palette: {
		primary: {
			main: greens[4],
		},
		secondary: {
			main: grays[6],
		},
		error: {
			main: reds[1],
		},
		info: {
			main: blues[1],
		},
		warning: {
			main: yellows[1],
		},
		success: {
			main: greens[1],
		},
		text: {
			primary: textColors.text,
			disabled: colord(textColors.text).lighten(0.5).toHex(),
		},
		background: {
			default: grays[2],
			paper: grays[1],
		},
		buttonPrimary: {
			main: greens[4],
			dark: greens[5],
			contrastText: grays[1],
		},
	},
	primaryColors,
	secondaryColors,
	textColors,
	blacks,
	borderColors,
	// @ts-ignore
	shadows: [
		'none',
		'0 2px 4px 0 rgb(0 0 0 / 10%)',
		...theme.shadows.slice(2),
	],
});
