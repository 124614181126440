import { SxProps } from '@mui/system';
import { Theme } from 'theme';

export const weekHeaderContainerStyle: SxProps<Theme> = theme => ({
	borderBottom: '1px solid',
	borderBottomColor: theme.palette.grey[300],

	'& p': {
		fontWeight: 400,
		padding: theme.spacing(1),
	},
});
