import { WellWish, Nullable, Image } from 'core/types';
import {
	Alert,
	Box,
	Button,
	Field,
	Form,
	FormOnSubmitReturn,
	FormStack,
	ImagePickerField,
	LoadingButton,
	WysiwygField,
} from 'components/common';
import { useTranslation } from 'hooks';
import { TFunction } from 'react-i18next';
import * as Yup from 'yup';
import { useCallback, useMemo, useRef } from 'react';
import { sleep } from 'utils/delay';

export type WellWishFormSubmittedValues = {
	message: string;
	image: Nullable<Image>;
};

export type WellWishFormInitialValues = Partial<WellWishFormSubmittedValues>;

export type WellWishFormProps = {
	onSubmit?: (
		values: WellWishFormSubmittedValues
	) => FormOnSubmitReturn<WellWish>;
	onCancel?: () => void;
	submitText?: string;
	submittingText?: string;
	initialValues?: WellWishFormInitialValues;
	isSubmitting?: boolean;
	editing?: boolean;
	rows?: number;
};

export const WellWishForm = function WellWishForm(props: WellWishFormProps) {
	const {
		onSubmit,
		onCancel,
		submitText,
		submittingText,
		initialValues,
		isSubmitting,
		editing = false,
		rows = 3,
	} = props;

	const { t } = useTranslation();

	const messageFieldRef = useRef<WysiwygField>(null);

	const cachedInitialValues = useRef({
		...initialValues,
		image: initialValues?.image ? [initialValues?.image] : null,
	}).current;

	const handleOnSubmit = useCallback(
		async ({ image, message }, form) => {
			await sleep(1);
			const result = onSubmit?.({
				message,
				image: image?.[0] ?? null,
			});
			return Promise.resolve(result)
				.then(() => {
					messageFieldRef.current?.blur();
					form.restart();
				})
				.catch(error => error);
		},
		[onSubmit]
	);

	const validationSchema = useMemo(() => createValidationSchema(t), []);

	return (
		<Form
			onSubmit={handleOnSubmit}
			validationSchema={validationSchema}
			subscription={{ submitError: true }}
			initialValues={cachedInitialValues}
		>
			{({ handleSubmit, submitError }) => (
				<form onSubmit={handleSubmit} noValidate>
					<FormStack spacing={editing ? 2 : 3} responsive={false}>
						<Box>
							<Field
								ref={messageFieldRef}
								name='message'
								component={WysiwygField}
								type='text'
								rows={rows}
								fullWidth
								required
							/>
						</Box>
						<Box sx={{ mt: editing ? 2 : 'inherit' }}>
							<Field
								name='image'
								component={ImagePickerField}
								buttonSize='small'
								imageServerConfig={{
									fit: 'fill',
									w: 600,
									h: 400,
									bg: 'fff',
								}}
							/>
						</Box>
						<Box
							display='flex'
							justifyContent={editing ? 'flex-end' : 'flex-start'}
						>
							{onCancel && (
								<Button
									size='small'
									variant='text'
									onClick={onCancel}
								>
									{t('form.cancel')}
								</Button>
							)}
							<LoadingButton
								loading={isSubmitting}
								loadingIndicator={
									submittingText ||
									t('well-wish-form.posting')
								}
								size='small'
								type='submit'
							>
								{submitText || t('well-wish-form.post')}
							</LoadingButton>
						</Box>
						{submitError && (
							<Box>
								<Alert
									variant='filled'
									severity='error'
									color='error'
								>
									{submitError}
								</Alert>
							</Box>
						)}
					</FormStack>
				</form>
			)}
		</Form>
	);
};

function createValidationSchema(t: TFunction) {
	return Yup.object().shape({
		message: Yup.string().required(t('form.generic-required')),
	});
}
