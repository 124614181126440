import React from 'react';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Tooltip } from '@mui/material';
import { useCurrentIdentity } from 'core/uses-cases/authentication';
import { useImageServer, useParams, useRouter, useTranslation } from 'hooks';
import {
	Avatar,
	ButtonBase,
	Box,
	RouterLink,
	Menu,
	MenuItem,
} from '../../../common';

export const AccountMenu = () => {
	const { t } = useTranslation();
	const identity = useCurrentIdentity();
	const router = useRouter();
	const { communityId } = useParams() as { communityId: string };

	const [anchorElNav, setAnchorElNav] = React.useState<Element>();

	const open = Boolean(anchorElNav);

	const handleOpenNavMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorElNav(event.currentTarget);
	};
	const handleCloseNavMenu = () => {
		setAnchorElNav(undefined);
	};

	const identityImage = useImageServer(identity?.image, {
		w: 32,
		h: 32,
		fit: 'crop',
	});

	return (
		<Box
			justifyContent='flex-end'
			alignItems='center'
			display='flex'
			flexGrow={1}
			py={0.6}
		>
			<Tooltip title='Account Menu'>
				<ButtonBase
					onClick={handleOpenNavMenu}
					disableRipple
					aria-controls={open ? 'account-menu' : undefined}
					aria-haspopup='true'
					aria-expanded={open ? 'true' : undefined}
					sx={theme => ({
						padding: theme.spacing(1, 2),
						borderRadius: theme.spacing(0.4),
						marginLeft: theme.spacing(1),
					})}
				>
					<Avatar
						alt={`${identity?.first_name?.[0]} ${identity?.last_name?.[0]}`}
						src={identityImage?.url}
						sx={{ width: 32, height: 32, mr: 2 }}
					/>
					<ArrowBackIosNewIcon
						sx={{
							fontSize: 'small',
							transform: open
								? 'rotate(90deg)'
								: 'rotate(-90deg)',
						}}
					/>
				</ButtonBase>
			</Tooltip>
			<Menu
				id='account-menu'
				anchorEl={anchorElNav}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				keepMounted
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				open={Boolean(anchorElNav)}
				onClose={handleCloseNavMenu}
				PaperProps={{
					elevation: 0,
					sx: {
						overflow: 'visible',
						filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
						mt: 1.5,
						minWidth: 150,
					},
				}}
			>
				<MenuItem
					onClick={handleCloseNavMenu}
					component={RouterLink}
					to={router.getPathFor('account.edit')}
				>
					{t('account-menu.my-account')}
				</MenuItem>
				{communityId && (
					<MenuItem
						onClick={handleCloseNavMenu}
						component={RouterLink}
						to={router.getPathFor('community.edit-members', {
							communityId,
							identityId: identity?.uuid,
						})}
					>
						{t('account-menu.community-profile')}
					</MenuItem>
				)}
				<MenuItem
					onClick={handleCloseNavMenu}
					component={RouterLink}
					to={router.getPathFor('community.support')}
				>
					{t('account-menu.help')}
				</MenuItem>
				<MenuItem
					component={RouterLink}
					to={router.getPathFor('account.logout')}
				>
					{t('account-menu.log-out')}
				</MenuItem>
			</Menu>
		</Box>
	);
};
