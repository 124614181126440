import { isCancelledError, useMutation } from 'react-query';
import {
	albumKeys,
	albumPhotoKeys,
	communityKeys,
} from 'core/utils/query-key-factory';
import { deleteAlbumMutationKey } from 'core/utils/mutation-key-factory';
import { SetupMutationFn } from 'core/configure/types';
import { Services } from 'core/services';
import { Album, AlbumPhoto } from 'core/types';
import {
	cancelPreviousMutation,
	onDeleteMutateOptimistic,
	PaginatedRecords,
	removeRecordFromInfinityQueryCache,
} from 'core/utils/optimistic-utils';
import { BaseHttpError } from '../../services/api/errors';
import { isPostPatchAlbumMutation } from './use-patch-album';

export interface DeleteAlbumMutationInput {
	communityId: string;
	albumId: string;
}

export const useDeleteAlbum = () => {
	const mutation = useMutation<null, BaseHttpError, DeleteAlbumMutationInput>(
		deleteAlbumMutationKey
	);

	return mutation;
};

export const setupDeleteAlbum: SetupMutationFn = (
	services: Services,
	createTrackedParallelMutation,
	mutationTracker
) => {
	const { queryClient, api } = services;

	const mutation = createTrackedParallelMutation<
		null,
		BaseHttpError,
		DeleteAlbumMutationInput
	>({
		mutationFn: ({ albumId }) => {
			if (albumId.length === 22) {
				return api.deleteAlbum(albumId);
			}

			return Promise.resolve(null);
		},
		onMutate: async input => {
			const { communityId, albumId } = input;

			// Cancel the previous mutation if it exists
			await cancelPreviousMutation(queryClient, mutation => {
				return (
					mutation.state.variables?.albumId === albumId &&
					isPostPatchAlbumMutation(mutation)
				);
			});

			await queryClient.cancelQueries(albumPhotoKeys.list(albumId));

			await onDeleteMutateOptimistic<Album>(
				queryClient,
				albumId,
				albumKeys.list(communityId),
				albumKeys.detail(communityId, albumId)
			);

			const photosSnapshot = queryClient.getQueryData<
				PaginatedRecords<AlbumPhoto>
			>(albumPhotoKeys.list(albumId));

			if (photosSnapshot) {
				for (const page of photosSnapshot.pages) {
					for (const photo of page.data) {
						removeRecordFromInfinityQueryCache(
							queryClient,
							communityKeys.feed(communityId),
							photo.uuid
						);

						removeRecordFromInfinityQueryCache(
							queryClient,
							albumPhotoKeys.list(communityId),
							photo.uuid
						);
					}
				}
			}
		},
		onSettled: (data, error, { communityId, albumId }) => {
			if (!isCancelledError(error)) {
				mutationTracker.queueInvalidations(
					albumKeys.list(communityId),
					albumPhotoKeys.list(albumId),
					albumPhotoKeys.list(communityId),
					communityKeys.feed(communityId)
				);
			}
		},
		retry: 1,
	});

	queryClient.setMutationDefaults(deleteAlbumMutationKey, mutation);
};
