import classNames from 'classnames';
import { CalendarEvent } from 'components/calendar/types';
import { Box, BoxProps, ButtonBase, useMediaQuery } from 'components/common';
import { PropsWithChildren, useMemo } from 'react';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import Popover from '@mui/material/Popover';
import { useTranslation } from 'hooks';
import { SxProps } from '@mui/system';
import { EventList } from './event-list';
import { getEventTags } from './event-row';
import {
	eventBoxContainerStyle,
	mobileMonthDayButtonStyle,
	mobileEventPopupContainerStyle,
} from './styles';

export const EventsBox = (
	props: PropsWithChildren<
		BoxProps & {
			maxRenderCount?: number;
			events: CalendarEvent[];
			onEventClick: (event: CalendarEvent) => void;
		}
	>
) => {
	const {
		sx,
		children,
		events,
		className,
		maxRenderCount,
		onEventClick,
		...rest
	} = props;

	const { t } = useTranslation();

	const isSmall = useMediaQuery.down('sm');

	const mobileRowClasses = useMemo(
		() =>
			events.reduce(
				(acc, event) => {
					const tags = getEventTags(event);

					return {
						'is-occasion':
							tags['is-occasion'] || acc['is-occasion'],
						'needs-help': tags['needs-help'] || acc['needs-help'],
						'needs-met': tags['needs-met'] || acc['needs-met'],
					};
				},
				{
					'is-occasion': false,
					'needs-help': false,
					'needs-met': false,
				}
			),
		[events, isSmall]
	);

	return (
		<Box
			{...rest}
			sx={[eventBoxContainerStyle, sx] as SxProps}
			className={classNames(className, {
				...(isSmall && mobileRowClasses),
			})}
		>
			{children}
			{!isSmall && (
				<EventList
					events={events}
					onEventClick={onEventClick}
					maxRenderCount={maxRenderCount}
				/>
			)}
			{isSmall && events.length > 0 && (
				<PopupState variant='popover'>
					{popupState => (
						<>
							<ButtonBase
								disableRipple
								sx={mobileMonthDayButtonStyle}
								{...bindTrigger(popupState)}
							>
								{t('calendar-widget.tasks', {
									count: events.length,
								})}
							</ButtonBase>
							<Popover
								{...bindPopover(popupState)}
								anchorOrigin={{
									vertical: 'center',
									horizontal: 'center',
								}}
								transformOrigin={{
									vertical: 'top',
									horizontal: 'center',
								}}
							>
								<Box sx={mobileEventPopupContainerStyle}>
									<EventList
										events={events}
										onEventClick={onEventClick}
										maxRenderCount={0}
									/>
								</Box>
							</Popover>
						</>
					)}
				</PopupState>
			)}
		</Box>
	);
};
