import { Box, SelectField, SelectItem } from 'components/common';
import { useTranslation } from 'hooks';
import everythingIcon from 'assets/images/feed-everything.svg?url';
import wellWishIcon from 'assets/images/feed-well-wish.svg?url';
import announcementIcon from 'assets/images/feed-announcement.svg?url';
import smallPencil from 'assets/images/small-pencil.svg?url';
import photoIcon from 'assets/images/feed-photo.svg?url';
import { useStore } from 'store';
import { filterStyle } from './styles';

const iconMap = {
	everything: everythingIcon,
	well_wish: wellWishIcon,
	announcement: announcementIcon,
	note: smallPencil,
	album_image: photoIcon,
};

type iconMapKeys = keyof typeof iconMap;

export const FeedFilter = () => {
	const { t } = useTranslation();
	const {
		feedFilter: { filter, communitySections, setFilter },
	} = useStore();

	const onChange = (event: { target: { value: string } }) => {
		setFilter(event.target.value);
	};

	return (
		<SelectField value={filter} onChange={onChange}>
			{communitySections.map((section: string) => {
				const icon = iconMap[section as iconMapKeys];

				return (
					<SelectItem key={section} value={section}>
						<Box sx={filterStyle}>
							<img src={icon} alt={icon} />
							{t(`community-home.${section}`)}
						</Box>
					</SelectItem>
				);
			})}
		</SelectField>
	);
};
