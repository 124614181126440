import { Grid, Box, Typography } from 'components/common';
import { Fragment } from 'react';
import { DateTime } from 'luxon';
import classNames from 'classnames';
import { useCalendar } from 'components/calendar/context';
import {
	useCalendarHeaderDays,
	useEventsForDay,
} from 'components/calendar/shared';
import { calendarUtils, hasSameMonth } from '../../utils';
import {
	yearMonthContainerStyle,
	yearMonthHeaderContainerStyle,
} from './styles';

type YearMonthProps = {
	month: DateTime;
};

type YearMonthDayProps = {
	date: DateTime;
	month: DateTime;
};

const YearMonthDay = ({ date, month }: YearMonthDayProps) => {
	const { setDisplayed, formatDate } = useCalendar();

	const events = useEventsForDay(date);

	const isOccasion = events.some(event => event.category === 'occasion');

	const needsHelp = events.some(event => event.volunteersAvailable > 0);

	return (
		<Grid item xs={12 / 7}>
			<Box
				onClick={() => setDisplayed(date)}
				className={classNames({
					'year-day': true,
					'from-other-month': !hasSameMonth(date, month),
					'is-occasion': isOccasion,
					'needs-help': needsHelp,
					'needs-met': events.length > 0,
				})}
			>
				<Typography variant='caption'>
					{formatDate(date, 'd')}
				</Typography>
			</Box>
		</Grid>
	);
};

export const YearMonth = ({ month }: YearMonthProps) => {
	const { formatDate, timezone } = useCalendar();

	const headerDays = useCalendarHeaderDays(timezone);

	const dates = calendarUtils.getMonthDates(month.year, month.month);

	return (
		<>
			<Grid container spacing={0} sx={yearMonthHeaderContainerStyle}>
				<Grid item xs={12}>
					<Typography variant='body2' className='title'>
						{month.toFormat('LLLL')}
					</Typography>
				</Grid>
				<Grid container item xs={12} className='header'>
					{headerDays.map(day => (
						<Fragment key={`week-header-${day.toISO()}`}>
							<Grid item xs={12 / 7}>
								<Typography variant='caption'>
									{formatDate(day, 'EEEEE')}
								</Typography>
							</Grid>
						</Fragment>
					))}
				</Grid>
			</Grid>
			<Grid container spacing={0} sx={yearMonthContainerStyle}>
				{dates.map(week => (
					<Fragment key={`week-${week[0].toISO()}`}>
						<Grid container item xs={12} spacing={0}>
							{week.map(day => (
								<Fragment key={`year-day-${day.toISO()}`}>
									<YearMonthDay date={day} month={month} />
								</Fragment>
							))}
						</Grid>
					</Fragment>
				))}
			</Grid>
		</>
	);
};
