import { useCallback, useEffect, useRef, useState } from 'react';

export const useSafeState = <T>(
	initialState: T | (() => T)
): [T, React.Dispatch<React.SetStateAction<T>>] => {
	const [state, setState] = useState(initialState);

	const isMounted = useRef(false);

	useEffect(() => {
		isMounted.current = true;
		return () => {
			isMounted.current = false;
		};
	}, []);

	const setSafeState = useCallback(
		(newState: React.SetStateAction<T>) => {
			if (isMounted.current) {
				if (newState instanceof Function) {
					setState(newState(state));
				} else {
					setState(newState);
				}
			}
		},
		[setState]
	);

	return [state, setSafeState];
};
