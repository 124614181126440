import config from 'config';
import { useRef, useState } from 'react';
import { InfiniteData } from 'react-query';

type UseInfinityScrollPaginationHandleArgs<T> = {
	data: InfiniteData<T[]> | undefined;
	fetchNextPage: () => void;
	hasNextPage: boolean | undefined;
	stepperPage?: number;
};

export function useInfinityScrollPaginationHandler<T>({
	data,
	fetchNextPage,
	hasNextPage,
	stepperPage = config.pagination.defaultPrefetchPageSize,
}: UseInfinityScrollPaginationHandleArgs<T>) {
	const [showMoreButton, setShowMoreButton] = useState(false);

	const totalPages = (data?.pages.length || 1) - 1;
	const currentPageRef = useRef(totalPages);

	const handleOnLoad = () => {
		currentPageRef.current++;

		if (
			hasNextPage &&
			(stepperPage === 0 || currentPageRef.current % stepperPage !== 0)
		) {
			fetchNextPage();
			setShowMoreButton(false);
		} else {
			setShowMoreButton(true);
		}
	};

	return {
		handleOnLoad,
		showMoreButton,
		setShowMoreButton,
	};
}
