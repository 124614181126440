import { env } from 'utils/env';

export const microsoft = {
	auth: {
		clientId: env.APP_MICROSOFT_CLIENT_ID,
		redirectUri: `${window.location.origin}/live-callback`,
	},
	cache: {
		cacheLocation: 'sessionStorage',
		storeAuthStateInCookie: false,
	},
};

export const gmail = {
	clientId: env.APP_GMAIL_CLIENT_ID,
};
