import { Box, BoxProps, Paper } from 'components/common';
import { gridCellStyle, gridRowStyle, gridStyle } from './styles';

export const Grid = ({ children, ...rest }: BoxProps) => (
	<Box
		className='grid-wrapper'
		component={Paper}
		elevation={0}
		sx={gridStyle}
		{...rest}
	>
		<Box>{children}</Box>
	</Box>
);

export const GridRow = ({ children, ...rest }: BoxProps) => (
	<Box className='grid-row' sx={gridRowStyle} {...rest}>
		{children}
	</Box>
);

export const GridRowHeader = (props: BoxProps) => (
	<GridRow top={0} position='sticky' zIndex={2} {...props} />
);

export const GridCellHeader = (props: BoxProps) => (
	<Box
		className='grid-cell grid-cell-header'
		flexGrow={0}
		flexShrink={0}
		flexBasis={300}
		paddingY={2}
		paddingLeft={3}
		paddingRight={2}
		top={0}
		left={0}
		position='sticky'
		sx={gridCellStyle}
		zIndex={1}
		{...props}
	/>
);

export const GridCell = (props: BoxProps) => (
	<Box
		className='grid-cell'
		minWidth={150}
		maxWidth={150}
		justifyContent='center'
		paddingY={1}
		paddingX={2}
		sx={gridCellStyle}
		{...props}
	/>
);
