import { SetupMutationFn } from 'core/configure/types';
import { BaseHttpError } from 'core/services/api/errors';
import { Group, CommunityMember, Identity } from 'core/types';
import { communityKeys, permissionKeys } from 'core/utils/query-key-factory';
import { isCancelledError, Mutation, useMutation } from 'react-query';
import { manageCommunityGroupMutationKey } from 'core/utils/mutation-key-factory';
import {
	onPatchMutateOptimisticInfinityQueryCache,
	cancelPreviousMutation,
	onSuccessOptimisticInInfinityQueryCache,
	findRecordFromInfinityQueryCache,
	PaginatedRecordsSnapshot,
} from 'core/utils/optimistic-utils';

export type ManageCommunityGroupMutationInput = {
	communityId: string;
	identityId: string;
	groupId: string;
	add: boolean;
};

export const useManageCommunityGroup = () => {
	const mutation = useMutation<
		Group,
		BaseHttpError,
		ManageCommunityGroupMutationInput
	>(manageCommunityGroupMutationKey);

	return mutation;
};

type MutationContext = {
	communityId: string;
	groupId: string;
	identityId: string;
	previousGroup: Group;
	updatedGroup: Group;
	listSnapshot: PaginatedRecordsSnapshot<Group>;
};

export const setupManageCommunityGroup: SetupMutationFn = (
	services,
	createTrackedParallelMutation,
	mutationTracker
) => {
	const { queryClient, api } = services;

	const mutation = createTrackedParallelMutation<
		Group,
		BaseHttpError,
		ManageCommunityGroupMutationInput,
		MutationContext
	>({
		mutationFn: async ({ groupId, communityId, identityId, add }) => {
			return api
				.patchOpenGroup(communityId, groupId, identityId, add)
				.then(response => response.data);
		},
		onMutate: async input => {
			const { groupId, communityId, identityId, add } = input;

			const communityMembers = queryClient.getQueryData<
				CommunityMember[]
			>(communityKeys.members(communityId));

			const { listSnapshot } =
				findRecordFromInfinityQueryCache<Group>(
					queryClient,
					communityKeys.groups(communityId),
					groupId
				) ?? {};

			let previousGroup = queryClient.getQueryData<Group>(
				communityKeys.group(communityId, groupId)
			) as Group;

			if (!previousGroup) {
				listSnapshot?.pages.forEach(page => {
					const found = page.data.find(
						group => group.uuid === groupId
					);

					if (found) {
						previousGroup = found;
					}
				});
			}

			const currentMembers = previousGroup.members as Array<
				Pick<Identity, 'uuid' | 'first_name' | 'last_name' | 'email'>
			>;
			let newMembers = null;

			if (add) {
				const member = communityMembers?.find(
					member => member.identity.uuid === identityId
				);

				newMembers = [
					...currentMembers,
					{
						uuid: identityId,
						first_name: member?.identity.first_name ?? '',
						last_name: member?.identity.last_name ?? '',
						email: member?.identity.email ?? '',
					},
				];
			} else {
				newMembers = currentMembers?.filter(
					member => member.uuid !== identityId
				);
			}

			//  optimistic Group
			const updatedGroup: Group = {
				...previousGroup,
				members: newMembers,
			};

			// Cancel the previous mutation if it exists
			await cancelPreviousMutation<MutationContext>(
				queryClient,
				mutation => {
					return (
						mutation.state.context?.groupId === groupId &&
						isManageCommunityGroupMutation(mutation)
					);
				}
			);

			await onPatchMutateOptimisticInfinityQueryCache<Group>(
				queryClient,
				communityKeys.groups(communityId),
				communityKeys.group(communityId, groupId),
				updatedGroup
			);

			return {
				communityId,
				groupId,
				identityId,
				previousGroup,
				updatedGroup,
				listSnapshot,
			};
		},
		onSuccess: (data, _input, { communityId, groupId }) => {
			onSuccessOptimisticInInfinityQueryCache<Group>(
				queryClient,
				communityKeys.groups(communityId),
				communityKeys.group(communityId, groupId),
				groupId,
				data
			);

			queryClient.invalidateQueries(communityKeys.groups(communityId));
		},
		onError: (error, _input, context) => {
			if (!isCancelledError(error)) {
				if (context?.listSnapshot) {
					queryClient.setQueryData(
						communityKeys.groups(context.communityId),
						context?.listSnapshot
					);
				}

				if (context?.previousGroup) {
					queryClient.setQueryData(
						communityKeys.group(
							context.communityId,
							context.groupId
						),
						context?.previousGroup
					);
				}
			}
		},
		onSettled: (_data, error, _variables, context) => {
			if (context && !isCancelledError(error)) {
				mutationTracker.queueInvalidations(
					permissionKeys.all(context.communityId)
				);
			}
		},
		retry: 1,
	});

	queryClient.setMutationDefaults(manageCommunityGroupMutationKey, mutation);
};

export const isManageCommunityGroupMutation = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	mutation: Mutation<any, any, any, any>
) =>
	[manageCommunityGroupMutationKey].includes(
		String(mutation?.options?.mutationKey)
	);
