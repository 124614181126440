import { useServices } from 'core/services';
import { BaseHttpError } from 'core/services/api/errors';
import { AdvanceCommunityPermissions } from 'core/types';
import { communityKeys } from 'core/utils/query-key-factory';
import { useQuery } from 'react-query';
import { cloneDeepObject } from 'utils/objects';

export const useGetGlobalPermissions = (
	communityId: string,
	options?: { enabled?: boolean }
) => {
	const { enabled = true } = options || {};
	const { api, authentication } = useServices();

	const isLoggedIn = authentication.isAuthenticated();

	return useQuery<AdvanceCommunityPermissions, BaseHttpError>(
		communityKeys.globalPermissions(communityId),
		() =>
			api
				.getCommunityGlobalPermissions(communityId)
				.then(response => response.data),
		{
			enabled: enabled && !!communityId && isLoggedIn,
		}
	);
};

export const getDefaultGlobalPermissions = (): AdvanceCommunityPermissions =>
	cloneDeepObject({
		community: {
			update: [],
			delete_comments: [],
		},
		announcement: {
			create: [],
			read: [],
			update: [],
			delete: [],
		},
		album: {
			create: [],
			read: [],
			update: [],
			delete: [],
			upload_photo: [],
		},
		album_image: {
			update: [],
			delete: [],
		},
		member: {
			create: [],
			update: [],
			delete: [],
			approve: [],
			view_private: [],
		},
		task: {
			create: [],
			read: [],
			update: [],
			delete: [],
			assign: [],
		},
		well_wish: {
			create: [],
			delete: [],
		},
		group: {
			create: [],
			read: [],
		},
		note: {
			create: [],
			read: [],
			update: [],
			delete: [],
		},
	});
