import { SetupMutationFn } from 'core/configure/types';
import { BaseHttpError } from 'core/services/api/errors';
import {
	Announcement,
	Identity,
	Community,
	Image,
	Nullable,
	CommunityMember,
} from 'core/types';
import { announcementKeys, communityKeys } from 'core/utils/query-key-factory';
import { isCancelledError, Mutation, useMutation } from 'react-query';
import {
	patchAnnouncementMutationKey,
	postAnnouncementMutationKey,
} from 'core/utils/mutation-key-factory';
import {
	cancelPreviousMutation,
	onPatchMutateOptimisticInfinityQueryCache,
	onPostMutateOptimisticInfinityQueryCache,
	onSuccessOptimisticInInfinityQueryCache,
	PaginatedRecordsSnapshot,
	removeRecordFromInfinityQueryCache,
	updatePartiallyRecordFromInfinityQueryCache,
} from 'core/utils/optimistic-utils';
import { mapVisibilityWithGroups } from 'core/utils/mapper';
import { AnnouncementInput } from '../../services/api/api-client/types';
import {
	findAnnouncementInfo,
	getAnnouncementListQueryKey,
	getAnnouncementsListKeys,
} from './utils';

export interface PatchAnnouncementMutationInput
	extends Omit<AnnouncementInput, 'image'> {
	whenToPost: string;
	communityId: string;
	announcementId: string;
	image: Nullable<Image>;
	createdDt: Nullable<string>;
}

export const usePatchAnnouncement = () => {
	const patchMutation = useMutation<
		Announcement,
		BaseHttpError,
		PatchAnnouncementMutationInput
	>(patchAnnouncementMutationKey);

	return patchMutation;
};

type MutationContext = {
	communityId: string;
	announcementId: string;
	currentAnnouncementInfo: {
		listKey: ReturnType<typeof getAnnouncementsListKeys>[number];
		listSnapshot: PaginatedRecordsSnapshot<Announcement>;
	} | null;
	updatedAnnouncement: Announcement;
};

export const setupPatchAnnouncement: SetupMutationFn = (
	services,
	createTrackedParallelMutation,
	mutationTracker
) => {
	const { queryClient, api, uploader } = services;

	const mutation = createTrackedParallelMutation<
		Announcement,
		BaseHttpError,
		PatchAnnouncementMutationInput,
		MutationContext
	>({
		mutationFn: async ({
			announcementId,
			communityId,
			image,
			createdDt,
			...input
		}) => {
			const remoteImage = await uploader.uploadImage(image);

			// If created dt is given, we need to set the call to be an update
			const actionCall = createdDt
				? api.patchAnnouncement.bind(api, announcementId)
				: api.postAnnouncement.bind(api, communityId);

			return actionCall({
				...input,
				image: remoteImage?.uuid ?? null,
			}).then(response => response.data);
		},
		onMutate: async input => {
			const {
				announcementId,
				communityId,
				publishedDt,
				whenToPost,
				allowDiscussion,
				image,
				message,
				title,
				visibility,
				visibilityGroups,
				author,
			} = input;

			// Cancel the previous mutation if it exists
			await cancelPreviousMutation<MutationContext>(
				queryClient,
				mutation =>
					isPostPatchAnnouncementMutation(mutation) &&
					mutation.state.context?.announcementId === announcementId
			);

			const community = queryClient.getQueryData<Community>(
				communityKeys.detail(communityId)
			);

			const communityMembers = queryClient.getQueryData<
				CommunityMember[]
			>(communityKeys.members(communityId));

			const announcementAuthor = communityMembers?.find(
				communityMember => communityMember.identity.uuid === author
			);

			const currentAnnouncementInfo = findAnnouncementInfo(
				queryClient,
				communityId,
				announcementId
			);

			const currentAnnouncement =
				currentAnnouncementInfo?.record as Announcement;

			//  optimistic announcement
			const updatedAnnouncement: Announcement = {
				...currentAnnouncement,
				title,
				message,
				image,
				uuid: announcementId,
				author: announcementAuthor?.identity as Identity,
				discussion_state: allowDiscussion ? 'enabled' : 'disabled',
				published_dt: publishedDt ?? null,
				who_can_see: mapVisibilityWithGroups(
					visibility,
					visibilityGroups,
					community?.groups.all ?? []
				),
			};

			if (currentAnnouncementInfo) {
				const { listKey: currentKey, record } = currentAnnouncementInfo;

				const nextKey = getAnnouncementListQueryKey({
					communityId,
					whenToPost,
					publishedDt,
				});

				if (currentKey[4] !== nextKey[4]) {
					// Was move from one list to another
					onPostMutateOptimisticInfinityQueryCache<Announcement>(
						queryClient,
						nextKey,
						announcementKeys.detail(communityId, announcementId),
						updatedAnnouncement,
						[
							announcementKeys.lists(communityId),
							announcementKeys.detail(
								communityId,
								announcementId
							),
						]
					);

					// Remove from old list
					removeRecordFromInfinityQueryCache<Announcement>(
						queryClient,
						currentKey,
						record.uuid
					);
				} else {
					// Update the existing announcement
					await onPatchMutateOptimisticInfinityQueryCache<Announcement>(
						queryClient,
						currentKey,
						announcementKeys.detail(communityId, announcementId),
						updatedAnnouncement
					);
				}
			}

			updatePartiallyRecordFromInfinityQueryCache(
				queryClient,
				communityKeys.feed(communityId),
				updatedAnnouncement,
				['title', 'message', 'image', 'video']
			);

			return {
				communityId,
				announcementId,
				currentAnnouncementInfo,
				updatedAnnouncement,
			};
		},
		onSuccess: (
			result,
			_input,
			{ communityId, currentAnnouncementInfo, announcementId }
		) => {
			if (currentAnnouncementInfo) {
				onSuccessOptimisticInInfinityQueryCache<Announcement>(
					queryClient,
					currentAnnouncementInfo.listKey,
					announcementKeys.detail(communityId, announcementId),
					announcementId,
					result
				);
			}
		},
		onError: (error, input, context) => {
			if (context?.currentAnnouncementInfo && !isCancelledError(error)) {
				queryClient.setQueryData(
					context.currentAnnouncementInfo.listKey,
					context?.currentAnnouncementInfo.listSnapshot
				);
			}
		},
		onSettled: (data, error, variables, context) => {
			if (context && !isCancelledError(error)) {
				mutationTracker.queueInvalidations(
					announcementKeys.lists(context.communityId),
					announcementKeys.detail(
						variables.communityId,
						context.announcementId
					),
					communityKeys.feed(context.communityId)
				);
			}
		},
		retry: 1,
	});

	queryClient.setMutationDefaults(patchAnnouncementMutationKey, mutation);
};

export const isPostPatchAnnouncementMutation = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	mutation: Mutation<any, any, any, any>
) =>
	[patchAnnouncementMutationKey, postAnnouncementMutationKey].includes(
		String(mutation?.options?.mutationKey)
	);
