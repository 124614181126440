import { Backdrop, Box, PageHeading, Paper } from 'components/common';
import {
	useTranslation,
	useSubmitHandlerForPausedMutation,
	useServices,
	useRouter,
	useParams,
} from 'hooks';
import {
	AlbumForm,
	useAlbumFormData,
	useDeleteAlbumHandler,
} from 'components/albums';
import { useGetAlbum, usePatchAlbum } from 'core/uses-cases/albums';
import { AlbumInput } from 'core/services/api/api-client/types';

export const EditAlbum = () => {
	const { t } = useTranslation();
	const { communityId, albumId } = useParams() as {
		communityId: string;
		albumId: string;
	};
	const { notification } = useServices();
	const router = useRouter();
	const albumQuery = useGetAlbum(communityId, albumId);
	const { mutateAsync, isPaused } = usePatchAlbum();

	const handleOnSubmit = useSubmitHandlerForPausedMutation<AlbumInput>(
		isPaused,
		values =>
			mutateAsync({
				...values,
				albumId,
				communityId,
			}),
		{
			onSuccess: () => {
				notification.show('success', t('albums.edit.success'));

				router.navigate('album.view', {
					communityId,
					albumId,
				});
			},
		},
		[communityId, albumId]
	);

	const { initialValues, groups } = useAlbumFormData(albumQuery?.data);

	const { open: onDeleteHandler, isDeletingPhoto } = useDeleteAlbumHandler({
		onConfirm: () =>
			router.navigate('community.albums', {
				communityId,
			}),
	});

	const handleOnDelete = () => {
		onDeleteHandler({ communityId, albumId });
	};

	return (
		<Box>
			<PageHeading title={t('albums.view.edit')} />
			<Box component={Paper} elevation={0} mt={6} px={5} py={5}>
				<AlbumForm
					edit
					onSubmit={handleOnSubmit}
					groups={groups}
					initialValues={initialValues}
					onDelete={handleOnDelete}
					deleteText={t('albums.delete-album')}
					submitText={t('common.save-changes')}
					submittingText={t('common.saving-changes')}
				/>
			</Box>
			<Backdrop open={isDeletingPhoto} />
		</Box>
	);
};
