import { EventScopeValues } from 'core/constants';
import { RawEvent } from 'core/types';
import {
	eventListWithParams,
	eventWithParams,
} from 'core/utils/request-with-params';
import { BaseHttpClient } from '../base-http-client';
import {
	CalendarExportTokenResponse,
	CalendarResponse,
	EventResponse,
	EventsResponse,
} from '../response-types';
import { transformEvent } from '../transformers';
import { EventInput, EventsParamArgs, PatchEventInput } from './types';

export const createCalendarApi = (http: BaseHttpClient) => ({
	/**
	 * Get calendar by id
	 *
	 * @param {string} calendarId
	 * @param {{
	 * 			signal?: AbortSignal;
	 * 		}} [options={}]
	 * @return {*}
	 */
	getCalendar(
		calendarId: string,
		options: {
			signal?: AbortSignal;
		} = {}
	) {
		const { signal } = options;
		return http
			.get<CalendarResponse>(`calendar/${calendarId}`, {}, { signal })
			.then(response => response.data);
	},

	/**
	 * Get events for a community/calendar
	 *
	 * @param {string} calendarId
	 * @param {{
	 * 			params?: EventsParamArgs;
	 * 			signal?: AbortSignal;
	 * 		}} [options={}]
	 * @return {*}
	 */
	getEvents(
		calendarId: string,
		options: {
			params?: EventsParamArgs;
			signal?: AbortSignal;
		} = {}
	) {
		const { params, signal } = options;
		return http
			.get<EventsResponse<RawEvent>>(
				`calendar/${calendarId}/event`,
				{
					...params,
					members: params?.members?.join(',') ?? undefined,
					with: eventListWithParams,
					sort_direction: 'ASC',
				},
				{
					signal,
				}
			)
			.then(response => ({
				data: response.data.data.map(transformEvent),
				pagination: response.data.pagination,
			}));
	},

	/**
	 * Creates events for a community/calendar
	 *
	 * @param {string} calendarId
	 * @param {EventInput} {
	 * 			startTimes,
	 * 			endTimes,
	 * 			volunteersNeeded,
	 * 			visibility,
	 * 			visibilityGroups,
	 * 			volunteer,
	 * 			volunteerGroups,
	 * 			...rest
	 * 		}
	 * @return {*}
	 */
	postEvent(
		calendarId: string,
		{
			startTimes,
			endTimes,
			volunteersNeeded,
			visibility,
			visibilityGroups,
			volunteer,
			volunteerGroups,
			...rest
		}: EventInput
	) {
		return http
			.post<EventsResponse<RawEvent>>(
				`calendar/${calendarId}/event`,
				{
					start_times: startTimes,
					end_times: endTimes,
					volunteers_needed: volunteersNeeded,
					visibility: visibility,
					visibility_groups: visibilityGroups,
					volunteer: volunteer,
					volunteer_groups: volunteerGroups,
					...rest,
				},
				{
					params: {
						with: eventListWithParams,
					},
				}
			)
			.then(response => ({
				data: response.data.data.map(transformEvent),
				pagination: response.data.pagination,
			}));
	},

	/**
	 * Get an event by id
	 *
	 * @param {string} calendarId
	 * @param {string} eventId
	 * @param {{
	 * 			signal?: AbortSignal;
	 * 		}} [options={}]
	 * @return {*}
	 */
	getEvent(
		calendarId: string,
		eventId: string,
		options: {
			signal?: AbortSignal;
		} = {}
	) {
		const { signal } = options;
		return http
			.get<EventResponse<RawEvent>>(
				`calendar/${calendarId}/event/${eventId}`,
				{
					with: eventWithParams,
				},
				{
					signal,
				}
			)
			.then(response => ({
				data: transformEvent(response.data.data),
			}));
	},

	/**
	 * It deletes an event from a calendar
	 * @param {string} calendarId - The ID of the calendar you want to delete the event from.
	 * @param {string} eventId - The ID of the event to delete.
	 * @returns null
	 */
	deleteEvent(calendarId: string, eventId: string, scope: EventScopeValues) {
		return http
			.delete<null>(`calendar/${calendarId}/event/${eventId}`, {
				params: {
					scope,
				},
			})
			.then(() => null);
	},

	/**
	 *  Updates an event
	 *
	 * @param {string} calendarId - The ID of the calendar you want to update the event on.
	 * @param {string} eventId - The ID of the event you want to update.
	 * @param {PatchEventInput} input - The input object that will be sent to the server.
	 * @returns The response from the server.
	 */
	patchEvent(calendarId: string, eventId: string, input: PatchEventInput) {
		return http
			.patch<EventResponse<RawEvent>>(
				`calendar/${calendarId}/event/${eventId}`,
				{ ...input },
				{
					params: {
						with: eventWithParams,
					},
				}
			)
			.then(response => ({
				data: transformEvent(response.data.data),
			}));
	},

	/**
	 * Adds a volunteer to an event
	 *
	 * @param {string} eventId
	 * @param {string} identityId
	 * @return {*}
	 */
	postIdentityVolunteer(eventId: string, identityId: string) {
		return http
			.post<EventResponse<RawEvent>>(
				`identity/${identityId}/event/${eventId}`,
				{},
				{
					params: {
						with: eventWithParams,
					},
				}
			)
			.then(response => ({
				data: transformEvent(response.data.data),
			}));
	},

	/**
	 *  Deleting the volunteer from the event.
	 *
	 * @param {string} eventId
	 * @param {string} identityId
	 * @return {*}
	 */
	deleteIdentityVolunteer(eventId: string, identityId: string) {
		return http
			.delete<null>(`identity/${identityId}/event/${eventId}`)
			.then(() => null);
	},

	/**
	 * Gets a token for exporting events
	 *
	 * @param {string} calendarId
	 * @return {*}
	 */
	getExportToken(calendarId: string) {
		return http
			.get<CalendarExportTokenResponse>(`calendar/${calendarId}/export`)
			.then(response => response.data);
	},
});
