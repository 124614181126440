import Cookies, { CookieSetOptions } from 'universal-cookie';

export interface CookieService {
	set(name: string, value: string, options?: CookieSetOptions): void;
	get(name: string): string;
	getAll(): unknown;
	remove(name: string, options?: CookieSetOptions): void;
}

export const Cookie = (): CookieService => {
	const cookies = new Cookies();

	return {
		set: (name, value, options = {}) => {
			cookies.set(name, value, { path: '/', ...options });
		},
		get: name => {
			return cookies.get(name);
		},
		getAll: () => cookies.getAll(),
		remove: (name, options = {}) =>
			cookies.remove(name, {
				path: '/',
				...options,
			}),
	};
};
