import { useCallback, useMemo } from 'react';
import { useTranslation } from 'hooks';
import { sleep } from 'utils/delay';
import {
	Box,
	Field,
	Form,
	FormOnSubmitReturn,
	LoadingButton,
	Paper,
	TextField,
} from 'components/common';
import { TFunction } from 'i18next';
import * as Yup from 'yup';

export type ShutdownCommunitySubmittedValues = {
	reason: string;
};

export type ShutdownCommunityProps = {
	onSubmit?: (
		values: ShutdownCommunitySubmittedValues
	) => FormOnSubmitReturn<null>;
	submitText?: string;
	submittingText?: string;
};

export const ShutdownForm = (props: ShutdownCommunityProps) => {
	const { onSubmit, submitText, submittingText } = props;
	const { t } = useTranslation();

	const handleOnSubmit = useCallback(
		async values => {
			await sleep(1);
			const result = onSubmit?.(values);
			return Promise.resolve(result).catch(error => error);
		},
		[onSubmit]
	);

	const validationSchema = useMemo(() => createValidationSchema(t), []);

	return (
		<Form onSubmit={handleOnSubmit} validationSchema={validationSchema}>
			{({ handleSubmit, submitting }) => (
				<form onSubmit={handleSubmit} noValidate>
					<Box
						component={Paper}
						elevation={0}
						mt={5}
						mb={3}
						px={5}
						py={5}
						sx={{
							width: {
								xs: 4 / 4,
								md: 3 / 4,
							},
						}}
					>
						<Field
							label={t('shutdown-community.explain-shutdown')}
							name='reason'
							component={TextField}
							type='text'
							multiline
							rows={7}
							fullWidth
							sx={{ marginTop: 1 }}
							required
						/>
						<LoadingButton
							sx={{ alignSelf: 'center', marginTop: 5 }}
							loading={submitting}
							loadingIndicator={
								submittingText ||
								t('shutdown-community.shutting-down-community')
							}
							size='small'
							type='submit'
						>
							{submitText ||
								t('shutdown-community.shutdown-community')}
						</LoadingButton>
					</Box>
				</form>
			)}
		</Form>
	);
};

function createValidationSchema(t: TFunction) {
	return Yup.object().shape({
		reason: Yup.string()
			.trim()
			.required(
				t('form.required', {
					name: t('shutdown-community.explanation'),
				})
			),
	});
}
