import {
	PageHeading,
	Typography,
	Grid,
	Box,
	Paper,
	Stack,
	Form,
	LoadingButton,
	CircularProgress,
} from 'components';
import { useValidateJwt } from 'core/uses-cases/authentication/use-validate-jwt';
import { useRouter, useSearchParams } from 'hooks';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'navigation';
import { useDeleteAccount } from 'core/uses-cases/authentication/use-delete-account';
import { useCurrentIdentity } from 'core/uses-cases/authentication';

export const ConfirmDeleteAccount = () => {
	const { t } = useTranslation();
	const [queryParams] = useSearchParams();
	const identity = useCurrentIdentity();
	const router = useRouter();

	const tokenId = queryParams.get('t') || '';

	const { isError, data, isLoading } = useValidateJwt(
		tokenId,
		'delete-account'
	);

	const { doDeletionAccountAsync } = useDeleteAccount(
		data?.data?.jwt?.sub || ''
	);

	if (!tokenId) {
		return <Navigate to={router.getPathFor('root')} />;
	}

	if (isLoading) {
		return <CircularProgress />;
	}

	const handleOnSubmit = () => {
		return doDeletionAccountAsync(undefined, {
			onSuccess: () => {
				router.navigate('root');
			},
		});
	};

	return (
		<Box>
			<PageHeading title={t('confirm-delete-account.title')}>
				<Typography variant='body2' mb={1}>
					{t('confirm-delete-account.name', {
						name: `${identity?.first_name} ${identity?.last_name}`,
					})}
				</Typography>
				<Typography variant='body2' mb={1}>
					{t('confirm-delete-account.email', {
						email: identity?.email,
					})}
				</Typography>
				<Typography variant='body2' mb={1}>
					{t('confirm-delete-account.description')}
				</Typography>
			</PageHeading>
			{isError ? (
				<Typography variant='body2' color='error'>
					{t('confirm-delete-account.validation-problem-occurred')}
				</Typography>
			) : (
				<Grid container justifyContent='center'>
					<Grid item md={4} xl={4} sm={8} xs={12}>
						<Box
							component={Paper}
							px={4.5}
							py={3}
							elevation={0}
							display='flex'
							flexDirection='column'
						>
							<Form
								onSubmit={handleOnSubmit}
								subscription={{ submitting: true }}
							>
								{({ handleSubmit, submitting }) => (
									<form onSubmit={handleSubmit} noValidate>
										<Stack
											spacing={3}
											alignContent='center'
										>
											<LoadingButton
												loading={submitting}
												loadingIndicator={t(
													'confirm-delete-account.deleting-account'
												)}
												type='submit'
												size='small'
											>
												{t(
													'confirm-delete-account.delete-account'
												)}
											</LoadingButton>
										</Stack>
									</form>
								)}
							</Form>
						</Box>
					</Grid>
				</Grid>
			)}
		</Box>
	);
};
