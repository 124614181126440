import { useVisibilityFormData } from 'components/community/hooks';
import { Group } from 'core/types';
import { useCommunityMembers } from 'core/uses-cases/member';
import { useMemo } from 'react';
import { GroupFormSubmittedValues } from './form';

export const useGroupFormData = (communityId: string, record?: Group) => {
	const {
		visibilityData: {
			visibility,
			visibilityGroups,
			management,
			managementGroups,
		},
		groups,
	} = useVisibilityFormData<GroupFormSubmittedValues>(record);

	const members = useCommunityMembers(communityId);
	const identities = members.map(({ identity }) => identity);

	const data = useMemo(() => {
		if (!record)
			return {
				members: [],
				openEnrollment: false,
				permissions: [],
			} as unknown as GroupFormSubmittedValues;

		return {
			name: record.description,
			members: (record.members ?? []).map(member => member.uuid),
			openEnrollment: record.has_open_enrollment,
			permissions: record.permissions.map(
				({ entity_uuid, name }) => `${entity_uuid}:${name}`
			),
		} as unknown as GroupFormSubmittedValues;
	}, [record]);

	return {
		identities,
		groups: groups.filter(group => group.uuid !== record?.uuid),
		initialValues: {
			...data,
			visibility,
			visibilityGroups,
			management,
			managementGroups,
		},
	};
};
