import { ButtonBase } from '@mui/material';
import { Box, Icon } from 'components/common';
import { useTranslation } from 'react-i18next';

type ShowMoreLessButtonProps = {
	onChange: () => void;
	value: boolean;
};

export const ShowMoreLessHoursButton = ({
	onChange,
	value,
}: ShowMoreLessButtonProps) => {
	const { t } = useTranslation();

	return (
		<Box
			bgcolor={theme => theme.secondaryColors.secondary5}
			display='flex'
			paddingY={1}
			paddingX={2}
		>
			<ButtonBase
				onClick={() => onChange()}
				disableRipple
				sx={{ flex: 1, alignItems: 'center' }}
			>
				{value
					? t('calendar-widget.show-more-times')
					: t('calendar-widget.show-less-times')}
				{value ? (
					<Icon name='arrow_drop_down' />
				) : (
					<Icon name='arrow_drop_up' />
				)}
			</ButtonBase>
		</Box>
	);
};
