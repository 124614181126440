import Stack from '@mui/material/Stack';
import { useCallback } from 'react';
import { useStore } from 'store';
import { Alert } from './alert';
import { Box } from '../layout/box';

export const AlertStack = () => {
	const {
		alert: { stack, dismissAlert },
	} = useStore();

	const handleOnClose = useCallback((id: string) => dismissAlert(id), []);

	return (
		<Box
			position='fixed'
			top='20px'
			left={0}
			right={0}
			px={['5%', '15%']}
			zIndex={9}
			data-test-id='alert-stack'
		>
			<Stack spacing={2}>
				{stack.map(alert => (
					<Alert
						key={alert.id}
						severity={alert.level}
						onClose={handleOnClose.bind(null, alert.id)}
						variant='filled'
						timeout={alert?.timeout}
					>
						{alert.message}
					</Alert>
				))}
			</Stack>
		</Box>
	);
};
