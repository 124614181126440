import { EditCommunityFormInitialValues } from 'components/community/edit';
import { useCurrentCommunity } from 'components';
import { useVisibilityFormData } from 'components/community/hooks';
import { useMemo } from 'react';
import { useTranslation } from 'hooks';

export const useCommunityFormData = () => {
	const community = useCurrentCommunity();
	const { groups } = useVisibilityFormData();
	const { t } = useTranslation();

	const initialValues = useMemo(
		() =>
			({
				image: community?.image?.uuid ? community?.image : null,
				name: community?.name ?? '',
				searchEnabled: community?.public_listing ?? false,
				allowMemberInvites: community?.members_can_invite ?? false,
				allowMemberImageUploads: community?.members_can_upload ?? false,
				slug: community?.slug ?? '',
				purpose: community?.purpose.name ?? '',
				zipCode: community?.zip_code ?? '',
				description: community?.description ?? '',
				contactGroup: community?.contact_group_uuid ?? '',
				availableSections:
					[
						...(community?.has_announcements
							? ['announcements']
							: []),
						...(community?.has_documents ? ['documents'] : []),
						...(community?.has_well_wishes ? ['well_wishes'] : []),
						...(community?.has_photos ? ['photos'] : []),
					] ?? [],
			} as EditCommunityFormInitialValues),
		[community]
	);

	const purposes = useMemo(
		() => [
			{
				value: 'caregiving',
				label: t('purposes.caregiving'),
			},
			{
				value: 'eldercare',
				label: t('purposes.eldercare'),
			},
			{
				value: 'military',
				label: t('purposes.military'),
			},
			{
				value: 'volunteering',
				label: t('purposes.volunteering'),
			},
			{
				value: 'parenting',
				label: t('purposes.parenting'),
			},
			{
				value: 'schools',
				label: t('purposes.schools'),
			},
			{
				value: 'religious',
				label: t('purposes.religious'),
			},
		],
		[]
	);

	const contactList = useMemo(() => {
		const groupsList = groups.map(({ uuid, description, members }) => ({
			value: uuid,
			label: description,
			size: members?.length ?? 0,
		}));

		groupsList.unshift(
			{ value: 'owner', label: t('edit-community.owner'), size: 1 },
			{
				value: 'admin',
				label: t('edit-community.site-admins'),
				size:
					community?.groups.all.find(
						group => group.name === 'site_admin'
					)?.members?.length ?? 0,
			}
		);

		return groupsList;
	}, [groups]);

	const availableSections = useMemo(
		() => [
			{
				value: 'announcements',
				label: t('edit-community.announcements'),
			},
			{
				value: 'documents',
				label: t('edit-community.notes'),
			},
			{
				value: 'well_wishes',
				label: t('edit-community.well-wishes'),
			},
			{
				value: 'photos',
				label: t('edit-community.photos'),
			},
		],
		[]
	);

	return {
		initialValues,
		purposes,
		contactList,
		availableSections,
		owner: community?.owner,
	};
};
