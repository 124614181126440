import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl, { FormControlProps } from '@mui/material/FormControl';
import InputLabel, { InputLabelProps } from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText/FormHelperText';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import { useCallback } from 'react';
import FormGroup from '@mui/material/FormGroup';
import classnames from 'classnames';

interface CheckboxFieldProps extends FormControlProps {
	value: any;
	inputLabel?: string;
	label?: string;
	disabled?: boolean;
	LabelProps?: InputLabelProps;
	CheckBoxProps?: CheckboxProps;
	helperText?: string;
	onChange: (value: any) => void;
}

export const CheckboxField = (props: CheckboxFieldProps) => {
	const {
		value,
		inputLabel,
		label,
		disabled,
		CheckBoxProps,
		LabelProps,
		helperText,
		onChange,
		...rest
	} = props;

	const handleChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			onChange?.(event.target.checked);
		},
		[onChange]
	);

	return (
		<FormControl {...rest} error={!!rest.error}>
			{inputLabel && (
				<InputLabel {...LabelProps}>{inputLabel}</InputLabel>
			)}
			<FormGroup
				className={classnames({
					'Mui-error': rest.error,
				})}
			>
				<FormControlLabel
					control={
						<Checkbox
							{...CheckBoxProps}
							checked={Boolean(value)}
							onChange={handleChange}
						/>
					}
					label={label || ''}
					disabled={disabled}
					componentsProps={{
						typography: {
							variant: 'body2',
						},
					}}
				/>
			</FormGroup>
			{helperText && <FormHelperText>{helperText}</FormHelperText>}
		</FormControl>
	);
};

export { Checkbox };

export type { CheckboxProps };
