import { AlertLevel } from 'core/services/types';
import { proxy } from 'valtio';

type Alert = {
	id: string;
	message: string;
	level: AlertLevel;
	timeout?: number;
};

export interface AlertState {
	stack: Alert[];
	showAlert: (alert: Alert) => void;
	dismissAlert: (id: string) => void;
}

export const alertState = proxy<AlertState>({
	stack: [],
	showAlert(alert: Alert): void {
		alertState.stack.push(alert);
	},
	dismissAlert(id: string): void {
		const index = alertState.stack.findIndex(a => a.id === id);

		if (index !== -1) {
			alertState.stack.splice(index, 1);
		}
	},
});
