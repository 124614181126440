import {
	Box,
	CircularProgress,
	Grid,
	Typography,
	PageHeading,
	Paper,
} from 'components';
import {
	JoinCommunityCard,
	RequestToJoinForm,
	RequestToJoinFormSubmittedValues,
} from 'components/community/request-to-join';
import { HttpClientError, DUPLICATED_IDENTITY } from 'core/services/api/errors';
import { useCurrentIdentity, useLogin } from 'core/uses-cases/authentication';
import { useGetPublicCommunity } from 'core/uses-cases/community';
import { usePostIdentity } from 'core/uses-cases/identity';
import { usePostRequestToJoin } from 'core/uses-cases/member';
import { useRouter, useTranslation } from 'hooks';
import { useCallback, useMemo } from 'react';
import { Navigate, useParams, useSearchParams } from 'react-router-dom';
import { useStore } from 'store';

export const RequestToJoin = () => {
	const { communityId } = useParams() as { communityId: string };
	const { data, isLoading } = useGetPublicCommunity(communityId);
	const { t } = useTranslation();
	const router = useRouter();
	const identityMutation = usePostIdentity();
	const loginMutation = useLogin();
	const requestToJoinMutation = usePostRequestToJoin();
	const [searchParams] = useSearchParams();
	const identity = useCurrentIdentity();

	let email = '';
	let hasAccount = false;

	const {
		auth: { isLoggedIn },
	} = useStore();

	if (isLoggedIn) {
		email = identity?.email ?? '';
		hasAccount = true;
	} else {
		email = searchParams.get('email') ?? '';
		hasAccount = searchParams.get('hasAccount') === 'true' ?? false;
	}

	const initialValues = useMemo(
		() => ({
			email: email ?? '',
		}),
		[email]
	);

	const onBack = useCallback(() => {
		router.navigate('request.select-email', {
			communityId,
		});
	}, []);

	const handleOnSubmit = useCallback(
		async (values: RequestToJoinFormSubmittedValues) => {
			try {
				const { notes, ...identityInfo } = values;

				if (!isLoggedIn) {
					if (!hasAccount) {
						await identityMutation.mutateAsync(identityInfo);
					}

					const { password, email } = identityInfo;

					await loginMutation.doLoginAsync({
						username: email,
						password,
					});
				}

				await requestToJoinMutation.mutateAsync(
					{
						communityId,
						notes,
					},
					{
						onSuccess: data => {
							if (data['is_accepted'] === '1') {
								router.navigate(
									'community.home',
									{
										communityId,
									},
									{},
									{ replace: true }
								);
							} else {
								router.navigate(
									'request.success',
									{
										communityId,
									},
									{},
									{ replace: true }
								);
							}
						},
					}
				);
			} catch (error) {
				// This doesn't run. Something higher up is catch the error and showing Authorized request
				// Will check again after error handler pass. May not need
				if (error instanceof HttpClientError) {
					if (error.errorCode === DUPLICATED_IDENTITY) {
						throw { email: t('form.email-already-exists') };
					}
				}
			}
		},
		[isLoggedIn, communityId]
	);

	if (email === '') {
		return (
			<Navigate
				to={router.getPathFor('request.select-email', {
					communityId,
				})}
				replace
			/>
		);
	}

	if (isLoading || !data) {
		return (
			<Box display='flex' justifyContent='center' mt={4}>
				<CircularProgress message={t('common.loading')} />
			</Box>
		);
	}

	return (
		<>
			<PageHeading title={data?.name}>
				<Grid container>
					<Grid item xs={12} md={6}>
						<Typography variant='body2'>
							{t('select-email.private-message')}
						</Typography>
					</Grid>
				</Grid>
			</PageHeading>
			<Grid container spacing={6} mt={0}>
				<Grid item xs={12} md={4}>
					<JoinCommunityCard community={data} />
				</Grid>
				<Grid item xs={12} md={8}>
					<Typography variant='h4'>
						{t('select-email.join-community')}
					</Typography>
					<Typography variant='body2'>
						{t('request-to-join.need-approved')}
					</Typography>
					<Box component={Paper} elevation={0} mt={6} px={5} py={5}>
						<RequestToJoinForm
							onSubmit={handleOnSubmit}
							isLoggedIn={isLoggedIn}
							hasAccount={hasAccount}
							initialValues={initialValues}
							onBack={onBack}
							submitText={t('request-to-join.request-to-join')}
							submittingText={t('request-to-join.requesting')}
						/>
					</Box>
				</Grid>
			</Grid>
		</>
	);
};
