import { Breakpoint } from '@mui/material';
import { SxProps } from '@mui/system';
import { Theme } from 'theme';

export const cellStyle: (lastCell?: {
	[key in Breakpoint]: boolean;
}) => SxProps<Theme> = lastCell => theme => {
	const borderRight = `1px solid ${theme.secondaryColors.secondary16}`;

	return {
		flex: 1,
		display: 'flex',
		alignItems: 'center',
		borderRight: {
			xs: lastCell?.xs ? 'none' : borderRight,
			sm: lastCell?.sm ? 'none' : borderRight,
			md: lastCell?.md ? 'none' : borderRight,
			lg: lastCell?.lg ? 'none' : borderRight,
			xl: lastCell?.xl ? 'none' : borderRight,
		},
		'& p, span': {
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
		},
		'& .MuiFormControlLabel-root .MuiFormControl-root': {
			flexShrink: 0,
		},
	};
};

export const gridCellStyle: (breakpoints?: {
	[key in Breakpoint]: boolean;
}) => SxProps<Theme> = breakpoints => ({
	display: {
		xs: breakpoints?.xs ? 'flex' : 'none',
		md: breakpoints?.md ? 'flex' : 'none',
		sm: breakpoints?.sm ? 'flex' : 'none',
		lg: breakpoints?.lg ? 'flex' : 'none',
		xl: breakpoints?.xl ? 'flex' : 'none',
	},
});
