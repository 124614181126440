import { useCallback } from 'react';
import { useRouter, useServices, useTranslation, useParams } from 'hooks';
import { usePatchCommunity } from 'core/uses-cases/community';
import { useCommunityFormData } from 'components/community/hooks';
import {
	Box,
	Paper,
	RouterLink,
	PageHeading,
	PickerFile,
} from 'components/common';
import { Trans } from 'react-i18next';
import {
	EditCommunityForm,
	EditCommunityFormSubmittedValues,
} from 'components/community/edit/form';
import { useCurrentIdentity } from 'core/uses-cases/authentication';

export const EditCommunity = () => {
	const { t } = useTranslation();
	const { notification } = useServices();
	const communityMutation = usePatchCommunity();
	const { initialValues, purposes, contactList, availableSections, owner } =
		useCommunityFormData();
	const router = useRouter();
	const { communityId } = useParams() as {
		communityId: string;
	};
	const identity = useCurrentIdentity();

	const handleOnSubmit = useCallback(
		async (values: EditCommunityFormSubmittedValues) => {
			let image: PickerFile | undefined | null = values?.image?.[0]
				? values.image[0]
				: undefined;

			if (initialValues.image && !image) {
				image = null;
			}

			const result = await communityMutation.mutateAsync({
				communityId,
				...values,
				image,
			});

			notification.show(
				'success',
				t('edit-community.community-update-success')
			);

			router.navigate('community.home', {
				communityId: result.slug,
			});
		},
		[communityId]
	);

	return (
		<Box>
			<PageHeading
				title={t('edit-community.edit-community-information')}
			/>
			<Box
				component={Paper}
				elevation={0}
				mt={6}
				px={5}
				py={5}
				sx={{ width: { md: 3 / 4 } }}
				mb={3}
			>
				<EditCommunityForm
					initialValues={initialValues}
					purposes={purposes}
					contactList={contactList}
					availableSections={availableSections}
					onSubmit={handleOnSubmit}
				/>
			</Box>
			{identity?.uuid === owner?.uuid && (
				<Trans i18nKey='edit-community.shutdown-community'>
					<RouterLink
						to={router.getPathFor('community.shutdown', {
							communityId,
						})}
					/>
				</Trans>
			)}
		</Box>
	);
};
