import { SetupMutationFn } from 'core/configure/types';
import { BaseHttpError } from 'core/services/api/errors';
import { WellWish, Image, Identity, Nullable } from 'core/types';
import {
	wellWishKeys,
	communityKeys,
	authenticationKeys,
} from 'core/utils/query-key-factory';
import { isCancelledError, Mutation, useMutation } from 'react-query';
import {
	patchWellWishMutationKey,
	postWellWishMutationKey,
} from 'core/utils/mutation-key-factory';
import {
	onPatchMutateOptimisticInfinityQueryCache,
	cancelPreviousMutation,
	onSuccessOptimisticInInfinityQueryCache,
	findRecordFromInfinityQueryCache,
	PaginatedRecordsSnapshot,
	updatePartiallyRecordFromInfinityQueryCache,
} from 'core/utils/optimistic-utils';
import { WellWishInput } from '../../services/api/api-client/types';

export interface PatchWellWishMutationInput
	extends Omit<WellWishInput, 'image'> {
	communityId: string;
	wellWishId: string;
	image: Nullable<Image>;
	createdDt: Nullable<string>;
}

export const usePatchWellWish = () => {
	const mutation = useMutation<
		WellWish,
		BaseHttpError,
		PatchWellWishMutationInput
	>(patchWellWishMutationKey);

	return mutation;
};

type MutationContext = {
	communityId: string;
	wellWishId: string;
	updatedWellWish: WellWish;
	currentWellWishInfo: {
		listSnapshot: PaginatedRecordsSnapshot<WellWish>;
	} | null;
};

export const setupPatchWellWish: SetupMutationFn = (
	services,
	createTrackedParallelMutation,
	mutationTracker
) => {
	const { queryClient, api, uploader } = services;

	const mutation = createTrackedParallelMutation<
		WellWish,
		BaseHttpError,
		PatchWellWishMutationInput,
		MutationContext
	>({
		mutationFn: async ({
			wellWishId,
			communityId,
			createdDt,
			image,
			...input
		}) => {
			const remoteImage = await uploader.uploadImage(image);

			// If created dt is given, we need to set the call to be an update
			const actionCall = createdDt
				? api.patchWellWish.bind(api, wellWishId)
				: api.postWellWish.bind(api, communityId);

			return actionCall({
				...input,
				image: remoteImage?.uuid ?? null,
			}).then(response => response.data);
		},
		onMutate: async input => {
			const { wellWishId, communityId, message, image, createdDt } =
				input;

			const author = queryClient.getQueryData<Identity>(
				authenticationKeys.identity
			) as Identity;

			//  optimistic WellWish
			const updatedWellWish: WellWish = {
				message,
				image,
				uuid: wellWishId,
				author,
				created_dt: createdDt ?? null,
			};

			// Cancel the previous mutation if it exists
			await cancelPreviousMutation<MutationContext>(
				queryClient,
				mutation => {
					return (
						mutation.state.context?.wellWishId === wellWishId &&
						isPostPatchWellWishMutation(mutation)
					);
				}
			);

			const currentWellWishInfo =
				findRecordFromInfinityQueryCache<WellWish>(
					queryClient,
					wellWishKeys.list(communityId),
					wellWishId
				);

			await onPatchMutateOptimisticInfinityQueryCache<WellWish>(
				queryClient,
				wellWishKeys.list(communityId),
				wellWishKeys.detail(communityId, wellWishId),
				updatedWellWish
			);

			updatePartiallyRecordFromInfinityQueryCache(
				queryClient,
				communityKeys.feed(communityId),
				updatedWellWish,
				['message', 'image']
			);

			return {
				communityId,
				wellWishId,
				updatedWellWish,
				currentWellWishInfo,
			};
		},
		onSuccess: (result, input, { communityId, wellWishId }) => {
			onSuccessOptimisticInInfinityQueryCache<WellWish>(
				queryClient,
				wellWishKeys.list(communityId),
				wellWishKeys.detail(communityId, wellWishId),
				wellWishId,
				result
			);
		},
		onError: (error, input, context) => {
			if (context?.currentWellWishInfo && !isCancelledError(error)) {
				queryClient.setQueryData(
					wellWishKeys.list(context.communityId),
					context?.currentWellWishInfo.listSnapshot
				);
			}
		},
		onSettled: (data, error, variables, context) => {
			if (context && !isCancelledError(error)) {
				mutationTracker.queueInvalidations(
					wellWishKeys.list(context.communityId),
					wellWishKeys.detail(
						variables.communityId,
						context.wellWishId
					),
					communityKeys.feed(context.communityId)
				);
			}
		},
		retry: 1,
	});

	queryClient.setMutationDefaults(patchWellWishMutationKey, mutation);
};

export const isPostPatchWellWishMutation = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	mutation: Mutation<any, any, any, any>
) =>
	[patchWellWishMutationKey, postWellWishMutationKey].includes(
		String(mutation?.options?.mutationKey)
	);
