import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';

export const useGetHoursRange = (timezone: string) => {
	const [showLessTime, toggle] = useState(true);

	const hours = useMemo(() => {
		let startTime = DateTime.local({ zone: timezone }).set({
			hour: showLessTime ? 7 : 0,
			minute: 0,
			second: 0,
			millisecond: 0,
		});

		const endTime = startTime.set({
			hour: showLessTime ? 21 : 23,
			minute: 59,
			second: 59,
			millisecond: 999,
		});

		const range = [];

		while (startTime <= endTime) {
			const prevStartTime = startTime;

			startTime = startTime.plus({ hour: 1 });

			range.push({
				startTime: prevStartTime,
				endTime: startTime.minus({ minute: 1 }),
			});
		}

		return range;
	}, [showLessTime]);

	const handleOnToggle = () => {
		toggle(!showLessTime);
	};

	return { hours, showLessTime, toggle: handleOnToggle };
};
