import { subscriptionKeys } from 'core/utils/query-key-factory';
import { useServices } from 'hooks';
import { useState } from 'react';
import { useQuery } from 'react-query';

export const useGetSubscriptionPreferences = (token: string) => {
	const { api } = useServices();
	const [enabled, setEnabled] = useState(!!token);

	return useQuery(
		subscriptionKeys.preference(token),
		() =>
			api
				.getSubscriptionPreferences(token)
				.then(response => response.data),
		{
			enabled: enabled,
			notifyOnChangeProps: 'tracked',
			onSettled: () => {
				setEnabled(false);
			},
		}
	);
};
