import { SxProps } from '@mui/system';
import { Theme } from 'theme';

export const groupNameBoxStyle: SxProps<Theme> = {
	'.MuiFormControl-root': {
		minWidth: 42,
	},

	'& > a': {
		lineHeight: 1,
	},

	'& .checkbox-placeholder': {
		width: 42,
		height: 42,
	},
};
