import React from 'react';
import MuiCircularProgress from '@mui/material/CircularProgress';
import { Box } from '../layout/box';
import { Text } from '../text';

export interface CircularProgressProps
	extends React.ComponentProps<typeof MuiCircularProgress> {
	message?: string;
}

export function CircularProgress(props: CircularProgressProps) {
	const { message } = props;

	return (
		<Box
			justifyContent='center'
			alignItems='center'
			display='flex'
			flexDirection='column'
		>
			<MuiCircularProgress />
			{message && <Text mt='2'>{message}</Text>}
		</Box>
	);
}
