import { useTranslation, useTruncateContent } from 'hooks';
import { Box, Link } from 'components';

type TruncatedContentProps = {
	enabled?: boolean;
	maxHeight?: number;
	children?: React.ReactNode;
	showText?: string;
	hideText?: string;
};

export const TruncatedContent = ({
	enabled = true,
	children,
	maxHeight,
	showText,
	hideText,
}: TruncatedContentProps) => {
	const { t } = useTranslation();

	const { visible, renderLink, toggleVisible, wrapperProps } =
		useTruncateContent({
			enabled,
			maxHeight,
		});

	return (
		<>
			<Box {...wrapperProps}>{children}</Box>
			{renderLink ? (
				<Box mt={1}>
					<Link onClick={toggleVisible}>
						{!visible
							? showText || t('truncate.view-full-content')
							: hideText || t('truncate.hide-full-content')}
					</Link>
				</Box>
			) : null}
		</>
	);
};
