import { useCalendar } from 'components/calendar/context';
import { EventList, useEventsForDateTime } from 'components/calendar/shared';
import { Grid, Typography } from 'components/common';
import { DateTime } from 'luxon';
import { timeRowStyle } from './styles';

type TimeRowProps = {
	label: string;
	day: DateTime;
	time?: DateTime;
};

export const TimeRow = ({ label, day, time }: TimeRowProps) => {
	const { onEventClick } = useCalendar();

	let dateTime = day;

	if (time) {
		dateTime = day.set({
			hour: time.hour,
			minute: time.minute,
			second: time.second,
		});
	}

	const events = useEventsForDateTime(dateTime, !time);

	return (
		<Grid container item spacing={0} sx={timeRowStyle}>
			<Grid item xs={2} sm={1}>
				<Typography variant='caption'>{label}</Typography>
			</Grid>
			<Grid item xs={10} sm={11}>
				<EventList
					events={events}
					onEventClick={onEventClick}
					maxRenderCount={0}
				/>
			</Grid>
		</Grid>
	);
};
