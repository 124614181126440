import { SetupMutationFn } from 'core/configure/types';
import { BaseHttpError } from 'core/services/api/errors';
import { BatchMembershipInvite, Community, MembershipInvite } from 'core/types';
import { deleteFailedInvitationKey } from 'core/utils/mutation-key-factory';
import { removeRecordFromInfinityQueryCache } from 'core/utils/optimistic-utils';
import { communityKeys } from 'core/utils/query-key-factory';
import { isCancelledError, useMutation } from 'react-query';

export interface DeleteFailedInvitesInput {
	communityId: string;
	invitationIds: string[];
}

export const useDeleteFailedInvites = () => {
	const mutation = useMutation<
		MembershipInvite,
		BaseHttpError,
		DeleteFailedInvitesInput
	>(deleteFailedInvitationKey);

	return mutation;
};

export const setupDeleteFailedInvites: SetupMutationFn = (
	services,
	createTrackedParallelMutation,
	mutationTracker
) => {
	const { api, queryClient } = services;

	const mutation = createTrackedParallelMutation<
		BatchMembershipInvite,
		BaseHttpError,
		DeleteFailedInvitesInput,
		null
	>({
		mutationFn: ({ communityId, invitationIds }) =>
			api
				.patchCommunityMembershipInvitations(
					communityId,
					invitationIds,
					'delete'
				)
				.then(response => response.data),
		onMutate: async ({ communityId, invitationIds }) => {
			for (const inviteId of invitationIds) {
				await removeRecordFromInfinityQueryCache<MembershipInvite>(
					queryClient,
					communityKeys.failedInvites(communityId),
					inviteId
				);

				queryClient.setQueryData<Community | undefined>(
					communityKeys.detail(communityId),
					community => {
						if (community) {
							community.team_stats.failed--;
						}

						return community;
					}
				);
			}

			return null;
		},
		onSettled: (_data, error, variables) => {
			if (!isCancelledError(error)) {
				mutationTracker.queueInvalidations(
					communityKeys.failedInvites(variables.communityId),
					communityKeys.detail(variables.communityId)
				);
			}
		},
		retry: 1,
	});

	queryClient.setMutationDefaults(deleteFailedInvitationKey, mutation);
};
