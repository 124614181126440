import { calendarUtils, getRangeOfDates } from 'components/calendar/utils';
import {
	Box,
	FormStack,
	Form,
	LoadingButton,
	FormOnSubmitReturn,
} from 'components/common';
import { useTranslation, useConstantValue } from 'hooks';
import { TFunction } from 'i18next';
import { DateTime } from 'luxon';
import { luxonDateTime } from 'utils/validation';
import * as Yup from 'yup';
import { useCallback, useMemo } from 'react';
import { sleep } from 'utils/delay';
import { DigestFrequency } from 'core/services/api/api-client/types';
import {
	DEFAULT_DELIVERY_HOUR,
	DIGEST_FREQUENCY,
	PREFERENCES,
} from './constants';
import { EmailPreferencesSection } from './email-preferences-section';

export type EmailPreferencesFormSubmittedValues = {
	emailPreferences: string[];
	digestFrequency: DigestFrequency;
	deliveryDay?: string;
	deliveryDays?: string[];
	deliveryTime: DateTime;
};

export type EmailPreferencesFormInitialValues =
	Partial<EmailPreferencesFormSubmittedValues>;

export type EmailPreferencesFormProps = {
	onSubmit?: (
		values: EmailPreferencesFormSubmittedValues
	) => FormOnSubmitReturn;
	submitText?: string;
	submittingText?: string;
	initialValues?: EmailPreferencesFormInitialValues;
};

export const EmailPreferencesForm = (props: EmailPreferencesFormProps) => {
	const { onSubmit, submitText, submittingText, initialValues } = props;

	const { t } = useTranslation();

	const weekDaysOptions = useMemo(() => {
		const today = DateTime.now();
		const startDate = calendarUtils.getFirstDayOfWeek(today);
		const endDate = calendarUtils.getLastDayOfWeek(today);
		const daysRange = getRangeOfDates(startDate, endDate);

		return daysRange.map(day => ({
			label: day.toFormat('EEEE'),
			value: day.setLocale('en-US').toFormat('EEEE'),
		}));
	}, []);

	const cachedInitialValues = useConstantValue({
		emailPreferences: [],
		digestFrequency: DIGEST_FREQUENCY.DAILY,
		deliveryDay: weekDaysOptions[0].value,
		deliveryDays: [],
		deliveryTime: DateTime.fromISO(`${DEFAULT_DELIVERY_HOUR}:00:00`),
		...initialValues,
	});
	const handleOnSubmit = useCallback(
		async values => {
			await sleep(1);
			const result = onSubmit?.(values);
			return Promise.resolve(result).catch(error => error);
		},
		[onSubmit]
	);

	const validationSchema = useMemo(() => createValidationSchema(t), []);

	return (
		<Form
			onSubmit={handleOnSubmit}
			validationSchema={validationSchema}
			subscription={{ submitting: true }}
			initialValues={cachedInitialValues}
		>
			{({ handleSubmit, submitting }) => (
				<form onSubmit={handleSubmit} noValidate>
					<FormStack responsive={false} spacing={0}>
						<EmailPreferencesSection />
						<Box mt={4}>
							<LoadingButton
								loading={submitting}
								loadingIndicator={
									submittingText ||
									t('email-preferences-form.updating-preferences') // prettier-ignore
								}
								size='small'
								type='submit'
							>
								{submitText ||
									t(
										'email-preferences-form.update-preferences'
									)}
							</LoadingButton>
						</Box>
					</FormStack>
				</form>
			)}
		</Form>
	);
};

function createValidationSchema(t: TFunction) {
	return Yup.object().shape({
		emailPreferences: Yup.array().of(Yup.string()),
		digestFrequency: Yup.string().oneOf([
			DIGEST_FREQUENCY.DAILY,
			DIGEST_FREQUENCY.WEEKLY,
			DIGEST_FREQUENCY.CUSTOM,
		]),
		deliveryTime: luxonDateTime('Invalid Time').required('Required'),
		deliveryDay: Yup.string().when('digestFrequency', {
			is: DIGEST_FREQUENCY.WEEKLY,
			then: schema => schema.required(t('form.required')),
			otherwise: schema => schema.notRequired(),
		}),
		deliveryDays: Yup.array()
			.of(Yup.string())
			.when(['emailPreferences', 'digestFrequency'], {
				is: (emailPreferences: string[], digestFrequency: string) =>
					digestFrequency === DIGEST_FREQUENCY.CUSTOM &&
					emailPreferences.includes(PREFERENCES.DIGEST_SUBSCRIPTION),
				then: schema =>
					schema.min(1, t('form.generic-required')).required(),
				otherwise: schema => schema.notRequired(),
			}),
	});
}
