import { SxProps } from '@mui/system';
import { Theme } from 'theme';

export const contentStyle: SxProps<Theme> = {
	display: 'flex',
	flexDirection: 'column',
	height: '100%',
	alignItems: 'center',
	justifyContent: 'center',

	'& img': {
		maxWidth: {
			xs: '100%',
			sm: 600,
			md: 700,
		},
	},

	'& p': {
		textAlign: 'center',
		mt: 5,
	},

	'& button': {
		mt: 5,
	},
};
