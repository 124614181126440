import { SxProps } from '@mui/system';

export const filterStyle: SxProps = {
	textAlign: 'end',
	'> img': {
		width: 14,
		height: 14,
		mr: 1,
		verticalAlign: 'middle',
	},
};
