import { identityWithParams } from 'core/utils/request-with-params';
import { BaseHttpClient } from '../base-http-client';
import { AccessTokenResponse } from '../response-types';

export const createAuthApi = (http: BaseHttpClient) => ({
	/**
	 * Perform login in action
	 *
	 * @param username
	 * @param password
	 * @param language
	 */
	postAccessToken(username: string, password: string, language: string) {
		return http.post<AccessTokenResponse>(
			'access-token',
			{
				username,
				password,
				language,
			},
			{
				params: {
					with: identityWithParams,
				},
			}
		);
	},

	postSessionData(data: string, iv: string) {
		return http.post<AccessTokenResponse>(
			'session',
			{
				data,
				iv,
			},
			{
				params: {
					with: identityWithParams,
				},
			}
		);
	},

	validateJwt(token: string, scope: string) {
		return http.post('jwt', {
			jwt: token,
			scope,
		});
	},

	requestDeletion(email: string) {
		return http.post('delete-account', {
			email,
		});
	},

	deleteAccount(uuid: string) {
		return http.delete(`identity/${uuid}`);
	},
});
