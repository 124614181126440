import {
	Box,
	Field,
	FormStack,
	Form,
	LoadingButton,
	FormOnSubmitReturn,
	RadioGroupField,
	TextField,
	FieldForSubscription,
} from 'components/common';
import { useTranslation } from 'hooks';
import { TFunction } from 'i18next';
import { useCallback, useMemo } from 'react';
import { sleep } from 'utils/delay';
import * as Yup from 'yup';

export type UnsubscribeFormSubmittedValues = {
	reason: string;
	otherReason: string;
};

export type UnsubscribeFormProps = {
	onSubmit?: (values: UnsubscribeFormSubmittedValues) => FormOnSubmitReturn;
	submitText?: string;
	submittingText?: string;
	hideNonActiveOptions?: boolean;
};

export const UnsubscribeForm = (props: UnsubscribeFormProps) => {
	const {
		onSubmit,
		submitText,
		submittingText,
		hideNonActiveOptions = false,
	} = props;

	const { t } = useTranslation();

	const handleOnSubmit = useCallback(
		async ({ reason, otherReason }) => {
			await sleep(1);

			const values = {
				reason,
				otherReason: reason === 'other' ? otherReason : '',
			};

			const result = onSubmit?.(values);

			return Promise.resolve(result).catch(error => error);
		},
		[onSubmit]
	);

	const reasonOptions = [
		{
			label: t('unsubscribe-form.read-info-on-site'),
			value: 'reason-1',
		},
		{
			label: t('unsubscribe-form.there-are-too-many-emails'),
			value: 'reason-2',
		},
		{
			label: t('unsubscribe-form.the-information-is-not-useful-to-me'),
			value: 'reason-3',
		},
		{
			label: t('unsubscribe-form.prefer-app-or-text'),
			value: 'reason-4',
		},
		...(!hideNonActiveOptions
			? [
				{
					label: t('unsubscribe-form.no-longer-active'),
					value: 'non-active',
				},
			  ]
			: []), // prettier-ignore
		{
			label: t('unsubscribe-form.other'),
			value: 'other',
		},
	];

	const validationSchema = useMemo(() => createValidationSchema(t), []);

	return (
		<Form
			onSubmit={handleOnSubmit}
			validationSchema={validationSchema}
			subscription={{ submitting: true }}
		>
			{({ handleSubmit, submitting }) => (
				<form onSubmit={handleSubmit} noValidate>
					<FormStack>
						<Box mt={3}>
							<Field
								label={t(
									'unsubscribe-form.you-will-not-longer-receive-emails'
								)}
								name='reason'
								component={RadioGroupField}
								options={reasonOptions}
							/>
						</Box>
						<Box>
							<FieldForSubscription
								name='reason'
								subscription={{ value: true }}
							>
								{({ input }) => (
									<Field
										name='otherReason'
										component={TextField}
										type='text'
										placeholder={t(
											'unsubscribe-form.please-type-the-reason'
										)}
										disabled={input.value !== 'other'}
										fullWidth
										required
									/>
								)}
							</FieldForSubscription>
						</Box>
						<Box>
							<LoadingButton
								loading={submitting}
								loadingIndicator={
									submittingText || t('form.submitting')
								}
								size='small'
								type='submit'
							>
								{submitText || t('form.submit')}
							</LoadingButton>
						</Box>
					</FormStack>
				</form>
			)}
		</Form>
	);
};

function createValidationSchema(t: TFunction) {
	return Yup.object().shape({
		reason: Yup.string()
			.oneOf([
				'reason-1',
				'reason-2',
				'reason-3',
				'reason-4',
				'non-active',
				'other',
			])
			.required(t('form.generic-required')),
		otherReason: Yup.string().when('reason', {
			is: 'other',
			then: schema => schema.required(t('form.generic-required')),
			otherwise: schema => schema.notRequired(),
		}),
	});
}
