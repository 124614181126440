import produce, { Draft } from 'immer';
import { InfiniteData, Mutation, QueryClient, QueryKey } from 'react-query';
import { MutationState } from 'react-query/types/core/mutation';

export type PaginatedResponse<T> = {
	data: T[];
	pagination: {
		next: string;
		previous: string;
	};
};

type Record<T> = T extends { uuid: string } ? T : never;

export type PaginatedRecords<T> = InfiniteData<PaginatedResponse<Record<T>>>;

export type PaginatedRecordsSnapshot<T> = PaginatedRecords<T> | undefined;

export const onPostMutateOptimistic = async <T>(
	queryClient: QueryClient,
	listKey: QueryKey,
	newRecord: Record<T>
) => {
	await queryClient.cancelQueries(listKey);

	const previousList = queryClient.getQueryData(listKey) as T[];

	queryClient.setQueryData(listKey, original => [
		[...((original || []) as Record<T>[])],
		newRecord as T,
	]);

	return previousList;
};

export const onPatchMutateOptimistic = <T>(
	queryClient: QueryClient,
	listKey: QueryKey,
	detailKey: QueryKey,
	updatedRecord: Record<T>
) => {
	queryClient.setQueryData(listKey, list => {
		if (!list) {
			return list;
		}

		return [...(list as Record<T>[])].map(item => {
			if (item.uuid === updatedRecord.uuid) {
				return updatedRecord;
			}

			return item;
		});
	});

	queryClient.setQueryData(detailKey, updatedRecord);
};

export const onDeleteMutateOptimistic = async <T>(
	queryClient: QueryClient,
	itemId: string,
	listKey: QueryKey,
	singleKey?: QueryKey
) => {
	await queryClient.cancelQueries(listKey);
	if (singleKey) {
		await queryClient.cancelQueries(singleKey);
	}

	const previousList = queryClient.getQueryData(listKey) as T[];

	queryClient.setQueryData(listKey, original =>
		[...((original || []) as Record<T>[])].filter(
			record => record.uuid !== itemId
		)
	);

	if (singleKey) {
		await queryClient.removeQueries(singleKey);
	}

	return previousList;
};

export const onPostMutateMultiOptimisticInfinityQueryCache = async <T>(
	queryClient: QueryClient,
	listKey: QueryKey,
	newRecords: Record<T>[],
	cancelKeys?: QueryKey | QueryKey[]
) => {
	const cancelList = Array.isArray(cancelKeys) ? cancelKeys : [cancelKeys];

	cancelList.push(listKey);

	for (const key of cancelList) await queryClient.cancelQueries(key);

	const previousList = queryClient.getQueryData<PaginatedRecords<T>>(listKey);

	queryClient.setQueryData<PaginatedRecordsSnapshot<T>>(
		listKey,
		currentData => {
			const currentState = currentData
				? currentData
				: { pages: [], pageParams: [] };

			return produce(currentState, draft => {
				if (draft.pages.length === 0) {
					draft.pages.push({
						data: [],
						pagination: { next: '', previous: '' },
					});
				}

				draft.pages[0].data.unshift(
					...(newRecords as Draft<Record<T>>[])
				);
			});
		}
	);

	return previousList;
};

export const onPostMutateSingleRecordInInfinityQueryCache = <T>(
	queryClient: QueryClient,
	listKey: QueryKey,
	newRecord: Record<T>,
	cancelKeys?: QueryKey | QueryKey[]
) => {
	return onPostMutateMultiOptimisticInfinityQueryCache(
		queryClient,
		listKey,
		[newRecord],
		cancelKeys
	);
};

export const onPostMutateOptimisticInfinityQueryCache = async <T>(
	queryClient: QueryClient,
	listKey: QueryKey,
	singleKey: QueryKey | null,
	newRecord: Record<T>,
	cancelKeys: QueryKey | QueryKey[] = [],
	appendToFirstPage = true
) => {
	const cancelList = Array.isArray(cancelKeys) ? cancelKeys : [cancelKeys];

	cancelList.push(listKey);
	if (singleKey) {
		cancelList.push(singleKey);
	}

	for (const key of cancelList) await queryClient.cancelQueries(key);

	queryClient.setQueryData<PaginatedRecordsSnapshot<T>>(
		listKey,
		currentData => {
			const currentState = currentData
				? currentData
				: { pages: [], pageParams: [] };

			return produce(currentState, draft => {
				if (draft.pages.length === 0) {
					draft.pages.push({
						data: [],
						pagination: { next: '', previous: '' },
					});
				}

				if (appendToFirstPage) {
					draft.pages[0].data.unshift(newRecord);
				} else {
					draft.pages[draft.pages.length - 1].data.push(newRecord);
				}
			});
		}
	);

	if (singleKey) {
		// Invalidate previous cache and ....
		await queryClient.invalidateQueries(singleKey, {
			exact: true,
			refetchInactive: false,
			refetchActive: false,
		});

		await queryClient.prefetchQuery(
			singleKey,
			() => Promise.resolve(newRecord),
			{ staleTime: Infinity }
		);
	}
};

export const onPatchMutateOptimisticInfinityQueryCache = async <T>(
	queryClient: QueryClient,
	listKey: QueryKey,
	singleKey: QueryKey,
	record: Record<T>
) => {
	await queryClient.cancelQueries(listKey);
	updateRecordFromInfinityQueryCache<T>(queryClient, listKey, record);
	await updateRecordCacheForOfflineMode<T>(queryClient, singleKey, record);
};

export const updateRecordCacheForOfflineMode = async <T>(
	queryClient: QueryClient,
	singleKey: QueryKey,
	record: Record<T>
) => {
	await queryClient.cancelQueries(singleKey);

	// Invalidate previous cache and ....
	await queryClient.invalidateQueries(singleKey, {
		exact: true,
		refetchInactive: false,
		refetchActive: false,
	});

	// ... Set the new optimistic
	await queryClient.prefetchQuery(singleKey, () => Promise.resolve(record), {
		staleTime: Infinity,
	});
};

export const onDeleteMutateOptimisticInfinityQueryCache = async <T>(
	queryClient: QueryClient,
	listKey: QueryKey,
	singleKey: QueryKey,
	itemId: string
) => {
	await queryClient.cancelQueries(listKey);
	await queryClient.cancelQueries(singleKey);

	removeRecordFromInfinityQueryCache<T>(queryClient, listKey, itemId);

	queryClient.removeQueries(singleKey);
};

export const onSuccessOptimisticInInfinityQueryCache = <T>(
	queryClient: QueryClient,
	listKey: QueryKey,
	singleKey: QueryKey,
	oldItemId: string,
	result: Record<T>
) => {
	queryClient.setQueryData<Record<T>>(singleKey, () => result);

	queryClient.setQueryData<PaginatedRecordsSnapshot<T>>(
		listKey,
		currentData => {
			if (!currentData) return currentData;

			return produce(currentData, draft => {
				// Replace optimistic record with real one
				for (const page of draft.pages) {
					const index = page.data.findIndex(
						item => item.uuid === oldItemId
					);
					if (index !== -1) {
						page.data[index] = result;
						break;
					}
				}
			});
		}
	);
};

export const onErrorOptimisticInInfinityQueryCache = <T>(
	queryClient: QueryClient,
	listKey: QueryKey,
	itemId: string
) => {
	removeRecordFromInfinityQueryCache<T>(queryClient, listKey, itemId);
};

export async function cancelPreviousMutation<Context>(
	queryClient: QueryClient,
	predicate: (mutation: Mutation<any, any, any, Context>) => boolean
) {
	// Look for an existing mutation, in case it was created in offline mode
	const existingMutation = queryClient.getMutationCache().find({
		predicate: mutation => {
			return (
				predicate(mutation as Mutation<any, any, any, Context>) &&
				mutation.state.isPaused
			);
		},
	});

	// Cancel the previous mutation if it exists
	if (existingMutation) {
		await existingMutation.cancel();
	}
}

export async function updatePausedMutationState<
	TData = unknown,
	TVariables = void,
	TContext = unknown
>(
	queryClient: QueryClient,
	predicate: (
		mutation: Mutation<TData, any, TVariables, TContext>
	) => boolean,
	updater: (currentState: MutationState) => MutationState
) {
	// Look for an existing mutation, in case it was created in offline mode
	const existingMutation = queryClient.getMutationCache().find({
		predicate: mutation => {
			return (
				predicate(
					mutation as Mutation<TData, any, TVariables, TContext>
				) && mutation.state.isPaused
			);
		},
	});

	if (existingMutation) {
		const currentState = updater(existingMutation.state);
		existingMutation.setState(currentState);
	}
}

export function updateRecordFromInfinityQueryCache<T>(
	queryClient: QueryClient,
	listKey: QueryKey,
	record: Record<T>
) {
	queryClient.setQueryData<PaginatedRecordsSnapshot<T>>(
		listKey,
		currentData => {
			if (!currentData) return currentData;

			return produce(currentData, draft => {
				// Replace optimistic record with real one
				for (const page of draft.pages) {
					const index = page.data.findIndex(
						item => item.uuid === record.uuid
					);
					if (index !== -1) {
						page.data[index] = record;
						break;
					}
				}
			});
		}
	);
}

export const onSuccessSingleRecordInInfinityQueryCache = <T>(
	queryClient: QueryClient,
	listKey: QueryKey,
	oldItemId: string,
	result: Record<T>
) => {
	queryClient.setQueryData<PaginatedRecordsSnapshot<T>>(
		listKey,
		currentData => {
			if (!currentData) return currentData;
			return produce(currentData, draft => {
				// Replace optimistic record with real one
				for (const page of draft.pages) {
					const index = page.data.findIndex(
						item => item.uuid === oldItemId
					);
					if (index !== -1) {
						page.data[index] = result;
						break;
					}
				}
			});
		}
	);
};

export function removeRecordFromInfinityQueryCache<T>(
	queryClient: QueryClient,
	listKey: QueryKey,
	recordId: string
) {
	queryClient.setQueryData<PaginatedRecordsSnapshot<T>>(
		listKey,
		currentData => {
			if (!currentData) return currentData;
			// Remove the optimistic record from the data
			return produce(currentData, draft => {
				for (const page of draft.pages) {
					const index = page.data.findIndex(
						item => item.uuid === recordId
					);
					if (index !== -1) {
						page.data.splice(index, 1);
						break;
					}
				}
			});
		}
	);
}

export function findRecordFromInfinityQueryCache<T>(
	queryClient: QueryClient,
	listKey: QueryKey,
	recordId: string
) {
	const listSnapshot = queryClient.getQueryData<PaginatedRecords<T>>(listKey);

	const pages = listSnapshot?.pages ?? [];

	for (const pageIndex in pages) {
		const index = pages[pageIndex].data.findIndex(
			record => record.uuid === recordId
		);

		if (index >= 0) {
			return {
				record: pages[pageIndex].data[index],
				pageIndex: Number(pageIndex),
				listSnapshot,
				index,
			};
		}
	}

	return null;
}

export function updatePartiallyRecordFromInfinityQueryCache<T>(
	queryClient: QueryClient,
	listKey: QueryKey,
	record: Record<T>,
	keysToUpdate: (keyof Record<T>)[]
) {
	queryClient.setQueryData<PaginatedRecordsSnapshot<T>>(
		listKey,
		currentData => {
			const currentState = currentData
				? currentData
				: { pages: [], pageParams: [] };

			return produce(currentState, draft => {
				if (draft.pages.length === 0) {
					draft.pages.push({
						data: [],
						pagination: { next: '', previous: '' },
					});
				}

				// Replace optimistic record with real one
				for (const page of draft.pages) {
					const index = page.data.findIndex(
						item => item.uuid === record.uuid
					);
					if (index !== -1) {
						for (const key of keysToUpdate) {
							page.data[index][key] = record[key];
						}
						break;
					}
				}
			});
		}
	);
}
