import { useLocation } from 'hooks';
import { useState, useCallback, useRef, useEffect } from 'react';
import { getBaseUrl } from 'utils/url';

export const useCopyLink = (
	url?: string,
	timeout = 1500,
	includeBaseUrl = true
) => {
	const [isCopied, setIsCopied] = useState(false);
	const timeoutRef = useRef<NodeJS.Timeout>();
	const location = useLocation();

	const linkToCopy = `${includeBaseUrl ? getBaseUrl() : ''}${
		url || location.pathname
	}`;

	const handleOnCopy = useCallback(() => setIsCopied(true), [url]);

	useEffect(() => {
		if (isCopied) {
			copyToClipboard(linkToCopy).then(() => {
				timeoutRef.current = setTimeout(() => {
					setIsCopied(false);
				}, timeout);
			});
		}

		return () => {
			timeoutRef.current && clearTimeout(timeoutRef.current);
		};
	}, [isCopied]);

	return { url: linkToCopy, isCopied, handleOnCopy };
};

function copyToClipboard(textToCopy: string) {
	// navigator clipboard api needs a secure context (https)
	if (navigator.clipboard && window.isSecureContext) {
		// navigator clipboard api method'
		return navigator.clipboard
			.writeText(textToCopy)
			.then(() => true)
			.catch(() => false);
	} else {
		// text area method
		const textArea = document.createElement('textarea');
		textArea.value = textToCopy;
		// make the textarea out of viewport
		textArea.style.position = 'fixed';
		textArea.style.left = '-999999px';
		textArea.style.top = '-999999px';
		document.body.appendChild(textArea);
		textArea.focus();
		textArea.select();

		try {
			const result = document.execCommand('copy');
			return Promise.resolve(result);
		} finally {
			document.body.removeChild(textArea);
		}
	}
}
