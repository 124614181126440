import { Breakpoint } from '@mui/material';
import MuiDialog, { DialogProps as MuiDialogProps } from '@mui/material/Dialog';
import MuiDialogActions from '@mui/material/DialogActions';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogContentText from '@mui/material/DialogContentText';
import MuiDialogTitle from '@mui/material/DialogTitle';
import {
	forwardRef,
	Ref,
	useEffect,
	useImperativeHandle,
	useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../button/button';

export type DialogProps<T = void, E = void> = {
	id: string;
	title?: string | React.ReactNode | ((contextData: T) => React.ReactNode);
	initialOpen?: boolean;
	content:
		| string
		| React.ReactNode
		| ((
				confirmHandler: (extra: E) => void,
				cancelHandler: () => void,
				contextData: T
		  ) => React.ReactNode);
	contextData?: T;
	onConfirm?: (contextData: T, extraData: E) => void;
	onCancel?: (contextData: T) => void;
	confirmText?: string;
	cancelText?: string;
	closeModal?: () => void;
	hideCancel?: boolean;
	hideConfirm?: boolean;
	hideActions?: boolean;
	fullWidth?: boolean;
	maxWidth?:
		| false
		| Breakpoint
		| undefined
		| ((contextData: T) => false | Breakpoint | undefined);
	renderActions?: (
		confirmHandler: (extra: E) => void,
		cancelHandler: () => void,
		contextData: T
	) => React.ReactNode;
	PaperProps?: MuiDialogProps['PaperProps'];
};

type DialogRefHandler = {
	open: () => void;
	close: () => void;
};

export const Dialog = forwardRef(function Dialog<T = void, E = void>(
	props: DialogProps<T, E>,
	ref: Ref<DialogRefHandler>
) {
	const {
		id,
		title,
		content,
		closeModal,
		onCancel,
		onConfirm,
		cancelText,
		confirmText,
		hideCancel,
		hideConfirm,
		hideActions = false,
		contextData,
		fullWidth,
		renderActions,
		initialOpen = true,
		PaperProps,
		maxWidth,
	} = props;
	const [open, setOpen] = useState(false);
	const dialogId = `dialog-${id}`;

	const { t } = useTranslation();

	useEffect(() => {
		setOpen(initialOpen);
	}, []);

	const handleClose = () => setOpen(false);

	useImperativeHandle(ref, () => ({
		open: () => setOpen(true),
		close: () => handleClose(),
	}));

	const handleOnConfirm = (extraData: E) => {
		onConfirm?.(contextData as T, extraData);
		handleClose();
	};

	const handleOnExited = () => closeModal?.();

	const handleOnCancel = () => {
		onCancel?.(contextData as T);
		handleClose();
	};

	return (
		<MuiDialog
			open={open}
			onClose={handleClose}
			id={dialogId}
			aria-labelledby={`${dialogId}-title`}
			aria-describedby={`${dialogId}-description`}
			PaperProps={{
				elevation: 2,
				...PaperProps,
			}}
			closeAfterTransition
			TransitionProps={{
				onExited: handleOnExited,
			}}
			fullWidth={fullWidth}
			maxWidth={
				typeof maxWidth === 'function'
					? maxWidth(contextData as T)
					: maxWidth
			}
		>
			{typeof title === 'string' ? (
				<MuiDialogTitle id={`${dialogId}-title`}>
					{title}
				</MuiDialogTitle>
			) : typeof title === 'function' ? (
				title(contextData as T)
			) : (
				<>{title}</>
			)}
			{typeof content === 'string' ? (
				<MuiDialogContent>
					<MuiDialogContentText id={`${dialogId}-description`}>
						{content}
					</MuiDialogContentText>
				</MuiDialogContent>
			) : typeof content === 'function' ? (
				content(handleOnConfirm, handleOnCancel, contextData as T)
			) : (
				<MuiDialogContent>{content}</MuiDialogContent>
			)}
			{!hideActions &&
				(renderActions ? (
					renderActions(
						handleOnConfirm,
						handleOnCancel,
						contextData as T
					)
				) : (
					<MuiDialogActions
						id={`${dialogId}-actions`}
						sx={{ paddingX: 2, paddingBottom: 2 }}
					>
						{!hideCancel ? (
							<Button
								size='small'
								variant='text'
								onClick={handleOnCancel}
							>
								{cancelText || t('common.close')}
							</Button>
						) : null}
						{!hideConfirm ? (
							<Button
								size='small'
								onClick={() => {
									handleOnConfirm(undefined as unknown as E);
								}}
							>
								{confirmText || t('common.ok')}
							</Button>
						) : null}
					</MuiDialogActions>
				))}
		</MuiDialog>
	);
});

export const DialogActions = MuiDialogActions;
export const DialogContent = MuiDialogContent;
export const DialogContentText = MuiDialogContentText;
export const DialogTitle = MuiDialogTitle;
