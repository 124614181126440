import { getCurrentTenant } from './utils';

const tenant = getCurrentTenant();

type Theme = Record<string, any>;

let tenantThemePromise = Promise.resolve({}) as Promise<Theme>;

if (tenant) {
	const modules = import.meta.glob('./*/theme/*.ts');

	const found = Object.keys(modules).find(key => {
		const matched = key.match(/\/([^/]+)\/theme\/[^/]+\.ts$/)?.[1] ?? false;
		return matched === tenant;
	});

	tenantThemePromise = found
		? modules[found]().then(mod => mod.default)
		: Promise.resolve({});
}

const getThemeForCurrentTenant = () => tenantThemePromise;

export default getThemeForCurrentTenant;
