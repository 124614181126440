import {
	Box,
	Field,
	Form,
	FormStack,
	Grid,
	LoadingButton,
	OptionType,
	SelectField,
	SelectItem,
	SelectMultipleField,
	TelephoneField,
	TextField,
	Typography,
	FormOnSubmitReturn,
	PlainTextField,
} from 'components';
import { EmailPreferencesSection } from 'components/email-preferences';
import { DigestFrequency } from 'core/services/api/api-client/types';
import { CommunityMember, PhoneNumberValue } from 'core/types';
import { TFunction } from 'i18next';
import { DateTime } from 'luxon';
import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { sleep } from 'utils/delay';
import { phoneNumber } from 'utils/validation';
import * as Yup from 'yup';
import { sectionHeader, sectionHeaderContainer } from './styles';

export type EditMemberFormSubmittedValues = {
	firstName?: string;
	lastName?: string;
	phone?: PhoneNumberValue;
	email?: string;
	helpWith: string;
	groups?: string[];
	administrativeNotes?: string;
	eventReminderTime?: string;
	emailPreferences?: string[];
	digestFrequency?: DigestFrequency;
	deliveryDay?: string;
	deliveryDays?: string[];
	deliveryTime?: DateTime;
};

type EditMemberFormProps = {
	initialValues: Partial<EditMemberFormSubmittedValues>;
	isSameMember: boolean;
	canEditGroups: boolean;
	canSeeAdminInfo: boolean;
	groupsOptions: OptionType[];
	reminderOptions: OptionType[];
	onSubmit?: (
		values: EditMemberFormSubmittedValues
	) => FormOnSubmitReturn<CommunityMember>;
	submitText?: string;
	submittingText?: string;
};

export const EditMemberForm = (props: EditMemberFormProps) => {
	const {
		initialValues,
		isSameMember,
		canEditGroups,
		canSeeAdminInfo,
		groupsOptions,
		reminderOptions,
		onSubmit,
		submitText,
		submittingText,
	} = props;

	const { t } = useTranslation();

	const cachedInitialValues = useRef({
		...initialValues,
	}).current;

	const handleOnSubmit = useCallback(
		async values => {
			await sleep(1);

			const result = onSubmit?.(values);

			return Promise.resolve(result).catch(error => error);
		},
		[onSubmit]
	);

	const validationSchema = createValidationSchema(t, isSameMember);

	return (
		<Form
			initialValues={cachedInitialValues}
			validationSchema={validationSchema}
			subscription={{ submitting: true }}
			onSubmit={handleOnSubmit}
		>
			{({ handleSubmit, submitting }) => (
				<form onSubmit={handleSubmit} noValidate>
					<Grid container>
						<Grid item xs={12} md={8}>
							<FormStack responsive={false}>
								<Typography sx={sectionHeader}>
									{t('edit-member-profile.personal-details')}
								</Typography>
								{isSameMember && (
									<>
										<Field
											label={t('common-label.first-name')}
											name='firstName'
											component={TextField}
											type='text'
											placeholder={t(
												'common-label.first-name'
											)}
											fullWidth
											required
										/>
										<Field
											label={t('common-label.last-name')}
											name='lastName'
											component={TextField}
											type='text'
											placeholder={t(
												'common-label.last-name'
											)}
											fullWidth
											required
										/>
										<Field
											label={t('common-label.phone')}
											name='phone'
											component={TelephoneField}
											type='text'
											placeholder='(201) 555-0123'
											fullWidth
											required
										/>
										<Field
											label={t('common-label.email')}
											name='email'
											component={TextField}
											type='text'
											placeholder={t(
												'common-label.email'
											)}
											fullWidth
											required
										/>
									</>
								)}
								{!isSameMember && (
									<>
										<PlainTextField
											label={t('common-label.first-name')}
											value={
												cachedInitialValues.firstName ?? '' // prettier-ignore
											}
											fullWidth
										/>
										<PlainTextField
											label={t('common-label.last-name')}
											value={
												cachedInitialValues.lastName ?? '' // prettier-ignore
											}
											fullWidth
										/>
										<PlainTextField
											label={t('common-label.phone')}
											value={
												cachedInitialValues.phone?.number ?? '' // prettier-ignore
											}
											fullWidth
										/>
										<PlainTextField
											label={t('common-label.email')}
											value={
												cachedInitialValues.email ?? ''
											}
											fullWidth
										/>
									</>
								)}
								<Field
									label={t('edit-member-profile.helpWith')}
									name='helpWith'
									component={TextField}
									type='text'
									fullWidth
									multiline
									rows={2}
								/>
								{canEditGroups ? (
									<Field
										label={t('edit-member-profile.groups')}
										name='groups'
										component={SelectMultipleField}
										options={groupsOptions}
									/>
								) : (
									<PlainTextField
										label={t('edit-member-profile.groups')}
										value={
											cachedInitialValues.groups
												?.map(name => {
													const description =
														name.replace('_', ' ');
													return (
														description
															.charAt(0)
															.toUpperCase() +
														description.slice(1)
													);
												})
												.join(', ') ?? ''
										}
										fullWidth
									/>
								)}
								{canSeeAdminInfo && (
									<Field
										label={t(
											'edit-member-profile.admin-notes'
										)}
										name='administrativeNotes'
										component={TextField}
										type='text'
										fullWidth
										multiline
										rows={2}
									/>
								)}
								{isSameMember && (
									<>
										<Box sx={sectionHeaderContainer}>
											<Typography>
												{t(
													'edit-member-profile.notifications'
												)}
											</Typography>
										</Box>
										<Field
											component={SelectField}
											name='eventReminderTime'
											fullWidth
											validateImmediately
											label={t(
												'edit-member-profile.email-alert'
											)}
										>
											{reminderOptions.map(option => (
												<SelectItem
													key={option.value}
													value={option.value}
												>
													{option.label}
												</SelectItem>
											))}
										</Field>
										<Box sx={sectionHeaderContainer}>
											<Typography>
												{t(
													'edit-member-profile.subscriptions'
												)}
											</Typography>
										</Box>
										<EmailPreferencesSection />
									</>
								)}
								<Box>
									<LoadingButton
										loading={submitting}
										loadingIndicator={
											submittingText ||
									t('email-preferences-form.updating-preferences') // prettier-ignore
										}
										size='small'
										type='submit'
									>
										{submitText ||
											t(
												'email-preferences-form.update-preferences'
											)}
									</LoadingButton>
								</Box>
							</FormStack>
						</Grid>
					</Grid>
				</form>
			)}
		</Form>
	);
};

function createValidationSchema(t: TFunction, isSameMember: boolean) {
	let schema = Yup.object();

	if (isSameMember) {
		schema = schema.shape({
			firstName: Yup.string().trim().required(t('form.generic-required')),
			lastName: Yup.string().trim().required(t('form.generic-required')),
			email: Yup.string()
				.trim()
				.email(t('form.invalid-email'))
				.required(t('form.generic-required')),
			phone: phoneNumber(t('form.invalid-phone')).required(
				t('form.generic-required')
			),
		});
	}

	return schema;
}
