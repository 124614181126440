import { SxProps } from '@mui/system';

export const resultStyle: SxProps = {
	cursor: 'pointer',
	marginBottom: 2,
	display: 'flex',
	alignItems: 'center',
	transition: 'top .1s, box-shadow .2s',
	transitionTimingFunction: 'ease-in-out',
	'&:hover': {
		boxShadow: '0 2px 4px 0 rgba(0,0,0,.1)',
	},
};

export const infoStyle: SxProps = {
	display: 'flex',
	flexDirection: 'column',
	paddingLeft: '20px',
	width: '80%',
};

export const imageStyle: SxProps = {
	width: { xs: 60, sm: 120 },
	height: { xs: 60, sm: 120 },
};

export const textStyle: SxProps = {
	overflow: 'hidden',
	overflowWrap: 'anywhere',
	textOverflow: 'ellipsis',
	height: 48,
	lineHeight: '1.5rem',
	display: '-webkit-box',
	WebkitLineClamp: 2,
	WebkitBoxOrient: 'vertical',
};
