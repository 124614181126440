import { Suspense, useMemo } from 'react';
import {
	ThemeProvider as MUIThemeProvider,
	createTheme,
} from '@mui/material/styles';
import { suspend } from 'suspend-react';
import { Splash } from 'components';
import getThemeForCurrentTenant from 'tenants/theme';
import { createBaseTheme } from './theme';

const DefaultFallback = <Splash message='Loading UI elements..' />;

const InnerThemeProvider: React.FC = props => {
	const tenantTheme = suspend(() => getThemeForCurrentTenant(), ['theme']);

	let theme = createBaseTheme();

	theme = useMemo(
		() => createTheme(theme, tenantTheme),
		[theme, tenantTheme]
	);

	return <MUIThemeProvider theme={theme} {...props} />;
};

export const ThemeProvider: React.FC<{
	fallback?: JSX.Element;
}> = ({ fallback = DefaultFallback, ...rest }) => {
	return (
		<Suspense fallback={fallback}>
			<InnerThemeProvider {...rest} />
		</Suspense>
	);
};
