import React from 'react';
import { withTenantTemplate } from 'tenants/react';
import { Container, Box } from '../../common';
import { HeaderLogo } from './logo';

type HeaderProps = {
	headerLeft?: React.ReactNode;
	headerRight?: React.ReactNode;
};

const HeaderBase = (props: HeaderProps) => {
	const { headerLeft, headerRight } = props;

	return (
		<Box className='header' bgcolor='white'>
			<Container maxWidth='lg'>
				<Box
					display='flex'
					alignItems='center'
					px={{ xs: 0, sm: 2 }}
					py={1}
				>
					<Box minWidth='0'>
						{headerLeft ? headerLeft : <HeaderLogo withName />}
					</Box>
					<Box flexGrow={1}>{headerRight}</Box>
				</Box>
			</Container>
		</Box>
	);
};

export const Header = withTenantTemplate(
	HeaderBase,
	'components/layout/shared/header.tsx'
);
