import { FormSpy } from 'react-final-form';
import { useCallback, useEffect, useRef } from 'react';
import { usePrevious } from 'hooks';

export interface Props<T> {
	onSave: (values: T) => Promise<void>;
	values: T;
	debounce: number;
}

function AutoSave<T>(props: Props<T>) {
	const timeoutRef = useRef<number | null>(null);
	const promiseRef = useRef<Promise<void> | null>(null);
	const prevValues = usePrevious(props.values);

	const save = useCallback(async () => {
		if (promiseRef.current) {
			await promiseRef.current;
		}

		const isDifferent = prevValues !== props.values;

		if (isDifferent) {
			promiseRef.current = props.onSave(props.values);
			await promiseRef.current;
			promiseRef.current = null;
		}
	}, [props.onSave, props.values]);

	useEffect(() => {
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}

		timeoutRef.current = setTimeout(save, props.debounce);

		return () => {
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
			}
		};
	}, [props.debounce, props.values]);

	return null;
}

export function AutoSaveField<T>(props: Omit<Props<T>, 'values'>) {
	return (
		<FormSpy subscription={{ values: true }}>
			{({ values }: { values: T }) => (
				<AutoSave values={values} {...props} />
			)}
		</FormSpy>
	);
}
